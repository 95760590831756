import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ICIDContractStatus,
  ICIDApplication,
  ICIDEstimateParam,
  ICIDRequest,
  ICIDBasicParam,
  ICIDCategory,
  ICIDRegisteredSearchType,
  ICIDRegisteredParam,
  ICIDRegisteredDetailParam,
  PCPCCouncilOpenDates,
} from 'types/material/icid/icid';
import client from 'lib/api/client';
import { DocumentStatus, APIPageableResponse, APIResponse } from 'types/common';
import path from 'lib/path';
import * as estimateActions from 'modules/estimate';
import { useOngoingDocs } from './ongoing';

const queryKeys = {
  pcpcCouncilOpenDatesKey: 'icid/getPCPCOpenDates',
  icidBasicKey: 'icid/getICIDBasic',
  icidCategoriesKey: 'icid/getICIDCategories',
  icidRequestListKey: 'icid/getICIDRequestList',
  icidRegisteredKey: 'icid/getICIDRegistered',
};

export const usePCPCCouncilOpenDates = () => {
  const { data, isLoading } = useQuery(
    [queryKeys.pcpcCouncilOpenDatesKey],
    () => client.get<APIResponse<PCPCCouncilOpenDates>>(`/material/pcpc-council/open-dates`),
    { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
  );

  const pcpcCouncilOpendates = useMemo(() => {
    const formattedPCPCCouncilOpenDates = data?.openDates.map((date) =>
      date.openDate.replace(/^\d{4}-0{0,1}(\d{1,2})-0{0,1}(\d{1,2})$/, '$1/$2'),
    );

    return formattedPCPCCouncilOpenDates;
  }, [data]);

  const currentYear = useMemo(() => data?.openYear, [data]);

  return { pcpcCouncilOpendates, isLoading, currentYear };
};

export const useICIDApplication = (materialIcidApplicationId?: number) => {
  const { data: icidApplication, isLoading: getICIDApplicationLoading } = useQuery(
    [queryKeys.icidBasicKey, materialIcidApplicationId],
    () =>
      client.get<APIResponse<ICIDApplication>>(
        `/material/icid-applications/${materialIcidApplicationId}`,
      ),
    {
      enabled: typeof materialIcidApplicationId !== 'undefined',
      select: (res) => res.data.result,
    },
  );

  return {
    icidApplication,
    getICIDApplicationLoading,
  };
};

export const useICIDBasic = ({
  materialIcidApplicationId,
}: {
  materialIcidApplicationId?: number;
}) => {
  const navigate = useNavigate();

  const { icidApplication, getICIDApplicationLoading } =
    useICIDApplication(materialIcidApplicationId);

  const { icidApplicationOngoings } = useOngoingDocs(materialIcidApplicationId);

  const { data: icidCategories = [], isLoading: getICIDCategoriesLoading } = useQuery(
    [queryKeys.icidCategoriesKey],
    () => client.get<APIResponse<ICIDCategory[]>>(`/material/icid-application/categories`),
    { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
  );

  const { mutate: checkTradeNameIsDuplicated } = useMutation((tradeName: string) =>
    client.get<APIResponse<{ isDuplicate: boolean }>>(
      `/material/icid-application/duplicate-checks/${tradeName}`,
    ),
  );

  const { mutate: addICIDBasic } = useMutation(
    ({ tradeName, materialCategoryId }: ICIDBasicParam) =>
      client.post<APIResponse<null>>(`/material/icid-applications`, {
        tradeName,
        materialCategoryId,
      }),
    {
      onSuccess: () => {
        message.success('등록되었습니다.');
        navigate(path.material.icid.requestList);
      },
    },
  );

  const { mutate: updateICIDBasic } = useMutation(
    (params: { tradeName?: string; materialCategoryId?: number }) =>
      client.patch<APIResponse<null>>(
        `/material/icid-applications/${materialIcidApplicationId}`,
        params,
      ),
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
        navigate(-1);
      },
    },
  );

  const readOnlyMode = useMemo(
    () =>
      typeof materialIcidApplicationId !== 'undefined' &&
      icidApplicationOngoings[0]?.status !== DocumentStatus.MOD,
    [materialIcidApplicationId, icidApplicationOngoings],
  );

  return {
    icidApplication,
    icidCategoryOptions: icidCategories.map((category: ICIDCategory) => ({
      label: category.categoryName,
      value: category.materialCategoryId,
    })),
    isLoading: getICIDCategoriesLoading || getICIDApplicationLoading,
    checkTradeNameIsDuplicated,
    addICIDBasic,
    updateICIDBasic,
    readOnlyMode,
  };
};

export const useICIDRequestList = ({
  isFinishedContract,
  page,
  tradeName,
}: {
  isFinishedContract: ICIDContractStatus;
  page: number;
  tradeName: string;
}) => {
  const queryClient = useQueryClient();
  const { data: { content: icidRequestList = [], totalElements: total = 0 } = {}, isLoading } =
    useQuery(
      [queryKeys.icidRequestListKey, page, isFinishedContract, tradeName],
      () =>
        client.get<APIPageableResponse<ICIDRequest[]>>(`/material/icid-applications`, {
          params: {
            tradeName,
            page,
            isFinishedContract,
          },
        }),
      { select: (res) => res.data.result },
    );

  const { mutate: deleteICIDRequest } = useMutation(
    (icidApplicationId: number) =>
      client.delete<APIResponse<null>>(`/material/icid-applications/${icidApplicationId}`),
    {
      onSuccess: () => {
        queryClient.refetchQueries([queryKeys.icidRequestListKey]);
        message.success('삭제되었습니다.');
      },
    },
  );

  return { icidRequestList, total, isLoading, deleteICIDRequest };
};

export const useICIDEstimate = () => {
  const dispatch = useDispatch();

  const { mutate: addICIDEstimate, isLoading } = useMutation(
    (icidApplicationId: number) =>
      client.post<APIResponse<ICIDEstimateParam>>(`/material/icid-application/estimates`, {
        icidApplicationId,
      }),
    {
      onSuccess: ({
        data: {
          result: { estimateFileUrl, materialIcidApplicationEstimateId },
        },
      }) => {
        dispatch(
          estimateActions.setEstimate({
            estimateFileUrl,
            materialIcidApplicationEstimateId,
          }),
        );
      },
    },
  );

  return {
    addICIDEstimate,
    isLoading,
  };
};

export const useICIDContract = (icidApplicationId: number) => {
  const navigate = useNavigate();
  const { mutate: addICIDContract, isLoading } = useMutation(
    ({
      icidApplicationEstimateId,
      postAddress,
      isAgreeContract,
    }: {
      icidApplicationEstimateId?: number;
      postAddress?: string;
      isAgreeContract: boolean;
    }) =>
      client.post<APIResponse<null>>(
        `/material/icid-application/estimates/${icidApplicationEstimateId}/contracts`,
        {
          postAddress,
          isAgreeContract,
        },
      ),
    {
      onSuccess: () => {
        message.success('계약이 체결되었습니다.');
        navigate(`${path.material.icid.root}/${icidApplicationId}`, {
          state: {
            status: 'contracted',
          },
          replace: true,
        });
      },
    },
  );

  return {
    addICIDContract,
    isLoading,
  };
};

export const useICIDRegistered = ({
  page,
  searchValue,
  searchType,
}: {
  page: number;
  searchValue: string;
  searchType: ICIDRegisteredSearchType;
}) => {
  const { data: { content: icidRegistered = [], totalElements: total = 0 } = {}, isLoading } =
    useQuery(
      [queryKeys.icidRegisteredKey, page, searchType, searchValue],
      () =>
        client.get<APIPageableResponse<ICIDRegisteredParam[]>>(`/material/icid-applications/post`, {
          params: {
            page,
            type: searchType,
            keyword: searchValue,
            size: 10,
          },
        }),
      { select: (res) => res.data.result },
    );

  return { icidRegistered, total, isLoading };
};

export const useICIDRegisteredDetail = ({ icidApplicationId }: { icidApplicationId: number }) => {
  const { data: icidRegisteredDetail = [], isLoading } = useQuery(
    [queryKeys.icidRegisteredKey],
    () =>
      client.get<APIResponse<ICIDRegisteredDetailParam[]>>(
        `/material/icid-applications/${icidApplicationId}/post-docs`,
      ),
    { select: (res) => res.data.result },
  );

  return { icidRegisteredDetail, isLoading };
};

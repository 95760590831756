import { useMutation, useQuery } from 'react-query';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { Terms, TermsType } from 'types/terms';

export const useAllTerms = () =>
  useQuery(
    ['terms/getAllTerms'],
    () => client.get<APIResponse<Terms[]>>(`/v2/pub/service-terms/latest`),
    {
      select: (res) => res.data.result,
    },
  );

export const useTermsByType = () => {
  const { mutate: getTermsByType, isLoading: termsLoading } = useMutation(
    ({ termType }: { termType: TermsType }) =>
      client.get<APIResponse<Pick<Terms, 'serviceTermId' | 'publishDate'>[]>>(
        `/v2/pub/service-terms?termType=${termType}`,
      ),
  );
  return {
    getTermsByType,
    termsLoading,
  };
};
export const useTermsDetail = () => {
  const { mutate: termsDetail } = useMutation(({ serviceTermId }: { serviceTermId?: number }) =>
    client.get<APIResponse<Terms>>(`/v2/pub/service-terms/${serviceTermId}`),
  );
  return {
    termsDetail,
  };
};

import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCurrentProduct } from 'service/brand/product/product';
import ProductHeavyMetalsAndMicrobiologicalStandard from 'components/product/ProductHeavyMetalsAndMicrobiologicalStandard';
import MistakeNoticeItem from 'components/MistakeNoticeItem';

const notices = [
  '국내 화장품법상 유통화장품안전관리 기준에 적합한 제품의 경우에만 certicos를 통한 인증이 가능합니다.',
  <MistakeNoticeItem />,
];

const ProductHeavyMetalsAndMicrobiologicalStandardPage = () => {
  const { productId } = useCurrentProduct();

  return (
    <ProductPageTemplate subtitle="Heavy Metals & Microbiological Standard" notices={notices}>
      <ProductHeavyMetalsAndMicrobiologicalStandard productId={productId} />
    </ProductPageTemplate>
  );
};

export default ProductHeavyMetalsAndMicrobiologicalStandardPage;

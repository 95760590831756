import React from 'react';
import styled, { CSSProperties, css } from 'styled-components';

import palette from 'lib/styles/palette';
import Flex from '../Flex/Flex';
import { icons } from './icons';
import { LoadingOutlined } from '@ant-design/icons';

type ColorType = keyof Omit<typeof palette, 'text'>;

interface IconProps {
  name: keyof typeof icons;
  color?: ColorType;
  hoverColor?: ColorType;
  circleColor?: ColorType;
  rectColor?: ColorType;
  size?: number;
  height?: number;
  width?: number;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler;
  className?: string;
  loading?: boolean;
}

const Icon = ({
  name,
  color,
  hoverColor,
  circleColor,
  rectColor,
  size = 24,
  width,
  height,
  onClick,
  style,
  className,
  loading = false,
}: IconProps) => {
  const IconComponent = icons[name];

  if (loading) {
    return <LoadingOutlined style={{ fontSize: size }} width={size} color={color} />;
  }

  return (
    <Container
      className={className}
      color={color}
      hoverColor={hoverColor}
      circleColor={circleColor}
      rectColor={rectColor}
      size={size}
      width={width}
      height={height}
      onClick={onClick}
      style={{
        ...(onClick && { cursor: 'pointer' }),
        ...style,
      }}
    >
      <IconComponent fill={color && palette[color]} width={width || size} height={height || size} />
    </Container>
  );
};

export default Icon;

const Container = styled(Flex)<Partial<IconProps>>`
  justify-content: center;
  align-items: center;

  ${({ color }) =>
    color &&
    css`
      svg {
        fill: ${palette[color]} !important;
      }

      path {
        fill: ${palette[color]} !important;
      }
    `}

  ${({ hoverColor }) =>
    hoverColor &&
    css`
      &:hover {
        svg {
          fill: ${palette[hoverColor]} !important;
        }

        path {
          fill: ${palette[hoverColor]} !important;
        }
      }
    `}

  ${({ circleColor }) =>
    circleColor &&
    css`
      circle {
        fill: ${palette[circleColor]} !important;
      }
    `}

    ${({ rectColor }) =>
    rectColor &&
    css`
      rect {
        fill: ${palette[rectColor]} !important;
      }
    `}

  ${({ size }) =>
    size &&
    css`
      width: ${size}px;
      height: ${size}px;
    `}

    ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

    ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

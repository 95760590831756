import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import ProductFDACertificatedColor from 'components/product/ProductFDACertificatedColor';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = [
  '색소의 FDA 인증 성적서를 보유하신 경우 영문 PDF 또는 ZIP 파일로 업로드 해주세요.',
  <MistakeNoticeItem />,
];

const ProductFDACertificatedColorPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId()!;

  return (
    <ProductPageTemplate subtitle="FDA 색소 인증 서류" notices={notices}>
      <ProductFDACertificatedColor productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductFDACertificatedColorPage;

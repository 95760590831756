import qs from 'qs';

import client from 'lib/api/client';
import { User, UserRegister, UserType, UserUpdate } from 'types/auth';
import { APIResponse } from 'types/common';
import { CompanyType } from 'types/company';

export const bizNumberDupCheck = (params: { bizNumber: string; companyType: CompanyType }) =>
  client.get(`/v1/companies/biz-number/duplicate?${qs.stringify(params)}`);
export const usernameDupCheck = (params: { username: string; userType: UserType }) =>
  client.get(`/v1/users/duplicate?${qs.stringify(params)}`);
export const registerUser = (user: UserRegister) => client.post('/v1/users', user);
export const updateUser = (user: UserUpdate) => client.patch(`/users/${user.userId}`, user);
export const resetPassword = (userId: number) => client.patch(`/v1/users/${userId}/password/reset`);
export const deleteUser = (userId: number) => client.delete(`/v1/users/${userId}`);
export const login = (params: { username: string; password: string; userType: UserType }) =>
  client.post('/v2/auth/login', params);
export const getUser = () => client.get<APIResponse<User>>(`/users`);
export const findPassword = (params: { username: string; email: string; userType: UserType }) =>
  client.post('/v1/users/password/reset', params);
export const reissueToken = (refreshToken: string) =>
  client.post('/v2/auth/login/auto', { refreshToken });
export const getManagers = (companyId: number) => client.get(`/companies/${companyId}/users`);
export const changePassword = (changedPassword: {
  userId: number;
  prevPassword: string;
  password: string;
  userType: UserType;
}) => client.patch('/v1/users/password', changedPassword);

import { ManagerPositionName } from 'types/brand/vcrp';
import { ProductSearchType } from 'types/product';

export const formOptions = [
  { value: 'Solid', text: 'Solid' },
  { value: 'Liquid', text: 'Liquid' },
  { value: 'Gas', text: 'Gas' },
  { value: 'Cream', text: 'Cream' },
  { value: 'Balm', text: 'Balm' },
  { value: 'Powder', text: 'Powder' },
  { value: 'Mist', text: 'Mist' },
];

export const colorOptions = [
  { value: 'Transparent', text: 'Transparent' },
  { value: 'Red', text: 'Red' },
  { value: 'Blue', text: 'Blue' },
  { value: 'Yellow', text: 'Yellow' },
  { value: 'Green', text: 'Green' },
];

export const odourOptions = [
  { value: 'Odourless', text: 'Odourless' },
  { value: 'Weak characteristic odour', text: 'Weak characteristic odour' },
];

export const netWeightUnitOptions = [
  { value: 'g', text: 'g' },
  { value: 'mL', text: 'mL' },
  { value: 'Oz.', text: 'Oz.' },
  { value: 'fl. Oz.', text: 'fl. Oz.' },
];

export const viscosityUnitOptions = [
  { value: 'mPa·s', text: 'mPa·s' },
  { value: 'cps', text: 'cps' },
];

export const hardnessUnitOptions = [
  { value: 'kg', text: 'kg' },
  { value: 'N', text: 'N' },
];

export const exponentOptions = [
  {
    value: 1,
    text: (
      <>
        10<sup>1</sup>
      </>
    ),
  },
  {
    value: 2,
    text: (
      <>
        10<sup>2</sup>
      </>
    ),
  },
  {
    value: 3,
    text: (
      <>
        10<sup>3</sup>
      </>
    ),
  },
  {
    value: 4,
    text: (
      <>
        10<sup>4</sup>
      </>
    ),
  },
  {
    value: 5,
    text: (
      <>
        10<sup>5</sup>
      </>
    ),
  },
  {
    value: 6,
    text: (
      <>
        10<sup>6</sup>
      </>
    ),
  },
  {
    value: 7,
    text: (
      <>
        10<sup>7</sup>
      </>
    ),
  },
  {
    value: 8,
    text: (
      <>
        10<sup>8</sup>
      </>
    ),
  },
  {
    value: 9,
    text: (
      <>
        10<sup>9</sup>
      </>
    ),
  },
];

export const managerPositionOptions = [
  { value: 'OWNER', text: ManagerPositionName.OWNER },
  { value: 'PRESIDENT', text: ManagerPositionName.PRESIDENT },
  { value: 'MANAGER (담당자)', text: ManagerPositionName.MANAGER },
];

export const searchTypeOptions = [
  { label: '전체', value: ProductSearchType.ALL },
  { label: '제품명', value: ProductSearchType.PRODUCT_NAME },
  { label: '국가', value: ProductSearchType.COUNTRY_NAME },
  { label: '제조사', value: ProductSearchType.MANUFACTURER_NAME },
];

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CompanyType } from 'types/company';

export const useManuMode = () => {
  const manuMode = useSelector(
    ({ company }) => company.company?.companyType === CompanyType.MANUFACTURE,
  );
  return manuMode;
};

export const useRetailerMode = () => {
  const location = useLocation();
  const retailerMode = location.pathname.startsWith('/retailer/');

  return retailerMode;
};

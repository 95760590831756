import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import UserNoticeDrawer from 'components/service/UserNoticeDrawer';
import * as serviceActions from 'modules/service';

const UserNoticeDrawerContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, visible, userNotices } = useSelector(
    ({ auth, service }: any) => ({
      userId: auth.user?.userId,
      visible: service.userNoticeDrawerVisible,
      userNotices: service.userNotices,
    }),
    shallowEqual,
  );
  const handleClickUserNotice = (userNotice: any) => {
    if (!userNotice.isConfirm) {
      dispatch(serviceActions.setUserNoticesConfirm([userNotice.userNoticeId]));
    }
    if (userNotice.noticePath) {
      navigate(userNotice.noticePath);
      dispatch(serviceActions.setUserNoticeDrawerVisible(false));
    }
  };
  const confirmAll = () => {
    dispatch(serviceActions.userNoticesConfirmAll(userId));
  };
  const closeUserNoticeDrawer = () => {
    dispatch(serviceActions.setUserNoticeDrawerVisible(false));
  };
  return (
    <UserNoticeDrawer
      visible={visible}
      userNotices={userNotices}
      onClickUserNoticeItem={handleClickUserNotice}
      onClickConfirmAll={confirmAll}
      onCloseUserNoticeDrawer={closeUserNoticeDrawer}
    />
  );
};

export default UserNoticeDrawerContainer;

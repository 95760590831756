import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';

const AlphabetFormItemBlock = styled.div<{ indent: boolean; alphabet: string }>`
  position: relative;
  display: flex;
  transform: ${({ indent }) => (indent ? 'translateX(-16px)' : 'none')};

  &::before {
    content: '${(props) => props.alphabet}.';
    color: ${palette.PRIMARY50};
    font-weight: 500;
    font-size: 18px;
    padding-right: 2px;
  }
`;

const AlphabetLabel = ({
  alphabet,
  indent = true,
  style,
  children,
}: {
  alphabet: string;
  indent?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => {
  return (
    <AlphabetFormItemBlock style={style} indent={indent} alphabet={alphabet}>
      <Typography.Text type="TITLE_2" medium inline>
        {children}
      </Typography.Text>
    </AlphabetFormItemBlock>
  );
};

export default AlphabetLabel;

import { Button, Checkbox, Spin, message } from 'antd';
import { isEmpty, isEqual, isNaN } from 'lodash';
import moment from 'moment';
import {
  ChangeEventHandler,
  DragEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import styled, { css } from 'styled-components';
import { nanoid } from 'nanoid';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { useModal } from 'hook/useModal';
import { messages } from 'lib/consts';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import {
  CompanyDocsDataParmas,
  useCompanyDocsList,
  useGetProductsWithDocs,
} from 'service/brand/company';
import { useGetProductsWithDocsHistory } from 'service/brand/qcqa/qcqa';
import { useUploadFiles } from 'service/common';
import {
  CompanyDocsAttach,
  CompanyDocsList,
  CompanyDocsListParams,
  CompanyDocsUserType,
} from 'types/brand/company';

const Container = styled.div`
  width: 100%;
  position: relative;

  .placeholder {
    position: absolute;
    background-color: white;
    border-bottom: solid 4px ${palette.SLATE_GRAY40};
    transform: translateY(14px);
  }

  .ant-collapse > .ant-collapse-item {
    border: none;
    padding: 24px 36px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0 !important;
  }
  .ant-collapse {
    border: none;
    background-color: transparent;
  }
  .ant-collapse-content {
    border: none;
    background-color: transparent !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    margin-top: 16px;
  }
`;

const HeaderContainer = styled(Flex)``;

const DocsContainer = styled(Flex)`
  padding-top: 24px;
  width: 100%;
`;

const DocsColumnContainer = styled(Flex)`
  margin-bottom: 16px;
  /* padding: 5px; */
  width: 100%;
  position: relative;
`;

const RowContainer = styled(Flex)<{
  isDragging?: boolean;
  visible?: boolean;
  isDraggableIconHover?: boolean;
  fileDragging?: boolean;
}>`
  background-color: ${palette.ETC_WHITE};
  margin: 0 auto !important;
  border-radius: 8px;
  width: 968px;
  border: 1px solid ${palette.SLATE_GRAY30};
  box-shadow: ${({ isDragging }) =>
    !isDragging ? 'none' : `0px 2px 8px rgba(162, 162, 162, 0.23)`};
  transition: box-shadow 300ms ease;
  transition: background-color 300ms ease;
  transition: opacity 300ms ease-out;
  padding: 24px 36px;

  .draggable_icon {
    display: none;
  }

  &:hover {
    .draggable_icon {
      cursor: pointer;
      position: absolute;
      left: 12px;
      display: flex;
      align-items: center;
    }
  }

  ${({ isDraggableIconHover }) =>
    isDraggableIconHover &&
    css`
      transition: display 200ms ease;
      .tooltip {
        display: block;
      }
    `};

  border: 2px dashed transparent;
  transition: border 200ms ease;

  ${({ fileDragging }) =>
    fileDragging &&
    `
    border: 2px dashed ${palette.PRIMARY50};
    background-color: ${palette.PRIMARY10};
  `}
`;

const Tooltip = styled(Flex)`
  display: none;
  font-size: 14px;
  position: absolute;
  left: -168px;
  height: 40px;
  background-color: ${palette.SLATE_GRAY70};
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 400;
  .tooltip_text1 {
    color: white;
  }

  .tooltip_text2 {
    color: ${palette.MESSAGE_WARNING};
    font-weight: 500;
  }
`;

const DocColumnContainer = styled(Flex)`
  position: relative;
  width: 100%;
`;

const DocsColumnHeader = styled(Flex)`
  width: 904px;
`;

const DocTitle = styled(Typography.Text)<{ visible?: boolean }>`
  font-weight: 700;
  font-size: 16px;
  color: ${({ visible }) => (visible ? palette.SLATE_GRAY70 : palette.SLATE_GRAY40)};
  user-select: none;
`;

const FileListContainer = styled(Flex)`
  width: 904px;
  margin-top: 16px;
`;

const FileItemContainer = styled(Flex)`
  width: 100%;
  padding: 8px 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
`;

const CollapseContainer = styled(Flex)<{
  open?: boolean;
  isDragging: boolean;
  visible?: boolean;
  isDraggableIconHover?: boolean;
}>`
  width: 1040px;
  border: 1px solid ${palette.SLATE_GRAY30} !important;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  background-color: ${({ open }) => (open ? `${palette.ETC_BG_BLUE}` : 'white')};
  transition: background-color 300ms ease;
  transition: border 300ms ease;
  box-shadow: ${({ isDragging }) =>
    !isDragging ? 'none' : `0px 2px 8px rgba(162, 162, 162, 0.23)`};
  transition: box-shadow 300ms ease;
  transition: background-color 300ms ease;

  .draggable_icon {
    display: none;
  }

  &:hover {
    .draggable_icon {
      cursor: pointer;
      position: absolute;
      left: 18px;
      display: flex;
      align-items: center;
    }
  }

  ${({ isDraggableIconHover }) =>
    isDraggableIconHover &&
    css`
      transition: display 200ms ease;
      .tooltip {
        display: block;
      }
    `};
`;

const CollapseHeaderContainer = styled(Flex)`
  width: 1040px;
  padding: 24px 36px;
`;

const CollapseBodyContainer = styled(Flex)<{ open: boolean }>`
  width: 100%;
`;

const HistoriesContainer = styled(Flex)`
  width: 100%;
  margin-bottom: 24px;
`;

const ButtonContainer = styled(Flex)`
  margin-top: 20px;
`;

const CertificatonTip = styled(Flex)`
  width: 64px;
  height: 20px;
  background-color: ${palette.YELLOW_20};
  border-radius: 3px;
  color: ${palette.SLATE_GRAY70};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const ProductListContainer = styled.div`
  background-color: ${palette.SLATE_GRAY10};
  padding: 16px 40px;
  text-align: start;
`;

interface DocsColumnProps {
  column?: {
    description?: string;
    id: string;
    relationType: string;
    name: string;
    visible: boolean;
    editable: boolean;
    open?: boolean;
    attachIds: string[];
    historyIds: string[];
    isForCertification?: boolean;
  };
  files?: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  }[];
  histories?: {
    qcqaDocumentRecordId?: string | number;
    name: string;
    recordDate: string;
    attachIds: string[];
  }[];
  index: number;
  docsList: CompanyDocsDataParmas | undefined;
  docItemNameValue: {
    [key: string]: string;
  };
  fileInfoList?: CompanyDocsAttach[];
  activePanelKey?: string;
  isActive?: boolean;
  fileMapBeforeUploading: {
    [key: string]: CompanyDocsAttach[];
  };
  setDocsList: React.Dispatch<React.SetStateAction<CompanyDocsDataParmas | undefined>>;
  setDocItemNameValue: React.Dispatch<React.SetStateAction<{ [columnId: string]: string }>>;
  setFileMapBeforeUploading: React.Dispatch<
    React.SetStateAction<{
      [key: string]: CompanyDocsAttach[];
    }>
  >;
  _ref: (ref: HTMLDivElement | null) => void;
}

const FileItem = ({
  file,
  index,
  onDownload,
  onDelete,
}: {
  file: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  };
  index: number;
  onDownload?: VoidFunction;
  onDelete?: VoidFunction;
}) => {
  return (
    <FileItemContainer justify="space-between">
      <Typography.Text type="SMALL" color="SLATE_GRAY70">
        {file.filename}
      </Typography.Text>
      <Flex columnGap={26}>
        <Typography.Text type="SMALL" color="GRAY50">
          {file.registerDt.slice(0, 10)}
        </Typography.Text>
        <Flex align="center" style={{ height: 18, cursor: 'pointer' }} onClick={onDownload}>
          <Icon name="download" size={16} color="GRAY60" />
        </Flex>
        <Flex align="center" style={{ height: 18, cursor: 'pointer' }} onClick={onDelete}>
          <Icon name="delete" size={18} color="GRAY60" />
        </Flex>
      </Flex>
    </FileItemContainer>
  );
};

const HistoryItem = ({
  column,
  history,
  docsList,
  docHistoryItemNameValue,
  docHistoryDtValue,
  fileInfoList,
  loading,
  preventFileHandling,
  onUpload,
  onDownload,
  onDelete,
  setDocsList,
  setDocHistoryItemNameValue,
  setDocHistoryDtValue,
}: {
  column: {
    id: string;
    relationType: string;
    name: string;
    visible: boolean;
    editable: boolean;
    open?: boolean;
    attachIds: string[];
    historyIds: string[];
  };
  history: {
    qcqaDocumentRecordId?: string | number;
    name: string;
    recordDate: string;
    attachIds: string[];
  };
  files?: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  }[];
  index: number;
  docsList: CompanyDocsDataParmas | undefined;
  docHistoryItemNameValue: {
    [key: string]: string;
  };
  docHistoryDtValue: {
    [key: string]: string;
  };

  fileInfoList?: CompanyDocsAttach[];
  preventFileHandling?: boolean;
  loading?: boolean;
  onUpload: (filesList: FileList | File[], isScreening?: boolean) => void;
  onDownload: (fileIndex: number) => void;
  onDelete: (fileIndex: number) => void;
  setDocsList: React.Dispatch<React.SetStateAction<CompanyDocsDataParmas | undefined>>;
  setDocHistoryItemNameValue: React.Dispatch<React.SetStateAction<{ [htId: string]: string }>>;
  setDocHistoryDtValue: React.Dispatch<React.SetStateAction<{ [htId: string]: string }>>;
}) => {
  const [fileDragState, setFileDragState] = useState<'none' | 'dragging'>('none');
  const [hoverCount, setHoverCount] = useState(0);
  const fileRef = useRef<HTMLInputElement>(null);
  const { openAlertModal, openDocsHistoryModal } = useModal();
  const { getProductsWithDocsHistory } = useGetProductsWithDocsHistory();

  const handleDeleteHistoryItem = () => {
    if (!history) return;

    openAlertModal({
      content: '개정 이력을 삭제 하시겠습니까?',
      onOk: () => {
        setDocsList((list) => {
          if (!list) return;

          const newHistoryIds = [...list.columns[column.id].historyIds];
          const idx = newHistoryIds.indexOf(String(history.qcqaDocumentRecordId));
          newHistoryIds.splice(idx, 1);

          const docs = { ...list };
          if (!history.qcqaDocumentRecordId) return;
          delete docs.histories[history.qcqaDocumentRecordId];

          return {
            ...docs,
            columns: {
              ...docs.columns,
              [column.id]: {
                ...docs.columns[column.id],
                historyIds: newHistoryIds,
              },
            },
          };
        });

        message.success('삭제되었습니다.');
      },
    });

    if (!isNaN(Number(history.qcqaDocumentRecordId))) {
      getProductsWithDocsHistory(
        {
          qcqaUserDocumentId: Number(column.id),
          qcqaRelationDocumentRecordId: Number(history.qcqaDocumentRecordId),
        },
        {
          onSuccess: ({ data: { result } }) => {
            if (result.length > 0) {
              openAlertModal({
                width: 400,
                noCancelButton: true,
                content: (
                  <>
                    <Typography.Text
                      type="BODY_2"
                      style={{ textAlign: 'center', marginBottom: 16 }}
                    >
                      아래 제품에 사용 중인
                      <br />
                      개정 이력이므로 삭제하실 수 없습니다.
                    </Typography.Text>
                    <ProductListContainer>
                      {result.map((product) => (
                        <Typography.Text
                          style={{
                            color: palette.SLATE_GRAY70,
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                          key={product.qcqaProductId}
                        >
                          {`${product.productNameKo || product.productNameEn} : ${product.lotNos
                            .map(({ lotNo }) => lotNo)
                            .join(', ')}`}
                        </Typography.Text>
                      ))}
                    </ProductListContainer>
                  </>
                ),
              });
            }
          },
        },
      );
    }
  };

  useEffect(() => {
    if (!docsList) return;

    const histories = docsList.columns[column?.id]?.historyIds?.map(
      (htId) => docsList.histories[htId],
    );

    const newDocHistoryDtValue = { ...docHistoryDtValue };
    const newDocHistoryItemNameValue = { ...docHistoryItemNameValue };

    for (const history of histories) {
      if (!history?.qcqaDocumentRecordId) return;
      newDocHistoryDtValue[history?.qcqaDocumentRecordId] = history?.recordDate;
      newDocHistoryItemNameValue[history?.qcqaDocumentRecordId] = history?.name;
    }

    setDocHistoryDtValue(newDocHistoryDtValue);
    setDocHistoryItemNameValue(newDocHistoryItemNameValue);
  }, []);

  const handleStopAction = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const referToFile = () => fileRef.current?.click();

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) return;
    onUpload(e.target.files);
    e.target.value = '';
  };

  const handleFileDownload = (fileIndex: number) => () => {
    onDownload(fileIndex);
  };

  const handleFileDelete = (fileIndex: number) => () => onDelete(fileIndex);

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = (e) => {
    setHoverCount((count) => count + 1);
    setFileDragState('dragging');
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1) {
      setFileDragState('none');
    }
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if ([...e.dataTransfer.files].filter((file) => file.type === '').length > 0) {
      message.warning('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    onUpload(e.dataTransfer.files);
  };

  if (history) {
    return (
      <RowContainer
        style={{ padding: '16px 32px', cursor: 'default' }}
        dir="row"
        align="center"
        visible={column?.visible}
        fileDragging={fileDragState === 'dragging'}
        onDragEnter={preventFileHandling ? undefined : handleFileDragEnter}
        onDragLeave={handleFileDragLeave}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={preventFileHandling ? undefined : handleFileDragUpload}
        onClick={handleStopAction}
      >
        <DocColumnContainer dir="column">
          <DocsColumnHeader justify="space-between" align="center">
            <input
              type="file"
              ref={fileRef}
              multiple={true}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
            <Flex gap={8} align="center">
              <Typography.Text
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: palette.SLATE_GRAY60,
                }}
              >
                {history.recordDate.slice(0, 10)}
              </Typography.Text>
              <DocTitle visible={true}>{history.name}</DocTitle>
              <Icon
                name="write"
                size={18}
                color="SLATE_GRAY70"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (!history.qcqaDocumentRecordId) return;
                  handleStopAction(e);
                  openDocsHistoryModal({
                    historyId: String(history.qcqaDocumentRecordId),
                    updateMode: true,
                    docHistoryDtValue: docHistoryDtValue,
                    docHistoryItemNameValue: docHistoryItemNameValue,
                    onSubmit: (itemName, historyDt) => {
                      if (!docsList || !history.qcqaDocumentRecordId) return;
                      setDocsList({
                        ...docsList,
                        histories: {
                          ...docsList.histories,
                          [String(history.qcqaDocumentRecordId)]: {
                            ...docsList.histories[history.qcqaDocumentRecordId],
                            name: itemName,
                            recordDate: historyDt,
                          },
                        },
                      });

                      setDocHistoryItemNameValue({
                        ...docHistoryItemNameValue,
                        [history.qcqaDocumentRecordId]: itemName,
                      });

                      setDocHistoryDtValue({
                        ...docHistoryDtValue,
                        [history.qcqaDocumentRecordId]: historyDt,
                      });
                      message.success('수정되었습니다.');
                    },
                  });
                }}
              />
            </Flex>
            <Flex gap={16}>
              <Flex
                gap={4}
                role="button"
                align="center"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  handleDeleteHistoryItem();
                }}
              >
                <Icon name="delete" size={16} color="SLATE_GRAY70" />
                <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                  삭제
                </Typography.Text>
              </Flex>

              <Flex
                gap={4}
                role="button"
                align="center"
                style={{ cursor: 'pointer' }}
                onClick={preventFileHandling ? undefined : referToFile}
              >
                <Icon name="plus" size={16} color="SLATE_GRAY70" />
                <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                  파일추가
                </Typography.Text>
              </Flex>
            </Flex>
          </DocsColumnHeader>
          {loading && (
            <Flex justify="center" align="center" dir="column" rowGap={4} style={{ width: '100%' }}>
              <LoadingIndicator size="sm" />
              <Typography.Text type="SMALL" color="SLATE_GRAY70" bold>
                파일을 업로드 하는 중...
              </Typography.Text>
            </Flex>
          )}
          {!loading && fileInfoList && fileInfoList?.length > 0 && (
            <FileListContainer dir="column" justify="space-between" gap={8}>
              {fileInfoList.map((file, fileIdx) => {
                return (
                  <FileItem
                    key={file?.attachId}
                    file={file}
                    index={fileIdx}
                    onDownload={handleFileDownload(fileIdx)}
                    onDelete={preventFileHandling ? undefined : handleFileDelete(fileIdx)}
                  />
                );
              })}
            </FileListContainer>
          )}
        </DocColumnContainer>
      </RowContainer>
    );
  } else {
    return <></>;
  }
};

const CollapseColumn = ({
  column,
  histories,
  index,
  docsList,
  docItemNameValue,
  fileMapBeforeUploading,
  setDocsList,
  setDocItemNameValue,
  setFileMapBeforeUploading,
  _ref,
}: DocsColumnProps) => {
  const [isDraggableIconHover, setIsDraggableIconHover] = useState<boolean>();
  const [docHistoryItemNameValue, setDocHistoryItemNameValue] = useState<{
    [htId: string]: string;
  }>({});
  const [uploadingDocumentsMap, setUploadingDocumentsMap] = useState<Record<string, boolean>>({});

  const [docHistoryDtValue, setDocHistoryDtValue] = useState<{
    [htId: string]: string;
  }>({});

  const { openDocsHistoryModal, openDocsItemModal, openAlertModal } = useModal();
  const { uploadFilesAsync } = useUploadFiles();
  const { getProductsWithDocs } = useGetProductsWithDocs();

  useEffect(() => {
    if (!column || !docsList) return;
    const columns = Object.values(docsList.columns);
    const newDocItemNameValue = { ...docItemNameValue };

    for (const item of columns) {
      newDocItemNameValue[item.id] = item.name;
    }

    setDocItemNameValue(newDocItemNameValue);
  }, [docsList]);

  const handleCollapse = () => {
    if (!column || !docsList) return;

    const newColumns = {
      ...docsList.columns,
    };

    for (const id of Object.keys(docsList.columns)) {
      if (id !== column.id) {
        newColumns[id].open = false;
      } else {
        newColumns[column.id].open = !docsList.columns[column.id].open;
      }
    }

    setDocsList({
      ...docsList,
      columns: newColumns,
    });
  };

  const addHistoryItem = (columnId: string) => {
    if (!docsList) return;

    openDocsHistoryModal({
      onSubmit: (itemName, historyDt) => {
        const newHistoryName = nanoid();

        const newHistories = {
          ...docsList.histories,
          [newHistoryName]: {
            qcqaDocumentRecordId: newHistoryName,
            name: itemName,
            recordDate: historyDt,
            attachIds: [],
            qcqaDocumentRecordAttachIds: [],
          },
        };

        docsList.columns[columnId].historyIds.push(newHistoryName);

        setDocHistoryItemNameValue({
          ...docHistoryItemNameValue,
          [newHistoryName]: itemName,
        });

        setDocHistoryDtValue({
          ...docHistoryDtValue,
          [newHistoryName]: historyDt,
        });

        setDocsList({
          ...docsList,
          histories: newHistories,
        });
        message.success('추가되었습니다.');
      },
    });
  };

  const handleVisible = (type: 'off' | 'on') => {
    if (!docsList || !column) return;

    setDocsList({
      ...docsList,
      columns: {
        ...docsList.columns,
        [column.id]: {
          ...docsList.columns[column.id],
          visible: type === 'off' ? false : true,
        },
      },
    });
  };

  const handleDeleteItem = () => {
    if (!column) return;

    if (!isNaN(Number(column.id))) {
      getProductsWithDocs(
        { qcqaUserDocumentId: Number(column.id) },
        {
          onSuccess: ({ data: { result } }) => {
            if (result.length > 0) {
              openAlertModal({
                width: 400,
                noCancelButton: true,
                content: (
                  <>
                    <Typography.Text
                      type="BODY_2"
                      style={{ textAlign: 'center', marginBottom: 16 }}
                    >
                      아래 제품에 사용 중인
                      <br />
                      항목을 삭제하실 수 없습니다.
                    </Typography.Text>
                    <ProductListContainer>
                      {result.map((product) => (
                        <Typography.Text
                          style={{
                            color: palette.SLATE_GRAY70,
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                          key={product.qcqaProductId}
                        >
                          {`${product.productNameKo || product.productNameEn} : ${product.lotNos
                            .map(({ lotNo }) => lotNo)
                            .join(', ')}`}
                        </Typography.Text>
                      ))}
                    </ProductListContainer>
                  </>
                ),
              });
            }
          },
        },
      );
    }

    openAlertModal({
      content: '항목을 삭제 하시겠습니까?',
      onOk: () => {
        setDocsList((list) => {
          if (!list) return;
          const newColumnOrder = [...list.columnOrder];
          const idx = newColumnOrder.indexOf(column.id);
          newColumnOrder.splice(idx, 1);

          const docs = { ...list };
          delete docs.columns[column.id];

          return {
            ...docs,
            columnOrder: newColumnOrder,
          };
        });
        message.success('삭제되었습니다.');
      },
    });
  };

  const handleStopAction = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const now = moment().format('YYYY-MM-DD');

  const handleFileUpload =
    (columnId: string, historyId: string) =>
    async (fileList: FileList | File[], isScreening: boolean = false) => {
      if (uploadingDocumentsMap[historyId]) {
        return;
      }

      setUploadingDocumentsMap((prevMap) => {
        prevMap[historyId] = true;

        return { ...prevMap };
      });

      const files: File[] = [];
      for (const file of fileList) {
        files.push(file);
      }

      if (!(historyId in fileMapBeforeUploading)) {
        fileMapBeforeUploading[historyId] = [];
      }

      const res = await uploadFilesAsync({
        files,
        uploadDestinationType: 'QCQA',
      });
      const fileLists: CompanyDocsAttach[] = [];

      for (const uploadFileRes of res.data.result) {
        fileLists.push({
          ...uploadFileRes,
          qcqaDocumentRecordAttachId: uploadFileRes.attachId,
          registerDt: now,
        });
      }

      setFileMapBeforeUploading((prevMap) => {
        prevMap[historyId] = prevMap[historyId].concat(fileLists);

        return { ...prevMap };
      });

      if (fileLists.length === 0) {
        message.warning(messages.SHOULD_CHECK_FILE);
      } else {
        message.success('정상적으로 업로드되었습니다.');
      }

      setUploadingDocumentsMap((prevMap) => {
        prevMap[historyId] = false;

        return { ...prevMap };
      });

      if (!docsList) return;

      //HINT: 파일 업로드시 files,columns,histories 수정
      setDocsList((prev) => {
        if (!prev) return;
        const newFiles = prev.files;
        const newHistories = prev.histories;

        const newAttachIds = [...(prev.histories[historyId]?.attachIds || [])];
        const newDocumentRecordAttachIds = [
          ...(prev.histories[historyId]?.qcqaDocumentRecordAttachIds || []),
        ];
        const newColumnsAttachIds = [...(prev.columns[columnId].attachIds || [])];

        const newColumnsHistoryIds = [...(prev.columns[columnId].historyIds || [])];

        fileLists.forEach((file) => {
          newAttachIds.push(String(file.attachId));
          newDocumentRecordAttachIds.push(String(file.qcqaDocumentRecordAttachId));
          newColumnsAttachIds.push(String(file.attachId));

          //files에 추가
          newFiles[file.attachId] = {
            attachId: file.attachId,
            filename: file.filename,
            uploadFileUrl: file.uploadFileUrl,
            registerDt: file.registerDt,
            qcqaDocumentRecordAttachId: file.qcqaDocumentRecordAttachId,
          };

          // 기존 histories에 추가
          newHistories[historyId] = {
            qcqaDocumentRecordId: prev.histories[historyId].qcqaDocumentRecordId,
            name: newHistories[historyId].name,
            recordDate: newHistories[historyId].recordDate,
            attachIds: newAttachIds,
            qcqaDocumentRecordAttachIds: newDocumentRecordAttachIds,
          };
        });

        return {
          ...prev,
          files: newFiles,
          histories: newHistories,
          columns: {
            ...prev.columns,
            [columnId]: {
              ...prev.columns[columnId],
              historyIds: newColumnsHistoryIds,
              attachIds: newColumnsAttachIds,
            },
          },
        };
      });
    };

  const handleFileDelete = (columnId: string, historyId: string) => (fileIndex: number) => {
    const fileInfoList =
      fileMapBeforeUploading[isNaN(Number(historyId)) ? historyId : Number(historyId)];

    if (!fileInfoList) return;
    const fileInfo = fileInfoList[fileIndex];

    openAlertModal({
      content: `‘${fileInfo.filename}’ 파일을 삭제하시겠습니까?`,
      okText: '확인',
      closeText: '취소',
      onOk: () => {
        if (!fileInfo.attachId) {
          message.warning('새로고침 후 다시 이용해주세요.');
          return;
        }
        fileInfoList.splice(fileIndex, 1);
        setFileMapBeforeUploading({ ...fileMapBeforeUploading });

        if (!docsList) return;

        setDocsList((list) => {
          if (!list) return;
          const docs = { ...list };
          const newAttachIds = docs.histories[historyId].attachIds;

          const fileIndex = docs.histories[historyId].attachIds.indexOf(String(fileInfo.attachId));
          newAttachIds.splice(fileIndex, 1);

          list.histories[historyId].attachIds = newAttachIds;

          delete docs.files[fileInfo.attachId];

          return docs;
        });

        message.success('삭제되었습니다.');
      },
    });
  };

  const handleFileDownload = (columnId: string, historyId: string) => (fileIndex: number) => {
    const fileInfo = (fileMapBeforeUploading[Number(historyId)] ?? [])[fileIndex];

    if (!fileInfo) return;
    downloadFile(fileInfo.uploadFileUrl, fileInfo.filename);
    message.success('정상적으로 다운로드되었습니다.');
  };

  const handleHideItem = () => {
    if (!column) return;

    getProductsWithDocs(
      { qcqaUserDocumentId: Number(column.id) },
      {
        onSuccess: ({ data: { result } }) => {
          if (result.length > 0) {
            openAlertModal({
              width: 400,
              noCancelButton: true,
              content: (
                <>
                  <Typography.Text type="BODY_2" style={{ textAlign: 'center', marginBottom: 16 }}>
                    아래 제품에 사용 중인
                    <br />
                    항목이므로 숨길 수 없습니다.
                  </Typography.Text>
                  <ProductListContainer>
                    {result.map((product) => (
                      <Typography.Text
                        style={{
                          color: palette.SLATE_GRAY70,
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                        key={product.qcqaProductId}
                      >
                        {`${product.productNameKo || product.productNameEn} : ${product.lotNos
                          .map(({ lotNo }) => lotNo)
                          .join(', ')}`}
                      </Typography.Text>
                    ))}
                  </ProductListContainer>
                </>
              ),
            });
            handleVisible('on');
          } else {
            handleVisible('off');
          }
        },
      },
    );
  };

  const customSort = (
    a: {
      qcqaDocumentRecordId?: string | number | undefined;
      name: string;
      recordDate: string;
      attachIds: string[];
    },
    b: {
      qcqaDocumentRecordId?: string | number | undefined;
      name: string;
      recordDate: string;
      attachIds: string[];
    },
  ) => {
    const dateA = new Date(a.recordDate.slice(0, 10));
    const dateB = new Date(b.recordDate.slice(0, 10));
    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }

    const koreanOrderA = a.name;
    const koreanOrderB = b.name;
    if (koreanOrderA < koreanOrderB) {
      return -1;
    }
    if (koreanOrderA > koreanOrderB) {
      return 1;
    }

    return 0;
  };

  if (column) {
    return (
      <Draggable key={index} draggableId={column?.id} index={index} isDragDisabled={column?.open}>
        {(provided, snapshot) => (
          <CollapseContainer
            open={column.open}
            dir="column"
            align="center"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            visible={column?.visible}
            isDraggableIconHover={isDraggableIconHover}
          >
            <CollapseHeaderContainer
              ref={_ref}
              justify="space-between"
              onClick={() => {
                handleCollapse();
              }}
            >
              <Flex align="center" gap={8}>
                {isDraggableIconHover && (
                  <Tooltip className="tooltip">
                    <span className="tooltip_text1">드래그하여</span>
                    <span className="tooltip_text2"> 순서변경</span>
                  </Tooltip>
                )}
                {!column.open && (
                  <Flex
                    {...provided.dragHandleProps}
                    className="draggable_icon"
                    onMouseEnter={() => setIsDraggableIconHover(true)}
                    onMouseLeave={() => setIsDraggableIconHover(false)}
                    style={{
                      height: '100%',
                      width: 25,
                      left: '0px !important',
                    }}
                  >
                    <Icon name="move" size={18} style={{ position: 'absolute', left: -5 }} />
                  </Flex>
                )}
                <DocTitle style={{ fontWeight: 500 }} visible={column.visible}>
                  {column.name}
                </DocTitle>
                {column.open && column.description && (
                  <div onClick={handleStopAction}>
                    <Tip trigger="click">{column.description}</Tip>
                  </div>
                )}
                {column.open && column.isForCertification && (
                  <CertificatonTip align="center" justify="center" textAlign="center">
                    인증 활용
                  </CertificatonTip>
                )}
                {column?.editable && column.open && (
                  <Icon
                    name="write"
                    size={18}
                    color="SLATE_GRAY70"
                    style={{ cursor: 'pointer' }}
                    onClick={(e: React.MouseEvent) => {
                      handleStopAction(e);
                      openDocsItemModal({
                        columnId: String(column.id),
                        updateMode: true,
                        docItemName: docItemNameValue,
                        onSubmit: (itemName) => {
                          if (!docsList) return;
                          setDocsList({
                            ...docsList,
                            columns: {
                              ...docsList.columns,
                              [column.id]: {
                                ...docsList.columns[column.id],
                                name: itemName,
                              },
                            },
                          });
                          setDocItemNameValue({
                            ...docItemNameValue,
                            [column.id]: itemName,
                          });
                          message.success('수정되었습니다.');
                        },
                      });
                    }}
                  />
                )}
              </Flex>
              <Flex gap={16} align="center">
                {column.open && column.visible && !column.editable && (
                  <Flex
                    gap={4}
                    role="button"
                    align="center"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      handleStopAction(e);

                      handleHideItem();
                    }}
                  >
                    <Icon name="hide" size={16} color="SLATE_GRAY70" />
                    <Typography.Text
                      type="BODY_2"
                      color="SLATE_GRAY70"
                      style={{ userSelect: 'none' }}
                    >
                      숨기기
                    </Typography.Text>
                  </Flex>
                )}
                {!column?.visible && column.open && !column.editable && (
                  <Flex
                    gap={4}
                    role="button"
                    align="center"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      handleStopAction(e);

                      handleVisible('on');
                    }}
                  >
                    <Icon name="show" size={16} color="SLATE_GRAY70" />
                    <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                      보기
                    </Typography.Text>
                  </Flex>
                )}
                {column.open && column.editable && (
                  <Flex
                    gap={4}
                    role="button"
                    align="center"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      handleStopAction(e);

                      handleDeleteItem();
                    }}
                  >
                    <Icon name="delete" size={16} color="SLATE_GRAY70" />
                    <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                      삭제
                    </Typography.Text>
                  </Flex>
                )}
                <Icon name={column.open ? 'up' : 'down'} color="SLATE_GRAY70" />
              </Flex>
            </CollapseHeaderContainer>
            {column.open && (
              <CollapseBodyContainer
                open={column.open}
                dir="column"
                gap={16}
                onClick={() => {
                  handleCollapse();
                }}
              >
                <Button
                  type="dashed"
                  block
                  icon={<Icon name="plus" size={14} color="PRIMARY50" />}
                  style={{
                    width: '968px',
                    height: 44,
                    marginTop: 16,
                    margin: '0 auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={(e) => {
                    handleStopAction(e);
                    addHistoryItem(column.id);
                  }}
                >
                  개정 이력 추가
                </Button>
                <HistoriesContainer dir="column" gap={16}>
                  {histories &&
                    histories.length > 0 &&
                    histories?.sort(customSort)?.map((history, htIdx) => {
                      if (!history?.qcqaDocumentRecordId) return <></>;
                      const files = docsList?.histories[
                        history.qcqaDocumentRecordId
                      ]?.attachIds?.map((id) => docsList.files[id]);

                      const historyId = String(history?.qcqaDocumentRecordId);

                      return (
                        <HistoryItem
                          key={history?.qcqaDocumentRecordId}
                          index={htIdx}
                          history={history}
                          column={column}
                          files={files}
                          docsList={docsList}
                          setDocsList={setDocsList}
                          fileInfoList={fileMapBeforeUploading[history?.qcqaDocumentRecordId]}
                          loading={uploadingDocumentsMap[history?.qcqaDocumentRecordId]}
                          onUpload={handleFileUpload(column?.id, historyId)}
                          onDownload={handleFileDownload(column?.id, historyId)}
                          onDelete={handleFileDelete(column?.id, historyId)}
                          docHistoryItemNameValue={docHistoryItemNameValue}
                          docHistoryDtValue={docHistoryDtValue}
                          setDocHistoryItemNameValue={setDocHistoryItemNameValue}
                          setDocHistoryDtValue={setDocHistoryDtValue}
                        />
                      );
                    })}
                </HistoriesContainer>
              </CollapseBodyContainer>
            )}
          </CollapseContainer>
        )}
      </Draggable>
    );
  } else {
    return <></>;
  }
};

const CompanyDocsListUpdate = ({
  tabKey,
  qcqaUserDocumentId,
  onChangeIsBlockTab,
  companyDocsList,
  isCompanyDocsListLoading,
  isMounted,
  setIsMounted,
}: {
  tabKey: string;
  qcqaUserDocumentId: number;
  onChangeIsBlockTab: React.Dispatch<React.SetStateAction<boolean>>;
  companyDocsList: CompanyDocsList[] | undefined;
  isCompanyDocsListLoading: boolean;
  isMounted: boolean;
  setIsMounted: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { addCompanyDocsList, iaAddCompanyDocsListLoading } = useCompanyDocsList({
    useType: CompanyDocsUserType.COMPANY,
  });

  const refs = useRef<HTMLDivElement[]>([]);
  const [docsList, setDocsList] = useState<CompanyDocsDataParmas | undefined>();
  const [docsCheckList, setDocsCheckList] = useState<CompanyDocsDataParmas | undefined>();
  const [showHideItems, setShowHideItems] = useState<boolean>(true);
  const [placeholderProps, setPlaceholderProps] = useState<{
    clientY: number;
    clientX: number;
    clientHeight: number;
    clientWidth: number;
  }>();
  const [docItemNameValue, setDocItemNameValue] = useState<{
    [columnId: string]: string;
  }>({});
  const [fileMapBeforeUploading, setFileMapBeforeUploading] = useState<
    Record<string, CompanyDocsAttach[]>
  >({});

  const { openDocsItemModal } = useModal();

  useEffect(() => {
    if (!companyDocsList) return;
    docsListToData();
  }, [companyDocsList]);

  useEffect(() => {
    if (!companyDocsList) return;

    const newHistoryMap: Record<string, CompanyDocsAttach[]> = {};

    companyDocsList.forEach((doc) => {
      doc.records?.forEach((history) => {
        if (!history?.qcqaDocumentRecordId) return;
        if (history.attaches) {
          newHistoryMap[history.qcqaDocumentRecordId] = history.attaches;
        } else {
          newHistoryMap[history.qcqaDocumentRecordId] = [];
        }
      });
    });

    setFileMapBeforeUploading(newHistoryMap);
  }, [companyDocsList]);

  const docsListToData = useCallback(() => {
    if (!companyDocsList) return;

    const newFiles = { ...docsList?.files };
    const newHistories = { ...docsList?.histories };
    const newColumns = { ...docsList?.columns };
    const newColumnOrder: string[] = [];

    companyDocsList.forEach((doc) => {
      newColumns[doc.qcqaUserDocumentId] = {
        id: String(doc.qcqaUserDocumentId),
        relationType: doc.relationType,
        name: doc.name,
        description: doc.description,
        visible: doc.isDisplay,
        editable: doc.relationType === 'BASIC' ? false : true,
        isForCertification: doc.isForCertification,
        open: false,
        attachIds: [],
        historyIds:
          doc.records && doc.records.length > 0
            ? doc.records?.map((record) => String(record.qcqaDocumentRecordId))
            : [],
      };

      doc.records?.forEach((history) => {
        if (!history.qcqaDocumentRecordId) return;

        newHistories[history.qcqaDocumentRecordId] = {
          qcqaDocumentRecordId: history.qcqaDocumentRecordId,
          name: history.name,
          recordDate: history.recordDate,
          attachIds: history.attaches?.map((attach) => String(attach.attachId)),
          qcqaDocumentRecordAttachIds: history.attaches?.map((attach) =>
            String(attach.qcqaDocumentRecordAttachId),
          ),
        };

        history?.attaches?.forEach((attach) => {
          newFiles[attach.attachId] = {
            attachId: attach.attachId,
            filename: attach.filename,
            uploadFileUrl: attach.uploadFileUrl,
            registerDt: attach.registerDt,
            qcqaDocumentRecordAttachId: attach.qcqaDocumentRecordAttachId,
          };
        });
      });
      newColumnOrder.push(String(doc.qcqaUserDocumentId));
    });

    const newDocsList = {
      ...docsList,
      columns: newColumns,
      columnOrder: newColumnOrder,
      histories: newHistories,
      files: newFiles,
    };

    setDocsList(newDocsList);
    setDocsCheckList(structuredClone(newDocsList));
  }, [companyDocsList]);

  const addDocsItem = () => {
    if (!docsList) return;

    openDocsItemModal({
      onSubmit: (itemName) => {
        const newDocIdName = `doc-${itemName}_${String(docsList.columnOrder.length) + 1}`;

        const newColumns = {
          ...docsList?.columns,
          [newDocIdName]: {
            id: newDocIdName,
            relationType: 'CUSTOM',
            name: itemName,
            visible: true,
            editable: true,
            open: false,
            attachIds: [],
            historyIds: [],
          },
        };

        setDocItemNameValue({
          ...docItemNameValue,
          [newDocIdName]: itemName,
        });

        setDocsList({
          ...docsList,
          columns: newColumns,
          columnOrder: [...docsList.columnOrder, newDocIdName],
        });
        message.success('추가되었습니다.');
      },
    });
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    if (!docsList) return;

    if (destination.droppableId === source.droppableId && source.index === destination.index)
      return;

    const newDocsColumnOrder = Array.from(docsList.columnOrder);
    const [targetItem] = newDocsColumnOrder.splice(source.index, 1);

    newDocsColumnOrder.splice(destination.index, 0, targetItem);

    setDocsList({
      ...docsList,
      columnOrder: newDocsColumnOrder,
    });
    return;
  };

  const queryAttr = 'data-rfd-drag-handle-draggable-id';

  const onDragStart = (event: any) => {
    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) return;

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;

    const borderHeight = 2;

    let clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom + borderHeight;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const onDragUpdate = (event: any) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const borderHeight = 2;

    let clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);

        const marginBottom = parseFloat(style.marginBottom);

        return total + curr.clientHeight + marginBottom + borderHeight;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const handleSubmit = () => {
    if (checkIsEqaul()) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }
    const docsListData: CompanyDocsListParams[] = [];

    if (!docsList) return;

    Object.entries(docsList.columnOrder).forEach(([key, order]) => {
      const column = docsList.columns[order];
      const { name, visible, historyIds } = column;

      const newRecords: Record<
        string,
        {
          qcqaRelationDocumentRecordId?: number;
          name: string;
          recordDate: string;
          attaches: number[];
        }
      > = {};

      historyIds.forEach((id) => {
        const history = docsList.histories[id];

        if (!history) return;

        newRecords[history.name] = {
          qcqaRelationDocumentRecordId:
            history.qcqaDocumentRecordId !== undefined
              ? Number(history.qcqaDocumentRecordId)
              : undefined,
          name: history.name,
          recordDate: history.recordDate.slice(0, 10),
          attaches: history.attachIds?.map((id) => Number(id)),
        };
      });

      docsListData.push({
        name: name,
        isDisplay: visible,
        displayOrder: Number(key) + 1,
        records: Object.values(newRecords),
        ...(!isNaN(Number(column.id)) && {
          qcqaUserDocumentId: Number(column.id),
        }),
      });
    });

    addCompanyDocsList(docsListData, {
      onSuccess: () => {
        message.success('수정되었습니다.');
        onChangeIsBlockTab(false);
      },
    });
  };

  const checkIsEqaul = () => {
    const docsListCopy = structuredClone(docsList);
    const docsCheckListCopy = structuredClone(docsCheckList);

    if (docsListCopy && docsCheckListCopy) {
      for (const key of Object.keys(docsListCopy.columns)) {
        delete docsListCopy.columns[key].open;
      }

      for (const key of Object.keys(docsCheckListCopy.columns)) {
        delete docsCheckListCopy.columns[key].open;
      }

      return isEqual(docsListCopy, docsCheckListCopy);
    }

    return true;
  };

  useEffect(() => {
    onChangeIsBlockTab(!checkIsEqaul());
  }, [checkIsEqaul()]);

  useEffect(() => {
    if (!qcqaUserDocumentId || isMounted || !docsList) {
      return;
    }

    const index = docsList.columnOrder.findIndex((order) => Number(order) === qcqaUserDocumentId);
    const element = refs.current[index];
    if (element) {
      window.scrollTo({
        // 스크롤 높이 + 요소 위치 - (내비게이션바 높이 80px + margin 16px)
        top: element.getBoundingClientRect().top + window.scrollY - 96,
        behavior: 'smooth',
      });
    }

    const handleCollapse = () => {
      const column = docsList.columns[qcqaUserDocumentId];
      if (!column || !docsList) return;

      const newColumns = {
        ...docsList.columns,
      };
      for (const id of Object.keys(docsList.columns)) {
        if (id !== column.id) {
          newColumns[id].open = false;
        } else {
          newColumns[column.id].open = !docsList.columns[column.id].open;
        }
      }
      setDocsList({
        ...docsList,
        columns: newColumns,
      });
      setIsMounted(true);
    };

    handleCollapse();
  }, [isMounted, docsList]);

  return (
    <Container>
      <HeaderContainer justify="space-between" align="center">
        <Typography.Text style={{ fontSize: 24 }}>회사 서류</Typography.Text>
        <Flex columnGap={8} align="center">
          <Checkbox checked={showHideItems} onChange={(e) => setShowHideItems(e.target.checked)}>
            <Typography.Text type="SMALL" color="SLATE_GRAY70" bold inline>
              숨긴 항목도 보기
            </Typography.Text>
          </Checkbox>
        </Flex>
      </HeaderContainer>
      <Spin
        size="small"
        spinning={tabKey !== CompanyDocsUserType.COMPANY || isCompanyDocsListLoading}
      >
        <DocsContainer>
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
          >
            <DocsColumnContainer dir="column" align="center">
              <Droppable droppableId="all-columns" type="column">
                {(provided, snapshot) => (
                  <Flex
                    dir="column"
                    align="center"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {docsList?.columnOrder.map((columnId, index) => {
                      const column = docsList.columns[columnId];

                      const filteredColumn =
                        docsList.columns[columnId]?.visible === true
                          ? docsList.columns[columnId]
                          : undefined;

                      const files = column?.attachIds.map((fileId) => docsList.files[fileId]);

                      const histories = column?.historyIds?.map((htId) => docsList.histories[htId]);

                      return (
                        <CollapseColumn
                          _ref={(ref: HTMLDivElement | null) => {
                            if (ref) {
                              refs.current[index] = ref;
                            }
                          }}
                          column={!showHideItems ? filteredColumn : column}
                          files={files}
                          histories={histories}
                          key={column?.id}
                          index={index}
                          docsList={docsList}
                          setDocsList={setDocsList}
                          docItemNameValue={docItemNameValue}
                          setDocItemNameValue={setDocItemNameValue}
                          fileInfoList={fileMapBeforeUploading[column?.id]}
                          fileMapBeforeUploading={fileMapBeforeUploading}
                          setFileMapBeforeUploading={setFileMapBeforeUploading}
                        />
                      );
                    })}
                    {provided.placeholder}
                    {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                      <div
                        className="placeholder"
                        style={{
                          top: placeholderProps?.clientY,
                          left: placeholderProps?.clientX,
                          height: placeholderProps?.clientHeight,
                          width: placeholderProps?.clientWidth,
                        }}
                      />
                    )}
                  </Flex>
                )}
              </Droppable>
            </DocsColumnContainer>
          </DragDropContext>
        </DocsContainer>
        <ButtonContainer align="center" gap={58} dir="column">
          <Button
            type="dashed"
            block
            icon={<Icon name="plus" size={14} color="PRIMARY50" />}
            style={{
              width: 520,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={addDocsItem}
          >
            항목 추가
          </Button>
          <Button
            type="primary"
            block
            style={{ width: 200, height: 56, fontSize: 18 }}
            onClick={handleSubmit}
            loading={iaAddCompanyDocsListLoading}
          >
            수정
          </Button>
        </ButtonContainer>
      </Spin>
    </Container>
  );
};

export default CompanyDocsListUpdate;

import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';

const DayLabelBlock = styled.div<{
  selected: boolean;
  opacity?: number;
  disabled?: boolean;
}>`
  display: inline-block;
  min-width: 56px;
  font-size: 14px;
  padding: 3px 0;
  letter-spacing: -0.4px;
  font-weight: 500;
  text-align: center;
  color: ${(props) => (props.selected ? palette.SLATE_GRAY70 : palette.GRAY50)};
  border: 1px solid ${(props) => (props.selected ? palette.PRIMARY50 : palette.GRAY40)};
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? palette.PRIMARY10 : '#f5f5f5')};
  cursor: pointer;

  ${(props) =>
    props.disabled === true &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}

  ${(props) =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `};
`;

const DayLabel = ({
  label,
  disabled,
  initialSelected,
  opacity,
}: {
  label: string;
  disabled?: boolean;
  initialSelected?: boolean;
  opacity?: number;
}) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    if (typeof initialSelected !== 'undefined') {
      setSelected(initialSelected);
    }
  }, [initialSelected]);
  return (
    <DayLabelBlock
      onClick={() => !disabled && setSelected(!selected)}
      selected={selected}
      disabled={disabled}
      opacity={opacity}
    >
      {label}
    </DayLabelBlock>
  );
};

export default DayLabel;

import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { DocumentCode } from 'types/brand/retailer/retailerApply';

const ProductPLIPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="생산물배상책임(PL)보험 "
      notices={[
        '보험증권상의 보장지역 내에서 보험기간 중에 발생된 보험사고로 인하여 타인에게 입힌 손해에 대한 법률상 책임을 보장 받을 수 있어야 합니다.',
        '반드시 보험 기간과 담보 품목이 입점 시점, 입점 품목과 적합한지 확인해주세요. ',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={DocumentCode.PLI}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductPLIPage;

import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { DocumentCode } from 'types/brand/retailer/retailerApply';

const ProductHATRPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="인체적용(임상)시험 결과보고서"
      notices={[
        '해당 서류는 사인 및 도장이 들어간 최종본을 업로드해 주세요.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={DocumentCode.HATR}
        accept="application/pdf"
        max={10}
      />
    </ProductPageTemplate>
  );
};

export default ProductHATRPage;

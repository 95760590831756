export interface APError {
  code: string;
  message: string;
}

export interface APIResponse<TData> {
  errors: APError[];
  result: TData;
}

export interface APIPageableResult<TData> {
  content: TData;
  size: number;
  totalElements: number;
}
export interface APIPageableResponse<TData> {
  errors: APError[];
  result: APIPageableResult<TData>;
}

export interface SavedFile {
  filename: string;
  attachUrl: string;
}

export enum DocumentStatus {
  INP = 'INP',
  CHK = 'CHK',
  OPT = 'OPT',
  MOD = 'MOD',
  ONG = 'ONG',
  FIN = 'FIN',
}

export interface RegisterInquiryParams {
  companyName: string;
  managerName: string;
  managerEmail: string;
  managerPhone: string;
  inquiryContent: string;
  isAgreePrivacyTerms: boolean;
}

export interface EstimateServiceType {
  estimateServiceTypeId: number;
  serviceTypeName: string;
}

export interface AddEstimateInquiryParams {
  companyName: string;
  inquiryUserName: string;
  inquiryUserMobile: string;
  inquiryUserEmail: string;
  skuCount: number;
  inquiryContent?: string;
  isAgreePrivacyTerms: boolean;
  serviceTypes: {
    estimateServiceTypeId: number;
    etcServiceTypeName: string | null;
  }[];
  countryIds?: number[];
}

export interface UploadFile {
  attachId: number;
  filename: string;
  uploadFileUrl: string;
  registerDt: string;
}

export interface UploadBrandFile {
  filename: string;
  url: string;
  size: number;
  updateDt: string;
}

export interface ChatMessage {
  isFinish?: boolean;
  threadRegisterDt?: string;
  userId?: number;
  userType?: 'ADMIN' | 'USER';
  message: string;
  threadMessageRegisterDt: string;
  isRead?: boolean;
  isDelete: boolean;
}

export interface ChatMessageThreadAttaches {
  filename: string;
  fileUrl: string;
  fileSize: number;
  threadAttachRegisterDt: string;
}

export enum DocumentSeqUploadType {
  PRE = 'PRE', // 견적 이전에 업로드해야 하는 문서
  AFT = 'AFT', // 견적 이후에 인증 진행하면서 업로드해야 하는 문서
  ANY = 'ANY', // 견적 이전에 업로드시키지만, 견적 이후에도 가능한 문서
}

export interface DocumentSeq {
  documentSeqId: number;
  documentName: string;
  documentCode: string;
  isRequireUpload: boolean;
  isForChina: boolean;
  isForEu: boolean;
  isIncludeManufacturerList: boolean;
  uploadType: DocumentSeqUploadType;
}

export interface DocumentFieldDetail {
  documentFieldId: number;
  documentSeqId: number;
  code: string;
  name: string;
  isManufacturer: boolean;
  isBrandCompany: boolean;
  isMaterialCompany: boolean;
  displayOrder: number;
  countryCode: string;
}

export interface DocumentField {
  code: string;
  documentFieldId: number;
  documentSeqId: number;
  name: string;
}

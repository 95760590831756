import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { nanoid } from 'nanoid';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import palette from 'lib/styles/palette';
import { RawMaterial } from 'types/material/rawMaterialSearch';

export const StyledTable = styled(Table)<{ isAd?: boolean }>`
  .ant-table-row:hover > .ant-table-cell {
    background-color: ${({ isAd }) => (isAd ? palette.SLATE_GRAY10 : '#fff')};
  }

  .ant-table-body {
    overflow: auto !important;
  }

  .ant-table-row {
    cursor: pointer;

    td.ant-table-cell {
      vertical-align: top;
      letter-spacing: -0.5px;
      word-break: break-all;

      &.text-align-left {
        text-align: left !important;
      }
    }
  }
  .ant-table-expand-icon-col {
    width: 34px;
  }

  tr.ant-table-expanding-row {
    td.ant-table-cell {
      border-bottom: none;

      &.ant-table-cell-ellipsis {
        overflow: visible;
        white-space: normal;
      }

      .keyword-wrap {
        height: auto;
      }

      &.text-align-left {
        text-align: left !important;
      }
    }
  }

  tr.ant-table-expanded-row {
    .ant-table-cell {
      background-color: ${({ isAd }) => (isAd ? palette.SLATE_GRAY10 : '#fff')};
    }
  }

  .keyword-wrap {
    height: 24px;
    overflow: hidden;
  }

  .ant-table-tbody > tr:not(:first-child) > td {
    &:first-child {
      padding: 10px 8px !important;
    }
  }

  ${({ isAd }) =>
    isAd &&
    css`
      .ant-table-row {
        background-color: ${palette.SLATE_GRAY10};
      }
    `}
`;

export const columns: ColumnsType<RawMaterial> = [
  {
    title: '원료명',
    dataIndex: 'materialNameEn',
    className: 'text-align-left',
    width: 220,
    ellipsis: true,
    align: 'center',
    render: (_, { materialNameEn }) => (
      <Typography.Text type="BODY_2" medium inline color="GRAY90">
        {materialNameEn}
      </Typography.Text>
    ),
  },
  {
    title: '원료사',
    dataIndex: 'companyNameKo',
    className: 'text-align-left',
    align: 'center',
    width: 120,
    ellipsis: true,
    render: (_, { companyNameKo }) => (
      <Typography.Text type="BODY_2" medium inline color="GRAY90">
        {companyNameKo}
      </Typography.Text>
    ),
  },
  {
    title: '성분',
    align: 'center',
    width: 80,
    ellipsis: true,
    render: (_, { countOfIngredients }) => (countOfIngredients >= 2 ? '복합' : '단일'),
  },
  {
    title: '효능',
    dataIndex: 'efficacies',
    align: 'center',
    width: 120,
    ellipsis: true,
    render: (_, { efficacies }) => efficacies?.join(', '),
  },
  {
    title: '배합 목적',
    dataIndex: 'formulaPurposes',
    align: 'center',
    width: 120,
    ellipsis: true,
    render: (_, { formulaPurposes }) => formulaPurposes?.join(', '),
  },
  {
    title: '마케팅 키워드',
    dataIndex: 'marketingKeywords',
    align: 'center',
    width: 160,
    ellipsis: true,
    render: (_, { marketingKeywords }) => (
      <Row gutter={[4, 4]} className="keyword-wrap">
        {marketingKeywords?.map((keyword) => (
          <Col key={nanoid()}>
            <Tags.Mark key={nanoid()} color="blue">
              {keyword}
            </Tags.Mark>
          </Col>
        ))}
      </Row>
    ),
  },
];

import { Modal, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { CloseIcon, Typography } from 'components/system';
import { documentNameMap } from 'lib/documentNames';
import palette from 'lib/styles/palette';
import { useProductInputRequest } from 'service/manufacturer/productInputRequest';
import { ProductInputRequestUser } from 'types/manufacturer/product';
import Table from 'components/ui/Table/Table';

const ProductInputRequestModal = ({
  productId,
  documentCode,
  productName,
  onClose,
}: {
  productId: number;
  documentCode: string;
  productName: string;
  onClose: () => void;
}) => {
  const parsedDocumentCode = `${documentCode.toUpperCase()}01`;
  const { users, getLoading, postProductInputRequestLoading, postProductInputRequest } =
    useProductInputRequest({ productId, documentCode: parsedDocumentCode });

  const handleClickInputRequest = (user: ProductInputRequestUser) => {
    const { name, userId: toUserId } = user;
    Modal.confirm({
      width: 420,
      style: { textAlign: 'center', marginTop: 16 },
      icon: null,
      closeIcon: <CloseIcon />,
      content: (
        <p>
          <Typography.Text type="BODY_2" medium inline>
            {name}
          </Typography.Text>
          님에게{' '}
          <Typography.Text type="BODY_2" medium inline>
            {productName}
          </Typography.Text>
          의
          <br />
          <Typography.Text type="BODY_2" medium inline>
            {documentNameMap[parsedDocumentCode]}
          </Typography.Text>{' '}
          입력을 요청하시겠습니까?
        </p>
      ),
      okText: '요청',
      cancelText: '취소',
      onOk: () => {
        postProductInputRequest({ toUserId, onSuccess: onClose });
      },
    });
  };

  const columns: ColumnsType<ProductInputRequestUser> = [
    {
      title: '이름',
      align: 'center',
      dataIndex: 'name',
      render: (name) => {
        return (
          <Typography.Text type="BODY_2" color="GRAY90" medium>
            {name}
          </Typography.Text>
        );
      },
    },
    {
      title: '부서명',
      align: 'center',
      dataIndex: 'deptName',
    },
    {
      title: '직급명',
      align: 'center',
      dataIndex: 'positionName',
    },
    {
      width: 80,
      render: (user) => (
        <Button
          style={{
            maxWidth: 72,
            maxHeight: 32,
            padding: '6px 14px 7px',
            color: palette.GRAY90,
            fontSize: 12,
          }}
          onClick={() => handleClickInputRequest(user)}
        >
          입력 요청
        </Button>
      ),
    },
  ];

  return (
    <Modal visible={documentCode.length > 0} onCancel={onClose} footer={null}>
      <Typography.Text type="TITLE_1">입력 요청 보내기</Typography.Text>
      <Table
        style={{ marginTop: 16 }}
        columns={columns}
        rowKey="userId"
        loading={getLoading || postProductInputRequestLoading}
        pagination={false}
        dataSource={users}
      />
    </Modal>
  );
};

export default ProductInputRequestModal;

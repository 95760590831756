import styled, { css } from 'styled-components';
import { Form, Input } from 'antd';

import { Typography } from 'components/system';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AntdExplainWrapper = styled.div`
  .ant-form-item-explain {
    width: 200px;
    position: absolute;
  }
`;

export const StyledLabelSpaceBetween = styled(StyledRow)`
  justify-content: space-between;
  align-items: center;
`;

export const StyledLabel = styled(Typography.Text)<{ isOnlyCN: boolean }>`
  font-size: 18px;
  margin-bottom: 8px;
  ${({ isOnlyCN }) =>
    !isOnlyCN &&
    css`
      &:before {
        color: #3785f7;
        content: '*';
        font-size: 14px;
        margin-right: 1px;
      }
    `}
`;

export const SelectInput = styled(Input)`
  position: absolute;
  border: none !important;
  width: 96px;
  top: -9px;
  left: -113px;
  height: 40px !important;
  padding: 4px 0 !important;
`;

export const CheckBoxLabel = styled(Typography.Text)`
  display: inline-block;
  font-size: 16px;
`;

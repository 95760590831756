import styled from 'styled-components';

import LoginContainer from 'containers/auth/LoginContainer';
import AdBannerCarousel from 'components/ad/AdBannerCarousel';
import { AdProductCode } from 'types/ad';

const LoginPageBlock = styled.div`
  width: 100%;
  padding-top: 40px;
`;

const LoginPage = () => {
  return (
    <LoginPageBlock>
      <LoginContainer />
      <AdBannerCarousel productCode={AdProductCode.LOGIN} />
    </LoginPageBlock>
  );
};

export default LoginPage;

import styled from 'styled-components';

import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import { DocumentCode } from 'types/product';

const Container = styled.div``;

const ProductImages = () => {
  return (
    <Container>
      <ProductSingleFileContainer documentCode={DocumentCode.PI} accept=".pdf, image/*" />
    </Container>
  );
};

export default ProductImages;

import styled from 'styled-components';

import Typography from 'components/system/general/Typography';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

const RegisterSuccessPageBlock = styled.div`
  padding-top: 40px;
`;

const RegisterSuccessPage = () => {
  return (
    <RegisterSuccessPageBlock>
      <Flex justify="center" align="center" gap={16}>
        <Icon name="check" size={96} color="PRIMARY50" />
        <Typography.Text light type="HEADLINE_1">
          회원가입이 요청되었습니다.
          <br />
          조금만 기다려 주세요.
        </Typography.Text>
      </Flex>
      <Typography.Text
        type="BODY_2"
        color="GRAY70"
        gutter={{ top: 24 }}
        style={{ maxWidth: 260, marginLeft: 'auto', marginRight: 'auto' }}
      >
        관리자 승인 후, 메일로 아이디를 전달드립니다.
        <br /> 아이디는 사업자등록번호로 부여됩니다.
      </Typography.Text>
    </RegisterSuccessPageBlock>
  );
};

export default RegisterSuccessPage;

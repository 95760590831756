import { useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table/interface';

import FooterBox from 'components/FooterBox';
import BuyerAddModal from 'components/modal/buyer/BuyerAddModal';
import { Typography } from 'components/system';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { useCountryNameKo } from 'service/country';
import { useAgencies } from 'service/brand/agency';
import { Agency } from 'types/agency';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import Table from 'components/ui/Table/Table';

const ProductBuyersBlock = styled.div`
  .ant-radio-inner::after {
    transition: none;
  }
  .ant-radio-checked .ant-radio-inner::after {
    display: block;
  }
`;

const ProductAgency = ({ productId, countryId }: { productId: number; countryId: number }) => {
  const { agencies, isLoading } = useAgencies({
    productId,
    countryId,
  });
  const [selectedAgencyIds, setSelectedAgencyIds] = useState<number[]>([]);
  const countryName = useCountryNameKo(countryId);

  const columns = useMemo<ColumnsType<Agency>>(
    () => [
      {
        title: '국가',
        render: () => <Typography.Text medium>{countryName}</Typography.Text>,
      },
      {
        title: '에이전시',
        render: (_, { agency: { companyName } }) => companyName,
      },
      {
        title: '대표',
        render: (_, { agency: { ceoName } }) => ceoName,
      },
    ],
    [countryName],
  );

  useEffect(() => {
    setSelectedAgencyIds(
      agencies.filter((agency) => agency.isSelected).map((agency) => agency.agency.agencyId),
    );
  }, [agencies]);

  return (
    <ProductBuyersBlock>
      <LoadingModal
        visible={isLoading}
        content={`해당 국가에 필요한 문서가 생성 중입니다.\n잠시만 기다려 주세요 :)`}
      />
      <BuyerAddModal countryId={countryId} />
      <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }} style={{ lineHeight: 1 }}>
        에이전시 목록
      </Typography.Text>
      <Table
        className="secondary"
        columns={columns}
        dataSource={agencies}
        rowKey={(agency) => agency.agency.agencyId}
        rowSelection={{
          type: 'checkbox',
          hideSelectAll: true,
          selectedRowKeys: selectedAgencyIds,
          renderCell: (checked) => <Checkbox checked={checked} disabled={true} />,
        }}
        loading={isLoading}
        pagination={false}
      />
      <FooterBox style={{ paddingTop: 24 }}>
        <ReadOnlyBackButton readOnly={true} />
      </FooterBox>
    </ProductBuyersBlock>
  );
};
export default ProductAgency;

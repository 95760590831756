import { useMemo, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import moment from 'moment';

import {
  useProductArtworkScreeningCorrections,
  useProductArtworkScreeningPages,
} from 'service/brand/product/product';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { ProductSplitImages } from 'types/brand/product/artworkScreening';
import AiResultModal from 'components/modal/product/AiResultModal';
import { Flex } from 'components/ui';
import { Typography } from 'components/system';
import ScreeningResultViewer from './ScreeningResultViewer';
import { formatFabricData } from './ScreeningResultViewer.helper';
import Icon from 'components/ui/Icon/Icon';

const Container = styled(Flex)`
  width: 100%;
`;
const AIScreeningWrapper = styled.div`
  width: 100%;
`;
const ButtonWrapper = styled.div`
  margin-top: 56px;
`;

const TextWrapper = styled.div`
  width: 100%;
  margin: 24px 0 0px 0;
`;

const Preview = styled.div`
  width: 920px;
  margin-top: 8px;
  position: relative;
`;

const Navigator = styled.div`
  display: flex;
  align-items: center;

  .material-icons {
    height: 20px;
  }
`;

const FileTextWrapper = styled(Flex)`
  width: 100%;
`;

const MessageWrapper = styled.div`
  font-size: 12px;
  padding: 16px;
  background: ${palette.GRAY10};
  border-radius: 4px;
  margin-top: 8px;

  p {
    margin: 0;
  }
`;

interface UploadedFile {
  url: string;
  name: string;
}

interface LocationState {
  productId: number;
  countryId: number;
}

const ProductArtworkAIScreeningContainer = ({
  enName,
  netWeight,
  newWeightUnit,
}: {
  enName: string;
  netWeight: number;
  newWeightUnit: string;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productId, countryId } = location.state as LocationState;
  const [page, setPage] = useState(1);
  const [selectedSplitImage, setSelectedSplitImage] = useState<ProductSplitImages>();
  const { productArtworkScreeningPages } = useProductArtworkScreeningPages({
    productId,
    countryId,
  });

  const screeningPageData = useMemo(() => {
    if (!productArtworkScreeningPages) return [];
    const newScreeningPageData: {
      filename: string;
      productArtworkScreeningId: number;
      pageNo: number;
    }[] = [];
    for (const pageData of productArtworkScreeningPages.pages) {
      for (const pageNo of pageData.pageNos) {
        newScreeningPageData.push({
          filename: pageData.filename,
          productArtworkScreeningId: pageData.productArtworkScreeningId,
          pageNo,
        });
      }
    }
    return newScreeningPageData;
  }, [productArtworkScreeningPages]);

  const { productArtworkScreeningCorrections } = useProductArtworkScreeningCorrections({
    productId: location?.state?.productId,
    countryId: location?.state?.countryId,
    page: screeningPageData[page - 1]?.pageNo,
    productArtworkScreeningId: screeningPageData[page - 1]?.productArtworkScreeningId,
  });

  const canvasData = useMemo(() => {
    return productArtworkScreeningCorrections
      ? {
          bgImgSrc: productArtworkScreeningCorrections.image.imageUrl,
          fabricData: formatFabricData(productArtworkScreeningCorrections),
        }
      : undefined;
  }, [productArtworkScreeningCorrections]);

  const currentFileName = screeningPageData[page - 1]?.filename || '';
  const totalPages = screeningPageData.length;

  const handleObjectClick = ({
    leftTopX,
    leftTopY,
    rightBottomX,
    rightBottomY,
  }: {
    leftTopX: number;
    leftTopY: number;
    rightBottomX: number;
    rightBottomY: number;
  }) => {
    const splitImages =
      productArtworkScreeningCorrections?.image.splitImages?.filter(
        (item) =>
          (item.drawingElements.length > 0 &&
            JSON.parse(item?.drawingElements[0]?.elementData || '')?.objects?.length > 0) ||
          (item?.imageCorrection !== null && item?.imageCorrection?.correctRequestMessage != null),
      ) || [];

    if (!splitImages?.length) return;

    const selectedSplitImage = splitImages.find(
      (splitImage) =>
        Math.abs(splitImage.leftTopPointX - leftTopX) < 1 &&
        Math.abs(splitImage.leftTopPointY - leftTopY) < 1 &&
        Math.abs(splitImage.rightBottomPointX - rightBottomX) < 1 &&
        Math.abs(splitImage.rightBottomPointY - rightBottomY) < 1,
    );

    setSelectedSplitImage(selectedSplitImage);
  };

  const checkIsFileReadableInPDFViewer = (file: File | string | UploadedFile | null) =>
    typeof file === 'string' ||
    (file && 'url' in file && file.url) ||
    (file instanceof File && file.type === 'application/pdf');

  return (
    <>
      <Container dir="column" align="center">
        <AIScreeningWrapper>
          <Preview>
            <FileTextWrapper
              justify="flex-end"
              align="center"
              style={{ width: '100%', marginBottom: 12 }}
            >
              <Flex align="center">
                <Typography.Text
                  type="BODY_2"
                  style={{
                    color: palette.GRAY70,
                  }}
                >
                  {currentFileName}
                </Typography.Text>
                {checkIsFileReadableInPDFViewer(
                  productArtworkScreeningCorrections?.image.imageUrl || '',
                ) &&
                  totalPages > 1 && (
                    <Navigator>
                      <Icon
                        size={20}
                        name="left"
                        color={page === 1 ? 'GRAY70' : 'GRAY90'}
                        onClick={() => page > 1 && setPage(page - 1)}
                      />
                      <span
                        style={{
                          padding: '0 8px',
                          position: 'relative',
                        }}
                      >
                        {page} / {totalPages}
                      </span>
                      <Icon
                        size={20}
                        name="right"
                        color={page === totalPages ? 'GRAY70' : 'GRAY90'}
                        onClick={() => page < totalPages && setPage(page + 1)}
                      />
                    </Navigator>
                  )}
              </Flex>
            </FileTextWrapper>

            <ScreeningResultViewer data={canvasData} onObjectClick={handleObjectClick} />
          </Preview>
        </AIScreeningWrapper>
        {productArtworkScreeningCorrections?.image?.imageCorrection?.correctRequestMessage && (
          <TextWrapper>
            <Typography.Text type="TITLE_2" gutter={{ bottom: 16 }}>
              보완사항
            </Typography.Text>
            <MessageWrapper>
              {parse(
                productArtworkScreeningCorrections?.image?.imageCorrection?.correctRequestMessage ||
                  '',
              )}
            </MessageWrapper>
          </TextWrapper>
        )}
        <ButtonWrapper>
          <Button
            style={{
              width: '200px',
              marginRight: '8px',
              height: '56px',
              fontSize: '18px',
            }}
            onClick={() =>
              downloadFile(
                productArtworkScreeningPages?.correctionPdfFileUrl,
                `${enName}_${netWeight}${newWeightUnit}_Artwork_AI스크리닝결과_${moment(
                  productArtworkScreeningPages?.correctionRequestDt,
                ).format('YYMMDD')}.pdf`,
              )
            }
          >
            다운로드
          </Button>
          <Button
            style={{ width: '200px', height: '56px', fontSize: '18px' }}
            type="primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            뒤로가기
          </Button>
        </ButtonWrapper>
        {selectedSplitImage && (
          <AiResultModal
            onClose={() => {
              setSelectedSplitImage(undefined);
            }}
            imageUrl={selectedSplitImage.imageUrl}
            angle={selectedSplitImage.imageCorrection?.imageRotationAngle}
            imgCorrection={selectedSplitImage?.imageCorrection}
            drawingElements={selectedSplitImage.drawingElements}
          />
        )}
      </Container>
    </>
  );
};

export default ProductArtworkAIScreeningContainer;

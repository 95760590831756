import { Button, Col, Row } from 'antd';

import { downloadFile } from 'lib/file';
import { contractDocumentKeyNameMap } from 'lib/consts';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import { useContractDocuments } from 'service/estimate';

const ContractDocuments = () => {
  const { contractDocuments } = useContractDocuments();

  return (
    <>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
        서류 다운로드
      </Typography.Text>
      <Row gutter={16} justify="space-between">
        {contractDocuments.map(({ fileKey, filename, attachUrl }: any) => (
          <Col flex="0 0 33.3%" key={fileKey}>
            <Button
              block
              icon={<Icon name="download" size={18} color="PRIMARY50" />}
              onClick={() => downloadFile(attachUrl, filename)}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {contractDocumentKeyNameMap[fileKey as keyof typeof contractDocumentKeyNameMap]}
            </Button>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ContractDocuments;

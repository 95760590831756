import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';
import { Country } from 'types/country';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';

const CountryItemBlock = styled.div<{
  selected: boolean;
  registered: boolean;
  isOriginal?: boolean;
}>`
  display: flex;
  align-items: center;
  border: 1px solid ${palette.GRAY40};
  padding: 11px 16px;
  border-radius: 4px;
  cursor: pointer;
  color: ${palette.GRAY50};

  ${(props) =>
    props.selected &&
    css`
      border-color ${palette.PRIMARY50};
      color: ${palette.GRAY90};
    `}

  ${(props) =>
    props.isOriginal &&
    css`
      border-color: ${palette.GRAY90};
      color: ${palette.GRAY90};
    `}

  ${(props) =>
    props.registered &&
    props.isOriginal &&
    css`
      cursor: default;
      border-color: ${palette.GRAY40};
    `}
  
  &:hover {
    ${(props) =>
      !props.registered &&
      css`
        border-color: ${palette.PRIMARY50};
        box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
      `}
    color: ${palette.GRAY90};
  }

  img.logo {
    width: 20px;
    margin-right: 8px;
  }
`;

const CountryItem = ({
  country,
  selected,
  registered = false,
  onClick,
}: {
  country: Country | { countryNameKo: 'ALL'; countryCode: ''; isOriginal: false };
  selected: boolean;
  registered?: boolean;
  onClick: () => void;
}) => {
  /**
   * HINT
   * selected: 선택 여부
   * isOriginal: 기존에 선택 여부 (DB)
   * registered: 제품이 등록 완료 상태
   */
  const { countryNameKo, countryCode, isOriginal } = country;
  return (
    <CountryItemBlock
      onClick={onClick}
      selected={selected}
      isOriginal={isOriginal}
      registered={registered}
    >
      {countryCode && (
        <img
          src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCode.toLowerCase()}.png`}
          className="logo"
          alt=""
        />
      )}
      <Typography.Text type="BODY_2" style={{ flex: '100%', lineHeight: '20px' }}>
        {countryNameKo}
      </Typography.Text>
      {selected && (!isOriginal || !registered) && (
        <Icon name="close" color="PRIMARY50" size={12} />
      )}
    </CountryItemBlock>
  );
};

export default CountryItem;

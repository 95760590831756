import { APIResponse } from 'types/common';
import {
  CompositionInformation,
  CompositionInformationDraft,
} from 'types/material/compositionInformation';
import client from '../client';

export const getCompositionInformation = (materialId: number) =>
  client.get<APIResponse<CompositionInformation[]>>(`/materials/${materialId}/composition-infos`);

export const uploadCompositionInformation = async ({
  materialId,
  file,
}: {
  materialId: number;
  file: File;
}) =>
  client.post<APIResponse<CompositionInformationDraft>>(
    `/materials/${materialId}/composition-infos`,
    {
      file,
    },
  );

import { Modal, Typography } from 'antd';
import styled from 'styled-components';

import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

export interface StibeePopUpModalProps {
  onClose?: VoidFunction;
}

const StibeePopUpModal = ({ onClose }: StibeePopUpModalProps) => {
  const handleNotShowWeek = (day: 1 | 7) => {
    onClose?.();
    let midnight = new Date();
    midnight.setHours(24 * day, 0, 0, 0);
    window.localStorage.setItem('ST_POP', `${midnight.getTime()}`);
  };

  return (
    <Container
      footer={null}
      open={true}
      width={320}
      centered
      closeIcon={<Icon name="stibeeCloseIcon" size={24} onClick={onClose} />}
      styles={{
        content: { borderRadius: 16, border: 'none' },
        body: {
          padding: '48px 24px 24px',
          backgroundColor: '#9ebaf9',
          borderRadius: '16px',
          textAlign: 'center',
        },
      }}
    >
      <Flex align="center" justify="center" dir="column" gap={24}>
        <Typography.Text
          style={{
            fontSize: 20,
            color: 'white',
            fontWeight: 500,
            lineHeight: 1.45,
          }}
        >
          뷰티업계 소식 등 <span style={{ fontWeight: 700 }}>다양한 정보를</span>
          <br />
          <span style={{ fontWeight: 700 }}>누구보다 빠르게</span> 받아보세요!
        </Typography.Text>
        <Icon name="stibeeIcon" size={116} height={148} />
        <a
          href="https://page.stibee.com/subscriptions/297387"
          target="blank"
          style={{ width: '100%' }}
        >
          <SubscribeBtn>
            <Typography.Text style={{ fontSize: 14, fontWeight: 500, color: 'white' }}>
              구독 하러 가기
            </Typography.Text>
            <Icon name="right" size={18} color="ETC_WHITE" />
          </SubscribeBtn>
        </a>
      </Flex>
      <Footer
        align="center"
        gap={8}
        justify="center"
        style={{ marginTop: 16, cursor: 'pointer' }}
        onClick={() => handleNotShowWeek(7)}
      >
        <CheckIcon name="checkCircleInactived" size={18} color="ETC_WHITE" />
        <CheckHoverIcon
          name="checkCircleActived"
          size={18}
          color="STIBEE_BLUE"
          circleColor="ETC_WHITE"
        />
        <Typography.Text style={{ fontSize: 14, color: 'white' }}>
          일주일 간 보지 않기
        </Typography.Text>
      </Footer>
    </Container>
  );
};

const Container = styled(Modal)`
  .ant-modal-close {
    width: 48px;
    height: 48px;
  }
`;

const CheckIcon = styled(Icon)`
  cursor: pointer;
`;

const CheckHoverIcon = styled(Icon)`
  cursor: pointer;
  display: none;
`;

const Footer = styled(Flex)`
  &:hover {
    ${CheckHoverIcon} {
      display: block !important;
    }
    ${CheckIcon} {
      display: none !important;
    }
  }
`;

const SubscribeBtn = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 44px;
  justify-content: center;
  align-items: center;
  background-color: #2041ba;
  border-radius: 8px;
  color: white;
  transition: all 200ms ease;
  &:hover {
    background-color: #122e96;
  }
`;

export default StibeePopUpModal;

import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import palette from 'lib/styles/palette';

const TooltipChip = ({
  ingredientName,
  color,
  textRect,
  setTextRect,
}: {
  ingredientName: string;
  color: string;
  textRect?: DOMRect;
  setTextRect: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
}) => {
  const [isHover, setIsHover] = useState(false);
  const [hasTooltip, setHasTooltip] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((ref.current?.clientWidth || 0) > 519) {
      setHasTooltip(true);
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      onMouseEnter={() => {
        setIsHover(true);
        setTextRect(ref.current?.getBoundingClientRect());
      }}
      onMouseLeave={() => {
        setIsHover(false);
        setTextRect(undefined);
      }}
    >
      <ClampedChip color={color}>{ingredientName}</ClampedChip>
      {isHover && hasTooltip && textRect && (
        <div
          style={{
            position: 'fixed',
            top: `${(textRect.top || 0) + 32}px`,
            left: `${(textRect.left || 0) + 12}px`,
            maxWidth: 560,
            zIndex: 2,
            paddingTop: 4,
          }}
        >
          <LongTextTooltip
            style={{
              maxWidth: 560,
            }}
          >
            {ingredientName}
          </LongTextTooltip>
        </div>
      )}
    </div>
  );
};

const ClampedChip = styled.div<{ color: string }>`
  border-radius: 20px;
  padding: 4px 9px;
  max-width: 520px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid ${({ color }) => color};
  font-size: 16px;
  background-color: #fff;
`;

const LongTextTooltip = styled.div`
  padding: 2px 8px;
  border-radius: 3px;
  background: ${palette.GRAY30};
  font-size: 12px;
  width: max-content;
  z-index: 2;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
`;

export default TooltipChip;

import { InputNumberProps, InputNumber as AntdInputNumber } from 'antd';
import palette from 'lib/styles/palette';
import { ForwardedRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';

interface Props extends InputNumberProps {
  inputwidth?: number;
}

const InputNumber = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  return <StyledInputNumber {...props} inputwidth={props.inputwidth} />;
};

const StyledInputNumber = styled(AntdInputNumber)<{
  disabled?: boolean;
  inputwidth?: number;
}>`
  .ant-input-number-handler {
    background-color: ${palette.GRAY20};
  }

  &.ant-form-item-has-error,
  &.ant-input-number-focused {
    box-shadow: none !important;
  }

  ${({ inputwidth }) =>
    inputwidth &&
    css`
      .ant-input-number-input {
        max-width: ${inputwidth}px !important;
      }
    `}

  &.ant-input-number .ant-input-number-focused {
    box-shadow: none !important;
  }

  /* antd svg를 숨기고 background-image를 통해 custom */
  .ant-input-number-handler-up-inner {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.87923 4.83748C7.10703 4.60967 7.47638 4.60967 7.70419 4.83748L11.2042 8.33748C11.432 8.56529 11.432 8.93463 11.2042 9.16244C10.9764 9.39024 10.607 9.39024 10.3792 9.16244L7.29171 6.07492L4.20419 9.16244C3.97638 9.39024 3.60703 9.39024 3.37923 9.16244C3.15142 8.93463 3.15142 8.56529 3.37923 8.33748L6.87923 4.83748Z' fill='%237E7E7E'/%3E%3C/svg%3E");
    background-position: center;
    width: 18px;
    height: 18px;
    margin-top: 0;
    right: 0;
    top: 0;
    svg {
      display: none;
    }
    ${({ disabled }) =>
      !disabled
        ? css`
            &:hover {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.87923 4.83748C7.10703 4.60967 7.47638 4.60967 7.70419 4.83748L11.2042 8.33748C11.432 8.56529 11.432 8.93463 11.2042 9.16244C10.9764 9.39024 10.607 9.39024 10.3792 9.16244L7.29171 6.07492L4.20419 9.16244C3.97638 9.39024 3.60703 9.39024 3.37923 9.16244C3.15142 8.93463 3.15142 8.56529 3.37923 8.33748L6.87923 4.83748Z' fill='%233785F7'/%3E%3C/svg%3E") !important;
            }
          `
        : css`
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.87923 4.83748C7.10703 4.60967 7.47638 4.60967 7.70419 4.83748L11.2042 8.33748C11.432 8.56529 11.432 8.93463 11.2042 9.16244C10.9764 9.39024 10.607 9.39024 10.3792 9.16244L7.29171 6.07492L4.20419 9.16244C3.97638 9.39024 3.60703 9.39024 3.37923 9.16244C3.15142 8.93463 3.15142 8.56529 3.37923 8.33748L6.87923 4.83748Z' fill='%23A9A6A6'/%3E%3C/svg%3E") !important;
            cursor: not-allowed;
          `}
  }

  .ant-input-number-handler-down-inner {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.08748 4.83748C3.31529 4.60967 3.68463 4.60967 3.91244 4.83748L6.99996 7.925L10.0875 4.83748C10.3153 4.60967 10.6846 4.60967 10.9124 4.83748C11.1402 5.06529 11.1402 5.43463 10.9124 5.66244L7.41244 9.16244C7.18463 9.39024 6.81529 9.39024 6.58748 9.16244L3.08748 5.66244C2.85967 5.43463 2.85967 5.06529 3.08748 4.83748Z' fill='%237E7E7E'/%3E%3C/svg%3E") !important;
    background-position: center;
    width: 18px;
    height: 18px;
    margin-top: 0;
    right: 0;
    bottom: 0;
    svg {
      display: none;
    }
    ${({ disabled }) =>
      !disabled
        ? css`
            &:hover {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.08748 4.83748C3.31529 4.60967 3.68463 4.60967 3.91244 4.83748L6.99996 7.925L10.0875 4.83748C10.3153 4.60967 10.6846 4.60967 10.9124 4.83748C11.1402 5.06529 11.1402 5.43463 10.9124 5.66244L7.41244 9.16244C7.18463 9.39024 6.81529 9.39024 6.58748 9.16244L3.08748 5.66244C2.85967 5.43463 2.85967 5.06529 3.08748 4.83748Z' fill='%233785F7'/%3E%3C/svg%3E") !important;
            }
          `
        : css`
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.08748 4.83748C3.31529 4.60967 3.68463 4.60967 3.91244 4.83748L6.99996 7.925L10.0875 4.83748C10.3153 4.60967 10.6846 4.60967 10.9124 4.83748C11.1402 5.06529 11.1402 5.43463 10.9124 5.66244L7.41244 9.16244C7.18463 9.39024 6.81529 9.39024 6.58748 9.16244L3.08748 5.66244C2.85967 5.43463 2.85967 5.06529 3.08748 4.83748Z' fill='%23A9A6A6'/%3E%3C/svg%3E") !important;
            cursor: not-allowed;
          `}
  }

  .ant-input-number-handler-wrap {
    border-left: none;
    width: 18px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: transparent;
    justify-content: center;
    margin-right: 2px;
  }

  .ant-input-number-handler-up {
    border-top: none;
    border-radius: 2px;
    height: 18px !important;

    &:hover {
      height: 18px !important;
    }
  }

  .ant-input-number-handler-down {
    border-top: none;
    border-radius: 2px;
    height: 18px !important;

    &:hover {
      height: 18px !important;
    }
  }
`;

export default forwardRef<HTMLInputElement, Props>(InputNumber);

import { Form, Typography, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'lodash';

import { messages, scrollToFirstErrorOption } from 'lib/consts';
import * as authApi from 'lib/api/auth';
import * as companyApi from 'lib/api/company';
import path from 'lib/path';
import { getCompany } from 'modules/company';
import { useUser } from 'service/auth';
import { useCompany } from 'service/company';
import { UserType } from 'types/auth';
import { useMaterialCompanies } from './company';
import { useModal } from 'hook/useModal';
import { isObjectChanged } from 'lib/form';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { setUser } from 'modules/auth';

export const useMaterialRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState<'company' | 'user'>('company');
  const [form] = Form.useForm();
  const user = useUser();
  const company = useCompany();
  const { openChangePasswordModal, openAlertModal } = useModal();
  const { isUseOfLogoAgreed } = useSelector(({ auth }: any) => auth.register);
  const updateMode = user !== null && company !== null;
  const { data: materials = [] } = useMaterialCompanies({
    isExcludeUserInserted: true,
    enabled: !updateMode,
  });
  const { data: factories } = useQuery(
    'company/getFactories',
    () => companyApi.getMaterialFactories(company.companyId),
    { select: (res) => res.data.result, enabled: updateMode },
  );
  const { mutate: registerMaterial, isLoading: registerLoading } = useMutation(
    async ({ companyForm, userForm, factories }: any) => {
      try {
        const res = await companyApi.registerCompany({
          ...companyForm,
          ...userForm,
          userType: UserType.MATERIAL,
          isServiceTermsAgreed: true,
          isPrivacyTermsAgreed: true,
          isUseOfLogoAgreed,
        });
        const companyId = res.data.result.companyId;
        const addingFactories = factories.filter(
          ({ addressKo, addressEn }: any) => addressKo && addressEn,
        );
        if (addingFactories.length > 0) {
          await companyApi.registerMaterialFactory(
            factories.map((factory: any, index: number) => ({
              ...factory,
              companyId,
              seq: index + 1,
            })),
          );
        }
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        navigate(path.register.success);
      },
    },
  );
  const { mutate: updateMaterial, isLoading: updateLoading } = useMutation(
    async ({ companyForm, userForm, factories: newFactories }: any) => {
      await companyApi.updateCompany(companyForm);
      const sequencedFactories = newFactories.map((newFactory: any, index: number) => ({
        ...newFactory,
        seq: index + 1,
      }));
      const addingFactories = sequencedFactories.filter(
        ({ materialFactoryId, addressKo, addressEn }: any) =>
          !materialFactoryId && addressKo && addressEn,
      );
      if (addingFactories.length > 0) {
        await companyApi.registerMaterialFactory(
          addingFactories.map((factory: any) => ({
            ...factory,
            companyId: company.companyId,
          })),
        );
      }
      const deletingFactoryIds = factories
        .filter(({ materialFactoryId }: any) => {
          const target = sequencedFactories.find(
            (newFactory: any) => newFactory.materialFactoryId !== materialFactoryId,
          );
          return !target || (!target.addressKo && !target.addressEn);
        })
        .map(({ materialFactoryId }: any) => materialFactoryId);
      if (deletingFactoryIds.length > 0) {
        await companyApi.deleteMaterialFactory(deletingFactoryIds);
      }
      const updatingFactories = sequencedFactories.filter(
        ({ materialFactoryId, addressKo, addressEn, seq }: any) => {
          if (!materialFactoryId) return false;
          const correspondingFactory = factories.find(
            (factory: any) => materialFactoryId === factory.materialFactoryId,
          );
          if (
            addressKo === correspondingFactory.addressKo &&
            addressEn === correspondingFactory.addressEn &&
            seq === correspondingFactory.seq
          ) {
            return false;
          }
          return true;
        },
      );

      if (updatingFactories.length) {
        await companyApi.updateMaterialFactory({
          companyId: company.companyId,
          factories: updatingFactories,
        });
      }

      return await authApi.updateUser(userForm);
    },
    {
      onSuccess: (res) => {
        dispatch(getCompany(company.companyId));
        dispatch(setUser(res.data.result));
        message.success('수정되었습니다.');
      },
    },
  );

  const handleClickChangePassword = () => {
    openChangePasswordModal({});
  };

  const handleClickNext = () => {
    form
      .validateFields()
      .then(() => {
        form.setFieldsValue({
          username: form.getFieldValue('bizNumber').replaceAll('-', ''),
        });
        setTab('user');
      })
      .catch((error) => {
        form.scrollToField(error.errorFields[0].name[0], scrollToFirstErrorOption);
      });
  };

  const handleClickBack = () => {
    setTab('company');
  };

  const handleSubmit = (formData: any) => {
    // 회사 정보
    const material = materials.find(
      ({ companyNameKo }) => companyNameKo === formData.companyNameKo,
    );
    const companyForm = {
      ...(updateMode && { companyId: company.companyId }),
      companyNameKo:
        formData.companyNameKo === 'companyNameKoDirect'
          ? formData.companyNameKoDirect
          : formData.companyNameKo,
      companyNameEn: formData.companyNameEn,
      ...(material?.materialCompanyId && {
        materialCompanyId: material.materialCompanyId,
      }),
      bizNumber: formData.bizNumber,
      ceoNameKo: formData.ceoNameKo,
      ceoNameEn: formData.ceoNameEn,
      addressKo: formData.addressKo,
      addressEn: formData.addressEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(!updateMode ? { companyEmail: formData.companyEmail } : { email: formData.companyEmail }),
      ...(formData.mainItem && { mainItem: formData.mainItem }),
      ...(formData.introduction && { introduction: formData.introduction }),
      isServiceTermsAgreed: true,
      isPrivacyTermsAgreed: true,
      companyType: 'MATERIAL',
      ...(!updateMode && {
        bizLicenseFile: formData.bizLicenseFile,
        bizLicenseEnFile: formData.bizLicenseEnFile,
      }),
    };
    // 공장 정보
    const formFactories = formData.factories;
    //유저 정보
    const userForm = {
      ...(updateMode && { userId: user.userId }),
      ...(!updateMode && { isSuperUser: true }),
      username: formData.bizNumber.replace(/-/g, ''),
      password: formData.password,
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
      userType: 'MATERIAL',
    };
    if (!updateMode) {
      // 등록
      registerMaterial({ companyForm, userForm, factories: formFactories });
    } else {
      // 수정

      const originFormData = {
        company: {
          addressEn: company.addressEn,
          addressKo: company.addressKo,
          ceoNameEn: company.ceoNameEn,
          ceoNameKo: company.ceoNameKo,
          tel: company.tel,
          fax: company.fax,
          email: company.email,
          mainItem: company.mainItem,
          introduction: company.introduction,
        },
        user: pick(user, ['deptName', 'name', 'positionName', 'email', 'mobile']),
        factories,
      };

      const updateFormData = {
        company: {
          addressEn: formData.addressEn,
          addressKo: formData.addressKo,
          ceoNameEn: formData.ceoNameEn,
          ceoNameKo: formData.ceoNameKo,
          tel: formData.tel,
          fax: formData.fax,
          email: formData.companyEmail,
          mainItem: formData.mainItem,
          introduction: formData.introduction,
        },
        user: pick({ ...userForm }, ['deptName', 'name', 'positionName', 'email', 'mobile']),
        factories: formData.factories.filter((item: any) => Object.keys(item).length > 0),
      };

      if (
        tab === 'company'
          ? !isObjectChanged({
              object1: _.omit(originFormData, 'user'),
              object2: _.omit(
                {
                  ...updateFormData,
                  factories: updateFormData.factories.filter((item: any) => item),
                },
                'user',
              ),
            })
          : !isObjectChanged({
              object1: originFormData.user,
              object2: updateFormData.user,
            })
      ) {
        return message.warning(messages.NO_NEED_TO_UPDATE);
      }

      openAlertModal({
        content: (
          <Typography.Text style={{ fontSize: '14px' }}>정말로 수정하시겠습니까?</Typography.Text>
        ),
        onOk: () => {
          updateMaterial({ companyForm, userForm, factories: formFactories });
        },
      });
    }
  };

  useEffect(() => {
    if (updateMode) {
      form.setFieldsValue({
        ...company,
        companyEmail: company.email,
        ...(company.bizLicenseUrl && {
          bizLicenseFile: { name: 'Uploaded File', url: company.bizLicenseUrl },
        }),
        ...(company.bizLicenseEnUrl && {
          bizLicenseEnFile: {
            name: 'Uploaded File',
            url: company.bizLicenseEnUrl,
          },
        }),
        ...user,
      });
    }
  }, []);

  useEffect(() => {
    if (factories) {
      form.setFieldsValue({
        factories: factories.length > 0 ? factories : [{}],
      });
    }
  }, [factories]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        companyNameKo: '씨디알아이',
        companyNameEn: 'CDRI',
        addressKo: '서울시 역삼동',
        addressEn: 'Jongro-gu, Seoul',
        factoryAddresses: [{ addressKo: '서울시 역삼동', addressEn: 'Jongro-gu, Seoul' }],
        ceoNameKo: 'cdri',
        ceoNameEn: 'cdri',
        tel: '0211112222',
        fax: '0211112222',
        majorProducts: '기타 등등1',
        description: '기타 등등2',
        bizNumber: '111-11-11111',
        password: '1111',
        passwordConfirm: '1111',
        name: 'CDRI',
        deptName: '서비스개발팀',
        positionName: '개발자',
        companyEmail: 'cdri@cdri.pro',
        email: 'cdri@cdri.pro',
        mobile: '01012345678',
        isEmailVerified: false,
        isPhoneVerified: false,
        isSmsAgreed: true,
        isEmailAgreed: true,
      });
    }
  }, []);

  return useMemo(
    () => ({
      tab,
      updateMode,
      materials,
      form,
      bizLicenseEnUrl: company?.bizLicenseEnUrl,
      submitLoading: registerLoading || updateLoading,
      setTab,
      handleClickChangePassword,
      handleClickNext,
      handleClickBack,
      handleSubmit,
    }),
    [tab, materials, updateMode, form, registerLoading, updateLoading],
  );
};

import { memo } from 'react';
import { Form, Input } from 'antd';

import { exceptKoreanRule, requireRule } from 'lib/validate';
import styled from 'styled-components';
import AlphabetLabel from './AlphabetLabel';

const MSDSFirefightingBlock = styled.div`
  width: 536px;
  padding-left: 18px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSFirefighting = () => {
  return (
    <MSDSFirefightingBlock>
      <Form.Item
        label={<AlphabetLabel alphabet="A">Suitable extinguishing media</AlphabetLabel>}
        name={['fireFighting', 'suitableExtinguishingMedia']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Any appropriate method to put out ordinary fire."
      >
        <Input.TextArea
          placeholder="적절한 소화제"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="Extinguishing media which shall not be used for safety reasons"
        name={['fireFighting', 'extinguishingMediaSafetyReasons']}
        rules={[requireRule, exceptKoreanRule]}
        initialValue="For this substance/mixture no limitations of extinguishing agents are given."
      >
        <Input.TextArea
          placeholder="부적절한 소화제"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="B">Specific hazards arising from the chemical</AlphabetLabel>
        }
        name={['fireFighting', 'specificHazardsChemical']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="None"
      >
        <Input.TextArea
          placeholder="화학물질로부터 생기는 특정 유해성"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={
          <AlphabetLabel alphabet="C">Special protective actions for fire-fighters</AlphabetLabel>
        }
        name={['fireFighting', 'specialProtectiveActions']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="None"
      >
        <Input.TextArea
          placeholder="화재 진압 시 착용할 보호구 및 예방조치"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
    </MSDSFirefightingBlock>
  );
};

export default memo(MSDSFirefighting);

import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { DocumentCode } from 'types/brand/retailer/retailerApply';
import { Typography } from 'components/system';

const ProductKARTPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="표시기재 파일 (국문 아트웍)"
      notices={[
        <Typography.Text type="BODY_2">
          제품에 최종 인쇄할 디자인 도면 (PDF) 으로 제품의 패키지를 전부 업로드해 주세요.
          <br />
          예시) 캡, 튜브, 개봉방지스티커, 라벨, 띠지, 삽지 등 제품에 인쇄된 모든 디자인 데이터
        </Typography.Text>,
        <Typography.Text type="BODY_2">
          <Typography.Text type="BODY_2" color="MESSAGE_ERROR" inline bold>
            반드시 국문, PDF 파일
          </Typography.Text>
          로 업로드 바랍니다. 최대 10개의 파일까지 업로드가 가능합니다.
        </Typography.Text>,
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={DocumentCode.KART}
        max={10}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductKARTPage;

import { useParams } from 'react-router-dom';

import ICIDDetail from 'components/material/icid/ICIDDetail';
import ICIDPageTemplate from './ICIDPageTemplate';

const ICIDDetailPage = () => {
  const params = useParams<{ icidApplicationId: string }>();
  const icidApplicationId = Number(params.icidApplicationId);

  return (
    <ICIDPageTemplate
      subtitle="세부 정보"
      notices={[
        '필수로 입력하셔야 하는 항목을 다 입력하셔야 등록이 가능하며, 그 전에는 임시저장이 가능합니다.',
        '모든 항목은 PCPC Application에 직접적으로 기재될 부분으로 반드시 영문으로 작성해주세요.',
      ]}
    >
      <ICIDDetail icidApplicationId={icidApplicationId} />
    </ICIDPageTemplate>
  );
};

export default ICIDDetailPage;

import { Button, Col, Row, Statistic, Typography as Typo } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import StatisticCard from 'components/StatisticCard';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import palette from 'lib/styles/palette';

const ProductDetailBlock = styled.div``;

const ProductDetail = ({
  manuMode,
  product,
  documents,
  fetchLoading,
  registerLoading,
  registerable,
  onClickRegister,
  onClickDocument,
}: any) => {
  const columns: ColumnsType<any> = useMemo(
    () => [
      { title: '구분', dataIndex: 'no', align: 'center' },
      {
        title: '입력정보',
        render: (document) => (
          <Flex align="center" gap={8}>
            <Typography.Text
              type="BODY_2"
              style={{
                fontWeight: 500,
                cursor: 'pointer',
                color: palette.GRAY90,
              }}
              onClick={() => onClickDocument(document)}
            >
              {document.documentName}
            </Typography.Text>
            {document.isPermit === false && <Icon name="lock" size={16} color="GRAY70" />}
          </Flex>
        ),
      },
      {
        title: '처리현황',
        align: 'center',
        render: (document) => {
          if (document.status === 'INP' || document.status === 'OPT') {
            return (
              <Tags.Mark
                type="arrow"
                color="blue"
                onClick={() => {
                  onClickDocument(document);
                }}
              >
                {document.status === 'INP' ? '필수 입력' : '선택 입력'}
              </Tags.Mark>
            );
          } else {
            return <Typo.Text>{document.statusString}</Typo.Text>;
          }
        },
      },
      {
        title: '최근 입력자 명',
        align: 'center',
        render: (document) =>
          document.modifierName ? `${document.modifierName} (${document.modifierCompanyName})` : '',
      },
      {
        title: '입력 일시',
        align: 'center',
        render: (document) =>
          document.modifierName && document.updateDate ? document.updateDate : '',
      },
    ],
    [],
  );
  return (
    <ProductDetailBlock>
      {!manuMode && (
        <StatisticCard>
          <Row wrap={false} gutter={24}>
            <Col span={9}>
              <Statistic
                title="제품명 (용량)"
                value={`${product.productDetail.productNameEn || '-'} ${
                  product.netWeight ? `(${product.netWeight} ${product.netWeightUnit})` : '( - )'
                }`}
              />
            </Col>
            <Col span={8}>
              <Statistic title="국문 제품명" value={product.productDetail.productNameKo || '-'} />
            </Col>
            <Col span={7}>
              <Statistic title="제조사" value={product.productDetail.manufacturerNameKo} />
            </Col>
          </Row>
        </StatisticCard>
      )}
      <Table
        size="small"
        columns={columns}
        rowKey={(row) => row.documentCode}
        dataSource={documents}
        pagination={false}
        loading={fetchLoading}
        style={{ marginTop: '30px' }}
      />
      {registerable && (
        <FooterBox>
          <Button type="primary" loading={registerLoading} onClick={onClickRegister}>
            등록 완료
          </Button>
        </FooterBox>
      )}
    </ProductDetailBlock>
  );
};

export default ProductDetail;

import { Descriptions, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import FormulaScreeningCountryProhibitInfosModal from 'components/brand/formulaScreening/FormulaScreeningCountryProhibitInfosModal';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import { countryImages } from 'lib/consts';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { RootState } from 'modules';
import { deleteIngredientToBox } from 'modules/material/ingredient';
import { useCompareIngredients } from 'service/material/ingredient';
import { CountryLawRegulation } from 'types/material/ingredient';

const IngredientCompareBox = () => {
  const { pathname } = useLocation();

  const { ingredientIds } = useSelector(
    ({ ingredient }: RootState) => ({
      ingredientIds: ingredient.ingredientIds,
    }),
    shallowEqual,
  );

  const { openConfirmModal, closeConfirmModal } = useModal();

  useEffect(() => {
    if (ingredientIds.length === 0) {
      closeConfirmModal();
    }
  }, [ingredientIds]);

  if (
    pathname !== path.material.ingredient.dictionary &&
    !pathname.startsWith(path.material.ingredient.detail)
  ) {
    return null;
  }

  return (
    <Container
      active={ingredientIds.length > 0}
      onClick={() => {
        if (ingredientIds.length === 0) return;
        openConfirmModal({
          title: '성분 비교하기',
          width: 800,
          content: <IngredientCompareModalContent />,
          footer: null,
          bodyStyle: {
            maxHeight: 499,
            overflowY: 'scroll',
          },
        });
      }}
    >
      <Icon name="check" size={18} color="ETC_WHITE" />
      <Typography.Text medium color="ETC_WHITE" type="TITLE_2">
        성분 비교하기 ({ingredientIds.length}/3)
      </Typography.Text>
    </Container>
  );
};

const Container = styled(Flex)<{ active: boolean }>`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 57px;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${({ active }) =>
    active
      ? css`
          background: ${palette.PRIMARY50};
          cursor: pointer;
        `
      : css`
          background: ${palette.SLATE_GRAY40};
          cursor: not-allowed;
        `};
`;

const IngredientCompareModalContent = () => {
  const { ingredientIds } = useSelector(
    ({ ingredient }: RootState) => ({
      ingredientIds: ingredient.ingredientIds,
    }),
    shallowEqual,
  );
  const [selectedCountryCode, setSelectedCountryCode] = useState<string | null>(null);
  const [selectedCountryInfos, setSelectedCountryInfos] = useState<CountryLawRegulation[]>([]);

  const dispatch = useDispatch();
  const { compareIngredientItems, getCompareIngredientItemsLoading } =
    useCompareIngredients(ingredientIds);

  const handleCloseCountryInfosModal = () => {
    setSelectedCountryCode(null);
    setSelectedCountryInfos([]);
  };

  const handleClickCountry = (
    formulaCountryInfos: CountryLawRegulation[],
    countryCode?: string,
  ) => {
    if (countryCode) {
      setSelectedCountryCode(countryCode);
    }
    setSelectedCountryInfos(formulaCountryInfos);
  };

  return (
    <ModalContentContainer>
      {/* 국가 성분 규제 모달 */}
      {selectedCountryInfos.length > 0 && (
        <FormulaScreeningCountryProhibitInfosModal
          defaultCountryCode={selectedCountryCode || undefined}
          countryInfos={selectedCountryInfos}
          onClose={handleCloseCountryInfosModal}
          maskStyle={{
            background: 'transparent',
          }}
        />
      )}
      <Spin spinning={getCompareIngredientItemsLoading}>
        <Descriptions column={1} bordered>
          <StyledDesItem label="국문명">
            <Flex align="stretch">
              {compareIngredientItems.map((item, idx) => (
                <FlexItem key={idx}>
                  {item && (
                    <Flex
                      justify="space-between"
                      align="start"
                      gap={8}
                      style={{ width: '100%', height: '100%' }}
                    >
                      <Typography.Text align="left" type="BODY_2" color="SLATE_GRAY70">
                        {item.name.name}
                      </Typography.Text>
                      <div
                        style={{
                          padding: 4,
                        }}
                      >
                        <Icon
                          name="smallClose"
                          size={12}
                          onClick={() => {
                            dispatch(
                              deleteIngredientToBox(
                                ingredientIds.findIndex((id) => id === item.ingredientId),
                              ),
                            );
                          }}
                        />
                      </div>
                    </Flex>
                  )}
                </FlexItem>
              ))}
            </Flex>
          </StyledDesItem>
          <StyledDesItem label="INCI Name">
            <Flex align="stretch">
              {compareIngredientItems.map((item, idx) => (
                <FlexItem key={idx}>
                  {item && (
                    <Typography.Text align="left" type="BODY_2" color="SLATE_GRAY70">
                      {item.inciName.map((item) => item.name).join(', ')}
                    </Typography.Text>
                  )}
                </FlexItem>
              ))}
            </Flex>
          </StyledDesItem>
          <StyledDesItem label="Function">
            <Flex align="stretch">
              {compareIngredientItems.map((item, idx) => (
                <FlexItem key={idx}>
                  {item && (
                    <Flex align="center" wrap="true" gap={8} style={{ width: '100%' }}>
                      {item.functions.map(({ ingredientFunctionId, function: functionName }) => (
                        <Flex
                          style={{
                            background: palette.PRIMARY30,
                            borderRadius: 3,
                            padding: '2px 8px',
                            maxWidth: '100%',
                          }}
                          key={ingredientFunctionId}
                        >
                          <Typography.Text style={{ fontSize: 12, fontWeight: 600 }}>
                            {functionName}
                          </Typography.Text>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </FlexItem>
              ))}
            </Flex>
          </StyledDesItem>
          <StyledDesItem label="EWG">
            <Flex align="stretch">
              {compareIngredientItems.map((item, idx) => (
                <FlexItem key={idx}>
                  {item && item.ewg && (
                    <Flex align="center" gap={8} style={{ width: '100%' }}>
                      <EWG scoreMax={item.ewg.scoreMax}>
                        {item.ewg.scoreMin === item.ewg.scoreMax
                          ? item.ewg.scoreMax
                          : `${item.ewg.scoreMin}-${item.ewg.scoreMax}`}
                      </EWG>
                    </Flex>
                  )}
                </FlexItem>
              ))}
            </Flex>
          </StyledDesItem>
          <StyledDesItem label="국가 규제사항">
            <Flex align="stretch">
              {compareIngredientItems.map(({ countryLawRegulations, ingredientId }) => (
                <FlexItem key={ingredientId}>
                  {countryLawRegulations && countryLawRegulations.length > 0 && (
                    <Flex align="center" wrap="true" gap={8} style={{ width: '100%' }}>
                      {countryLawRegulations
                        .reduce((acc, cur) => {
                          if (!acc.find((item) => item.code === cur.country.code)) {
                            acc.push({
                              nameKo: cur.country.nameKo,
                              code: cur.country.code,
                            });
                          }
                          return acc;
                        }, [] as { nameKo: string; code: string }[])
                        .map(({ code, nameKo }) => (
                          <img
                            key={code}
                            src={countryImages[nameKo]}
                            alt={code}
                            style={{
                              width: 20,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleClickCountry(countryLawRegulations, code);
                            }}
                          />
                        ))}
                    </Flex>
                  )}
                </FlexItem>
              ))}
            </Flex>
          </StyledDesItem>
        </Descriptions>
      </Spin>
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  .ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content,
  .ant-descriptions-bordered .ant-descriptions-row {
    border-color: ${palette.GRAY30};
  }

  .ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view {
    border-left: none;
    border-right: none;
    border-top: 2px solid ${palette.PRIMARY50};
    border-radius: 0;
  }

  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-label {
    padding: 10px 16px;
    width: 168px;
    font-weight: 500;
    font-size: 14px;
    color: ${palette.SLATE_GRAY70};
    vertical-align: top !important;
  }

  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-content {
    padding: 0;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: ${palette.SLATE_GRAY70};
  }
`;

const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 0 0 184px;
  max-width: 184px;
  padding: 12px 16px;
  min-height: 43px;

  :not(:nth-child(3)) {
    border-right: 1px solid ${palette.GRAY30};
  }
`;

const StyledDesItem = styled(Descriptions.Item)``;

const EWG = styled.div<{ scoreMax: number }>`
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: 6px 12px 12px 12px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;

  ${({ scoreMax }) =>
    scoreMax <= 2
      ? css`
          background: #00b32d;
        `
      : scoreMax <= 6
      ? css`
          background: #fdb900;
        `
      : css`
          background: #fb2f2f;
        `};
`;

export default IngredientCompareBox;

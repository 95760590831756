import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Empty } from 'antd';

import { fileToBase64 } from 'lib/file';
import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';
import { Flex } from 'components/ui';
import client from 'lib/api/client';
import { getReactEnvVar } from 'lib/common';
import FileLoading from './FileLoading';
import Icon from 'components/ui/Icon/Icon';

const FileViewBlock = styled.div`
  width: 100%;

  .ant-card-head {
    height: 54px;
    margin: 0;

    .ant-card-head-title {
      font-size: 14px;
      padding: 16px 0;
    }
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-height: 166px;
    overflow: hidden;
  }
`;

const PreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
`;

const Navigator = styled.div`
  display: flex;
  align-items: center;

  .material-icons {
    height: 20px;
  }
`;

const ViewSectionBlock = styled.div`
  padding: 16px;
  border: 1px solid ${palette.GRAY40};
  overflow-y: auto;
  height: 1200px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
`;

const StyledImg = styled.img`
  width: 100%;
`;

const UnknownFileFormat = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
interface UploadedFile {
  url: string;
  name: string;
}

const ViewSection = ({
  file,
  pageNumber,
  encodedFile,
  onLoadSuccess,
}: {
  file?: File | string | UploadedFile | null;
  pageNumber: number;
  encodedFile?: string;
  onLoadSuccess: ({ numbers }: any) => void;
}) => {
  const [fileUrl, setFileUrl] = useState(file);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (typeof file === 'string' && file.startsWith('/files')) {
      (async () => {
        const response = await client.get(`${getReactEnvVar('SERVER_URL')}${file}`, {
          responseType: 'blob',
        });

        setMounted(true);
        setFileUrl(window.URL.createObjectURL(response.data));
      })();
    } else {
      setMounted(true);
      setFileUrl(file);
    }
  }, [file]);

  if (file && mounted) {
    if (
      (typeof file === 'string' &&
        (file.endsWith('.pdf') || file.endsWith('.PDF') || file.startsWith('/files'))) ||
      (file instanceof File && file.type === 'application/pdf')
    ) {
      return (
        <Document
          file={fileUrl}
          onLoadSuccess={onLoadSuccess}
          onLoadError={console.error}
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
          loading={<FileLoading />}
        >
          <Page pageNumber={pageNumber} scale={2} />
        </Document>
      );
    }
    if (typeof file === 'string' && !/\.zip|\.xlsx|\.doc|\.docx$/.test(file)) {
      return <StyledImg src={file} alt="fileImage" />;
    }
    if (typeof file !== 'string' && 'url' in file && file.url) {
      if (file.url.endsWith('.pdf') || file.url.endsWith('.PDF')) {
        return (
          <Document
            file={file.url}
            onLoadSuccess={onLoadSuccess}
            onLoadError={console.error}
            loading={<FileLoading />}
            options={{
              cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
          >
            <Page pageNumber={pageNumber} scale={2} />
          </Document>
        );
      }
      if (/(jpe?g)|(png)|(gif)|(bmp)$/.test(file.url)) {
        return <StyledImg src={file.url} alt="fileImage" />;
      }
    }
    if (encodedFile) {
      return <StyledImg src={encodedFile} alt="fileImage" />;
    }
    if (
      typeof file !== 'string' &&
      !file.name.endsWith('pdf') &&
      !file.name.endsWith('PDF') &&
      !/(jpe?g)|(png)|(gif)|(bmp)$/.test(file.name)
    ) {
      return (
        <UnknownFileFormat>
          <Typography.Text type="BODY_2">미리보기가 지원되지 않는 파일 형식입니다.</Typography.Text>
        </UnknownFileFormat>
      );
    }

    return <FileLoading />;
  } else if (file && !mounted) {
    return <FileLoading />;
  } else {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
};

const FileViewer = ({
  title = '미리보기',
  file,
  style,
  bodyStyle,
  titleStyle,
}: {
  title?: string;
  height?: string;
  file?: File | string | UploadedFile | null;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
}) => {
  const [encodedFile, setEncodedFile] = useState();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const onLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  useEffect(() => {
    if (file && file instanceof File && /image\//.test(file.type)) {
      const encodeFile = async () => {
        setEncodedFile(await fileToBase64(file));
      };
      encodeFile();
    }
  }, [file]);

  const checkIsFileReadableInPDFViewer = (file: File | string | UploadedFile | null) =>
    typeof file === 'string' ||
    (file && 'url' in file && file.url) ||
    (file instanceof File && file.type === 'application/pdf');

  return (
    <FileViewBlock style={style}>
      <PreviewHeader>
        <Typography.Text className="previewText" type="BODY_2" style={titleStyle}>
          {title}
        </Typography.Text>
        <Flex>
          {file && checkIsFileReadableInPDFViewer(file) && numPages > 1 && (
            <Navigator>
              <Icon
                name="left"
                size={20}
                color={pageNumber === 1 ? 'GRAY70' : 'GRAY90'}
                onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}
              />
              <span style={{ padding: '0 8px', position: 'relative', lineHeight: '20px' }}>
                {pageNumber} / {numPages}
              </span>
              <Icon
                name="right"
                size={20}
                color={pageNumber === numPages ? 'GRAY70' : 'GRAY90'}
                onClick={() => pageNumber < numPages && setPageNumber(pageNumber + 1)}
              />
            </Navigator>
          )}
        </Flex>
      </PreviewHeader>
      <ViewSectionBlock style={bodyStyle}>
        <ViewSection
          file={file}
          pageNumber={pageNumber}
          encodedFile={encodedFile}
          onLoadSuccess={onLoadSuccess}
        />
      </ViewSectionBlock>
    </FileViewBlock>
  );
};

export default FileViewer;

import { Col, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useCountryId, useProductETCCertifications } from 'service/brand/product/product';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import { DocumentCode } from 'types/product';
import { messages } from 'lib/consts';
import useGA, { EGAActionType } from 'hook/useGA';
import UpdateLog from 'components/product/UpdateLog';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import { useNavigate } from 'react-router-dom';

interface FileItem {
  productEtcCertificationId: number;
  name: string;
  url: string;
}

const ProductETCFile = ({ productId }: { productId: number }) => {
  const navigate = useNavigate();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [files, setFiles] = useState<(File | FileItem)[]>([]);
  const countryId = useCountryId() || 0;
  const { sendEventToGA } = useGA();
  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const {
    productETCCertifications,
    addProductETCCertifications,
    isAddProductETCCertificationsLoading,
  } = useProductETCCertifications({
    productId,
    countryId,
  });

  const updateMode = productETCCertifications && productETCCertifications?.length > 0;

  useEffect(() => {
    if (productETCCertifications && productETCCertifications.length > 0) {
      setFiles(
        productETCCertifications.map(({ productEtcCertificationId, filename, uploadFileUrl }) => ({
          productEtcCertificationId,
          name: filename,
          url: uploadFileUrl,
        })),
      );
    }
  }, [productETCCertifications]);

  const handleUpload = (file: File) => {
    setFiles((draft) => draft.concat(file));

    return true;
  };
  const handleDelete = (file: File) => {
    if (updateMode && files.length === 1) {
      message.warning(messages.REQUIRE_ONE_OR_MORE_FILES);
      return true;
    }
    setFiles(files.filter((item) => item !== file));
  };

  const handleSubmit = () => {
    if (!files || files.length === 0) {
      return message.warning('파일을 업로드해 주세요.');
    }

    const addFiles = (files as File[]).filter((item: File) => item instanceof File);

    const existedFileIds = (files as FileItem[])
      .filter((item) => item.productEtcCertificationId)
      .map((item) => item.productEtcCertificationId);
    const deletedFileIds = productETCCertifications
      ?.filter(
        ({ productEtcCertificationId }) => !existedFileIds.includes(productEtcCertificationId),
      )
      .map(({ productEtcCertificationId }) => productEtcCertificationId);

    addProductETCCertifications(
      {
        files: addFiles,
        countryId,
        ...(deletedFileIds &&
          deletedFileIds?.length > 0 && {
            deleteProductEtcCertificationIds: deletedFileIds,
          }),
      },
      {
        onSuccess: () => {
          if ((deletedFileIds && deletedFileIds?.length > 0) || updateMode) {
            message.success('보완 완료되었습니다.');
            navigate(-1);
            return;
          }

          sendEventToGA({
            documentName: 'ETC-Certifications',
            actionType: EGAActionType.MODIFY,
          });
          message.success('입력되었습니다.');
          navigate(-1);
        },
      },
    );
  };

  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.EDC}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.EDC}
            />
          </Col>
        )}
      </Row>
      <FileUploadTemplate
        updateMode={updateMode}
        readOnly={readOnlyMode}
        files={files}
        viewerVisible
        status="MOD"
        fetchLoading={isAddProductETCCertificationsLoading}
        onUpload={handleUpload}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
        documentCode={DocumentCode.EDC}
        correctRequestWithNoUpdate={true}
      />
    </>
  );
};

export default ProductETCFile;

import IngredientSearch from 'components/material/ingredient/IngredientSearch';
import { useSearchParams } from 'hook/useSearchParams';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import { IngredientSearchType } from 'types/material/ingredient';

export interface IngredientDictionaryPageSearchParams {
  page: number;
  firstSearchType: IngredientSearchType;
  secondSearchType: IngredientSearchType;
  firstKeyword: string;
  secondKeyword: string;
  isSearchAgain: boolean;
}

const IngredientDictionaryPage = () => {
  const { searchParams, onChangeSearchParams } = useSearchParams({
    path: path.material.ingredient.dictionary,
  });

  const queryParams: IngredientDictionaryPageSearchParams = {
    page: Number(searchParams.get('page')) || 1,
    firstSearchType:
      (searchParams.get('firstSearchType') as IngredientSearchType) || IngredientSearchType.NAME,
    secondSearchType:
      (searchParams.get('secondSearchType') as IngredientSearchType) || IngredientSearchType.NAME,
    firstKeyword: searchParams.get('firstKeyword') || '',
    secondKeyword: searchParams.get('secondKeyword') || '',
    isSearchAgain: searchParams.get('isSearchAgain') === 'true',
  };

  return (
    <PageTemplate style={{ maxWidth: 1040, minHeight: 783 }}>
      <IngredientSearch queryParams={queryParams} onChangeSearchParams={onChangeSearchParams} />
    </PageTemplate>
  );
};

export default IngredientDictionaryPage;

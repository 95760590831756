import { Tabs } from 'antd';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import PostModal from 'components/modal/service/PostModal';
import NoticeContainer from 'containers/service/NoticeContainer';
import UseGuideContainer from 'containers/service/UseGuideContainer';
import { deviceSize } from 'lib/styles';

const ServicePageBlock = styled.div`
  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-tab {
    width: 80px;
    margin-right: 0;

    .ant-tabs-tab-btn {
      margin: 0 auto;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 100px;

    @media ${deviceSize.sm} {
      margin-left: 0;
    }
  }
`;

const ServicePage = () => {
  const { tab } = useParams<{
    tab?: string;
  }>();
  const location = useLocation();
  const initialUseGuideId = new URLSearchParams(location.search).get('useGuideId') || undefined;

  return (
    <ServicePageBlock>
      <PostModal />
      <Tabs
        defaultActiveKey={tab || 'notice'}
        centered
        destroyInactiveTabPane
        items={[
          {
            key: 'notice',
            label: '공지사항',
            children: <NoticeContainer />,
          },
          {
            key: 'guide',
            label: '이용안내',
            children: <UseGuideContainer initialUseGuideId={initialUseGuideId} />,
          },
        ]}
      ></Tabs>
    </ServicePageBlock>
  );
};

export default ServicePage;

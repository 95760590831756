import { Modal } from 'antd';

import Checklist from 'components/checklist/Checklist';
import { SelfEstimateTarget } from 'types/brand/estimate';

const ChecklistModal = ({
  estimateTarget,
  onClose,
}: {
  estimateTarget: SelfEstimateTarget;
  onClose: () => void;
}) => {
  return (
    <Modal width={834} open footer={null} destroyOnClose onCancel={onClose}>
      <Checklist estimateTarget={estimateTarget} closeModal={onClose} />
    </Modal>
  );
};

export default ChecklistModal;

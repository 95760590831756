import { Button, Col, Modal, Row, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';

import { MsdsGet } from 'types/material/msds';
import { DocumentCode, RegisteredRawMaterial } from 'types/material/rawMaterial';
import { useGetMsds } from 'service/material/msds';
import { useRegisteredRawMaterials } from 'service/material/rawMaterial';
import { Typography } from 'components/system';
import Table from 'components/ui/Table/Table';

const MSDSImportModal = ({
  visible,
  onClose,
  onImport,
}: {
  visible: boolean;
  onClose: () => void;
  onImport: (rawMaterialMsds: MsdsGet) => void;
}) => {
  const { registeredRawMaterials, getLoading: getRegisteredRawMaterialsLoading } =
    useRegisteredRawMaterials({
      enabled: visible,
      documentCode: DocumentCode.MSDS,
    });
  const { getMsds, getLoading: getMsdsLoading } = useGetMsds();

  const [selectedMaterialId, setSelectedMaterialId] = useState<number | undefined>();

  const handleSelectMaterial = (keys: React.Key[]) => {
    setSelectedMaterialId(Number(keys[0]));
  };

  const handleImportMaterial = () => {
    Modal.confirm({
      width: 390,
      icon: null,
      centered: true,
      closable: true,
      content: (
        <Typography.Text type="BODY_2" style={{ textAlign: 'center' }}>
          Data 불러오기를 하시면 현재 입력된 내용은 사라지고
          <br />
          선택하신 원료의 MSDS Data 내용으로 변경됩니다.
          <br />
          Data를 불러오시겠습니까?
        </Typography.Text>
      ),
      onOk: () => {
        getMsds(selectedMaterialId!, {
          onSuccess: (res) => {
            onImport(res.data.result);
            onClose();
          },
        });
      },
      okText: '불러오기',
      okButtonProps: { style: { paddingLeft: 10, paddingRight: 10 } },
    });
  };

  const columns: ColumnsType<RegisteredRawMaterial> = [
    {
      title: '원료명',
      align: 'center',
      width: '30%',
      render: (_, { materialNameEn, materialId }) => (
        <Typography.Text type="BODY_2" color="GRAY90" medium>
          {materialNameEn}
        </Typography.Text>
      ),
    },
    {
      title: '실 생산자명',
      align: 'center',
      dataIndex: 'originManufacturerName',
    },
    { title: '원료 등록일', align: 'center', dataIndex: 'finishRegisterDt' },
    {
      title: '원료 등록자',
      align: 'center',
      dataIndex: ['finishRegistrant', 'name'],
    },
  ];

  useEffect(() => {
    if (visible) {
      setSelectedMaterialId(undefined);
    }
  }, [visible]);
  return (
    <Modal visible={visible} onCancel={onClose} footer={null} width={920}>
      <Spin spinning={getMsdsLoading}>
        <Typography.Text type="TITLE_1" gutter={{ bottom: 24 }}>
          등록된 원료 불러오기
        </Typography.Text>
        <Table
          columns={columns}
          rowSelection={{
            type: 'radio',
            selectedRowKeys:
              typeof selectedMaterialId !== 'undefined' ? [selectedMaterialId.toString()] : [],
            onChange: handleSelectMaterial,
          }}
          dataSource={registeredRawMaterials}
          rowKey={({ materialId }) => materialId.toString()}
          loading={getRegisteredRawMaterialsLoading}
          footer={() => (
            <Row justify="end" style={{ marginTop: 16 }}>
              <Col>
                <Button
                  style={{ width: 160 }}
                  disabled={typeof selectedMaterialId === 'undefined'}
                  onClick={() => handleImportMaterial()}
                >
                  Data 불러오기
                </Button>
              </Col>
            </Row>
          )}
        />
      </Spin>
    </Modal>
  );
};

export default MSDSImportModal;

import { Col, Form, FormInstance, message, Modal, Radio, RadioChangeEvent, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import styled, { css } from 'styled-components';
import { useState, useEffect } from 'react';
import produce from 'immer';

import palette from 'lib/styles/palette';
import MarketingTemplateItem from './MarketingTemplateItem';
import { MarketingTemplateType } from 'types/material/marketing';
import { s3AssetDomain } from 'lib/consts';

const CloseButtonIcon = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const StyledPage = styled(Row)<{ disabled?: boolean }>`
  position: relative;
  width: 1040px;
  height: 586px;
  border: 1px solid #d3d3d3;
  padding: 24px 68px 40px;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border: 1px solid ${palette.PRIMARY50};
        ${CloseButtonIcon} {
          display: block;
        }
      }
    `}

  ${CloseButtonIcon} {
    display: none;
  }

  & + & {
    margin-top: 40px;
  }
`;

const StyledCol = styled(Col)<{ $istype?: boolean; $active: boolean }>`
  color: ${(props) => (props.$istype ? palette.PRIMARY50 : palette.SLATE_GRAY70)};

  ${(props) => !props.$active && `opacity: 50%`}
`;

const TemplateExampleImg = styled.img`
  width: 96px;
  height: 72px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper-disabled {
    background-color: #fff;
  }

  .ant-radio-button-wrapper-disabled:hover {
    background-color: #fff;
  }
`;

interface Props {
  field: FormListFieldData;
  isMinimum: boolean;
  form: FormInstance<any>;
  remove: any;
  className?: string;
  disabled?: boolean;
}

const MarketingTemplatePage = ({ field, isMinimum, form, remove, className, disabled }: Props) => {
  const [activeTemplateType, setActiveTemplateType] = useState<MarketingTemplateType>('A');

  useEffect(() => {
    const temp = form.getFieldValue(['pages']);
    setActiveTemplateType(temp[field.name]?.templateType);
  }, [form, field]);
  const handleCloseBtn = () => {
    if (isMinimum) {
      return message.warning('최소 1 페이지 이상 입력해 주세요.');
    }
    Modal.confirm({
      icon: null,
      content: (
        <div style={{ textAlign: 'center', marginBottom: 12 }}>페이지를 삭제하시겠습니까?</div>
      ),
      closable: true,
      onOk: () => {
        remove(field.name);
      },
    });
  };
  const generateFormList = (templateType: string, field: FormListFieldData) => {
    if (templateType === 'A') {
      return (
        <Form.List name={[field?.name, 'items']}>
          {(itemFields) =>
            itemFields.map((itemField) => {
              return (
                <MarketingTemplateItem
                  disabled={disabled}
                  key={itemField.name}
                  form={form}
                  itemField={itemField}
                  pageIndex={field?.name}
                  type="A"
                />
              );
            })
          }
        </Form.List>
      );
    } else if (templateType === 'B') {
      return (
        <Form.List name={[field?.name, 'items']}>
          {(itemFields) => {
            return (
              <>
                <MarketingTemplateItem
                  disabled={disabled}
                  form={form}
                  itemField={itemFields[0]}
                  pageIndex={field.name}
                  type="A"
                />
                <MarketingTemplateItem
                  disabled={disabled}
                  form={form}
                  itemField={itemFields[1]}
                  pageIndex={field.name}
                  type="B"
                />
              </>
            );
          }}
        </Form.List>
      );
    } else {
      return (
        <Form.List name={[field.name, 'items']}>
          {(itemFields) => (
            <MarketingTemplateItem
              disabled={disabled}
              form={form}
              itemField={itemFields[0]}
              pageIndex={field.name}
              type="C"
            />
          )}
        </Form.List>
      );
    }
  };

  const handleTemplateTypeChange = (e: RadioChangeEvent, field: FormListFieldData) => {
    const templateType = e.target.value;
    form.setFieldsValue({
      pages: produce(form.getFieldValue('pages'), (proxy: any) => {
        proxy[field.name] = {
          templateType,
          items: [
            { locationFlag: 'UP' },
            ...(templateType !== 'C' ? [{ locationFlag: 'DOWN' }] : []),
          ],
        };
      }),
    });
  };

  const generateRadioButton = (templateType: MarketingTemplateType) => {
    const getTitle = () => {
      switch (templateType) {
        case 'A':
          return {
            text: '정렬형',
            url: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/template_a_${
              activeTemplateType === templateType ? 'active' : 'inactived'
            }.png`,
          };
        case 'B':
          return {
            text: '지그재그형',
            url: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/template_b_${
              activeTemplateType === templateType ? 'active' : 'inactived'
            }.png`,
          };
        case 'C':
          return {
            text: '페이지형',
            url: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/template_c_${
              activeTemplateType === templateType ? 'active' : 'inactived'
            }.png`,
          };
      }
    };
    return (
      <Radio.Button
        value={templateType}
        onChange={(e) => {
          setActiveTemplateType(e.target.value);
        }}
        style={{ marginBottom: 16 }}
      >
        <Row justify="center" gutter={8} style={{ marginBottom: 4 }}>
          <StyledCol $istype $active={activeTemplateType === templateType}>
            {templateType}
          </StyledCol>
          <StyledCol $active={activeTemplateType === templateType}>{getTitle().text}</StyledCol>
        </Row>
        <TemplateExampleImg src={getTitle().url} alt="templateExample"></TemplateExampleImg>
      </Radio.Button>
    );
  };

  return (
    <StyledPage disabled={disabled} className={className}>
      <CloseButtonIcon
        onClick={handleCloseBtn}
        src={`${s3AssetDomain}/btn_close.png`}
        alt="closeBtn"
      />
      <Col span={20}>{generateFormList(activeTemplateType, field)}</Col>
      <Col span={3} offset={1} style={{ margin: '72px 0 0 32px' }}>
        <Form.Item name={[field.name, 'templateType']}>
          <StyledRadioGroup
            disabled={disabled}
            style={{
              flexDirection: 'column',
            }}
            onChange={(e) => handleTemplateTypeChange(e, field)}
          >
            {['A', 'B', 'C'].map((type) => generateRadioButton(type as MarketingTemplateType))}
          </StyledRadioGroup>
        </Form.Item>
      </Col>
    </StyledPage>
  );
};

export default MarketingTemplatePage;

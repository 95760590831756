import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Modal, Space, Typography } from 'antd';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import * as serviceActions from 'modules/service';
import { downloadFile } from 'lib/file';
import Icon from 'components/ui/Icon/Icon';

const editorOptions = {
  width: 'auto',
  height: 'auto',
  minHeight: '300px',
  maxHeight: '500px',
};

const PostModal = () => {
  const dispatch = useDispatch();
  const post = useSelector(({ service }: any) => service.post);
  const { attaches, content } = post || {};
  const onClose = () => {
    dispatch(serviceActions.setPost(null));
  };
  return (
    <Modal
      open={post !== null}
      title={post?.title}
      width={1024}
      styles={{ body: { minHeight: 400 } }}
      footer={null}
      destroyOnClose
      onCancel={onClose}
    >
      <SunEditor
        setDefaultStyle="user-select: text;"
        lang="ko"
        setOptions={editorOptions}
        showToolbar={false}
        defaultValue={content}
        disable
      />
      {attaches && attaches.length > 0 && (
        <>
          <Divider />
          <Typography.Paragraph strong style={{ marginBottom: 4, paddingLeft: 8 }}>
            첨부 파일
          </Typography.Paragraph>
          <Space>
            {attaches.map(
              ({
                faqAttachId,
                attachUrl,
                filename,
              }: {
                faqAttachId: number;
                attachUrl: string;
                filename: string;
              }) => (
                <Button
                  key={faqAttachId}
                  type="link"
                  icon={<Icon name="clip" size={14} />}
                  style={{
                    padding: '0px 4px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => downloadFile(attachUrl, filename)}
                >
                  {filename}
                </Button>
              ),
            )}
          </Space>
        </>
      )}
    </Modal>
  );
};

export default PostModal;

import { Link } from 'react-router-dom';
import { Result } from 'antd/lib';

import path from 'lib/path';
import Icon from 'components/ui/Icon/Icon';
import { Typography } from 'components/system';

const Error404Page = () => {
  return (
    <Result
      title={
        <Typography.Text type="HEADLINE_1" light>
          404 Error
        </Typography.Text>
      }
      subTitle={
        <Typography.Text type="BODY_2" color="GRAY70" gutter={{ top: 8 }}>
          요청하신 페이지를 찾을 수 없습니다.
          <br />
          입력하신 주소가 정확한지 확인해주세요.
        </Typography.Text>
      }
      icon={<Icon name="warning" size={64} style={{ margin: '0 auto 32px' }} />}
      extra={
        <Link to={path.main} style={{ fontSize: 12 }}>
          홈으로 돌아가기
        </Link>
      }
      style={{ marginTop: '100px' }}
    />
  );
};

export default Error404Page;

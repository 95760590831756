import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { IngredientCoordinate } from 'types/brand/artworkScreening/artworkScreening';

const resizingBars: {
  direction:
    | 'nwse'
    | 'ew'
    | 'ns'
    | 'nesw'
    | 'nwse-negative'
    | 'ew-negative'
    | 'ns-negative'
    | 'nesw-negative';
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}[] = [
  {
    direction: 'nwse',
    top: 0,
    left: 0,
  },
  {
    direction: 'ew',
    left: 0,
    top: 16,
    bottom: 16,
  },
  {
    direction: 'ns',
    top: 0,
    left: 16,
    right: 16,
  },
  {
    direction: 'nesw',
    left: 0,
    bottom: 0,
  },
  {
    direction: 'nesw-negative',
    right: 0,
    top: 0,
  },
  {
    direction: 'nwse-negative',
    right: 0,
    bottom: 0,
  },
  {
    direction: 'ns-negative',
    bottom: 0,
    left: 16,
    right: 16,
  },
  {
    direction: 'ew-negative',
    right: 0,
    top: 16,
    bottom: 16,
  },
];

const initialWidth = 424;
const initialHeight = 537;
const minimumWidth = 240;
const minimumHeight = 260;

const IngredientImageModal = ({
  url,
  ingredientCoordinate,
  isMinimize,
  setIsMinimize,
}: {
  url: string;
  ingredientCoordinate: IngredientCoordinate[];
  isMinimize: boolean;
  setIsMinimize: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [imgOriginalSize, setImgOriginalSize] = useState({
    width: 1,
    height: 1,
  });
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [imageWrapperSize, setImageWrapperSize] = useState({
    width: 0,
    height: 0,
  });
  const [imageSize, setImageSize] = useState({ width: 1, height: 1 });
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [isResizing, setIsResizing] = useState<
    | 'nwse'
    | 'ew'
    | 'ns'
    | 'nesw'
    | 'nwse-negative'
    | 'ew-negative'
    | 'ns-negative'
    | 'nesw-negative'
  >();
  const [imageScale, setImageScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [, setScrollPosition] = useState({ x: 0, y: 0 });
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    x: windowWidth - initialWidth > 0 ? windowWidth - initialWidth : 0,
    y: windowHeight - initialHeight > 0 ? windowHeight - initialHeight : 0,
  });
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(Math.min(initialHeight, windowHeight));

  const startX = useRef(0);
  const startY = useRef(0);
  const offsetX = useRef(0);
  const offsetY = useRef(100);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const aspectRatio =
    imgOriginalSize.height === 0 ? 1 : imgOriginalSize.width / imgOriginalSize.height;
  const containerRatio = imageSize.height ? imageSize.width / imageSize.height : 1;
  const scale =
    aspectRatio > containerRatio
      ? imageSize.width / imgOriginalSize.width
      : imageSize.height / imgOriginalSize.height;
  const newWidth = imgOriginalSize.width * scale;
  const newHeight = imgOriginalSize.height * scale;
  const imgOffsetX = (imageSize.width - newWidth) / 2;
  const imgOffsetY = (imageSize.height - newHeight) / 2;
  const maxScrollWidth = imageSize.width - imageWrapperSize.width + 32;
  const maxScrollHeight = imageSize.height - imageWrapperSize.height + 32;

  const handleScroll = () => {
    setScrollPosition({
      x: imageWrapperRef.current?.scrollLeft || 0,
      y: imageWrapperRef.current?.scrollTop || 0,
    });
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    setIsDragging(true);
    setDragPosition({ x: e.clientX, y: e.clientY });
    e.preventDefault();
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (isDragging) {
      const dx = e.clientX - dragPosition.x;
      const dy = e.clientY - dragPosition.y;
      setDragPosition({ x: e.clientX, y: e.clientY });
      changeScrollPosition(dx, dy);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const changeScrollPosition = (dx: number, dy: number) => {
    setScrollPosition((prev) => {
      let newX = prev.x - dx;
      let newY = prev.y - dy;

      if (imageWrapperRef.current) {
        imageWrapperRef.current.scrollLeft = newX;
        imageWrapperRef.current.scrollTop = newY;
      }

      return {
        ...prev,
        x: newX < 0 ? 0 : newX > maxScrollWidth ? maxScrollWidth : newX,
        y: newY < 0 ? 0 : newY > maxScrollHeight ? maxScrollHeight : newY,
      };
    });
  };

  const handleMouseDownResizingBar = (
    event: React.MouseEvent<HTMLDivElement>,
    direction:
      | 'nwse'
      | 'ew'
      | 'ns'
      | 'nesw'
      | 'nwse-negative'
      | 'ew-negative'
      | 'ns-negative'
      | 'nesw-negative',
  ) => {
    setIsResizing(direction);
    startX.current = event.clientX;
    startY.current = event.clientY;
  };

  useEffect(() => {
    const handleMouseMoveResizingBar = (event: MouseEvent) => {
      if (!isResizing) return;

      const isNegative = isResizing.endsWith('-negative');
      const dx = event.clientX - startX.current;
      const dy = event.clientY - startY.current;
      let maxHeight = !isNegative
        ? startY.current + height
        : height + windowHeight - startY.current;
      let maxWidth = !isNegative ? startX.current + width : width + windowWidth - startX.current;

      if (isResizing.startsWith('ns')) {
        const newHeight = height - (isNegative ? -dy : dy);
        const finalHeight = newHeight > maxHeight ? maxHeight : newHeight;
        const newY = modalPosition.y + height - newHeight;

        setModalPosition({
          ...modalPosition,
          ...(!isNegative && {
            y:
              newY < 0
                ? 0
                : newY > startY.current + height - minimumHeight
                ? startY.current + height - minimumHeight
                : newY,
          }),
        });
        setHeight(Math.min(windowHeight, Math.max(minimumHeight, finalHeight)));
      } else if (isResizing.startsWith('ew')) {
        const newWidth = width - (isNegative ? -dx : dx);
        const finalWidth = newWidth > maxWidth ? maxWidth : newWidth;
        const newX = modalPosition.x + width - newWidth;

        setModalPosition({
          ...modalPosition,
          ...(!isNegative && {
            x:
              newX < 0
                ? 0
                : newX > startX.current + width - minimumWidth
                ? startX.current + width - minimumWidth
                : newX,
          }),
        });
        setWidth(Math.max(minimumWidth, Math.min(windowWidth, finalWidth)));
      } else if (isResizing.startsWith('nwse')) {
        const newWidth = width - (isNegative ? -dx : dx);
        const newHeight = height - (isNegative ? -dy : dy);
        const finalWidth = newWidth > maxWidth ? maxWidth : newWidth;
        const finalHeight = newHeight > maxHeight ? maxHeight : newHeight;
        const newX = modalPosition.x + width - newWidth;
        const newY = modalPosition.y + height - newHeight;

        setModalPosition({
          ...modalPosition,
          ...(!isNegative && {
            x:
              newX < 0
                ? 0
                : newX > startX.current + width - minimumWidth
                ? startX.current + width - minimumWidth
                : newX,
          }),
          ...(!isNegative && {
            y:
              newY < 0
                ? 0
                : newY > startY.current + height - minimumHeight
                ? startY.current + height - minimumHeight
                : newY,
          }),
        });
        setHeight(Math.min(windowHeight, Math.max(minimumHeight, finalHeight)));
        setWidth(Math.max(minimumWidth, Math.min(windowWidth, finalWidth)));
      } else if (isResizing.startsWith('nesw')) {
        const newWidth = width - (isNegative ? -dx : dx);
        const newHeight = height - (isNegative ? dy : -dy);
        maxHeight = isNegative ? startY.current + height : height + windowHeight - startY.current;
        maxWidth = !isNegative ? startX.current + width : width + windowWidth - startX.current;
        const finalWidth = newWidth > maxWidth ? maxWidth : newWidth;
        const finalHeight = newHeight > maxHeight ? maxHeight : newHeight;
        const newX = modalPosition.x + width - newWidth;
        const newY = modalPosition.y + height - newHeight;

        setModalPosition({
          ...modalPosition,
          ...(!isNegative && {
            x:
              newX < 0
                ? 0
                : newX > startX.current + width - minimumWidth
                ? startX.current + width - minimumWidth
                : newX,
          }),
          ...(isNegative && {
            y:
              newY < 0
                ? 0
                : newY > startY.current + height - minimumHeight
                ? startY.current + height - minimumHeight
                : newY,
          }),
        });
        setHeight(Math.min(windowHeight, Math.max(minimumHeight, finalHeight)));
        setWidth(Math.max(minimumWidth, Math.min(windowWidth - 32, finalWidth)));
      }
    };

    const handleMouseUpResizingBar = () => {
      setIsResizing(undefined);
    };

    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMoveResizingBar);
      window.addEventListener('mouseup', handleMouseUpResizingBar);

      return () => {
        window.removeEventListener('mousemove', handleMouseMoveResizingBar);
        window.removeEventListener('mouseup', handleMouseUpResizingBar);
      };
    } else {
      document.body.style.cursor = '';
    }
  }, [isResizing]);

  useEffect(() => {
    const handleMouseUpHeader = () => {
      setIsGrabbing(false);
    };

    const handleMouseMoveHeader = (e: MouseEvent) => {
      if (isGrabbing) {
        const newX = e.clientX - offsetX.current;
        const newY = e.clientY - offsetY.current;

        setModalPosition({
          x:
            newX < 0
              ? 0
              : newX + containerSize.width > windowWidth
              ? windowWidth - containerSize.width
              : newX,
          y:
            newY < 0
              ? 0
              : newY + containerSize.height > windowHeight
              ? windowHeight - containerSize.height
              : newY,
        });
      }
    };

    if (isGrabbing) {
      document.body.classList.add('grabbing');
      window.addEventListener('mousemove', handleMouseMoveHeader);
      window.addEventListener('mouseup', handleMouseUpHeader);

      return () => {
        document.body.classList.remove('grabbing');
        window.removeEventListener('mousemove', handleMouseMoveHeader);
        window.removeEventListener('mouseup', handleMouseUpHeader);
      };
    } else {
      document.body.style.cursor = '';
    }
  }, [isGrabbing]);

  useEffect(() => {
    if (isImgLoaded) {
      const width = imageRef.current?.naturalWidth || 0;
      const height = imageRef.current?.naturalHeight || 0;
      setImgOriginalSize({
        width,
        height,
      });
    }
  }, [isImgLoaded]);

  useEffect(() => {
    const updateSizes = () => {
      setContainerSize({
        width: containerRef.current?.clientWidth || 0,
        height: containerRef.current?.clientHeight || 0,
      });
      setImageWrapperSize({
        width: imageWrapperRef.current?.clientWidth || 0,
        height: imageWrapperRef.current?.clientHeight || 0,
      });
      setImageSize({
        width: imageRef.current?.clientWidth || 0,
        height: imageRef.current?.clientHeight || 0,
      });
    };

    const containerObserver = new ResizeObserver(updateSizes);
    const imageWrapperObserver = new ResizeObserver(updateSizes);
    const imageObserver = new ResizeObserver(updateSizes);

    if (containerRef.current) {
      containerObserver.observe(containerRef.current);
    }
    if (imageWrapperRef.current) {
      imageWrapperObserver.observe(imageWrapperRef.current);
    }
    if (imageRef.current) {
      imageObserver.observe(imageRef.current);
    }

    updateSizes();

    return () => {
      containerObserver.disconnect();
      imageWrapperObserver.disconnect();
      imageObserver.disconnect();
    };
  }, [isMinimize]);

  if (!isMinimize) {
    return (
      <Container
        ref={containerRef}
        style={{
          width,
          height,
          top: modalPosition.y,
          left: modalPosition.x,
        }}
        onMouseLeave={() => {
          setIsDragging(false);
        }}
      >
        {resizingBars.map(({ top, right, left, bottom, direction }, idx) => (
          <ResizingBar
            key={idx}
            direction={direction}
            top={top}
            left={left}
            bottom={bottom}
            right={right}
            onMouseDown={(e) => {
              setTooltipVisible(false);
              handleMouseDownResizingBar(e, direction);
            }}
          >
            {idx === 0 && (
              <Icon
                size={8}
                name="resizer"
                color="GRAY70"
                style={{ position: 'absolute', pointerEvents: 'none' }}
              />
            )}
          </ResizingBar>
        ))}
        <Header
          isGrabbing={isGrabbing}
          onMouseDown={(e) => {
            setTooltipVisible(false);
            setIsGrabbing(true);
            offsetX.current = e.clientX - modalPosition.x;
            offsetY.current = e.clientY - modalPosition.y;
          }}
        >
          <Typography.Text type="BODY_2" style={{ userSelect: 'none' }}>
            전성분 이미지
          </Typography.Text>
          <Icon
            name="minus"
            size={24}
            onClick={() => {
              setTooltipVisible(false);
              setIsMinimize(true);
            }}
          />
        </Header>
        <ImageWrapper
          ref={imageWrapperRef}
          height={height}
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          onScroll={handleScroll}
        >
          <Image
            ref={imageRef}
            imageScale={imageScale}
            src={url}
            onLoad={() => setIsImgLoaded(true)}
            alt="ingredients"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          />
          {ingredientCoordinate.map((coordinate) => {
            const x1 = Math.min(...coordinate.points.map((item) => item.pointX));
            const y1 = Math.min(...coordinate.points.map((item) => item.pointY));
            const x2 = Math.max(...coordinate.points.map((item) => item.pointX));
            const y2 = Math.max(...coordinate.points.map((item) => item.pointY));

            const [imgX1, imgX2, imgY1, imgY2] = [
              x1 * scale + imgOffsetX,
              x2 * scale + imgOffsetX,
              y1 * scale + imgOffsetY,
              y2 * scale + imgOffsetY,
            ];

            return (
              <HighlightedArea
                key={coordinate.formulaScreeningArtworkImageIngredientCoordinateId}
                style={{
                  top: imgY1 + 16,
                  left: imgX1 + 16,
                  width: imgX2 - imgX1,
                  height: imgY2 - imgY1,
                }}
              />
            );
          })}
        </ImageWrapper>
        <ZoomController onMouseUp={handleMouseUp} justify="center" gap={14}>
          <Icon
            size={18}
            color="GRAY70"
            name="zoomOut"
            onClick={() => setImageScale(imageScale > 0.5 ? imageScale - 0.5 : 0.5)}
          />
          <Typography.Text color="GRAY70" type="SMALL" align="center" style={{ width: 30 }}>
            {imageScale * 50}%
          </Typography.Text>
          <Icon
            size={18}
            color="GRAY70"
            name="zoomIn"
            onClick={() => setImageScale(imageScale < 2 ? imageScale + 0.5 : 2)}
          />
        </ZoomController>
        {tooltipVisible && (
          <Tooltip>
            <Flex gap={4}>
              <Icon name="blueDown" size={16} />
              <Typography.Text type="SMALL">크기를 자유롭게 조절해 보세요!</Typography.Text>
            </Flex>
            <Icon
              name="tooltipArrow2"
              style={{
                position: 'absolute',
                top: `calc(100% - 22px)`,
                left: 0,
              }}
              size={48}
            />
          </Tooltip>
        )}
      </Container>
    );
  }

  return (
    <MinimizedHeader justify="space-between" width={width}>
      <Typography.Text type="BODY_2">전성분 이미지</Typography.Text>
      <Icon name="plus" size={24} onClick={() => setIsMinimize(false)} />
    </MinimizedHeader>
  );
};

const Container = styled.div`
  position: fixed;
  top: 100px;
  right: 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
  z-index: 101;
`;

const Header = styled(Flex)<{ isGrabbing: boolean }>`
  justify-content: space-between;
  border-bottom: 1px solid ${palette.GRAY30};
  padding: 16px 24px;
  cursor: ${({ isGrabbing }) => (isGrabbing ? 'grabbing' : 'grab')};
  user-select: 'none';
`;

const MinimizedHeader = styled(Flex)<{ width: number }>`
  position: fixed;
  bottom: 0;
  right: 0;
  width: ${({ width }) => `${width}px`};
  height: 56px;
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
  z-index: 101;
`;

const ImageWrapper = styled.div<{ height: number }>`
  padding: 16px;
  height: ${({ height }) => height - 56}px;
  overflow: scroll;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Image = styled.img<{ imageScale: number }>`
  height: ${({ imageScale }) => `${100 * imageScale}%`};
  width: ${({ imageScale }) => `${100 * imageScale}%`};
  object-fit: contain;
  object-position: center center;
  cursor: grab;
`;

const HighlightedArea = styled.div`
  position: absolute;
  background: rgba(44, 244, 240, 0.3);
  border: 1px dashed ${palette.ETC_HIGHLIGHTING};
  pointer-events: none;
`;

const ResizingBar = styled.div<{
  direction:
    | 'nwse'
    | 'nesw'
    | 'ew'
    | 'ns'
    | 'nwse-negative'
    | 'nesw-negative'
    | 'ew-negative'
    | 'ns-negative';
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}>`
  position: absolute;
  top: ${({ top }) => top && `${top}px`};
  right: ${({ right }) => right && `${right}px`};
  bottom: ${({ bottom }) => bottom && `${bottom}px`};
  left: ${({ left }) => left && `${left}px`};
  cursor: ${({ direction }) => `${direction.replace('-negative', '')}-resize`};
  user-select: none;
  z-index: 1;

  ${({ direction }) => {
    if (direction.startsWith('nwse') || direction.startsWith('nesw')) {
      return css`
        width: 16px;
        height: 16px;
        padding: 4px;
      `;
    } else if (direction.startsWith('ew')) {
      return css`
        width: 5px;
      `;
    } else if (direction.startsWith('ns')) {
      return css`
        height: 5px;
      `;
    }
  }};
`;

const ZoomController = styled(Flex)`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  border: 1px solid ${palette.GRAY30};
  background: #fff;
  user-select: none;
  width: 126px;
  height: 40px;
`;

const shake = keyframes`
  0%, 30%, 40%, 53.3%, 66.667%, 100% {
    transform: translate(0, -100%);
  }
  35% {
    transform: translate(-14px, -100%);
  }
  46.667% {
    transform: translate(-10px, -100%);
  }
  60% {
    transform: translate(-4px, -100%);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: -14px;
  left: 0px;
  transform: translate(0, -100%);
  background-color: ${palette.SLATE_GRAY70};
  color: #fff;
  padding: 12px;
  border-radius: 8px;
  animation: ${shake} 3s ease-in-out both, ${fadeOut} 0.5s 5s ease forwards;
`;

export default IngredientImageModal;

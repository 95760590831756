import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import ProductSaleInfo from 'components/product/ProductSaleInfo';
import { Typography } from 'components/system';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = [
  '미국내 제품을 판매하는 웹페이지의 주소를 입력해주세요. ex)아마존 판매 페이지',
  <>
    온라인 판매 페이지가 없는 경우, 미국내에 제품이 $1000 이상 판매되었다는 증빙서류를 업로드
    해주세요. ex)
    <Typography.Text type="BODY_2" medium inline>
      ORDER FORM, COMMERCIAL INVOICE
    </Typography.Text>
  </>,
  <>
    증빙서류는 영문 파일로 업로드 해주세요.{' '}
    <Typography.Text type="BODY_2" medium inline>
      (PDF, JPG, ZIP)
    </Typography.Text>
  </>,
  <MistakeNoticeItem />,
];

const ProductSaleInfoPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId()!;

  return (
    <ProductPageTemplate subtitle="미국내 제품 판매 정보" notices={notices}>
      <ProductSaleInfo productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductSaleInfoPage;

import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCurrentProduct } from 'service/brand/product/product';
import ProductETCFile from 'components/product/ProductETCFile';

const notices = ['입력 정보 외에 인증에 필요한 자료를 업로드 할 수 있습니다.'];

const ProductEtcFilePage = () => {
  const currentProduct = useCurrentProduct();
  const productId = currentProduct.productId;

  return (
    <ProductPageTemplate subtitle="기타 자료 (인증)" notices={notices}>
      <ProductETCFile productId={productId} />
    </ProductPageTemplate>
  );
};

export default ProductEtcFilePage;

import { useCallback, useMemo, useState } from 'react';
import { useQuery, useInfiniteQuery } from 'react-query';
import qs from 'qs';

import {
  RawMaterialSearchType,
  AdRawMaterial,
  RawMaterialCategories,
  Efficacy,
  FormulaPurpose,
  RawMaterial,
  RawMaterialSearchParams,
} from 'types/material/rawMaterialSearch';
import client from 'lib/api/client';
import { APIPageableResponse, APIResponse } from 'types/common';
import { RetentionCharacteristic } from 'types/material/rawMaterialDetail';

export const useRawMaterialCategories = () => {
  const {
    data: rawMaterialCategories = {
      allEfficacies: [],
      allFormulaPurposes: [],
      allRetentionCharacteristics: [],
    },
    isFetching: getLoading,
  } = useQuery(
    'material/rawMaterialSearch/getRawMaterialCategories',
    () => client.get<APIResponse<RawMaterialCategories>>('/v1/material/category-infos'),
    { select: (res) => res.data.result },
  );

  return useMemo(
    () => ({
      efficacies: rawMaterialCategories.allEfficacies,
      formulaPurposes: rawMaterialCategories.allFormulaPurposes,
      retentionCharacteristics: rawMaterialCategories.allRetentionCharacteristics,
      getLoading,
    }),
    [rawMaterialCategories, getLoading],
  );
};

const useMaterialSearchKey = 'material/search';

export const useRawMaterialSearch = (inciName?: string) => {
  const [selectedEfficacies, setSelectedEfficacies] = useState<Efficacy[]>([]);
  const selectEfficacy = (efficacy: Efficacy) => {
    if (selectedEfficacies.includes(efficacy)) {
      setSelectedEfficacies((draft) => draft.filter((item) => item !== efficacy));
    } else {
      setSelectedEfficacies((draft) => draft.concat(efficacy));
    }
  };

  const [selectedFormulaPurposes, setSelectedFormulaPurposes] = useState<FormulaPurpose[]>([]);

  const [selectedCharacteristics, setSelectedRemarkCategories] = useState<
    RetentionCharacteristic[]
  >([]);

  const selectFormulaPurpose = (formulaPurpose: FormulaPurpose) => {
    if (selectedFormulaPurposes.includes(formulaPurpose)) {
      setSelectedFormulaPurposes((draft) => draft.filter((item) => item !== formulaPurpose));
    } else {
      setSelectedFormulaPurposes((draft) => draft.concat(formulaPurpose));
    }
  };

  const selectCharacteristic = (characteristic: RetentionCharacteristic) => {
    if (
      selectedCharacteristics.find(
        (selectedCharacteristic) =>
          selectedCharacteristic.materialCategoryId === characteristic.materialCategoryId,
      )
    ) {
      setSelectedRemarkCategories((draft) =>
        draft.filter((item) => item.materialCategoryId !== characteristic.materialCategoryId),
      );
    } else {
      setSelectedRemarkCategories((draft) => draft.concat(characteristic));
    }
  };

  const searchTypeOptions = useMemo(
    () => [
      { label: '전체', value: RawMaterialSearchType.ALL },
      { label: '원료명', value: RawMaterialSearchType.NAME },
      { label: '원료사', value: RawMaterialSearchType.COMPANY },
      { label: 'INCI Name', value: RawMaterialSearchType.INCI },
      { label: '마케팅 키워드', value: RawMaterialSearchType.MARKETING },
    ],
    [],
  );

  const [searchType, setSearchType] = useState(
    inciName ? RawMaterialSearchType.INCI : RawMaterialSearchType.ALL,
  );
  const changeSearchType = useCallback((searchType: RawMaterialSearchType) => {
    setSearchType(searchType);
  }, []);
  const [searchKeyword, setSearchKeyword] = useState(inciName || '');
  const changeKeyword = useCallback((searchKeyword: string) => {
    setSearchKeyword(searchKeyword);
  }, []);
  const refreshSearch = () => {
    setSelectedEfficacies([]);
    setSelectedFormulaPurposes([]);
    setSearchKeyword('');
  };

  const efficacyIds = useMemo(
    () => selectedEfficacies.map(({ efficacyCategoryId }) => efficacyCategoryId),
    [selectedEfficacies],
  );
  const purposeIds = useMemo(
    () => selectedFormulaPurposes.map(({ formulaPurposeCategoryId }) => formulaPurposeCategoryId),
    [selectedFormulaPurposes],
  );
  const retentionCharacteristicIds = useMemo(
    () => selectedCharacteristics.map(({ materialCategoryId }) => materialCategoryId),
    [selectCharacteristic],
  );

  return useMemo(
    () => ({
      selectedEfficacies,
      selectEfficacy,
      selectedFormulaPurposes,
      selectFormulaPurpose,
      searchTypeOptions,
      searchType,
      setSearchType: changeSearchType,
      searchKeyword,
      setSearchKeyword: changeKeyword,
      refreshSearch,
      efficacyIds,
      purposeIds,
      selectedCharacteristics,
      selectCharacteristic,
      retentionCharacteristicIds,
    }),
    [
      selectedEfficacies,
      selectedFormulaPurposes,
      searchTypeOptions,
      searchType,
      changeSearchType,
      searchKeyword,
      changeKeyword,
      selectedCharacteristics,
      selectCharacteristic,
      retentionCharacteristicIds,
    ],
  );
};

export const useInfiniteRawMaterials = ({
  inciName,
  efficacyIds,
  purposeIds,
  retentionCharacteristicIds,
  searchKeyword,
  searchType,
}: RawMaterialSearchParams) => {
  const [isExact, setIsExact] = useState(inciName ? true : false);
  const {
    data,
    isLoading: rawMaterialLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [
      useMaterialSearchKey,
      efficacyIds,
      purposeIds,
      retentionCharacteristicIds,
      searchKeyword,
      searchType,
      isExact,
    ],
    (params) => {
      const efficacyIds = params.queryKey[1] as number[];
      const purposeIds = params.queryKey[2] as number[];
      const retentionCharacteristicIds = params.queryKey[3] as number[];
      return client.get<APIPageableResponse<RawMaterial[]>>(
        `/v1/public-materials?${qs.stringify(
          {
            ...(efficacyIds.length > 0 && { efficacyIds }),
            ...(purposeIds.length > 0 && { purposeIds }),
            ...(retentionCharacteristicIds.length > 0 && {
              retentionCharacteristicIds,
            }),
            keyword: params.queryKey[4],
            searchType: params.queryKey[5],
            isExact: params.queryKey[6],
            size: 10,
            page: params.pageParam || 1,
          },
          { indices: false },
        )}`,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.data.result.totalElements >= pages.length * 10 + 1
          ? pages.length + 1
          : undefined;
      },
      onSuccess: () => {
        if (isExact) {
          setIsExact(false);
        }
      },
    },
  );
  const rawMaterials = useMemo(
    () =>
      data?.pages
        ?.map((page) => page)
        ?.reduce(
          (prevRawMaterials, response) => prevRawMaterials.concat(response.data.result.content),
          [] as RawMaterial[],
        ) || [],
    [data],
  );

  return {
    rawMaterialLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
    rawMaterials,
  };
};

export const useAdMaterials = ({
  inciName,
  efficacyIds,
  purposeIds,
  retentionCharacteristicIds,
  searchKeyword,
  searchType,
}: RawMaterialSearchParams) => {
  const { data: adRawMaterials, isLoading: isAdLoading } = useQuery(
    ['material-advertisings', inciName, efficacyIds, purposeIds, searchKeyword, searchType],
    () =>
      client.get<APIResponse<AdRawMaterial[]>>(
        `/material-advertisings?${qs.stringify(
          {
            ...(efficacyIds.length > 0 && { efficacyIds }),
            ...(purposeIds.length > 0 && { purposeIds }),
            ...(retentionCharacteristicIds.length > 0 && {
              retentionCharacteristicIds,
            }),
            keyword: searchKeyword,
            searchType,
            isExact: inciName ? true : false,
          },
          { indices: false },
        )}`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return {
    adRawMaterials,
    isAdLoading,
  };
};

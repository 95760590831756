import { useRoutes } from 'react-router-dom';

import EstimatePickPage from 'pages/estimate/EstimatePickPage';
import Error404Page from 'pages/error/Error404Page';
import EstimateContractPage from 'pages/estimate/EstimateContractPage';
import { useCheckManufacturer } from 'lib/hook';

const EstimateRouter = () => {
  useCheckManufacturer();
  const element = useRoutes([
    {
      path: 'pick',
      element: <EstimatePickPage />,
    },
    {
      path: 'contract',
      element: <EstimateContractPage />,
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);

  return element;
};

export default EstimateRouter;

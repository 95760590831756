import { useEffect, useState } from 'react';
import { Button, Form, List, message, Space } from 'antd';
import styled from 'styled-components';

import { useAnswerChecklist, useChecklist } from 'service/brand/checklist';
import { Typography } from 'components/system';
import ChecklistItem from 'components/checklist/ChecklistItem';
import { SelfEstimateTarget } from 'types/brand/estimate';
import { useModal } from 'hook/useModal';

const ChecklistBlock = styled.div``;

const Checklist = ({
  estimateTarget,
  closeModal,
}: {
  estimateTarget: SelfEstimateTarget;
  closeModal: () => void;
}) => {
  const { productId, countryId, estimateTargetId, status } = estimateTarget;
  const [form] = Form.useForm();
  const [isCompeleteToWrite, setIsCompeleteToWrite] = useState(false);
  const [updateMode, setUpdateMode] = useState(status === 'REG');
  const [finished, setFinished] = useState(updateMode);

  const { openAlertModal } = useModal();
  const { checklist, getLoading, resetChecklist, addChecklistItem } = useChecklist({
    productId,
    countryId,
    updateMode,
  });
  const { answerChecklist, isLoading: answerLoading } = useAnswerChecklist();

  const handleAnswerChecklist = (checklistForm: any) => {
    answerChecklist(
      {
        estimateTargetId,
        checklistAnswers: Object.entries(checklistForm).map(([key, value]) => ({
          countryChecklistId: Number(key),
          [typeof value === 'boolean' ? 'isYes' : 'subjectAnswer']: value,
        })),
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };
  const handleShowNextChecklistItem = ({
    stop,
    nextId,
    msg,
    countryChecklistId,
  }: {
    stop: boolean;
    nextId: number;
    msg?: string;
    countryChecklistId: number;
  }) => {
    if (msg) {
      openAlertModal({
        content: msg,
        closeText: '다시 선택',
        onCustomClose: () => {
          form.setFieldsValue({ [countryChecklistId]: undefined });
        },
        onOk: () => {
          handleShowNextChecklistItem({ stop, nextId, countryChecklistId });
        },
      });
      return;
    }

    if (stop) {
      return closeModal();
    }

    setTimeout(() => {
      const value = form.getFieldsValue()[countryChecklistId];

      const next = addChecklistItem(countryChecklistId, value);
      if (next === undefined) {
        setIsCompeleteToWrite(true);
        message.success('모든 체크리스트를 완료했습니다.');
      }
    }, 0);
  };
  const handleReset = () => {
    form.resetFields();
    resetChecklist();
    setFinished(false);
    setUpdateMode(false);
    setIsCompeleteToWrite(false);
  };

  const disabled = finished || !isCompeleteToWrite;

  useEffect(() => {
    if (!checklist || !updateMode) return;

    const newForm = checklist.reduce((acc, curr) => {
      acc[curr.countryChecklistId] = curr.answer?.subjectAnswer || curr.answer?.isYes;
      return acc;
    }, {} as { [key: number]: string | boolean | undefined });
    form.setFieldsValue(newForm);
  }, [checklist, updateMode]);

  return (
    <ChecklistBlock>
      <Typography.Text type="TITLE_1" gutter={{ right: 16, bottom: 34 }}>
        체크리스트 작성
      </Typography.Text>
      <Form form={form} onFinish={handleAnswerChecklist}>
        <List
          dataSource={checklist}
          renderItem={(checklistItem, index) => (
            <ChecklistItem
              {...checklistItem}
              form={form}
              disabled={finished || isCompeleteToWrite || index < checklist.length - 1}
              onChoice={handleShowNextChecklistItem}
            />
          )}
          loading={getLoading}
          style={{ minHeight: 228 }}
        />
        <Space
          style={{
            width: '100%',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <Button
            onClick={() => {
              handleReset();
            }}
          >
            다시 작성
          </Button>
          {!updateMode && (
            <Button
              type="primary"
              className={disabled ? 'ant-btn-waiting' : ''}
              htmlType="submit"
              loading={answerLoading}
              disabled={disabled}
            >
              작성 완료
            </Button>
          )}
        </Space>
      </Form>
    </ChecklistBlock>
  );
};

export default Checklist;

import { Dropdown, Form, Input, Menu } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import palette from 'lib/styles/palette';

const MarketingImageTypeDropdownBlock = styled.div`
  .ant-dropdown-menu {
    padding: 0px !important;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-size: 12px;
    padding: 0px 10px !important;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  font-size: 12px;
  padding: 0px 10px !important;
  color: #a9a6a6;

  &:hover {
    color: #222 !important;
  }
`;

const StyledChipInput = styled(Input)`
  width: 92px;
  padding: 1px 8px !important;
  height: 20px !important;
  border-radius: 12px;
  border: solid 1px ${palette.PRIMARY50};
  background-color: ${palette.PRIMARY10};
  font-size: 11px;
  font-weight: 500;
  color: ${palette.SLATE_GRAY70};
`;

const SelectChip = styled.div<{ isDefault: boolean }>`
  width: fit-content;
  padding: 1px 8px;
  height: 20px;
  border-radius: 12px;
  border: solid 1px ${(props) => (props.isDefault ? palette.GRAY40 : palette.PRIMARY50)};
  background-color: ${(props) => (props.isDefault ? '#fff' : palette.PRIMARY10)};
  font-size: 11px;
  font-weight: 500;
  color: ${(props) => (props.isDefault ? '#a9a6a6' : palette.SLATE_GRAY70)};
`;

interface Props {
  pageIndex: number;
  disabled?: boolean;
  initialItemType?: string;
  onDropdown?: (type: string) => void;
}

const MarketingImageTypeDropdown = ({
  pageIndex,
  disabled,
  onDropdown,
  initialItemType,
}: Props) => {
  const [itemType, setItemType] = useState(initialItemType || '');

  const handleMenuItem = (type: string) => {
    setItemType(type);
    if (onDropdown) onDropdown(type);
  };

  return (
    <MarketingImageTypeDropdownBlock>
      {itemType !== '직접입력' ? (
        <Dropdown
          disabled={disabled}
          overlay={
            <Menu>
              <StyledMenuItem onClick={() => handleMenuItem('분자구조')}>분자구조</StyledMenuItem>
              <StyledMenuItem onClick={() => handleMenuItem('원료컨셉')}>원료컨셉</StyledMenuItem>
              <StyledMenuItem onClick={() => handleMenuItem('실험자료')}>실험자료</StyledMenuItem>
              <StyledMenuItem onClick={() => setItemType('직접입력')}>직접입력</StyledMenuItem>
            </Menu>
          }
          placement="bottomLeft"
        >
          <SelectChip isDefault={itemType.length === 0}>
            {itemType ? itemType : '이미지 종류 선택'}
          </SelectChip>
        </Dropdown>
      ) : (
        <Form.Item name={[pageIndex, 'itemType']}>
          <StyledChipInput maxLength={7} placeholder="이미지 종류 입력" disabled={disabled} />
        </Form.Item>
      )}
    </MarketingImageTypeDropdownBlock>
  );
};

export default MarketingImageTypeDropdown;

import { Button, Col, Row, message } from 'antd';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import GoBackIfThereIsNoCountryId from 'components/common/GoBackIfThereIsNoCountryId';
import { Typography } from 'components/system';
import {
  Flex,
  OrderList,
  OrderListItem,
  OrderListItemContent,
  OrderListItemLabel,
} from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import useGA, { EGAActionType } from 'hook/useGA';
import { useModal } from 'hook/useModal';
import { messages } from 'lib/consts';
import { urlToFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { useProductDocStatus, useProductPCPA } from 'service/product';
import { DocStatus, DocumentCode } from 'types/product';
import UpdateLog from './UpdateLog';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  margin: 20px auto 0;
  max-width: 864px;
`;

const ProductPCPAUIContainer = styled.div``;

const SignDescText = styled(Typography.Text)`
  color: ${palette.SLATE_GRAY90};
`;

SignDescText.defaultProps = {
  type: 'BODY_1',
};

const SignAlphaText = styled(Typography.Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: ${palette.MESSAGE_CHECK};
  border-radius: 50%;
`;

SignAlphaText.defaultProps = {
  type: 'SMALL',
  color: 'ETC_WHITE',
};

const SignImgContainer = styled(Flex)`
  padding: 32px 64px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 12px;

  > img {
    width: 476px;
  }
`;

export const ProductPCPA = () => {
  const navigate = useNavigate();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [initialFile, setInitialFile] = useState<File | undefined>();
  const { sendEventToGA } = useGA();
  const currentProduct = useCurrentProduct();
  const { openAlertModal } = useModal();
  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const productId = currentProduct.productId;
  const countryId = useCountryId() || 0;
  const {
    isGetProductPCPALoading,
    isUpdateProductPCPALoading,
    productPCPA,
    updateProductPCPA,
    pcpaFileData,
    isPcpaFileDataLoading,
  } = useProductPCPA({ productId, countryId });
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PCPA,
  });

  const updateMode = productPCPA;

  useEffect(() => {
    if (docStatus?.status === DocStatus.INP) {
      openAlertModal({
        content: '동시 진행 중인 제품이 있는 경우\nCDRI 담당자에게 문의해 주시기 바랍니다.',
        noCancelButton: true,
      });
    }
  }, []);

  const cta = useMemo(() => {
    if (!updateMode) {
      return '등록';
    } else if (docStatus?.status === 'MOD') {
      return '보완 완료';
    }

    return '수정';
  }, [updateMode, docStatus]);

  const handleDocumentDownload = () => {
    pcpaFileData(
      { productId, countryId },
      {
        onSuccess: (res) => {
          if (!res.data) {
            message.warning('서류 양식이 존재하지 않습니다.');
          } else {
            let a = document.createElement('a');

            let file = new Blob([res.data], {
              type: 'application/pdf',
            });

            const url = URL.createObjectURL(file);

            a.href = url;
            a.download = decodeURIComponent(
              `위탁가공협의서_${currentProduct.productDetail.productNameEn}.pdf`,
            );
            a.click();
          }
        },
      },
    );
  };

  const handleSubmit = (file: File) => {
    updateProductPCPA(
      {
        countryId,
        productConsignmentProcessingAgreement: { file },
      },
      {
        onSuccess: () => {
          sendEventToGA({
            documentName: '위탁가공협의서',
            actionType: !updateMode
              ? EGAActionType.REGISTER
              : docStatus?.status === 'MOD'
              ? EGAActionType.MODIFY
              : EGAActionType.UPDATE,
          });
          message.success('입력되었습니다.');
          navigate(-1);
        },
      },
    );
  };

  useEffect(() => {
    if (!productPCPA?.uploadFileUrl || !productPCPA?.filename) return;

    (async () => {
      const file = await urlToFile(productPCPA.uploadFileUrl, productPCPA?.filename);
      setInitialFile(file);
    })();
  }, [productPCPA]);

  return (
    <Container>
      {isGetProductPCPALoading && <FullLoading />}
      <GoBackIfThereIsNoCountryId />
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.PCPA}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.PCPA}
            />
          </Col>
        )}
      </Row>
      <ProductPCPAUI
        initialFile={initialFile}
        documentDownloading={isPcpaFileDataLoading}
        readOnly={readOnlyMode}
        onDocumentExmapleDownload={handleDocumentDownload}
        submitting={isUpdateProductPCPALoading}
        cta={cta}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

const ProductPCPAUI = ({
  onDocumentExmapleDownload,
  onSubmit,
  documentDownloading,
  readOnly = false,
  submitting,
  cta,
  initialFile,
}: {
  onDocumentExmapleDownload?: VoidFunction;
  onSubmit?: (file: File) => void;
  documentDownloading?: boolean;
  readOnly?: boolean;
  submitting?: boolean;
  cta?: string;
  initialFile?: File;
}) => {
  const [file, setFile] = useState<File | undefined>();

  const handleFileUpload: ComponentProps<typeof FileUploadContainer>['onUpload'] = (file: File) => {
    setFile(file);
    return true;
  };

  const handleFileDelete: ComponentProps<typeof FileUploadContainer>['onDelete'] = () => {
    setFile(undefined);
    return false;
  };

  const handleSubmit = () => {
    if (!file) {
      message.warning(messages.REQUIRE_ONE_OR_MORE_FILES);
      return;
    }

    onSubmit?.(file);
  };

  useEffect(() => {
    if (!initialFile) return;

    setFile(initialFile);
  }, [initialFile]);

  return (
    <ProductPCPAUIContainer>
      <OrderList>
        <OrderListItem horizontal columnGap={16}>
          <OrderListItemLabel>서류를 다운로드해 주세요.</OrderListItemLabel>
          <OrderListItemContent>
            <Button
              loading={documentDownloading}
              style={{ borderRadius: 4, display: 'flex', alignItems: 'center' }}
              icon={<Icon name="download" size={14} color="PRIMARY50" />}
              onClick={onDocumentExmapleDownload}
            >
              서류 양식 다운로드
            </Button>
          </OrderListItemContent>
        </OrderListItem>
        <OrderListItem rowGap={16}>
          <OrderListItemLabel>출력 후 서명해 주세요.</OrderListItemLabel>
          <OrderListItemContent>
            <SignImgContainer align="center" justify="center" gap={40}>
              <img
                src="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/pcpa_sign.png"
                alt="pcpa_sign"
              />
              <Flex dir="column" gap={24}>
                <Flex justify="center" align="flex-start" gap={10}>
                  <SignAlphaText>A</SignAlphaText>
                  <Flex dir="column">
                    <SignDescText>
                      <Typography.Text color="PRIMARY50" inline>
                        브랜드사
                      </Typography.Text>{' '}
                      대표자 성함 (영문)
                      <br />
                      수기작성 / 직인날인
                    </SignDescText>
                    <Typography.Text style={{ fontSize: 14, color: palette.MESSAGE_ERROR }}>
                      *수권서와 동일하게 입력해 주세요.
                    </Typography.Text>
                  </Flex>
                </Flex>
                <Flex justify="center" align="flex-start" gap={10}>
                  <SignAlphaText>B</SignAlphaText>
                  <Flex dir="column">
                    <SignDescText>
                      <Typography.Text color="PRIMARY50" inline>
                        제조사
                      </Typography.Text>{' '}
                      대표자 성함 (영문) <br /> 수기 작성 / 직인 날인
                    </SignDescText>
                  </Flex>
                </Flex>
              </Flex>
            </SignImgContainer>
          </OrderListItemContent>
        </OrderListItem>
        <OrderListItem rowGap={24}>
          <OrderListItemLabel>
            서명이 완료된 서류를 스캔하여 아래에 업로드해 주세요.
          </OrderListItemLabel>
          <OrderListItemContent>
            <FileUploadContainer
              readOnly={readOnly}
              files={file}
              accept=".pdf"
              max={1}
              onUpload={handleFileUpload}
              onDelete={handleFileDelete}
            />
            <FooterBox>
              {cta === '보완 완료' && (
                <CorrectRequestWithoutChangeButton documentCode={DocumentCode.PCPA} />
              )}
              <ReadOnlyBackButton readOnly={readOnly}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  {cta}
                </Button>
              </ReadOnlyBackButton>
            </FooterBox>
          </OrderListItemContent>
        </OrderListItem>
      </OrderList>
    </ProductPCPAUIContainer>
  );
};

export default ProductPCPAUI;

import { useLayoutEffect } from 'react';

import ProductPageTemplate from 'templates/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { ProductPQS } from 'components/product/ProductPQS';

const notices = [
  '해당 정보는 검역 샘플의 통관에 사용될 수 있으므로, 실제로 발송하시는 검역 샘플의 정보를 정확히 입력해 주세요.',
  <MistakeNoticeItem />,
];

const ProductPQSPage = () => {
  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateStyle"]');
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <ProductPageTemplate
      subtitle="검역 샘플 정보"
      notices={notices}
      childrenStyle={{ paddingTop: 32 }}
    >
      <ProductPQS />
    </ProductPageTemplate>
  );
};

export default ProductPQSPage;

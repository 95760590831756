import PageTemplate, { PageTemplateProps } from 'templates/PageTemplate';
import { Typography } from 'components/system';
import { useProductSample, useProductSampleId } from 'service/manufacturer/productSample';
import { ProductSampleStatus } from 'types/manufacturer/productSample';
import * as S from 'templates/PageTemplateStyle';

const ProductSamplePageTemplate = ({ title, subtitle, ...props }: PageTemplateProps) => {
  const productSampleId = useProductSampleId();
  const { productSample } = useProductSample(productSampleId);
  return (
    <S.PageTemplateBlock>
      <PageTemplate
        {...props}
        title={
          title ||
          (productSample && productSample.status !== ProductSampleStatus.RDY
            ? '샘플 관리'
            : '샘플 등록')
        }
        subtitle={
          <S.NameContainer>
            {subtitle && (
              <Typography.Text style={{ fontSize: 20 }} gutter={{ right: 16 }}>
                {subtitle}
              </Typography.Text>
            )}
            {productSample && (
              <div>
                <Typography.Text type="BODY_2" inline gutter={{ right: 4 }} className="label">
                  랩넘버
                </Typography.Text>
                <Typography.Text
                  type="TITLE_2"
                  medium
                  className="product-name"
                  inline
                  style={{ maxWidth: 580 }}
                >
                  {productSample.labNo}
                </Typography.Text>
              </div>
            )}
          </S.NameContainer>
        }
      />
    </S.PageTemplateBlock>
  );
};

export default ProductSamplePageTemplate;

import { useParams } from 'react-router-dom';
import moment from 'moment';

import path from 'lib/path';
import QCQAPageTemplate from 'templates/QCQAPageTemplate';
import { useSearchParams } from 'hook/useSearchParams';
import QCQAOutputList from 'components/brand/qcqa/QCQAOutputList';

export interface QCQAOutputListPageSearchParams {
  productYear: number;
  outputDateLower: string;
  outputDateUpper: string;
}

const QCQAOutputListPage = () => {
  const today = moment();
  const currYear = String(today.year());
  const params = useParams<{
    qcqaCompanyId: string;
  }>();
  const qcqaCompanyId = Number(params.qcqaCompanyId);
  const { searchParams } = useSearchParams({
    path: `${path.qcqa.outputs.root}/${qcqaCompanyId}`,
  });

  const queryParams: QCQAOutputListPageSearchParams = {
    productYear: Number(searchParams.get('productYear')) || today.year(),
    outputDateLower: `${currYear}-01-01`,
    outputDateUpper: today.format('YYYY-MM-DD'),
  };

  return (
    <QCQAPageTemplate title="생산실적 현황">
      <QCQAOutputList queryParams={queryParams} />
    </QCQAPageTemplate>
  );
};

export default QCQAOutputListPage;

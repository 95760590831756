import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { useNMPAMaterialCounts } from 'service/material/nmpa/nmpa';
import Icon from 'components/ui/Icon/Icon';

const Container = styled(Flex)`
  height: 106px;
  padding: 24px 16px;
  background: ${palette.SLATE_GRAY10};
  border-radius: 8px;
`;

const NMPAMaterialStats = () => {
  const { nmpaMaterialCounts } = useNMPAMaterialCounts();

  return (
    <Container gap={48} align="center">
      <Flex dir="column" align="center" style={{ flex: '1 1 256px' }}>
        <Typography.Text type="BODY_2" color="GRAY70">
          총 계약 원료 수
        </Typography.Text>
        <Typography.Text type="TITLE_1" medium color="PRIMARY50">
          {nmpaMaterialCounts?.totalCount}
        </Typography.Text>
      </Flex>
      <Flex gap={16} align="center" style={{ flex: '1 1 584px' }}>
        <Flex dir="column" align="center" style={{ flex: '1 1 160px' }}>
          <Typography.Text type="BODY_2" color="GRAY70">
            취득 가능 원료
          </Typography.Text>
          <Typography.Text type="TITLE_1" medium color="SLATE_GRAY70">
            {nmpaMaterialCounts &&
              nmpaMaterialCounts.totalCount -
                (nmpaMaterialCounts.ongoingCount + nmpaMaterialCounts.finishCount)}
          </Typography.Text>
        </Flex>
        <Icon name="right" color="SLATE_GRAY60" size={20} />
        <Flex dir="column" align="center" style={{ flex: '1 1 160px' }}>
          <Typography.Text type="BODY_2" color="GRAY70">
            취득 진행중
          </Typography.Text>
          <Typography.Text type="TITLE_1" medium color="SLATE_GRAY70">
            {nmpaMaterialCounts?.ongoingCount}
          </Typography.Text>
        </Flex>
        <Icon name="right" color="SLATE_GRAY60" size={20} />
        <Flex dir="column" align="center" style={{ flex: '1 1 160px' }}>
          <Typography.Text type="BODY_2" color="GRAY70">
            취득 완료 원료
          </Typography.Text>
          <Typography.Text type="TITLE_1" medium color="SLATE_GRAY70">
            {nmpaMaterialCounts?.finishCount}
          </Typography.Text>
        </Flex>
      </Flex>
    </Container>
  );
};

export default NMPAMaterialStats;

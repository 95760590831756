import { useMutation } from 'react-query';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import path from 'lib/path';

export const useAddProductWithCode = () => {
  const navigate = useNavigate();
  const { mutate: AddProductWithCode, isLoading: AddProductWithCodeLoading } = useMutation(
    ({ manufacturerId, authCode }: { manufacturerId: number; authCode: string }) =>
      client.post<APIResponse<null>>(`/manufacturers/${manufacturerId}/products/register`, {
        authCode,
      }),
    {
      onSuccess: () => {
        navigate(path.product.ready);
        message.success('등록되었습니다.');
      },
    },
  );

  return {
    AddProductWithCode,
    AddProductWithCodeLoading,
  };
};

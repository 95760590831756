import styled from 'styled-components';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/system';
import ContractGuide from 'components/contract/ContractGuide';
import FooterBox from 'components/FooterBox';
import path from 'lib/path';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';

const EstimatePaymentBlock = styled.div``;

const EstimatePayment = ({ isNMPAEstimate = false }: { isNMPAEstimate?: boolean }) => {
  const navigate = useNavigate();
  return (
    <EstimatePaymentBlock>
      <Flex
        justify="center"
        align="center"
        gap={16}
        style={{ marginBottom: isNMPAEstimate ? 80 : 120 }}
      >
        <Icon
          name="check"
          size={96}
          color="PRIMARY50"
          style={{
            flex: '0 0 96px',
          }}
        />
        <Typography.Text light type="HEADLINE_1">
          계약이 체결되었습니다.
          <br />
          결제 진행 및 계약서를 송부해 주세요.
        </Typography.Text>
      </Flex>
      <ContractGuide isNMPAEstimate={isNMPAEstimate} />
      {isNMPAEstimate && (
        <FooterBox>
          <Button type="primary" size="large" onClick={() => navigate(`${path.my}/nmpa`)}>
            회사 정보 입력하기
          </Button>
        </FooterBox>
      )}
    </EstimatePaymentBlock>
  );
};

export default EstimatePayment;

import { AxiosResponse } from 'axios';

import { CoaAdd, CoaGet, CoaUpdate } from 'types/material/coa';
import { APIResponse } from 'types/common';
import { CoaPhase, CoaTest } from 'types/material/coa';
import client from 'lib/api/client';

export const getCoaPhases = () => client.get<APIResponse<CoaPhase[]>>('/material/coa/phases');

export const getCoaTests = (materialCategoryId: number) =>
  client.get<APIResponse<CoaTest[]>>(`/material/coa/phases/${materialCategoryId}/tests`);

export const getCoa = async (materialId: number) => {
  const res = (await client.get<APIResponse<CoaGet>>(
    `/material/coa?materialId=${materialId}`,
  )) as any;
  if (res.data.result) {
    res.data.result.materialCategoryId = res.data.result.materialCategory.materialCategoryId;
    res.data.result.materialCOAItems = res.data.result.materialCOAItems.map((coaItem: any) => ({
      ...coaItem,
      ...(coaItem.materialCategory && {
        materialCategoryId: coaItem.materialCategory.materialCategoryId,
      }),
      isNoData: coaItem.isNoData !== null ? coaItem.isNoData : undefined,
    }));
  }
  return res as AxiosResponse<APIResponse<CoaGet>>;
};

export const addCoa = (coa: CoaAdd) => client.post('/material/coa', coa);

export const addTemporaryCoa = (coa: CoaAdd) => client.post('/material/temporary-coa', coa);

export const updateCoa = (coa: CoaUpdate) =>
  client.patch(`/material/coa/${coa.materialCOAId}`, coa);

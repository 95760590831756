const COLOR = {
  C_MAIN: '#DA2128',
  WHITE: '#ffffff',
  TYPO1: '#000000',
  TYPO2: '#222222',
  TYPO3: '#363636',
  TYPO4: '#4C4C4C',
  TYPO5: '#7E7E7E',
  TYPO6: '#A5A5A5',
  TYPO7: '#BBBBBB',
  BG1: '#1D1B1B',
  BG2: '#F5F5F5',
  BG3: '#FAFAFA',
  BG4: '#FBE9E9',
  BORDER01: '#F0F0F0',
};

export default COLOR;

import { useState } from 'react';
import { Button } from 'antd';
import styled, { css } from 'styled-components';

import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';

const IngredientAreaSelectModal = ({
  onOk,
  okLoading,
  splitImages,
}: {
  onOk: (formulaScreeningArtworkImageId: number) => void;
  okLoading: boolean;
  splitImages: {
    formulaScreeningArtworkImageId: number;
    url: string;
    filename: string;
    pointX1: number;
    pointY1: number;
    pointX2: number;
    pointY2: number;
  }[];
}) => {
  const [selectedArtworkImageId, setSelectedArtworkImageId] = useState<number>();
  const imageWidth = splitImages.length === 2 || splitImages.length === 4 ? 368 : 240;
  const imageHeight = splitImages.length === 2 || splitImages.length === 3 ? 336 : 160;

  return (
    <Container>
      <Flex gap={16} wrap="true" style={{ maxHeight: 336, overflowY: 'scroll' }}>
        {splitImages.map(({ formulaScreeningArtworkImageId, url }) => (
          <ImageWrapper
            key={formulaScreeningArtworkImageId}
            width={imageWidth}
            height={imageHeight}
            isSelected={selectedArtworkImageId === formulaScreeningArtworkImageId}
            onClick={() => {
              setSelectedArtworkImageId(formulaScreeningArtworkImageId);
            }}
          >
            <img
              src={url}
              alt="ingredients"
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }}
            />
          </ImageWrapper>
        ))}
      </Flex>
      <Flex justify="center" gutter={{ top: 24 }}>
        <Button
          key="submit"
          type="primary"
          loading={okLoading}
          disabled={!selectedArtworkImageId}
          onClick={() => {
            if (selectedArtworkImageId) {
              onOk(selectedArtworkImageId);
            }
          }}
          style={{ width: 100 }}
        >
          선택 완료
        </Button>
      </Flex>
    </Container>
  );
};

const Container = styled.div``;

const ImageWrapper = styled.div<{
  isSelected: boolean;
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 4px;
  padding: 4px;
  border: 1px solid ${palette.GRAY30};
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid ${palette.PRIMARY50};
      background-color: ${palette.PRIMARY20};
    `}
`;

export default IngredientAreaSelectModal;

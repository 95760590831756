import { useLayoutEffect } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { ProductPCPA } from 'components/product/ProductPCPA';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '서류 양식에 정보 기입 및 서명을 한 후 스캔하여 업로드해 주세요.',
  '브랜드사의 서명은 반드시 수권서에 날인된 서명과 동일하게 날인해 주세요.',
  <MistakeNoticeItem />,
];

const ProductPCPAPage = () => {
  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateStyle"]');
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <ProductPageTemplate subtitle="위탁가공협의서 (생산위임계약서)" notices={notices}>
      <ProductPCPA />
    </ProductPageTemplate>
  );
};

export default ProductPCPAPage;

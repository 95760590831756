import styled from 'styled-components';
import { ReactElement } from 'react';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';

interface Props {
  icon: React.ReactNode;
  label: string;
  description: string | ReactElement;
  onClick: () => void;
  promotionText?: string;
  flex?: string;
}

const ShortcutBlock = styled.div<{ flex: string }>`
  flex: ${({ flex }) => flex};
  height: 148px;
  border: 1px solid ${palette.GRAY40};
  border-radius: 12px;
  padding: 24px;
  cursor: pointer;

  &:hover {
    border-color: ${palette.PRIMARY50};
    box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
  }
`;

const PromotionBox = styled.div`
  background-color: ${palette.PRIMARY50};
  border-radius: 4px;
  padding: 3px 5px;
`;

const Shortcut = ({
  icon,
  label,
  description,
  onClick,
  promotionText,
  flex = '1 1 calc(50% - 8px)',
}: Props) => {
  return (
    <ShortcutBlock onClick={onClick} flex={flex}>
      <Flex justify="space-between">
        {icon}
        {/* TODO: 미사용 확정시 promotionText 없애기 */}
        {promotionText && (
          <PromotionBox>
            <Typography.Text type="SMALL" color="ETC_WHITE">
              {promotionText}
            </Typography.Text>
          </PromotionBox>
        )}
      </Flex>
      <Typography.Text type="TITLE_2" gutter={{ top: 8 }}>
        {label}
      </Typography.Text>
      <Typography.Text
        type="BODY_2"
        color="GRAY70"
        gutter={{ top: 4 }}
        style={{ minHeight: 40, lineHeight: 'normal', wordBreak: 'keep-all' }}
      >
        {description}
      </Typography.Text>
    </ShortcutBlock>
  );
};

export default Shortcut;

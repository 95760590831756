import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { useModal } from 'hook/useModal';
import { deviceSize } from 'lib/styles';
import { useMyICIDEstimates } from 'service/material/estimate';
import { DepositStatus } from 'types/estimate';
import { ICIDEstimate } from 'types/material/estimate';

const Container = styled.div`
  padding: 0 16px;

  @media ${deviceSize.sm} {
    padding: 0;
  }
`;

const getDepositStatusColor = (depositStatus: DepositStatus) => {
  if (depositStatus === DepositStatus.READY) return 'lightGray';
  else if (depositStatus === DepositStatus.PARTIAL) return 'red';
  return 'blue';
};

const getDepositStatusText = (depositStatus: DepositStatus) => {
  if (depositStatus === DepositStatus.READY) return '미납';
  else if (depositStatus === DepositStatus.PARTIAL) return '분납';
  return '완납';
};

const ICIDEstimateList = () => {
  const [page, setPage] = useState(1);
  const { myICIDEstimates, totalElements } = useMyICIDEstimates({
    page,
  });

  const { openDocumentPreviewModal, openEstimateHistoriesModal, openEstimateAdditionalsModal } =
    useModal();

  const columns: ColumnsType<ICIDEstimate> = useMemo(
    () => [
      { title: '견적서 번호', dataIndex: 'estimateCode', align: 'center' },
      {
        title: '견적 발행일',
        align: 'center',
        render: (_, { registerDt }) => registerDt.slice(0, 10),
      },
      {
        title: '입금 처리 / 견적 금액',
        align: 'center',
        render: (_, { estimatePrice }) =>
          `0원 / ${Math.floor(estimatePrice * 1.1).toLocaleString()}원`,
      },
      {
        title: '입금 상태',
        align: 'center',
        render: (_, { depositStatus }) => (
          <Tags.Mark color={getDepositStatusColor(depositStatus)}>
            {getDepositStatusText(depositStatus)}
          </Tags.Mark>
        ),
      },
      {
        title: '계약서 수령',
        align: 'center',
        render: (_, { receivedContract }) => (
          <Tags.Mark color={receivedContract ? 'blue' : 'lightGray'}>
            {receivedContract ? '완료' : '대기중'}
          </Tags.Mark>
        ),
      },
      {
        title: '견적서',
        align: 'center',
        render: (_, { estimateFileUrl }) => (
          <Icon
            name="page"
            size={22}
            onClick={() =>
              openDocumentPreviewModal({
                documentUrls: estimateFileUrl,
              })
            }
            style={{ margin: '0 auto' }}
          />
        ),
      },
      {
        title: '견적서 수정 이력',
        dataIndex: 'materialIcidApplicationEstimateId',
        align: 'center',
        render: (_, { materialIcidApplicationEstimateId }) => (
          <Tags.Mark
            type="arrow"
            color="blue"
            onClick={() =>
              openEstimateHistoriesModal({
                estimateId: materialIcidApplicationEstimateId,
                type: 'materialIcid',
              })
            }
          >
            확인하기
          </Tags.Mark>
        ),
      },
      {
        title: '추가 견적',
        align: 'center',
        render: (_, { additionals: estimateAdditionals }) =>
          estimateAdditionals && (
            <Tags.Mark
              type="arrow"
              color="blue"
              onClick={() =>
                openEstimateAdditionalsModal({
                  estimateAdditionals: estimateAdditionals.map(
                    ({
                      estimateAdditionalCode,
                      registerDt,
                      estimatePrice,
                      estimateFileUrl: fileUrl,
                    }) => ({
                      estimateAdditionalCode,
                      registerDt,
                      estimatePrice,
                      fileUrl,
                    }),
                  ),
                })
              }
            >
              확인하기
            </Tags.Mark>
          ),
      },
    ],
    [],
  );

  return (
    <>
      <Container>
        <Table
          columns={columns}
          dataSource={myICIDEstimates}
          rowKey="estimateCode"
          pagination={{
            current: page,
            total: totalElements,
            onChange: setPage,
          }}
        />
      </Container>
    </>
  );
};

export default ICIDEstimateList;

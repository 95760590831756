import { useQuery } from 'react-query';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { Agency } from 'types/agency';

export const useAgencies = ({ productId, countryId }: { productId: number; countryId: number }) => {
  const { data: agencies = [], isLoading } = useQuery(
    ['prod/agencies', productId, countryId],
    () =>
      client.get<APIResponse<Agency[]>>(`/products/${productId}/product-agencies`, {
        params: {
          countryId,
        },
      }),
    {
      select: (res) => res.data.result,
    },
  );

  return {
    agencies,
    isLoading,
  };
};

import PageTemplate from 'templates/PageTemplate';
import { UserInquiryForm } from 'components/common/InquiryForm';

const UserInquiryPage = () => {
  return (
    <PageTemplate
      back={false}
      title="이용 문의"
      description={`certicos 사용상의 문의 또는 궁금한 점은 아래에 내용을 기재하여 보내주세요.\n담당자 확인 후 빠른 시일 내에 연락드리겠습니다.`}
    >
      <UserInquiryForm />
    </PageTemplate>
  );
};

export default UserInquiryPage;

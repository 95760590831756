import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';

import {
  DocumentCode,
  FDAAccountItem,
  MyRPItem,
  Product,
  ProductArtworkCountry,
  ProductDuplicatedCheckParams,
  ProductETCCertifications,
  ProductRPItem,
  ProductUpdateParams,
  UsingProductRPItem,
} from 'types/product';
import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import * as productApi from 'lib/api/product';
import * as manufacturerProductApi from 'lib/api/manufacturer/product';
import { setCurrentDocStatus, setCurrentProduct } from 'modules/product';
import {
  fixDocument,
  getCertificate,
  setReadOnlyMode,
  toggleJapaneseProductNameModalVisible,
  toggleProductNameModalVisible,
} from 'modules/certificate';
import { useProductDocStatus } from 'service/product';
import { apiUrls, useBasicMutation, useBasicQuery } from 'service/core';
import { EfficacyEvaluationAttachUpload } from 'components/product/ProductEfficacyEvaluation';
import {
  ProductArtworkScreeningCorrections,
  ProductArtworkScreeningPages,
} from 'types/brand/product/artworkScreening';
import * as certificateActions from 'modules/certificate';
import useGA, { EGAActionType } from 'hook/useGA';
import path from 'lib/path';

export interface EfficacyChina {
  efficacyChinaId: number;
  efficacyName: string;
  descriptionCode:
    | 'PROCEEDING_WITHOUT_EFFICACY_EVALUATION'
    | 'THREE_EFFICACY_EVALUATION'
    | 'FOUR_EFFICACY_EVALUATION'
    | 'SPECIAL_EFFICACY'
    | 'ONE_EFFICACY_EVALUATION'
    | 'TWO_EFFICACY_EVALUATION';
}

export interface EfficacyChinaListItem {
  efficacyChinaId: number;
  productEfficacyChinaId: number;
}

export interface AddEfficacyChinaParams {
  countryId: number;
  efficacyChinaIds: number[];
}

export interface UpdateEfficacyChinaParams {
  countryId: number;
  productEfficacyChinas: {
    productEfficacyChinaId?: number;
    efficacyChinaId: number;
  }[];
}

export enum EfficacyDescriptionCode {
  PROCEEDING_WITHOUT_EFFICACY_EVALUATION = 'PROCEEDING_WITHOUT_EFFICACY_EVALUATION',
  THREE_EFFICACY_EVALUATION = 'THREE_EFFICACY_EVALUATION',
  FOUR_EFFICACY_EVALUATION = 'FOUR_EFFICACY_EVALUATION',
  SPECIAL_EFFICACY = 'SPECIAL_EFFICACY',
  ONE_EFFICACY_EVALUATION = 'ONE_EFFICACY_EVALUATION',
  TWO_EFFICACY_EVALUATION = 'TWO_EFFICACY_EVALUATION',
}

export interface ProductEfficacyChinaEvaluationListItem {
  productEfficacyChinaId: number;
  efficacyName: string;
  descriptionCode: EfficacyDescriptionCode;
  type: 'SPECIAL' | 'NON_SPECIAL';
  productEfficacyEvaluationChinaFileResponseDtos: {
    productEfficacyEvaluationDataChinaId: number;
    filename: string;
    uploadFileUrl: string;
  }[];
}

export interface UpdateProductEfficacyChinaEvaluation {
  countryId: number;
  productEfficacyEvaluationChinas: {
    productEfficacyChinaId: number;
    fileInfos: {
      productEfficacyEvaluationDataChinaId?: number;
      file?: File | EfficacyEvaluationAttachUpload;
    }[];
  }[];
}

export interface AddProductETCCertificationParams {
  countryId: number;
  files?: File[];
  deleteProductEtcCertificationIds?: number[];
}

const queryKeys = {
  useEfficacyChinaList: 'product/useEfficacyChinaList',
  useProductEfficacyChina: 'product/useProductEfficacyChina',
};

export const useRegisterProduct = () => {
  const navigate = useNavigate();
  const { mutate: registerProduct } = useMutation(productApi.updateProduct, {
    onSuccess: () => {
      navigate(path.product.registered);
    },
  });
  return {
    registerProduct,
  };
};

export const useSetCurrentProduct = ({
  productId,
  countryId,
  manuMode = false,
}: {
  productId?: number;
  countryId?: number;
  manuMode?: boolean;
}) => {
  const dispatch = useDispatch();
  useQuery(
    ['product/getProduct', productId, countryId],
    async () => {
      const res = !manuMode
        ? await productApi.getProduct({
            productId: productId!,
            countryId,
          })
        : await manufacturerProductApi.getProduct(productId!);
      dispatch(setCurrentProduct(res.data.result));
      return res;
    },
    {
      suspense: true,
      enabled: typeof productId !== 'undefined',
    },
  );
};

export const useSetCurrentProductDocStatus = ({
  productId,
  countryId,
  documentCode,
}: {
  productId?: number;
  countryId?: number;
  documentCode?: DocumentCode;
}) => {
  const dispatch = useDispatch();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });

  useEffect(() => {
    dispatch(
      setReadOnlyMode(
        typeof countryId !== 'undefined' &&
          productDocStatus !== null &&
          (productDocStatus.status === 'ONG' || productDocStatus.status === 'FIN'),
      ),
    );
    return () => {
      dispatch(setReadOnlyMode(false));
    };
  }, [productDocStatus]);

  useEffect(() => {
    dispatch(setCurrentDocStatus(productDocStatus));
  }, [productDocStatus]);
};

export const useCurrentProduct = () => {
  const currentProduct = useSelector(({ product }: any) => product.currentProduct as Product);
  return currentProduct;
};

export const useProductCountries = (productId: number) => {
  // 제품 상세정보 조회 (vprd002)
  const { data: productArtworkCountries, isLoading: isProductCountriesLoading } = useBasicQuery<
    ProductArtworkCountry[]
  >({
    apiUrl: apiUrls.productArtworkCountries,
    urlParams: { productId },
  });

  const countries = useMemo(() => {
    if (!productArtworkCountries) return undefined;
    const newArr = [...productArtworkCountries];

    newArr.sort(({ country: countryA }, { country: countryB }) =>
      countryA.countryNameKo < countryB.countryNameKo
        ? -1
        : countryA.countryNameKo > countryB.countryNameKo
        ? 1
        : 0,
    );

    return newArr;
  }, [productArtworkCountries]);

  return {
    productArtworkCountries: countries,
    isProductCountriesLoading,
  };
};

export const useCountryId = () => {
  const { search } = useLocation();
  return useMemo(() => {
    const query = new URLSearchParams(search);
    return Number(query.get('countryId')) || undefined;
  }, [search]);
};

export const useGetDuplicatedProduct = () => {
  const { mutate: getDuplicatedProduct, isLoading: getDuplicatedProductLoading } = useMutation(
    (params: ProductDuplicatedCheckParams) =>
      client.get<APIResponse<{ isDuplicate: boolean }>>(`/products/duplicate-check`, {
        params: {
          productNameEn: encodeURIComponent(params.productNameEn),
          netWeight: params.netWeight,
          netWeightUnit: params.netWeightUnit,
        },
      }),
  );
  return useMemo(
    () => ({ getDuplicatedProduct, getDuplicatedProductLoading }),
    [getDuplicatedProduct, getDuplicatedProductLoading],
  );
};

export const useDeleteProducts = () => {
  const { mutate: deleteProducts, isLoading: deleteProductsLoading } = useMutation(
    (productIds: number[]) => client.delete(`/products?productIds=${productIds}`),
  );

  return useMemo(
    () => ({ deleteProducts, deleteProductsLoading }),
    [deleteProducts, deleteProductsLoading],
  );
};

export const useUpdateProductName = ({
  productDocStatus,
  certTargetId,
}: {
  productDocStatus: any;
  certTargetId: number;
}) => {
  const dispatch = useDispatch();
  const { mutate: updateProductName, isLoading } = useMutation(
    (productUpdateParams: ProductUpdateParams) =>
      client.patch('/product', {
        ...productUpdateParams,
      }),
    {
      onSuccess: () => {
        dispatch(toggleProductNameModalVisible());
        if (productDocStatus?.status === 'MOD') {
          dispatch(fixDocument(productDocStatus.productDocStatusId));
          message.success('보완 완료되었습니다.');
        } else if (productDocStatus?.status === 'ONG') {
          dispatch(certificateActions.getDocuments(certTargetId));
          message.success('수정되었습니다.');
        } else {
          dispatch(certificateActions.getDocuments(certTargetId));
          message.success('등록되었습니다.');
        }
        dispatch(getCertificate(certTargetId));
      },
    },
  );

  return {
    updateProductName,
    isLoading,
  };
};

export const useUpdateJapaneseProductName = ({
  productDocStatus,
  certTargetId,
}: {
  productDocStatus: any;
  certTargetId: number;
}) => {
  const dispatch = useDispatch();
  const { mutate: updateProductName, isLoading } = useMutation(
    (productUpdateParams: ProductUpdateParams) => client.patch('/product', productUpdateParams),
    {
      onSuccess: () => {
        dispatch(toggleJapaneseProductNameModalVisible());
        if (productDocStatus?.status === 'MOD') {
          dispatch(fixDocument(productDocStatus.productDocStatusId));
          message.success('보완 완료되었습니다.');
        } else if (productDocStatus?.status === 'ONG') {
          dispatch(certificateActions.getDocuments(certTargetId));
          message.success('수정되었습니다.');
        } else {
          dispatch(certificateActions.getDocuments(certTargetId));
          message.success('등록되었습니다.');
        }
        dispatch(getCertificate(certTargetId));
      },
    },
  );

  return {
    updateProductName,
    isLoading,
  };
};

export const useEfficacyChinaList = () => {
  const { data: efficacyChinaList, isLoading } = useQuery(
    [queryKeys.useEfficacyChinaList],
    () => client.get<APIResponse<EfficacyChina[]>>('/v1/efficacy-china'),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  return {
    efficacyChinaList,
    isLoading,
  };
};

export const useProductEfficacyChina = ({ productId }: { productId: number }) => {
  const [initialData] = useState([]);

  const {
    data: productEfficacyChinaList = initialData,
    isLoading: isGetProductEfficacyChinaListLoading,
  } = useBasicQuery<EfficacyChinaListItem[]>({
    apiUrl: apiUrls.productEfficacyChina,
    urlParams: { productId },
  });

  const { mutate: addProductEfficacyChina, isLoading: isAddProductEfficacyChinaLoading } =
    useBasicMutation<AddEfficacyChinaParams>({
      apiUrl: apiUrls.productEfficacyChina,
      urlParams: { productId },
      refetchUrl: apiUrls.productEfficacyChina,
    });

  const { mutate: updateProductEfficacyChina, isLoading: isUpdateProductEfficacyChinaLoading } =
    useBasicMutation<UpdateEfficacyChinaParams>({
      apiUrl: apiUrls.productEfficacyChina,
      urlParams: { productId },
      refetchUrl: apiUrls.productEfficacyChina,
      method: 'patch',
    });

  return {
    productEfficacyChinaList,
    isGetProductEfficacyChinaListLoading,
    addProductEfficacyChina,
    isAddProductEfficacyChinaLoading,
    updateProductEfficacyChina,
    isUpdateProductEfficacyChinaLoading,
  };
};

export const useProductEfficacyEvaluation = (productId: number) => {
  const [initialData] = useState([]);
  const {
    data: productEfficacyChinaEvaluationListItems = initialData,
    isLoading: isGetproductEfficacyChinaEvaluationListItemsLoading,
  } = useBasicQuery<ProductEfficacyChinaEvaluationListItem[]>({
    apiUrl: apiUrls.productEfficacyChinaEvaluations,
    urlParams: { productId },
  });

  const {
    mutate: updateProductEfficacyChinaEvaluation,
    isLoading: isUpdateProductEfficacyChinaEvaluationLoading,
  } = useBasicMutation<UpdateProductEfficacyChinaEvaluation, null>({
    apiUrl: apiUrls.productEfficacyChinaEvaluations,
    urlParams: { productId },
    useFormData: true,
    refetchUrl: apiUrls.productEfficacyChinaEvaluations,
  });

  return {
    productEfficacyChinaEvaluationListItems,
    isGetproductEfficacyChinaEvaluationListItemsLoading,
    updateProductEfficacyChinaEvaluation,
    isUpdateProductEfficacyChinaEvaluationLoading,
  };
};

export const useProductArtworkScreeningPages = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const [initialData] = useState<ProductArtworkScreeningPages>();

  const {
    data: productArtworkScreeningPages = initialData,
    isLoading: isGetProductArtworkScreeningPages,
  } = useBasicQuery<ProductArtworkScreeningPages>({
    apiUrl: apiUrls.productArtworkScreeningPages,
    urlParams: { productId },
    qs: { countryId },
  });

  return {
    productArtworkScreeningPages,
    isGetProductArtworkScreeningPages,
  };
};

export const useProductArtworkScreeningCorrections = ({
  productId,
  countryId,
  page,
  productArtworkScreeningId,
}: {
  productId: number;
  countryId: number;
  productArtworkScreeningId?: number;
  page?: number;
}) => {
  const {
    data: productArtworkScreeningCorrections,
    isLoading: isGetProductArtworkScreeningCorrectionsLoading,
  } = useBasicQuery<ProductArtworkScreeningCorrections>({
    apiUrl: apiUrls.productArtworkScreeningCorrections,
    urlParams: { productId },
    qs: {
      countryId,
      productArtworkScreeningId,
      pageNo: page,
    },
    option: {
      enabled: productArtworkScreeningId !== undefined && page !== undefined,
    },
  });

  return {
    productArtworkScreeningCorrections,
    isGetProductArtworkScreeningCorrectionsLoading,
  };
};

export const useProductArtworkScreeningStatus = ({
  productId,
  countryId,
  countriesCanSeeAI,
}: {
  productId: number;
  countryId?: number;
  countriesCanSeeAI: boolean;
}) => {
  const { data: productArtworkScreeningStatus } = useBasicQuery<boolean | null>({
    apiUrl: apiUrls.productArtworkScreeningStatus,
    urlParams: {
      productId,
    },
    qs: {
      countryId,
    },
    option: {
      enabled: countryId !== undefined && countriesCanSeeAI,
    },
  });
  return {
    productArtworkScreeningStatus,
  };
};

export const useProductETCCertifications = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { data: productETCCertifications, isLoading: isProductETCCertificationsLoading } =
    useBasicQuery<ProductETCCertifications[]>({
      apiUrl: apiUrls.productETCCertifications,
      urlParams: { productId },
      qs: { countryId },
    });

  const { mutate: addProductETCCertifications, isLoading: isAddProductETCCertificationsLoading } =
    useBasicMutation<AddProductETCCertificationParams>({
      apiUrl: apiUrls.productETCCertifications,
      urlParams: { productId },
      useFormData: true,
      refetchUrl: apiUrls.productETCCertifications,
    });

  return {
    productETCCertifications,
    isProductETCCertificationsLoading,
    addProductETCCertifications,
    isAddProductETCCertificationsLoading,
  };
};

// 마이페이지 FDA 계정
export const useFDAAccount = ({ fdaAccountId }: { fdaAccountId?: number }) => {
  const { data: fdaAccount, isLoading: fdaAccountLoading } = useBasicQuery<FDAAccountItem>({
    apiUrl: apiUrls.fdaAccount,
  });

  const { mutate: addFDAAccount, isLoading: addFDAAccountLoading } = useBasicMutation<
    Omit<FDAAccountItem, 'fdaAccountId'>
  >({
    apiUrl: apiUrls.fdaAccount,
    refetchUrl: apiUrls.fdaAccount,
    method: 'post',
  });
  const { mutate: updateFDAAccount, isLoading: updateFDAAccountLoading } = useBasicMutation<
    Omit<FDAAccountItem, 'fdaAccountId'>
  >({
    apiUrl: apiUrls.updateFdaAccount,
    refetchUrl: apiUrls.fdaAccount,
    ...(fdaAccountId && {
      urlParams: {
        fdaAccountId,
      },
    }),
    method: 'patch',
  });

  return {
    fdaAccount,
    fdaAccountLoading,
    addFDAAccount,
    addFDAAccountLoading,
    updateFDAAccount,
    updateFDAAccountLoading,
  };
};
//제품 FDA
export const useProductFDAAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const navigate = useNavigate();
  const { sendEventToGA } = useGA();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.FDAAR,
  });

  const { data: productFDAAccount = null, isLoading: getProductFDAAccountLoading } = useBasicQuery({
    apiUrl: apiUrls.productFDAAccount,
    urlParams: {
      productId,
      countryId,
    },
  });

  const { mutate: addProductFDAccount, isLoading: addProductFDAccountLoading } = useBasicMutation({
    apiUrl: apiUrls.productFDAAccount,
    refetchUrl: apiUrls.productFDAAccount,
    urlParams: {
      productId,
      countryId,
    },
    method: 'put',
    option: {
      onSuccess: () => {
        sendEventToGA({
          documentName: '보유 FDA 계정 정보',
          actionType:
            productFDAAccount !== null && productDocStatus?.status !== 'INP'
              ? EGAActionType.MODIFY
              : EGAActionType.REGISTER,
        });
        if (productFDAAccount !== null && productDocStatus?.status !== 'INP') {
          message.success('보완 완료되었습니다.');
        } else {
          message.success('등록되었습니다.');
        }
        navigate(-1);
      },
    },
  });

  return useMemo(
    () => ({
      productFDAAccount,
      getProductFDAAccountLoading,
      addProductFDAccount,
      addProductFDAccountLoading,
      updateMode: productFDAAccount !== null && productDocStatus?.status !== 'INP',
      readOnlyMode: productFDAAccount !== null && productDocStatus?.status !== 'MOD',
    }),
    [productFDAAccount, addProductFDAccount, addProductFDAccountLoading, productDocStatus],
  );
};

// 마이페이지 RP
export const useMyAllRP = () => {
  const { data: myAllRP, isLoading: myAllRPLoading } = useBasicQuery<MyRPItem[]>({
    apiUrl: apiUrls.myRP,
  });

  return {
    myAllRP,
    myAllRPLoading,
  };
};
export const useMyRP = ({ responsiblePersonId }: { responsiblePersonId?: number }) => {
  const { data: myRPDetail, isLoading: myRPDetailLoading } = useBasicQuery<MyRPItem>({
    apiUrl: apiUrls.myRPDetail,
    ...(responsiblePersonId && {
      urlParams: {
        responsiblePersonId,
      },
    }),
    option: {
      enabled: !!responsiblePersonId,
    },
  });

  const { mutate: addMyRP, isLoading: addMyRPLoading } = useBasicMutation<
    Omit<MyRPItem, 'responsiblePersonId'>
  >({
    apiUrl: apiUrls.myRP,
    refetchUrl: [apiUrls.myRP, apiUrls.productRPs],
    method: 'post',
  });
  const { mutate: updateMyRP, isLoading: updateMyRPLoading } = useBasicMutation<
    Omit<MyRPItem, 'responsiblePersonId'>
  >({
    apiUrl: apiUrls.myRPDetail,
    refetchUrl: apiUrls.myRP,
    ...(responsiblePersonId && {
      urlParams: {
        responsiblePersonId,
      },
    }),
    method: 'patch',
  });

  const { mutate: deleteMyRP, isLoading: deleteMyRPLoading } = useBasicMutation<number[]>({
    apiUrl: apiUrls.myRP,
    method: 'delete',
    refetchUrl: apiUrls.myRP,
  });

  const { mutate: usingProductRPs } = useMutation((responsiblePersonIds: number[]) =>
    client.get<APIResponse<UsingProductRPItem[]>>(
      `/v1/responsible-persons/using-products?responsiblePersonIds=${responsiblePersonIds}`,
    ),
  );

  return {
    addMyRP,
    addMyRPLoading,
    updateMyRP,
    updateMyRPLoading,
    myRPDetail,
    myRPDetailLoading,
    deleteMyRP,
    deleteMyRPLoading,
    usingProductRPs,
  };
};

// 제품 RP
export const useProductRPs = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { sendEventToGA } = useGA();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.RP,
  });

  const { data: productRP = null, isLoading: getProductRPLoading } = useBasicQuery<ProductRPItem>({
    apiUrl: apiUrls.productRPs,
    urlParams: {
      productId,
    },
    qs: {
      countryId,
    },
  });

  const { mutate: addProductRP, isLoading: addProductRPLoading } = useMutation(
    ({ responsiblePersonId }: { responsiblePersonId: number }) =>
      client.put(`/v1/products/${productId}/responsible-person-infos?countryId=${countryId}`, {
        responsiblePersonId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([apiUrls.productRPs, productId, countryId]);
        sendEventToGA({
          documentName: 'RP정보',
          actionType:
            productRP !== null && productDocStatus?.status !== 'INP'
              ? EGAActionType.MODIFY
              : EGAActionType.REGISTER,
        });
        if (productRP !== null && productDocStatus?.status !== 'INP') {
          message.success('보완 완료되었습니다.');
        } else {
          message.success('등록되었습니다.');
        }
        navigate(-1);
      },
    },
  );

  return useMemo(
    () => ({
      productRP,
      getProductRPLoading,
      addProductRP,
      addProductRPLoading,
      updateMode: productRP !== null && productDocStatus?.status !== 'INP',
      readOnlyMode: productRP !== null && productDocStatus?.status !== 'MOD',
    }),
    [productRP, addProductRP, addProductRPLoading, productDocStatus],
  );
};

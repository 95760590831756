import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

import Table from 'components/ui/Table/Table';

const PostListBlock = styled.div`
  padding-top: 64px;
`;

const PostList = ({
  type,
  title,
  list,
  loading,
  onClick,
}: {
  type: 'faq' | 'notice' | 'useGuide';
  title: string;
  list: any[];
  loading: boolean;
  onClick: (post: any) => void;
}) => {
  return (
    <PostListBlock>
      <Typography.Title
        level={3}
        style={{ textAlign: 'center', fontWeight: 300, marginBottom: 32 }}
      >
        {title}
      </Typography.Title>
      <Table
        columns={[
          {
            title: '번호',
            dataIndex: `${type}Id`,
            align: 'center',
            width: 160,
          },
          {
            title: '제목',
            align: 'center',
            render: (item) => (
              <Row style={{ width: '100%' }}>
                <Col>
                  <Typography.Link onClick={() => onClick(item)}>{item.title}</Typography.Link>
                </Col>
              </Row>
            ),
          },
          {
            title: '작성자',
            align: 'center',
            width: 160,
            render: () => '관리자',
          },
        ]}
        dataSource={list}
        rowKey={(row) => row[`${type}Id`]}
        pagination={{ position: ['bottomCenter'] }}
        loading={loading}
      />
    </PostListBlock>
  );
};

export default PostList;

import { Button, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import { useManufacturer } from 'service/manufacturer/company';
import { ManufacturerDetail } from 'types/manufacturer/company';
import ManufacturerFormModal from './ManufacturerFormModal';

const ManufacturerManage = ({ manufacturerId }: { manufacturerId?: number }) => {
  const [modalVisible, setModalVisible] = useState(manufacturerId !== undefined);
  const [companyManufacturerId, setCompanyManufacturerId] = useState<number | undefined>(
    manufacturerId,
  );
  const [selectedManuIds, setSelectedManuIds] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  const { openAlertModal } = useModal();
  const {
    manufacturers,
    total,
    getManufacturersLoading,
    deleteManufacturers,
    deleteLoading,
    getQCQAProductsByManufacturers,
    getQCQAProductsByManufacturersLoading,
  } = useManufacturer({ page });

  const columns: ColumnsType<ManufacturerDetail> = [
    {
      title: '화장품제조업자',
      width: 280,
      onCell: ({ companyManufacturerId }) => ({
        onClick: () => {
          setCompanyManufacturerId(companyManufacturerId);
          setModalVisible(true);
        },
        style: { cursor: 'pointer' },
      }),
      render: (_, { manufacturerNameKo, manufacturerNameEn }) => (
        <Typography.Text type="BODY_2" medium color="GRAY90">
          {manufacturerNameKo || manufacturerNameEn || '-'}
        </Typography.Text>
      ),
    },
    {
      title: '화장품제조업자 주소',
      width: 400,
      onCell: ({ companyManufacturerId }) => ({
        onClick: () => {
          setCompanyManufacturerId(companyManufacturerId);
          setModalVisible(true);
        },
        style: { cursor: 'pointer' },
      }),
      render: (_, { manufacturerAddressKo, manufacturerAddressEn }) =>
        manufacturerAddressKo || manufacturerAddressEn || '-',
    },
    {
      title: '전화번호',
      width: 160,
      onCell: ({ companyManufacturerId }) => ({
        onClick: () => {
          setCompanyManufacturerId(companyManufacturerId);
          setModalVisible(true);
        },
        style: { cursor: 'pointer' },
      }),
      render: (_, { manufacturerTel }) => manufacturerTel || '-',
    },
  ];

  const handleClickDelete = () => {
    if (!selectedManuIds.length) return;

    getQCQAProductsByManufacturers(
      { companyManufacturerIds: selectedManuIds },
      {
        onSuccess: (res) => {
          const allManufacturers = res.data.result;
          const manufacturers = allManufacturers.filter(
            (manufacturer) => manufacturer.usedProducts.length !== 0,
          );

          if (manufacturers.length === 0) {
            openAlertModal({
              content: (
                <Typography.Text style={{ fontSize: '14px' }}>삭제하시겠습니까?</Typography.Text>
              ),
              okText: '삭제',
              okLoading: getQCQAProductsByManufacturersLoading,
              onOk: () => {
                deleteManufacturers(
                  {
                    companyManufacturerIds: selectedManuIds,
                  },
                  {
                    onSuccess: () => {
                      message.success('삭제되었습니다.');
                    },
                  },
                );
                setSelectedManuIds([]);
              },
            });
          } else {
            openAlertModal({
              width: 400,
              content: (
                <>
                  <Typography.Text type="BODY_2" style={{ textAlign: 'center', marginBottom: 16 }}>
                    아래 제품에 입력된 제조사이므로
                    <br />
                    삭제하실 수 없습니다.
                  </Typography.Text>
                  <ProductListContainer>
                    {manufacturers.map(
                      ({ usedProducts, manufacturerNameKo, companyManufacturerId }) => (
                        <div key={companyManufacturerId}>
                          <Typography.Text type="SMALL" medium color="PRIMARY50">
                            {manufacturerNameKo}
                          </Typography.Text>
                          <Flex gap={4} dir="column" gutter={{ top: 4 }}>
                            {usedProducts.map((product) => (
                              <Typography.Text
                                key={product.qcqaProductId}
                                style={{
                                  color: palette.SLATE_GRAY70,
                                  fontSize: 12,
                                  fontWeight: 400,
                                }}
                              >
                                {`QC - ${
                                  product.productNameKo || product.productNameEn
                                }: ${product.lotNos.map(({ lotNo }) => lotNo).join(', ')}`}
                              </Typography.Text>
                            ))}
                          </Flex>
                        </div>
                      ),
                    )}
                  </ProductListContainer>
                </>
              ),
              noCancelButton: true,
            });
          }
        },
      },
    );
  };

  return (
    <Container>
      {modalVisible && (
        <ManufacturerFormModal
          companyManufacturerId={companyManufacturerId}
          onClose={() => {
            setCompanyManufacturerId(undefined);
            setModalVisible(false);
          }}
        />
      )}
      <Flex gap={16} justify="flex-end">
        <Button
          type="primary"
          block
          icon={<Icon name="plus" size={14} color="ETC_WHITE" />}
          style={{
            width: '119px',
            height: 44,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          제조사 추가
        </Button>
        <StyledButton
          loading={deleteLoading}
          type="default"
          icon={<Icon name="delete" size={14} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleClickDelete}
        >
          선택 삭제
        </StyledButton>
      </Flex>
      <Table
        rowKey={({ companyManufacturerId }) => companyManufacturerId}
        rowSelection={{
          selectedRowKeys: selectedManuIds,
          onChange: (key) => setSelectedManuIds(key as number[]),
        }}
        columns={columns}
        dataSource={manufacturers}
        loading={getManufacturersLoading}
        pagination={{
          current: page,
          total,
          onChange: (page) => setPage(page),
        }}
        style={{
          marginTop: 16,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  th.ant-table-cell {
    padding: 10px 16px !important;
  }

  td.ant-table-cell {
    padding: 18px 16px !important;
  }

  .ant-table-selection-column {
    width: 80px !important;
  }
`;

const StyledButton = styled(Button)`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${palette.PRIMARY50};
      transition: all 200ms ease;
    }
  }

  svg,
  path {
    transition: all 200ms ease;
  }
`;
const ProductListContainer = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  background-color: ${palette.SLATE_GRAY10};
  padding: 16px 40px;
  text-align: start;
  max-height: 349px;
  overflow-y: scroll;
`;

export default ManufacturerManage;

import { useCallback, useState } from 'react';
import { message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SearchInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import { NMPARegisteredListItem, NMPARegisteredPageState } from 'types/material/nmpa/nmpa';
import usePreviewModal from 'hook/usePreviewModal';
import { useNMPARegistered } from 'service/material/nmpa/nmpa';
import path from 'lib/path';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const Container = styled.div`
  .ant-table-container table tr {
    td {
      height: 56px;
    }

    th {
      height: 40px;
    }

    td,
    th {
      padding: 0px 8px;
    }

    svg {
      cursor: pointer;
    }
  }
`;

const NMPARegistered = ({
  pageState,
  onChangePageState,
}: {
  pageState: NMPARegisteredPageState;
  onChangePageState: (state: NMPARegisteredPageState) => void;
}) => {
  const navigate = useNavigate();
  const { page, searchValue } = pageState;
  const [documentName, setDocumentName] = useState('');
  const { company } = useSelector(
    ({ company }: any) => ({
      company: company.company,
    }),
    shallowEqual,
  );
  const { PreviewModal, setUrl } = usePreviewModal({
    title: '원료 신고 자료',
  });

  const { nmpaRegisteredListItems, total, isLoading } = useNMPARegistered({
    page,
    tradeName: searchValue,
  });

  const previewDocument = (fileUrl: string, documentName: string) => {
    if (!fileUrl) {
      message.warning('파일이 존재하지 않습니다.');

      return;
    }

    setDocumentName(documentName);
    setUrl(fileUrl);
  };

  const handleChangePage = useCallback(
    (page: number) => {
      onChangePageState({
        page,
        searchValue,
      });
    },
    [searchValue],
  );

  const handleSearch = useCallback((searchValue: string) => {
    onChangePageState({
      page: 1,
      searchValue,
    });
  }, []);

  const columns: ColumnsType<NMPARegisteredListItem> = [
    {
      title: '취득 신청 번호',
      align: 'center',
      width: 156,
      render: (_, { applicationCode }) => (
        <Typography.Text medium type="BODY_2" color="GRAY90">
          {applicationCode}
        </Typography.Text>
      ),
    },
    {
      title: '원료명(상품명)',
      width: 320,
      dataIndex: 'tradeName',
      render: (_, { tradeName, materialNmpaBasicInfoId }) => (
        <Typography.Text
          medium
          type="BODY_2"
          color="GRAY90"
          onClick={() => navigate(`${path.material.nmpa.material.root}/${materialNmpaBasicInfoId}`)}
        >
          {tradeName}
        </Typography.Text>
      ),
    },
    {
      title: '원료 신고 번호',
      align: 'center',
      width: 156,
      render: (_, { declarationCode }) => (
        <Typography.Text medium type="BODY_2" color="GRAY90">
          {declarationCode}
        </Typography.Text>
      ),
    },
    {
      title: '원료 신고 자료',
      align: 'center',
      width: 96,
      render: (_, { declarationFileUrl, declarationCode, tradeName }) => (
        <Icon
          name="page"
          size={22}
          onClick={() =>
            previewDocument(
              declarationFileUrl,
              `${company.companyNameEn}_${tradeName}_${declarationCode}`,
            )
          }
          style={{ margin: '0 auto' }}
        />
      ),
    },
    {
      title: '취득 완료일',
      align: 'center',
      width: 96,
      render: (_, { declarationFinishDt }) => declarationFinishDt.slice(0, 10),
    },
  ];

  return (
    <Container>
      <PreviewModal documentName={documentName} />
      <Flex justify="flex-end">
        <SearchInput
          onSearch={handleSearch}
          defaultValue={searchValue}
          placeholder="원료명(상품명) 검색"
          style={{ width: 320 }}
        />
      </Flex>
      <Table
        style={{ marginTop: 16 }}
        loading={isLoading}
        dataSource={nmpaRegisteredListItems}
        columns={columns}
        pagination={{ current: page, onChange: handleChangePage, total: total }}
        rowKey="applicationCode"
      />
    </Container>
  );
};

export default NMPARegistered;

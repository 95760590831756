import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Form, Tabs } from 'antd';
import { MessageType } from 'antd/es/message/interface';

import { scrollToFirstErrorOption } from 'lib/consts';
import path from 'lib/path';
import FooterBox from 'components/FooterBox';
import SuperUserForm from 'components/auth/SuperUserForm';
import BrandForm from './BrandForm';
import { NoticeCard } from 'components/system';
import { BrandCompany } from 'types/brand/company';

const BrandRegisterForm = ({
  tabKey,
  setTabKey,
  updateMode,
  form,
  submitLoading,
  onSubmit,
  onClickChangePassword,
  brands,
  bizLicenseEnUrl,
}: {
  tabKey: string;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  updateMode: boolean;
  form: any;
  submitLoading: boolean;
  onSubmit: (formData: any) => MessageType | undefined;
  onClickChangePassword: () => void;
  brands: BrandCompany[];
  bizLicenseEnUrl: string;
}) => {
  const location = useLocation();
  const mypageRoute = location.pathname.includes(path.my);

  const handleClickNext = () => {
    form
      .validateFields()
      .then(() => {
        form.setFieldsValue({
          username: form.getFieldValue('bizNumber').replaceAll('-', ''),
        });
        setTabKey('user');
      })
      .catch((error: any) => {
        form.scrollToField(error.errorFields[0].name[0], scrollToFirstErrorOption);
      });
  };
  const handleClickBack = () => {
    setTabKey('company');
  };

  const FooterForm = (
    <FooterBox>
      {!updateMode && tabKey === 'company' && (
        <Button>
          <Link to={path.login}>취소</Link>
        </Button>
      )}
      {!updateMode && tabKey === 'user' && <Button onClick={handleClickBack}>뒤로</Button>}
      {!updateMode && tabKey === 'company' && (
        <Button type="primary" onClick={handleClickNext}>
          다음
        </Button>
      )}
      {(updateMode || tabKey === 'user') && (
        <Button type="primary" loading={submitLoading} htmlType="submit">
          {!updateMode ? '회원가입' : '수정'}
        </Button>
      )}
    </FooterBox>
  );

  const CompanyForm = (
    <Form
      form={form}
      layout="vertical"
      colon={false}
      scrollToFirstError={scrollToFirstErrorOption}
      onFinish={onSubmit}
      style={{ maxWidth: 688, margin: '0 auto' }}
    >
      <BrandForm
        isMyPage={!!mypageRoute}
        brands={brands}
        visible={updateMode || tabKey === 'company'}
        updateMode={updateMode}
        bizLicenseEnUrl={bizLicenseEnUrl}
      />
      {mypageRoute ? <>{FooterForm}</> : null}
    </Form>
  );

  const UserForm = (
    <>
      {mypageRoute && (
        <NoticeCard title="안내사항" style={{ marginTop: 0 }}>
          <ul>
            <li>
              슈퍼유저 계정은 회사 당 1개의 계정만 소유 가능하며, 모든 영역에서 최고 권한을 가진
              계정입니다.
            </li>
            <li>슈퍼유저는 하위 담당자를 추가하거나 삭제할 수 있습니다.</li>
          </ul>
        </NoticeCard>
      )}
      <Form
        form={form}
        layout="vertical"
        colon={false}
        scrollToFirstError={scrollToFirstErrorOption}
        onFinish={onSubmit}
        style={{ maxWidth: 688, margin: '0 auto' }}
      >
        {(updateMode || tabKey === 'user') && (
          <SuperUserForm
            form={form}
            updateMode={updateMode}
            onClickChangePassword={onClickChangePassword}
            onClickBack={handleClickBack}
          />
        )}
        {mypageRoute ? FooterForm : null}
      </Form>
    </>
  );

  return (
    <BrandRegisterFormBlock updateMode={updateMode}>
      {mypageRoute ? (
        <StyledTabs
          defaultActiveKey={'company'}
          onTabClick={(key) => setTabKey(key)}
          items={[
            {
              key: 'company',
              label: '회사 정보',
              children: CompanyForm,
            },
            {
              key: 'user',
              label: '슈퍼유저 정보',
              children: UserForm,
            },
          ]}
        />
      ) : (
        <Form
          form={form}
          layout="vertical"
          colon={false}
          scrollToFirstError={scrollToFirstErrorOption}
          onFinish={onSubmit}
          style={{ maxWidth: 688, margin: '0 auto' }}
        >
          {CompanyForm}
          {UserForm}
          {FooterForm}
        </Form>
      )}
    </BrandRegisterFormBlock>
  );
};

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 36px;
    cursor: pointer;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

const BrandRegisterFormBlock = styled.div<{ updateMode: boolean }>`
  max-width: 1040px;
  margin: 0 auto;
  ${(props) =>
    !props.updateMode &&
    css`
      padding: 40px 0;
    `}
  .ant-tabs {
    overflow: visible !important;
  }
`;

export default BrandRegisterForm;

import { message, Modal, notification } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import ProductCountry from 'components/product/ProductCountry';
import { getDocuments, setCurrentProduct } from 'modules/product';
import { messages } from 'lib/consts';
import UpdateLog from 'components/product/UpdateLog';
import { useCountries } from 'service/country';
import { Typography } from 'components/system';
import { useProductCountry } from 'service/product';
import * as productApi from 'lib/api/product';
import path from 'lib/path';
import useGA, { EGAActionType } from 'hook/useGA';
import palette from 'lib/styles/palette';
import { DocStatus, DocumentCode, ProductStatus } from 'types/product';
import { useMovePathname } from 'hook/useMovePathname';

const NotificationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -48px;
  cursor: pointer;
`;

const ProductCountryContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countries = useCountries();
  const { sendEventToGA } = useGA();
  const {
    getProductCountries,
    getProductCountriesLoading,
    addProductCountry,
    addProductCountryAsync,
    addProductCountryLoading,
    deleteProductCountry,
    deleteProductCountryAsync,
    deleteProductCountryLoading,
  } = useProductCountry();
  const { currentProduct } = useSelector(
    ({ product }) => ({
      currentProduct: product.currentProduct,
    }),
    shallowEqual,
  );
  const { movePathname } = useMovePathname();

  const productId = currentProduct?.productId;
  const productCountries = currentProduct?.productCountries;

  const updateMode = !!productCountries;

  const savedCountryIds = !updateMode
    ? []
    : productCountries
    ? productCountries?.map(({ countryId }: { countryId: number }) => countryId)
    : [];

  const [selectedCountryIds, setSelectedCountryIds] = useState<number[]>(savedCountryIds);

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = useMemo(() => _.debounce(setSearchKeyword, 300), []);
  const approvedCountries = useMemo(
    () =>
      countries.map((country) => ({
        ...country,
        isOriginal: savedCountryIds?.includes(country.countryId),
      })),
    [countries, savedCountryIds],
  );

  const deselectedCountries = useMemo(
    () =>
      approvedCountries.filter(
        ({ countryId, countryNameKo }) =>
          !selectedCountryIds?.includes(countryId) &&
          countryNameKo.includes(searchKeyword.replace(/[ㄱ-ㅎㅏ-ㅣ]/g, '').toUpperCase()),
      ),
    [approvedCountries, selectedCountryIds, searchKeyword],
  );
  const selectedCountries = useMemo(
    () =>
      approvedCountries
        .filter(({ countryId }) => selectedCountryIds?.includes(countryId))
        .sort((a, b) => (a.isOriginal ? -1 : 1)),
    [approvedCountries, selectedCountryIds],
  );
  const isSearching = useMemo(
    () => countries.length - selectedCountries?.length !== deselectedCountries.length,
    [countries, selectedCountries, deselectedCountries],
  );
  const selectCountry = useCallback((targetIds: number) => {
    setSelectedCountryIds((draft) => draft?.concat(targetIds));
  }, []);
  const deselectCountry = useCallback((targetId: number) => {
    setSelectedCountryIds((draft) => draft.filter((countryId) => countryId !== targetId));
  }, []);

  const onSubmit = () => {
    if (selectedCountryIds.length === 0) {
      return message.warning('한 개 이상의 국가를 선택해 주세요');
    }
    if (!updateMode) {
      addProductCountry(
        {
          productId,
          countryIds: selectedCountryIds,
        },
        {
          onSuccess: (res) => {
            sendEventToGA({
              documentName: '국가 선택',
              actionType: EGAActionType.REGISTER,
            });
            dispatch(
              setCurrentProduct({
                ...currentProduct,
                productCountries: res.data.result,
              }),
            );
            dispatch(getDocuments(currentProduct.productId));
            movePathname({ productId: currentProduct.productId });
          },
        },
      );
      return;
    }

    const newCountryIds = selectedCountryIds.filter(
      (countryId) => !savedCountryIds.includes(countryId),
    );

    if (currentProduct.status === 'PRD-RDY') {
      // HINT: 수정모드 + 등록 중인 제품 => 국가 추가/삭제 가능
      const deletedCountryIds = savedCountryIds.filter(
        (countryId: number) => !selectedCountryIds.includes(countryId),
      );
      if (!newCountryIds.length && !deletedCountryIds.length) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }

      const deletedProductCountryIds = currentProduct.productCountries
        .filter(({ countryId }: { countryId: number }) => deletedCountryIds.includes(countryId))
        .map(({ productCountryId }: { productCountryId: number }) => productCountryId);

      // HINT : 국가의 삭제만 일어나는 경우(newCountryIds가 없고 deletedCountryIds만 있을 때)는 모달이 띄워지지 않음.
      if (!newCountryIds.length && deletedCountryIds.length) {
        deleteProductCountry(deletedProductCountryIds, {
          onSuccess: () => {
            message.success('수정되었습니다.');

            if (!currentProduct) return;
            getProductCountries(currentProduct.productId, {
              onSuccess: (res) => {
                sendEventToGA({
                  documentName: '국가 선택',
                  actionType: EGAActionType.UPDATE,
                });
                dispatch(
                  setCurrentProduct({
                    ...currentProduct,
                    productCountries: res.data.result,
                  }),
                );
                navigate(`${path.product.root}/${productId}`);
              },
            });
          },
        });
        return;
      }
      Modal.confirm({
        icon: null,
        content: (
          <Typography.Text style={{ display: 'block', textAlign: 'center' }}>
            국가를 추가하는 경우, 추가로 서류 등록이 필요
            <br />할 수 있습니다. 추가하시겠습니까?
          </Typography.Text>
        ),
        onOk: () => {
          addProductCountryAsync(
            {
              productId,
              countryIds: newCountryIds,
            },
            {
              onSuccess: () => {
                message.success('수정되었습니다.');

                // HINT : 국가의 추가가 있는 경우만 메세지 생성.
                if (!(!newCountryIds.length && deletedProductCountryIds.length)) {
                  message.info(
                    '추가 국가의 규제 및 사용 여부를 Product Formula Breakdown 에서 확인해 주세요.',
                  );

                  if (!currentProduct) return;
                  getProductCountries(currentProduct.productId, {
                    onSuccess: (res) => {
                      sendEventToGA({
                        documentName: '국가 선택',
                        actionType: EGAActionType.UPDATE,
                      });
                      dispatch(
                        setCurrentProduct({
                          ...currentProduct,
                          productCountries: res.data.result,
                        }),
                      );
                      navigate(`${path.product.root}/${productId}`);
                    },
                  });
                }
                if (deletedProductCountryIds.length > 0) {
                  deleteProductCountryAsync(deletedProductCountryIds).then(() => {
                    if (!currentProduct) return;
                    getProductCountries(currentProduct.productId, {
                      onSuccess: (res) => {
                        sendEventToGA({
                          documentName: '국가 선택',
                          actionType: EGAActionType.UPDATE,
                        });
                        dispatch(
                          setCurrentProduct({
                            ...currentProduct,
                            productCountries: res.data.result,
                          }),
                        );
                        navigate(`${path.product.root}/${productId}`);
                      },
                    });
                  });
                }
              },
            },
          );
        },
      });
    } else {
      // HINT: 수정모드 + 등록완료 제품 : 국가 추가만 가능, 경고 팝업 필요
      if (!newCountryIds.length) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }
      Modal.confirm({
        icon: null,
        content: (
          <>
            <Typography.Text style={{ display: 'block', textAlign: 'center' }}>
              국가를 추가하는 경우, 추가로 서류 등록이 필요
              <br />할 수 있습니다. 추가하시겠습니까?
            </Typography.Text>
            <Typography.Text
              style={{ display: 'block', textAlign: 'center' }}
              type="SMALL"
              gutter={{ top: 4 }}
            >
              (추가 서류 등록이 필요한 경우 해당 제품이
              <br /> ‘등록 중 제품 관리’로 이동합니다.)
            </Typography.Text>
          </>
        ),
        onOk: () => {
          addProductCountry(
            {
              productId,
              countryIds: newCountryIds,
            },
            {
              onSuccess: () => {
                sendEventToGA({
                  documentName: '국가 선택',
                  actionType: EGAActionType.UPDATE,
                });
                updateInfo();
                if (!currentProduct) return;
                getProductCountries(currentProduct.productId, {
                  onSuccess: (res) => {
                    dispatch(
                      setCurrentProduct({
                        ...currentProduct,
                        productCountries: res.data.result,
                      }),
                    );
                  },
                });
              },
            },
          );

          const updateInfo = async () => {
            const response = await productApi.getDocuments(productId);
            const documents = response.data.result;
            if (documents.find(({ status }: { status: DocStatus }) => status === 'INP')) {
              await productApi.updateProduct({
                productId,
                status: ProductStatus.PRD_RDY,
              });
              navigate(path.product.ready, { replace: true });
              message.info("추가 서류 등록이 필요하여 제품이 '등록 중 제품 관리'로 이동합니다.", 5);
              message.info(
                '추가 국가의 규제 및 사용 여부를 Product Formula Breakdown 에서 확인해 주세요.',
              );
              return;
            } else {
              message.success('수정되었습니다.');
              navigate(-1);
              const filteredCountries = countries.filter(({ countryId }) =>
                newCountryIds.includes(countryId),
              );

              notification.info({
                style: {
                  backgroundColor: palette.ETC_WHITE,
                  padding: '16px 24px',
                  boxShadow:
                    '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
                },
                icon: <></>,
                message: null,
                description: (
                  <NotificationItem onClick={() => navigate(path.estimate.pick)}>
                    <Typography.Text style={{ marginBottom: 8 }}>제품 등록</Typography.Text>
                    <Typography.Text type="BODY_2">
                      <b>{`${currentProduct.productDetail.productNameEn} ${
                        currentProduct.netWeight
                          ? `(${currentProduct.netWeight} ${currentProduct.netWeightUnit})`
                          : '( - )'
                      }`}</b>{' '}
                      제품에{' '}
                      <b>
                        {`[${filteredCountries[0].countryNameKo}${
                          filteredCountries.length > 1
                            ? ` 외 ${filteredCountries.length - 1}개 국가`
                            : ''
                        }]`}
                      </b>{' '}
                      추가가 완료되었습니다. <br />
                      <b>인증 신청 &gt; 셀프 견적</b>
                      에서 해당 제품의 견적을 받을 수 있습니다.
                    </Typography.Text>
                  </NotificationItem>
                ),
                duration: 0,
              });
            }
          };
        },
      });
    }
  };

  const resetCountries = useCallback(() => {
    setSelectedCountryIds(savedCountryIds);
  }, []);

  return (
    <>
      {updateMode && <UpdateLog productId={productId} documentCode={DocumentCode.COUNTRY} />}
      <ProductCountry
        updateMode={updateMode}
        registered={currentProduct?.status === 'PRD-REG'}
        deselectedCountries={deselectedCountries}
        selectedCountries={selectedCountries}
        selectedCountryIds={selectedCountryIds}
        isSearching={isSearching}
        fetchLoading={
          getProductCountriesLoading || addProductCountryLoading || deleteProductCountryLoading
        }
        onSelectCountry={selectCountry}
        onDeselectCountry={deselectCountry}
        onSubmit={onSubmit}
        onReset={resetCountries}
        onSearch={handleSearch}
      />
    </>
  );
};

export default ProductCountryContainer;

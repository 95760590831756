import { Button, Col, message, Row, Upload } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import produce from 'immer';

import FileList from 'components/file/FileList';
import FileViewer from 'components/file/FileViewer';
import { Typography } from 'components/system';
import { DocumentCode, Document } from 'types/brand/retailer/retailerApply';
import { useDocuments } from 'service/brand/retailer/retailerApply';
import FooterBox from 'components/FooterBox';
import { useNavigate } from 'react-router-dom';

const Container = styled.div``;

const ProductCMBC = ({ productId }: { productId: number }) => {
  const navigate = useNavigate();
  const { documents, uploadDocuments, uploadLoading } = useDocuments({
    productId,
    documentCode: DocumentCode.CMBC,
  });
  const [previewedFile, setPreviewedFile] = useState<File | null>(null);
  const [files, setFiles] = useState<(File | Document)[]>([]);
  const handleUpload = (file: File, index: 0 | 1) => {
    setPreviewedFile(file);
    setFiles((draft) =>
      produce(draft, (proxy) => {
        proxy[index] = file;
      }),
    );
  };

  const handleSubmit = () => {
    if (!files[0]) {
      return message.warning('앞면을 업로드해 주세요.');
    }
    if (!files[1]) {
      return message.warning('뒷면을 업로드해 주세요.');
    }
    uploadDocuments(files, {
      onSuccess: () => navigate(-1),
    });
  };

  useEffect(() => {
    if (documents.length > 0) {
      setFiles(documents);
    }
  }, [documents]);

  return (
    <Container>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <FileList
            header={
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="BODY_2" gutter={{ bottom: 8 }}>
                    화장품 제조업 등록필증 (앞면)
                  </Typography.Text>
                </Col>
                <Col>
                  <Upload
                    accept="application/pdf"
                    beforeUpload={(file) => {
                      handleUpload(file, 0);
                      return false;
                    }}
                    itemRender={() => null}
                  >
                    <Button size="small" style={{ height: 24 }}>
                      파일 선택
                    </Button>
                  </Upload>
                </Col>
              </Row>
            }
            selectedFile={previewedFile}
            files={files[0]}
            onFileClick={setPreviewedFile}
          />
          <FileList
            header={
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="BODY_2" gutter={{ bottom: 8 }}>
                    화장품 제조업 등록필증 (뒷면)
                  </Typography.Text>
                </Col>
                <Col>
                  <Upload
                    accept="application/pdf"
                    beforeUpload={(file) => {
                      handleUpload(file, 1);
                      return false;
                    }}
                    itemRender={() => null}
                  >
                    <Button size="small" style={{ height: 24 }}>
                      파일 선택
                    </Button>
                  </Upload>
                </Col>
              </Row>
            }
            selectedFile={previewedFile}
            files={files[1]}
            onFileClick={setPreviewedFile}
            style={{ marginTop: 24 }}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <FileViewer file={previewedFile} bodyStyle={{ height: 556 }} />
        </Col>
      </Row>
      <FooterBox>
        <Button loading={uploadLoading} type="primary" onClick={handleSubmit}>
          {documents.length > 0 ? '수정' : '등록'}
        </Button>
      </FooterBox>
    </Container>
  );
};

export default ProductCMBC;

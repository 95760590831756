import { Button, Form, InputNumber, message, Radio } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';

import {
  Flex,
  OrderList,
  OrderListItem,
  OrderListItemContent,
  OrderListItemLabel,
} from 'components/ui';
import palette from 'lib/styles/palette';
import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import path from 'lib/path';
import { useNMPAChecklist } from 'service/material/nmpa/nmpa';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
  p {
    letter-spacing: -0.5px !important;
  }
  svg {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .ant-radio-wrapper {
    line-height: 42px;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  height: 44px;
  background: ${palette.SLATE_GRAY30};
  .ant-input-number-handler-wrap {
    display: none;
  }
  input {
    padding: 0 16px;
    color: ${palette.SLATE_GRAY70};
    font-weight: 500;
    &::placeholder {
      font-weight: 400;
    }
  }
  * {
    background: ${palette.SLATE_GRAY30};
    border-radius: 4px;
    height: 100%;
  }
  &:hover,
  &:focus,
  &:active {
    background: ${palette.SLATE_GRAY30};
  }
`;

const IconContainer = styled(Flex)`
  position: absolute;
  width: 16px;
  height: 22px;
  right: 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const ArrowUpContainer = styled(IconContainer)`
  top: 0;
`;

const ArrowDownContainer = styled(IconContainer)`
  top: 22px;
`;

const StyledRadioGroup = styled(Radio.Group)<{ dir?: 'column' | 'row' }>`
  flex-direction: ${({ dir }) => (dir ? dir : 'row')};
  margin: 0 29px;
  label {
    flex: 1;
  }
`;

const AdditionalInfo = styled.div`
  margin-top: 16px;
  padding: 11px 24px;
  background: ${palette.SLATE_GRAY10};
  border-radius: 8px;
`;

const NMPAChecklist = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [finished, setFinished] = useState(false);
  const [rawMaterialCount, setRawMaterialCount] = useState<number>();
  const {
    originalChecklist,
    checklist,
    setChecklist,
    makeChecklistItem,
    getNMPAEstimate,
    getNMPAEstimateLoading,
  } = useNMPAChecklist();

  const handleSubmit = (formValues: { [index: number]: boolean }) => {
    if (rawMaterialCount) {
      getNMPAEstimate(
        {
          rawMaterialCount,
          checklists: Object.entries(formValues).map(([countryChecklistId, isYes]) => ({
            countryChecklistId: Number(countryChecklistId),
            isYes,
          })),
        },
        {
          onSuccess: ({
            data: {
              result: { materialNmpaEstimateId, estimateFileUrl },
            },
          }) => {
            navigate(`${path.material.nmpa.estimate.root}/${materialNmpaEstimateId}`, {
              state: { estimateFileUrl },
            });
          },
        },
      );
    }
  };

  const handleClickRadio = ({
    countryChecklistId,
    nextId,
    index,
    yesChecked,
  }: {
    countryChecklistId: number;
    nextId: number | null;
    index: number;
    yesChecked: boolean;
  }) => {
    let newChecklist;

    newChecklist = checklist.slice(0, index + 1).map((item) => {
      if (item.countryChecklistId === countryChecklistId) {
        return { ...item, answer: yesChecked };
      }
      return item;
    });

    form.resetFields(checklist.slice(index + 1).map((item) => item.countryChecklistId));

    const nextChecklistItem = originalChecklist.find(
      ({ countryChecklistId }) => countryChecklistId === nextId,
    );

    if (nextChecklistItem) {
      newChecklist = newChecklist.concat(makeChecklistItem([nextChecklistItem]));
    }

    setChecklist(newChecklist);

    if (!nextId) {
      setFinished(true);
    } else {
      setFinished(false);
    }
  };

  return (
    <Container>
      <Form onFinish={handleSubmit} form={form}>
        <OrderList rowGap={45}>
          <OrderListItem>
            <OrderListItemLabel type="TITLE_2">
              취득을 원하시는 원료는 몇 개 인가요?
            </OrderListItemLabel>
            <OrderListItemContent gutter={{ top: 20 }}>
              <div style={{ position: 'relative' }}>
                <StyledInputNumber
                  onChange={(e) => setRawMaterialCount(Number(e) > 0 ? Number(e) : undefined)}
                  value={rawMaterialCount}
                  placeholder="숫자 입력"
                  bordered={false}
                  min={1}
                  maxLength={3}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                <ArrowUpContainer
                  justify="center"
                  align="center"
                  onClick={() => {
                    if (rawMaterialCount && rawMaterialCount > 998)
                      return message.warning('최대 999개까지 가능합니다.');
                    setRawMaterialCount(rawMaterialCount ? rawMaterialCount + 1 : 1);
                  }}
                >
                  <Icon name="up" color="SLATE_GRAY60" size={16} />
                </ArrowUpContainer>
                <ArrowDownContainer
                  justify="center"
                  align="center"
                  onClick={() =>
                    setRawMaterialCount(
                      rawMaterialCount && rawMaterialCount > 1 ? rawMaterialCount - 1 : 1,
                    )
                  }
                >
                  <Icon name="down" color="SLATE_GRAY60" size={16} />
                </ArrowDownContainer>
              </div>
            </OrderListItemContent>
          </OrderListItem>
          {typeof rawMaterialCount !== 'undefined' &&
            checklist?.map(
              (
                { countryChecklistId, questionMsg, questionSubMsg, yesNextId, noNextId, noMsg },
                index,
              ) => (
                <OrderListItem key={countryChecklistId}>
                  <OrderListItemLabel type="TITLE_2">{questionMsg}</OrderListItemLabel>
                  <OrderListItemContent gutter={{ top: 20 }}>
                    <Form.Item name={countryChecklistId}>
                      <StyledRadioGroup dir="column">
                        <Radio
                          value={true}
                          onClick={() =>
                            handleClickRadio({
                              countryChecklistId,
                              nextId: yesNextId,
                              index,
                              yesChecked: true,
                            })
                          }
                        >
                          네
                        </Radio>
                        <Radio
                          value={false}
                          onClick={() =>
                            handleClickRadio({
                              countryChecklistId,
                              nextId: noNextId,
                              index,
                              yesChecked: false,
                            })
                          }
                        >
                          아니오
                        </Radio>
                      </StyledRadioGroup>
                    </Form.Item>
                    {noMsg && checklist[index].answer === false && (
                      <AdditionalInfo>
                        <Typography.Text type="SMALL" color="SLATE_GRAY70">
                          {noMsg.replace(/(?=\s\().*/, '')}
                          <Typography.Text inline type="SMALL" color="PRIMARY50">
                            {noMsg.replace(/.*(?=\s\()/, '')}
                          </Typography.Text>
                        </Typography.Text>
                      </AdditionalInfo>
                    )}
                    {questionSubMsg && checklist[index].answer === false && (
                      <AdditionalInfo>
                        <Typography.Text type="SMALL" color="SLATE_GRAY70">
                          {questionSubMsg.split('.')[0]}.
                          <Typography.Text inline type="SMALL" color="PRIMARY50">
                            {' '}
                            {questionSubMsg.split('.')[1]}
                          </Typography.Text>
                        </Typography.Text>
                      </AdditionalInfo>
                    )}
                  </OrderListItemContent>
                </OrderListItem>
              ),
            )}
        </OrderList>
        {typeof rawMaterialCount !== 'undefined' && finished && (
          <FooterBox>
            <Button loading={getNMPAEstimateLoading} type="primary" htmlType="submit">
              견적 확인하기
            </Button>
          </FooterBox>
        )}
      </Form>
    </Container>
  );
};

export default NMPAChecklist;

import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, message, Modal, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import Chat from './Chat';
import FileListItem from './FileListItem';
import { Flex } from 'components/ui';
import { useAnswerPCPCQuestion, usePCPCQuestions } from 'service/material/icid/ongoing';
import Icon from 'components/ui/Icon/Icon';

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .ant-upload.ant-upload-select {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${palette.PRIMARY50};
  gap: 4px;
  width: 100%;
`;

const FileListContainer = styled.div`
  margin-top: 16px;

  div ~ div {
    margin-top: 16px;
  }
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 283px;
  overflow: auto;

  div ~ div {
    margin-top: 16px;
  }
`;

const PCPCQuestionModal = ({
  icidApplicationId,
  onClose,
}: {
  icidApplicationId: number;
  onClose: () => void;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [answer, setAnswer] = useState('');
  const pcpcQuestions = usePCPCQuestions(icidApplicationId);
  const { answerPCPCQuestion } = useAnswerPCPCQuestion();

  const handleDelete = useCallback((file: File) => {
    setFiles((draft) => draft.filter((prevFile) => prevFile.name !== file.name));
  }, []);
  const handleSubmit = () => {
    if (!pcpcQuestions) {
      return message.warning('관리자의 답변이 없습니다.');
    }
    if (answer.length === 0) {
      message.warning('PCPC Question 을 입력해 주세요.');
      return;
    }

    const parentId =
      pcpcQuestions.pcpcQuestions[pcpcQuestions.pcpcQuestions.length - 1]
        ?.materialIcidApplicationPcpcQuestionId;

    if (!parentId) {
      return message.warning('관리자의 답변이 없습니다.');
    }
    answerPCPCQuestion(
      {
        icidApplicationId,
        parentMaterialIcidApplicationPcpcQuestionId: parentId,
        content: answer,
        attachFiles: files,
      },
      {
        onSuccess: () => {
          message.success('등록 완료되었습니다.');
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      open
      onCancel={onClose}
      footer={null}
      maskClosable={false}
      title="PCPC Question"
      width={688}
      styles={{ body: { padding: '16px 24px 32px' } }}
    >
      <Flex align="center" gap={14} style={{ marginBottom: 16 }} wrap="true">
        <Flex gap={4} align="center" style={{ maxWidth: '100%' }}>
          <Typography.Text type="SMALL" color="SLATE_GRAY70" style={{ whiteSpace: 'nowrap' }}>
            원료명(영문)
          </Typography.Text>
          <Typography.Text medium>{pcpcQuestions?.tradeName}</Typography.Text>
        </Flex>
        <Flex gap={4} align="center" style={{ maxWidth: '100%' }}>
          <Typography.Text type="SMALL" color="SLATE_GRAY70" style={{ whiteSpace: 'nowrap' }}>
            카테고리
          </Typography.Text>
          <Typography.Text medium>{pcpcQuestions?.categoryName}</Typography.Text>
        </Flex>
      </Flex>
      <ChatContainer>
        {pcpcQuestions?.pcpcQuestions.map(
          ({ content, registerDt, materialIcidApplicationPcpcQuestionAttaches }) => (
            <Chat
              content={content}
              registerDt={registerDt}
              attaches={materialIcidApplicationPcpcQuestionAttaches}
            />
          ),
        )}
      </ChatContainer>
      <TextArea
        onChange={(e) => setAnswer(e.target.value)}
        placeholder="PCPC Question 을 입력해 주세요. 등록 버튼을 누르시면 해당 답변이 즉시 등재 담당자에게 전달됩니다."
        style={{ marginTop: 16, height: 120, resize: 'none' }}
        maxLength={5000}
      />
      <Typography.Text
        align="right"
        type="SMALL"
        color="GRAY50"
      >{`${answer.length} / 5,000`}</Typography.Text>
      <UploadWrapper>
        <Upload
          beforeUpload={(file) => {
            setFiles((draft) => draft.concat(file));
            return false;
          }}
          listType="picture"
          itemRender={() => null}
        >
          <StyledButton icon={<Icon name="plus" size={16} color="PRIMARY50" />}>
            {files.length > 0 ? '추가 업로드' : '파일 업로드'}
          </StyledButton>
        </Upload>
        {files.length > 0 && (
          <FileListContainer>
            {files.map((file) => (
              <FileListItem key={file.name} file={file} onDelete={handleDelete} />
            ))}
          </FileListContainer>
        )}
        <Button
          disabled={answer.length === 0}
          type="primary"
          style={{ width: 200, height: 56, marginTop: 24, alignSelf: 'center' }}
          onClick={handleSubmit}
        >
          등록
        </Button>
      </UploadWrapper>
    </Modal>
  );
};

export default PCPCQuestionModal;

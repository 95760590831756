export interface Product {
  productId: number;
  companyId: number;
  status: string;
  manufacturerStatus?: string;
  netWeight: number;
  netWeightUnit: string;
  registerDt: string;
  updateDt?: string;
  manufacturerId?: number;
  manufacturerItemNo?: string;
  manufacturerItemNameKo?: string;
  brandCompanyNameKo?: string;
  manufacturerZipCode?: string;
  companyFactoryId?: number;
  authCode: any;
  authenticatedBrandCompanyId?: number;
  isUsePackingAttestation?: boolean;
  packingAttestationType?: string;
  productCountries: ProductCountry[];
  productLotNos: any;
  productLabNos: any;
  productDetail: any;
  productCategory: ProductCategory;
}

export interface ManufacturerProduct extends Product {
  isUsedProductSample: boolean;
  productSampleId: number | null;
  productSampleRegisterUserName: string | null;
  productSampleRegisterUserDeptName: string | null;
}

export interface ProductCategory {
  productCategoryId: number;
  productId: number;
  cosmeticCategoryIdDepth1: number;
  cosmeticCategoryIdDepth2: number;
  isFunctional: boolean;
  functionalCategoryId: number;
}

export interface ProductCountry {
  productCountryId: number;
  productId: number;
  countryId: number;
  country: Country;
  productNameKo?: string;
  productNameEn?: string;
  registerDt: string;
  updateDt?: string;
}

export interface Country {
  countryId: number;
  countryCode: string;
  countryNameKo?: string;
  countryNameEn?: string;
  programCost: any;
  isHaveChecklist: boolean;
  functionalCertAuthority: any;
  nonFunctionalCertAuthority: any;
}
export interface ProductBasicForm {
  isForEurope: boolean;
  isForChina: boolean;
  isForUs: boolean;
  brandCompanyNameKo: string;
  manufacturerItemNameKo: string;
  manufacturerItemNo: string;
  companyFactoryId: number;
  addressEn: string;
  labNo: string;
  netWeight: number;
  netWeightUnit: string;
  netWeightUnitDirect: string;
  isUsedProductSample: boolean;
  productSampleId: number | null;
  manufacturerZipCode: string;
}

export interface ProductBasicAdd extends Omit<ProductBasicForm, 'netWeightUnitDirect'> {
  manufacturerId: number;
}

export interface ProductBasicUpdate extends ProductBasicAdd {
  productId: number;
}

export enum ProductStatus {
  PRD_RDY = 'PRD_RDY',
  PRD_REG = 'PRD_REG',
}

export enum ProductSearchType {
  ALL = 'all',
  MANUFACTURER_ITEM_NAME_KO = 'manufacturerItemNameKo',
  BRAND_COMPANY_NAME_KO = 'brandCompanyNameKo',
  SAMPLE_LAB_NO = 'sampleLabNo',
}

export interface ProductSearchParams {
  page: number;
  searchType: ProductSearchType;
  searchKeyword: string;
  status: ProductStatus;
}
export interface ProductInputRequest {
  inputRequestId: number;
  productId: number;
  countryId: number;
  documentCode: string;
  documentSeqId: number;
  fromUserId: number;
  fromUserName: string;
  toUserId: number;
  toUserName: string;
  status: string;
}

export interface ProductInputRequestUser {
  deptName: string;
  isAlreadyRequest: boolean;
  name: string;
  positionName: string;
  userId: number;
}

import { Button, Col, Row } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

import { useVCRPAccount } from 'service/brand/product/vcrp';
import VCRPAccountAddModal from 'components/modal/product/VCRPAccountAddModal';
import { VCRPAccountParam } from 'types/brand/vcrp';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { Flex } from 'components/ui';
import Table from 'components/ui/Table/Table';

const VCRPAccountContainer = styled.div`
  .ant-table-content {
    th:first-child,
    td:first-child {
      padding-left: 36px;
    }
  }
`;

const VCRPPasswordContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${palette.GRAY30};
  border-radius: 4px;
`;

const VCRPAccount = () => {
  const { vcrpAccount, getVCRPAccountLoading } = useVCRPAccount();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns: ColumnsType<VCRPAccountParam> = useMemo(
    () => [
      {
        title: '사용자명 (ID)',
        width: '360px',
        align: 'left',
        dataIndex: 'vcrpAccount',
      },
      {
        title: '비밀번호',
        align: 'left',
        width: vcrpAccount?.vcrpPassword && vcrpAccount.vcrpPassword.length > 15 ? 270 : 150,
        render: (_, { vcrpPassword }) => (
          <VCRPPasswordContainer>
            {isPasswordVisible ? vcrpPassword : '*'.repeat(vcrpPassword.length)}
          </VCRPPasswordContainer>
        ),
      },
      {
        align: 'left',
        render: () => (
          <>
            {isPasswordVisible ? (
              <IconWrapper
                onClick={() => setIsPasswordVisible(false)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Icon name="show" size={14} color="GRAY60" />
              </IconWrapper>
            ) : (
              <IconWrapper
                onClick={() => setIsPasswordVisible(true)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Icon name="hide" size={14} color="GRAY60" />
              </IconWrapper>
            )}
          </>
        ),
      },
    ],
    [vcrpAccount, isPasswordVisible],
  );

  return (
    <VCRPAccountContainer>
      <VCRPAccountAddModal
        vcrpAccount={vcrpAccount}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <Table
        rowKey="vcrpAccountId"
        columns={columns}
        dataSource={vcrpAccount ? [vcrpAccount] : []}
        className="secondary"
        loading={getVCRPAccountLoading}
        pagination={false}
      />
      {vcrpAccount !== null && (
        <Row justify="end" style={{ marginTop: 8 }}>
          <Col>
            <Button onClick={() => setIsModalVisible(true)}>계정 정보 변경</Button>
          </Col>
        </Row>
      )}
    </VCRPAccountContainer>
  );
};

export default VCRPAccount;

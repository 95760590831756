export enum AdProductCode {
  MAIN = 'PB001', // 메인 상단 배너
  LOGIN = 'PB002', // 로그인 배너
  MATERIAL_SEARCH = 'PB003', // 원료 배너
}

export interface AdBanner {
  companyPurchaseProductId: number;
  filename: string;
  fileUrl: string;
  eventUrl: string;
}

import { Col, Row } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Typography } from 'components/system';
import PageTemplate from 'templates/PageTemplate';
import { DocumentCode } from 'types/material/icid/icid';
import { useICIDApplication } from 'service/material/icid/icid';

const Container = styled.div`
  .ant-page-header-heading-left {
    align-items: unset;
  }

  .ant-page-header-back {
    padding-top: 12px;
  }

  .ant-page-header-heading-sub-title {
    padding-top: 8px;
  }
`;

const ICIDPageTemplate = ({
  children,
  subtitle,
  documentCode,
  isICIDApplying = false,
  ...restProps
}: {
  documentCode?: DocumentCode;
  isICIDApplying?: boolean;
} & React.ComponentProps<typeof PageTemplate>) => {
  const params = useParams<{ icidApplicationId: string | undefined }>();
  const materialIcidApplicationId = params.icidApplicationId
    ? Number(params.icidApplicationId)
    : undefined;
  const { icidApplication } = useICIDApplication(materialIcidApplicationId);

  return (
    <Container>
      <PageTemplate
        title="원료 등재"
        {...restProps}
        subtitle={
          <>
            <Row align="middle" gutter={16}>
              <Col>
                <Typography.Text style={{ fontSize: 20 }}>{subtitle}</Typography.Text>
              </Col>
              {!isICIDApplying && (
                <Col>
                  <Row align="middle" gutter={4}>
                    <Col>
                      <Typography.Text type="SMALL" color="SLATE_GRAY70" style={{ opacity: 0.5 }}>
                        원료명
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text
                        medium
                        color="SLATE_GRAY70"
                        style={{
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {icidApplication?.tradeName}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </>
        }
        exampleImgUrl={
          typeof documentCode !== 'undefined' && documentCode !== DocumentCode.BASIC
            ? `https://30cos.s3.ap-northeast-2.amazonaws.com/material/icid/${documentCode}.png`
            : undefined
        }
      >
        {children}
      </PageTemplate>
    </Container>
  );
};

export default ICIDPageTemplate;

import { Button, Col, Radio, Row } from 'antd';
import { Typography } from 'components/system';
import { useState } from 'react';
import styled from 'styled-components';

import { Pictogram } from 'types/material/msds';
import MSDSPictogram from './MSDSPictogram';

const MSDSPictogramSelectModalContentBlock = styled.div``;

const MSDSPictogramSelectModalContent = ({
  pictograms,
  onSelectPictogram,
}: {
  pictograms: Pictogram[];
  onSelectPictogram: (pictogram: Pictogram) => void;
}) => {
  const [selectedPictogram, setSelectedPictogram] = useState<Pictogram | null>(null);
  const handleSelectPictogram = () => {
    onSelectPictogram(selectedPictogram!);
  };
  return (
    <MSDSPictogramSelectModalContentBlock>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 24 }}>
        Pictogram 선택하기
      </Typography.Text>
      <Row gutter={[16, 20]}>
        {pictograms.map((pictogram) => (
          <Col key={pictogram.materialMsdsStatementId} style={{ textAlign: 'center' }}>
            <MSDSPictogram
              src={pictogram.attachUrl}
              onClick={() => setSelectedPictogram(pictogram)}
            />
            <Radio
              checked={pictogram.attachUrl === selectedPictogram?.attachUrl}
              style={{ marginRight: 0 }}
              onClick={() => setSelectedPictogram(pictogram)}
            />
          </Col>
        ))}
      </Row>
      <Row justify="center" style={{ marginTop: 24 }}>
        <Col>
          <Button onClick={handleSelectPictogram} disabled={!selectedPictogram}>
            선택 완료
          </Button>
        </Col>
      </Row>
    </MSDSPictogramSelectModalContentBlock>
  );
};

export default MSDSPictogramSelectModalContent;

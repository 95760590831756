import { Col, Form, FormInstance, Input, Row, Select, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import ManufacturerFormModal from 'components/manufacturer/ManufacturerFormModal';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import Icon from 'components/ui/Icon/Icon';
import InputNumber from 'components/ui/InputNumber';
import { parseWeightUnit } from 'lib/form';
import { netWeightUnitOptions } from 'lib/selectOption';
import palette from 'lib/styles/palette';
import { checkIsPastDate, generateFormRules, noFutureDateRule, normalizeDate } from 'lib/validate';
import { useQCQALotBasic } from 'service/brand/qcqa/qcqa';
import { useAllManufacturer } from 'service/manufacturer/company';
import { ManufacturerDetail } from 'types/manufacturer/company';
import { QCQALotForm } from './QCQALot';

const Container = styled.div<{ disabledNoStyle: boolean }>`
  margin: 40px auto 0;
  max-width: 520px;

  ${({ disabledNoStyle }) =>
    disabledNoStyle &&
    css`
      .ant-select-disabled .ant-select-selector {
        background: white !important;
        cursor: text !important;

        input {
          cursor: text !important;
        }

        &:hover {
          border-color: #61a5ff !important;
        }
      }
    `}

  .ant-form-item-explain.ant-form-item-explain-error {
    position: relative;
    z-index: 2;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-form-item-no-colon {
    width: 100% !important;
  }
`;

const QCQALotBasic = ({
  pageState,
  form,
  qcqaProductSelectOptions,
  selectLoading,
  qcqaProductId,
  qcqaProductLotnoId,
  onChangeQCQAProductId,
  updateMode,
  onChangeInitialFormValues,
  manufacturerDetail,
  onChangeSelectedCompanyManufacturerId,
  checkIsRegistrable,
}: {
  pageState?: { qcqaProductId: number };
  form: FormInstance<QCQALotForm>;
  qcqaProductSelectOptions: {
    label: string;
    value: number;
  }[];
  selectLoading: boolean;
  qcqaProductId?: number;
  qcqaProductLotnoId?: number;
  onChangeQCQAProductId: (qcqaProductId: number) => void;
  updateMode: boolean;
  onChangeInitialFormValues: React.Dispatch<React.SetStateAction<QCQALotForm | undefined>>;
  manufacturerDetail: ManufacturerDetail | undefined;
  onChangeSelectedCompanyManufacturerId: React.Dispatch<React.SetStateAction<number | undefined>>;
  checkIsRegistrable: VoidFunction;
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { allManufacturers, getAllManufacturersLoading } = useAllManufacturer();

  const { qcqaLotBasic } = useQCQALotBasic({
    qcqaProductId,
    qcqaProductLotnoId,
  });

  const handleClickWithoutProductId = () => {
    if (!qcqaProductId) {
      message.warning('품질 제품을 먼저 선택해 주세요.');
    }
  };

  const changeManuInfo = (companyManufacturerId: number) => {
    onChangeSelectedCompanyManufacturerId(companyManufacturerId);
    checkIsRegistrable();
  };

  useEffect(() => {
    const companyManufacturerId = form.getFieldValue('companyManufacturerId');

    if (companyManufacturerId) {
      changeManuInfo(companyManufacturerId);
    }
  }, [form]);

  useEffect(() => {
    if (qcqaLotBasic && qcqaProductId) {
      const { qcqaProductLotnoId, ...rest } = qcqaLotBasic;

      const initialFormValues = {
        ...rest,
        netWeightUnitDirect: '',
        qcqaProductId,
        manufacturerFax: rest.manufacturerFax || '',
        manufacturerNameEn: rest.manufacturerNameEn || '',
        manufacturerAddressKo: rest.manufacturerAddressKo || '',
      };
      form.setFieldsValue(initialFormValues);
      onChangeSelectedCompanyManufacturerId(rest.companyManufacturerId);
      onChangeInitialFormValues(initialFormValues);
    }
  }, [qcqaLotBasic]);

  useEffect(() => {
    if (manufacturerDetail) {
      form.setFieldsValue({
        manufacturerNameEn: manufacturerDetail.manufacturerNameEn || '',
        manufacturerAddressKo: manufacturerDetail.manufacturerAddressKo || '',
        manufacturerTel: manufacturerDetail.manufacturerTel || '',
        manufacturerFax: manufacturerDetail.manufacturerFax || '',
      });
      checkIsRegistrable();
    }
  }, [manufacturerDetail]);

  return (
    <Container disabledNoStyle={!qcqaProductId}>
      <Spin spinning={updateMode && !qcqaLotBasic}>
        {modalVisible && (
          <ManufacturerFormModal
            onClose={() => {
              setModalVisible(false);
            }}
          />
        )}
        <Form.Item
          label="품질 제품"
          name="qcqaProductId"
          rules={generateFormRules({
            required: true,
          })}
        >
          <Select
            disabled={!!pageState?.qcqaProductId || updateMode}
            loading={selectLoading}
            placeholder="제품 선택"
            options={qcqaProductSelectOptions}
            value={qcqaProductId}
            onChange={(e: number) => {
              onChangeQCQAProductId(e);
            }}
          />
        </Form.Item>
        <Flex align="flex-end" gap={8}>
          <Form.Item
            label="제조일자"
            name="manufacturingDate"
            rules={[
              ...generateFormRules({
                required: true,
              }),
              noFutureDateRule,
            ]}
            normalize={normalizeDate}
            style={{ flex: 1 }}
          >
            <DatePicker
              checkIsValidDate={checkIsPastDate}
              onClick={handleClickWithoutProductId}
              disabled={!qcqaProductId}
            />
          </Form.Item>
          <Form.Item
            label="Lot No."
            name="lotNo"
            rules={generateFormRules({
              required: true,
              exceptKorean: true,
              maxLength: 30,
            })}
            style={{ flex: 1 }}
          >
            <Input
              onClick={handleClickWithoutProductId}
              readOnly={!qcqaProductId}
              placeholder="영문, 숫자, 기호 입력"
            />
          </Form.Item>
        </Flex>
        <Form.Item
          label="용량"
          required
          shouldUpdate={(prev, next) => prev.netWeightUnit !== next.netWeightUnit}
          style={{ marginBottom: 0 }}
        >
          {({ getFieldValue, setFieldsValue }) => {
            return (
              <Row gutter={8}>
                <Col>
                  <Form.Item
                    name="netWeight"
                    rules={generateFormRules({
                      required: true,
                      onlyNumber: true,
                      maxLength: 10,
                    })}
                  >
                    <InputNumber
                      className="hide-handler"
                      style={{ width: '168px' }}
                      placeholder="숫자 입력"
                      maxLength={10}
                      keyboard={false}
                      readOnly={!qcqaProductId}
                      onClick={handleClickWithoutProductId}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="netWeightUnit" rules={generateFormRules({ required: true })}>
                    <Select
                      style={{ width: '168px' }}
                      placeholder="단위 선택"
                      onSelect={() => setFieldsValue({ netWeightUnitDirect: null })}
                      disabled={!qcqaProductId}
                      onClick={() => {
                        handleClickWithoutProductId();
                      }}
                    >
                      {netWeightUnitOptions.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.text}
                        </Select.Option>
                      ))}
                      <Select.Option key="others" value="others">
                        직접입력
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {getFieldValue('netWeightUnit') === 'others' && (
                  <Col>
                    <Form.Item
                      name="netWeightUnitDirect"
                      rules={generateFormRules({
                        required: true,
                        maxLength: 10,
                      })}
                    >
                      <Input
                        autoFocus
                        placeholder="단위 직접 입력"
                        style={{ width: 168 }}
                        onBlur={(e) => {
                          const netWeightUnit = parseWeightUnit(e.target.value);
                          if (netWeightUnit) {
                            setFieldsValue({
                              netWeightUnit,
                              netWeightUnitDirect: null,
                            });
                          }
                        }}
                        readOnly={!qcqaProductId}
                        onClick={handleClickWithoutProductId}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            );
          }}
        </Form.Item>
        <Form.Item
          label="생산량"
          style={{ flex: 1 }}
          name="output"
          rules={generateFormRules({
            required: true,
            onlyNumber: true,
          })}
        >
          <InputNumber
            placeholder="숫자 입력"
            keyboard={false}
            style={{ width: 256 }}
            formatter={(value) => {
              if (value) {
                return Number(value).toLocaleString();
              }
              return value as any;
            }}
            maxLength={1_000_000_000}
          />
        </Form.Item>
        <Form.Item
          required
          label={
            <Flex justify="space-between" align="center" style={{ width: 'calc(100% - 6.68px)' }}>
              <Flex align="center">화장품제조업자</Flex>
              <Flex
                gap={4}
                align="center"
                style={{
                  border: `1px solid ${palette.PRIMARY50}`,
                  borderRadius: 4,
                  padding: '2px 7px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <Icon name="plus" size={12} color="PRIMARY50" />
                <Typography.Text type="SMALL">제조사 정보 추가</Typography.Text>
              </Flex>
            </Flex>
          }
        >
          <Form.Item
            name="companyManufacturerId"
            required
            rules={generateFormRules({
              required: true,
            })}
            style={{ flex: 1, marginBottom: 8 }}
          >
            <Select
              loading={getAllManufacturersLoading}
              placeholder="국문"
              showSearch
              filterOption={(keyword, option) =>
                (option?.label &&
                  typeof option.label === 'string' &&
                  (option.label as string).includes(keyword)) ||
                false
              }
              optionLabelProp="customlabel"
              options={allManufacturers.map(
                ({ manufacturerNameKo, companyManufacturerId, nickname }) => ({
                  label: (
                    <Flex gap={8} align="center" justify="space-between">
                      <Typography.Text type="BODY_2">{manufacturerNameKo}</Typography.Text>
                      <Typography.Text type="SMALL" color="GRAY70">
                        {nickname}
                      </Typography.Text>
                    </Flex>
                  ),
                  value: companyManufacturerId,
                  customlabel: manufacturerNameKo,
                }),
              )}
              disabled={!qcqaProductId}
              onClick={() => {
                handleClickWithoutProductId();
              }}
              onChange={changeManuInfo}
            />
          </Form.Item>
          <Form.Item
            className="dropdown-form-item"
            name="manufacturerNameEn"
            rules={generateFormRules({
              exceptKorean: true,
              maxLength: 255,
            })}
            labelAlign="right"
            style={{ flex: 1 }}
          >
            <Input
              autoComplete="off"
              disabled
              onClick={() => {
                handleClickWithoutProductId();
              }}
            />
          </Form.Item>
          <Form.Item
            className="dropdown-form-item"
            label="화장품제조업자 주소"
            name="manufacturerAddressKo"
            rules={generateFormRules({
              maxLength: 255,
            })}
            style={{ flex: 1, marginBottom: 0 }}
          >
            <Input
              autoComplete="off"
              disabled
              onClick={() => {
                handleClickWithoutProductId();
              }}
            />
          </Form.Item>
        </Form.Item>
        <Flex gap={8}>
          <Form.Item
            className="dropdown-form-item"
            label="화장품제조업자 연락처"
            name="manufacturerTel"
            rules={generateFormRules({
              onlyNumber: true,
              minLength: 8,
              maxLength: 12,
            })}
            style={{ flex: 1 }}
          >
            <Input
              autoComplete="off"
              disabled
              onClick={() => {
                handleClickWithoutProductId();
              }}
            />
          </Form.Item>
          <Form.Item
            className="dropdown-form-item"
            name="manufacturerFax"
            rules={generateFormRules({
              onlyNumber: true,
              minLength: 8,
              maxLength: 12,
            })}
            style={{ flex: 1, marginBottom: 0, marginTop: 36.28 }}
          >
            <Input
              autoComplete="off"
              disabled
              onClick={() => {
                handleClickWithoutProductId();
              }}
            />
          </Form.Item>
        </Flex>
        <Form.Item
          name="remark"
          label="비고"
          rules={generateFormRules({
            maxLength: 100,
          })}
        >
          <Input.TextArea
            placeholder="해당 생산 제품의 특이사항을 자유롭게 기재"
            style={{ resize: 'none', padding: '12px 16px' }}
            readOnly={!qcqaProductId}
            onClick={handleClickWithoutProductId}
          />
        </Form.Item>
      </Spin>
    </Container>
  );
};

export default QCQALotBasic;

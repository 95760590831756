import { Button, Form, message } from 'antd';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Flex } from 'components/ui';
import { useModal } from 'hook/useModal';
import { getChangedValues } from 'lib/common';
import { messages, qcqaDocumentCodes } from 'lib/consts';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useQCQALot } from 'service/brand/qcqa/qcqa';
import { useAllManufacturer, useManufacturerDetail } from 'service/manufacturer/company';
import { QCQALotAttach, QCQALotDocsListItem, QCQALotDocumentUseType } from 'types/brand/qcqa';
import QCQALotBasic from './QCQALotBasic';
import QCQALotRegisterDocs from './QCQALotRegisterDocs';
import { useNavigate } from 'react-router-dom';
import RouteLeaveGuard from 'components/RouteLeaveGuard';

const Container = styled.div``;

const TabContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  width: 528px;
  background-color: ${palette.ETC_BG_BLUE};
`;

const Tab = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 44px;
  background-color: ${({ active }) => (active ? '#fff' : palette.ETC_BG_BLUE)};
  color: ${({ active }) => (active ? palette.GRAY90 : palette.GRAY50)};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
`;

export interface QCQALotForm {
  qcqaProductId: number;
  companyManufacturerId: number;
  manufacturingDate: string;
  lotNo: string;
  netWeight: number;
  netWeightUnit: string;
  netWeightUnitDirect: string;
  output: number;
  manufacturerNameEn: string;
  manufacturerAddressKo: string;
  manufacturerTel: string;
  manufacturerFax: string;
  remark: string;
}

const QCQALot = ({
  pageState,
  params,
  updateMode,
}: {
  pageState?: { qcqaProductId: number };
  params: {
    qcqaProductLotnoId?: string;
    qcqaProductId?: string;
  };
  updateMode: boolean;
}) => {
  const navigate = useNavigate();
  const qcqaProductLotnoId = params.qcqaProductLotnoId
    ? Number(params.qcqaProductLotnoId)
    : undefined;
  const isBlockingGoBack = useRef<boolean>(true);
  const [form] = Form.useForm<QCQALotForm>();
  const [initialFormValues, setInitialFormValues] = useState<QCQALotForm>();
  const [tab, setTab] = useState<'basic' | 'document'>('basic');
  const [isRegistrable, setIsRegistrable] = useState(false);
  const [isFieldEmpty, setIsFieldEmpty] = useState(!updateMode);
  // HINT: 탭 계속 이동시 api 호출 한 번만 되도록
  const [qcqaProductId, setQCQAProductId] = useState<number | undefined>(
    pageState?.qcqaProductId || (params.qcqaProductId ? Number(params.qcqaProductId) : undefined),
  );
  const [qcqaLotDocsListCopy, setQCQALotDocsListCopy] = useState<QCQALotDocsListItem[]>([]);
  const [selectedCompanyManufacturerId, setSelectedCompanyManufacturerId] = useState<number>();
  const [tempDataMap, setTempDataMap] = useState<
    Record<
      number,
      {
        attaches?: QCQALotAttach[];
        selected?: {
          qcqaProductLotnoRecordId: number;
          qcqaRelationDocumentRecordId: number;
        };
      }
    >
  >({});

  const { openAlertModal } = useModal();
  const { allManufacturers } = useAllManufacturer();
  const {
    qcqaLotDocsList,
    isGetQCQALotDocsLoading,
    refetchQCQALotDocsList,
    qcqaProductList,
    isQCQAProductListLoading,
    createQCQALot,
    isCreateQCQALotLoading,
    updateQCQALotBasic,
    isUpdateQCQALotBasicLoading,
  } = useQCQALot({
    qcqaProductId,
    qcqaProductLotnoId,
  });
  const { manufacturerDetail } = useManufacturerDetail({
    companyManufacturerId: selectedCompanyManufacturerId,
  });
  const { updateManufacturer, updateManufacturerLoading } = useManufacturerDetail({
    companyManufacturerId: selectedCompanyManufacturerId,
  });

  const handleRefetchQCQALotDocsList = () => {
    refetchQCQALotDocsList();
  };

  const qcqaProductSelectOptions = qcqaProductList.map((item) => ({
    label: item.productNameKo || item.productNameEn,
    value: item.qcqaProductId,
  }));

  const handleSubmit = (_: QCQALotForm) => {
    const formValues: QCQALotForm = form.getFieldsValue(true);

    let { qcqaProductId, netWeightUnit, netWeightUnitDirect, manufacturingDate, output } =
      formValues;

    // HINT: 제조사 정보 변경시 제조사 정보만 따로 업데이트
    if (manufacturerDetail) {
      const { manufacturerNameEn, manufacturerAddressKo, manufacturerTel, manufacturerFax } =
        manufacturerDetail;
      const {
        manufacturerNameEn: newManufacturerNameEn,
        manufacturerAddressKo: newManufacturerAddressKo,
        manufacturerTel: newManufacturerTel,
        manufacturerFax: newManufacturerFax,
      } = formValues;

      const changedValues = getChangedValues({
        obj1: {
          ...(manufacturerNameEn && { manufacturerNameEn }),
          ...(manufacturerAddressKo && { manufacturerAddressKo }),
          manufacturerTel,
          manufacturerFax,
        },
        obj2: {
          ...(newManufacturerNameEn && {
            manufacturerNameEn: newManufacturerNameEn,
          }),
          ...(newManufacturerAddressKo && {
            manufacturerAddressKo: newManufacturerAddressKo,
          }),
          manufacturerTel: newManufacturerTel,
          manufacturerFax: newManufacturerFax,
        },
      });

      if (Object.values(changedValues).filter((value) => !!value).length !== 0) {
        updateManufacturer(changedValues);
      }
    }

    // 수정
    if (updateMode) {
      if (!isRegistrable) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }
      updateQCQALotBasic(
        {
          ...formValues,
          manufacturerNameKo:
            allManufacturers.find(
              (item) => item.companyManufacturerId === formValues.companyManufacturerId,
            )?.manufacturerNameKo || '',
          output: Number(output),
        },
        {
          onSuccess: () => {
            message.success('수정되었습니다.');
            navigate(
              `${
                path.qcqa.management.root
              }/${qcqaProductId}/lot?page=1&productYear=${manufacturingDate.slice(0, 4)}`,
            );
          },
        },
      );
      return;
    }

    // 등록
    if (netWeightUnit === 'others') {
      netWeightUnit = netWeightUnitDirect;
    }

    const productName = qcqaProductSelectOptions.find(
      (item) => item.value === qcqaProductId,
    )?.label;

    const getDocumentParams = () => {
      const newRecords: {
        qcqaUserDocumentId: number;
        relationType: string;
        qcqaRelationDocumentRecordId: number;
      }[] = [];
      const newAttaches: {
        qcqaUserDocumentId: number;
        attachIds: number[];
      }[] = [];

      qcqaLotDocsListCopy.forEach(({ selected, qcqaUserDocumentId, useType, attaches }) => {
        if (
          useType !== QCQALotDocumentUseType.LOT_NO &&
          selected &&
          selected.qcqaRelationDocumentRecordId
        ) {
          newRecords.push({
            qcqaUserDocumentId,
            relationType: useType,
            qcqaRelationDocumentRecordId: selected.qcqaRelationDocumentRecordId,
          });
        } else if (useType === QCQALotDocumentUseType.LOT_NO && attaches && attaches.length > 0) {
          newAttaches.push({
            qcqaUserDocumentId,
            attachIds: attaches.map(({ attachId }) => attachId),
          });
        }
      });

      return {
        ...(newRecords.length > 0 && { records: newRecords }),
        ...(newAttaches.length > 0 && { attaches: newAttaches }),
      };
    };

    openAlertModal({
      content: `${productName}의 제조번호를 등록합니다.\n등록 후 [QC > 제조번호 관리] 메뉴로 이동합니다.`,
      onOk: () => {
        createQCQALot(
          {
            ...formValues,
            manufacturerNameKo:
              allManufacturers.find(
                (item) => item.companyManufacturerId === formValues.companyManufacturerId,
              )?.manufacturerNameKo || '',
            netWeightUnit,
            output: Number(output),
            ...getDocumentParams(),
          },
          {
            onSuccess: () => {
              isBlockingGoBack.current = false;
              message.success('등록되었습니다.');

              const productYear = manufacturingDate.slice(0, 4);

              navigate(
                `${path.qcqa.management.root}/${qcqaProductId}/lot?page=1&productYear=${
                  Number(productYear) <= 2018 ? 2018 : productYear
                }`,
              );
            },
          },
        );
      },
      closeText: '등록 취소',
      okText: '등록하고 이동',
    });
  };

  const checkIsRegistrable = () => {
    const allErrorFields = form.getFieldsError();

    const formValues: QCQALotForm = form.getFieldsValue(true);
    const {
      qcqaProductId,
      manufacturingDate,
      lotNo,
      netWeight,
      netWeightUnit,
      companyManufacturerId,
      manufacturerTel,
      output,
    } = formValues;

    if (
      qcqaProductId &&
      manufacturingDate &&
      lotNo &&
      netWeight !== undefined &&
      netWeightUnit &&
      companyManufacturerId &&
      manufacturerTel &&
      output !== undefined &&
      allErrorFields.every((field) => field.errors && field.errors.length === 0)
    ) {
      if (updateMode && isEqual(formValues, initialFormValues)) {
        setIsRegistrable(false);
        return;
      }
      setIsRegistrable(true);
    } else {
      setIsRegistrable(false);
    }
  };

  const handleFormFieldsChange = () => {
    checkIsRegistrable();

    const getIsFieldEmpty = () => {
      const formValues = form.getFieldsValue(true);

      return Object.entries(formValues).every(([key, value]) => {
        if (key === 'qcqaProductId' && pageState?.qcqaProductId) {
          return true;
        }

        return !value;
      });
    };

    setIsFieldEmpty(getIsFieldEmpty());
  };

  const handleChangeQCQAProductId = (qcqaProductId: number) => {
    setQCQAProductId(qcqaProductId);
  };

  useEffect(() => {
    if (qcqaLotDocsList.length > 0) {
      const copiedDocsList: QCQALotDocsListItem[] = structuredClone(qcqaLotDocsList);
      const qcqaLotDocsListCopy = copiedDocsList.map((item) => {
        const { selected, records } = item;
        let result = item;

        if (updateMode) {
          return result;
        }

        if (!selected && records && records.length > 0) {
          result = {
            ...item,
            selected: {
              qcqaProductLotnoRecordId: records[0].qcqaDocumentRecordId,
              qcqaRelationDocumentRecordId: records[0].qcqaDocumentRecordId,
            },
          };
        }

        const targetTempData = tempDataMap[item.qcqaUserDocumentId];

        if (targetTempData?.attaches) {
          result.attaches = targetTempData.attaches;
        }

        if (records && records.length > 0 && targetTempData?.selected) {
          if (
            targetTempData.selected?.qcqaRelationDocumentRecordId === 0 ||
            records.some(
              (item) =>
                item.qcqaDocumentRecordId === targetTempData.selected?.qcqaRelationDocumentRecordId,
            )
          ) {
            result.selected = targetTempData.selected;
          }
        }

        return result;
      });

      setQCQALotDocsListCopy(qcqaLotDocsListCopy);
    }
  }, [qcqaLotDocsList]);

  useEffect(() => {
    if (pageState?.qcqaProductId) {
      form.setFieldsValue({
        qcqaProductId: pageState.qcqaProductId,
      });
    }
  }, [pageState]);

  useEffect(() => {
    if (manufacturerDetail && qcqaLotDocsListCopy.length > 0) {
      const result = [...qcqaLotDocsListCopy];
      const businessRegistersIndex = result.findIndex(
        (item) => item.code === qcqaDocumentCodes.BUSINESS_REGISTERS_CODE,
      );
      const cosmeticRegistersIndex = result.findIndex(
        (item) => item.code === qcqaDocumentCodes.COSMETIC_REGISTERS_CODE,
      );

      if (businessRegistersIndex !== -1) {
        result[businessRegistersIndex].attaches =
          manufacturerDetail.attach.businessRegisters?.map((item) => ({
            attachId: 1,
            filename: item.filename,
            registerDt: item.updateDt,
            uploadFileUrl: item.url,
          })) || [];
      }

      if (cosmeticRegistersIndex !== -1) {
        result[cosmeticRegistersIndex].attaches =
          manufacturerDetail.attach.cosmeticRegisters?.map((item) => ({
            attachId: 1,
            filename: item.filename,
            registerDt: item.updateDt,
            uploadFileUrl: item.url,
          })) || [];
      }
      setQCQALotDocsListCopy(result);
    }
  }, [manufacturerDetail]);

  return (
    <Container>
      <RouteLeaveGuard
        isChanged={isBlockingGoBack.current && !updateMode && !isFieldEmpty}
        content={`제조번호가 등록되지 않았습니다.\n이 페이지에서 나가시겠습니까?`}
        closeText="계속 등록"
        okText="나가기"
      />
      <Form
        form={form}
        colon={false}
        layout="vertical"
        onFinish={handleSubmit}
        onFieldsChange={handleFormFieldsChange}
      >
        <Flex justify="space-between" align="center">
          <TabContainer>
            <Tab active={tab === 'basic'} onClick={() => setTab('basic')}>
              기본 정보
            </Tab>
            <Tab
              active={tab === 'document'}
              onClick={() => {
                if (isRegistrable || updateMode) {
                  setTab('document');
                } else {
                  form.validateFields();
                }
              }}
            >
              서류 등록
            </Tab>
          </TabContainer>
          {!(updateMode && tab === 'document') && (
            <Button
              loading={
                isCreateQCQALotLoading || isUpdateQCQALotBasicLoading || updateManufacturerLoading
              }
              type="primary"
              onClick={form.submit}
              style={{ width: 160, height: 44 }}
            >
              {updateMode ? '수정' : '등록'}
            </Button>
          )}
        </Flex>
        {tab === 'basic' ? (
          // 기본 정보
          <QCQALotBasic
            pageState={pageState}
            form={form}
            qcqaProductSelectOptions={qcqaProductSelectOptions}
            selectLoading={isQCQAProductListLoading}
            qcqaProductId={qcqaProductId}
            qcqaProductLotnoId={qcqaProductLotnoId}
            onChangeQCQAProductId={handleChangeQCQAProductId}
            updateMode={updateMode}
            onChangeInitialFormValues={setInitialFormValues}
            manufacturerDetail={manufacturerDetail}
            onChangeSelectedCompanyManufacturerId={setSelectedCompanyManufacturerId}
            checkIsRegistrable={checkIsRegistrable}
          />
        ) : (
          qcqaProductId && (
            // 서류 등록
            <QCQALotRegisterDocs
              qcqaProductId={qcqaProductId}
              qcqaProductLotnoId={qcqaProductLotnoId}
              qcqaLotDocsList={qcqaLotDocsListCopy}
              isGetQCQALotDocsLoading={isGetQCQALotDocsLoading}
              onChangeQCQALotDocsList={setQCQALotDocsListCopy}
              onChangeTempDataMap={setTempDataMap}
              updateMode={updateMode}
              onRefetch={handleRefetchQCQALotDocsList}
              companyManufacturerId={selectedCompanyManufacturerId}
            />
          )
        )}
      </Form>
    </Container>
  );
};

export default QCQALot;

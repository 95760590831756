import palette from 'lib/styles/palette';
import styled from 'styled-components';
import { Typography } from './system';
import { Flex } from './ui';
import Icon from './ui/Icon/Icon';

interface QuickMenuProps {
  onMaterialSearchClick?: VoidFunction;
  onIngredientDictClick?: VoidFunction;
  onFormulaScreeningClick?: VoidFunction;
  onCTKSearchClick?: VoidFunction;
}

const Container = styled.div`
  padding: 0px 8px;
  width: 100px;
  height: fit-content;

  > ul {
    list-style-type: none;
    padding: 0px;
    margin: 0;

    > li {
      padding: 16px 0;
      text-align: center;
      cursor: pointer;
      transition: opacity 0.25s;

      &:hover {
        opacity: 0.7;
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${palette.GRAY30};
      }
    }
  }

  box-shadow: 0px 2px 8px rgba(162, 162, 162, 0.23);
  border-radius: 8px;
`;

const IconWrapper = styled(Flex)<{
  color: keyof typeof palette;
}>`
  width: 40px;
  height: 40px;
  background-color: ${({ color }) => palette[color]};
  border-radius: 100%;
  margin: auto;
  margin-bottom: 8px;
`;

const QuickMenu = ({
  onMaterialSearchClick,
  onIngredientDictClick,
  onFormulaScreeningClick,
  onCTKSearchClick,
}: QuickMenuProps) => {
  return (
    <Container>
      <ul>
        <li onClick={onCTKSearchClick}>
          <IconWrapper color="LIGHT_BLUE40" justify="center" align="center">
            <Icon name="formulaIcon" color="ETC_WHITE" size={22} />
          </IconWrapper>
          <Typography.Text type="BODY_2">제형검색</Typography.Text>
        </li>
        <li onClick={onMaterialSearchClick}>
          <IconWrapper color="PRIMARY50" justify="center" align="center">
            <Icon name="find" color="ETC_WHITE" size={20} />
          </IconWrapper>
          <Typography.Text type="BODY_2">원료검색</Typography.Text>
        </li>
        <li onClick={onIngredientDictClick}>
          <IconWrapper color="SLATE_GRAY60" justify="center" align="center">
            <Icon name="book" color="ETC_WHITE" size={20} />
          </IconWrapper>
          <Typography.Text type="BODY_2">성분사전</Typography.Text>
        </li>
        <li onClick={onFormulaScreeningClick}>
          <IconWrapper color="SLATE_GRAY70" justify="center" align="center">
            <Icon name="scanning" color="ETC_WHITE" size={20} />
          </IconWrapper>
          <Typography.Text type="BODY_2">성분 스크리닝</Typography.Text>
        </li>
      </ul>
    </Container>
  );
};

export default QuickMenu;

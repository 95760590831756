import { APIPageableResponse, APIResponse } from 'types/common';
import { IngredientDetail, IngredientSearchResult } from 'types/material/ingredient';
import client from '../client';

export const getIngredientDetail = async (kciaIngredientId: number) => {
  const res = await client.get<APIResponse<IngredientDetail>>(
    `ingredient-dictionary/ingredients/${kciaIngredientId}`,
  );
  return res.data;
};

export const searchIngredient = async ({
  pageNo,
  size = 10,
  newKeyword,
  inciName,
  preKeyword,
}: {
  pageNo: number;
  size: number;
  inciName?: string;
  newKeyword?: string;
  preKeyword?: string;
}) => {
  const res = await client.get<APIPageableResponse<IngredientSearchResult[]>>(
    `/ingredient-dictionary/ingredients`,
    {
      params: {
        page: pageNo,
        size,
        ...(newKeyword && { newKeyword: encodeURIComponent(newKeyword) }),
        ...(preKeyword && { preKeyword: encodeURIComponent(preKeyword) }),
      },
    },
  );
  return res.data;
};

export const getCountryProhibitsUpdatedDate = () =>
  client.get<APIResponse<string>>('/ingredient/prohibit/update-date');

export const getEwgUpdatedDate = () =>
  client.get<APIResponse<string>>('/ingredient/ewg/update-date');

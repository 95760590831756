import { useRoutes } from 'react-router-dom';

import Error404Page from 'pages/error/Error404Page';
import NMPARequestPage from 'pages/material/nmpa/NMPARequestPage';
import NMPARequestListPage from 'pages/material/nmpa/NMPARequestListPage';
import NMPAOngoingPage from 'pages/material/nmpa/NMPAOngoingPage';
import NMPARegisteredPage from 'pages/material/nmpa/NMPARegisteredPage';
import NMPAChecklistPage from 'pages/material/nmpa/NMPAChecklistPage';
import NMPAContractPage from 'pages/material/nmpa/NMPAContractPage';
import NMPAEstimatePage from 'pages/material/nmpa/NMPAEstimatePage';
import NMPADocumentListPage from 'pages/material/nmpa/NMPADocumentListPage';
import NMPAMaterialListPage from 'pages/material/nmpa/NMPAMaterialListPage';
import { DocumentCode } from 'types/material/nmpa/nmpa';
import NMPABasicPage from 'pages/material/nmpa/NMPABasicPage';
import NMPABATPage from 'pages/material/nmpa/NMPABATPage';
import NMPADetailPage from 'pages/material/nmpa/NMPADetailPage';
import NMPADIFPage from 'pages/material/nmpa/NMPADIFPage';
import NMPAEDTPage from 'pages/material/nmpa/NMPAEDTPage';
import NMPAESDPage from 'pages/material/nmpa/NMPAESDPage';

const NMPARouter = () => {
  const element = useRoutes([
    {
      path: `estimate`,
      children: [
        {
          path: 'request',
          element: <NMPARequestPage />,
        },
        {
          path: 'check-list',
          element: <NMPAChecklistPage />,
        },
        {
          path: ':materialNmpaEstimateId',
          element: <NMPAEstimatePage />,
        },
        {
          path: ':materialNmpaEstimateId/contract',
          element: <NMPAContractPage />,
        },
      ],
    },
    {
      path: 'request-list',
      element: <NMPARequestListPage />,
    },
    {
      path: 'ongoing',
      element: <NMPAOngoingPage />,
    },
    {
      path: 'registered',
      element: <NMPARegisteredPage />,
    },
    {
      path: 'material-list',
      element: <NMPAMaterialListPage />,
    },
    {
      path: 'material',
      children: [
        { index: true, element: <NMPAMaterialListPage /> },
        { path: `${DocumentCode.BASIC}`, element: <NMPABasicPage /> },
        // materialId와 nmpaBasicInfoId는 같습니다.
        { path: `:materialId/${DocumentCode.BAT}`, element: <NMPABATPage /> },
        {
          path: `:materialId/${DocumentCode.DETAIL}`,
          element: <NMPADetailPage />,
        },
        {
          path: `:materialId/${DocumentCode.DIF}`,
          element: <NMPADIFPage />,
        },
        {
          path: `:materialId/${DocumentCode.EDT}`,
          element: <NMPAEDTPage />,
        },
        {
          path: `:materialId/${DocumentCode.ESD}`,
          element: <NMPAESDPage />,
        },
        {
          path: `:materialId`,
          element: <NMPADocumentListPage />,
        },
      ],
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);

  return element;
};

export default NMPARouter;

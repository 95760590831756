import { useRoutes } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { useCheckManufacturer } from 'lib/hook';
import CertificateOngoingPage from 'pages/certificate/CertificateOngoingPage';
import CertificateCompletePage from 'pages/certificate/CertificateCompletePage';
import CertificateDetailPage from 'pages/certificate/CertificateDetailPage';
import CertificateDocumentsPage from 'pages/certificate/CertificateDocumentsPage';
import Error404Page from 'pages/error/Error404Page';

const CertificateRouter = () => {
  useCheckManufacturer();

  const element = useRoutes([
    {
      path: 'ongoing',
      element: <CertificateOngoingPage key={nanoid()} />,
    },
    {
      path: 'complete',
      element: <CertificateCompletePage key={nanoid()} />,
    },
    {
      path: `ongoing/:certTargetId`,
      element: <CertificateDetailPage />,
    },
    {
      path: `complete/:certTargetId`,
      element: <CertificateDocumentsPage />,
    },
    {
      path: `*`,
      element: <Error404Page />,
    },
  ]);

  return element;
};

export default CertificateRouter;

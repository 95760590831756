import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Col, Row, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useCertificates } from 'hook/certificate';
import SearchInput from 'components/system/form/SearchInput';
import { Typography } from 'components/system';
import { SearchType } from 'pages/certificate/CertificateCompletePage';
import { CertificateCompletePageSearchParams } from 'pages/certificate/CertificateCompletePage';
import Table from 'components/ui/Table/Table';
import RangePicker from 'components/ui/DatePicker/RangePicker';
import { CertificateListItem } from 'types/certificate';

const CertificateCompleteBlock = styled.div`
  .ant-radio-button-wrapper {
    width: 50px;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 8px 16px;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      padding: 17px 16px;
    }
  }
`;

const CertificateComplete = ({
  queryParams,
  onChangeQueryParams,
}: {
  queryParams: URLSearchParams;
  onChangeQueryParams: (queryParams: Partial<CertificateCompletePageSearchParams>) => void;
}) => {
  const navigate = useNavigate();
  const finishDateLower = queryParams.get('finishDateLower') || '';
  const finishDateUpper = queryParams.get('finishDateUpper') || '';
  const searchValue = queryParams.get('searchValue') || '';
  const searchType = (queryParams.get('searchType') as SearchType) || SearchType.ALL;
  const page = Number(queryParams.get('page')) || 1;

  const [finishDateBuffer, setFinishDateBuffer] = useState({
    finishDateLower,
    finishDateUpper,
  });
  const [searchTypeBuffer, setSearchTypeBuffer] = useState<SearchType>(searchType);
  const { certificatesListItems, total, isLoading } = useCertificates({
    page,
    isFinish: true,
    searchType,
    searchValue,
    finishDateLower,
    finishDateUpper,
  });

  const searchTypeOptions = useMemo(
    () => [
      { label: '전체', value: 'all' },
      { label: '제품명', value: 'productNameEn' },
      { label: '제조사명', value: 'manufacturerNameEn' },
      {
        label: '국가명',
        value: 'countryNameKo',
      },
    ],
    [],
  );

  const handleChangeFinishDate = ([finishDateLower, finishDateUpper]: [string, string]) => {
    if (finishDateLower === 'Invalid date' || finishDateUpper === 'Invalid date') {
      setFinishDateBuffer({ finishDateLower: '', finishDateUpper: '' });
    } else {
      setFinishDateBuffer({ finishDateLower, finishDateUpper });
    }
  };

  const showCertificate = (certificate: any) => {
    navigate(`${path.certificate.complete}/${certificate.certTargetId}`);
  };

  const handleSearch = (searchValue: string) => {
    onChangeQueryParams({
      page: 1,
      searchType: searchTypeBuffer,
      searchValue,
      finishDateLower: finishDateBuffer.finishDateLower,
      finishDateUpper: finishDateBuffer.finishDateUpper,
    });
  };

  const handleChangePage = (page: number) => {
    onChangeQueryParams({ page });
  };

  const columns: ColumnsType<CertificateListItem> = [
    {
      title: 'No.',
      align: 'center',
      width: 80,
      render: (_, __, index) => total - index - (page - 1) * 10,
    },
    {
      title: '브랜드명',
      align: 'center',
      width: 160,
      render: (row) => (
        <div style={{ wordBreak: 'break-all' }}>
          {row.estimateTarget.productDetail.countryBrandName ||
            row.estimateTarget.productDetail.brandNameEn ||
            '-'}
        </div>
      ),
    },
    {
      title: '제품명 (용량)',
      width: 280,
      render: (row) => (
        <Typography.Text
          type="BODY_2"
          style={{
            cursor: 'pointer',
            fontWeight: 500,
            color: palette.GRAY90,
            wordBreak: 'break-all',
          }}
          onClick={() => showCertificate(row)}
        >
          {`${row.estimateTarget.productDetail.productNameEn} ${
            row.estimateTarget.netWeight
              ? `(${row.estimateTarget.netWeight} ${row.estimateTarget.netWeightUnit})`
              : '( - )'
          }`}
        </Typography.Text>
      ),
    },
    {
      title: '제조사',
      align: 'center',
      width: 160,
      render: (_, { manufacturerNameEn }) => (
        <div style={{ wordBreak: 'break-all' }}>{manufacturerNameEn}</div>
      ),
    },
    {
      title: '국가',
      align: 'center',
      width: 120,
      render: (_, { estimateTarget, certTargetAppendCertification }) => (
        <>
          {estimateTarget.countryName}
          {certTargetAppendCertification && (
            <>
              , <br />
              {certTargetAppendCertification.country?.countryNameKo || '-'}
            </>
          )}
        </>
      ),
    },
    {
      title: '인증 완료일',
      align: 'center',
      width: 120,
      render: (_, certificate) => (
        <>
          {certificate.certFinYmd}
          {certificate.certTargetAppendCertification && (
            <>
              , <br />
              {certificate.certTargetAppendCertification.certFinYmd}
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <CertificateCompleteBlock>
      <Row align="middle" justify="end" gutter={8}>
        <Col>
          <Row align="middle" gutter={[8, 8]}>
            <Col>
              <Typography.Text type="BODY_2">인증 완료일</Typography.Text>
            </Col>
            <Col>
              <RangePicker
                startDt={finishDateBuffer.finishDateLower}
                endDt={finishDateBuffer.finishDateUpper}
                onChange={handleChangeFinishDate}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <Select
                options={searchTypeOptions}
                value={searchTypeBuffer}
                onChange={setSearchTypeBuffer}
                style={{ width: 100 }}
              />
            </Col>
            <Col>
              <SearchInput
                placeholder="검색"
                defaultValue={searchValue}
                onSearch={handleSearch}
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={certificatesListItems}
        rowKey="certTargetId"
        loading={isLoading}
        style={{ marginTop: 16 }}
        pagination={{
          current: page,
          pageSize: 10,
          total: total,
          onChange: handleChangePage,
        }}
        scroll={{ x: 576 }}
      />
    </CertificateCompleteBlock>
  );
};

export default CertificateComplete;

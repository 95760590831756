import produce from 'immer';
import styled from 'styled-components';
import { Col, Form, FormInstance, Input, InputRef, Row } from 'antd';
import { useRef } from 'react';
import { FormListFieldData } from 'antd/lib/form/FormList';

import ImageUploadDragger from 'components/ImageUploadDragger';
import MarketingImageTypeDropdown from './MarketingImageTypeDropdown';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';

const StyledItem = styled.div`
  height: 243px;
  margin-top: 16px;

  .ant-input {
    padding: 0;
  }
`;

const StyledTitleInput = styled(Input)<{ $textRight?: boolean }>`
  height: 24px !important;
  padding-left: 0px !important;
  font-size: 16px;
  border: none;
  margin-bottom: 8px;

  ${(props) => props.$textRight && `text-align:right`};
`;

const TitleDivider = styled.div`
  border: 1px solid ${palette.PRIMARY50};
  background-color: ${palette.PRIMARY50};
`;

const StyledDescriptionTextArea = styled(Input.TextArea)<{ type?: string }>`
  border: 1px solid ${palette.GRAY40};
  width: ${(props) => (props.type === 'C' ? 750 : 392)}px;
  height: ${(props) => (props.type === 'C' ? 160 : 175)}px !important;
  font-size: 11px;
  padding: 10px 8px 30px !important;
  resize: none;
`;

const StyledShortDescriptionInput = styled(Input)`
  border: none;
  width: 514px;
  font-size: 11px;
  padding-left: 8px !important;
  height: 17px !important;
`;

const StyledDiv = styled.div`
  width: auto;
  display: inline-block;
  visibility: hidden;
  position: fixed;
  overflow: auto;
`;

interface Props {
  itemField: FormListFieldData;
  form: FormInstance<any>;
  pageIndex: number;
  disabled?: boolean;
  type: string;
}

const MarketingTemplateItem = ({ form, itemField, pageIndex, disabled, type }: Props) => {
  const handleDropdown = (type: string) => {
    form.setFieldsValue({
      pages: produce(form.getFieldValue('pages'), (proxy: any) => {
        proxy[pageIndex].items[itemField.name].itemType = type;
      }),
    });
  };

  const handleDragger = (image: any) => {
    form.setFieldsValue({
      pages: produce(form.getFieldValue('pages'), (proxy: any) => {
        proxy[pageIndex].items[itemField.name].uploadFile = image;
      }),
    });
  };

  const taRef = useRef(null);
  const inputRef = useRef<InputRef>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const shortRef = useRef<HTMLDivElement>(null);

  if (type === 'C') {
    return (
      <StyledItem key={itemField.key}>
        <Form.Item
          name={[itemField.name, 'dataTitle']}
          rules={generateFormRules({
            maxLength: 20,
          })}
        >
          <StyledTitleInput
            disabled={disabled}
            placeholder="자료 제목을 입력해 주세요."
            ref={inputRef}
            maxLength={20}
          />
        </Form.Item>
        <StyledDiv ref={titleRef} />
        <TitleDivider />
        <Form.Item name={[itemField.name, 'uploadFile']}>
          <ImageUploadDragger
            style={{ margin: '8px 0' }}
            disabled={disabled}
            onDragger={handleDragger}
            width={750}
            height={268}
            initialImageUrl={form.getFieldValue([
              'pages',
              pageIndex,
              'items',
              itemField.name,
              'attachUrl',
            ])}
          />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item name={[itemField.name, 'itemType']}>
              <MarketingImageTypeDropdown
                pageIndex={itemField.name}
                initialItemType={form.getFieldValue([
                  'pages',
                  pageIndex,
                  'items',
                  itemField.name,
                  'itemType',
                ])}
                onDropdown={handleDropdown}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name={[itemField.name, 'shortDescription']}
              rules={generateFormRules({
                maxLength: 40,
              })}
            >
              <StyledShortDescriptionInput
                disabled={disabled}
                placeholder="이미지에 대해 간단히 설명해 주세요."
                maxLength={40}
              />
            </Form.Item>
            <StyledDiv ref={shortRef} />
          </Col>
        </Row>
        <Form.Item
          name={[itemField.name, 'description']}
          rules={generateFormRules({
            maxLength: 200,
          })}
        >
          <StyledDescriptionTextArea
            disabled={disabled}
            style={{ marginTop: 8 }}
            type="C"
            placeholder="효능 설명 및 마케팅 클레임 내용을 입력해 주세요."
            ref={taRef}
            maxLength={200}
          />
        </Form.Item>
        <Form.Item
          style={{ display: 'none' }}
          name={[itemField.name, 'locationFlag']}
          initialValue={'UP'}
        />
      </StyledItem>
    );
  }

  return (
    <StyledItem key={itemField.key}>
      <Form.Item
        name={[itemField.name, 'dataTitle']}
        rules={generateFormRules({
          maxLength: 20,
        })}
      >
        <StyledTitleInput
          $textRight={type === 'B'}
          disabled={disabled}
          placeholder="자료 제목을 입력해 주세요."
          ref={inputRef}
          maxLength={20}
        />
      </Form.Item>
      <StyledDiv ref={titleRef} />
      <TitleDivider />
      {type === 'A' && (
        <Row style={{ marginTop: 8 }} gutter={8}>
          <Col>
            <Form.Item name={[itemField.name, 'uploadFile']}>
              <ImageUploadDragger
                disabled={disabled}
                onDragger={handleDragger}
                initialImageUrl={form.getFieldValue([
                  'pages',
                  pageIndex,
                  'items',
                  itemField.name,
                  'attachUrl',
                ])}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name={[itemField.name, 'description']}
              rules={generateFormRules({
                maxLength: 200,
              })}
            >
              <StyledDescriptionTextArea
                disabled={disabled}
                placeholder="효능 설명 및 마케팅 클레임 내용을 입력해 주세요."
                ref={taRef}
                maxLength={200}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {type === 'B' && (
        <Row style={{ marginTop: 8 }} gutter={8}>
          <Col>
            <Form.Item
              name={[itemField.name, 'description']}
              rules={generateFormRules({
                maxLength: 200,
              })}
            >
              <StyledDescriptionTextArea
                disabled={disabled}
                placeholder="효능 설명 및 마케팅 클레임 내용을 입력해 주세요."
                ref={taRef}
                maxLength={200}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={[itemField.name, 'uploadFile']}>
              <ImageUploadDragger
                disabled={disabled}
                onDragger={handleDragger}
                initialImageUrl={form.getFieldValue([
                  'pages',
                  pageIndex,
                  'items',
                  itemField.name,
                  'attachUrl',
                ])}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 8 }}>
        <Col>
          <MarketingImageTypeDropdown
            disabled={disabled}
            initialItemType={form.getFieldValue([
              'pages',
              pageIndex,
              'items',
              itemField.name,
              'itemType',
            ])}
            pageIndex={itemField.name}
            onDropdown={handleDropdown}
          />
        </Col>
        <Col style={{ marginTop: -3 }}>
          <Form.Item
            name={[itemField.name, 'shortDescription']}
            rules={generateFormRules({
              maxLength: 40,
            })}
          >
            <StyledShortDescriptionInput
              disabled={disabled}
              placeholder="이미지에 대해 간단히 설명해 주세요."
              maxLength={40}
            />
          </Form.Item>
          <StyledDiv ref={shortRef} />
        </Col>
      </Row>
      <Form.Item
        style={{ display: 'none' }}
        name={[itemField.name, 'locationFlag']}
        initialValue={itemField.name === 0 ? 'UP' : 'DOWN'}
      />
    </StyledItem>
  );
};

export default MarketingTemplateItem;

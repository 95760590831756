import { Button, Descriptions, Form, Input, InputRef, message, Select, Tag } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { isEqual } from 'lodash';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { MinusIcon, Typography } from 'components/system';
import { CorrectButton, Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import { getChangedValues } from 'lib/common';
import { messages } from 'lib/consts';
import { filterOptionForStringLabel } from 'lib/form';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { requireRule } from 'lib/validate';
import {
  useNMPABasicInfoStatus,
  useNMPABasicOld,
  useNMPABAT,
  useNMPACorrectWithNoUpdate,
  useNMPAFormattedBasicInfoAttributes,
  useNMPAOverwriteHistory,
  useNMPATempStore,
} from 'service/material/nmpa/nmpa';
import {
  DocumentCode,
  MaterialNmpaBasicInfoStatus,
  NMPABATAttribute,
  NMPABATForm,
  NMPABATParam,
  NMPABATRegisterParams,
  NMPABATRegisterParamsAdditional,
  NMPADocumentCode,
  NMPATempStoreType,
} from 'types/material/nmpa/nmpa';
import AttributeCheckbox from './AttributeCheckbox';

const Container = styled.div`
  max-width: 1040px;

  .ant-descriptions-header {
    margin-bottom: 8px;
  }

  .ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view {
    border-width: 0px;
    border-top: 2px solid ${palette.PRIMARY50};
    border-bottom: 1px solid ${palette.GRAY40};
    border-radius: 0;
  }

  .ant-descriptions-title {
    overflow: initial;
  }

  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-label {
    background-color: ${palette.GRAY10};
    width: 160px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding: 0 16px;
  }

  .ant-descriptions-row {
    border-color: ${palette.GRAY40};
  }

  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-content {
    border-left-width: 0px;
    border-right-width: 0px;
    padding: 8px 16px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-tag {
    background-color: #f5f9ff;
    margin-right: 4px;
    color: #4e5968;
    border: 1px solid #3785f7;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 4px 4px 8px;
    display: flex;
    align-items: center;

    & > span {
      margin-left: 4px;
      color: #4e5968;
    }
  }

  .ant-select-disabled {
    .ant-tag {
      background-color: ${palette.GRAY30};
      color: #a9a6a6;
      border: 1px solid ${palette.GRAY40};
    }
  }
`;

const CollapseBoxContainer = styled(Flex)`
  margin-top: 32px;
  padding: 16px 36px;
  background: ${palette.SLATE_GRAY30};
  border-radius: 8px;

  cursor: pointer;
`;

const CollapseIcon = styled.div`
  width: 6px;
  height: 6px;
  background: ${palette.PRIMARY50};
`;

const CollapseChild = styled.div<{ active: boolean }>`
  visibility: ${({ active }) => !active && 'hidden'};
  width: ${({ active }) => !active && 0};
  height: ${({ active }) => !active && 0};
  opacity: ${({ active }) => !active && 0};
`;

const tagRender = (props: {
  label: React.ReactNode;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
}) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
      {label}
    </Tag>
  );
};

const etcText = '기타 (직접 입력)';

const NMPABATItem = ({ nmpaBasicInfoId }: { nmpaBasicInfoId: number }) => {
  const navigate = useNavigate();
  // HINT: 최초 불러온 데이터를 저장하고 있고, 수정할 내역을 검사할 때 사용됩니다.
  const [initFormData, setInitFormData] = useState<NMPABATForm>();
  const [form] = Form.useForm<NMPABATForm>();
  const [activeCollapse, setActiveCollapse] = useState<{
    [key: string]: boolean;
  }>({
    NMPA_MAIN_INGREDIENT_BASIC_FEATURE: true,
    NMPA_RAW_MATERIAL_SOURCE: true,
    NMPA_RAW_MATERIAL_PRODUCTION_METHOD: true,
    NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE: true,
    NMPA_RAW_MATERIAL_MIXING_REASON: true,
  });
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const { openAlertModal, openNMPACorrectChatModal } = useModal();
  const { nmpaBasicInfo } = useNMPABasicOld(nmpaBasicInfoId);

  const materialNmpaBasicInfoId = nmpaBasicInfo?.materialNmpaBasicInfoId;

  const { nmpaCorrectRequestWithNoUpdate, nmpaCorrectRequestWithNoUpdateLoading } =
    useNMPACorrectWithNoUpdate({
      materialNmpaBasicInfoId,
      documentCode: NMPADocumentCode.BAT,
    });

  const { getLoading, nmpaBAT, nmpaBATCategory, addNMPABAT, addLoading } =
    useNMPABAT(nmpaBasicInfoId);

  const { categoryMap: categoryInfo, collapsableCategoryIds } = useNMPAFormattedBasicInfoAttributes(
    {
      batCategories: nmpaBATCategory || [],
    },
  );

  const { nmpaTempStore, addNMPATempStore, addNMPATempStoreLoading } = useNMPATempStore({
    type: NMPATempStoreType.BASIC_ATTRIBUTE,
    materialNmpaBasicInfoId: nmpaBasicInfoId,
  });

  const { basicInfoStatus, isLoading: isBasicInfoStatusLoading } = useNMPABasicInfoStatus({
    materialId: nmpaBasicInfoId,
    documentCode: DocumentCode.BAT,
    enabled: nmpaBasicInfoId !== undefined && nmpaBAT.length > 0,
  });

  const { nmpaOverwriteHistory, nmpaOverwriteHistoryLoading } = useNMPAOverwriteHistory({
    nmpaBasicInfoId,
    documentCode: NMPADocumentCode.BAT,
  });

  const readOnly =
    (nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.ONGOING &&
      !basicInfoStatus?.isNmpaDocModStatus) ||
    nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.FINISH;
  const updateMode = nmpaBAT.length > 0;

  const ctaName = useMemo(() => {
    if (!updateMode) return '등록';
    if (basicInfoStatus?.isNmpaDocModStatus) return '보완 완료';
    return '수정';
  }, [updateMode, basicInfoStatus]);

  const options = useMemo(
    () =>
      nmpaBasicInfo?.materialNmpaBasicInfoIncis && [
        { label: '원료 전체', value: 0 },
        ...nmpaBasicInfo.materialNmpaBasicInfoIncis.map((item) => ({
          label: `${item.chinaIngredient.nameEn}`,
          value: item.materialNmpaBasicInfoInciId,
        })),
      ],
    [nmpaBasicInfo],
  );

  const transformFormValues = (formValues: NMPABATForm) => {
    const nmpaBasicAttributes: NMPABATRegisterParams[] = [];
    const arr = [
      formValues[NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE],
      ...formValues[NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE],
      ...formValues[NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD],
      ...(formValues[NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE]?.length > 0
        ? formValues[NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE]
        : []),
      ...formValues[NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON],
    ];

    arr.forEach((materialCategoryId) => {
      const materialNmpaBasicAttributeAdditionals: NMPABATRegisterParamsAdditional[] = [];

      formValues[materialCategoryId]?.forEach((item) => {
        const isDirectInputOrigin = item.origin === etcText;
        const isDirectInputIndicator = item.indicator === etcText;

        materialNmpaBasicAttributeAdditionals.push({
          ...(item.materialNmpaBasicInfoInciIds?.length !== 0 && {
            materialNmpaBasicInfoInciIds: item.materialNmpaBasicInfoInciIds?.filter(
              (item) => item !== 0,
            ),
          }),
          isAllMaterial: item.materialNmpaBasicInfoInciIds?.includes(0) ? true : false,
          ...(item.chinaIngredientId && {
            chinaIngredientId: item.chinaIngredientId,
          }),
          ...(item.origin && {
            origin: isDirectInputOrigin ? item.originDirectInput : item.origin,
            isDirectInputOrigin,
          }),
          ...(item.originTargetName && {
            originTargetName: item.originTargetName,
          }),
          ...(item.indicator && {
            indicator: isDirectInputIndicator ? item.indicatorDirectInput : item.indicator,
            isDirectInputIndicator,
          }),
          ...(item.figure && { figure: item.figure }),
          ...(item.unit && { unit: item.unit }),
          ...(item.molecularFormula && {
            molecularFormula: item.molecularFormula,
          }),
          ...(item.casNo && { casNo: item.casNo }),
          ...(item.testWay && { testWay: item.testWay }),
          ...(item.scientificName && { scientificName: item.scientificName }),
          ...(item.organismName && { organismName: item.organismName }),
          ...(item.heredity && { heredity: item.heredity }),
          ...(item.geneticModification && {
            geneticModification: item.geneticModification,
          }),
          ...(item.carrier && { carrier: item.carrier }),
          ...(item.source && { source: item.source }),
        });
      });

      nmpaBasicAttributes.push({
        materialCategoryId,
        ...(materialNmpaBasicAttributeAdditionals.length !== 0 && {
          materialNmpaBasicAttributeAdditionals,
        }),
      });
    });

    return nmpaBasicAttributes;
  };

  const handleSubmit = (formValues: NMPABATForm) => {
    if (formValues[formValues.NMPA_MAIN_INGREDIENT_BASIC_FEATURE].length === 0) {
      message.warning('주성분 기본 기능을 입력해주세요.');
      return;
    }

    if (initFormData !== undefined && isEqual(formValues, initFormData)) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    const syntheticProcessMixture = nmpaBATCategory.find(
      ({ code }) => code === 'NMPA_RAW_MATERIAL_MIXING_REASON001',
    )?.materialCategoryId;

    let modalContent = '';
    let okMessage = '';

    const isSyntheticProcessMixtureIncludes =
      syntheticProcessMixture &&
      formValues[NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON].includes(
        syntheticProcessMixture,
      );

    // "합성 공정 등으로 인한 혼합물"을 선택하지 않은 경우
    if (
      !isSyntheticProcessMixtureIncludes &&
      nmpaBasicInfo?.materialNmpaBasicInfoIncis.some(({ content }) => content === null)
    ) {
      modalContent =
        '중국 안전 정보 등록 플랫폼 규정 상\n원료 배합/ 혼합하는 사유에 따라\n함량 입력이 반드시 필요합니다.';
      okMessage = '함량를 입력해 주세요.';
    }

    // "합성 공정 등으로 인한 혼합물"만 선택한 경우
    if (
      isSyntheticProcessMixtureIncludes &&
      formValues[NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON].length === 1 &&
      nmpaBasicInfo?.materialNmpaBasicInfoIncis.some(
        ({ rangeMin, rangeMax }) => rangeMin === null || rangeMax === null,
      )
    ) {
      modalContent =
        '중국 안전 정보 등록 플랫폼 규정 상\n합성 공정으로 인한 혼합물인 경우 범위 입력이\n반드시 필요합니다.';
      okMessage = '범위를 입력해 주세요.';
    }

    // "합성 공정 등으로 인한 혼합물" + "그 외" 선택한 경우
    if (
      isSyntheticProcessMixtureIncludes &&
      formValues[NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON].length !== 1 &&
      nmpaBasicInfo?.materialNmpaBasicInfoIncis.some(
        ({ content, rangeMin, rangeMax }) =>
          content === null || rangeMin === null || rangeMax === null,
      )
    ) {
      modalContent =
        '중국 안전 정보 등록 플랫폼 규정 상\n합성 공정으로 인한 혼합물인 경우 범위를,\n그 외 혼합물인 경우 함량을 반드시 입력해 주세요.';
      okMessage = '함량/범위를 입력해 주세요.';
    }

    if (initFormData) {
      nmpaOverwriteHistory(getChangedValues({ obj1: initFormData, obj2: formValues }));
    }

    addNMPABAT(transformFormValues(formValues), {
      onSuccess: () => {
        message.success(`${ctaName}되었습니다.`);
        navigate(-1);

        if (modalContent && okMessage) {
          openAlertModal({
            width: 400,
            content: modalContent,
            closeText: '나중에',
            okText: '입력하기',
            onOk: () => {
              message.info(okMessage);
              navigate(
                `${path.material.nmpa.material.root}/${DocumentCode.BASIC}?materialId=${nmpaBasicInfoId}`,
              );
            },
          });
        }
      },
    });
  };

  const handleNoUpdateSubmit = () => {
    if (materialNmpaBasicInfoId) {
      openAlertModal({
        content: `
'보완할 내용 없음' 버튼을 클릭하시면, 관리자의 보완 요청이 있기 전까지는 수정이 불가합니다.

보완할 내용 없이 처리 하시겠습니까?
`,
        onOk: () => {
          nmpaCorrectRequestWithNoUpdate(
            {},
            {
              onSuccess: () => {
                message.success('보완 내용 없이 완료 처리되었습니다.');
                navigate(-1);
              },
            },
          );
        },
      });
    }
  };

  const handleFinishFailed = (errorInfo: ValidateErrorEntity) => {
    setActiveCollapse({
      NMPA_MAIN_INGREDIENT_BASIC_FEATURE: true,
      NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE: true,
      NMPA_RAW_MATERIAL_MIXING_REASON: true,
      NMPA_RAW_MATERIAL_PRODUCTION_METHOD: true,
      NMPA_RAW_MATERIAL_SOURCE: true,
    });

    const { errorFields } = errorInfo;

    if (errorFields.length > 0) {
      const id = errorFields[0].name.join('_');
      const el = document.getElementById(id);
      const top =
        el?.getBoundingClientRect().top && window.scrollY + el?.getBoundingClientRect().top - 300;

      window.scrollTo({ top });
    }
  };

  const handleSaveDetail = () => {
    addNMPATempStore(JSON.stringify({ ...form.getFieldsValue() }), {
      onSuccess: () => {
        message.success('임시저장되었습니다.');
      },
    });
  };

  useEffect(() => {
    const newFormData: NMPABATForm = {} as NMPABATForm;

    if (nmpaBAT.length !== 0) {
      nmpaBAT.forEach(({ materialCategoryId, materialNmpaBasicAttributeAdditionals }) => {
        if (
          categoryInfo[NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE]?.includes(
            materialCategoryId,
          )
        ) {
          newFormData[NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE] = materialCategoryId;
        }
        if (categoryInfo[NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE]?.includes(materialCategoryId)) {
          newFormData[NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE] = newFormData[
            NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE
          ]
            ? [...newFormData[NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE], materialCategoryId]
            : [materialCategoryId];
        }
        if (
          categoryInfo[NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD]?.includes(
            materialCategoryId,
          )
        ) {
          newFormData[NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD] = newFormData[
            NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD
          ]
            ? [
                ...newFormData[NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD],
                materialCategoryId,
              ]
            : [materialCategoryId];
        }
        if (
          categoryInfo[NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE]?.includes(
            materialCategoryId,
          )
        ) {
          newFormData[NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE] = newFormData[
            NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE
          ]
            ? [
                ...newFormData[NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE],
                materialCategoryId,
              ]
            : [materialCategoryId];
        }
        if (
          categoryInfo[NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON]?.includes(
            materialCategoryId,
          )
        ) {
          newFormData[NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON] = newFormData[
            NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON
          ]
            ? [...newFormData[NMPABATAttribute.NMPA_RAW_MATERIAL_MIXING_REASON], materialCategoryId]
            : [materialCategoryId];
        }

        newFormData[materialCategoryId] = [];

        materialNmpaBasicAttributeAdditionals.forEach((item, index) => {
          newFormData[materialCategoryId][index] = {
            key: index,
            ...((item.materialNmpaBasicInfoIncis.length !== 0 || item.isAllMaterial) && {
              materialNmpaBasicInfoInciIds: item.isAllMaterial
                ? [0]
                : item.materialNmpaBasicInfoIncis.map(
                    ({ materialNmpaBasicInfoInciId }) => materialNmpaBasicInfoInciId,
                  ),
            }),
            ...(item.origin && {
              origin: item.isDirectInputOrigin ? etcText : item.origin,
            }),
            ...(item.originTargetName && {
              originTargetName: item.originTargetName,
            }),
            ...(item.isDirectInputOrigin && {
              originDirectInput: item.origin,
            }),
            ...(item.indicator && {
              indicator: item.isDirectInputIndicator ? etcText : item.indicator,
            }),
            ...(item.isDirectInputIndicator && {
              indicatorDirectInput: item.indicator,
            }),
            ...(item.figure && { figure: item.figure }),
            ...(item.unit && { unit: item.unit }),
            ...(item.molecularFormula && {
              molecularFormula: item.molecularFormula,
            }),
            ...(item.casNo && { casNo: item.casNo }),
            ...(item.testWay && { testWay: item.testWay }),
            ...(item.scientificName && {
              scientificName: item.scientificName,
            }),
            ...(item.organismName && { organismName: item.organismName }),
            ...(item.heredity && { heredity: item.heredity }),
            ...(item.geneticModification && {
              geneticModification: item.geneticModification,
            }),
            ...(item.carrier && { carrier: item.carrier }),
            ...(item.source && { source: item.source }),
          };
        });
      });

      form.setFieldsValue(newFormData);
      setTimeout(() => setInitFormData(form.getFieldsValue()), 0);
    } else if (!getLoading && nmpaBAT.length === 0 && nmpaTempStore) {
      form.setFieldsValue(JSON.parse(nmpaTempStore));
    }
  }, [nmpaBAT, nmpaTempStore, getLoading]);

  const loading = isBasicInfoStatusLoading || getLoading;

  return (
    <Container>
      {loading && <FullLoading />}
      {basicInfoStatus?.isNmpaDocModStatus && (
        <Flex justify="end" style={{ marginBottom: 16 }}>
          <CorrectButton
            onClick={() => {
              openNMPACorrectChatModal({
                title: nmpaBasicInfo?.tradeName,
                nmpaBasicInfoId: materialNmpaBasicInfoId,
                documentCode: NMPADocumentCode.BAT,
                isFirstOpenChatModal,
              });
              setIsFirstOpenChatModal(true);
            }}
            disableAnim={false}
          />
        </Flex>
      )}
      <Form form={form} onFinish={handleSubmit} onFinishFailed={handleFinishFailed}>
        <AttributeCheckbox readOnly={readOnly} nmpaBATCategory={nmpaBATCategory} form={form} />
        <Form.Item
          noStyle
          shouldUpdate={(prev: any, next: any) => {
            return prev?.[NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE]
              ? prev[NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE] !==
                  next[NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE]
              : true;
          }}
        >
          {({ getFieldValue }) =>
            getFieldValue(NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE) && (
              <BasicFeature
                readOnly={readOnly}
                options={options}
                activeCollapse={activeCollapse}
                onChangeActiveCollapse={setActiveCollapse}
                getFieldValue={getFieldValue}
                collapsableCategoryIds={collapsableCategoryIds}
                nmpaBAT={nmpaBAT}
              />
            )
          }
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev: any, next: any) => {
            return prev?.[NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE]
              ? prev[NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE].length !==
                  next[NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE].length
              : true;
          }}
        >
          {({ getFieldValue }) =>
            getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE) &&
            getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE).length !== 0 &&
            getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE).some((item: any) =>
              Object.values(collapsableCategoryIds).includes(item),
            ) && (
              <SourceOfMaterial
                readOnly={readOnly}
                options={options}
                activeCollapse={activeCollapse}
                onChangeActiveCollapse={setActiveCollapse}
                getFieldValue={getFieldValue}
                collapsableCategoryIds={collapsableCategoryIds}
                nmpaBAT={nmpaBAT}
              />
            )
          }
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev: any, next: any) => {
            return prev?.[NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD]
              ? prev[NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD].length !==
                  next[NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD].length
              : true;
          }}
        >
          {({ getFieldValue }) =>
            getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD) &&
            getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD).length !== 0 &&
            getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD).some((item: any) =>
              Object.values(collapsableCategoryIds).includes(item),
            ) && (
              <ProducingMethod
                readOnly={readOnly}
                options={options}
                activeCollapse={activeCollapse}
                onChangeActiveCollapse={setActiveCollapse}
                getFieldValue={getFieldValue}
                collapsableCategoryIds={collapsableCategoryIds}
                nmpaBAT={nmpaBAT}
              />
            )
          }
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev: any, next: any) => {
            return prev?.[NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE]
              ? prev[NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE].length !==
                  next[NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE].length
              : true;
          }}
        >
          {({ getFieldValue }) =>
            getFieldValue(NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE) &&
            getFieldValue(NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE).length !== 0 &&
            getFieldValue(NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE).some((item: any) =>
              Object.values(collapsableCategoryIds).includes(item),
            ) && (
              <EtcAttribute
                readOnly={readOnly}
                options={options}
                activeCollapse={activeCollapse}
                onChangeActiveCollapse={setActiveCollapse}
                getFieldValue={getFieldValue}
                collapsableCategoryIds={collapsableCategoryIds}
                nmpaBAT={nmpaBAT}
              />
            )
          }
        </Form.Item>
        <FooterBox>
          {!readOnly && nmpaBAT.length === 0 && (
            <Button loading={addNMPATempStoreLoading} onClick={handleSaveDetail}>
              임시 저장
            </Button>
          )}
          <ReadOnlyBackButton readOnly={readOnly}>
            <Flex>
              {basicInfoStatus?.isNmpaDocModStatus && (
                <Button
                  loading={nmpaCorrectRequestWithNoUpdateLoading}
                  onClick={handleNoUpdateSubmit}
                >
                  보완할 내용 없음
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                loading={addLoading || nmpaOverwriteHistoryLoading}
              >
                {ctaName}
              </Button>
            </Flex>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </Container>
  );
};

const BasicFeature = ({
  activeCollapse,
  onChangeActiveCollapse,
  options,
  getFieldValue,
  readOnly,
  collapsableCategoryIds,
  nmpaBAT,
}: {
  readOnly: boolean;
  activeCollapse: {
    [key: string]: boolean;
  };
  onChangeActiveCollapse: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  options: { label: string; value: number }[] | undefined;
  getFieldValue: (name: NamePath) => any;
  collapsableCategoryIds: {
    [key: string]: number;
  };
  nmpaBAT: NMPABATParam[];
}) => {
  const inputRef = useRef<InputRef | null>(null);

  return (
    <CollapseBox
      activeCollapse={activeCollapse}
      collapseCode="NMPA_MAIN_INGREDIENT_BASIC_FEATURE"
      onChangeActiveCollapse={onChangeActiveCollapse}
      title="주성분 기본 기능"
    >
      <>
        {getFieldValue(NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE) ===
          collapsableCategoryIds['NMPA_MAIN_INGREDIENT_BASIC_FEATURE001'] && (
          <Form.List
            name={collapsableCategoryIds['NMPA_MAIN_INGREDIENT_BASIC_FEATURE001']}
            initialValue={[{}]}
          >
            {(fields, { remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}

                        <Typography.Text medium gutter={{ top: 16 }}>
                          구조가 명확한 단일 화합물
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_MAIN_INGREDIENT_BASIC_FEATURE001"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          지표
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex gap={8}>
                        <Form.Item
                          noStyle
                          shouldUpdate={() => {
                            return true;
                          }}
                        >
                          {({ getFieldValue, setFieldsValue }) => {
                            return (
                              <>
                                <Form.Item
                                  name={[name, 'indicator']}
                                  style={{ flex: 1 }}
                                  rules={[requireRule]}
                                >
                                  <Select
                                    showSearch
                                    filterOption={(keyword, option) =>
                                      (option?.label as string)
                                        .toLowerCase()
                                        .includes(keyword.toLowerCase())
                                    }
                                    placeholder="물질을 나타내는 지표"
                                    disabled={readOnly}
                                    options={[
                                      {
                                        label: '순도',
                                        value: '순도',
                                      },
                                      {
                                        label: etcText,
                                        value: etcText,
                                      },
                                    ]}
                                    onSelect={(value) => {
                                      if (value === etcText) {
                                        setTimeout(() => inputRef?.current?.focus());
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={
                                    getFieldValue([
                                      collapsableCategoryIds[
                                        'NMPA_MAIN_INGREDIENT_BASIC_FEATURE001'
                                      ],
                                      name,
                                      'indicator',
                                    ]) !== etcText
                                      ? [name, 'indicator']
                                      : [name, 'indicatorDirectInput']
                                  }
                                  style={{ flex: 1 }}
                                  rules={[
                                    requireRule,
                                    {
                                      pattern: /^.{1,100}$/,
                                      message: '최대 100자',
                                    },
                                  ]}
                                >
                                  <Input
                                    ref={inputRef}
                                    disabled={
                                      readOnly ||
                                      getFieldValue([
                                        collapsableCategoryIds[
                                          'NMPA_MAIN_INGREDIENT_BASIC_FEATURE001'
                                        ],
                                        name,
                                        'indicator',
                                      ]) !== etcText
                                    }
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          수치
                        </Typography.Text>
                      }
                      contentStyle={{ width: 356 }}
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'figure']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,50}$/,
                            message: '한글 입력 불가, 최대 50자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          단위
                        </Typography.Text>
                      }
                      contentStyle={{ width: 244 }}
                      span={1}
                    >
                      <Form.Item
                        name={[name, 'unit']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,10}$/,
                            message: '최대 10자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          분자식
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'molecularFormula']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,300}$/,
                            message: '한글 입력 불가, 최대 300자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          CAS No.
                        </Typography.Text>
                      }
                      span={1}
                    >
                      <Form.Item
                        name={[name, 'casNo']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,13}$/,
                            message: '한글 입력 불가, 최대 13자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          테스트 방법
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'testWay']}
                        rules={[
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
              </>
            )}
          </Form.List>
        )}
        {getFieldValue(NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE) ===
          collapsableCategoryIds['NMPA_MAIN_INGREDIENT_BASIC_FEATURE002'] && (
          <Form.List
            name={collapsableCategoryIds['NMPA_MAIN_INGREDIENT_BASIC_FEATURE002']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          혼합물(폴리머 미함유)
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_MAIN_INGREDIENT_BASIC_FEATURE002"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          지표
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex gap={8}>
                        <Form.Item
                          noStyle
                          shouldUpdate={() => {
                            return true;
                          }}
                        >
                          {({ getFieldValue }) => {
                            return (
                              <>
                                <Form.Item
                                  name={[name, 'indicator']}
                                  style={{ flex: 1 }}
                                  rules={[requireRule]}
                                >
                                  <Select
                                    placeholder="물질을 나타내는 지표"
                                    showSearch
                                    filterOption={(keyword, option) =>
                                      (option?.label as string)
                                        .toLowerCase()
                                        .includes(keyword.toLowerCase())
                                    }
                                    disabled={readOnly}
                                    options={[
                                      {
                                        label: '평균 분자량',
                                        value: '평균 분자량',
                                      },
                                      {
                                        label: '응집도',
                                        value: '응집도',
                                      },
                                      {
                                        label: '순도',
                                        value: '순도',
                                      },
                                      {
                                        label: etcText,
                                        value: etcText,
                                      },
                                    ]}
                                    onSelect={(value) => {
                                      if (value === etcText) {
                                        setTimeout(() => inputRef?.current?.focus());
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={
                                    getFieldValue([
                                      collapsableCategoryIds[
                                        'NMPA_MAIN_INGREDIENT_BASIC_FEATURE002'
                                      ],
                                      name,
                                      'indicator',
                                    ]) !== etcText
                                      ? [name, 'indicator']
                                      : [name, 'indicatorDirectInput']
                                  }
                                  style={{ flex: 1 }}
                                  rules={[
                                    requireRule,
                                    {
                                      pattern: /^.{1,100}$/,
                                      message: '최대 100자',
                                    },
                                  ]}
                                >
                                  <Input
                                    ref={inputRef}
                                    disabled={
                                      readOnly ||
                                      getFieldValue([
                                        collapsableCategoryIds[
                                          'NMPA_MAIN_INGREDIENT_BASIC_FEATURE002'
                                        ],
                                        name,
                                        'indicator',
                                      ]) !== etcText
                                    }
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          수치
                        </Typography.Text>
                      }
                      span={2}
                      contentStyle={{ width: 356 }}
                    >
                      <Form.Item
                        name={[name, 'figure']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,50}$/,
                            message: '한글 입력 불가, 최대 50자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          단위
                        </Typography.Text>
                      }
                      span={1}
                      contentStyle={{ width: 244 }}
                    >
                      <Form.Item
                        name={[name, 'unit']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,10}$/,
                            message: '최대 10자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          분자식
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'molecularFormula']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,100}$/,
                            message: '한글 입력 불가, 최대 100자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          CAS No.
                        </Typography.Text>
                      }
                      span={1}
                    >
                      <Form.Item
                        name={[name, 'casNo']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,13}$/,
                            message: '한글 입력 불가, 최대 13자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          테스트 방법
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'testWay']}
                        rules={[
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                <PlusButton readOnly={readOnly} count={fields.length} add={add} />
              </>
            )}
          </Form.List>
        )}
        {getFieldValue(NMPABATAttribute.NMPA_MAIN_INGREDIENT_BASIC_FEATURE) ===
          collapsableCategoryIds['NMPA_MAIN_INGREDIENT_BASIC_FEATURE003'] && (
          <Form.List
            name={collapsableCategoryIds['NMPA_MAIN_INGREDIENT_BASIC_FEATURE003']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          폴리머(또는 함유 혼합물)
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_MAIN_INGREDIENT_BASIC_FEATURE003"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          지표
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex gap={8}>
                        <Form.Item
                          noStyle
                          shouldUpdate={() => {
                            return true;
                          }}
                        >
                          {({ getFieldValue }) => {
                            return (
                              <>
                                <Form.Item
                                  name={[name, 'indicator']}
                                  style={{ flex: 1 }}
                                  rules={[requireRule]}
                                >
                                  <Select
                                    placeholder="물질을 나타내는 지표"
                                    showSearch
                                    filterOption={(keyword, option) =>
                                      (option?.label as string)
                                        .toLowerCase()
                                        .includes(keyword.toLowerCase())
                                    }
                                    disabled={readOnly}
                                    options={[
                                      {
                                        label: '평균 분자량',
                                        value: '평균 분자량',
                                      },
                                      {
                                        label: '응집도',
                                        value: '응집도',
                                      },
                                      {
                                        label: etcText,
                                        value: etcText,
                                      },
                                    ]}
                                    onSelect={(value) => {
                                      if (value === etcText) {
                                        setTimeout(() => inputRef?.current?.focus());
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={
                                    getFieldValue([
                                      collapsableCategoryIds[
                                        'NMPA_MAIN_INGREDIENT_BASIC_FEATURE003'
                                      ],
                                      name,
                                      'indicator',
                                    ]) !== etcText
                                      ? [name, 'indicator']
                                      : [name, 'indicatorDirectInput']
                                  }
                                  style={{ flex: 1 }}
                                  rules={[
                                    requireRule,
                                    {
                                      pattern: /^.{1,100}$/,
                                      message: '최대 100자',
                                    },
                                  ]}
                                >
                                  <Input
                                    ref={inputRef}
                                    disabled={
                                      readOnly ||
                                      getFieldValue([
                                        collapsableCategoryIds[
                                          'NMPA_MAIN_INGREDIENT_BASIC_FEATURE003'
                                        ],
                                        name,
                                        'indicator',
                                      ]) !== etcText
                                    }
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          수치
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'figure']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,50}$/,
                            message: '한글 입력 불가, 최대 50자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          단위
                        </Typography.Text>
                      }
                      span={1}
                      contentStyle={{ width: 244 }}
                    >
                      <Form.Item
                        name={[name, 'unit']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,10}$/,
                            message: '최대 10자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          분자식
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'molecularFormula']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,100}$/,
                            message: '한글 입력 불가, 최대 100자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          CAS No.
                        </Typography.Text>
                      }
                      span={1}
                    >
                      <Form.Item
                        name={[name, 'casNo']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,13}$/,
                            message: '한글 입력 불가, 최대 13자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          테스트 방법
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'testWay']}
                        rules={[
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                <PlusButton readOnly={readOnly} count={fields.length} add={add} />
              </>
            )}
          </Form.List>
        )}
      </>
    </CollapseBox>
  );
};

const SourceOfMaterial = ({
  activeCollapse,
  onChangeActiveCollapse,
  options,
  getFieldValue,
  readOnly,
  collapsableCategoryIds,
  nmpaBAT,
}: {
  readOnly: boolean;
  activeCollapse: {
    [key: string]: boolean;
  };
  onChangeActiveCollapse: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  options: { label: string; value: number }[] | undefined;
  getFieldValue: (name: NamePath) => any;
  collapsableCategoryIds: { [key: string]: number };
  nmpaBAT: NMPABATParam[];
}) => {
  const inputRef = useRef<InputRef | null>(null);

  return (
    <CollapseBox
      activeCollapse={activeCollapse}
      collapseCode={NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE}
      onChangeActiveCollapse={onChangeActiveCollapse}
      title="원료의 출처"
    >
      <>
        {getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE).includes(
          collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE002'],
        ) && (
          <Form.List
            name={collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE002']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          식물(직접유래)
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_RAW_MATERIAL_SOURCE002"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          Origin (유래)
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex gap={8}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(_: any, __: any) => {
                            return true;
                          }}
                        >
                          {({ getFieldValue }) => {
                            return (
                              <>
                                <Form.Item
                                  name={[name, 'origin']}
                                  style={{ flex: 1 }}
                                  rules={[requireRule]}
                                >
                                  <Select
                                    placeholder="물질이 추출된 부분"
                                    showSearch
                                    filterOption={(keyword, option) =>
                                      (option?.label as string)
                                        .toLowerCase()
                                        .includes(keyword.toLowerCase())
                                    }
                                    disabled={readOnly}
                                    options={[
                                      {
                                        label: '식물 전체(전초)',
                                        value: '식물 전체(전초)',
                                      },
                                      {
                                        label: '잎',
                                        value: '잎',
                                      },
                                      {
                                        label: '열매',
                                        value: '열매',
                                      },
                                      {
                                        label: '뿌리',
                                        value: '뿌리',
                                      },
                                      {
                                        label: '꽃',
                                        value: '꽃',
                                      },
                                      {
                                        label: '줄기',
                                        value: '줄기',
                                      },
                                      {
                                        label: '씨',
                                        value: '씨',
                                      },
                                      {
                                        label: etcText,
                                        value: etcText,
                                      },
                                    ]}
                                    onSelect={(value) => {
                                      if (value === etcText) {
                                        setTimeout(() => inputRef?.current?.focus());
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={
                                    getFieldValue([
                                      collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE002'],
                                      name,
                                      'origin',
                                    ]) !== etcText
                                      ? [name, 'origin']
                                      : [name, 'originDirectInput']
                                  }
                                  style={{ flex: 1 }}
                                  rules={[
                                    requireRule,
                                    {
                                      pattern: /^.{1,100}$/,
                                      message: '최대 100자',
                                    },
                                  ]}
                                >
                                  <Input
                                    ref={inputRef}
                                    disabled={
                                      readOnly ||
                                      getFieldValue([
                                        collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE002'],
                                        name,
                                        'origin',
                                      ]) !== etcText
                                    }
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          유래 식물명
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'originTargetName']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,100}$/,
                            message: '한글 입력 불가, 최대 100자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          지표
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex gap={8}>
                        <Form.Item noStyle shouldUpdate={(_, __) => true}>
                          {({ getFieldValue }) => {
                            return (
                              <>
                                <Form.Item name={[name, 'indicator']} style={{ flex: 1 }}>
                                  <Select
                                    placeholder="물질을 나타내는 지표"
                                    showSearch
                                    filterOption={(keyword, option) =>
                                      (option?.label as string)
                                        .toLowerCase()
                                        .includes(keyword.toLowerCase())
                                    }
                                    disabled={readOnly}
                                    options={[
                                      {
                                        label: '성분전체',
                                        value: '성분전체',
                                      },
                                      {
                                        label: '증발잔류물',
                                        value: '증발잔류물',
                                      },
                                      {
                                        label: '고체함량',
                                        value: '고체함량',
                                      },
                                      {
                                        label: '건조감량',
                                        value: '건조감량',
                                      },
                                      {
                                        label: '점화잔류물',
                                        value: '점화잔류물',
                                      },
                                      {
                                        label: '물리화학적 지표',
                                        value: '물리화학적 지표',
                                      },
                                      {
                                        label: etcText,
                                        value: etcText,
                                      },
                                    ]}
                                    onSelect={(value) => {
                                      if (value === etcText) {
                                        setTimeout(() => inputRef?.current?.focus());
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={
                                    getFieldValue([
                                      collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE002'],
                                      name,
                                      'indicator',
                                    ]) !== etcText
                                      ? [name, 'indicator']
                                      : [name, 'indicatorDirectInput']
                                  }
                                  style={{ flex: 1 }}
                                  rules={[
                                    {
                                      pattern: /^.{1,100}$/,
                                      message: '최대 100자',
                                    },
                                  ]}
                                >
                                  <Input
                                    ref={inputRef}
                                    disabled={
                                      readOnly ||
                                      getFieldValue([
                                        collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE002'],
                                        name,
                                        'indicator',
                                      ]) !== etcText
                                    }
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          수치
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'figure']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,50}$/,
                            message: '한글 입력 불가, 최대 50자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          단위
                        </Typography.Text>
                      }
                      span={1}
                      contentStyle={{ width: 244 }}
                    >
                      <Form.Item
                        name={[name, 'unit']}
                        rules={[
                          {
                            pattern: /^.{1,10}$/,
                            message: '최대 10자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          분자식
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'molecularFormula']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,100}$/,
                            message: '한글 입력 불가, 최대 100자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          CAS No.
                        </Typography.Text>
                      }
                      span={1}
                    >
                      <Form.Item
                        name={[name, 'casNo']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,13}$/,
                            message: '한글 입력 불가, 최대 13자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          테스트 방법
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'testWay']}
                        rules={[
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                <PlusButton readOnly={readOnly} count={fields.length} add={add} />
              </>
            )}
          </Form.List>
        )}
        {getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE).includes(
          collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE004'],
        ) && (
          <Form.List
            name={collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE004']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          동물(직접유래)
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_RAW_MATERIAL_SOURCE004"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          Origin (유래)
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'origin']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,100}$/,
                            message: '최대 100자',
                          },
                        ]}
                      >
                        <Input placeholder="유래 부위" disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          유래 동물명
                        </Typography.Text>
                      }
                    >
                      <Form.Item
                        name={[name, 'originTargetName']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,100}$/,
                            message: '한글 입력 불가, 최대 100자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                <PlusButton readOnly={readOnly} count={fields.length} add={add} />
              </>
            )}
          </Form.List>
        )}
        {getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_SOURCE).includes(
          collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE007'],
        ) && (
          <Form.List
            name={collapsableCategoryIds['NMPA_RAW_MATERIAL_SOURCE007']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          조류 및 거대균류(직접유래)
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_RAW_MATERIAL_SOURCE007"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          Origin (유래)
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'origin']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,100}$/,
                            message: '최대 100자',
                          },
                        ]}
                      >
                        <Input placeholder="유래 부위" disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text
                          color="SLATE_GRAY70"
                          medium
                          asterisk
                          style={{
                            alignItems: 'flex-start',
                            position: 'relative',
                          }}
                        >
                          유래 조류 및 거대 균류 명
                        </Typography.Text>
                      }
                    >
                      <Form.Item
                        name={[name, 'originTargetName']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,100}$/,
                            message: '한글 입력 불가, 최대 100자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                <PlusButton readOnly={readOnly} count={fields.length} add={add} />
              </>
            )}
          </Form.List>
        )}
      </>
    </CollapseBox>
  );
};

const ProducingMethod = ({
  activeCollapse,
  onChangeActiveCollapse,
  options,
  getFieldValue,
  readOnly,
  collapsableCategoryIds,
  nmpaBAT,
}: {
  readOnly: boolean;
  activeCollapse: {
    [key: string]: boolean;
  };
  onChangeActiveCollapse: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  options: { label: string; value: number }[] | undefined;
  getFieldValue: (name: NamePath) => any;
  collapsableCategoryIds: { [key: string]: number };
  nmpaBAT: NMPABATParam[];
}) => {
  return (
    <CollapseBox
      activeCollapse={activeCollapse}
      collapseCode="NMPA_RAW_MATERIAL_PRODUCTION_METHOD"
      onChangeActiveCollapse={onChangeActiveCollapse}
      title="원료의 생산 방법"
    >
      <>
        {getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD).includes(
          collapsableCategoryIds['NMPA_RAW_MATERIAL_PRODUCTION_METHOD007'],
        ) && (
          <Form.List
            name={collapsableCategoryIds['NMPA_RAW_MATERIAL_PRODUCTION_METHOD007']}
            initialValue={[{}]}
          >
            {(fields, { remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}

                        <Typography.Text medium gutter={{ top: 16 }}>
                          유전공학 기술을 이용한 발효 또는 세포/조직 배양
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_RAW_MATERIAL_PRODUCTION_METHOD007"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text
                          color="SLATE_GRAY70"
                          medium
                          asterisk
                          style={{
                            alignItems: 'flex-start',
                            position: 'relative',
                          }}
                        >
                          발효 또는 세포/조직 학명
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'scientificName']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,100}$/,
                            message: '한글 입력 불가, 최대 100자',
                          },
                        ]}
                      >
                        <Input
                          placeholder="발효 또는 배양하는 세포/조직의 학명을 라틴어로 입력"
                          disabled={readOnly}
                        />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          유기체 명
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'organismName']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,1000}$/,
                            message: '한글 입력 불가, 최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          유전 정보
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'heredity']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input placeholder="원본 유전 정보" disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          유전자 변형 정보
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'geneticModification']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          운반체 정보
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'carrier']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input placeholder="유전 물질 운반체의 정보" disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text
                          color="SLATE_GRAY70"
                          medium
                          asterisk
                          style={{
                            alignItems: 'flex-start',
                            position: 'relative',
                          }}
                        >
                          박테리아 정보 또는 세포/조직 출처
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Form.Item
                        name={[name, 'source']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
              </>
            )}
          </Form.List>
        )}
        {getFieldValue(NMPABATAttribute.NMPA_RAW_MATERIAL_PRODUCTION_METHOD).includes(
          collapsableCategoryIds['NMPA_RAW_MATERIAL_PRODUCTION_METHOD008'],
        ) && (
          <Form.List
            name={collapsableCategoryIds['NMPA_RAW_MATERIAL_PRODUCTION_METHOD008']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          기타 일반발효 또는 세포/조직배양
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_RAW_MATERIAL_PRODUCTION_METHOD008"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text
                          color="SLATE_GRAY70"
                          medium
                          asterisk
                          style={{
                            alignItems: 'flex-start',
                            position: 'relative',
                          }}
                        >
                          박테리아 정보 또는 세포/조직 출처
                        </Typography.Text>
                      }
                    >
                      <Form.Item
                        name={[name, 'source']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,100}$/,
                            message: '최대 100자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
              </>
            )}
          </Form.List>
        )}
      </>
    </CollapseBox>
  );
};

const EtcAttribute = ({
  activeCollapse,
  onChangeActiveCollapse,
  options,
  getFieldValue,
  readOnly,
  collapsableCategoryIds,
  nmpaBAT,
}: {
  readOnly: boolean;
  activeCollapse: {
    [key: string]: boolean;
  };
  onChangeActiveCollapse: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  options: { label: string; value: number }[] | undefined;
  getFieldValue: (name: NamePath) => any;
  collapsableCategoryIds: { [key: string]: number };
  nmpaBAT: NMPABATParam[];
}) => {
  const inputRef = useRef<InputRef | null>(null);

  return (
    <CollapseBox
      activeCollapse={activeCollapse}
      collapseCode="NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE"
      onChangeActiveCollapse={onChangeActiveCollapse}
      title="기타 다른 원료 특징"
    >
      <>
        {getFieldValue(NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE).includes(
          collapsableCategoryIds['NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE003'],
        ) && (
          <Form.List
            name={collapsableCategoryIds['NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE003']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          펩타이드
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE003"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          지표
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex gap={8}>
                        <Form.Item noStyle shouldUpdate={true}>
                          {({ getFieldValue }) => {
                            return (
                              <>
                                <Form.Item
                                  name={[name, 'indicator']}
                                  style={{ flex: 1 }}
                                  rules={[requireRule]}
                                >
                                  <Select
                                    placeholder="물질을 나타내는 지표"
                                    showSearch
                                    filterOption={(keyword, option) =>
                                      (option?.label as string)
                                        .toLowerCase()
                                        .includes(keyword.toLowerCase())
                                    }
                                    disabled={readOnly}
                                    options={[
                                      {
                                        label: '아미노산 수',
                                        value: '아미노산 수',
                                      },
                                      {
                                        label: '아미노산 종류',
                                        value: '아미노산 종류',
                                      },
                                      {
                                        label: '시퀀스/구성/구조적 특성화',
                                        value: '시퀀스/구성/구조적 특성화',
                                      },
                                      {
                                        label: '평균분자량',
                                        value: '평균분자량',
                                      },
                                      {
                                        label:
                                          '아미노산 서열(올리고펩타이드 원료는 명확한 아미노산 서열을 가져야 함)',
                                        value:
                                          '아미노산 서열(올리고펩타이드 원료는 명확한 아미노산 서열을 가져야 함)',
                                      },
                                      {
                                        label: etcText,
                                        value: etcText,
                                      },
                                    ]}
                                    onSelect={(value) => {
                                      if (value === etcText) {
                                        setTimeout(() => inputRef?.current?.focus());
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={
                                    getFieldValue([
                                      collapsableCategoryIds[
                                        'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE003'
                                      ],
                                      name,
                                      'indicator',
                                    ]) !== etcText
                                      ? [name, 'indicator']
                                      : [name, 'indicatorDirectInput']
                                  }
                                  style={{ flex: 1 }}
                                  rules={[
                                    requireRule,
                                    {
                                      pattern: /^.{1,100}$/,
                                      message: '최대 100자',
                                    },
                                  ]}
                                >
                                  <Input
                                    ref={inputRef}
                                    disabled={
                                      readOnly ||
                                      getFieldValue([
                                        collapsableCategoryIds[
                                          'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE003'
                                        ],
                                        name,
                                        'indicator',
                                      ]) !== etcText
                                    }
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          수치
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'figure']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,50}$/,
                            message: '한글 입력 불가, 최대 50자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          단위
                        </Typography.Text>
                      }
                      span={1}
                      contentStyle={{ width: 244 }}
                    >
                      <Form.Item
                        name={[name, 'unit']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,10}$/,
                            message: '최대 10자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          테스트 방법
                        </Typography.Text>
                      }
                    >
                      <Form.Item
                        name={[name, 'testWay']}
                        rules={[
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                <PlusButton readOnly={readOnly} count={fields.length} add={add} />
              </>
            )}
          </Form.List>
        )}
        {getFieldValue(NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE).includes(
          collapsableCategoryIds['NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE004'],
        ) && (
          <Form.List
            name={collapsableCategoryIds['NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE004']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          단백질
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE004"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          지표
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex gap={8}>
                        <Form.Item noStyle shouldUpdate={true}>
                          {({ getFieldValue }) => {
                            return (
                              <>
                                <Form.Item
                                  name={[name, 'indicator']}
                                  style={{ flex: 1 }}
                                  rules={[requireRule]}
                                >
                                  <Select
                                    placeholder="물질을 나타내는 지표"
                                    showSearch
                                    filterOption={(keyword, option) =>
                                      (option?.label as string)
                                        .toLowerCase()
                                        .includes(keyword.toLowerCase())
                                    }
                                    disabled={readOnly}
                                    options={[
                                      {
                                        label: '아미노산 수',
                                        value: '아미노산 수',
                                      },
                                      {
                                        label: '아미노산 종류',
                                        value: '아미노산 종류',
                                      },
                                      {
                                        label: '시퀀스/구성/구조적 특성화',
                                        value: '시퀀스/구성/구조적 특성화',
                                      },
                                      {
                                        label: '평균분자량',
                                        value: '평균분자량',
                                      },
                                      {
                                        label: etcText,
                                        value: etcText,
                                      },
                                    ]}
                                    onSelect={(value) => {
                                      if (value === etcText) {
                                        setTimeout(() => inputRef?.current?.focus());
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={
                                    getFieldValue([
                                      collapsableCategoryIds[
                                        'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE004'
                                      ],
                                      name,
                                      'indicator',
                                    ]) !== etcText
                                      ? [name, 'indicator']
                                      : [name, 'indicatorDirectInput']
                                  }
                                  style={{ flex: 1 }}
                                  rules={[
                                    requireRule,
                                    {
                                      pattern: /^.{1,100}$/,
                                      message: '최대 100자',
                                    },
                                  ]}
                                >
                                  <Input
                                    ref={inputRef}
                                    disabled={
                                      readOnly ||
                                      getFieldValue([
                                        collapsableCategoryIds[
                                          'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE004'
                                        ],
                                        name,
                                        'indicator',
                                      ]) !== etcText
                                    }
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          수치
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'figure']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,50}$/,
                            message: '한글 입력 불가, 최대 50자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          단위
                        </Typography.Text>
                      }
                      span={1}
                      contentStyle={{ width: 244 }}
                    >
                      <Form.Item
                        name={[name, 'unit']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,10}$/,
                            message: '최대 10자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          테스트 방법
                        </Typography.Text>
                      }
                    >
                      <Form.Item
                        name={[name, 'testWay']}
                        rules={[
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                <PlusButton readOnly={readOnly} count={fields.length} add={add} />
              </>
            )}
          </Form.List>
        )}
        {getFieldValue(NMPABATAttribute.NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE).includes(
          collapsableCategoryIds['NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE005'],
        ) && (
          <Form.List
            name={collapsableCategoryIds['NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE005']}
            initialValue={[{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key }, index) => (
                  <Descriptions
                    key={key}
                    bordered
                    title={
                      <div style={{ position: 'relative' }}>
                        {!readOnly && (
                          <MinusIcon
                            style={{
                              position: 'absolute',
                              left: -40,
                              top: 0,
                            }}
                            onClick={() => {
                              if (fields.length === 1) {
                                message.warning('최소 1개 이상은 입력해야 합니다.');

                                return;
                              }
                              remove(index);
                            }}
                          />
                        )}
                        <Typography.Text medium gutter={{ top: 16 }}>
                          나노 원료
                        </Typography.Text>
                      </div>
                    }
                  >
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          성분
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <InciSelectForm
                        nmpaBAT={nmpaBAT}
                        name={name}
                        disabled={readOnly}
                        options={options}
                        collapsableCategoryIds={collapsableCategoryIds}
                        code="NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE005"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          지표
                        </Typography.Text>
                      }
                      span={3}
                    >
                      <Flex gap={8}>
                        <Form.Item noStyle shouldUpdate={true}>
                          {({ getFieldValue }) => {
                            return (
                              <>
                                <Form.Item
                                  name={[name, 'indicator']}
                                  style={{ flex: 1 }}
                                  rules={[requireRule]}
                                >
                                  <Select
                                    placeholder="물질을 나타내는 지표"
                                    showSearch
                                    filterOption={(keyword, option) =>
                                      (option?.label as string)
                                        .toLowerCase()
                                        .includes(keyword.toLowerCase())
                                    }
                                    disabled={readOnly}
                                    options={[
                                      {
                                        label: '입자 크기 범위',
                                        value: '입자 크기 범위',
                                      },
                                      {
                                        label: '평균 입자 크기 (수 크기 분포)',
                                        value: '평균 입자 크기 (수 크기 분포)',
                                      },
                                      {
                                        label: '평균 입자 크기 (질량 크기 분표)',
                                        value: '평균 입자 크기 (질량 크기 분표)',
                                      },
                                      {
                                        label: etcText,
                                        value: etcText,
                                      },
                                    ]}
                                    onSelect={(value) => {
                                      if (value === etcText) {
                                        setTimeout(() => inputRef?.current?.focus());
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={
                                    getFieldValue([
                                      collapsableCategoryIds[
                                        'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE005'
                                      ],
                                      name,
                                      'indicator',
                                    ]) !== etcText
                                      ? [name, 'indicator']
                                      : [name, 'indicatorDirectInput']
                                  }
                                  style={{ flex: 1 }}
                                  rules={[
                                    requireRule,
                                    {
                                      pattern: /^.{1,100}$/,
                                      message: '최대 100자',
                                    },
                                  ]}
                                >
                                  <Input
                                    ref={inputRef}
                                    disabled={
                                      readOnly ||
                                      getFieldValue([
                                        collapsableCategoryIds[
                                          'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE005'
                                        ],
                                        name,
                                        'indicator',
                                      ]) !== etcText
                                    }
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          수치
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'figure']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,50}$/,
                            message: '한글 입력 불가, 최대 50자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium asterisk>
                          단위
                        </Typography.Text>
                      }
                      span={1}
                      contentStyle={{ width: 244 }}
                    >
                      <Form.Item
                        name={[name, 'unit']}
                        rules={[
                          requireRule,
                          {
                            pattern: /^.{1,10}$/,
                            message: '최대 10자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          분자식
                        </Typography.Text>
                      }
                      span={2}
                    >
                      <Form.Item
                        name={[name, 'molecularFormula']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,100}$/,
                            message: '한글 입력 불가, 최대 100자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          CAS No.
                        </Typography.Text>
                      }
                      span={1}
                    >
                      <Form.Item
                        name={[name, 'casNo']}
                        rules={[
                          {
                            pattern: /^[^ㄱ-ㅎ가-힣ㅏ-ㅣ]{1,13}$/,
                            message: '한글 입력 불가, 최대 13자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography.Text color="SLATE_GRAY70" medium>
                          테스트 방법
                        </Typography.Text>
                      }
                    >
                      <Form.Item
                        name={[name, 'testWay']}
                        rules={[
                          {
                            pattern: /^.{1,1000}$/,
                            message: '최대 1000자',
                          },
                        ]}
                      >
                        <Input disabled={readOnly} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                ))}
                <PlusButton readOnly={readOnly} count={fields.length} add={add} />
              </>
            )}
          </Form.List>
        )}
      </>
    </CollapseBox>
  );
};

const CollapseBox = ({
  children,
  onChangeActiveCollapse,
  collapseCode,
  activeCollapse,
  title,
}: {
  children: ReactElement | boolean;
  onChangeActiveCollapse: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  collapseCode: string;
  activeCollapse: {
    [key: string]: boolean;
  };
  title: string;
}) => {
  return (
    <>
      <CollapseBoxContainer
        justify="space-between"
        onClick={() => {
          onChangeActiveCollapse({
            ...activeCollapse,
            [collapseCode]: !activeCollapse[collapseCode],
          });
        }}
      >
        <Flex align="center" gap={8}>
          <CollapseIcon />
          <Typography.Text color="SLATE_GRAY70" medium>
            {title}
          </Typography.Text>
        </Flex>
        <Icon name={activeCollapse[collapseCode] ? 'up' : 'down'} />
      </CollapseBoxContainer>
      <CollapseChild active={activeCollapse[collapseCode]}>{children}</CollapseChild>
    </>
  );
};

const InciSelectForm = ({
  disabled,
  options,
  name,
  nmpaBAT,
  collapsableCategoryIds,
  code,
}: {
  disabled: boolean;
  options: { label: string; value: number }[] | undefined;
  name: number;
  nmpaBAT: NMPABATParam[];
  collapsableCategoryIds: {
    [key: string]: number;
  };
  code: string;
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (nmpaBAT.length === 0 || !options) return;

    const attributeAdditional = nmpaBAT.find(
      ({ materialCategoryId }) => materialCategoryId === collapsableCategoryIds[code],
    )?.materialNmpaBasicAttributeAdditionals[name];

    if (attributeAdditional?.isAllMaterial) {
      setFilteredOptions(
        options?.map((item) => (item.value === 0 ? item : { ...item, disabled: true })),
      );
    } else if (attributeAdditional && attributeAdditional.materialNmpaBasicInfoIncis.length > 0) {
      setFilteredOptions(
        options?.map((item) => (item.value !== 0 ? item : { ...item, disabled: true })),
      );
    }
  }, [nmpaBAT, options]);

  return (
    <Form.Item name={[name, 'materialNmpaBasicInfoInciIds']} rules={[requireRule]}>
      <Select
        mode="multiple"
        tagRender={tagRender}
        disabled={disabled}
        placeholder="원료에 포함된 성분을 선택해 주세요."
        style={{ width: '100%' }}
        options={filteredOptions}
        showSearch
        filterOption={filterOptionForStringLabel}
        onChange={(values: number[]) => {
          if (values.includes(0)) {
            setFilteredOptions(
              options?.map((item) => (item.value === 0 ? item : { ...item, disabled: true })),
            );
          } else if (values.length > 0) {
            setFilteredOptions(
              options?.map((item) => (item.value !== 0 ? item : { ...item, disabled: true })),
            );
          } else {
            setFilteredOptions(options);
          }
        }}
      />
    </Form.Item>
  );
};

const PlusButton = ({
  readOnly,
  add,
  count,
}: {
  readOnly: boolean;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  count: number;
}) => {
  return (
    <>
      {!readOnly && (
        <Button
          type="dashed"
          icon={<Icon name="plus" size={14} color="PRIMARY50" />}
          block
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 16,
            maxWidth: 520,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          onClick={() => {
            if (count >= 50) {
              message.warning('최대 50개까지 입력 가능합니다.');
              return;
            }
            add({});
          }}
        >
          추가
        </Button>
      )}
    </>
  );
};

export default NMPABATItem;

import { useEffect, useMemo, useState, ComponentProps } from 'react';
import { Button, Col, Form, FormInstance, Input, message, Modal, Row } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

import { DocumentCode } from 'types/product';
import { generateFormRules } from 'lib/validate';
import { focusToInvalidatedField, normalizeBlank } from 'lib/form';
import { urlToFile } from 'lib/file';
import { messages } from 'lib/consts';
import { useProductDocStatus, useProductInformation } from 'service/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import UpdateLog from 'components/product/UpdateLog';
import { Tip, Typography } from 'components/system';
import FullLoading from 'components/FullLoading';
import palette from 'lib/styles/palette';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import Icon from 'components/ui/Icon/Icon';
import { useNavigate } from 'react-router-dom';

export interface ProductInformationChinaForm {
  brandNameEn?: string;
  brandNameEnMeaning?: string;
  brandNameCn?: string;
  brandNameCnMeaning?: string;
  productNameEn?: string;
  productNameCn?: string;
}

interface ProductTrademarkCertificateChina {
  productTrademarkCertificateChinaId: number;
  file: File;
}

const Container = styled.div`
  width: 100%;

  .ant-btn[disabled],
  .ant-btn[disabled]:hover {
    border: 1px solid ${palette.GRAY40};
    background-color: ${palette.ETC_WHITE};
  }
`;

const ProductInformationChinaUIContainer = styled.div`
  width: 520px;
  max-width: 520px;
  margin: 16px auto 0;
`;

const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    height: 56px;
    font-size: 16px;
  }
`;

export const ProductInformationChina = () => {
  const navigate = useNavigate();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [productTrademarkCertificates, setProductTrademarkCertificates] = useState<
    ProductTrademarkCertificateChina[]
  >([]);
  const currentProduct = useCurrentProduct();
  const chinaProductDetail = currentProduct.productDetails.find(
    (productDetail) => productDetail.country?.countryCode === 'CN',
  );
  const { sendEventToGA } = useGA();
  const countryId = useCountryId();
  const productId = currentProduct.productId;
  const productDetailId = chinaProductDetail?.productDetailId;
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PIF,
  });
  const [form] = Form.useForm<ProductInformationChinaForm>();
  const [initialFormData, setInitialFormData] = useState<ProductInformationChinaForm | undefined>(
    undefined,
  );
  const {
    productInformation,
    isGetProductInformationLoading,
    updateChinaProductInformation,
    isUpdateChinaProductInformationLoading,
  } = useProductInformation({ productId, productDetailId });
  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const initialFiles = useMemo(() => {
    return productTrademarkCertificates.map((certificate) => certificate.file);
  }, [productTrademarkCertificates]);
  const cta = useMemo(() => {
    if (docStatus?.status === 'MOD') {
      return '보완 완료';
    }

    return '등록';
  }, [docStatus]);

  const handleSubmit: ComponentProps<typeof ProductInformationChinaUI>['onSubmit'] = (
    values,
    files,
  ) => {
    if (!productDetailId) return;

    const certificateIds: number[] = [];
    const newFiles: { file: File }[] = [];

    for (const file of files) {
      const certificate = productTrademarkCertificates.find((c) => c.file === file);

      // 전에 업로드 되었던 파일인 경우
      if (certificate) {
        certificateIds.push(certificate.productTrademarkCertificateChinaId);
      } else {
        newFiles.push({ file });
      }
    }

    const formData = values;
    if (
      isEqual(formData, initialFormData) &&
      certificateIds.length === productTrademarkCertificates.length &&
      newFiles.length === 0
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    const newProductTrademarkCertificateChinas: {
      productTrademarkCertificateChinaId?: number;
      file?: File;
    }[] = certificateIds.map((id) => ({
      productTrademarkCertificateChinaId: id,
    }));

    newProductTrademarkCertificateChinas.push(...newFiles);

    const params = {
      productId,
      productDetail: {
        countryBrandNameMeaning: formData.brandNameCnMeaning || '',
        brandNameEnMeaning: formData.brandNameEnMeaning || '',
        productDetailId,
        countryProductName: formData.productNameCn || '',
        countryBrandName: formData.brandNameCn || '',
      },
      productTrademarkCertificateChinas: newProductTrademarkCertificateChinas,
    };

    updateChinaProductInformation(params, {
      onSuccess: () => {
        sendEventToGA({
          documentName: '제품 정보',
          actionType: cta === '보완 완료' ? EGAActionType.MODIFY : EGAActionType.REGISTER,
        });
        message.success(`${cta}되었습니다.`);
        navigate(-1);
      },
    });
  };

  useEffect(() => {
    if (chinaProductDetail === undefined) navigate(-1);
  }, [chinaProductDetail]);

  useEffect(() => {
    if (!productInformation) return;

    (async () => {
      const newFormData: ProductInformationChinaForm = {
        brandNameEn: productInformation.brandNameEn,
        brandNameEnMeaning: productInformation.brandNameEnMeaning,
        productNameEn: productInformation.productNameEn,
        brandNameCn: productInformation.countryBrandName,
        brandNameCnMeaning: productInformation.countryBrandNameMeaning,
        productNameCn: productInformation.countryProductName,
      };
      const newProductTrademarkCertificates: ProductTrademarkCertificateChina[] = [];

      for (const certificate of productInformation.productTrademarkCertificateChinas) {
        newProductTrademarkCertificates.push({
          productTrademarkCertificateChinaId: certificate.productTrademarkCertificateChinaId,
          file: await urlToFile(certificate.uploadFileUrl, certificate.filename),
        });
      }

      form.setFieldsValue(newFormData);
      setInitialFormData(newFormData);
      setProductTrademarkCertificates(newProductTrademarkCertificates);
    })();
  }, [productInformation]);

  const loading = isGetProductInformationLoading;
  const submitting = isUpdateChinaProductInformationLoading;

  return (
    <Container>
      {loading && <FullLoading />}
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.PIF}
          />
        </Col>
        <Col>
          <UpdateLog productId={productId} countryId={countryId} documentCode={DocumentCode.PIF} />
        </Col>
      </Row>
      <ProductInformationChinaUI
        form={form}
        cta={cta}
        readOnly={readOnlyMode}
        submitting={submitting}
        initialFiles={initialFiles}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export const ProductInformationChinaUI = ({
  form,
  cta,
  readOnly = false,
  initialFiles,
  onSubmit,
  submitting,
}: {
  form: FormInstance<ProductInformationChinaForm>;
  cta?: string;
  readOnly?: boolean;
  initialFiles?: File[];
  onSubmit?: (values: ProductInformationChinaForm, files: File[]) => void;
  submitting?: boolean;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileUploadModalVisible, setFileUploadModalVisible] = useState(false);

  const handleSubmit = (values: ProductInformationChinaForm) => {
    onSubmit?.(values, files);
  };

  const handleFileUpload = (file: File) => {
    if (files.find((item) => item.name === file.name)) {
      message.warning(messages.DUPLICATE_FILE_NAME);
      return false;
    }

    setFiles((draft) => draft.concat(file));
    return true;
  };

  const handleFileDelete = (file: File) => {
    setFiles((draft) => draft.filter((f) => f !== file));
    return false;
  };

  useEffect(() => {
    if (initialFiles === undefined) return;

    setFiles(initialFiles);
  }, [initialFiles]);

  const buttonDisabledMode = readOnly && files.length === 0;

  return (
    <ProductInformationChinaUIContainer>
      <Modal
        title="중국 상표등록증 업로드"
        visible={fileUploadModalVisible}
        onCancel={() => setFileUploadModalVisible(false)}
        footer={null}
        destroyOnClose
        maskClosable={false}
        width={864}
      >
        <FileUploadContainer
          accept=".pdf"
          max={20}
          files={files}
          onUpload={handleFileUpload}
          onDelete={handleFileDelete}
          readOnly={readOnly}
        />
        <ButtonContainer>
          <Button type="primary" onClick={() => setFileUploadModalVisible(false)}>
            확인
          </Button>
        </ButtonContainer>
      </Modal>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={form ? focusToInvalidatedField({ form, offsetY: -300 }) : undefined}
      >
        <Form.Item required label="브랜드명 (영문)" name="brandNameEn">
          <Input disabled />
        </Form.Item>
        <Form.Item
          required
          label="브랜드명 (영문) 의미"
          name="brandNameEnMeaning"
          rules={generateFormRules({ required: true, maxLength: 100 })}
        >
          <Input disabled={readOnly} placeholder="영문 브랜드명의 의미를 서술형으로 기재" />
        </Form.Item>
        <Form.Item
          label="브랜드명 (중문)"
          name="brandNameCn"
          rules={generateFormRules({ maxLength: 50 })}
        >
          <Input disabled={readOnly} placeholder={readOnly ? '' : '중문'} />
        </Form.Item>
        <Form.Item
          label="브랜드명 (중문) 의미"
          name="brandNameCnMeaning"
          rules={generateFormRules({ maxLength: 100 })}
        >
          <Input
            disabled={readOnly}
            placeholder={readOnly ? '' : '중문 브랜드명의 의미를 서술형으로 기재'}
          />
        </Form.Item>
        <Form.Item required label="제품명 (영문)" name="productNameEn">
          <Input disabled />
        </Form.Item>
        <Form.Item
          required
          label={
            <>
              제품명 (중문)
              <Tip
                style={{
                  marginLeft: 4,
                  zIndex: 1,
                }}
                trigger="click"
              >
                <Typography.Text type="SMALL">
                  상표등록증이 없는 경우, 중문 제품명에
                  <br />
                  알파벳, 한어병음, 숫자, 기호 사용 불가
                </Typography.Text>
              </Tip>
            </>
          }
          name="productNameCn"
          normalize={normalizeBlank}
          rules={generateFormRules({ required: true, maxLength: 50 })}
        >
          <Input
            disabled={readOnly}
            placeholder="위생허가에 등록할 중문 제품명 입력"
            onBlur={(e) => {
              const value = e.target.value;

              if (value.endsWith(' ')) {
                form.setFieldsValue({
                  productNameCn: value.trimEnd(),
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label={
            <>
              중국 상표등록증 업로드
              <Tip
                style={{
                  marginLeft: 4,
                  zIndex: 1,
                }}
                trigger="click"
              >
                <Typography.Text type="SMALL">
                  상표등록증이 있는 경우, 보유하신 상표등록증을 모두 업로드해 주세요.
                </Typography.Text>
              </Tip>
            </>
          }
        >
          <Button
            onClick={() => setFileUploadModalVisible(true)}
            style={{
              width: 120,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            disabled={buttonDisabledMode}
          >
            <Icon name="upload" color={buttonDisabledMode ? 'GRAY60' : 'PRIMARY50'} size={14} />
            {files.length > 0 ? '파일 보기' : '파일 선택'}
          </Button>
        </Form.Item>
      </Form>
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnly}>
          {cta === '보완 완료' && (
            <CorrectRequestWithoutChangeButton documentCode={DocumentCode.PIF} />
          )}
          <Button type="primary" onClick={form?.submit} loading={submitting}>
            {cta}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </ProductInformationChinaUIContainer>
  );
};

export default ProductInformationChinaUI;

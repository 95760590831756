import { useEffect } from 'react';
import { Spin } from 'antd';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Flex } from 'components/ui';
import path from 'lib/path';
import { useInfiniteRawMaterials } from 'service/material/rawMaterialSearch';
import { columns, StyledTable } from './common';
import { Typography } from 'components/system';
import { RawMaterial, RawMaterialSearchParams } from 'types/material/rawMaterialSearch';
import Icon from 'components/ui/Icon/Icon';

const TableContainer = styled.div`
  margin-top: 24px;
`;

const FetchArea = styled(Flex)`
  height: 60px;
`;

const RawMaterialSearchTable = (props: RawMaterialSearchParams) => {
  const navigate = useNavigate();
  const { rawMaterials, fetchNextPage, hasNextPage, rawMaterialLoading } =
    useInfiniteRawMaterials(props);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <TableContainer>
      <Flex gap={8} style={{ marginBottom: 8 }} align="center">
        <Icon name="experiment" size={20} />
        <Typography.Text medium color="SLATE_GRAY70">
          일반 원료
        </Typography.Text>
      </Flex>
      <StyledTable
        scroll={{
          y: '100%',
          x: '100%',
        }}
        loading={rawMaterialLoading}
        columns={columns as any}
        dataSource={rawMaterials}
        rowKey="materialId"
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(
                `${path.material.rawMaterial.detailView}/${(record as RawMaterial).materialId}`,
              );
            },
          };
        }}
      />
      {hasNextPage && (
        <FetchArea ref={ref} justify="center" align="center">
          <Spin spinning />
        </FetchArea>
      )}
    </TableContainer>
  );
};

export default RawMaterialSearchTable;

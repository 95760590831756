import { useState } from 'react';
import { useMutation } from 'react-query';
import { ArtworkItem } from 'types/brand/product/artworkScreening';

import { TranslateLanguageCode } from 'components/brand/artworkScreening/step4/ReportModalContent';
import client from 'lib/api/client';
import { apiUrls, inciApiUrls, useBasicMutation, useBasicQuery } from 'service/core';

import {
  FormulaArtworkScreeningItem,
  FormulaArtworkScreeningJson,
  ArtworkScreeningResult,
  InquiryErrorType,
  ScreeningStatus,
} from 'types/brand/artworkScreening/artworkScreening';
import { APIResponse } from 'types/common';

export const useFormulaArtworkScreening = ({
  formulaScreeningId,
}: {
  formulaScreeningId?: number;
}) => {
  const [formulaScreeningResult, setFormulaScreeningResult] =
    useState<FormulaArtworkScreeningItem | null>(null);

  // Json으로 전성분 스크리닝 (로그인 필요) (FSC007)
  const {
    mutate: uploadFormulaArtworkScreeningJson,
    isLoading: uploadFormulaArtworkScreeningJsonLoading,
  } = useMutation(
    (params: FormulaArtworkScreeningJson) =>
      client.post<APIResponse<FormulaArtworkScreeningItem>>(
        `/formula-screenings/ingredients${
          formulaScreeningId ? `?formulaScreeningId=${formulaScreeningId}` : ''
        }`,
        params,
      ),
    {
      onSuccess: (res) => {
        setFormulaScreeningResult(res.data.result);
      },
      onError: (err) => {
        setFormulaScreeningResult(null);
      },
    },
  );

  //Excel로 전성분 스크리닝 (로그인 필요) (FSC008)
  const {
    mutate: uploadFormulaArtworkScreeningFile,
    isLoading: uploadFormulaArtworkScreeningFileLoading,
  } = useMutation(
    (params: { uploadFile: File }) =>
      client.post<APIResponse<FormulaArtworkScreeningItem>>(
        `/formula-screenings/ingredients${
          formulaScreeningId ? `?formulaScreeningId=${formulaScreeningId}` : ''
        }`,
        params,
      ),
    {
      onSuccess: (res) => {
        const result = res.data.result;

        setFormulaScreeningResult({
          ...res.data.result,
          isRowError: result.isRowError,
          list: result.list.filter(
            ({ ingredientName, wt, casNo }) => ingredientName || wt || casNo,
          ),
        });
      },
      onError: () => {
        setFormulaScreeningResult(null);
      },
    },
  );

  // 전성분 스크리닝 분석 결과 스크리닝 ID를 통한 조회 (FSC004)
  const { data: formulaScreeningDetail, isLoading: formulaScreeningDetailLoading } =
    useBasicQuery<FormulaArtworkScreeningItem>({
      apiUrl: apiUrls.formulaArtworkScreeningDetail,
      ...(formulaScreeningId && { urlParams: { formulaScreeningId } }),
      option: {
        enabled: !!formulaScreeningId,
        onSuccess: (res) => {
          setFormulaScreeningResult(res);
        },
      },
    });

  // 전성분 스크리닝 정보 조회 (로그인 필요) (FSC003)
  const { data: formulaScreeningStatus, isLoading: getFormulaScreeningStatusLoading } =
    useBasicQuery<{
      formulaScreeningId: number;
      status: ScreeningStatus;
    }>({
      apiUrl: apiUrls.formulaArtworkScreeningStatus,
      ...(formulaScreeningId && { urlParams: { formulaScreeningId } }),
      option: {
        enabled: !!formulaScreeningId,
      },
    });
  // 전성분 스크리닝 아트웍 정보 조회 (로그인 필요) (FSC006)
  const { mutate: formulaScreeningArtworkFile } = useBasicMutation<
    { formulaScreeningId: number },
    ArtworkItem
  >({
    method: 'get',
    apiUrl: apiUrls.formulaScreeningArtworkFile,
    paramNamesForUrl: ['formulaScreeningId'],
  });

  return {
    uploadFormulaArtworkScreeningJson,
    uploadFormulaArtworkScreeningFile,
    formulaScreeningResult,
    setFormulaScreeningResult,
    formulaArtworkScreeningLoading:
      uploadFormulaArtworkScreeningFileLoading || uploadFormulaArtworkScreeningJsonLoading,
    formulaScreeningDetail,
    formulaScreeningDetailLoading,
    formulaScreeningStatus,
    getFormulaScreeningStatusLoading,
    formulaScreeningArtworkFile,
  };
};

// 전성분 스크리닝 요청 (로그인 필요) (FSC004)
export const useFormulaArtworkScreeningRequest = () => {
  const { mutate: requestFormulaScreening, isLoading: requestFormulaScreeningLoading } =
    useBasicMutation<{ artwork?: File; formulaScreeningId: number }>({
      apiUrl: apiUrls.formulaArtworkScreeningRequest,
      paramNamesForUrl: ['formulaScreeningId'],
      useFormData: true,
    });

  const { mutate: cancelFormulaScreening, isLoading: cancelFormulaScreeningLoading } =
    useBasicMutation<{ formulaScreeningId: number }>({
      apiUrl: apiUrls.formulaArtworkScreeningRequest,
      paramNamesForUrl: ['formulaScreeningId'],
      method: 'DELETE',
    });

  // 전성분_스크리닝_분석_요청_fsc013
  const { mutate: requestFormulaScreeningAgain, isLoading: requestFormulaScreeningAgainLoading } =
    useMutation(
      ({
        formulaScreeningArtworkImageId,
        formulaScreeningId,
      }: {
        formulaScreeningArtworkImageId: number;
        formulaScreeningId: number;
      }) =>
        client.put(
          `/v1/formula-screenings/${formulaScreeningId}/analyses?formulaScreeningArtworkImageId=${formulaScreeningArtworkImageId}`,
        ),
    );

  return {
    requestFormulaScreening,
    requestFormulaScreeningLoading,
    cancelFormulaScreening,
    cancelFormulaScreeningLoading,
    requestFormulaScreeningAgain,
    requestFormulaScreeningAgainLoading,
  };
};

//전성분 스크리닝 분석 결과 조회 (FSC008)
export const useFormulaArtworkScreeningAnalyses = () => {
  const {
    mutate: formulaArtworkScreeningAnalyses,
    isLoading: formulaArtworkScreeningAnalysesLoading,
  } = useBasicMutation<{
    formulaScreeningId: number;
  }>({
    method: 'get',
    apiUrl: apiUrls.formulaArtworkScreeningAnalyses,
    paramNamesForUrl: ['formulaScreeningId'],
  });

  return {
    formulaArtworkScreeningAnalyses,
    formulaArtworkScreeningAnalysesLoading,
  };
};

export const useFormulaArtworkScreeingResult = ({
  formulaScreeningId,
}: {
  formulaScreeningId: number;
}) => {
  // 전성분 스크리닝 분석 결과 조회 (FSC009)
  const { data: artworkScreeningResult, isLoading: getArtworkScreeningResultLoading } =
    useBasicQuery<ArtworkScreeningResult>({
      apiUrl: apiUrls.formulaArtworkScreeningAnalyses,
      urlParams: {
        formulaScreeningId,
      },
      option: {
        cacheTime: 0,
        select: (res) => {
          const countryOrder: {
            [key: string]: number;
          } = {
            EU: 1,
            GB: 2,
            KR: 3,
            CN: 4,
            JP: 5,
            US: 6,
            ETC: 7,
          };

          const artworkScreeningResult = res.data.result;
          artworkScreeningResult.availableCountryCodes =
            artworkScreeningResult.availableCountryCodes.sort(
              (a, b) => countryOrder[a] - countryOrder[b],
            );
          return artworkScreeningResult;
        },
      },
    });

  // 검토 결과 수정된 성분 저장 (FSC010)
  const { mutate: reportIngredients, isLoading: reportIngredientsLoading } = useMutation(
    (
      params: {
        formulaScreeningAnalyzeItemId: number;
        ingredientName: string;
        additionalIngredientName?: string;
        isUpdate: boolean;
      }[],
    ) =>
      client.put(
        `/v1/formula-screenings/${formulaScreeningId}/analyses/update-ingredients`,
        params,
      ),
  );

  return {
    reportIngredients,
    reportIngredientsLoading,
    artworkScreeningResult,
    getArtworkScreeningResultLoading,
  };
};

export const useFormulaScreeningArtworkInquiry = () => {
  // 전성분 스크리닝 문의 등록 (로그인 필요) (FSC010)
  const {
    mutate: registerFormulaScreeningArtworkInquiry,
    isLoading: registerFormulaScreeningArtworkInquiryLoading,
  } = useBasicMutation<{
    formulaScreeningId: number;
    formulaScreeningAnalyzeItemId?: number;
    errorType?: InquiryErrorType;
    countryCodes?: string[];
    content: string;
  }>({
    apiUrl: apiUrls.formulaArtworkScreeningInquiry,
    paramNamesForUrl: ['formulaScreeningId'],
  });

  return {
    registerFormulaScreeningArtworkInquiry,
    registerFormulaScreeningArtworkInquiryLoading,
  };
};

export const useFormulaScreeningArtworkReport = ({
  languageCode,
}: {
  languageCode: TranslateLanguageCode;
}) => {
  // 성분 리스트 번역 (SDC08)
  const { mutate: translateIngredients, isLoading: translateIngredientsLoading } = useBasicMutation<
    {
      name: string;
    }[],
    {
      ingredientId: number | null;
      name: string;
      translateName: string | null;
    }[]
  >({
    isInciApi: true,
    apiUrl: `${inciApiUrls.translateIngredients}?languageCode=${languageCode}`,
  });

  return {
    translateIngredients,
    translateIngredientsLoading,
  };
};

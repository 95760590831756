export enum RegisterStatus {
  RM_RDY = 'RM_RDY',
  RM_REG = 'RM_REG',
}

export enum RegisterSearchType {
  ALL = 'ALL',
  MATERIAL_NAME_EN = 'M_NAME',
  ORIGIN_MANUFACTURE_NAME = 'O_M_NAME',
}

export interface MaterialRegisterGet {
  status: RegisterStatus;
  page: number;
  searchType: RegisterSearchType;
  searchKeyword: string;
}

export interface MaterialRegister {
  materialId: number;
  materialNameEn: string;
  originManufacturerName: string;
  uploadExpiredDate?: number;
  finishRegisterDt: string;
  finishRegisterName: string;
  finishRegisterDeptName: string;
  isDiscontinued: boolean;
  updateDt?: string;
}

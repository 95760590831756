import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginByToken } from 'modules/auth';
import Storage from 'lib/storage';
import AppRouter from 'routers/AppRouter';
import FullLoading from 'components/FullLoading';

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const refreshToken = Storage.getItem('auth.refreshToken');
  const loginByTokenLoading = useSelector(({ loading }) => loading['auth/LOGIN_BY_TOKEN']);
  const loginSuccessLoading = useSelector(({ loading }) => loading['auth/LOGIN_SUCCESS']);
  useEffect(() => {
    if (refreshToken) {
      dispatch(loginByToken(refreshToken));
    }
    setIsLoading(false);
  }, []);

  if (isLoading || (refreshToken !== null && loginByTokenLoading) || loginSuccessLoading) {
    return <FullLoading />;
  }

  return <AppRouter />;
};

export default App;

import { useQuery } from 'react-query';
import { useMemo } from 'react';

import client from 'lib/api/client';
import { APIPageableResponse } from 'types/common';
import { ICIDEstimate } from 'types/material/estimate';

export const useMyICIDEstimates = ({ page, size = 10 }: { page: number; size?: number }) => {
  const { data = null } = useQuery(
    ['material/icid-application/estimates', page, size],
    () =>
      client.get<APIPageableResponse<ICIDEstimate[]>>('/v1/material/icid-application/estimates', {
        params: {
          page,
          size,
        },
      }),
    {
      select: (res) => res.data.result,
    },
  );

  const { content: myICIDEstimates = [], totalElements } = data || {};

  return useMemo(() => ({ myICIDEstimates, totalElements }), [myICIDEstimates, totalElements]);
};

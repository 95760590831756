import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import { useMemo, useState } from 'react';

import FooterBox from 'components/FooterBox';
import UpdateLog from 'components/product/UpdateLog';
import { messages } from 'lib/consts';
import { requireRule } from 'lib/validate';
import { toggleProductNameModalVisible } from 'modules/certificate';
import { DocumentCode } from 'types/product';
import { useCurrentProduct, useUpdateProductName } from 'service/brand/product/product';
import { useGetDuplicatedProductName } from 'service/brand/certificate/certificateDetail';
import { normalizeBlank } from 'lib/form';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import { useProductDocStatus } from 'service/product';

const ProductNameModal = ({ certTargetId }: { certTargetId: number }) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const visible = useSelector(({ certificate }: any) => certificate.productNameModalVisible);
  const { estimateTarget, readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      estimateTarget: certificate.certificate.estimateTarget,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const { productId, countryId, netWeight, netWeightUnit } = estimateTarget;
  const { productDetail } = useCurrentProduct();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PN,
  });
  const submitLoading = useSelector(({ loading }: any) => loading['product/UPDATE_PRODUCT_NAME']);

  const ctaName = useMemo(() => {
    if (productDocStatus?.status === 'ONG') return '수정';
    if (productDocStatus?.status === 'MOD') return '보완 완료';
    return '등록';
  }, [productDocStatus]);

  const { getDuplicatedProductName } = useGetDuplicatedProductName(productId);

  const onClose = () => {
    dispatch(toggleProductNameModalVisible());
  };

  const { updateProductName } = useUpdateProductName({
    productDocStatus,
    certTargetId,
  });
  const onSubmit = ({ productNameEn }: any) => {
    if (productDetail.productNameEn === productNameEn) {
      return message.warning(messages.NO_NEED_TO_UPDATE);
    }

    getDuplicatedProductName(
      { productNameEn, netWeight, netWeightUnit },
      {
        onSuccess: ({
          data: {
            result: { isDuplicate },
          },
        }) => {
          if (isDuplicate) {
            message.error(
              '이미 사용중인 제품명입니다. 다른 제품명을 사용하시거나 고객센터로 문의해주세요.',
            );

            return;
          }
          updateProductName({ productId, countryId, productNameEn });
        },
      },
    );
  };

  return (
    <Modal
      visible={visible}
      title="제품명 관리"
      footer={null}
      destroyOnClose={true}
      onCancel={onClose}
    >
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.PN}
            productId={productId}
            countryId={countryId}
          />
        </Col>
        <Col>
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={DocumentCode.BASIC}
            validCodes={['BASIC01-PNE']}
          />
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="제품명"
          name="productNameEn"
          initialValue={productDetail?.productNameEn}
          rules={[requireRule]}
          normalize={normalizeBlank}
        >
          <Input
            disabled={readOnlyMode}
            onBlur={(e) => {
              const value = e.target.value;

              if (value.endsWith(' ')) {
                form.setFieldsValue({
                  productNameEn: value.trimEnd(),
                });
              }
            }}
          />
        </Form.Item>
        {!readOnlyMode && (
          <FooterBox>
            {ctaName === '보완 완료' && (
              <CorrectRequestWithoutChangeButton
                documentCode={DocumentCode.PN}
                productId={productId}
                countryId={countryId}
                certTargetId={certTargetId}
                onSubmit={() => {
                  onClose();
                }}
              />
            )}
            <Button type="primary" loading={submitLoading} htmlType="submit">
              {ctaName}
            </Button>
          </FooterBox>
        )}
      </Form>
    </Modal>
  );
};

export default ProductNameModal;

import styled from 'styled-components';
import { Form, Input, AutoComplete, Checkbox, Button } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/lib/table';
import { nanoid } from 'nanoid';

import { CoaFormParam, CoaItemForm } from 'types/material/coa';
import { messages } from 'lib/consts';
import palette from 'lib/styles/palette';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import { Typography } from 'components/system';
import { MinusIcon } from 'components/system/general/icon';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

interface Props {
  form: FormInstance<CoaFormParam>;
  coaItems: CoaItemForm[];
  optionalCoaTests: any[];
  loading: boolean;
  onAddItem: (coaItem: CoaItemForm) => void;
  onRemoveItem: (index: number) => void;
}

const CoaItemListBlock = styled.div`
  margin-top: 8px;

  .ant-table {
    border-top: 2px solid ${palette.PRIMARY50};
    border-radius: 0;
    overflow: visible;

    th.ant-table-cell {
      background-color: #f9f9f9 !important;
    }
    .ant-table-cell {
      border-color: #d8d8d8;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-control-input {
      position: relative;
      min-height: 32px;
      .ant-input {
        height: 32px;
      }
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input,
    input.ant-input,
    .ant-input-affix-wrapper,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-number-input,
    .ant-picker {
      height: 32px;
    }
    .ant-select-selection-placeholder {
      text-align: left;
      line-height: 30px;
    }
    .ant-form-item-explain {
      position: absolute;
      bottom: -16px;
    }
  }

  .ant-checkbox + span {
    padding-left: 4px;
  }
`;

const TextWithAsterisk = styled(Typography.Text)`
  &::before {
    content: '*';
    color: ${palette.PRIMARY50};
  }
`;

const CoaItemList = ({
  form,
  coaItems,
  optionalCoaTests,
  loading,
  onAddItem,
  onRemoveItem,
}: Props) => {
  const selectedCoaTestNames = coaItems.map(({ testName }) => testName);
  const handleChangeIsNoData = (index: number, checked: boolean) => {
    if (checked) {
      const materialCOAItems = form.getFieldValue('materialCOAItems');
      materialCOAItems[index].specification = null;
      materialCOAItems[index].testResult = null;
      materialCOAItems[index].testUnit = '';
      materialCOAItems[index].testMethod = '';
      form.setFieldsValue({ materialCOAItems });
    }
  };
  const columns: ColumnsType<CoaItemForm> = [
    {
      title: (
        <Typography.Text medium color="SLATE_GRAY70">
          No.
        </Typography.Text>
      ),
      align: 'center',
      render: (_, { isNoData }, index) => (
        <TextWithAsterisk medium style={{ position: 'relative' }}>
          {index + 1}
          {typeof isNoData === 'undefined' && (
            <MinusIcon
              style={{ position: 'absolute', left: -40 }}
              onClick={() => onRemoveItem(index)}
            />
          )}
          <Form.Item name={[index, 'displayOrder']} noStyle initialValue={index}>
            <Input style={{ display: 'none' }} />
          </Form.Item>
        </TextWithAsterisk>
      ),
    },
    {
      title: (
        <TextWithAsterisk medium color="SLATE_GRAY70">
          TEST
        </TextWithAsterisk>
      ),
      align: 'center',
      width: 188,
      render: (_, { materialCategoryId, testName }, index) => (
        <Form.Item
          name={[index, 'testName']}
          rules={[
            ...(!materialCategoryId ? [requireRule, exceptKoreanRule] : []),
            {
              validator: async (_, value) => {
                if (!value || materialCategoryId) return;
                const materialCOAItems = form.getFieldValue('materialCOAItems');
                const targets = materialCOAItems.filter(({ testName }: any) => testName === value);
                if (targets.length >= 2) {
                  throw new Error(messages.SHOULD_NOT_DUPLICATE);
                }
              },
            },
          ]}
        >
          {materialCategoryId ? (
            <Typography.Text type="BODY_2" style={{ textAlign: 'left' }}>
              {testName}
            </Typography.Text>
          ) : (
            <AutoComplete
              options={optionalCoaTests
                .filter(({ name }) => !selectedCoaTestNames.includes(name))
                .map(({ name }) => ({
                  label: name,
                  value: name,
                }))}
              maxLength={100}
              onSelect={(value) => {
                const { materialCategoryId } = optionalCoaTests.find(({ name }) => name === value);
                const materialCOAItems = form.getFieldValue('materialCOAItems');
                materialCOAItems[index].materialCategoryId = materialCategoryId;
                form.setFieldsValue({ materialCOAItems });
              }}
              placeholder="직접 입력 혹은 선택"
            />
          )}
        </Form.Item>
      ),
    },
    {
      title: (
        <TextWithAsterisk medium color="SLATE_GRAY70">
          Specification
        </TextWithAsterisk>
      ),
      align: 'center',
      width: 188,
      render: (_, { isNoData }, index) =>
        (typeof isNoData === 'undefined' || !isNoData) && (
          <Form.Item
            name={[index, 'specification']}
            rules={[requireRule, exceptKoreanRule]}
            initialValue={null}
            normalize={(value) => (value !== '' ? value : null)}
          >
            <Input placeholder="검사항목 규격" maxLength={100} />
          </Form.Item>
        ),
    },
    {
      title: (
        <TextWithAsterisk medium color="SLATE_GRAY70">
          Result
        </TextWithAsterisk>
      ),
      align: 'center',
      width: 108,
      render: (_, { isNoData }, index) =>
        (typeof isNoData === 'undefined' || !isNoData) && (
          <Form.Item
            name={[index, 'testResult']}
            rules={[requireRule, exceptKoreanRule]}
            initialValue={null}
            normalize={(value) => (value !== '' ? value : null)}
          >
            <Input placeholder="결과 입력" maxLength={100} />
          </Form.Item>
        ),
    },
    {
      title: (
        <Typography.Text medium color="SLATE_GRAY70">
          Unit
        </Typography.Text>
      ),
      align: 'center',
      width: 108,
      render: (_, { isNoData }, index) =>
        (typeof isNoData === 'undefined' || !isNoData) && (
          <Form.Item name={[index, 'testUnit']} rules={[exceptKoreanRule]}>
            <Input placeholder="단위" maxLength={20} />
          </Form.Item>
        ),
    },
    {
      title: (
        <Typography.Text medium color="SLATE_GRAY70">
          Method
        </Typography.Text>
      ),
      align: 'center',
      width: 188,
      render: (_, { isNoData }, index) =>
        (typeof isNoData === 'undefined' || !isNoData) && (
          <Form.Item name={[index, 'testMethod']} rules={[exceptKoreanRule]}>
            <Input placeholder="검사 기기 or 방법" maxLength={100} />
          </Form.Item>
        ),
    },
    {
      align: 'center',
      width: 90,
      render: (_, { isNoData }, index) =>
        typeof isNoData !== 'undefined' && (
          <Form.Item name={[index, 'isNoData']} valuePropName="checked">
            <Checkbox onChange={(e) => handleChangeIsNoData(index, e.target.checked)}>
              <Typography.Text type="BODY_2" inline>
                No data
              </Typography.Text>
            </Checkbox>
          </Form.Item>
        ),
    },
  ];

  const handleAddItem = () => {
    onAddItem({
      testName: '',
      tmpId: nanoid(),
    });
  };
  return (
    <CoaItemListBlock>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 12 }}>
        CoA 검사 항목 입력
      </Typography.Text>
      <Table
        columns={columns}
        dataSource={coaItems}
        pagination={false}
        loading={loading}
        rowKey={({ materialCoaItemId, materialCategoryId, tmpId }) =>
          materialCoaItemId || materialCategoryId || tmpId!
        }
      />
      <Button
        type="dashed"
        icon={<Icon name="plus" size={14} color="PRIMARY50" />}
        block
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 8,
          maxWidth: 520,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        onClick={handleAddItem}
      >
        검사항목 추가
      </Button>
    </CoaItemListBlock>
  );
};

export default CoaItemList;

import { Typography } from 'antd';

const MistakeNoticeItem = () => {
  return (
    <Typography.Text style={{ padding: 0, fontWeight: 500 }}>
      잘못 입력된 데이터로 인해 담당자에게 책임의 소지가 발생할 수 있으므로, 신중하게 입력 바랍니다.
    </Typography.Text>
  );
};

export default MistakeNoticeItem;

import { useMutation, useQuery } from 'react-query';
import { useMemo } from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
  CertificationItemAdd,
  CertificationItemForm,
  CertificationItemGet,
  CertificationItemUpdate,
} from 'types/material/certification';
import { APIResponse } from 'types/common';
import { messages } from 'lib/consts';
import client from 'lib/api/client';
import { createFormData } from 'lib/file';

export const useCertificationItems = (materialId: number, updateMode: boolean) => {
  const navigate = useNavigate();

  const { data: certificationItems = [], isFetching: getLoading } = useQuery(
    ['material/certification/getCertificationItems', materialId],
    () =>
      client.get<APIResponse<CertificationItemGet[]>>(`/materials/${materialId}/certifications`),
    { enabled: updateMode, cacheTime: 0, select: (res) => res.data.result },
  );

  const { mutate: addCertificationItems, isLoading: addLoading } = useMutation(
    ({
      materialId,
      certificationItems,
    }: {
      materialId: number;
      certificationItems: CertificationItemAdd[];
    }) =>
      client.post(
        `/materials/${materialId}/certifications`,
        createFormData({ list: certificationItems }),
      ),
    {
      onSuccess: () => {
        message.success('저장되었습니다.');
        navigate(-1);
      },
    },
  );

  const { mutate: updateCertificationItemsMutate, isLoading: updateLoading } = useMutation(
    ({
      materialId,
      certificationItems,
    }: {
      materialId: number;
      certificationItems: CertificationItemUpdate[];
    }) =>
      client.patch(
        `/materials/${materialId}/certifications`,
        createFormData({ list: certificationItems }),
      ),
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
        navigate(-1);
      },
    },
  );

  const updateCertificationItems = ({
    materialId,
    certificationItems: modifiedCertificationItems,
  }: {
    materialId: number;
    certificationItems: CertificationItemForm[];
  }) => {
    const updatingCertificationItems = modifiedCertificationItems.reduce<CertificationItemUpdate[]>(
      (acc, modifiedItem) => {
        const {
          materialCertificationId,
          certificationTitle,
          uploadFile,
          expiryDate,
          hasRestrictMaterial,
          uploadType,
        } = modifiedItem;
        const originItem = certificationItems.find(
          (item) => item.materialCertificationId === materialCertificationId,
        )!;
        if (materialCertificationId) {
          // UPDATE
          const updatingItem = {
            ...(uploadType === 'AUTO' &&
              !uploadFile &&
              originItem.attachUrl && {
                isAutoGenerateDocument: true,
                uploadFile: undefined,
              }),
            ...(certificationTitle !== originItem.certificationTitle && {
              certificationTitle,
            }),
            ...(uploadFile &&
              uploadFile instanceof File && {
                uploadFile,
              }),
            ...(typeof expiryDate !== 'undefined' &&
              expiryDate !== originItem.expiryDate && {
                expiryDate,
              }),
            ...(typeof hasRestrictMaterial !== 'undefined' &&
              hasRestrictMaterial !== originItem.hasRestrictMaterial && {
                hasRestrictMaterial,
              }),
          };
          if (Object.keys(updatingItem).length > 0) {
            acc.push({
              ...updatingItem,
              materialCertificationId,
              actionType: 'MOD',
            });
          }
        } else {
          // ADD
          const { uploadFile, ...rest } = modifiedItem;
          acc.push({
            ...rest,
            ...(uploadFile && { uploadFile: uploadFile as File }),
            actionType: 'ADD',
          });
        }
        return acc;
      },
      [],
    );
    // DELETE
    const deletingCertificationItems: {
      materialCertificationId: number;
      actionType: 'DELETE';
    }[] = certificationItems
      .filter(({ materialCertificationId }) =>
        modifiedCertificationItems.every(
          (item) => item.materialCertificationId !== materialCertificationId,
        ),
      )
      .map(({ materialCertificationId }) => ({
        materialCertificationId,
        actionType: 'DELETE',
      }));

    if (updatingCertificationItems.length > 0 || deletingCertificationItems.length > 0) {
      updateCertificationItemsMutate({
        materialId,
        certificationItems: [...updatingCertificationItems, ...deletingCertificationItems],
      });
    } else {
      message.warning(messages.NO_NEED_TO_UPDATE);
    }
  };

  return useMemo(
    () => ({
      certificationItems,
      getLoading,
      addCertificationItems,
      addLoading,
      updateCertificationItems,
      updateLoading,
    }),
    [
      certificationItems,
      getLoading,
      addCertificationItems,
      addLoading,
      updateCertificationItems,
      updateLoading,
    ],
  );
};

import ProductImport from 'components/product/ProductImport';
import PageTemplate from 'templates/PageTemplate';

const ProductImportPage = () => {
  return (
    <PageTemplate title="코드로 제품 등록" back={false}>
      <ProductImport />
    </PageTemplate>
  );
};

export default ProductImportPage;

import styled from 'styled-components';
import { Button, Checkbox, Col, Form, FormInstance, Input, Row, Select } from 'antd';

import { generateFormRules } from 'lib/validate';
import FooterBox from 'components/FooterBox';
import { Tip, Typography } from 'components/system';
import { netWeightUnitOptions } from 'lib/selectOption';
import { normalizeBlank } from 'lib/form';
import { ManufacturerCompany } from 'types/manufacturer/company';
import InputNumber from 'components/ui/InputNumber';
import { Flex } from 'components/ui';

const ProductBasicFormBlock = styled.div`
  max-width: 688px;
  margin: 0 auto;

  .ant-form-item-required.ant-form-item-no-colon {
    width: 100%;
  }

  .ant-checkbox {
    top: initial;

    & + span {
      padding-right: 0;
    }
  }
`;

interface ProductBasicFormProps {
  updateMode?: boolean;
  form: FormInstance<any>;
  manufacturers: ManufacturerCompany[];
  isManufacturer?: boolean;
  onFormFinished: VoidFunction;
  fetchLoading: boolean;
  isNetWeightUnitDirect: boolean;
  onChangeIsNetWeightUnitDirect: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductBasicForm = ({
  updateMode = false,
  form,
  manufacturers,
  isManufacturer = false,
  onFormFinished,
  fetchLoading,
  isNetWeightUnitDirect,
  onChangeIsNetWeightUnitDirect,
}: ProductBasicFormProps) => {
  return (
    <ProductBasicFormBlock>
      <Form
        form={form}
        colon={false}
        onFinish={onFormFinished}
        layout="vertical"
        style={{ marginTop: 4 }}
      >
        <Form.Item
          label="브랜드명 (영문)"
          name="brandNameEn"
          rules={generateFormRules({
            required: true,
            exceptKorean: true,
            maxLength: 100,
          })}
        >
          <Input placeholder="영문 브랜드명 입력" />
        </Form.Item>
        <Row gutter={16} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label={
                <>
                  제품명 (국문/영문){' '}
                  <Tip style={{ marginLeft: 4 }} trigger="click">
                    <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                      제품명에 브랜드명이 포함되길 원하는 경우
                    </Typography.Text>
                    <Typography.Text type="BODY_2" style={{ fontSize: 10 }}>
                      예시 ① 브랜드명 <span className="primary">certicos</span> 제품명{' '}
                      <span className="primary">soothing cream</span> 인 경우 →{' '}
                      <span className="primary">'soothing cream'</span> 으로 인증
                    </Typography.Text>
                    <Typography.Text type="BODY_2" style={{ fontSize: 10 }}>
                      예시 ② 브랜드명 <span className="primary">certicos</span> 제품명{' '}
                      <span className="primary">certicos soothing cream</span> 인 경우 →{' '}
                      <span className="primary">'certicos soothing cream'</span> 으로 인증
                    </Typography.Text>
                  </Tip>
                </>
              }
              name="productNameKo"
              normalize={normalizeBlank}
              rules={generateFormRules({ required: true, maxLength: 100 })}
            >
              <Input
                placeholder="국문 제품명 입력"
                onBlur={(e) => {
                  const value = e.target.value;

                  if (value.endsWith(' ')) {
                    form.setFieldsValue({
                      productNameKo: value.trimEnd(),
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name="productNameEn"
              normalize={normalizeBlank}
              rules={generateFormRules({
                required: true,
                exceptKorean: true,
                maxLength: 100,
              })}
            >
              <Input
                placeholder="인증서에 등록될 영문 입력"
                onBlur={(e) => {
                  const value = e.target.value;

                  if (value.endsWith(' ')) {
                    form.setFieldsValue({
                      productNameEn: value.trimEnd(),
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="용량" required style={{ marginBottom: 0, position: 'relative' }}>
          <Checkbox
            checked={isNetWeightUnitDirect}
            onChange={(e) => onChangeIsNetWeightUnitDirect(e.target.checked)}
            style={{
              position: 'absolute',
              top: -32,
              right: 0,
              height: 24,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            단위 직접 입력
          </Checkbox>
          <Flex gap={16}>
            <Form.Item
              name="netWeight"
              rules={generateFormRules({
                required: true,
                onlyNumber: true,
                maxLength: 10,
              })}
              style={{ flex: 1 }}
            >
              <InputNumber
                className="hide-handler"
                style={{ width: '100%' }}
                placeholder="용량 입력"
                maxLength={10}
                keyboard={false}
              />
            </Form.Item>
            {isNetWeightUnitDirect ? (
              <Form.Item
                name="netWeightUnitDirect"
                rules={generateFormRules({
                  maxLength: 15,
                })}
                style={{ flex: 1 }}
              >
                <Input placeholder="단위 입력" style={{ width: '100%' }} />
              </Form.Item>
            ) : (
              <Form.Item name="netWeightUnit" style={{ flex: 1 }}>
                <Select style={{ width: '100%' }} placeholder="단위 선택">
                  {netWeightUnitOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Flex>
        </Form.Item>
        <Flex gap={16} align="flex-end">
          <Form.Item
            label={
              <>
                화장품제조업자 (국문/영문){' '}
                <Tip style={{ marginLeft: 4 }} trigger="click">
                  <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                    국문 예시 : <span className="primary">(주)씨디알아이</span>
                  </Typography.Text>
                  <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                    영문 예시 : <span className="primary">CDRI CO., LTD.</span> (cGMP 또는 ISO
                    22716에 등록된 영문과 동일하게 입력)
                  </Typography.Text>
                </Tip>
              </>
            }
            name="manufacturerId"
            rules={generateFormRules({ required: true, maxLength: 255 })}
            style={{ flex: 1 }}
          >
            <Select
              placeholder="국문명 선택"
              showSearch
              filterOption={(keyword, option) =>
                (option?.label &&
                  typeof option.label === 'string' &&
                  option.label.includes(keyword)) ||
                false
              }
              disabled={isManufacturer}
              options={manufacturers.map(
                ({
                  manufacturerId,
                  companyNameKo,
                }: {
                  manufacturerId: number;
                  companyNameKo: string;
                }) => ({
                  label: companyNameKo,
                  value: manufacturerId,
                }),
              )}
            />
          </Form.Item>
          <Form.Item
            name="manufacturerNameEn"
            required
            rules={generateFormRules({
              required: true,
              exceptKorean: true,
              maxLength: 255,
            })}
            style={{ flex: 1 }}
          >
            <Input placeholder="cGMP 또는 ISO22716 보유시 같은 영문" />
          </Form.Item>
        </Flex>
        <Form.Item
          label={
            <>
              화장품제조업자 주소 (영문){' '}
              <Tip style={{ marginLeft: 4 }} trigger="click">
                <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                  예시 :{' '}
                  <span className="primary">
                    A-13, 3F SB plaza, 194-25, Osongsaengmyeong 1-ro, Osong-eup,
                    <br />
                    Heungdeok-gu, Cheongju-si, Chungcheongbuk-do, Republic of Korea
                  </span>
                </Typography.Text>
                <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                  (cGMP 또는 ISO 22716에 등록된 영문과 동일하게 입력)
                </Typography.Text>
              </Tip>
            </>
          }
          name="manufacturerAddressEn"
          required
          rules={generateFormRules({
            required: true,
            exceptKorean: true,
            maxLength: 255,
          })}
        >
          <Input placeholder="영문 주소 입력" />
        </Form.Item>
        <Row gutter={16} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="화장품제조업자 전화번호"
              name="manufacturerTel"
              required
              rules={generateFormRules({
                required: true,
                onlyNumber: true,
                minLength: 8,
                maxLength: 12,
              })}
            >
              <Input placeholder="전화번호 입력" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="화장품제조업자 우편번호"
              name="manufacturerZipCode"
              required
              rules={generateFormRules({
                required: true,
                onlyNumber: true,
                minLength: 5,
                maxLength: 10,
              })}
            >
              <Input placeholder="우편번호 입력" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="화장품책임판매업자 (국문/영문)"
              name="brandCompanyNameKo"
              required
              rules={generateFormRules({
                required: true,
              })}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name="brandCompanyNameEn"
              required
              rules={generateFormRules({
                required: true,
                exceptKorean: true,
              })}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="화장품책임판매업자 주소 (국문/영문)"
          name="brandCompanyAddressKo"
          required
          rules={generateFormRules({
            required: true,
          })}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="brandCompanyAddressEn"
          required
          rules={generateFormRules({
            required: true,
            exceptKorean: true,
          })}
        >
          <Input disabled />
        </Form.Item>
        <FooterBox>
          <Button type="primary" htmlType="submit" loading={fetchLoading}>
            {!updateMode ? '다음' : '수정'}
          </Button>
        </FooterBox>
      </Form>
    </ProductBasicFormBlock>
  );
};

export default ProductBasicForm;

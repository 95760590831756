import { Button, Form, Input, message, Modal, Select } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import path from 'lib/path';
import { requireRule } from 'lib/validate';
import FooterBox from 'components/FooterBox';
import { Tip, Typography } from 'components/system';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { useICIDBasic } from 'service/material/icid/icid';
import { ICIDApplicationStatus, ICIDBasicParam } from 'types/material/icid/icid';
import { messages } from 'lib/consts';

const ICIDBasicContainer = styled.div`
  margin: 24px auto 0;
  max-width: 520px;
`;

const ICIDBasic = ({ icidApplicationId }: { icidApplicationId?: number }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    icidApplication,
    icidCategoryOptions,
    isLoading,
    checkTradeNameIsDuplicated,
    addICIDBasic,
    updateICIDBasic,
  } = useICIDBasic({
    materialIcidApplicationId: icidApplicationId,
  });
  const updateMode = !!icidApplication;
  const readOnlyMode =
    icidApplication?.status === ICIDApplicationStatus.APPLYING ||
    icidApplication?.status === ICIDApplicationStatus.ONGOING ||
    icidApplication?.status === ICIDApplicationStatus.CANCEL;

  const showDuplicateModal = () =>
    Modal.confirm({
      width: 400,
      bodyStyle: { textAlign: 'center', padding: 24 },
      content: (
        <div style={{ margin: '8px 0 12px' }}>
          이미 등록된 원료명 (Trade Name) 입니다.
          <br />
          등재 의뢰 리스트에서 계약 진행 현황을 확인해 주세요.
        </div>
      ),
      closable: true,
      cancelText: '닫기',
      okText: '확인하러 가기',
      icon: null,
      onOk: () => navigate(path.material.icid.requestList),
    });

  const handleSubmit = ({ tradeName, materialCategoryId }: ICIDBasicParam) => {
    if (!icidApplication) {
      checkTradeNameIsDuplicated(tradeName, {
        onSuccess: (res) => {
          const { isDuplicate } = res.data.result;
          if (isDuplicate) {
            showDuplicateModal();
            return;
          }

          addICIDBasic({ tradeName, materialCategoryId });
        },
      });

      return;
    }

    if (
      icidApplication.tradeName === tradeName &&
      icidApplication.category.materialCategoryId === materialCategoryId
    ) {
      return message.warning(messages.NO_NEED_TO_UPDATE);
    }

    updateICIDBasic({
      ...(icidApplication.tradeName !== tradeName && { tradeName }),
      ...(icidApplication.category.materialCategoryId !== materialCategoryId && {
        materialCategoryId,
      }),
    });
  };

  useEffect(() => {
    if (icidApplication) {
      form.setFieldsValue({
        tradeName: icidApplication.tradeName,
        materialCategoryId: icidApplication.category.materialCategoryId,
      });
    }
  }, [icidApplication]);

  return (
    <ICIDBasicContainer>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="원료명 (Trade Name)"
          name="tradeName"
          required
          rules={[
            requireRule,
            {
              pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]{1,150}$/,
              message: '영문, 숫자, 기호 가능 최대 150자',
            },
          ]}
        >
          <Input placeholder="영문" maxLength={150} disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label={
            <>
              카테고리 선택
              <Tip style={{ marginLeft: 4 }} trigger="click">
                <Typography.Text type="SMALL">
                  실제 PCPC의 INCI Application Form 작성시
                  <br />
                  선택하는 카테고리와 동일한 항목입니다.
                </Typography.Text>
              </Tip>
            </>
          }
          name="materialCategoryId"
          required
          rules={[requireRule]}
        >
          <Select
            placeholder="카테고리 선택"
            options={icidCategoryOptions}
            disabled={readOnlyMode}
          />
        </Form.Item>
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {!updateMode ? '등록' : '수정'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </ICIDBasicContainer>
  );
};

export default ICIDBasic;

import styled from 'styled-components';

type Size = 'sm' | 'md';

const LoadingIndicatorWrapper = styled.div<{
  size: Size;
}>`
  ${({ size }) =>
    size === 'sm' &&
    `
    transform: translate(-24px, -24px);
    width: 28px;
    height: 28px;
  `}

  ${({ size }) =>
    size === 'md' &&
    `
    width: 80px;
    height: 80px;
  `}
  display: inline-block;
  position: relative;

  div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 30px;
    left: 39px;
    width: 2px;
    height: 4px;
    border-radius: 4px;
    background: #3785f7;

    ${({ size }) =>
      size === 'sm' &&
      `
      top: 30px;
      left: 39px;
      width: 2px;
      height: 4px;
    `}

    ${({ size }) =>
      size === 'md' &&
      `
      top: 16px;
      left: 38px;
      width: 3px;
      height: 11px;
    `}
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const LoadingIndicator = ({ size = 'md' }: { size?: Size }) => {
  return (
    <LoadingIndicatorWrapper size={size}>
      {Array(12)
        .fill(0)
        .map((_, index) => (
          <div key={index}></div>
        ))}
    </LoadingIndicatorWrapper>
  );
};

export default LoadingIndicator;

import ManagerList from 'components/auth/ManagerList';
import { getManagers } from 'modules/auth';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ManagerRegisterModal from './ManagerRegisterModal';

const ManagerListContainer = () => {
  const dispatch = useDispatch();
  const { companyId, managers, getLoading } = useSelector(({ auth, loading }) => ({
    companyId: auth.user.companyId,
    managers: auth.managers,
    getLoading: loading['auth/GET_MANAGERS'],
  }));
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const handleRegisterManager = () => {
    setRegisterModalVisible(true);
  };
  const handleCloseModal = (isRegisterSuccess) => {
    setRegisterModalVisible(false);
    if (isRegisterSuccess) {
      dispatch(getManagers(companyId));
    }
  };
  useEffect(() => {
    dispatch(getManagers(companyId));
  }, []);
  return (
    <>
      <ManagerRegisterModal visible={registerModalVisible} onClose={handleCloseModal} />
      <ManagerList
        getLoading={getLoading}
        managers={managers}
        onRegisterManager={handleRegisterManager}
      />
    </>
  );
};

export default ManagerListContainer;

import { message } from 'antd';
import moment from 'moment';
import { Rule, RuleObject } from 'antd/lib/form';

import { MarketingFormDataBase } from 'types/material/marketing';
import { messages } from './consts';

/** NEW RULES (22.07.01~) */

export interface GenerateFormRuleOptions {
  required?: boolean;
  exceptKorean?: boolean;
  exceptNumber?: boolean;
  onlyEnglish?: boolean;
  onlyKorean?: boolean;
  onlyNumber?: boolean;
  minLength?: number;
  maxLength?: number;
  contact?: boolean;
  koreanLengthFour?: boolean;
  email?: boolean;
  preventOverYear?: number;
  url?: boolean;
}

// TODO: 기존 validation 검증 로직(정규식)을 해당 함수 하나로 대체 예정
export const generateFormRules = (options: GenerateFormRuleOptions): Rule[] => {
  const validator = (_: RuleObject, value: any = '') => {
    let errorMessage = '';

    const appendErrorMessage = (message: string) => (errorMessage += message + ', ');

    // 필수 (null || undefined || 빈 문자열 || 빈 배열)
    if (options.required && (value === undefined || value === null || value?.length === 0)) {
      appendErrorMessage('필수 항목');

      return Promise.reject('필수 항목');
    }

    if (value?.length > 0 && options.onlyKorean && /[^ㄱ-ㅎㅏ-ㅣ가-힣]+/g.test(value)) {
      appendErrorMessage('한글만 입력 가능');
    }

    // 공백의 입력도 허용합니다.
    if (value?.length > 0 && options.onlyEnglish && /[^a-z|\s]+/gi.test(value)) {
      appendErrorMessage('영문만 입력 가능');
    }

    // 글자수
    if (
      value?.length > 0 &&
      typeof options.minLength === 'number' &&
      typeof options.maxLength === 'number' &&
      (value?.length < options.minLength || value?.length > options.maxLength)
    ) {
      appendErrorMessage(`${options.minLength} ~ ${options.maxLength} 글자`);
    } else if (
      value?.length > 0 &&
      typeof options.minLength === 'number' &&
      value?.length < options.minLength
    ) {
      appendErrorMessage(`최소 ${options.minLength}자`);
    } else if (typeof options.maxLength === 'number' && value?.length > options.maxLength) {
      appendErrorMessage(`최대 ${options.maxLength}자`);
    }

    // 한글 입력 불가
    if (options.exceptKorean && value?.length > 0 && /[ㄱ-ㅎㅏ-ㅣ가-힣]+/g.test(value)) {
      appendErrorMessage('한글 입력 불가');
    }

    //숫자 입력 불가
    if (options.exceptNumber && value?.length > 0 && /[0-9]+/g.test(value)) {
      appendErrorMessage('숫자 입력 불가');
    }

    //1~4까지의 한글
    if (
      options.koreanLengthFour &&
      options.maxLength === 4 &&
      !/^[ㄱ-ㅎㅏ-ㅣ가-힣]+$/g.test(value)
    ) {
      appendErrorMessage('1~4까지의 한글');
    }

    // 오직 숫자
    if (options.onlyNumber && value?.length > 0 && !/^[-]?\d+[.]?\d*$/.test(value)) {
      appendErrorMessage('숫자만 입력 가능');
    }

    if (
      options.email &&
      value?.length > 0 &&
      !/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2})?$/i.test(
        value,
      )
    ) {
      appendErrorMessage('올바르지 않은 이메일 형식');
    }

    // 대쉬가 포함된 전화번호 형식
    if (options.contact && value?.length > 0 && !/^\d{2,3}-\d{3,4}-\d{3,4}$/.test(value)) {
      appendErrorMessage('-가 포함된 전화번호 입력');
    }

    if (options.preventOverYear && typeof value === 'number') {
      if (value > options.preventOverYear) {
        appendErrorMessage('미래의 날짜는 입력 불가');
      }
    }
    // URL 형식
    if (
      options.url &&
      value?.length > 0 &&
      !/^(https?:\/\/)?((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|\d{1,3}(\.\d{1,3}){3}(:\d+)?)(:\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(\[-a-zA-Z\d_]*)?$/i.test(
        value,
      )
    ) {
      appendErrorMessage('올바르지 않은 URL 형식');
    }

    if (options.preventOverYear && typeof value === 'number') {
      if (value > options.preventOverYear) {
        appendErrorMessage('미래의 날짜는 입력 불가');
      }
    }

    if (errorMessage) {
      // 마지막 ', ' 제거
      return Promise.reject(errorMessage.substring(0, errorMessage.length - 2));
    } else {
      return Promise.resolve();
    }
  };

  return [
    {
      validator,
    },
    options.required ? { required: true, message: '' } : { required: false }, //HINT: Form.Item에 required 표시가 보이도록 설정
  ];
};

export const getEmailRule = ({
  start,
  end,
  message,
}: {
  start: number;
  end: number;
  message: string;
}) => ({
  pattern: new RegExp(
    `(?=^[\\da-z]([-_.]?[\\da-z])*@[\\da-z]([-_]?[\\da-z])*\\.[a-z]{2,3}(\\.[a-z]{2})?$)(?=^.{${start},${end}}$)`,
    'i',
  ),
  message,
});

export const getNumberRule = ({
  start,
  end,
  message,
}: {
  start: number;
  end: number;
  message: string;
}) => ({
  pattern: new RegExp(`^[\\d]{${start},${end}}$`),
  message,
});

export const getNumberAndDotRule = ({
  start = 1,
  end,
  message,
}: {
  start?: number;
  end: number;
  message: string;
}) => ({
  pattern: new RegExp(`(?=^[-]?\\d+[.]?\\d*$).{${start},${end}}$`),
  message,
});

export const getExceptNumberRule = ({
  start = 1,
  end,
  message,
}: {
  start?: number;
  end: number;
  message: string;
}) => ({
  pattern: new RegExp(`^[^\\d]{${start},${end}}$`),
  message,
});

export const getTextRule = ({
  start = 1,
  end,
  message,
}: {
  start?: number;
  end: number;
  message: string;
}) => ({
  pattern: new RegExp(`^.{${start},${end}}$`, 's'),
  message,
});

export const getExeceptKoreanRule = ({
  start = 1,
  end,
  message,
}: {
  start?: number;
  end: number;
  message: string;
}) => ({
  pattern: new RegExp(`^[^ㄱ-ㅎㅏ-ㅣ가-힣]{${start},${end}}$`),
  message,
});

export const getExeceptAlphabetRule = ({
  start = 1,
  end,
  message,
}: {
  start?: number;
  end: number;
  message: string;
}) => ({
  pattern: new RegExp(`^[^a-zA-Z]{${start},${end}}$`),
  message,
});

/* REGEX */

export const dateRegex = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;

/* RULES */
export const requireRule = {
  required: true,
  message: messages.REQUIRED_FIELD,
};

export const exceptKoreanRule = {
  pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]*$/,
  message: messages.SHOULD_EXCEPT_KOREAN,
};

export const exceptAlphabetRule = {
  pattern: /^[^A-Z]*$/i,
  message: messages.SHOULD_EXCEPT_ALPHABET,
};

export const exceptKoreanOrAlphabetRule = {
  pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣A-Z]*$/i,
  message: messages.SHOULD_EXCEPT_KOREAN_OR_ALPHABET,
};

export const exceptKoreanOrNumberRule = {
  pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣0-9]*$/i,
  message: messages.SHOULD_EXCEPT_KOREAN_OR_NUMBER,
};

export const alphabetOrNumberRule = {
  pattern: /^[A-Z0-9]*$/i,
  message: messages.SHOULD_ALPHABET_OR_NUMBER,
};

export const numberRule = {
  type: 'number' as const,
  message: messages.SHOULD_NUMBER,
};

export const numbersRule = {
  pattern: /^[-]?\d+(?:[.]\d+)?$/,
  message: messages.SHOULD_NUMBER,
};

export const phoneRule = {
  pattern: /^\d{8,12}$/i,
  message: messages.PHONE_FORMAT,
};

export const mobilePhoneRule = {
  pattern: /^\d{10,11}$/i,
  message: messages.MOBILE_PHONE_FORMAT,
};

export const chinaPhoneRule = {
  pattern: /^\d{10,20}$/i,
  message: messages.CHINA_PHONE_FORMAT,
};

export const emailRule = {
  pattern:
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2})?$/i,
  message: messages.INVALID_EMAIL_FORMAT,
};

export const numberOrKoreanRule = {
  pattern: /^[ㄱ-ㅎㅏ-ㅣ가-힣0-9|]+$/,
  message: messages.SHOULD_NUMBER_OR_KOREAN,
};

export const koreanOrAlphabetRule = {
  pattern: /^[ㄱ-ㅎㅏ-ㅣ가-힣A-Z]+$/i,
  message: messages.SHOULD_KOREAN_OR_ALPHABET,
};

export const numberOneToNinetynineRule = {
  pattern: /^(?:[1-9][0-9]?|99)$/,
  message: messages.NUBER_ONE_TO_NINETYNINE,
};

export const dateRule: Rule = {
  validator: async (_, value) => {
    if (value !== '' && !moment(value, 'YYYY-MM-DD', true).isValid()) {
      throw Error(messages.INVALID_DATE_FORMAT);
    }
  },
};

export const noPastDateRule: Rule = {
  validator: async (_, value) => {
    if (!value) return;
    if (value !== '' && !moment(value, 'YYYY-MM-DD', true).isValid()) {
      throw Error(messages.INVALID_DATE_FORMAT);
    }
    if (dateRegex.test(value) && new Date(value).getTime() < new Date().getTime()) {
      throw new Error('과거의 날짜는 입력 불가');
    }
  },
};

export const noFutureDateRule: Rule = {
  validator: async (_, value) => {
    if (!value) return;
    if (value !== '' && !moment(value, 'YYYY-MM-DD', true).isValid()) {
      throw Error(messages.INVALID_DATE_FORMAT);
    }
    if (new Date(value).getTime() > new Date().getTime()) {
      throw new Error('미래의 날짜는 입력 불가');
    }
  },
};

export const alphabetOrSpaceRule = {
  pattern: /^[A-Z\s]*$/i,
  message: messages.SHOULD_ALPHABET_OR_SPACE,
};

export const urlRule = {
  type: 'url' as const,
  message: '올바르지 않은 URL 형식',
};

/* FORM INPUT NORMALIZE */
export const normalizeExeceptKoreanRule =
  ({ start, end }: { start: number; end: number }) =>
  (value: string, prevValue: string) => {
    const { pattern } = getExeceptKoreanRule({
      start,
      end,
      message: '',
    });

    if (pattern.test(value)) return value;

    return prevValue || '';
  };

/* NORMALIZE */
export const normalizeDate = (value: any) =>
  value
    .replace(/[^\d-]/, '')
    .replace(/^(\d{4})(\d{1,})/, '$1-$2')
    .replace(/^(\d{4}-\d{2})(\d{1,})/, '$1-$2')
    .replace(/^(\d{4}-\d{2}-\d{2}).+/, '$1');

export const normalizeNumber = (value: any) => value.replace(/[^\d]/g, '');
export const capitalize = (value: any) => value.charAt(0).toUpperCase() + value.slice(1);

export const checkInputNumber = (target: string, maxLen: number, msg: string) => {
  if (target.length > maxLen) {
    message.warning(msg);
  }
};

export const validateStringItem = (value?: string) => {
  if (value) {
    if (value.trim().length > 0) {
      return false;
    }
  }
  return true;
};

export const checkIsPageEmpty = (formData: MarketingFormDataBase): boolean => {
  for (let i = 0; i < formData?.pages?.length; i++) {
    let isPageEmpty = true;
    const page = formData.pages[i];
    for (let j = 0; j < page.items.length; j++) {
      const item = page.items[j];
      if (!validateStringItem(item.dataTitle)) {
        isPageEmpty = false;
        break;
      }
      if (!validateStringItem(item.description)) {
        isPageEmpty = false;
        break;
      }
      if (!validateStringItem(item.attachUrl)) {
        isPageEmpty = false;
        break;
      }
      if (!validateStringItem(item.shortDescription)) {
        isPageEmpty = false;
        break;
      }
      if (!validateStringItem(item.itemType)) {
        isPageEmpty = false;
        break;
      }
      if (item.uploadFile) {
        isPageEmpty = false;
      }
    }
    if (isPageEmpty) {
      return isPageEmpty;
    }
  }

  return false;
};

export const checkIsPastDate = (current: moment.Moment) => current.isBefore(moment().endOf('day'));

export const checkIsFutureDate = (current: moment.Moment) => current.isAfter(moment().endOf('day'));

import { Col, Collapse, Empty, Row, Select, Statistic } from 'antd';
import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';

import StatisticCard from 'components/StatisticCard';
import {
  allRetailerIds,
  useRetailerApplyProductDetail,
  useSelectRetailerIds,
  useUnselectRetailerIds,
  useSavedSelectedRetailerIds,
} from 'service/brand/retailer/retailerApply/product';
import { useSetCurrentProduct } from 'service/brand/product/product';
import { retailerImages } from 'lib/consts';
import palette from 'lib/styles/palette';
import SharedDocumentTable from './SharedDocumentTable';
import PrivateDocumentTable from './PrivateDocumentTable';
import RetailerMark from '../../RetailerMark';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  .ant-table-wrapper table tr {
    & > th {
      padding: 10px 8px;
    }

    & > td {
      padding: 18px 16px;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom-color: ${palette.GRAY40};
  }
`;

const RetailerMarkContainer = styled.div`
  margin: 16px auto 56px;
`;

const CollapseContainer = styled.div`
  margin-top: 16px;

  .ant-collapse {
    border: none;
    background-color: #fff;
    counter-reset: no;

    .ant-collapse-item {
      border-bottom: none;

      &:nth-child(2) {
        margin-top: 40px;
      }

      .ant-collapse-header {
        border-radius: 8px;
        background-color: ${palette.SLATE_GRAY10};
        padding: 16px 58px;

        &::before {
          position: absolute;
          left: 34px;
          top: 17px;
          display: block;
          counter-increment: no;
          content: counter(no);
          background-color: ${palette.PRIMARY50};
          color: #fff;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          text-align: center;
          line-height: 18px;
          letter-spacing: -0.8px;
          padding-right: 1px;
          font-size: 12px;
        }

        .ant-collapse-arrow {
          top: 0;
          right: 0;
          padding: 0;
        }
      }

      .ant-collapse-content {
        border-top: none;

        .ant-collapse-content-box {
          padding: 24px 0 0px;
        }
      }
    }
  }
`;

const FINActiveDocumentsDownloadButton = styled.div`
  color: #fff;
  font-weight: 500;
  padding: 6px 8px;
  background-color: ${palette.SLATE_GRAY70};
  border-color: ${palette.SLATE_GRAY70};
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
`;

const ProductDetail = ({ productId }: { productId: number }) => {
  useSetCurrentProduct({ productId });
  const { currentProduct } = useSelector(
    ({ product }: any) => ({
      currentProduct: product.currentProduct,
    }),
    shallowEqual,
  );
  const { savedRetailerIds } = useSavedSelectedRetailerIds(productId);
  const { selectRetailerIds } = useSelectRetailerIds(productId);
  const { unselectRetailerIds } = useUnselectRetailerIds(productId);

  const [selectedRetailerIds, setSelectedRetailerIds] = useState<number[]>([]);
  const selectRetailerId = (id: number) => {
    if (selectedRetailerIds.find((selectedId) => selectedId === id)) {
      setSelectedRetailerIds((draft) => draft.filter((selectedId) => selectedId !== id));
      unselectRetailerIds([id]);
    } else {
      setSelectedRetailerIds((draft) => [...draft, id]);
      selectRetailerIds([id]);
    }
  };
  const selectAllRetailerIds = () => {
    if (selectedRetailerIds.length === retailers.length + 1) {
      setSelectedRetailerIds([]);
      unselectRetailerIds(selectedRetailerIds.filter((id) => id !== 0));
    } else {
      setSelectedRetailerIds(allRetailerIds);
      selectRetailerIds(allRetailerIds.filter((id) => id !== 0));
    }
  };

  const {
    retailers,
    retailerMap,
    sharedDocuments,
    userPrivateDocuments,
    retailerDocumentMap,
    downloadableRetailerFilesMap,
    isNeedOBD,
    downloadRetailerFileZip,
    downloadAllFINActiveDocuments,
  } = useRetailerApplyProductDetail(productId, selectedRetailerIds);

  const highlightSelectOptions = useMemo(() => {
    const options = selectedRetailerIds.map((id) => ({
      label: retailerMap.get(id),
      value: id,
    }));
    if (selectedRetailerIds.every((id) => id !== 0)) {
      options.unshift({ label: '전체 리스트', value: 0 });
    }
    return options;
  }, [selectedRetailerIds, retailerMap]);

  const [highlightId, setHighlightId] = useState<number>();
  useEffect(() => {
    if (
      selectedRetailerIds.length === 0 ||
      (highlightId && !selectedRetailerIds.includes(highlightId))
    ) {
      setHighlightId(undefined);
    }
  }, [selectedRetailerIds]);
  useEffect(() => {
    if (savedRetailerIds.length > 0 && retailers.length > 0) {
      setSelectedRetailerIds(
        savedRetailerIds.length === retailers.length ? [0, ...savedRetailerIds] : savedRetailerIds,
      );
    }
  }, [savedRetailerIds, retailers]);
  const [activeCollapseKeys, setActiveCollapseKeys] = useState(['shared', 'private']);
  const handleChangeActiveCollapseKeys = (keys: string | string[]) => {
    setActiveCollapseKeys(Array.isArray(keys) ? keys : [keys]);
  };
  const {
    netWeight,
    netWeightUnit,
    productDetail: { productNameKo, productNameEn, manufacturerNameKo },
  } = currentProduct || { productDetail: {} };
  const isActiveAll =
    selectedRetailerIds.length === retailers.length + 1 ||
    (selectedRetailerIds.length === retailers.length &&
      selectedRetailerIds.every((id) => id === 0));

  return (
    <Container>
      <StatisticCard style={{ marginTop: 4 }}>
        <Row gutter={16}>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic
              title="제품명(용량)"
              value={`${productNameEn || '-'} (${netWeight} ${netWeightUnit})`}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic title="국문 제품명" value={productNameKo} />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic title="제조사" value={manufacturerNameKo} />
          </Col>
        </Row>
      </StatisticCard>
      <Typography.Text light type="HEADLINE_1" align="center" gutter={{ top: 40 }}>
        입점하실 유통사를 선택해 주세요.
      </Typography.Text>
      <RetailerMarkContainer>
        <Flex gap={8} wrap="true" align="center">
          <RetailerMark
            title={isActiveAll ? '유통사 전체 해제' : '유통사 전체 선택'}
            active={isActiveAll}
            onClick={selectAllRetailerIds}
          />
          {retailers.map(({ retailerId, name }) => (
            <RetailerMark
              key={retailerId}
              active={selectedRetailerIds.some((id) => id === retailerId)}
              onClick={() => selectRetailerId(retailerId)}
              title={name}
              downloadable={downloadableRetailerFilesMap.has(retailerId)}
              onDownload={() =>
                downloadRetailerFileZip(
                  retailerId,
                  `${productNameEn} (${netWeight} ${netWeightUnit})_${name}.zip`,
                )
              }
              imageUrl={retailerImages[retailerId - 1]}
            />
          ))}
        </Flex>
      </RetailerMarkContainer>
      <Row justify="space-between" align="bottom">
        <Col>
          <Select
            style={{ width: 210 }}
            placeholder="유통사 별 서류 확인"
            value={highlightId}
            onChange={(id) => setHighlightId(id as number)}
            options={highlightSelectOptions}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="선택하신 유통사가 없습니다."
              />
            }
          />
        </Col>
        <Col>
          <FINActiveDocumentsDownloadButton
            onClick={() =>
              downloadAllFINActiveDocuments(
                `${productNameEn} (${netWeight} ${netWeightUnit})_서류_${moment(new Date()).format(
                  'YYYY-MM-DD',
                )}.zip`,
              )
            }
          >
            <Flex align="center" gap={4}>
              <Icon name="download" color="ETC_WHITE" size={18} />
              <Typography.Text type="BODY_2" inline>
                입력한 서류 다운
              </Typography.Text>
            </Flex>
          </FINActiveDocumentsDownloadButton>
        </Col>
      </Row>
      <CollapseContainer>
        <Collapse
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <Icon name="up" size={20} style={{ position: 'absolute', top: 18, right: 36 }} />
            ) : (
              <Icon name="down" size={20} style={{ position: 'absolute', top: 18, right: 36 }} />
            )
          }
          activeKey={activeCollapseKeys}
          onChange={handleChangeActiveCollapseKeys}
        >
          <Collapse.Panel
            key="shared"
            header={
              <Typography.Text type="BODY_2" color="SLATE_GRAY70" medium>
                회사 공통 서류
              </Typography.Text>
            }
          >
            <SharedDocumentTable
              sharedDocuments={sharedDocuments}
              productId={productId}
              isNeedOBD={isNeedOBD}
              highlight={typeof highlightId !== 'undefined' && highlightId !== 0}
            />
          </Collapse.Panel>
          <Collapse.Panel
            key="private"
            header={
              <Typography.Text type="BODY_2" color="SLATE_GRAY70" medium>
                개별 서류
              </Typography.Text>
            }
          >
            <PrivateDocumentTable
              privateDocuments={userPrivateDocuments}
              productId={productId}
              highlightIds={
                highlightId
                  ? retailerDocumentMap
                      ?.get(highlightId)
                      ?.map(({ retailerApplyDocSeqId }) => retailerApplyDocSeqId)
                  : undefined
              }
              hasSelectedId={selectedRetailerIds.length > 0}
            />
          </Collapse.Panel>
        </Collapse>
      </CollapseContainer>
    </Container>
  );
};

export default ProductDetail;

import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { s3AssetDomain } from 'lib/consts';
import { Flex } from 'components/ui';

const ThirtyCOSAccountBlock = styled.div``;

const ThirtyCOSAccountBody = styled(Flex)`
  padding: 20px 0;
  border-radius: 8px;
  background-color: ${palette.SLATE_GRAY10};

  p {
    font-size: 16px;
  }
`;

const ThirtyCOSAccount = () => {
  return (
    <ThirtyCOSAccountBlock>
      <Typography.Text type="TITLE_1" gutter={{ top: 24, bottom: 16 }}>
        결제 방법 (계좌 이체)
      </Typography.Text>
      <ThirtyCOSAccountBody justify="space-around">
        <Flex gap={16}>
          <img style={{ width: 24 }} src={`${s3AssetDomain}/shinhanbank.png`} alt="bank logo" />
          <Typography.Text type="TITLE_2" color="SLATE_GRAY70" medium>
            신한은행
          </Typography.Text>
        </Flex>
        <Typography.Text type="TITLE_2" color="SLATE_GRAY70" medium>
          100-035-807481
        </Typography.Text>
        <Typography.Text type="TITLE_2" color="SLATE_GRAY70" medium>
          (주)씨디알아이
        </Typography.Text>
      </ThirtyCOSAccountBody>
    </ThirtyCOSAccountBlock>
  );
};

export default ThirtyCOSAccount;

import LoadingIndicator from 'components/ui/LoadingIndicator';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 1200px;
`;

const FileLoading = () => {
  return (
    <Container>
      <LoadingIndicator />
      <div>
        해당 서류에 필요한 문서가 생성 중입니다.
        <br />
        잠시만 기다려 주세요 {`:)`}
      </div>
    </Container>
  );
};

export default FileLoading;

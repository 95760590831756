import { Button, Col, Empty, List, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Key, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import SearchableList from 'components/SearchableList';
import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { CanSelfEstimateCountry, SelfEstimateTarget, SimpleProduct } from 'types/brand/estimate';

const EstimatePickBlock = styled.div`
  margin-top: 20px;
`;
const ProductItem = styled(List.Item)<{ selected: boolean }>`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? palette.LIGHT_BLUE20 : '#fff')};
  border: none;
  border-bottom: 1px solid #d3d3d335 !important;
`;
const CountryItem = styled(List.Item)<{ selected: boolean }>`
  cursor: pointer;
  border: none;
  border-bottom: 1px solid #d3d3d335 !important;

  ${(props) =>
    props.selected
      ? css`
          i.material-icons {
            color: ${palette.PRIMARY50};
            -webkit-text-stroke: 1px ${palette.PRIMARY50};
          }
        `
      : css`
          i.material-icons {
            color: #d3d3d3;
          }
        `}
`;
const AddButton = styled(Button)`
  display: block;
  margin: 32px auto 8px;
`;

const EstimatePick = ({
  estimateTargets,
  selectedEstimateKeys,
  products,
  selectedProduct,
  countries,
  selectedCountries,
  getProductsLoading,
  getEstimateTargetsLoading,
  publishLoading,
  deleteLoading,
  addLoading,
  onSelectProduct,
  onSelectCountry,
  onSelectAllCountries,
  onAddCountries,
  onChangeEstimateKeys,
  onDeleteEstimateTargets,
  onPublishEstimate,
  onClickChecklist,
}: {
  estimateTargets: SelfEstimateTarget[];
  selectedEstimateKeys: any;
  products: SimpleProduct[];
  selectedProduct: SimpleProduct | null;
  countries: CanSelfEstimateCountry[];
  selectedCountries: CanSelfEstimateCountry[];
  getProductsLoading: boolean;
  getEstimateTargetsLoading: boolean;
  publishLoading: boolean;
  deleteLoading: boolean;
  addLoading: boolean;
  onSelectProduct: (product: SimpleProduct) => void;
  onSelectCountry: (target: CanSelfEstimateCountry) => void;
  onSelectAllCountries: VoidFunction;
  onAddCountries: VoidFunction;
  onChangeEstimateKeys: (ids: Key[]) => void;
  onDeleteEstimateTargets: VoidFunction;
  onPublishEstimate: VoidFunction;
  onClickChecklist: (estimateTarget: SelfEstimateTarget) => void;
}) => {
  const columns: ColumnsType<SelfEstimateTarget> = useMemo(
    () => [
      {
        title: '제품명 (용량)',
        width: '43.4%',
        render: (_, { estimateTargetId, productDetail, netWeight, netWeightUnit }) => (
          <Typography.Text
            type="BODY_2"
            style={{
              color: palette.GRAY90,
              fontWeight: 500,
              cursor: 'pointer',
            }}
            onClick={() => {
              if (selectedEstimateKeys.includes(estimateTargetId)) {
                onChangeEstimateKeys(
                  selectedEstimateKeys.filter((key: number) => key !== estimateTargetId),
                );
              } else {
                onChangeEstimateKeys(selectedEstimateKeys.concat(estimateTargetId));
              }
            }}
          >
            {`${productDetail.productNameEn} ${
              netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
            }`}
          </Typography.Text>
        ),
      },
      {
        title: '국가',
        width: '28.6%',
        align: 'center',
        render: (_, { countryName }) => (
          <Typography.Text type="BODY_2" color="GRAY70">
            {countryName}
          </Typography.Text>
        ),
      },
      {
        title: '체크리스트',
        align: 'center',
        width: '21.7%',
        render: (_, estimateTarget) =>
          estimateTarget.status !== 'ONG' ? (
            <Tags.Mark
              type="arrow"
              color={estimateTarget.status === 'INI' ? 'blue' : 'lightGray'}
              onClick={() => onClickChecklist(estimateTarget)}
            >
              {estimateTarget.status === 'INI' ? '작성하기' : '작성완료'}
            </Tags.Mark>
          ) : (
            <span>-</span>
          ),
      },
    ],
    [selectedEstimateKeys, onChangeEstimateKeys],
  );

  const handleClickPublishEstimate = () => {
    if (selectedEstimateKeys.length > 0) {
      onPublishEstimate();
    }
  };

  return (
    <EstimatePickBlock>
      <Row gutter={[16, 32]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <SearchableList
            title="제품 선택"
            placeholder="제품명 입력"
            filterList={(item: SimpleProduct, keyword) => item.productNameEn.includes(keyword)}
            list={products}
            loading={getProductsLoading}
            renderItem={(item: SimpleProduct) => (
              <ProductItem
                selected={item === selectedProduct}
                onClick={() => onSelectProduct(item)}
              >
                {`${item.productNameEn} (${item.netWeight} ${item.netWeightUnit})`}
              </ProductItem>
            )}
            emptyText={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    등록 완료된 제품이 없습니다.
                    <br />
                    먼저 제품을 등록해 주세요.
                  </>
                }
              />
            }
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <SearchableList
            title="국가 선택"
            placeholder="국가 입력"
            list={countries}
            top={selectedProduct}
            filterList={({ countryNameKo }, keyword) => countryNameKo.includes(keyword)}
            action={
              <Typography.Text
                type="BODY_2"
                style={{ color: palette.PRIMARY50, cursor: 'pointer' }}
                onClick={onSelectAllCountries}
              >
                전체 선택
              </Typography.Text>
            }
            emptyText={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  selectedProduct ? (
                    <>
                      이미 모든 국가가 추가 되었습니다.
                      <br />
                      아래 목록에 없을 경우{' '}
                      <Link to={path.certificate.ongoing} style={{ textDecoration: 'underline' }}>
                        인증 진행
                      </Link>{' '}
                      또는{' '}
                      <Link to={path.certificate.complete} style={{ textDecoration: 'underline' }}>
                        인증 완료
                      </Link>{' '}
                      상태일 수 있습니다.
                    </>
                  ) : (
                    '제품을 선택해 주세요.'
                  )
                }
              />
            }
            renderItem={(item: CanSelfEstimateCountry) => (
              <CountryItem
                selected={selectedCountries.includes(item)}
                onClick={() => onSelectCountry(item)}
              >
                <Flex align="center" style={{ width: '100%' }}>
                  <Flex style={{ marginRight: '8px' }}>
                    <Icon
                      name="check"
                      size={18}
                      color={selectedCountries.includes(item) ? 'PRIMARY50' : 'GRAY40'}
                    />
                  </Flex>
                  <Flex gap={4}>
                    <img
                      src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${item.countryCode.toLowerCase()}.png`}
                      alt=""
                      style={{ width: '20px' }}
                    />
                    <Typography.Text
                      style={{
                        color: palette.GRAY90,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {item.countryNameKo}
                    </Typography.Text>
                  </Flex>
                </Flex>
              </CountryItem>
            )}
          />
        </Col>
      </Row>
      <AddButton
        loading={addLoading}
        icon={<Icon name="plus" size={18} color="PRIMARY50" />}
        onClick={onAddCountries}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        셀프 견적 리스트에 추가
      </AddButton>
      <Row align="middle" gutter={8}>
        <Col>
          <Typography.Text type="TITLE_1">셀프 견적 리스트</Typography.Text>
        </Col>
        <Col>
          <Tip trigger="click">
            <Typography.Text type="SMALL">
              위의 ‘셀프 견적 리스트에 추가’ 버튼으로 제품을
              <br />
              계속 추가하실 수 있습니다.
            </Typography.Text>
          </Tip>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={estimateTargets}
        rowKey={({ estimateTargetId }) => estimateTargetId}
        pagination={false}
        rowSelection={{
          columnWidth: 60,
          selectedRowKeys: selectedEstimateKeys,
          onChange: onChangeEstimateKeys,
        }}
        loading={getEstimateTargetsLoading}
        style={{ marginTop: 16 }}
      />
      <Row justify="end" gutter={[8, 8]} style={{ marginTop: 24 }}>
        <Col>
          <Button
            loading={deleteLoading}
            type="default"
            icon={<Icon name="delete" size={16} color="PRIMARY50" />}
            onClick={onDeleteEstimateTargets}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            리스트에서 제거
          </Button>
        </Col>
        <Col>
          <Button
            type={selectedEstimateKeys.length !== 0 ? 'primary' : undefined}
            className={selectedEstimateKeys.length === 0 ? 'ant-btn-waiting' : ''}
            loading={publishLoading}
            onClick={handleClickPublishEstimate}
          >
            총 {selectedEstimateKeys.length} SKU 견적서 한 번에 받기
          </Button>
        </Col>
      </Row>
    </EstimatePickBlock>
  );
};

export default EstimatePick;

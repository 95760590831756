import { Button, Col, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { Tip } from 'components/system';
import { downloadFile } from 'lib/file';
import { DocumentCode } from 'types/brand/retailer/retailerApply';
import Icon from 'components/ui/Icon/Icon';

const INGSSampleContainer = styled.div`
  margin-bottom: 24px;
`;

const ProductINGSPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="전성분표"
      notices={[
        '전성분표를 엑셀 또는 PDF형식으로 업로드해 주세요.',
        '유통 플랫폼별 전성분표를 각각 관리할 수 있도록 최대 3개의 파일까지 업로드가 가능합니다.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={DocumentCode.INGS}
        accept="application/pdf, .xlsx"
        max={3}
        sample={
          <INGSSampleContainer>
            <Row align="middle" gutter={4}>
              <Col>
                <Typography.Text style={{ fontSize: 20 }}>샘플 양식</Typography.Text>
              </Col>
              <Col>
                <Tip trigger="click">
                  <Typography.Text>
                    해당 양식은 certicos에서 제공하는 기본 양식입니다.
                    <br /> 실제 입점시 유통사에서 요청받은 특정 양식이 있을 경우는, 해당 양식을
                    이용해 주세요.
                  </Typography.Text>
                </Tip>
              </Col>
            </Row>
            <Row gutter={8} style={{ marginTop: 4 }}>
              <Col>
                <Button
                  icon={<Icon name="download" size={16} color="PRIMARY50" />}
                  onClick={() =>
                    downloadFile(
                      'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Retailer+Apply+Product+Formula.xlsx',
                      '유통사_전성분표_일반서식_V2.xlsx',
                    )
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  일반 엑셀 서식
                </Button>
              </Col>
              <Col>
                <Button
                  icon={<Icon name="download" size={16} color="PRIMARY50" />}
                  onClick={() =>
                    downloadFile(
                      'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Retailer+Apply+Product+Formula(HomeShopping).xlsx',
                      '유통사_전성분표_홈쇼핑서식_V2.xlsx',
                    )
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  홈쇼핑 엑셀 서식
                </Button>
              </Col>
            </Row>
          </INGSSampleContainer>
        }
      />
    </ProductPageTemplate>
  );
};

export default ProductINGSPage;

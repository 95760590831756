import { Input } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import Icon from 'components/ui/Icon/Icon';

const PasswordInput = (props: PasswordProps) => {
  return (
    <Input.Password
      {...props}
      iconRender={(visible) =>
        visible ? (
          <Icon size={20} name="show" color="PRIMARY50" />
        ) : (
          <Icon size={20} name="hide" color="GRAY50" />
        )
      }
    />
  );
};

export default PasswordInput;

import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import client from 'lib/api/client';
import { APIResponse, DocumentField } from 'types/common';
import { DocumentCode, UpdateLogs } from 'types/material/common';
import { useDocumentFieldMap } from 'service/common';

export const useUpdateLogs = ({
  id,
  documentCode,
  isVisibleLogs,
}: {
  id: number;
  documentCode: DocumentCode;
  isVisibleLogs: boolean;
}) => {
  const [selectedField, setSelectedField] = useState<DocumentField | null>(null);

  const { documentFieldMap } = useDocumentFieldMap();

  const fields = useMemo(
    () => documentFieldMap?.[documentCode] || [],
    [documentCode, documentFieldMap],
  );

  const { data: allUpdateLogs, isLoading: logLoading } = useQuery(
    [
      'icid-applications/doc/logs',
      id,
      selectedField?.documentSeqId,
      selectedField?.documentFieldId,
      isVisibleLogs,
    ],
    () =>
      client.post<APIResponse<UpdateLogs>>('/v1/icid-applications/doc/logs', {
        materialIcidApplicationId: id,
        documentSeqId: fields[0].documentSeqId,
        documentFieldIds: fields.map((item) => item.documentFieldId),
      }),
    {
      enabled: isVisibleLogs,
      select: (res) => res.data.result,
    },
  );

  return {
    allUpdateLogs,
    logLoading,
    fields,
    selectedField,
    changeSelectedField: setSelectedField,
  };
};

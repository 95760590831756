import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { deviceSize } from 'lib/styles';
import { s3AssetDomain } from 'lib/consts';
import COLOR from './colors';
import { H1 } from './introStyles';

const chatBoxImages = [
  {
    pc: `${s3AssetDomain}/intro_best_faq_1.png`,
    mobile: `${s3AssetDomain}/intro_best_faq_mobile_1.png`,
  },
  {
    pc: `${s3AssetDomain}/intro_best_faq_2.png`,
    mobile: `${s3AssetDomain}/intro_best_faq_mobile_2.png`,
  },
  {
    pc: `${s3AssetDomain}/intro_best_faq_3.png`,
    mobile: `${s3AssetDomain}/intro_best_faq_mobile_3.png`,
  },
  {
    pc: `${s3AssetDomain}/intro_best_faq_4.png`,
    mobile: `${s3AssetDomain}/intro_best_faq_mobile_4.png`,
  },
];

const Container = styled.div`
  padding: 150px 24px;
  background-color: ${COLOR.BG3};

  @media ${deviceSize.sm} {
    padding: 70px 24px;
  }
`;

const Body = styled.div`
  max-width: 1200px;
  margin: auto;

  > *:first-child {
    margin-bottom: 70px;

    @media ${deviceSize.sm} {
      margin-bottom: 50px;
    }
  }
`;

const ChatBoxList = styled.div`
  > *:not(:first-child) {
    margin-top: 50px;

    @media ${deviceSize.sm} {
      margin-top: 30px;
    }
  }
`;

const ChatBoxWrapper = styled.div<{ index: number }>`
  display: flex;
  justify-content: ${({ index }) => (index % 2 ? 'flex-end' : 'flex-start')};

  @media ${deviceSize.sm} {
    justify-content: center;
  }

  > img {
    width: 100%;
    max-width: 1016px;
  }
`;

const SectionK = () => {
  return (
    <Container>
      <Body>
        <H1 style={{ textAlign: 'center' }}>BEST FAQ</H1>
        <ChatBoxList>
          <ChatBox index={0} />
          <ChatBox index={1} />
          <ChatBox index={2} />
          <ChatBox index={3} />
        </ChatBoxList>
      </Body>
    </Container>
  );
};

const ChatBox = ({ index }: { index: number }) => {
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  return (
    <ChatBoxWrapper index={index}>
      <img src={isMobile ? chatBoxImages[index].mobile : chatBoxImages[index].pc} alt="faq" />
    </ChatBoxWrapper>
  );
};

export default SectionK;

import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import { scrollToFirstErrorOption } from 'lib/consts';
import { focusToInvalidatedField } from 'lib/form';
import { useForceUpdate } from 'lib/hook';
import { colorOptions, formOptions, odourOptions } from 'lib/selectOption';
import palette from 'lib/styles/palette';
import {
  capitalize,
  checkIsFutureDate,
  checkIsPastDate,
  dateRule,
  generateFormRules,
  normalizeDate,
} from 'lib/validate';
import { usePhysicalProperties } from 'service/brand/product/physicalProperties';
import { useProductSample } from 'service/manufacturer/productSample';
import { DocumentCode } from 'types/product';
import UpdateLog from '../UpdateLog';
import HardnessForm from './HardnessForm';
import PHForm from './PHForm';
import SpecificGravityForm from './SpecificGravityForm';
import ViscosityForm from './ViscosityForm';

const ProductPhysicalPropertyBlock = styled.div`
  width: 688px;
  margin: 0 auto;

  .calendar-dropdown {
    right: 0 !important;
    left: auto;
  }
`;

const TypeOfProductForm = styled(Form.Item)`
  .ant-form-item-control-input {
    min-height: 0px;
  }
  .ant-radio-wrapper:first-child {
  }
`;

const RadioBlock = styled.div`
  padding: 12px 84px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
  height: 48px;
`;

const TypeOfProductRadio = ({
  value,
  onChange,
  readOnlyMode,
}: {
  value?: boolean;
  onChange?: (e: RadioChangeEvent) => void;
  readOnlyMode: boolean;
}) => {
  return (
    <RadioBlock>
      <Radio.Group value={value} onChange={onChange} disabled={readOnlyMode}>
        <Flex align="center" justify="center" gap={80} style={{ width: '100%' }}>
          <Radio value="LEAVE_ON" style={{ color: palette.SLATE_GRAY70, fontWeight: 500 }}>
            Leave-On
          </Radio>
          <Radio value="RINSE_OFF" style={{ color: palette.SLATE_GRAY70, fontWeight: 500 }}>
            Rinse-Off
          </Radio>
        </Flex>
      </Radio.Group>
    </RadioBlock>
  );
};

const directInputSelectNames = ['form', 'color', 'odour', 'netWeightUnit'];

const ProductPhysicalProperty = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const forceUpdate = useForceUpdate();
  const {
    form,
    certificateMode,
    currentProduct,
    fetchLoading,
    updateMode,
    readOnlyMode,
    handleChangePhRadioGroup,
    handleChangeSpecificGravityRadioGroup,
    handleChangeViscosityRadioGroup,
    handleChangeHardnessRadioGroup,
    onSubmit,
  } = usePhysicalProperties(productId, countryId);
  const { productSample } = useProductSample(currentProduct.productSampleId || undefined);

  const isOnlyCN =
    currentProduct.productCountries.length === 1 &&
    currentProduct.productCountries[0].country.countryId === 3;

  useEffect(() => {
    if (!updateMode && productSample) {
      form.setFieldsValue({ productType: productSample.productType });
    }
  }, [updateMode, productSample]);

  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.PHY}
          />
        </Col>
        {updateMode && productId && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.PHY}
            />
          </Col>
        )}
      </Row>
      <ProductPhysicalPropertyBlock>
        <Form
          form={form}
          colon={false}
          layout="vertical"
          scrollToFirstError={scrollToFirstErrorOption}
          style={{ margin: '20px 0' }}
          onFinish={onSubmit}
          onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
          onValuesChange={(changedValues) => {
            if (
              Object.keys(changedValues).filter((key) => directInputSelectNames.includes(key))
                .length
            ) {
              forceUpdate();
            }
          }}
        >
          <TypeOfProductForm
            label="Type of Product"
            required
            name="productType"
            rules={generateFormRules({
              required: true,
            })}
          >
            <TypeOfProductRadio readOnlyMode={readOnlyMode} />
          </TypeOfProductForm>
          <Form.Item
            label="해당하는 항목 선택"
            required
            name="aerosolOrEthanol"
            rules={generateFormRules({
              required: true,
            })}
          >
            <Radio.Group disabled={readOnlyMode}>
              <Radio.Button value="aerosol">에어로졸 제품</Radio.Button>
              <Radio.Button value="ethanol">에탄올이 10% 이상 함유</Radio.Button>
              <Radio.Button value="nothing">둘 다 해당 없음</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Appearance - Form" required>
            <Row gutter={16} wrap={false}>
              <Col flex="auto">
                <Form.Item
                  noStyle
                  name={form.getFieldValue('form') !== 'others' ? 'form' : 'formDirect'}
                  rules={generateFormRules({
                    required: true,
                    onlyEnglish: true,
                  })}
                  normalize={capitalize}
                >
                  <Input
                    maxLength={100}
                    disabled={form.getFieldValue('form') !== 'others' || readOnlyMode}
                    style={
                      !readOnlyMode
                        ? {
                            color: palette.GRAY90,
                            backgroundColor: palette.ETC_WHITE,
                          }
                        : undefined
                    }
                  />
                </Form.Item>
              </Col>
              <Col flex="0 0 248px">
                <Form.Item
                  noStyle
                  initialValue="others"
                  name="form"
                  rules={generateFormRules({
                    required: true,
                  })}
                >
                  <Select disabled={readOnlyMode}>
                    <Select.Option key="others" value="others">
                      직접입력
                    </Select.Option>
                    {formOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="Appearance - Color" required>
            <Row gutter={16} wrap={false}>
              <Col flex="auto">
                <Form.Item
                  noStyle
                  name={form.getFieldValue('color') !== 'others' ? 'color' : 'colorDirect'}
                  rules={generateFormRules({
                    required: true,
                    onlyEnglish: true,
                  })}
                  normalize={capitalize}
                >
                  <Input
                    maxLength={40}
                    disabled={form.getFieldValue('color') !== 'others' || readOnlyMode}
                    style={
                      !readOnlyMode
                        ? {
                            color: palette.GRAY90,
                            backgroundColor: palette.ETC_WHITE,
                          }
                        : undefined
                    }
                  />
                </Form.Item>
              </Col>
              <Col flex="0 0 248px">
                <Form.Item
                  noStyle
                  name="color"
                  initialValue="others"
                  rules={generateFormRules({
                    required: true,
                  })}
                >
                  <Select disabled={readOnlyMode}>
                    <Select.Option key="others" value="others">
                      직접입력
                    </Select.Option>
                    {colorOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="Odour" required>
            <Row gutter={16} wrap={false}>
              <Col flex="auto">
                <Form.Item
                  noStyle
                  name={form.getFieldValue('odour') !== 'others' ? 'odour' : 'odourDirect'}
                  rules={generateFormRules({
                    required: true,
                    onlyEnglish: true,
                  })}
                  normalize={capitalize}
                >
                  <Input
                    maxLength={30}
                    disabled={form.getFieldValue('odour') !== 'others' || readOnlyMode}
                    style={
                      !readOnlyMode
                        ? {
                            color: palette.GRAY90,
                            backgroundColor: palette.ETC_WHITE,
                          }
                        : undefined
                    }
                  />
                </Form.Item>
              </Col>
              <Col flex="0 0 248px">
                <Form.Item
                  noStyle
                  initialValue="others"
                  name="odour"
                  rules={generateFormRules({ required: true })}
                >
                  <Select disabled={readOnlyMode}>
                    <Select.Option key="others" value="others">
                      직접입력
                    </Select.Option>
                    {odourOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={
              <>
                Net weight or Net volume (용량)
                <Tip style={{ marginLeft: 4 }} trigger="click">
                  <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                    용량은 기본정보에서 수정하실 수 있습니다.
                  </Typography.Text>
                </Tip>
              </>
            }
            required
          >
            <Row gutter={16}>
              <Col flex="1 1 50%">
                <Form.Item noStyle>
                  <Input disabled value={currentProduct?.netWeight} />
                </Form.Item>
              </Col>
              <Col flex="1 1 50%">
                <Form.Item noStyle>
                  <Input disabled value={currentProduct?.netWeightUnit} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="Lot No. (Batch number)"
            name="lotNo"
            required={isOnlyCN ? false : true}
            rules={generateFormRules({
              required: !isOnlyCN,
              exceptKorean: true,
            })}
          >
            <Input
              maxLength={15}
              placeholder="유통화장품법 기준으로 테스트를 완료한 제품의 Lot No."
              disabled={readOnlyMode}
            />
          </Form.Item>
          <Flex justify="space-between">
            <Form.Item
              label="Manufacturing Date"
              name="manufacturingDate"
              required={isOnlyCN ? false : true}
              rules={[
                ...generateFormRules({
                  required: !isOnlyCN,
                  exceptKorean: true,
                }),
                dateRule,
              ]}
              normalize={normalizeDate}
            >
              <DatePicker
                style={{ width: 336 }}
                disabled={readOnlyMode}
                checkIsValidDate={checkIsPastDate}
              />
            </Form.Item>
            <Form.Item
              label="Expiry Date"
              name="expiryDate"
              required={isOnlyCN ? false : true}
              rules={[
                ...generateFormRules({
                  required: !isOnlyCN,
                  exceptKorean: true,
                }),
                dateRule,
              ]}
              normalize={normalizeDate}
            >
              <DatePicker
                disabled={readOnlyMode}
                style={{ width: 336 }}
                checkIsValidDate={checkIsFutureDate}
              />
            </Form.Item>
          </Flex>
          <PHForm
            isOnlyCN={isOnlyCN}
            readOnlyMode={readOnlyMode}
            onChangePhRadioGroup={handleChangePhRadioGroup}
          />
          <SpecificGravityForm
            isOnlyCN={isOnlyCN}
            readOnlyMode={readOnlyMode}
            onChangeSpecificGravityRadioGroup={handleChangeSpecificGravityRadioGroup}
          />
          <ViscosityForm
            isOnlyCN={isOnlyCN}
            readOnlyMode={readOnlyMode}
            onChangeViscosityRadioGroup={handleChangeViscosityRadioGroup}
          />
          <HardnessForm
            isOnlyCN={isOnlyCN}
            readOnlyMode={readOnlyMode}
            onChangeHardnessRadioGroup={handleChangeHardnessRadioGroup}
          />
          <FooterBox>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              {updateMode && certificateMode && (
                <CorrectRequestWithoutChangeButton documentCode={DocumentCode.PHY} />
              )}
              <Button type="primary" loading={fetchLoading} htmlType="submit">
                {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </ProductPhysicalPropertyBlock>
    </>
  );
};

export default ProductPhysicalProperty;

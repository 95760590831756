import { Avatar, Col, Row } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { nanoid } from 'nanoid';
import Icon from 'components/ui/Icon/Icon';

const StyledCircle = styled.div<{ opacity: number; delay: number }>`
  width: 8px;
  height: 8px;
  opacity: ${(props) => props.opacity};
  border-radius: 4px;
  background-color: ${palette.PRIMARY50};

  @keyframes bounce {
    0%,
    50%,
    100% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.6);
    }
    75% {
      transform: scale(1.4);
    }
  }
  animation: bounce 1.5s 0.5s linear infinite;
  animation-delay: ${(props) => `${props.delay}s`};
`;

const circles = [
  { opacity: 0.2, delay: 0 },
  { opacity: 0.3, delay: 0.1 },
  { opacity: 0.5, delay: 0.2 },
  { opacity: 0.8, delay: 0.3 },
  { opacity: 1, delay: 0.4 },
];

const OngoingRequestModalContent = ({
  fromUserName,
  toUserName,
}: {
  fromUserName: string;
  toUserName: string;
}) => {
  return (
    <>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 24 }}>
        입력 요청 중입니다.
      </Typography.Text>
      <Row style={{ marginBottom: 50 }} justify="center" align="middle" gutter={36}>
        <Col>
          <Avatar
            size={40}
            icon={
              <Icon
                name="person"
                color="ETC_WHITE"
                size={20}
                style={{ margin: '0 auto', height: 40 }}
              />
            }
          />
          <Typography.Text>{fromUserName}</Typography.Text>
        </Col>
        <Col>
          <Row gutter={8}>
            {circles.map(({ opacity, delay }) => (
              <Col key={nanoid()}>
                <StyledCircle opacity={opacity} delay={delay} />
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          <Avatar
            style={{ backgroundColor: palette.PRIMARY50 }}
            size={40}
            icon={
              <Icon
                name="person"
                color="ETC_WHITE"
                size={20}
                style={{ margin: '0 auto', height: 40 }}
              />
            }
          />
          <Typography.Text>{toUserName}</Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export default OngoingRequestModalContent;

import React from 'react';
import styled from 'styled-components';
import { Button, Collapse, Form, FormInstance, Row } from 'antd';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import MarketingTemplatePage from './MarketingTemplatePage';
import FileViewer from 'components/file/FileViewer';
import { downloadFile } from 'lib/file';
import { MarketingFile } from 'types/material/marketing';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';

const StyledCollapse = styled(Collapse)`
  background: #fff;

  .ant-collapse-header {
    margin-bottom: 24px;
    background: ${palette.GRAY10} !important;
    border-radius: 8px !important;
    padding: 16px 36px !important;
    color: ${palette.SLATE_GRAY70} !important;
    .ant-collapse-arrow {
      top: 0;
      right: 0;
      padding: 0 !important;
    }
  }

  .site-collapse-custom-panel {
    overflow: hidden;
    border: 0px;
    border-radius: 2px;
  }

  .ant-collapse-content-box {
    background-color: #fff;
    padding: 0px !important;
  }

  .ant-upload-list {
    display: none !important;
  }

  .ant-form-item-control-input {
    min-height: 24px !important;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-radio-button,
  .ant-radio-button-checked {
    display: none !important;
  }

  .ant-radio-button-wrapper {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    line-height: 16px;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #fff;
  }
`;

const StyledBottomButton = styled(Button)<{ pdf?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: none;
  color: ${(props) => (props.pdf === 'true' ? '#fff' : palette.SLATE_GRAY70)};
  background-color: ${(props) => (props.pdf === 'true' ? palette.LIGHT_BLUE40 : '#eff1f8')};

  :hover,
  :focus {
    background-color: ${(props) =>
      props.pdf === 'true' ? palette.LIGHT_BLUE40 : '#eff1f8'} !important;
    color: ${(props) => (props.pdf === 'true' ? '#fff' : palette.SLATE_GRAY70)} !important;
  }
`;
interface Props {
  title: string;
  form: FormInstance<any>;
  disabled?: boolean;
  file?: MarketingFile;
  onDownloadOnly: () => void;
  onChangeDownloadLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const MarketingTemplateCollapse: React.FC<Props> = ({
  title,
  form,
  disabled,
  file,
  onDownloadOnly,
  onChangeDownloadLoading,
}) => {
  return (
    <Form form={form} layout="vertical">
      <StyledCollapse
        expandIconPosition="end"
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <Icon
            name={isActive ? 'up' : 'down'}
            size={20}
            style={{ position: 'absolute', top: 20, right: 36 }}
          />
        )}
        className="site-collapse-custom-collapse"
      >
        <Collapse.Panel
          header={title}
          style={{
            fontSize: '16px',
            fontWeight: 500,
          }}
          key="1"
          className="site-collapse-custom-panel"
        >
          {!disabled ? (
            <Form.List name="pages" initialValue={[{ templateType: 'A', items: [{}, {}] }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <MarketingTemplatePage
                      key={field.key}
                      disabled={disabled}
                      field={field}
                      isMinimum={fields.length === 1}
                      form={form}
                      remove={remove}
                    />
                  ))}
                  <Flex style={{ marginTop: 25 }} gap={16}>
                    <StyledBottomButton
                      onClick={() => {
                        add({
                          templateType: 'A',
                          items: [{}, {}],
                        });
                      }}
                    >
                      <Icon name="plus" size={16} color="SLATE_GRAY70" />
                      <Typography.Text>페이지 추가</Typography.Text>
                    </StyledBottomButton>
                    <StyledBottomButton pdf="true" onClick={onDownloadOnly}>
                      <Icon name="download" size={16} color="ETC_WHITE" />
                      <Typography.Text>PDF 다운로드</Typography.Text>
                    </StyledBottomButton>
                  </Flex>
                </>
              )}
            </Form.List>
          ) : (
            <Row align="middle" justify="center">
              <FileViewer file={file?.attachUrl} bodyStyle={{ height: 586 }} style={undefined} />
              <StyledBottomButton
                pdf="true"
                onClick={async () => {
                  if (file) {
                    onChangeDownloadLoading(true);
                    await downloadFile(file.attachUrl, file.filename);
                    onChangeDownloadLoading(false);
                  }
                }}
                style={{ marginTop: 16 }}
              >
                <Icon name="download" size={16} color="ETC_WHITE" />
                <Typography.Text>PDF 다운로드</Typography.Text>
              </StyledBottomButton>
            </Row>
          )}
        </Collapse.Panel>
      </StyledCollapse>
    </Form>
  );
};

export default MarketingTemplateCollapse;

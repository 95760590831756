import styled, { css } from 'styled-components';
import { message } from 'antd';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';

const Container = styled(Flex)<{ $active: boolean }>`
  min-width: 176px;
  min-height: 46px;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid ${palette.GRAY40};
  cursor: pointer;

  img {
    margin-right: 8px;
  }

  ${({ $active }) =>
    $active &&
    css`
      box-shadow: 0px 2px 22px rgba(162, 162, 162, 0.23);
      border-color: ${palette.PRIMARY50};
    `}
`;

const DownloadContainer = styled.div<{ $active: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${palette.SLATE_GRAY10};
  margin-left: auto;

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${palette.PRIMARY20};
    `}
`;

const RetailerMark = ({
  title,
  imageUrl,
  active,
  downloadable,
  onDownload,
  onClick,
}: {
  title: string;
  imageUrl?: string;
  active: boolean;
  downloadable?: boolean;
  onDownload?: () => void;
  onClick?: () => void;
}) => {
  const handleDownload = () => {
    if (!downloadable) {
      return message.warning('입력필요 항목을 모두 입력하셔야 다운로드할 수있습니다.');
    }

    onDownload && onDownload();
  };

  return (
    <Container $active={active} align="middle" onClick={onClick}>
      {imageUrl && <img src={imageUrl} alt={title} />}
      <Typography.Text color={active ? 'GRAY90' : 'GRAY50'} inline>
        {title}
      </Typography.Text>
      {active && imageUrl && (
        <DownloadContainer
          $active={!!downloadable}
          onClick={(e) => {
            e.stopPropagation();
            handleDownload();
          }}
        >
          {downloadable ? (
            <>
              <Icon name="downloadActived" />
            </>
          ) : (
            <Icon name="downloadDisabled" />
          )}
        </DownloadContainer>
      )}
    </Container>
  );
};

export default RetailerMark;

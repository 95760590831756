import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductSearchType, ProductStatus, ProductSearchParams } from 'types/manufacturer/product';
import * as productApi from 'lib/api/manufacturer/product';
import useUpdateEffect from 'hook/useUpdateEffect';
import path from 'lib/path';
import { useDispatch } from 'react-redux';
import * as productActions from 'modules/product';
import { setCurrentProduct } from 'modules/product';
import { useMovePathname } from 'hook/useMovePathname';

export const useProduct = (productId?: number) => {
  const { data: product = null, isFetching: getProductLoading } = useQuery(
    ['manufacturer/products', productId],
    () => productApi.getProduct(productId!),
    {
      select: (res) => res.data.result,
      enabled: productId !== undefined,
    },
  );
  return useMemo(
    () => ({
      product,
      getProductLoading,
    }),
    [product, getProductLoading],
  );
};

export const useGetProduct = () => {
  const { mutateAsync: getProduct, isLoading } = useMutation(
    productApi.getProductForCheckDuplicate,
  );

  return useMemo(
    () => ({
      getProduct,
      isLoading,
    }),
    [getProduct, isLoading],
  );
};

export const useProducts = (status: ProductStatus) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const savedSearchParams = (state as Partial<ProductSearchParams>) || {};
  const [page, setPage] = useState(savedSearchParams.page ?? 1);
  const [searchType, setSearchType] = useState<ProductSearchType>(
    savedSearchParams.searchType ?? ProductSearchType.ALL,
  );
  const [searchKeyword, setSearchKeyword] = useState(savedSearchParams.searchKeyword ?? '');
  const {
    data: { content: products = [], totalElements = 0 } = {},
    isFetching: getLoading,
    refetch,
  } = useQuery(
    ['manufacturer/getProductSamples', page, searchType, searchKeyword, status],
    () => productApi.getProducts({ page, searchType, searchKeyword, status }),
    {
      select: (res) => ({
        ...res.data.result,
        content: res.data.result.content.map((product: any) => ({
          ...product,
          productDetail: product.productDetails[0],
        })),
      }),
    },
  );

  const showProductDetail = useCallback((product: any) => {
    navigate(`/manufacturer/product/${product.productId}`);
  }, []);

  const { mutate: issueAuthCode, isLoading: issueAuthCodeLoading } = useMutation(
    productApi.issueAuthCode,
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  useUpdateEffect(() => {
    navigate(pathname, {
      state: {
        searchType,
        searchKeyword,
        page,
        status,
      },
      replace: true,
    });
  }, [searchType, searchKeyword, page, status]);

  return useMemo(
    () => ({
      page,
      setPage,
      searchType,
      setSearchType,
      searchKeyword,
      setSearchKeyword,
      products,
      totalElements,
      getLoading,
      showProductDetail,
      issueAuthCode,
      issueAuthCodeLoading,
    }),
    [
      page,
      searchType,
      searchKeyword,
      products,
      totalElements,
      getLoading,
      showProductDetail,
      issueAuthCode,
    ],
  );
};

export const useAddManuProduct = () => {
  const dispatch = useDispatch();
  const { movePathname } = useMovePathname();

  const { mutate: addManuProduct } = useMutation(productApi.addProductBasic, {
    onSuccess: (res) => {
      const addedProduct = res.data.result;
      const { productId } = addedProduct;
      dispatch(productActions.manuGetDocuments(productId));
      dispatch(setCurrentProduct(addedProduct));
      movePathname({ productId });
    },
  });
  return {
    addManuProduct,
  };
};

export const useRegisterManuProduct = () => {
  const navigate = useNavigate();
  const { mutate: registerManuProduct } = useMutation(productApi.manuRegisterProduct, {
    onSuccess: () => {
      navigate(path.manufacturer.product.list);
    },
  });
  return {
    registerManuProduct,
  };
};

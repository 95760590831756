import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { DocumentCode } from 'types/product';

const notices = [
  '제품 QC(품질관리) 담당자의 영문 이력서를 PDF서류로 업로드해 주세요.',
  <MistakeNoticeItem />,
];

const ProductROPQMPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Resume of Product Quality Manager"
      notices={notices}
      exampleImg="Resume_of_Product_Quality_Manager.png"
    >
      <ProductSingleFileContainer
        documentCode={DocumentCode.ROPQM}
        formUrl="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Resume%E2%80%99+of+product+quality+manager.docx"
      />
    </ProductPageTemplate>
  );
};

export default ProductROPQMPage;

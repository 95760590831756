import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import FooterBox from 'components/FooterBox';
import { requireRule } from 'lib/validate';
import * as certificateActions from 'modules/certificate';
import * as productActions from 'modules/product';
import UpdateLog from 'components/product/UpdateLog';
import { useShelfLifeDeclaration } from 'service/brand/product/shelfLifeDeclaration';
import { DocumentCode } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import InputNumber from 'components/ui/InputNumber';

const ShelfLifeMonthModal = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const certTargetId = Number(useParams<{ certTargetId: string }>().certTargetId);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const visible = useSelector(({ certificate }: any) => certificate.shelfLifeMonthModalVisible);
  const { estimateTarget, readOnlyMode, submitLoading } = useSelector(
    ({ certificate, loading }: any) => ({
      estimateTarget: certificate.certificate.estimateTarget,
      readOnlyMode: certificate.readOnlyMode,
      submitLoading: loading['product/SET_PRODUCT_EXPIRE_MONTHS'],
    }),
    shallowEqual,
  );
  const { productId, countryId } = estimateTarget;
  const { productExpireMonths, getLoading } = useShelfLifeDeclaration({
    productId,
    countryId,
    enabled: visible,
  });
  const handleClose = () => {
    dispatch(certificateActions.toggleShelfLifeMonthModalVisible());
  };
  const handleSubmit = ({
    productExpireMonths: newProductExpireMonths,
  }: {
    productExpireMonths: number;
  }) => {
    dispatch(
      productActions.setProductExpireMonths({
        productId,
        countryId,
        productExpireMonths: newProductExpireMonths,
        certTargetId,
        updateMode: productExpireMonths !== null,
      }),
    );
  };

  useEffect(() => {
    form.setFieldsValue({ productExpireMonths });
  }, [productExpireMonths]);

  return (
    <Modal
      open={visible}
      title="제품의 유통기한 입력"
      footer={null}
      destroyOnClose={true}
      onCancel={handleClose}
    >
      <Spin spinning={getLoading}>
        <Row justify="end" gutter={8}>
          <Col>
            <ProductCorrectButton
              isFirstOpenChatModal={isFirstOpenChatModal}
              onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
              documentCode={DocumentCode.SLD}
              productId={productId}
              countryId={countryId}
            />
          </Col>
          {productExpireMonths !== null && (
            <Col>
              <UpdateLog
                productId={productId}
                countryId={countryId}
                documentCode={DocumentCode.SLD}
              />
            </Col>
          )}
        </Row>
        <Form form={form} labelCol={{ span: 12 }} onFinish={handleSubmit} style={{ marginTop: 16 }}>
          <Row gutter={8} justify="center" align="top">
            <Col>
              <Form.Item
                name="productExpireMonths"
                rules={[requireRule]}
                initialValue={productExpireMonths}
              >
                <InputNumber
                  // prefix="months"
                  maxLength={2}
                  min={1}
                  max={99}
                  disabled={readOnlyMode}
                />
              </Form.Item>
            </Col>
            <Col style={{ lineHeight: '44px' }}>months</Col>
          </Row>
          {!readOnlyMode && (
            <FooterBox>
              {productExpireMonths !== null && (
                <CorrectRequestWithoutChangeButton
                  documentCode={DocumentCode.SLD}
                  productId={productId}
                  countryId={countryId}
                />
              )}
              <Button type="primary" loading={submitLoading} htmlType="submit">
                {productExpireMonths === null ? '등록' : '보완 완료'}
              </Button>
            </FooterBox>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ShelfLifeMonthModal;

import palette from 'lib/styles/palette';
import styled, { css } from 'styled-components';
import { TypographyBlockProps, TypographyProps } from '.';

interface TextProps extends Omit<TypographyProps, 'type'> {
  hover?: boolean;
  type?: TypographyProps['type'];
  asterisk?: boolean;
}
interface TextBlockProps extends Omit<TypographyBlockProps, 'type'> {
  hover?: boolean;
  type?: TypographyProps['type'];
}

const TextBlock = styled.span<TextBlockProps>`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;

  ${({ type }) =>
    type === 'HEADLINE_1' &&
    css`
      font-size: 34px;
    `}
  ${({ type }) =>
    type === 'HEADLINE_2' &&
    css`
      font-size: 32px;
      line-height: 1.45;
    `}
    ${({ type }) =>
    type === 'TITLE_1' &&
    css`
      font-size: 24px;
    `}
    ${({ type }) =>
    type === 'TITLE_2' &&
    css`
      font-size: 18px;
      line-height: 1.45;
    `}
    ${({ type }) =>
    type === 'BODY_1' &&
    css`
      font-size: 16px;
    `}
    ${({ type }) =>
    type === 'BODY_2' &&
    css`
      font-size: 14px;
      line-height: 1.4;
    `}
    ${({ type }) =>
    type === 'SMALL' &&
    css`
      font-size: 12px;
      line-height: 1.3;
    `}
    ${({ type }) =>
    type === 'CAPTION' &&
    css`
      font-size: 10px;
      line-height: 1.2;
    `}

  ${({ gutter }) => {
    if (typeof gutter === 'number') {
      return css`
        margin: ${gutter}px;
      `;
    }
    if (typeof gutter === 'object') {
      return css`
        margin-top: ${gutter.top ? `${gutter.top}px` : 0};
        margin-bottom: ${gutter.bottom ? `${gutter.bottom}px` : 0};
        margin-left: ${gutter.left ? `${gutter.left}px` : 0};
        margin-right: ${gutter.right ? `${gutter.right}px` : 0};
      `;
    }
  }}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

    ${({ light }) =>
    light &&
    css`
      font-weight: 300;
    `}

    ${({ medium }) =>
    medium &&
    css`
      font-weight: 500;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

    ${({ semiBold }) =>
    semiBold &&
    css`
      font-weight: 600;
    `}
    
  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ asterisk }) =>
    asterisk &&
    css`
      &::before {
        content: '*';
        color: ${palette.PRIMARY50};
      }
    `}

  ${({ asteriskSize }) =>
    asteriskSize !== undefined &&
    css`
      &::before {
        font-size: ${asteriskSize + 'px'};
      }
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}
    
  ${({ color }) =>
    color &&
    css`
      color: ${palette[color]};
    `}

    span.primary {
    color: ${palette.PRIMARY50};
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ hover }) =>
    hover &&
    css`
      cursor: pointer;

      :hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    `}


    ${({ mark }) =>
    mark &&
    css`
      transform-style: preserve-3d;

      mark {
        position: relative;
        background-color: transparent;
        display: inline-block;
        padding: 0;
        transform-style: preserve-3d;

        &::after {
          content: '';
          position: absolute;
          bottom: 4px;
          left: 50%;
          display: inline-block;
          width: calc(100% + 24px);
          height: 8px;
          background-color: #b1e2ff;
          transform: translateZ(-1px) translateX(-50%);
        }
      }
    `}
`;

const Text = ({
  children,
  type = 'BODY_1',
  gutter,
  align,
  color,
  light,
  medium,
  semiBold,
  bold,
  inline,
  asterisk = false,
  mark = false,
  className,
  style,
  underline,
  hover,
  onClick,
}: TextProps) => {
  return (
    <TextBlock
      type={type}
      gutter={gutter}
      align={align}
      color={color}
      light={light}
      medium={medium}
      semiBold={semiBold}
      bold={bold}
      inline={inline}
      asterisk={asterisk}
      mark={mark}
      className={className}
      style={style}
      underline={underline}
      onClick={onClick}
      hover={hover}
    >
      {children}
    </TextBlock>
  );
};

export default Text;

import ProductAnnex14 from 'components/product/ProductAnnex14';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  'Product Formula Breakdown (China) 에 업로드한 최신 파일 기준으로 ANNEX 14를 보유한 Trade Name이 표기됩니다.',
  'Trade Name 당 1개의 ANNEX 14파일을 업로드할 수 있습니다.',
];

const ProductAnnex14Page = () => {
  return (
    <ProductPageTemplate subtitle="ANNEX 14" notices={notices}>
      <ProductAnnex14 />
    </ProductPageTemplate>
  );
};

export default ProductAnnex14Page;

import { useParams } from 'react-router-dom';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import QCQAProductUpdate from 'components/brand/qcqa/QCQAProduct/QCQAProductUpdate';
import QCQAPageTemplate from 'templates/QCQAPageTemplate';
import { useSearchParams } from 'hook/useSearchParams';
import path from 'lib/path';

const notices = [
  '화장품책임판매업자의 주소가 잘못된 경우, 마이페이지 > 회원정보 수정에서 확인하실 수 있습니다.',
  '제품 서류는 개정이력으로 관리되며 항목 수정은 마이페이지 > 공통 서류 관리에서 가능합니다.',
  '인증 활용서류는 영문 자료와 함께 등록해 두시면  해외인증 서비스 진행 시 간편하게 사용할 수 있습니다.',
  <MistakeNoticeItem />,
];

const QCQAProductUpdatePage = () => {
  const { qcqaProductId: sQcqaProductId = '0' } = useParams<{
    qcqaProductId?: string;
  }>();
  const qcqaProductId = Number(sQcqaProductId);

  const { searchParams } = useSearchParams({
    path: `${path.qcqa.management.root}/${qcqaProductId}`,
  });

  const { tab, qcqaUserDocumentId }: { tab: 'basic' | 'document'; qcqaUserDocumentId: number } = {
    tab: (searchParams.get('tab') as 'basic' | 'document') || 'basic',
    qcqaUserDocumentId: Number(searchParams.get('qcqaUserDocumentId')) || 0,
  };

  return (
    <QCQAPageTemplate
      title="품질 제품 관리"
      notices={notices}
      back={tab === 'document' ? false : true}
    >
      <QCQAProductUpdate
        initialTab={tab || 'basic'}
        qcqaProductId={qcqaProductId}
        qcqaUserDocumentId={qcqaUserDocumentId}
      />
    </QCQAPageTemplate>
  );
};

export default QCQAProductUpdatePage;

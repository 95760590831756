import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import client from 'lib/api/client';
import { createFormData, downloadFile, getFilenameFromUrl } from 'lib/file';
import { apiUrls, useBasicMutation, useBasicQuery } from 'service/core';
import { APIPageableResponse, APIResponse } from 'types/common';
import {
  ChinaIngredient,
  DangerInfo,
  EtcData,
  NMPABATCategory,
  NMPABATParam,
  NMPABATRegisterParams,
  NMPABasicInfo,
  NMPABasicInfoStatusResponse,
  NMPABasicInfosParams,
  NMPAChatMessage,
  NMPAChecklistItem,
  NMPACompanyInfo,
  NMPAContractStatus,
  NMPACorrectRequestMsg,
  NMPADetailItem,
  NMPADetailUseGuide,
  NMPADocumentCode,
  NMPAEstimate,
  NMPAEvaluationData,
  NMPAMaterialListItem,
  NMPAMaterialProgressStatus,
  NMPAMaterialUsePurpose,
  NMPAOngoingDocsResponse,
  NMPAOngoingMaterial,
  NMPARegisteredListItem,
  NMPARequestListItem,
  NMPATempStoreType,
  UpdateNMPADangerInfosParams,
  UpdateNMPAEtcDataParams,
  UpdateNMPAEvaluationDataParams,
} from 'types/material/nmpa/nmpa';

export interface ChekclistItem {
  countryChecklistId: number;
  questionMsg: string;
  questionSubMsg: string;
  yesNextId: number | null;
  noNextId: number | null;
  noMsg: string | null;
  answer?: boolean;
}

const queryKeys = {
  nmpaChecklistKey: 'nmpa/check-list',
  nmpaMaterialCountsKey: 'nmpa/material-counts',
  nmpaRequestListKey: 'nmpa/request-list',
  nmpaBasicKey: 'nmpa/getNMPABasic',
  nmpaBATKey: 'nmpa/getNMPABAT',
  nmpaBATCategoryKey: 'nmpa/getNMPABATCategory',
  nmpaCompanyInfoTypeKey: 'nmpa/company-info-type',
  nmpaCompanyInfoKey: 'nmpa/company-info',
  nmpaRegisteredListKey: 'nmpa/registered',
  nmpaMaterialListKey: 'nmpa/material-list',
  nmpaBasicInfo: 'nmpa/basic-info',
  nmpaMaterialUsePurposesKey: 'nmpa/material-use-purposes',
  nmpaDetailKey: 'nmpa/detail',
  nmpaTempStoreKey: 'nmpa/temp',
  nmpaOngoingDocs: 'nmpa/ongoing-docs',
  nmpaOngoingMaterials: 'nmpa/ongoing-materials',
  nmpaChinaIngredients: 'nmpa/china-ingredients',
  nmpaEvaluationData: 'nmpa/evaluation-data',
  nmpaEtcData: 'nmpa/etc-data',
  nmpaDangerInfos: 'nmpa/danger-infos',
  nmpaDocStatus: 'nmpa/doc-status',
  nmpaEstimateRawMaterialCount: 'nmpa/raw-material-count',
  nmpaDetailUseGuide: 'nmpa/detail/use-guide',
};

export const useNMPAChecklist = () => {
  const [checklist, setChecklist] = useState<ChekclistItem[]>([]);

  const { data: originalChecklist = [], isLoading: getChecklistLoading } = useQuery(
    queryKeys.nmpaChecklistKey,
    () => client.get<APIResponse<NMPAChecklistItem[]>>('/material/nmpa/checklists'),
    { select: (res) => res.data.result },
  );

  const { mutate: getNMPAEstimate, isLoading: getNMPAEstimateLoading } = useMutation(
    (params: NMPAEstimate) =>
      client.post<
        APIResponse<{
          materialNmpaEstimateId: number;
          estimateFileUrl: string;
        }>
      >(`/material/nmpa/estimates`, params),
  );

  const makeChecklistItem = (arr: NMPAChecklistItem[]) =>
    arr.map((item) => {
      const {
        country,
        countryId,
        answerType,
        yesType,
        noType,
        yesMsg,
        yesGoodsAdditionalId,
        noGoodsAdditionalId,
        targetCosmeticCategoryId,
        ...rest
      } = item;

      return rest;
    });

  useEffect(() => {
    if (originalChecklist.length > 0) {
      setChecklist([makeChecklistItem(originalChecklist)[0]]);
    }
  }, [originalChecklist]);

  return {
    checklist,
    setChecklist,
    originalChecklist,
    getChecklistLoading,
    makeChecklistItem,
    getNMPAEstimate,
    getNMPAEstimateLoading,
  };
};

export const useNMPAMaterialCounts = () => {
  const { data: nmpaMaterialCounts } = useQuery(
    queryKeys.nmpaChecklistKey,
    () =>
      client.get<
        APIResponse<{
          totalCount: number;
          ongoingCount: number;
          finishCount: number;
        }>
      >(`/material/nmpa/summaries`),
    {
      select: (res) => res.data.result,
    },
  );

  return { nmpaMaterialCounts };
};

export const useNMPARequestList = ({
  contractStatus,
  page,
}: {
  contractStatus: NMPAContractStatus;
  page: number;
}) => {
  const queryClient = useQueryClient();
  const { data: { content: nmpaRequestListItems = [], totalElements: total = 0 } = {}, isLoading } =
    useQuery(
      [queryKeys.nmpaRequestListKey, page, contractStatus],
      () =>
        client.get<APIPageableResponse<NMPARequestListItem[]>>(
          `/material/nmpa/acquisition-requests`,
          {
            params: {
              page,
              status: contractStatus,
              size: 10,
            },
          },
        ),
      { select: (res) => res.data.result },
    );

  const { mutate: deleteNMPARequest, isLoading: isDeleteLoading } = useMutation(
    (materialNmpaEstimateIds: number[]) => {
      return client.delete<APIResponse<null>>(
        `/material/nmpa/estimates/${materialNmpaEstimateIds.join(',')}`,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.nmpaRequestListKey);
        message.success('삭제되었습니다.');
      },
    },
  );

  return {
    nmpaRequestListItems,
    total,
    isLoading,
    deleteNMPARequest,
    isDeleteLoading,
  };
};

export const useNMPAContract = () => {
  const { mutate: addNMPAContract, isLoading } = useMutation(
    ({
      materialNmpaEstimateId,
      postAddress,
      isAgreeContract,
    }: {
      materialNmpaEstimateId: number;
      postAddress?: string;
      isAgreeContract: boolean;
    }) =>
      client.post<APIResponse<null>>(
        `/material/nmpa/estimates/${materialNmpaEstimateId}/contracts`,
        {
          postAddress,
          isAgreeContract,
        },
      ),
  );

  return { addNMPAContract, isLoading };
};

export const useNMPAMaterialList = ({
  progressStatus = NMPAMaterialProgressStatus.INPUTTING,
  searchValue,
  page,
}: {
  progressStatus: NMPAMaterialProgressStatus;
  searchValue?: string;
  page: number;
}) => {
  const { data: { content: nmpaMaterialList = [], totalElements: total = 0 } = {}, isLoading } =
    useQuery(
      [queryKeys.nmpaMaterialListKey, page, progressStatus, searchValue],
      () =>
        client.get<APIPageableResponse<NMPAMaterialListItem[]>>(`/material/nmpa/raw-informations`, {
          params: {
            page,
            isOngoing: progressStatus === NMPAMaterialProgressStatus.INPUTTING,
            tradeName: searchValue,
            size: 5,
          },
        }),
      { select: (res) => res.data.result },
    );
  return {
    nmpaMaterialList,
    total,
    isLoading,
  };
};

export const useNMPACompanyInfo = () => {
  const { data: nmpaCompanyInfo, isLoading: getNMPACompanyInfoLoading } = useQuery(
    queryKeys.nmpaCompanyInfoKey,
    () => client.get<APIResponse<NMPACompanyInfo | null>>(`/material/nmpa/company-infos`),
    { select: (res) => res.data.result },
  );

  const { data: nmpaCompanyInfoType, isLoading: getNMPACompanyInfoTypeLoadingQuery } = useQuery(
    queryKeys.nmpaCompanyInfoTypeKey,
    () =>
      client.get<APIResponse<{ isProxy: boolean; isDirect: boolean }>>(
        `/material/nmpa/company-infos/type`,
      ),
    { select: (res) => res.data.result },
  );

  return {
    nmpaCompanyInfo,
    nmpaCompanyInfoType,
    isLoading: getNMPACompanyInfoLoading || getNMPACompanyInfoTypeLoadingQuery,
  };
};

export const useNMPAAuthorization = () => {
  const queryClient = useQueryClient();

  const { mutate: addNMPAAuthorization, isLoading } = useMutation(
    (formData: FormData) =>
      client.post<APIResponse<null>>(`/material/nmpa/company-infos/proxy`, formData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.nmpaCompanyInfoKey);
      },
    },
  );

  return { addNMPAAuthorization, isLoading };
};

export const useNMPAPlatformAccount = () => {
  const queryClient = useQueryClient();

  const { mutate: addNMPAPlatformAccount, isLoading } = useMutation(
    (params: { platformUsername: string; platformPassword: string }) =>
      client.post<APIResponse<null>>(`/material/nmpa/company-infos/direct`, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.nmpaCompanyInfoKey);
      },
    },
  );

  return { addNMPAPlatformAccount, isLoading };
};

export const useNMPACompanyInfoFileUrl = () => {
  const {
    mutate: generateCompanyInformationFileUrl,
    isLoading: generateCompanyInformationFileUrlLoading,
  } = useMutation(
    (params: { zipcode: string; managerName: string }) =>
      client.post<APIResponse<{ companyInformationFileUrl: string }>>(
        '/material/nmpa/company-infos/generate-company-information',
        params,
      ),
    {
      onSuccess: async ({
        data: {
          result: { companyInformationFileUrl },
        },
      }) => {
        const filename = await getFilenameFromUrl(companyInformationFileUrl);
        downloadFile(companyInformationFileUrl, filename);
      },
    },
  );

  const {
    mutate: generateLetterOfAuthorizationFileUrl,
    isLoading: generateLetterOfAuthorizationFileUrlLoading,
  } = useMutation(
    (params: { zipcode: string; managerName: string }) =>
      client.post<APIResponse<{ letterOfAuthorizationFileUrl: string }>>(
        '/material/nmpa/company-infos/generate-letter-of-authorization',
        params,
      ),
    {
      onSuccess: ({
        data: {
          result: { letterOfAuthorizationFileUrl },
        },
      }) => {
        downloadFile(
          letterOfAuthorizationFileUrl,
          getFilenameFromUrl(letterOfAuthorizationFileUrl),
        );
      },
    },
  );

  return {
    generateCompanyInformationFileUrl,
    generateLetterOfAuthorizationFileUrl,
    generateCompanyInformationFileUrlLoading,
    generateLetterOfAuthorizationFileUrlLoading,
  };
};

export const useNMPABAT = (nmpaBasicInfoId: number) => {
  const queryClient = useQueryClient();

  const { data: nmpaBATCategory = [], isLoading: getNMPABATCategoryLoading } = useQuery(
    [queryKeys.nmpaBATCategoryKey],
    () => client.get<APIResponse<NMPABATCategory[]>>(`/material/nmpa/material-categories`),
    { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
  );

  const { data: nmpaBAT = [], isLoading: getNMPABATLoading } = useQuery(
    [queryKeys.nmpaBATKey, nmpaBasicInfoId],
    () =>
      client.get<APIResponse<NMPABATParam[]>>(
        `/material/nmpa/basic-infos/${nmpaBasicInfoId}/attributes`,
      ),
    { select: (res) => res.data.result },
  );

  const { mutate: addNMPABAT, isLoading: addLoading } = useMutation(
    (params: NMPABATRegisterParams[]) =>
      client.put<APIResponse<null>>(
        `/material/nmpa/basic-infos/${nmpaBasicInfoId}/attributes`,
        params,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.nmpaBATKey);
      },
    },
  );

  return {
    nmpaBATCategory,
    nmpaBAT,
    getLoading: getNMPABATCategoryLoading || getNMPABATLoading,
    addNMPABAT,
    addLoading,
  };
};

export const useNMPAFormattedBasicInfoAttributes = ({
  batCategories,
}: {
  batCategories: NMPABATCategory[];
}) => {
  const { categoryMap, collapsableCategoryIds } = useMemo(
    () => generateBasicInfoAttributeCategoryMap(batCategories),
    [batCategories],
  );

  return { categoryMap, collapsableCategoryIds };
};

const generateBasicInfoAttributeCategoryMap = (categories: NMPABATCategory[]) => {
  const collabsableList: string[] = [
    'NMPA_MAIN_INGREDIENT_BASIC_FEATURE001',
    'NMPA_MAIN_INGREDIENT_BASIC_FEATURE002',
    'NMPA_MAIN_INGREDIENT_BASIC_FEATURE003',
    'NMPA_RAW_MATERIAL_SOURCE002',
    'NMPA_RAW_MATERIAL_SOURCE004',
    'NMPA_RAW_MATERIAL_SOURCE007',
    'NMPA_RAW_MATERIAL_PRODUCTION_METHOD007',
    'NMPA_RAW_MATERIAL_PRODUCTION_METHOD008',
    'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE003',
    'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE004',
    'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE005',
  ];
  const collapsableCategoryIds: { [key: string]: number } = {};
  const categoryMap: {
    [key: string]: number[];
  } = {};

  for (const category of categories) {
    const lastWords = category.code.slice(category.code.length - 3);
    // 서브 메뉴
    if (lastWords.startsWith('0')) {
      const firstCategory = category.code.slice(0, category.code.length - 3);
      // 분류명으로 속성 찾기
      if (!categoryMap[firstCategory]) categoryMap[firstCategory] = [];

      categoryMap[firstCategory].push(category.materialCategoryId);

      if (collabsableList.includes(category.code)) {
        collapsableCategoryIds[category.code] = category.materialCategoryId;
      }
    }
  }

  return {
    categoryMap,
    collapsableCategoryIds,
  };
};

export const useNMPAOngoingDocs = ({ materialId }: { materialId?: number }) => {
  const { data, isLoading } = useQuery(
    [queryKeys.nmpaOngoingDocs, materialId],
    () =>
      client.get<APIResponse<NMPAOngoingDocsResponse>>(
        `/v1/material/nmpa/basic-infos/${materialId}/ongoing-docs`,
      ),
    {
      select: (res) => res.data.result,
      enabled: materialId !== undefined,
    },
  );

  return {
    nmpaOngoingDocs: data?.ongoingDocs || [],
    basicInfoStatus: data?.basicInfoStatus || '',
    materialNmpaBasicInfoId: data?.materialNmpaBasicInfoId,
    isLoading,
  };
};

export const useNMPARegistered = ({ page, tradeName }: { page: number; tradeName: string }) => {
  const {
    data: { content: nmpaRegisteredListItems = [], totalElements: total = 0 } = {},
    isLoading,
  } = useQuery(
    [queryKeys.nmpaRegisteredListKey, page, tradeName],
    () =>
      client.get<APIPageableResponse<NMPARegisteredListItem[]>>(
        `/material/nmpa/basic-infos/finish-info`,
        {
          params: {
            page,
            size: 10,
            tradeName,
          },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return {
    nmpaRegisteredListItems,
    total,
    isLoading,
  };
};

export const useNMPAOngoingMaterials = ({
  tradeName,
  page,
}: {
  tradeName?: string;
  page: number;
}) => {
  const { data: { content: nmpaOngoingMaterials = [], totalElements: total } = {}, isLoading } =
    useQuery(
      [queryKeys.nmpaOngoingMaterials, tradeName, page],
      () =>
        client.get<APIPageableResponse<NMPAOngoingMaterial[]>>(
          `/v1/material/nmpa/basic-infos/ongoing-docs`,
          {
            params: {
              page,
              tradeName,
              size: 10,
            },
          },
        ),
      {
        select: (res) => res.data.result,
      },
    );

  return { nmpaOngoingMaterials, total, isLoading };
};

export const useNMPAChinaIngredients = () => {
  const { data: nmpaChinaIngredients, isLoading } = useQuery(
    [queryKeys.nmpaChinaIngredients],
    () => client.get<APIResponse<ChinaIngredient[]>>(`/v1/china-ingredients`),
    {
      select: (res) => {
        // HINT: WATER를 맨위로
        res.data.result.sort((a) => (a.nameEn === 'WATER' ? -1 : 0));
        return res.data.result;
      },
      staleTime: Number.MAX_VALUE,
    },
  );

  return {
    nmpaChinaIngredients,
    isLoading,
  };
};

export const useNMPABasicOld = (materialId?: number) => {
  const { data: nmpaBasicInfo, isLoading: isReadNMPABasicInfoLoading } = useQuery(
    [queryKeys.nmpaBasicInfo, materialId],
    () => client.get<APIResponse<NMPABasicInfo>>(`/material/nmpa/basic-infos/${materialId}`),
    {
      enabled: materialId !== undefined,
      select: (res) => res.data.result,
    },
  );

  const { mutate: createNMPABasicInfo, isLoading: isCreateNMPABasicInfoLoading } = useMutation(
    (params: NMPABasicInfosParams) =>
      client.post<APIResponse<number>>(`/v1/material/nmpa/basic-infos`, params),
  );

  const { mutate: updateNMPABasicInfo, isLoading: isUpdateNMPABasicInfoLoading } = useMutation(
    ({ materialId, ...params }: NMPABasicInfosParams & { materialId: number }) =>
      client.patch(`/v1/material/nmpa/basic-infos/${materialId}`, params),
  );

  return {
    createNMPABasicInfo,
    isCreateNMPABasicInfoLoading,
    updateNMPABasicInfo,
    isUpdateNMPABasicInfoLoading,
    nmpaBasicInfo,
    isReadNMPABasicInfoLoading,
  };
};

export const useNMPATradeNameDuplicateCheck = () => {
  const { mutate: duplicateCheck } = useMutation(({ tradeName }: { tradeName: string }) =>
    client.get<APIResponse<boolean>>('/v1/material/nmpa/basic-infos/duplicate-checks', {
      params: {
        tradeName: encodeURIComponent(tradeName),
      },
    }),
  );

  return {
    duplicateCheck,
  };
};

export const useNMPAMaterialUsePurposes = () => {
  const { data: nmpaMaterialUsePurposes = [], isLoading: getNMPAMaterialUsePurposesLoading } =
    useQuery(
      queryKeys.nmpaMaterialUsePurposesKey,
      () =>
        client.get<APIResponse<NMPAMaterialUsePurpose[]>>(`/material/nmpa/material-use-purposes`),
      { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
    );

  return { nmpaMaterialUsePurposes, getNMPAMaterialUsePurposesLoading };
};

export const useNMPADetail = (nmpaBasicInfoId: number) => {
  const queryClient = useQueryClient();

  const { data: nmpaDetail = null, isLoading: getNMPADetailLoading } = useQuery(
    [queryKeys.nmpaDetailKey, nmpaBasicInfoId],
    () =>
      client.get<APIResponse<NMPADetailItem>>(
        `/material/nmpa/basic-infos/${nmpaBasicInfoId}/details`,
      ),
    { select: (res) => res.data.result },
  );

  const { mutate: addNMPADetail, isLoading: addNMPADetailLoading } = useMutation(
    (params: NMPADetailItem) =>
      client.put<APIResponse<null>>(
        `/material/nmpa/basic-infos/${nmpaBasicInfoId}/details`,
        params,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.nmpaDetailKey);
      },
    },
  );

  return {
    nmpaDetail,
    getNMPADetailLoading,
    addNMPADetail,
    addNMPADetailLoading,
  };
};

export const useNMPATempStore = ({
  type,
  materialNmpaBasicInfoId,
}: {
  type: NMPATempStoreType;
  materialNmpaBasicInfoId: number;
}) => {
  const { data: nmpaTempStore, isLoading } = useQuery(
    [queryKeys.nmpaTempStoreKey, materialNmpaBasicInfoId, type],
    () =>
      client.get<APIResponse<string>>(`/material/nmpa/temp-stores/${materialNmpaBasicInfoId}`, {
        params: {
          type,
        },
      }),
    { select: (res) => res.data.result },
  );

  const { mutate: addNMPATempStore, isLoading: addNMPATempStoreLoading } = useMutation(
    (tempData: string) =>
      client.put<APIResponse<null>>(`/material/nmpa/temp-stores/${materialNmpaBasicInfoId}`, {
        tempData,
        type,
      }),
  );

  return {
    nmpaTempStore,
    isLoading,
    addNMPATempStore,
    addNMPATempStoreLoading,
  };
};

export const useNMPAEvaluationData = (materialId?: number) => {
  const queryClient = useQueryClient();
  const { data: evaluationData = [], isLoading: isReadNMPAEvaluationLoading } = useQuery(
    [queryKeys.nmpaEvaluationData, materialId],
    () =>
      client.get<APIResponse<NMPAEvaluationData[]>>(
        `/v1/material/nmpa/basic-infos/${materialId}/evaluation-datas`,
      ),
    {
      enabled: materialId !== undefined,
      select: (res) => res.data.result,
    },
  );

  const { mutate: updateNMPAEvaluationData, isLoading: isUpdateNMPAEvaluationDataLoading } =
    useMutation(
      ({ materialId, ...params }: UpdateNMPAEvaluationDataParams & { materialId: number }) =>
        client.post<APIResponse<null>>(
          `/v1/material/nmpa/basic-infos/${materialId}/evaluation-datas`,
          createFormData(params),
        ),
      {
        onSuccess: () => {
          queryClient.refetchQueries([queryKeys.nmpaEvaluationData, materialId]);
        },
      },
    );

  return {
    updateNMPAEvaluationData,
    isUpdateNMPAEvaluationDataLoading,
    evaluationData,
    isReadNMPAEvaluationLoading,
  };
};

export const useNMPAEtcData = (materialId?: number) => {
  const queryClient = useQueryClient();
  const { data: etcData = [], isLoading: isReadNMPAEtcDataLoading } = useQuery(
    [queryKeys.nmpaEtcData, materialId],
    () =>
      client.get<APIResponse<EtcData[]>>(`/v1/material/nmpa/basic-infos/${materialId}/etc-datas`),
    {
      enabled: materialId !== undefined,
      select: (res) => res.data.result,
    },
  );

  const { mutate: updateNMPAEtcData, isLoading: isUpdateNMPAEtcDataLoading } = useMutation(
    ({ materialId, ...params }: UpdateNMPAEtcDataParams & { materialId: number }) =>
      client.post<APIResponse<number>>(
        `/v1/material/nmpa/basic-infos/${materialId}/etc-datas`,
        createFormData(params),
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries([queryKeys.nmpaEtcData, materialId]);
      },
    },
  );

  return {
    etcData,
    isReadNMPAEtcDataLoading,
    updateNMPAEtcData,
    isUpdateNMPAEtcDataLoading,
  };
};

export const useNMPADangerInfos = (materialId?: number) => {
  const queryClient = useQueryClient();

  const { data: dangerInfos = null, isLoading: isReadNMPADangerInfosLoading } = useQuery(
    [queryKeys.nmpaDangerInfos, materialId],
    () =>
      client.get<APIResponse<DangerInfo>>(
        `/v1/material/nmpa/basic-infos/${materialId}/danger-infos`,
      ),
    {
      enabled: materialId !== undefined,
      select: (res) => res.data.result,
    },
  );

  const { mutate: updateNMPADangerInfos, isLoading: isUpdateNMPADangerInfosLoading } = useMutation(
    ({ materialId, ...params }: UpdateNMPADangerInfosParams & { materialId: number }) =>
      client.put<APIResponse<number>>(
        `/v1/material/nmpa/basic-infos/${materialId}/danger-infos`,
        params,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.nmpaDangerInfos);
      },
    },
  );

  return {
    dangerInfos,
    isReadNMPADangerInfosLoading,
    updateNMPADangerInfos,
    isUpdateNMPADangerInfosLoading,
  };
};

export const useNMPABasicInfoComplete = () => {
  const queryClient = useQueryClient();

  const { mutate: completeBasicInfo, isLoading } = useMutation(
    (materialId: number) => client.post(`/v1/material/nmpa/basic-infos/${materialId}/complete`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.nmpaOngoingDocs);
      },
    },
  );

  return {
    completeBasicInfo,
    isLoading,
  };
};

export const useNMPAAcquisition = () => {
  const queryClient = useQueryClient();
  const { mutate: acquisition, isLoading } = useMutation(
    ({ materialId, isAccountProxy }: { materialId: number; isAccountProxy: boolean }) =>
      client.post(`/v1/material/nmpa/raw-informations/${materialId}/acquisition-applications`, {
        isAccountProxy,
      }),
    {
      onSuccess: () => {
        message.success('취득 신청이 완료되었습니다.');
        queryClient.refetchQueries([queryKeys.nmpaMaterialListKey]);
      },
    },
  );

  return {
    acquisition,
    isLoading,
  };
};

export const useNMPABasicInfoStatus = ({
  materialId,
  documentCode,
  enabled = false,
}: {
  materialId?: number;
  documentCode: string;
  enabled: boolean;
}) => {
  const { data: basicInfoStatus, isLoading } = useQuery(
    [queryKeys.nmpaDocStatus, materialId, documentCode, enabled],
    () =>
      client.get<APIResponse<NMPABasicInfoStatusResponse | null>>(
        `/v1/material/nmpa/basic-infos/${materialId}/statuses?documentCode=${documentCode.toUpperCase()}01`,
      ),
    {
      enabled,
      select: (res) => res.data.result,
    },
  );

  return {
    basicInfoStatus,
    isLoading,
  };
};

export const useNMPAEstimateRawMaterialCount = ({
  materialNmpaEstimateId,
}: {
  materialNmpaEstimateId: number;
}) => {
  const { data } = useQuery(
    queryKeys.nmpaEstimateRawMaterialCount,
    () =>
      client.get<
        APIResponse<{
          rawMaterialCount: number;
        }>
      >(`/material/nmpa/estimates/${materialNmpaEstimateId}/raw-material-count`),
    {
      select: (res) => res.data.result,
    },
  );

  return { rawMaterialCount: data ? data.rawMaterialCount : 0 };
};

export const useNMPADetailUseGuideInfo = ({
  materialNmpaBasicInfoId,
}: {
  materialNmpaBasicInfoId: number;
}) => {
  const { data: nmpaDetailUseGuideInfo, isLoading: isNmpaDetailUseGuideInfoLoading } = useQuery(
    [queryKeys.nmpaDetailUseGuide, materialNmpaBasicInfoId],
    () =>
      client.get<APIResponse<NMPADetailUseGuide[]>>(
        `/material/nmpa/basic-infos/${materialNmpaBasicInfoId}/recommend-usage`,
      ),
    {
      select: (res) => res.data.result,
    },
  );
  return {
    nmpaDetailUseGuideInfo,
    isNmpaDetailUseGuideInfoLoading,
  };
};

// NMPA 보완 메시지 조회
export const useNMPAChatMessages = ({
  nmpaBasicInfoId,
  documentCode,
}: {
  nmpaBasicInfoId?: number;
  documentCode: NMPADocumentCode;
}) => {
  const [emptyArray] = useState([]);

  // nmpaDocStatusId 조회
  const { data: nmpaDocStatusId } = useBasicQuery<number>({
    apiUrl: apiUrls.getNMPADocStatusId,
    urlParams: {
      ...(nmpaBasicInfoId && { nmpaBasicInfoId }),
      documentCode,
    },
    option: {
      enabled: nmpaBasicInfoId !== undefined,
    },
  });

  const { data: nmpaChatMessages = emptyArray, isLoading: nmpaChatMessagesLoading } = useBasicQuery<
    NMPAChatMessage[]
  >({
    apiUrl: apiUrls.nmpaChatMessages,
    qs: {
      nmpaBasicInfoId,
      nmpaDocStatusId,
    },
    option: {
      enabled: nmpaBasicInfoId !== undefined && nmpaDocStatusId !== undefined,
    },
  });

  return { nmpaChatMessages, nmpaChatMessagesLoading };
};

// NMPA 보완 메시지 보내기
export const useNMPACorrectRequestChat = ({
  materialNmpaDocThreadId,
}: {
  materialNmpaDocThreadId: number;
}) => {
  const { mutate: nmpaCorrectRequestChat, isLoading: nmpaCorrectRequestChatLoading } =
    useBasicMutation<NMPACorrectRequestMsg>({
      apiUrl: apiUrls.nmpaCorrectRequestChat,
      urlParams: { materialNmpaDocThreadId },
      useFormData: true,
      refetchUrl: apiUrls.nmpaChatMessages,
    });

  return {
    nmpaCorrectRequestChat,
    nmpaCorrectRequestChatLoading,
  };
};

// NMPA 변경 없이 보완 완료
export const useNMPACorrectWithNoUpdate = ({
  materialNmpaBasicInfoId,
  documentCode,
}: {
  materialNmpaBasicInfoId?: number;
  documentCode: NMPADocumentCode;
}) => {
  const {
    mutate: nmpaCorrectRequestWithNoUpdate,
    isLoading: nmpaCorrectRequestWithNoUpdateLoading,
  } = useBasicMutation({
    method: 'patch',
    apiUrl: apiUrls.nmpaCorrectRequestWithNoUpdate,
    urlParams: {
      ...(materialNmpaBasicInfoId && { materialNmpaBasicInfoId }),
      documentCode,
    },
  });

  return {
    nmpaCorrectRequestWithNoUpdate,
    nmpaCorrectRequestWithNoUpdateLoading,
  };
};

// NMPA 보완 변경사항 저장
export const useNMPAOverwriteHistory = ({
  nmpaBasicInfoId,
  documentCode,
}: {
  nmpaBasicInfoId?: number;
  documentCode: NMPADocumentCode;
}) => {
  const getNewDocumentCode = () => {
    switch (documentCode) {
      case NMPADocumentCode.BASIC:
        return 'BASIC_INFO';
      case NMPADocumentCode.BAT:
        return 'BASIC_ATTRIBUTE';
      case NMPADocumentCode.DETAIL:
        return 'DETAIL_INFO';
      case NMPADocumentCode.DIF:
        return 'DANGER_INFO';
      case NMPADocumentCode.EDT:
        return 'EVALUATION_DATA';
      case NMPADocumentCode.ESD:
        return 'ETC_SUBMIT_DATA';
    }
  };

  const { mutate: nmpaOverwriteHistory, isLoading: nmpaOverwriteHistoryLoading } =
    useBasicMutation<any>({
      method: 'put',
      apiUrl: apiUrls.nmpaOverwriteHistory,
      urlParams: {
        ...(nmpaBasicInfoId && { nmpaBasicInfoId }),
        documentCode: getNewDocumentCode(),
      },
      customBodyData: (params) => ({ historyData: JSON.stringify(params) }),
    });

  return { nmpaOverwriteHistory, nmpaOverwriteHistoryLoading };
};

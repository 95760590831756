import { Button, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';

import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { generateFormRules, requireRule } from 'lib/validate';
import { PasswordInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import { FDAAccountItem } from 'types/product';
import { useFDAAccount } from 'service/brand/product/product';
import { useEffect } from 'react';

export interface FDAAccountAddModalProps {
  onClose?: () => void;
  fdaAccount?: FDAAccountItem;
}

const FDAAccountAddModal = ({ fdaAccount, onClose }: FDAAccountAddModalProps) => {
  const [form] = Form.useForm<Omit<FDAAccountItem, 'fdaAccountId'>>();

  const { addFDAAccount, updateFDAAccount, addFDAAccountLoading, updateFDAAccountLoading } =
    useFDAAccount({ fdaAccountId: fdaAccount?.fdaAccountId });

  useEffect(() => {
    if (fdaAccount) {
      form.setFieldsValue(fdaAccount);
    }
  }, [fdaAccount]);

  const handleSubmit = (formValues: Omit<FDAAccountItem, 'fdaAccountId'>) => {
    if (!fdaAccount) {
      addFDAAccount(formValues, {
        onSuccess: () => {
          message.success('추가되었습니다.');
          onClose?.();
        },
      });
    } else if (
      formValues.fdaAccount !== fdaAccount.fdaAccount ||
      formValues.fdaPassword !== fdaAccount.fdaPassword
    ) {
      updateFDAAccount(
        {
          fdaAccount: formValues.fdaAccount,
          fdaPassword: formValues.fdaPassword,
        },
        {
          onSuccess: () => {
            message.success('변경되었습니다.');
            onClose?.();
          },
        },
      );
    } else {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }
  };

  return (
    <FDAAccountAddModalContainer>
      <Modal open={true} destroyOnClose footer={null} onCancel={onClose} centered width={528}>
        <Typography.Text type="TITLE_1" style={{ marginBottom: 4 }}>
          계정 정보 {fdaAccount === null ? '입력' : '변경'}
        </Typography.Text>
        <Form
          form={form}
          style={{ marginTop: 32 }}
          layout="vertical"
          scrollToFirstError={scrollToFirstErrorOption}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="사용자명 (ID)"
            name="fdaAccount"
            required
            rules={generateFormRules({
              required: true,
              exceptKorean: true,
            })}
          >
            <Input placeholder="ID 입력" />
          </Form.Item>
          <Form.Item
            label="비밀번호"
            name="fdaPassword"
            rules={[
              requireRule,
              {
                pattern: /^.{3,35}$/i,
                message: '3~35자',
              },
            ]}
          >
            <PasswordInput placeholder="비밀번호 입력" autoComplete="new-password" maxLength={35} />
          </Form.Item>
          <Flex style={{ paddingTop: 32 }} gap={8} justify="center">
            <Button style={{ width: '57px' }} htmlType="button" onClick={onClose}>
              취소
            </Button>
            <Button
              style={{ width: '57px' }}
              type="primary"
              htmlType="submit"
              loading={addFDAAccountLoading || updateFDAAccountLoading}
            >
              {fdaAccount === null ? '입력' : '변경'}
            </Button>
          </Flex>
        </Form>
      </Modal>
    </FDAAccountAddModalContainer>
  );
};

const FDAAccountAddModalContainer = styled.div`
  .ant-modal-body {
    padding: 24px;
  }
`;

export default FDAAccountAddModal;

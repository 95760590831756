import { memo } from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';

import { requireRule, exceptKoreanRule } from 'lib/validate';
import AlphabetLabel from './AlphabetLabel';

const MSDSHandlingStorageBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSHandlingStorage = () => {
  return (
    <MSDSHandlingStorageBlock>
      <Form.Item
        label={
          <AlphabetLabel alphabet="A" style={{ width: 538 }}>
            Precautions for safe handling
          </AlphabetLabel>
        }
        name={['handlingStorage', 'precautionsForSafeHandling']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Use normal good handling procedures."
      >
        <Input.TextArea
          placeholder="안전취급요령"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={<AlphabetLabel alphabet="B">Conditions for safe storage</AlphabetLabel>}
        name={['handlingStorage', 'conditionsForSafeStorage']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="Keep containers tightly closed, away from heat and light and store at a cool and dry place."
      >
        <Input.TextArea
          placeholder="안전한 저장 방법(피해야 할 조건을 포함)"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
    </MSDSHandlingStorageBlock>
  );
};

export default memo(MSDSHandlingStorage);

import ProductSamplePageTemplate from 'templates/manufacturer/ProductSamplePageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSampleRecipe from 'components/manufacturer/productSample/ProductSampleRecipe';

const notices = [
  '샘플로 등록하고자 하는 처방을 엑셀 파일로 업로드 후  처방 저장을 클릭해 주세요.',
  '동일한 랩넘버 내에서 변경사항이 있는 경우, 여러 번 처방 저장을 하여 히스토리로 관리하실 수 있습니다.',
  '저장된 처방의 관리나 처방 히스토리 보기는 본인만 할 수 있습니다.',
  <MistakeNoticeItem />,
];

const ProductSampleRecipePage = () => {
  return (
    <ProductSamplePageTemplate notices={notices} subtitle="처방" exampleImg="Recipe.png">
      <ProductSampleRecipe />
    </ProductSamplePageTemplate>
  );
};

export default ProductSampleRecipePage;

import { Button, Space, Upload, message } from 'antd';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import FileViewer from 'components/file/FileViewer';
import Typography from 'components/system/general/Typography';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';

const FileUpload = ({
  value,
  onChange,
  readOnly = false,
  isView = true,
  accept = '.pdf',
  icon = <Icon name="upload" color="PRIMARY50" size={18} />,
  text = '파일 선택',
  bodyStyle,
  multiple = false,
  max,
  deletable = false,
}: {
  value?: File | File[] | { name: string; url: string };
  onChange?: (e: ChangeEvent<any>) => void;
  readOnly: boolean;
  isView?: boolean;
  accept?: string;
  icon?: ReactNode;
  text?: string;
  bodyStyle?: React.CSSProperties;
  multiple?: boolean;
  max?: number;
  deletable?: boolean;
}) => {
  const [files, setFiles] = useState<File | File[] | { name: string; url: string }>();

  useEffect(() => {
    if (Array.isArray(files) && max && files.length > max) {
      message.warning(`최대 ${max}개의 파일만 업로드 가능합니다.`);
      onChange?.({
        target: {
          value: files.slice(0, max),
        },
      } as ChangeEvent<any>);
    } else if (files) {
      onChange?.({
        target: {
          value: files,
        },
      } as ChangeEvent<any>);
    }
  }, [files]);

  return (
    <FileUploadBlock>
      {!readOnly && (
        <Space>
          <Upload
            multiple={multiple}
            accept={accept === 'all' ? undefined : accept}
            itemRender={() => null}
            beforeUpload={(file, fileList) => {
              setFiles(
                multiple ? (!Array.isArray(value) ? fileList : [...value, ...fileList]) : file,
              );
              return false;
            }}
          >
            <StyledButton
              icon={icon}
              style={{
                width: text === '파일 선택' ? 120 : 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={(e) => {
                if (Array.isArray(value) ? max === value.length : value && max === 1) {
                  e.stopPropagation();
                  message.warning(`최대 ${max}개의 파일만 업로드 가능합니다.`);
                }
              }}
            >
              {text}
            </StyledButton>
          </Upload>
          {value && !deletable && !Array.isArray(value) && (
            <Flex gap={8} align="center">
              <Typography.Text type="BODY_2">{value.name}</Typography.Text>
              <Icon
                name="smallClose"
                size={12}
                onClick={() => {
                  onChange?.({
                    target: { value: undefined },
                  } as ChangeEvent<any>);
                }}
              />
            </Flex>
          )}
        </Space>
      )}
      {value &&
        deletable &&
        (Array.isArray(value) ? (
          value.map((file, index) => (
            <DeletableValueWrapper key={index}>
              <Icon name="clip" size={16} color="GRAY70" />
              <Typography.Text type="SMALL" color="GRAY70" gutter={{ left: 4 }} style={{ flex: 1 }}>
                {file.name}
              </Typography.Text>
              <Icon
                name="close"
                size={16}
                color="GRAY70"
                onClick={() => {
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  onChange?.({
                    target: {
                      value: newValue.length > 0 ? newValue : undefined,
                    },
                  } as ChangeEvent<any>);
                }}
                style={{ marginLeft: 12 }}
              />
            </DeletableValueWrapper>
          ))
        ) : (
          <DeletableValueWrapper>
            <Icon name="clip" size={16} color="GRAY70" />
            <Typography.Text type="SMALL" color="GRAY70" gutter={{ left: 4 }} style={{ flex: 1 }}>
              {value.name}
            </Typography.Text>
            <Icon
              name="close"
              size={16}
              color="GRAY70"
              onClick={() => {
                onChange?.({
                  target: { value: undefined },
                } as ChangeEvent<any>);
              }}
              style={{ marginLeft: 12 }}
            />
          </DeletableValueWrapper>
        ))}
      {value && isView && !Array.isArray(value) && (
        <FileViewer
          file={value}
          style={{ maxWidth: 520, marginTop: !readOnly ? 16 : 0 }}
          bodyStyle={bodyStyle}
        />
      )}
    </FileUploadBlock>
  );
};

const FileUploadBlock = styled.div`
  * {
    transition: all 150ms ease !important;
  }
`;

const StyledButton = styled(Button)`
  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    svg,
    path {
      fill: ${palette.PRIMARY50};
    }
  }
`;

const DeletableValueWrapper = styled(Flex)`
  margin-top: 8px;
  padding: 8px 12px;
  align-items: center;
  border-radius: 4px;
  background: ${palette.GRAY10};
`;

export default FileUpload;

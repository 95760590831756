import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';
import styled from 'styled-components';

import FileUploadContainer from 'containers/file/FileUploadContainer';
import { messages } from 'lib/consts';
import { SaleInfoAttachUpload } from 'types/brand/vcrp';
import Icon from 'components/ui/Icon/Icon';

const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    height: 56px;
    font-size: 16px;
  }
`;

const ProductSaleInfoUpload = ({
  value = [],
  onChange,
  deleteAttachIds,
  setDeleteAttachIds,
  readOnly,
}: {
  value?: File[];
  onChange?: (e: { target: { value?: File[] } }) => void;
  deleteAttachIds: number[];
  setDeleteAttachIds: Dispatch<SetStateAction<number[]>>;
  readOnly: boolean;
}) => {
  const [visible, setVisible] = useState(false);
  const [files, setFiles] = useState<File[]>(value);

  useEffect(() => {
    if (value?.length || files.length) {
      onChange?.({
        target: { value: files },
      });
    }
  }, [files]);

  const handleUpload = (file: File) => {
    if (value?.find((item) => item.name === file.name)) {
      message.warning(messages.DUPLICATE_FILE_NAME);
      return false;
    }

    setFiles((draft) => draft.concat(file));
    return true;
  };

  const handleDelete = (file: File | SaleInfoAttachUpload) => {
    if ('productSaleInfoAttachId' in file) {
      setDeleteAttachIds([...deleteAttachIds, file.productSaleInfoAttachId]);
    }

    setFiles((draft) => draft.filter((item) => item.name !== file.name));
    return false;
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        style={{
          width: 120,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon size={14} color="PRIMARY50" name="upload" />
        {value.length ? '파일 보기' : '파일 선택'}
      </Button>
      <Modal
        title="미국내 판매 증빙 서류"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        destroyOnClose
        maskClosable={false}
        width={864}
      >
        <FileUploadContainer
          accept=".pdf,.jpg,.jpeg,.zip"
          max={10}
          files={value}
          onUpload={handleUpload}
          onDelete={handleDelete}
          readOnly={readOnly}
        />
        <ButtonContainer>
          <Button type="primary" onClick={() => setVisible(false)}>
            확인
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default ProductSaleInfoUpload;

import styled from 'styled-components';

import { s3AssetDomain } from 'lib/consts';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';

const ContractSendGuideBlock = styled.div`
  margin-top: 24px;
`;

const ContractSendGuideWrap = styled.div`
  padding: 44px 0px;
  border-radius: 8px;
  background-color: ${palette.SLATE_GRAY10};
`;

const ContractSendGuideBody = styled.div`
  max-width: 400px;
  margin: 0 auto;

  img {
    position: relative;
    left: 10px;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const BulletNumber = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${palette.PRIMARY50};
  font-size: 12px;
  text-align: center;
  color: #fff;
  vertical-align: text-top;
`;

const ContractSendGuide = () => {
  return (
    <ContractSendGuideBlock>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
        계약서 송부 가이드
      </Typography.Text>
      <ContractSendGuideWrap>
        <ContractSendGuideBody>
          <Typography.Text type="TITLE_2" gutter={{ bottom: 16 }}>
            <BulletNumber>1</BulletNumber>각 2부씩 날인
          </Typography.Text>
          <img src={`${s3AssetDomain}/doc-contract.png`} alt="doc-contract" />
          <Typography.Text type="TITLE_2" gutter={{ top: 12, bottom: 16 }}>
            <BulletNumber>2</BulletNumber>CDRI 서울지사 주소로 계약서 송부
          </Typography.Text>
          <Typography.Text type="BODY_2" align="center" style={{ position: 'relative' }}>
            서울 강남구 논현로 417 화원빌딩 가든층 A (06246)
          </Typography.Text>
        </ContractSendGuideBody>
      </ContractSendGuideWrap>
    </ContractSendGuideBlock>
  );
};

export default ContractSendGuide;

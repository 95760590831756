import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Col, message, Row } from 'antd';

import { messages } from 'lib/consts';
import FileUploadTemplate from 'templates/FileUploadTemplate';
import UpdateLog from 'components/product/UpdateLog';
import { DocStatus, DocumentCode, ProductDocStatus } from 'types/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { useProductDocStatus } from 'service/product';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import { useMovePathname } from 'hook/useMovePathname';

export interface ProductSingleFileContainerProps {
  documentCode: DocumentCode;
  footerVisible?: boolean;
  viewerVisible?: boolean;
  loading?: boolean;
  accept?: string;
  formUrl?: string;
  onSubmit?: (args: {
    productId: number;
    countryId: number | undefined;
    updateMode: boolean;
    file: File;
    docStatus: ProductDocStatus | null;
  }) => void;
}

const ProductSingleFileContainer = ({
  documentCode,
  footerVisible = true,
  viewerVisible = true,
  loading,
  accept = '.pdf',
  formUrl,
  onSubmit, // HINT: submit 기본 동작을 덮어쓰려면 해당 prop을 전달
}: ProductSingleFileContainerProps) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { sendEventToGA } = useGA();
  const countryId = useCountryId();
  const currentProduct = useCurrentProduct();
  const { movePathname } = useMovePathname();
  const { productId } = currentProduct;

  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );

  const {
    productDocStatus: docStatus,
    addProdctDocStatus,
    addProdctDocStatusLoading,
    updateProdctDocStatus,
    updateProdctDocStatusLoading,
  } = useProductDocStatus({ productId, countryId, documentCode });

  const updateMode = useMemo(
    () =>
      docStatus !== null &&
      docStatus.status !== DocStatus.INP &&
      docStatus.status !== DocStatus.OPT,
    [docStatus],
  );
  const [file, setFile] = useState<File | { name: string; url: string } | null>(null);
  const onDelete = () => {
    setFile(null);
  };

  const getDocumentName = (documentCode: DocumentCode) => {
    switch (documentCode) {
      case DocumentCode.IFRA:
        return 'IFRA Conformity Certificate';
      case DocumentCode.RMCOA:
        return 'Raw Material CoA';
      case DocumentCode.RMMSDS:
        return 'Raw Material MSDS';
      case DocumentCode.PI:
        return 'Product Images';
      case DocumentCode.CFS:
        return 'CFS';
      case DocumentCode.PCC:
        return '중국 상표등록증';
      case DocumentCode.LOGOAI:
        return 'Brand Logo (AI File)';
      case DocumentCode.MANUC:
        return 'Manufacturing Process (China)';
      case DocumentCode.ROPQM:
        return 'Resume of Product Quality Manager';
      default:
        return '알 수 없는 문서';
    }
  };

  useEffect(() => {
    if (
      docStatus !== null &&
      docStatus.status !== DocStatus.INP &&
      docStatus.status !== DocStatus.OPT &&
      docStatus.uploadFileUrl
    ) {
      setFile({
        name:
          docStatus.filename ||
          docStatus.uploadFileUrl
            ?.slice(docStatus.uploadFileUrl.lastIndexOf('/') + 1)
            .replace(/_\d{14}/, ''),
        url: docStatus.uploadFileUrl,
      });
    }
  }, [docStatus]);

  const handleSubmit = () => {
    if (!file) return message.warning(messages.REQUIRED_FILE);
    if ('url' in file) {
      return message.warning('파일이 기존 파일과 같아 수정할 필요가 없습니다.');
    }

    if (onSubmit) {
      onSubmit({
        productId,
        countryId,
        file,
        docStatus,
        updateMode,
      });
      return;
    }

    if (!updateMode) {
      addProdctDocStatus(
        {
          documentCode,
          productId: currentProduct.productId,
          documentFile: file,
          ...(countryId && { countryId }),
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: getDocumentName(documentCode),
              actionType: EGAActionType.REGISTER,
            });
            movePathname({ productId });
          },
        },
      );
    } else {
      updateProdctDocStatus(
        {
          documentCode,
          productId,
          documentFile: file,
          ...(countryId && { countryId }),
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: getDocumentName(documentCode),
              actionType: docStatus?.status === 'MOD' ? EGAActionType.MODIFY : EGAActionType.UPDATE,
            });
          },
        },
      );
    }
  };
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={documentCode}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog productId={productId} countryId={countryId} documentCode={documentCode} />
          </Col>
        )}
      </Row>
      <FileUploadTemplate
        footerVisible={footerVisible}
        updateMode={updateMode}
        readOnly={readOnlyMode}
        viewerVisible={viewerVisible}
        files={file}
        accept={accept}
        fetchLoading={addProdctDocStatusLoading || updateProdctDocStatusLoading || loading}
        formUrl={formUrl}
        status={docStatus?.status}
        onUpload={setFile}
        onDelete={onDelete}
        onSubmit={handleSubmit}
        correctRequestWithNoUpdate={true}
        documentCode={documentCode}
      />
    </>
  );
};

export default ProductSingleFileContainer;

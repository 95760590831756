import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import * as productSampleApi from 'lib/api/manufacturer/productSample';
import {
  ProductSampleBasicAdd,
  ProductSampleRecipeAdd,
  ProductSampleRecipeDetailDraft,
  ProductSampleRecipeDetailItem,
  ProductSampleRecipeDraft,
  ProductSampleRecipeUpdate,
  ProductSampleProcessMapItemForm,
  ProductSampleProcessMapItemUpdate,
  ProductSampleSearchType,
  ProductSampleStatus,
  ProductSampleSearchStatus,
  ProductSampleSearchParams,
} from 'types/manufacturer/productSample';
import { getUpdatingObject } from 'lib/form';
import path from 'lib/path';
import { messages } from 'lib/consts';
import useUpdateEffect from 'hook/useUpdateEffect';

export const useProductSampleId = () => {
  const params = useParams<{ productSampleId: string }>();
  return Number(params.productSampleId) || undefined;
};

export const useCheckProductSampleIsDuplicated = () => {
  const { mutate: checkSampleIsDuplicated } = useMutation((labNo: string) =>
    productSampleApi.checkProductSampleIsDuplicated(labNo),
  );
  return checkSampleIsDuplicated;
};

export const useProductSamples = (defaultStatus: ProductSampleSearchStatus) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const savedSearchParams = (state as Partial<ProductSampleSearchParams>) || {};
  const [isMyProductSample, setIsMyProductSample] = useState(
    savedSearchParams.isMyProductSample ?? defaultStatus === ProductSampleSearchStatus.REGISTERING
      ? true
      : false,
  );
  const [searchType, setSearchType] = useState(
    savedSearchParams.searchType ?? ProductSampleSearchType.ALL,
  );
  const [searchKeyword, setSearchKeyword] = useState(savedSearchParams.searchKeyword ?? '');
  const [page, setPage] = useState(savedSearchParams.page ?? 1);
  const [size, setSize] = useState<ProductSampleSearchParams['size']>(savedSearchParams.size ?? 10);
  const [status, setStatus] = useState<ProductSampleSearchStatus>(
    savedSearchParams.status ?? defaultStatus,
  );

  const {
    data: { content: productSamples = [], totalElements = 0 } = {},
    isFetching: getLoading,
    refetch: refetchProductSamples,
  } = useQuery(
    [
      'manufacture/getProductSamples',
      isMyProductSample,
      page,
      size,
      searchType,
      searchKeyword,
      status,
    ],
    () =>
      productSampleApi.getProductSamples({
        status,
        isMyProductSample,
        page,
        size,
        searchType,
        searchKeyword,
      }),
    {
      select: (res) => {
        const productSamples = res.data.result.content;
        productSamples.forEach((item) => {
          if (item.recipeDetailItems) {
            addRowNo(item.recipeDetailItems);
          }
        });

        return res.data.result;
      },
    },
  );
  useUpdateEffect(() => {
    navigate(pathname, {
      state: {
        isMyProductSample,
        status,
        searchType,
        searchKeyword,
        page,
        size,
      },
      replace: true,
    });
  }, [isMyProductSample, status, searchType, searchKeyword, page, size]);
  return useMemo(
    () => ({
      productSamples,
      totalElements,
      getLoading,
      refetchProductSamples,
      isMyProductSample,
      setIsMyProductSample,
      status,
      setStatus,
      searchType,
      setSearchType,
      searchKeyword,
      setSearchKeyword,
      page,
      setPage,
      size,
      setSize,
    }),
    [
      productSamples,
      totalElements,
      getLoading,
      isMyProductSample,
      status,
      searchType,
      searchKeyword,
      page,
      size,
    ],
  );
};

export const useConfirmProductSamples = () => {
  const { mutate: confirmProductSamples, isLoading } = useMutation((productSampleIds: number[]) =>
    productSampleApi.confirmProductSamples(productSampleIds),
  );

  return useMemo(() => ({ confirmProductSamples, isLoading }), [confirmProductSamples, isLoading]);
};

export const useChangeProductSampleInternalDisclosure = () => {
  const { mutate: changeProductSampleInternalDisclosure, isLoading } = useMutation(
    ({
      productSampleId,
      isInternalDisclosure,
    }: {
      productSampleId: number;
      isInternalDisclosure: boolean;
    }) =>
      productSampleApi.updateProductSample({
        productSampleId,
        isInternalDisclosure,
      }),
  );

  return useMemo(
    () => ({ changeProductSampleInternalDisclosure, isLoading }),
    [changeProductSampleInternalDisclosure, isLoading],
  );
};

export const useProductSampleDocuments = (productSampleId: number) => {
  const { data: productSampleDocuments = [], isFetching: getLoading } = useQuery(
    ['manufacture/getProductSampleDocuments', productSampleId],
    () => productSampleApi.getProductSampleDocuments(productSampleId),
    { select: (res) => res.data.result },
  );

  return useMemo(
    () => ({ productSampleDocuments, getLoading }),
    [productSampleDocuments, getLoading],
  );
};

export const useProductSample = (productSampleId?: number) => {
  const navigate = useNavigate();

  const { data: productSample = null, isFetching: getLoading } = useQuery(
    ['manufacturer/getProductSample', productSampleId],
    () => productSampleApi.getProductSample(productSampleId!),
    {
      enabled: typeof productSampleId !== 'undefined',
      select: (res) => res.data.result,
    },
  );
  const { mutate: addProductSample, isLoading: addLoading } = useMutation(
    productSampleApi.addProductSample,
    {
      onSuccess: (res) => {
        const productSampleId = res.data.result.productSampleId;
        message.success('등록되었습니다.');
        navigate(`${path.manufacturer.productSample.detail}/${productSampleId}`);
      },
    },
  );
  const { mutate: updateProductSampleMutate, isLoading: updateLoading } = useMutation(
    productSampleApi.updateProductSample,
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
        navigate(-1);
      },
    },
  );
  const updateProductSample = useCallback(
    ({ countryTargets, ...restProductSample }: ProductSampleBasicAdd) => {
      if (productSample === null) throw new Error('Invalid productSample');
      const { countryTargets: originalCountryTargets, ...originalProductSample } = productSample;
      const isCountryTargetsChanged =
        countryTargets.length !== originalCountryTargets.length ||
        countryTargets.some((countryTarget) => !originalCountryTargets.includes(countryTarget));
      const updatingProductSample = {
        ...getUpdatingObject(restProductSample, originalProductSample),
        ...(isCountryTargetsChanged && { countryTargets }),
      };
      if (Object.keys(updatingProductSample).length === 0) {
        return message.warning(messages.NO_NEED_TO_UPDATE);
      }
      updateProductSampleMutate({
        productSampleId: productSample!.productSampleId,
        ...updatingProductSample,
      });
    },
    [productSample],
  );
  return useMemo(
    () => ({
      productSample,
      getLoading,
      addProductSample,
      addLoading,
      updateProductSample,
      updateLoading,
    }),
    [productSample, getLoading, addProductSample, addLoading, updateProductSample, updateLoading],
  );
};

export const useIsProductSampleConfirmed = (productSampleId?: number) => {
  const { productSample } = useProductSample(productSampleId);
  return (productSample && productSample.status !== ProductSampleStatus.RDY) || false;
};

export const useProductSampleRecipes = (productSampleId: number) => {
  const [recipeDraft, setRecipeDraft] = useState<ProductSampleRecipeDraft | null>(null);
  const [hasNewRecipe, setHasNewRecipe] = useState(false);
  const {
    data: productSampleRecipes = [],
    isFetching: getLoading,
    refetch,
  } = useQuery(
    'manufacturer/getProductSampleRecipes',
    () => productSampleApi.getProductSampleRecipes(productSampleId),
    { select: (res) => res.data.result },
  );
  const { mutate: validateProductSampleRecipe, isLoading: validateLoading } = useMutation(
    productSampleApi.validateProductSampleRecipe,
    {
      onSuccess: (res) => {
        setRecipeDraft(res.data.result);
      },
      onError: () => {
        if (recipeDraft && recipeDraft.invalidCodes.length > 0) {
          setRecipeDraft(null);
        }
      },
    },
  );
  const { mutate: addProductSampleRecipe, isLoading: addLoading } = useMutation(
    (productSampleRecipe: ProductSampleRecipeAdd) =>
      productSampleApi.addProductSampleRecipe(productSampleId, productSampleRecipe),
    {
      onSuccess: () => {
        message.success('하단 처방 히스토리에 처방이 저장되었습니다. ');
        refetch().then(() => {
          setRecipeDraft(null);
          setHasNewRecipe(true);
        });
      },
    },
  );

  return useMemo(
    () => ({
      recipeDraft,
      hasNewRecipe,
      validateProductSampleRecipe,
      validateLoading,
      productSampleRecipes,
      getLoading,
      addProductSampleRecipe,
      addLoading,
    }),
    [
      recipeDraft,
      hasNewRecipe,
      validateProductSampleRecipe,
      validateLoading,
      productSampleRecipes,
      getLoading,
      addProductSampleRecipe,
      addLoading,
    ],
  );
};

export const useUpdateProductSampleRecipe = () => {
  const { mutate: updateProductSampleRecipe, isLoading } = useMutation(
    ({
      productSampleId,
      productSampleRecipe,
    }: {
      productSampleId: number;
      productSampleRecipe: ProductSampleRecipeUpdate;
    }) => productSampleApi.updateProductSampleRecipe(productSampleId, productSampleRecipe),
    {
      onSuccess: () => {
        message.success('메모가 저장되었습니다.');
      },
    },
  );
  return useMemo(
    () => ({ updateProductSampleRecipe, isLoading }),
    [updateProductSampleRecipe, isLoading],
  );
};

export const addRowNo = (recipeDetailItems: ProductSampleRecipeDetailItem[]) => {
  let rowNo = 0;

  for (let i = 0; i < recipeDetailItems.length; i++) {
    recipeDetailItems[i].rowNo =
      recipeDetailItems[i].phase !== null && recipeDetailItems[i].phase !== '' ? ++rowNo : rowNo;
  }
};

export const calculateRecipeDetailItemsRowSpan = (
  recipeDetailItems: ProductSampleRecipeDetailItem[],
) => {
  const targetKeys: (keyof ProductSampleRecipeDetailItem)[] = [
    'phase',
    'materialName',
    'materialCompanyName',
    'flaIngredientPercent',
  ];
  let rowSpanAcc = 1;

  targetKeys.forEach((targetKey) => {
    for (let i = recipeDetailItems.length - 1; i >= 0; i--) {
      if (recipeDetailItems[i][targetKey] === null || recipeDetailItems[i][targetKey] === '') {
        rowSpanAcc++;
      } else {
        switch (targetKey) {
          case 'phase': {
            recipeDetailItems[i].phaseRowSpan = rowSpanAcc;
            break;
          }
          case 'materialName': {
            recipeDetailItems[i].materialNameRowSpan = rowSpanAcc;
            break;
          }
          case 'materialCompanyName': {
            recipeDetailItems[i].materialCompanyNameRowSpan = rowSpanAcc;
            break;
          }
          default: {
            recipeDetailItems[i].flaIngredientPercentRowSpan = rowSpanAcc;
          }
        }
        rowSpanAcc = 1;
      }
    }
  });
};

export const useProductSampleRecipeDetail = (productSampleId: number, enabled: boolean) => {
  const navigate = useNavigate();

  const [recipeDetailDraft, setRecipeDetailDraft] = useState<ProductSampleRecipeDetailDraft | null>(
    null,
  );

  const { mutate: validateProductSampleRecipeDetail, isLoading: validateLoading } = useMutation(
    productSampleApi.validateProductSampleRecipeDetail,
    {
      onSuccess: (res) => {
        const recipeDetailDraft = res.data.result;
        const recipeDetailItems = recipeDetailDraft.recipeDetailItems;
        addRowNo(recipeDetailItems);
        calculateRecipeDetailItemsRowSpan(recipeDetailItems);
        setRecipeDetailDraft(recipeDetailDraft);
      },
      onError: () => {
        if (recipeDetailDraft && recipeDetailDraft.invalidCodes.length > 0) {
          setRecipeDetailDraft(null);
        }
      },
    },
  );

  const { mutate: addProductSampleRecipeDetail, isLoading: addLoading } = useMutation(
    productSampleApi.addProductSampleRecipeDetail,
    {
      onSuccess: () => {
        if (!productSampleRecipeDetail) {
          message.success('입력되었습니다.');
        } else {
          message.success('수정되었습니다.');
        }
        navigate(-1);
      },
    },
  );

  const { data: productSampleRecipeDetail = null, isFetching: getLoading } = useQuery(
    ['manufacturer/getProductSampleRecipeDetail', productSampleId],
    () => productSampleApi.getProductSampleRecipeDetail(productSampleId),
    {
      select: (res) => {
        const recipeDetail = res.data.result;
        const recipeDetailItems = recipeDetail.recipeDetailItems;

        addRowNo(recipeDetailItems);
        calculateRecipeDetailItemsRowSpan(res.data.result.recipeDetailItems);
        return res.data.result;
      },
      enabled,
    },
  );

  return useMemo(
    () => ({
      validateProductSampleRecipeDetail,
      validateLoading,
      recipeDetailDraft,
      addProductSampleRecipeDetail,
      addLoading,
      productSampleRecipeDetail,
      getLoading,
    }),
    [
      validateProductSampleRecipeDetail,
      validateLoading,
      recipeDetailDraft,
      addProductSampleRecipeDetail,
      addLoading,
      productSampleRecipeDetail,
      getLoading,
    ],
  );
};

export const useProductSampleProcessMapPhases = (productSampleId: number, enabled: boolean) => {
  const { data: productSampleProcessMapPhases = null } = useQuery(
    ['manufacture/getProductSampleProcessMapPhases', productSampleId],
    () => productSampleApi.getProductSampleProcessMapPhases(productSampleId),
    { select: (res) => res.data.result, enabled },
  );
  return productSampleProcessMapPhases;
};

export const useProductSampleProcessMap = (productSampleId: number, enabled: boolean) => {
  const navigate = useNavigate();

  const { data: processMapItems = [] } = useQuery(
    ['manufacturer/getProductSampleProcessMap', productSampleId],
    () => productSampleApi.getProductSampleProcessMap(productSampleId),
    { select: (res) => res.data.result.processMapItems, enabled },
  );

  const { mutate: addProductSampleProcessMap, isLoading: addLoading } = useMutation(
    (processMapItems: ProductSampleProcessMapItemForm[]) =>
      productSampleApi.addProductSampleProcessMap({
        productSampleId,
        processMapItems,
      }),
    {
      onSuccess: () => {
        message.success('입력되었습니다.');
        navigate(-1);
      },
    },
  );

  const { mutate: updateProductSampleProcessMapMutate, isLoading: updateLoading } = useMutation(
    (processMapItems: ProductSampleProcessMapItemUpdate[]) =>
      productSampleApi.updateProductSampleProcessMap({
        productSampleId,
        processMapItems,
      }),
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
        navigate(-1);
      },
    },
  );

  const updateProductSampleProcessMap = useCallback(
    (newProcessMapItems: ProductSampleProcessMapItemForm[]) => {
      const updatingProcessMapItems: ProductSampleProcessMapItemUpdate[] = [];
      processMapItems.forEach((originalProcessMapItem, index) => {
        const {
          processType = null,
          temperature = null,
          isNotApplicableTemperature = null,
          rpm = null,
          isNotApplicableRpm = null,
          processTime = null,
          isUntilProcessCompletion = null,
          directInputText = null,
          mixedDatas = null,
        } = newProcessMapItems[index];
        const updatingProcessMapItem = (getUpdatingObject(
          {
            processType,
            temperature,
            isNotApplicableTemperature,
            rpm,
            isNotApplicableRpm,
            processTime,
            isUntilProcessCompletion,
            directInputText,
          },
          originalProcessMapItem,
        ) || {}) as ProductSampleProcessMapItemUpdate;
        if (
          mixedDatas &&
          (!originalProcessMapItem.mixedDatas ||
            mixedDatas.length !== originalProcessMapItem.mixedDatas.length ||
            mixedDatas.some((mixedData) => !originalProcessMapItem.mixedDatas?.includes(mixedData)))
        ) {
          updatingProcessMapItem.mixedDatas = mixedDatas;
        }
        if (Object.keys(updatingProcessMapItem).length > 0) {
          updatingProcessMapItem.productSampleProcessMapItemId =
            originalProcessMapItem.productSampleProcessMapItemId;
          updatingProcessMapItems.push(updatingProcessMapItem);
        }
      });
      if (updatingProcessMapItems.length === 0) {
        return message.warning(messages.NO_NEED_TO_UPDATE);
      }
      updateProductSampleProcessMapMutate(updatingProcessMapItems);
    },
    [processMapItems],
  );

  return useMemo(
    () => ({
      processMapItems,
      addProductSampleProcessMap,
      addLoading,
      updateProductSampleProcessMap,
      updateLoading,
    }),
    [
      processMapItems,
      addProductSampleProcessMap,
      addLoading,
      updateProductSampleProcessMap,
      updateLoading,
    ],
  );
};

export const usePublishProcessMapPreview = () => {
  const { mutate: publishProcessMapPreview, isLoading } = useMutation(
    productSampleApi.publishProcessMapPreview,
  );

  return useMemo(
    () => ({ publishProcessMapPreview, isLoading }),
    [isLoading, publishProcessMapPreview],
  );
};

export const useUsableProductSamples = () => {
  const { data: productSamples = [], isLoading } = useQuery(
    'manufacture/getUsableProductSamples',
    productSampleApi.getUsableProductSamples,
    {
      select: (res) => res.data.result,
    },
  );
  return useMemo(() => ({ productSamples, isLoading }), [productSamples, isLoading]);
};

export const useGetProductSample = () => {
  const { mutate: getProductSample, isLoading } = useMutation(productSampleApi.getProductSample);

  return useMemo(() => ({ getProductSample, isLoading }), [getProductSample, isLoading]);
};

import ProductPageTemplate from 'templates/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { ProductEfficacyChina } from 'components/product/ProductEfficacy';

const notices = [
  '해당 제품에 사용하실 효능을 선택해 주세요. 효능을 선택하시면 어떤 방법으로 증빙이 가능한지 확인하실 수 있습니다.',
  '추후 각 효능에 해당하는 증빙자료를 [효능 평가 자료] 메뉴에 업로드하셔야 합니다.',
  '효능 증빙이 불가할 경우, 해당 효능을 사용하실 수 없으며 아트웍에서도 해당 마케팅 클레임을 수정 또는 삭제하셔야 합니다.',
  <MistakeNoticeItem />,
];

const ProductEfficacyPage = () => {
  return (
    <ProductPageTemplate subtitle="제품 효능 정보" notices={notices}>
      <ProductEfficacyChina />
    </ProductPageTemplate>
  );
};

export default ProductEfficacyPage;

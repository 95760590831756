import ProductPageTemplate from 'templates/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { ProductInformationChina } from 'components/product/ProductInformationChina';

const notices = [
  '제품명(중문) 추천 서비스를 이용하실 수 있습니다. 해당 서비스가 필요하신 경우 전화나 하단의 ‘이용 문의’로 문의 바랍니다.',
  <MistakeNoticeItem />,
];

const ProductInformationChinaPage = () => {
  return (
    <ProductPageTemplate subtitle="제품 정보" notices={notices}>
      <ProductInformationChina />
    </ProductPageTemplate>
  );
};

export default ProductInformationChinaPage;

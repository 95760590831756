import { useLocation, useNavigate } from 'react-router-dom';

import CertificateComplete from 'components/certificate/CertificateComplete';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';

export interface CertificateCompletePageSearchParams {
  page: number;
  searchType: SearchType;
  searchValue: string;
  finishDateLower: string;
  finishDateUpper: string;
}

export enum SearchType {
  ALL = 'all',
  PRODUCT_NAME_EN = 'productNameEn',
  MANUFACTURER_NAME_EN = 'manufacturerNameEn',
  COUNTRY_NAMEK_KO = 'countryNameKo',
}

const CertificateCompletePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const handleChangeQueryParams = (params: Partial<CertificateCompletePageSearchParams>) => {
    for (const [key, value] of Object.entries(params)) {
      queryParams.set(key, value.toString());
    }
    navigate(`${path.certificate.complete}?${queryParams.toString()}`);
  };

  return (
    <PageTemplate title="인증 완료 제품 관리" back={false}>
      <CertificateComplete
        queryParams={queryParams}
        onChangeQueryParams={handleChangeQueryParams}
      />
    </PageTemplate>
  );
};

export default CertificateCompletePage;

import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import path from 'lib/path';
import { logout } from 'modules/auth.js';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lowerCasedSearch = search.toLocaleLowerCase();

  useEffect(() => {
    if (lowerCasedSearch === '?reason=tokenerror' || lowerCasedSearch === '?reason=expire') {
      dispatch(logout(true));
      return;
    }
    dispatch(logout());
  }, []);

  return <Navigate to={`${path.login}${search}`} replace />;
};

export default LogoutPage;

import ProductReadyList from 'components/product/ProductReadyList';
import path from 'lib/path';
import { useLocation, useNavigate } from 'react-router-dom';
import PageTemplate from 'templates/PageTemplate';
import { ProductSearchType, ProductListPageState } from 'types/product';

const ProductReadyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageState = (location.state as ProductListPageState) || {
    page: 1,
    searchType: ProductSearchType.ALL,
    searchKeyword: '',
  };

  const handleChangePageState = (state: ProductListPageState) => {
    navigate(path.product.ready, {
      state,
      replace: true,
    });
  };

  return (
    <PageTemplate
      title="등록 중 제품 관리"
      description="아래 등록 중인 제품을 관리해보세요."
      back={false}
    >
      <ProductReadyList pageState={pageState} onChangePageState={handleChangePageState} />
    </PageTemplate>
  );
};

export default ProductReadyPage;

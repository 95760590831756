import { Button, Tabs, message } from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { SearchInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { messages } from 'lib/consts';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import {
  useNMPAAcquisition,
  useNMPACompanyInfo,
  useNMPAMaterialCounts,
  useNMPAMaterialList,
} from 'service/material/nmpa/nmpa';
import {
  DocumentCode,
  MaterialNmpaBasicInfoStatus,
  NMAPCompanyType,
  NMPAMaterialListItem,
  NMPAMaterialListPageState,
  NMPAMaterialProgressStatus,
} from 'types/material/nmpa/nmpa';

const Container = styled.div`
  .ant-table-container table tr {
    td {
      height: 76px;
      padding: 4px;
    }

    th {
      height: 40px;
      line-height: 1;
    }

    td:first-child,
    th:first-child {
      padding-left: 16px;
    }

    td:last-child,
    th:last-child {
      padding-right: 16px;
    }
  }

  .ant-tabs-tab {
    justify-content: center;
    width: 148px;
    font-size: 16px;
    color: #a9a6a6;
  }

  .ant-tabs-ink-bar {
    height: 4px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    font-weight: 500;
  }
`;

const CountBox = styled(Flex)`
  padding: 12px 16px;
  height: 44px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  box-sizing: border-box;

  *:nth-child(2) {
    transform: translateY(-1px);
  }
`;

const progressStatusOptions = [
  { title: '입력 중인 원료', key: NMPAMaterialProgressStatus.INPUTTING },
  { title: '입력 완료 원료', key: NMPAMaterialProgressStatus.INPUT_FINISH },
];

const NMPAMaterialList = ({
  pageState,
  onPageStateChange,
}: {
  pageState: NMPAMaterialListPageState;
  onPageStateChange: (state: NMPAMaterialListPageState) => void;
}) => {
  const navigate = useNavigate();
  const { page, progressStatus, searchValue: searchedValue } = pageState;
  const [searchValue, setSearchValue] = useState(searchedValue);
  const { nmpaMaterialCounts } = useNMPAMaterialCounts();
  const { nmpaMaterialList, total, isLoading } = useNMPAMaterialList({
    page,
    progressStatus,
    searchValue: searchedValue,
  });
  const { nmpaCompanyInfo, nmpaCompanyInfoType } = useNMPACompanyInfo();
  const { acquisition } = useNMPAAcquisition();

  const availableMaterialCount = useMemo(() => {
    if (!nmpaMaterialCounts) return undefined;
    return (
      nmpaMaterialCounts.totalCount -
      nmpaMaterialCounts.ongoingCount -
      nmpaMaterialCounts.finishCount
    );
  }, [nmpaMaterialCounts]);

  const handleProgressStatusChange = (progressStatus: NMPAMaterialProgressStatus) => {
    onPageStateChange({
      page: 1,
      progressStatus,
      searchValue: searchedValue,
    });
  };

  const handlePageChange = (page: number) => {
    onPageStateChange({
      page,
      progressStatus,
      searchValue: searchedValue,
    });
  };

  const handleSearch = () => {
    onPageStateChange({
      page,
      progressStatus,
      searchValue,
    });
  };

  const handleAcquisition = (materialNmpaBasicInfoId: number) => () => {
    if (!nmpaCompanyInfoType) {
      message.warning(messages.INVALID_APPROACH);
      return;
    }
    const areNotEmpty = (...values: any[]) =>
      values.every((value: any) => value !== undefined && value !== '' && value !== null);

    const { isDirect } = nmpaCompanyInfoType;

    const directInputted = areNotEmpty(nmpaCompanyInfo?.platformUsername);

    const proxyInputted = areNotEmpty(nmpaCompanyInfo?.ceoName, nmpaCompanyInfo?.companyName);

    const request = (proxy: boolean) => {
      if (proxy && !proxyInputted) {
        message.warning('회사 정보를 입력해주세요.');
        navigate(path.my + '/nmpa', {
          state: {
            type: NMAPCompanyType.PROXY,
          },
          replace: true,
        });
        return;
      } else if (!proxy && !directInputted) {
        message.warning('플랫폼 계정 정보를 입력해주세요.');
        navigate(path.my + '/nmpa', {
          state: {
            type: NMAPCompanyType.PLATFORM,
          },
          replace: true,
        });
        return;
      }

      acquisition(
        {
          materialId: materialNmpaBasicInfoId,
          isAccountProxy: proxy,
        },
        {
          onSuccess: () => {
            navigate(path.material.nmpa.ongoing, { replace: true });
            message.success('취득 중 원료 관리 화면으로 이동합니다.');
          },
        },
      );
    };

    request(!isDirect);
  };

  const columns: ColumnsType<NMPAMaterialListItem> = [
    {
      title: '원료명 (상품명)',
      width: progressStatus === NMPAMaterialProgressStatus.INPUTTING ? 712 : 560,
      render: (_, { materialNmpaBasicInfoId, tradeName }) => (
        <Typography.Text
          medium
          type="BODY_2"
          color="GRAY90"
          onClick={() => {
            navigate(`${path.material.nmpa.material.root}/${materialNmpaBasicInfoId}`);
          }}
        >
          {tradeName}
        </Typography.Text>
      ),
    },
    ...(progressStatus === NMPAMaterialProgressStatus.INPUTTING
      ? [
          {
            title: '최근 수정 일시',
            align: 'center',
            width: 160,
            render: (_, { updateDt, readyDt }) =>
              updateDt?.slice(0, 10) || readyDt?.slice(0, 10) || '-',
          } as ColumnType<NMPAMaterialListItem>,
        ]
      : []),
    ...(progressStatus === NMPAMaterialProgressStatus.INPUT_FINISH
      ? [
          {
            title: '입력 완료일',
            align: 'center',
            width: 160,
            render: (_, { readyDt }) => readyDt?.slice(0, 10) || '-',
          } as ColumnType<NMPAMaterialListItem>,
        ]
      : []),

    ...((progressStatus === NMPAMaterialProgressStatus.INPUT_FINISH
      ? [
          {
            title: '취득 진행',
            align: 'center',
            width: 136,
            render: (_, { materialNmpaBasicInfoId, status }) => {
              switch (status) {
                case MaterialNmpaBasicInfoStatus.READY:
                  return (
                    <Tags.Mark
                      color="blue"
                      type="arrow"
                      onClick={handleAcquisition(materialNmpaBasicInfoId)}
                    >
                      취득 신청
                    </Tags.Mark>
                  );
                case MaterialNmpaBasicInfoStatus.ONGOING:
                  return <Tags.Mark color="lightGray">취득 진행중</Tags.Mark>;
                case MaterialNmpaBasicInfoStatus.FINISH:
                  return <Typography.Text type="BODY_2">취득 완료</Typography.Text>;
                default:
                  return null;
              }
            },
          },
        ]
      : []) as ColumnType<NMPAMaterialListItem>[]),
  ];

  return (
    <Container>
      <Tabs
        defaultActiveKey={progressStatus}
        onChange={(key) => handleProgressStatusChange(key as NMPAMaterialProgressStatus)}
        tabBarStyle={{ margin: '24px 0 0' }}
        tabBarGutter={0}
        renderTabBar={(props, DefaultTabBar) => (
          <>
            <DefaultTabBar {...props} />
            <Flex
              justify="space-between"
              align="center"
              style={{ marginTop: 24, marginBottom: 16 }}
            >
              <Flex gap={8}>
                <AvailableMaterialCountOfRequest count={availableMaterialCount} />
                <Button
                  type="primary"
                  icon={<Icon name="multipleAdd" size={18} color="ETC_WHITE" />}
                  onClick={() => {
                    navigate(`${path.material.nmpa.material.root}/${DocumentCode.BASIC}`);
                  }}
                >
                  신규 원료 정보 등록
                </Button>
              </Flex>
              <SearchInput
                placeholder="원료명(상품명) 검색"
                defaultValue={searchedValue}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                onSearch={handleSearch}
                style={{ width: 320 }}
              />
            </Flex>
          </>
        )}
      >
        {progressStatusOptions.map(({ title, key }) => (
          <Tabs.TabPane tab={title} key={key}>
            <Table
              loading={isLoading}
              dataSource={nmpaMaterialList}
              columns={columns}
              pagination={{
                current: page,
                onChange: handlePageChange,
                pageSize: 5,
                total: total,
              }}
              rowKey="materialNmpaBasicInfoId"
              footer={() => (
                <Flex
                  justify="flex-end"
                  style={{
                    marginTop: '16px',
                  }}
                ></Flex>
              )}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Container>
  );
};

const AvailableMaterialCountOfRequest = ({ count }: { count?: number }) => {
  return (
    <CountBox columnGap={8} align="center">
      <Typography.Text type="BODY_2" color="SLATE_GRAY70">
        취득 가능 원료
      </Typography.Text>
      <Typography.Text type="TITLE_2" color="SLATE_GRAY70" medium>
        {count ?? ''}
      </Typography.Text>
    </CountBox>
  );
};

export default NMPAMaterialList;

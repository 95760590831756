import { Spin } from 'antd';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import QuickMenu from 'components/QuickMenu';
import CompanyStat from 'components/main/CompanyStat';
import CompanyStatsBlock from 'components/main/CompanyStatsBlock';
import Shortcut from 'components/main/Shortcut';
import WorldMap from 'components/main/WorldMap';
import Typography from 'components/system/general/Typography';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import path from 'lib/path';
import { useMain } from 'service/main';
import palette from 'lib/styles/palette';
import { ReactComponent as ArtworkScreeningImg } from 'asset/svg/artwork_screening.svg';

const MainPage = () => {
  const navigate = useNavigate();
  const { companyName, stats, getStatsLoading } = useMain();
  const { openStibeePopUpModal } = useModal();
  const {
    productOnCount = 0,
    productFinCount = 0,
    certOnCount = 0,
    certFinCount = 0,
    certFinCountryCount = 0,
  } = {
    ...stats,
  };

  useEffect(() => {
    const expireDate = window.localStorage.getItem('ST_POP');
    let today = new Date();
    if (!expireDate || Number(today.getTime()) > Number(expireDate)) {
      openStibeePopUpModal({});
    }
    if (expireDate === 'Infinity') {
      return;
    }
  }, []);

  const handleAddProduct = () => {
    navigate(path.product.basic);
  };

  return (
    <MainPageBlock>
      <Flex gap={8} style={{ position: 'relative' }}>
        <CompanyStatsBlock>
          <Typography.Text mark light type="HEADLINE_1">
            <>
              현재 <mark>{companyName}</mark>
              님의
              <br /> 인증 현황입니다.
            </>
          </Typography.Text>
          <Spin spinning={getStatsLoading}>
            <Flex gutter={{ top: 36 }}>
              <CompanyStat
                amount={productOnCount}
                opacity={0.25}
                label="등록 중 제품"
                onClick={() => {
                  navigate(path.product.ready);
                }}
              />
              <CompanyStat
                amount={productFinCount}
                opacity={0.5}
                label="등록 완료 제품"
                onClick={() => {
                  navigate(path.product.registered);
                }}
              />
              <CompanyStat
                amount={certOnCount}
                opacity={0.75}
                label="인증 진행 중"
                onClick={() => navigate(path.certificate.ongoing)}
              />
              <CompanyStat
                amount={certFinCount}
                opacity={1}
                label="인증 완료"
                onClick={() => navigate(path.certificate.complete)}
              />
            </Flex>
          </Spin>
        </CompanyStatsBlock>
        <WorldMap count={certFinCountryCount} />
        <div
          style={{
            position: 'absolute',
            left: '100%',
            transform: 'translateX(48px)',
            marginRight: 24,
          }}
        >
          <QuickMenu
            onMaterialSearchClick={() => navigate(path.material.rawMaterial.search)}
            onIngredientDictClick={() => navigate(path.material.ingredient.dictionary)}
            onFormulaScreeningClick={() => navigate(path.formulaScreening)}
            onCTKSearchClick={() =>
              window.open(
                'https://www.ctkclip.com/ko/plp/2/1?utm_source=cooperate&utm_medium=cdri&utm_campaign=cdricerticos',
                '_blank',
              )
            }
          />
        </div>
      </Flex>
      <Flex gap={16} gutter={{ top: 32 }} align="end">
        <FavoriteMenu>
          <Typography.Text type="TITLE_1">자주 찾는 메뉴</Typography.Text>
          <Flex gap={16} wrap="true" gutter={{ top: 16 }}>
            <Shortcut
              icon={<Icon name="multipleAdd" color="PRIMARY50" />}
              label="신규 제품 등록"
              description="지금 바로 제품을 등록해 보세요."
              onClick={handleAddProduct}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="write" color="PRIMARY50" />}
              label="셀프 견적"
              description="셀프로 제품 견적을 내보세요."
              onClick={() => navigate(path.estimate.pick)}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="page" color="PRIMARY50" />}
              label="인증 진행 현황"
              description="현재 인증 상황을 확인해 보세요."
              onClick={() => navigate(path.certificate.ongoing)}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="liskCheck" color="PRIMARY50" />}
              label="인증 완료 제품 관리"
              description="인증된 제품들을 관리해 보세요."
              onClick={() => navigate(path.certificate.complete)}
              flex={'1 1 calc(50% - 8px)'}
            />
          </Flex>
        </FavoriteMenu>
        {/* Artwork Screening */}
        <ArtworkScreeningContainer
          justify="center"
          align="center"
          onClick={() => navigate(path.artworkScreening)}
        >
          <Flex dir="column" justify="center" align="center" gap={24}>
            <Flex dir="column" gap={4} align="center">
              <Typography.Text type="TITLE_2" color="PRIMARY50" style={{ fontWeight: 600 }}>
                아트웍 스크리닝
              </Typography.Text>
              <Typography.Text type="BODY_2" color="GRAY90">
                AI 기술을 활용한 Artwork의 전성분 라벨링 확인
              </Typography.Text>
            </Flex>
            <ArtworkScreeningImg style={{ height: 190 }} />
          </Flex>
        </ArtworkScreeningContainer>
      </Flex>
    </MainPageBlock>
  );
};

const MainPageBlock = styled.div`
  padding-top: 40px;
  max-width: 1040px !important;
`;

const FavoriteMenu = styled.div``;

const ArtworkScreeningContainer = styled(Flex)`
  border-radius: 12px;
  background-color: ${palette.PRIMARY10};
  border: 1px solid transparent;
  height: 100%;
  height: 312px;
  padding: 24px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${palette.PRIMARY50};
    box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
  }
`;

export default MainPage;

import { memo } from 'react';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { Button, Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { CompositionInformation } from 'types/material/msds';
import palette from 'lib/styles/palette';
import { getExeceptKoreanRule } from 'lib/validate';
import { MinusIcon, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const TextWithAsterisk = styled(Typography.Text)`
  &::before {
    content: '*';
    color: ${palette.PRIMARY50};
  }
`;

const MSDSCompositionInformationsBlock = styled.div`
  margin-bottom: 32px;

  .ant-table {
    overflow: visible;
  }

  th.ant-table-cell {
    text-align: center;
  }

  td.ant-table-cell {
    padding: 10px 4px !important;
    vertical-align: top;

    .ant-form-item {
      margin-bottom: 0;

      .ant-form-item-control-input {
        min-height: 22px;

        .ant-input {
          padding: 8px;
          border-color: transparent;
          background-color: transparent;
          text-align: center;

          &:hover,
          &:focus {
            border-color: ${palette.PRIMARY50};
            background-color: #fff;
          }
        }
      }
    }
  }
`;

const MSDSCompositionInformations = () => {
  const normalizeInput = (value: string) => value.replace('\n', '');

  return (
    <MSDSCompositionInformationsBlock>
      <TextWithAsterisk type="BODY_2" gutter={{ bottom: 4 }}>
        현재 페이지에서 수정되는 Data는 'Composition Information' 페이지의 정보에 영향을 주지
        않습니다.
      </TextWithAsterisk>
      <Form.Item
        shouldUpdate={(prev, next) =>
          prev.compositionInformations?.length !== next.compositionInformations?.length
        }
      >
        {({ getFieldValue, setFieldsValue }) => {
          const columns: ColumnsType<CompositionInformation> = [
            {
              title: 'No.',
              align: 'center',
              width: 40,
              render: (_, __, index) => (
                <div style={{ position: 'relative' }}>
                  {index > 0 && (
                    <MinusIcon
                      style={{ position: 'absolute', left: -30 }}
                      onClick={() => {
                        const newCompositionInformations = [
                          ...(getFieldValue('compositionInformations') || []),
                        ];
                        newCompositionInformations.splice(index, 1);
                        setFieldsValue({
                          compositionInformations: newCompositionInformations,
                        });
                      }}
                    />
                  )}
                  <Typography.Text type="BODY_2" gutter={{ top: 10 }}>
                    {index + 1}
                  </Typography.Text>
                </div>
              ),
            },
            {
              title: 'INCI Name',
              width: 444,
              render: (_, __, index) => (
                <Form.Item
                  name={['compositionInformations', index, 'inciNameEn']}
                  normalize={normalizeInput}
                  rules={[
                    getExeceptKoreanRule({
                      start: 1,
                      end: 1000,
                      message: '한글 입력 불가, 최대 1000자',
                    }),
                  ]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    style={{ textAlign: 'left' }}
                    placeholder="INCI Name 입력"
                  />
                </Form.Item>
              ),
            },
            {
              title: 'WT %',
              dataIndex: 'wt',
              width: 128,
              render: (_, __, index) => (
                <Form.Item
                  name={['compositionInformations', index, 'wt']}
                  normalize={normalizeInput}
                  rules={[
                    getExeceptKoreanRule({
                      start: 1,
                      end: 1000,
                      message: '한글 입력 불가, 최대 1000자',
                    }),
                  ]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    style={{ textAlign: 'right' }}
                    placeholder="함량 입력"
                  />
                </Form.Item>
              ),
            },
            {
              title: 'CAS NO.',
              width: 128,
              render: (_, __, index) => (
                <Form.Item
                  name={['compositionInformations', index, 'casNo']}
                  normalize={normalizeInput}
                  rules={[
                    getExeceptKoreanRule({
                      start: 1,
                      end: 1000,
                      message: '한글 입력 불가, 최대 1000자',
                    }),
                  ]}
                >
                  <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} placeholder="CAS No." />
                </Form.Item>
              ),
            },
            {
              title: 'EC NO.',
              width: 128,
              render: (_, __, index) => (
                <Form.Item
                  name={['compositionInformations', index, 'euCasNo']}
                  normalize={normalizeInput}
                  rules={[
                    getExeceptKoreanRule({
                      start: 1,
                      end: 1000,
                      message: '한글 입력 불가, 최대 1000자',
                    }),
                  ]}
                >
                  <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} placeholder="EC No." />
                </Form.Item>
              ),
            },
          ];

          return (
            <Table
              columns={columns}
              pagination={false}
              dataSource={getFieldValue(['compositionInformations'])}
              rowKey={({ materialMsdsCompositionInformationId }) =>
                materialMsdsCompositionInformationId || nanoid()
              }
            />
          );
        }}
      </Form.Item>
      <Form.List name="compositionInformations">
        {(_, { add }) => (
          <Flex justify="center">
            <Button
              type="dashed"
              onClick={() => {
                add({});
              }}
              block
              icon={<Icon name="plus" size={14} color="PRIMARY50" />}
              style={{
                width: 520,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              신규 입력 항목 추가
            </Button>
          </Flex>
        )}
      </Form.List>
    </MSDSCompositionInformationsBlock>
  );
};

export default memo(MSDSCompositionInformations);

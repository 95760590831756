import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import parse from 'html-react-parser';

import { createRequestActionTypes, createRequestSaga } from 'lib/saga';
import * as serviceApi from 'lib/api/service';
import { finishLoading, startLoading } from 'modules/loading';
import history from 'lib/history';

const [GET_NOTICES, GET_NOTICES_SUCCESS] = createRequestActionTypes('service/GET_NOTICES');
const SET_POST = 'service/SET_POST';
const [GET_USER_NOTICES, GET_USER_NOTICES_SUCCESS] = createRequestActionTypes(
  'service/GET_USER_NOTICES',
);
const ADD_USER_NOTICE = 'service/ADD_USER_NOTICE';
const [SET_USER_NOTICES_CONFIRM, SET_USER_NOTICES_CONFIRM_SUCCESS] = createRequestActionTypes(
  'service/SET_USER_NOTICES_CONFIRM',
);
const [USER_NOTICES_CONFIRM_ALL, USER_NOTICES_CONFIRM_ALL_SUCCESS] = createRequestActionTypes(
  'service/USER_NOTICES_CONFIRM_ALL',
);
const SET_USER_NOTICE_DRAWER_VISIBLE = 'service/SET_USER_NOTICE_DRAWER_VISIBLE';

export const getNotices = createAction(GET_NOTICES);
const getNoticesSaga = createRequestSaga(GET_NOTICES, serviceApi.getNotices);
export const setPost = createAction(SET_POST);
export const getUserNotices = createAction(GET_USER_NOTICES);
const getUserNoticesSaga = function* ({ payload: userId }) {
  const showUserNotice = ({ title, content, noticePath }) => {
    notification.open({
      key: 'userNoticeNotification',
      icon: null,
      message: title,
      description: parse(content),
      duration: 0,
      style: { cursor: 'pointer' },
      onClick: () => {
        if (noticePath) {
          if (noticePath === window.location.pathname) {
            window.location.reload();
          } else {
            history.push(noticePath);
          }
        } else if (noticePath === null) {
          notification.destroy('userNoticeNotification');
        }
        notification.destroy('userNoticeNotification');
      },
    });
  };
  yield put(startLoading(GET_USER_NOTICES));
  try {
    const {
      data: { result },
    } = yield call(serviceApi.getUserNotices, userId);
    yield put({
      type: GET_USER_NOTICES_SUCCESS,
      payload: result,
    });
    if (result.length > 0 && !result[0].isConfirm) {
      yield put(setUserNoticesConfirm([result[0].userNoticeId]));
      showUserNotice(result[0]);
    }
  } catch (error) {}
  yield put(finishLoading(GET_USER_NOTICES));
};
export const addUserNotice = createAction(ADD_USER_NOTICE);
export const setUserNoticesConfirm = createAction(SET_USER_NOTICES_CONFIRM);
const setUserNoticesConfirmSaga = function* ({ payload }) {
  yield put(startLoading(SET_USER_NOTICES_CONFIRM));
  try {
    yield put({
      type: SET_USER_NOTICES_CONFIRM_SUCCESS,
      payload,
    });
    yield call(serviceApi.setUserNoticeConfirm, payload);
  } catch (error) {}
  yield put(finishLoading(SET_USER_NOTICES_CONFIRM));
};
export const userNoticesConfirmAll = createAction(USER_NOTICES_CONFIRM_ALL);
const userNoticeConfirmAllSaga = createRequestSaga(
  USER_NOTICES_CONFIRM_ALL,
  serviceApi.userNoticeConfirmAll,
);
export const setUserNoticeDrawerVisible = createAction(SET_USER_NOTICE_DRAWER_VISIBLE);

export function* serviceSaga() {
  yield takeLatest(GET_NOTICES, getNoticesSaga);
  yield takeLatest(GET_USER_NOTICES, getUserNoticesSaga);
  yield takeLatest(SET_USER_NOTICES_CONFIRM, setUserNoticesConfirmSaga);
  yield takeLatest(USER_NOTICES_CONFIRM_ALL, userNoticeConfirmAllSaga);
}

const initialState = {
  notices: [],
  useGuides: [],
  post: null,
  userNotices: [],
  userNoticeDrawerVisible: false,
};

export default handleActions(
  {
    [GET_NOTICES_SUCCESS]: (state, { payload: result }) =>
      produce(state, (draft) => {
        draft.notices = result.content;
      }),
    [SET_POST]: (state, { payload: post }) =>
      produce(state, (draft) => {
        draft.post =
          post === null
            ? null
            : {
                ...post,
                attaches:
                  post.noticeAttaches ||
                  post.faqAttachResponseDtos ||
                  post.useGuideAttachResponseDtos,
              };
      }),
    [GET_USER_NOTICES_SUCCESS]: (state, { payload: result }) =>
      produce(state, (draft) => {
        draft.userNotices = result;
      }),
    [ADD_USER_NOTICE]: (state, { payload: userNotice }) =>
      produce(state, (draft) => {
        draft.userNotices.unshift(userNotice);
      }),
    [SET_USER_NOTICES_CONFIRM_SUCCESS]: (state, { payload: userNoticeIds }) =>
      produce(state, (draft) => {
        userNoticeIds.forEach((userNoticeId) => {
          draft.userNotices.find(
            (userNotice) => userNotice.userNoticeId === userNoticeId,
          ).isConfirm = true;
        });
      }),
    [USER_NOTICES_CONFIRM_ALL_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.userNotices.forEach((userNotice) => {
          userNotice.isConfirm = true;
        });
      }),
    [SET_USER_NOTICE_DRAWER_VISIBLE]: (state, { payload: visible }) =>
      produce(state, (draft) => {
        draft.userNoticeDrawerVisible = visible;
      }),
  },
  initialState,
);

import RawMaterialMSDS from 'components/material/msds/RawMaterialMSDS';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const RawMaterialMSDSPage = () => {
  return (
    <RawMaterialPageTemplate
      documentName="Material Safety Data Sheet"
      notices={[
        '모든 항목은 필수 입력이며 자료 없음, 해당 없음의 경우 No data, None 등과 같은 영문을 입력해 주세요.',
        '각 항목의 내용은 정제수 기준으로 자동 입력되어 있으며 MSDS 내에서 자유롭게 수정할 수 있습니다.',
        '현재 페이지에서 입력되는 내용은 MSDS 서류에만 반영되므로, 다른 원료 정보 입력 페이지에는 영향을 주지 않습니다.',
        '이 곳에 올려주시는 데이터는 원료사의 승인 없이는 외부로 반출되지 않습니다.',
        <MistakeNoticeItem />,
      ]}
    >
      <RawMaterialMSDS />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialMSDSPage;

import { Button, Spin, message } from 'antd';
import moment from 'moment';
import { ChangeEventHandler, DragEventHandler, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { useModal } from 'hook/useModal';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { nanoid } from 'nanoid';
import { CompanyDocsDataParmas } from 'service/brand/company';
import { useGetProductsWithDocsHistory } from 'service/brand/qcqa/qcqa';
import { useUploadFiles } from 'service/common';
import { CompanyDocsAttach } from 'types/brand/company';
import { messages } from 'lib/consts';

const Container = styled.div`
  margin-top: 40px;
`;

const DocsContainer = styled(Flex)`
  padding-top: 24px;
  width: 100%;
`;

const CollapseContainer = styled(Flex)<{
  open?: boolean;
  isDragging?: boolean;
  visible?: boolean;
  isDraggableIconHover?: boolean;
  fileDragging?: boolean;
}>`
  width: 1040px;
  box-shadow: 0px 0px 0px 1px ${palette.SLATE_GRAY30} !important;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  background-color: ${({ open }) => (open ? `${palette.ETC_BG_BLUE}` : 'white')};
  transition: background-color 300ms ease;
  transition: box-shadow 300ms ease;
  transition: background-color 300ms ease;

  .draggable_icon {
    display: none;
  }

  &:hover {
    .draggable_icon {
      cursor: pointer;
      position: absolute;
      left: 18px;
      display: flex;
      align-items: center;
    }
  }

  ${({ isDraggableIconHover }) =>
    isDraggableIconHover &&
    css`
      transition: display 200ms ease;
      .tooltip {
        display: block;
      }
    `};
  border: 2px dashed transparent;
  transition: border 200ms ease;

  ${({ fileDragging }) =>
    fileDragging &&
    `
      border: 2px dashed ${palette.PRIMARY50} !important;
      box-shadow: none !important;
      background-color: ${palette.PRIMARY10};
    `}
`;

const CollapseHeaderContainer = styled(Flex)`
  width: 1040px;
  padding: 24px 36px;
`;

const CollapseBodyContainer = styled(Flex)<{ open: boolean }>`
  width: 100%;
`;

const DocTitle = styled(Typography.Text)<{ visible?: boolean }>`
  font-weight: 700;
  font-size: 16px;
  color: ${({ visible }) => (visible ? palette.SLATE_GRAY70 : palette.SLATE_GRAY40)};
  user-select: none;
`;

const CertificatonTip = styled(Flex)`
  width: 64px;
  height: 20px;
  background-color: ${palette.YELLOW_20};
  border-radius: 3px;
  color: ${palette.SLATE_GRAY70};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const HistoriesContainer = styled(Flex)`
  width: 100%;
  margin-bottom: 24px;
`;

const RowContainer = styled(Flex)<{
  isDragging?: boolean;
  visible?: boolean;
  isDraggableIconHover?: boolean;
  fileDragging?: boolean;
}>`
  background-color: ${palette.ETC_WHITE};
  margin: 0 auto !important;
  border-radius: 8px;
  width: 968px;
  border: 1px solid ${palette.SLATE_GRAY30};
  box-shadow: ${({ isDragging }) =>
    !isDragging ? 'none' : `0px 2px 8px rgba(162, 162, 162, 0.23)`};
  transition: box-shadow 300ms ease;
  transition: background-color 300ms ease;
  transition: opacity 300ms ease-out;
  padding: 24px 36px;

  .draggable_icon {
    display: none;
  }

  &:hover {
    .draggable_icon {
      cursor: pointer;
      position: absolute;
      left: 12px;
      display: flex;
      align-items: center;
    }
  }

  ${({ isDraggableIconHover }) =>
    isDraggableIconHover &&
    css`
      transition: display 200ms ease;
      .tooltip {
        display: block;
      }
    `};

  border: 2px dashed transparent;
  transition: border 200ms ease;

  ${({ fileDragging }) =>
    fileDragging &&
    `
      border: 2px dashed ${palette.PRIMARY50};
      background-color: ${palette.PRIMARY10};
    `}
`;

const DocColumnContainer = styled(Flex)`
  position: relative;
  width: 100%;
`;

const DocsColumnHeader = styled(Flex)`
  width: 904px;
`;

const FileListContainer = styled(Flex)`
  width: 904px;
  margin-top: 16px;
`;

const FileItemContainer = styled(Flex)`
  width: 100%;
  padding: 8px 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
`;

const ScreeningDiv = styled.div`
  background-color: #e6f0ff;
  border-radius: 4px;
  padding: 8px 16px;
  width: 968px;
  font-size: 12px;
  font-weight: 500;
  color: ${palette.SLATE_GRAY70};
`;

const ScreeningButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  padding-left: 7px;
  border-radius: 4px;
  border: 1px solid ${palette.SLATE_GRAY40};
  cursor: pointer;
`;

interface DocsColumnProps {
  qcqaProductDetailId: number;
  column?: {
    description?: string;
    id: string;
    relationType: string;
    name: string;
    visible: boolean;
    editable: boolean;
    open?: boolean;
    attachIds: string[];
    historyIds: string[];
    isForCertification?: boolean;
    code?: string;
  };
  files?: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  }[];
  histories?: {
    qcqaDocumentRecordId?: string | number;
    name: string;
    recordDate: string;
    attachIds: string[];
  }[];
  docsList: CompanyDocsDataParmas | undefined;
  docItemNameValue: {
    [key: string]: string;
  };
  fileInfoList?: CompanyDocsAttach[];
  activePanelKey?: string;
  isActive?: boolean;
  fileMapBeforeUploading: {
    [key: string]: CompanyDocsAttach[];
  };
  screeningFileData: Record<string, File | undefined>;
  setDocsList: React.Dispatch<React.SetStateAction<CompanyDocsDataParmas | undefined>>;
  setDocItemNameValue: React.Dispatch<React.SetStateAction<{ [columnId: string]: string }>>;
  setFileMapBeforeUploading: React.Dispatch<
    React.SetStateAction<{
      [key: string]: CompanyDocsAttach[];
    }>
  >;
  setScreeningFileData: React.Dispatch<React.SetStateAction<Record<string, File | undefined>>>;
  onDeleteHistoryItem: (props: DeleteHistoryItemProps) => void;
  _ref: (ref: HTMLDivElement | null) => void;
}

interface DeleteHistoryItemProps {
  column: {
    id: string;
    relationType: string;
    name: string;
    visible: boolean;
    editable: boolean;
    open?: boolean | undefined;
    attachIds: string[];
    historyIds: string[];
    code?: string | undefined;
  };
  history: {
    qcqaDocumentRecordId?: string | number | undefined;
    name: string;
    recordDate: string;
    attachIds: string[];
  };
}

const FORMULA_BREAKDOWN_CODE = 'CODE_044';

const FileItem = ({
  file,
  onDownload,
  onDelete,
}: {
  file: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  };
  onDownload?: VoidFunction;
  onDelete?: VoidFunction;
}) => {
  return (
    <FileItemContainer justify="space-between">
      <Typography.Text type="SMALL" color="SLATE_GRAY70">
        {file.filename}
      </Typography.Text>
      <Flex columnGap={26}>
        <Typography.Text type="SMALL" color="GRAY50">
          {file.registerDt.slice(0, 10)}
        </Typography.Text>
        <Flex align="center" style={{ height: 18, cursor: 'pointer' }} onClick={onDownload}>
          <Icon name="download" size={16} color="GRAY60" />
        </Flex>
        <Flex align="center" style={{ height: 18, cursor: 'pointer' }} onClick={onDelete}>
          <Icon name="delete" size={18} color="GRAY60" />
        </Flex>
      </Flex>
    </FileItemContainer>
  );
};

const HistoryItem = ({
  qcqaProductDetailId,
  column,
  history,
  docsList,
  docHistoryItemNameValue,
  docHistoryDtValue,
  fileInfoList,
  loading,
  preventFileHandling,
  screeningFile,
  colFileDragState,
  rowFileDragState,
  onUpload,
  onDownload,
  onDelete,
  setDocsList,
  setDocHistoryItemNameValue,
  setDocHistoryDtValue,
  onDeleteHistoryItem,
  setRowFileDragState,
  setColFileDragState,
}: {
  qcqaProductDetailId: number;
  column: {
    id: string;
    relationType: string;
    name: string;
    visible: boolean;
    editable: boolean;
    open?: boolean;
    attachIds: string[];
    historyIds: string[];
    code?: string;
  };
  history: {
    qcqaDocumentRecordId?: string | number;
    name: string;
    recordDate: string;
    attachIds: string[];
  };
  files?: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  }[];
  index: number;
  docsList: CompanyDocsDataParmas | undefined;
  docHistoryItemNameValue: {
    [key: string]: string;
  };
  docHistoryDtValue: {
    [key: string]: string;
  };
  screeningFile?: Record<string, File | undefined>;
  fileInfoList?: CompanyDocsAttach[];
  preventFileHandling?: boolean;
  loading?: boolean;
  colFileDragState: 'none' | 'dragging';
  rowFileDragState: {
    [rowId: string]: 'none' | 'dragging';
  };
  onUpload: (filesList: FileList | File[], isScreening?: boolean) => void;
  onDownload: (fileIndex: number) => void;
  onDelete: (fileIndex: number) => void;
  setDocsList: React.Dispatch<React.SetStateAction<CompanyDocsDataParmas | undefined>>;
  setDocHistoryItemNameValue: React.Dispatch<React.SetStateAction<{ [htId: string]: string }>>;
  setDocHistoryDtValue: React.Dispatch<React.SetStateAction<{ [htId: string]: string }>>;
  onDeleteHistoryItem: (props: DeleteHistoryItemProps) => void;
  setRowFileDragState: React.Dispatch<
    React.SetStateAction<{
      [rowId: string]: 'none' | 'dragging';
    }>
  >;
  setColFileDragState: React.Dispatch<React.SetStateAction<'none' | 'dragging'>>;
}) => {
  const [hoverCount, setHoverCount] = useState(0);
  const fileRef = useRef<HTMLInputElement>(null);
  const { openDocsHistoryModal, openQCQAFormulaBreakdownModal } = useModal();

  const handleDeleteHistoryItem = () => {
    if (!history) return;

    onDeleteHistoryItem({
      column,
      history,
    });
  };

  useEffect(() => {
    if (!docsList) return;

    const histories = docsList.columns[column?.id]?.historyIds?.map(
      (htId) => docsList.histories[htId],
    );

    const newDocHistoryDtValue = { ...docHistoryDtValue };
    const newDocHistoryItemNameValue = { ...docHistoryItemNameValue };

    for (const history of histories) {
      if (!history?.qcqaDocumentRecordId) return;
      newDocHistoryDtValue[history?.qcqaDocumentRecordId] = history?.recordDate;
      newDocHistoryItemNameValue[history?.qcqaDocumentRecordId] = history?.name;
    }

    setDocHistoryDtValue(newDocHistoryDtValue);
    setDocHistoryItemNameValue(newDocHistoryItemNameValue);
  }, []);

  const handleStopAction = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const referToFile = () => fileRef.current?.click();

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) return;
    onUpload(e.target.files);
    e.target.value = '';
  };

  const handleFileDownload = (fileIndex: number) => () => {
    onDownload(fileIndex);
  };

  const handleFileDelete = (fileIndex: number) => () => onDelete(fileIndex);

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = (e) => {
    setHoverCount((count) => count + 1);
    setRowFileDragState((prev) => {
      const newObj = prev;
      if (!history.qcqaDocumentRecordId) return prev;
      newObj[history.qcqaDocumentRecordId] = 'dragging';
      return newObj;
    });
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1) {
      setRowFileDragState((prev) => {
        const newObj = prev;
        if (!history.qcqaDocumentRecordId) return prev;
        newObj[history.qcqaDocumentRecordId] = 'none';
        return newObj;
      });
    }
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    setColFileDragState('none');
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if ([...e.dataTransfer.files].filter((file) => file.type === '').length > 0) {
      if (
        [...e.dataTransfer.files].filter((file) => file.type === '' && !file.name.includes('.'))
      ) {
        message.warning('파일 형식만 업로드해주세요.');
        return;
      }
      message.warning('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    onUpload(e.dataTransfer.files);
  };

  const handleScreeningClick = (historyId: string | number | undefined) => {
    if (preventFileHandling || !screeningFile || !historyId) return;

    openQCQAFormulaBreakdownModal({
      qcqaRelationDocumentRecordId: !isNaN(Number(history.qcqaDocumentRecordId))
        ? Number(history.qcqaDocumentRecordId)
        : 0,
      qcqaProductDetailId,
      onRegister: onUpload,
      screeningFile: screeningFile[!isNaN(Number(historyId)) ? historyId : String(historyId)],
    });
  };

  if (history) {
    return (
      <RowContainer
        style={{ padding: '16px 32px', cursor: 'default' }}
        dir="row"
        align="center"
        visible={column?.visible}
        fileDragging={rowFileDragState[history.qcqaDocumentRecordId!] === 'dragging'}
        onDragEnter={preventFileHandling ? undefined : handleFileDragEnter}
        onDragLeave={handleFileDragLeave}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={preventFileHandling ? undefined : handleFileDragUpload}
        onClick={handleStopAction}
      >
        <DocColumnContainer dir="column">
          <DocsColumnHeader justify="space-between" align="center">
            <input
              type="file"
              ref={fileRef}
              multiple={true}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
            <Flex gap={8} align="center">
              <Typography.Text
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: palette.SLATE_GRAY60,
                }}
              >
                {history.recordDate.slice(0, 10)}
              </Typography.Text>
              <DocTitle visible={true}>{history.name}</DocTitle>
              <Icon
                name="write"
                size={18}
                color="SLATE_GRAY70"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (!history.qcqaDocumentRecordId) return;
                  handleStopAction(e);
                  openDocsHistoryModal({
                    historyId: String(history.qcqaDocumentRecordId),
                    updateMode: true,
                    docHistoryDtValue: docHistoryDtValue,
                    docHistoryItemNameValue: docHistoryItemNameValue,
                    onSubmit: (itemName, historyDt) => {
                      if (!docsList || !history.qcqaDocumentRecordId) return;
                      setDocsList({
                        ...docsList,
                        histories: {
                          ...docsList.histories,
                          [String(history.qcqaDocumentRecordId)]: {
                            ...docsList.histories[history.qcqaDocumentRecordId],
                            name: itemName,
                            recordDate: historyDt,
                          },
                        },
                      });

                      setDocHistoryItemNameValue({
                        ...docHistoryItemNameValue,
                        [history.qcqaDocumentRecordId]: itemName,
                      });

                      setDocHistoryDtValue({
                        ...docHistoryDtValue,
                        [history.qcqaDocumentRecordId]: historyDt,
                      });
                      message.success('수정되었습니다.');
                    },
                  });
                }}
              />
            </Flex>
            <Flex gap={16} align="center">
              {column.code === FORMULA_BREAKDOWN_CODE && (
                <ScreeningButton
                  role="button"
                  onClick={() => handleScreeningClick(history.qcqaDocumentRecordId)}
                >
                  <Icon name="screening" size={18} />
                  <Typography.Text type="SMALL" bold>
                    스크리닝
                  </Typography.Text>
                </ScreeningButton>
              )}
              <Flex
                gap={4}
                role="button"
                align="center"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  handleDeleteHistoryItem();
                }}
              >
                <Icon name="delete" size={16} color="SLATE_GRAY70" />
                <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                  삭제
                </Typography.Text>
              </Flex>

              <Flex
                gap={4}
                role="button"
                align="center"
                style={{ cursor: 'pointer' }}
                onClick={preventFileHandling ? undefined : referToFile}
              >
                <Icon name="plus" size={16} color="SLATE_GRAY70" />
                <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                  파일추가
                </Typography.Text>
              </Flex>
            </Flex>
          </DocsColumnHeader>
          {loading && (
            <Flex justify="center" align="center" dir="column" rowGap={4} style={{ width: '100%' }}>
              <LoadingIndicator size="sm" />
              <Typography.Text type="SMALL" color="SLATE_GRAY70" bold>
                파일을 업로드 하는 중...
              </Typography.Text>
            </Flex>
          )}
          {!loading && fileInfoList && fileInfoList?.length > 0 && (
            <FileListContainer dir="column" justify="space-between" gap={8}>
              {fileInfoList.map((file, fileIdx) => {
                return (
                  <FileItem
                    key={file?.attachId}
                    file={file}
                    onDownload={handleFileDownload(fileIdx)}
                    onDelete={preventFileHandling ? undefined : handleFileDelete(fileIdx)}
                  />
                );
              })}
            </FileListContainer>
          )}
        </DocColumnContainer>
      </RowContainer>
    );
  } else {
    return <></>;
  }
};

const CollapseColumn = ({
  qcqaProductDetailId,
  column,
  histories,
  docsList,
  docItemNameValue,
  fileMapBeforeUploading,
  screeningFileData,
  setDocsList,
  setDocItemNameValue,
  setFileMapBeforeUploading,
  setScreeningFileData,
  onDeleteHistoryItem,
  _ref,
}: DocsColumnProps) => {
  const [docHistoryItemNameValue, setDocHistoryItemNameValue] = useState<{
    [htId: string]: string;
  }>({});
  const [uploadingDocumentsMap, setUploadingDocumentsMap] = useState<Record<string, boolean>>({});

  const [docHistoryDtValue, setDocHistoryDtValue] = useState<{
    [htId: string]: string;
  }>({});
  const [hoverCount, setHoverCount] = useState(0);
  const [colFileDragState, setColFileDragState] = useState<'none' | 'dragging'>('none');
  const [rowFileDragState, setRowFileDragState] = useState<{
    [rowId: string]: 'none' | 'dragging';
  }>({});
  const [sameHistoryNameCount, setSameHistoryNameCount] = useState<{
    [columnId: string]: number;
  }>({});

  const {
    openDocsHistoryModal,
    openDocsItemModal,
    openAlertModal,
    closeQCQAFormulaBreakdownModal,
  } = useModal();

  const { uploadFilesAsync } = useUploadFiles();

  useEffect(() => {
    if (!column || !docsList) return;

    const count =
      Object.values(docsList?.histories).filter((item) => item.name.split(' (')[0] === column.name)
        .length - 1;

    setSameHistoryNameCount((prev) => {
      const newObj = prev;

      newObj[column?.id] = count;
      return newObj;
    });
  }, [docsList]);

  const isRowDragging = Object.values(rowFileDragState).find((val) => val === 'dragging');

  const handleCollapse = () => {
    if (!column || !docsList) return;

    const newColumns = {
      ...docsList.columns,
    };

    //HINT: 모달 중복 열림 허용하지 않을 때
    // for (const id of Object.keys(docsList.columns)) {
    // if (id !== column.id) {
    //   newColumns[id].open = false;
    // } else {
    // newColumns[column.id].open = !docsList.columns[column.id].open;
    // }
    // }
    newColumns[column.id].open = !docsList.columns[column.id].open;

    setDocsList({
      ...docsList,
      columns: newColumns,
    });
  };

  const handleStopAction = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const addHistoryItem = (columnId: string) => {
    if (!docsList) return;

    openDocsHistoryModal({
      onSubmit: (itemName, historyDt) => {
        const newHistoryName = nanoid();

        const newHistories = {
          ...docsList.histories,
          [newHistoryName]: {
            qcqaDocumentRecordId: newHistoryName,
            name: itemName,
            recordDate: historyDt,
            attachIds: [],
            qcqaDocumentRecordAttachIds: [],
          },
        };

        docsList.columns[columnId].historyIds.push(newHistoryName);

        setDocHistoryItemNameValue({
          ...docHistoryItemNameValue,
          [newHistoryName]: itemName,
        });

        setDocHistoryDtValue({
          ...docHistoryDtValue,
          [newHistoryName]: historyDt,
        });

        setDocsList({
          ...docsList,
          histories: newHistories,
        });
        message.success('추가되었습니다.');
      },
    });
  };

  const now = moment().format('YYYY-MM-DD');

  const handleFileUpload =
    (columnId: string, historyId: string, isFirstHistory: boolean = false) =>
    async (fileList: FileList | File[], isScreening: boolean = false) => {
      if (uploadingDocumentsMap[historyId]) {
        return;
      }

      const files: File[] = [];
      for (const file of fileList) {
        files.push(file);
      }

      if (!(historyId in fileMapBeforeUploading)) {
        fileMapBeforeUploading[historyId] = [];
      }

      if (isScreening) {
        files.forEach((file) => {
          setScreeningFileData({
            ...screeningFileData,
            [historyId]: file,
          });
        });
        closeQCQAFormulaBreakdownModal();
      }

      setUploadingDocumentsMap((prevMap) => {
        prevMap[historyId] = true;

        return { ...prevMap };
      });

      const res = await uploadFilesAsync({
        files,
        uploadDestinationType: 'QCQA',
      });
      const fileLists: CompanyDocsAttach[] = [];

      for (const uploadFileRes of res.data.result) {
        fileLists.push({
          ...uploadFileRes,
          qcqaDocumentRecordAttachId: uploadFileRes.attachId,
          registerDt: now,
        });
      }

      setFileMapBeforeUploading((prevMap) => {
        prevMap[historyId] = prevMap[historyId].concat(fileLists);

        return { ...prevMap };
      });

      if (fileLists.length === 0) {
        message.warning(messages.SHOULD_CHECK_FILE);
      } else {
        message.success('정상적으로 업로드되었습니다.');
      }

      setUploadingDocumentsMap((prevMap) => {
        prevMap[historyId] = false;

        return { ...prevMap };
      });

      if (!docsList) return;

      //HINT: 파일 업로드시 files,columns,histories 수정
      setDocsList((prev) => {
        if (!prev) return;
        const newFiles = prev.files;
        const newHistories = prev.histories;

        const newAttachIds = [...(prev.histories[historyId]?.attachIds || [])];
        const newDocumentRecordAttachIds = [
          ...(prev.histories[historyId]?.qcqaDocumentRecordAttachIds || []),
        ];
        const newColumnsAttachIds = [...(prev.columns[columnId].attachIds || [])];

        const newColumnsHistoryIds = [...(prev.columns[columnId].historyIds || [])];

        Object.values(newHistories).forEach((val) => {
          if (!column) return;
          const nameWithoutNum = column.name.split('(')[0];

          if (nameWithoutNum === val.name) {
            setSameHistoryNameCount((prev) => {
              const newObj = prev;
              newObj[columnId] = prev[columnId] > 0 ? (prev[columnId] += 1) : 1;

              return newObj;
            });
          }
        });

        const count = sameHistoryNameCount[columnId];

        const historyName = `${column?.name}${count && count > 0 ? ` (${count})` : ''}`;

        fileLists.forEach((file) => {
          newAttachIds.push(String(file.attachId));
          newDocumentRecordAttachIds.push(String(file.qcqaDocumentRecordAttachId));
          newColumnsAttachIds.push(String(file.attachId));

          //files에 추가
          newFiles[file.attachId] = {
            attachId: file.attachId,
            filename: file.filename,
            uploadFileUrl: file.uploadFileUrl,
            registerDt: file.registerDt,
            qcqaDocumentRecordAttachId: file.qcqaDocumentRecordAttachId,
          };
          if (!column) return;

          // 기존 histories에 추가
          newHistories[historyId] = {
            qcqaDocumentRecordId: isFirstHistory
              ? historyId
              : prev.histories[historyId].qcqaDocumentRecordId,
            name: isFirstHistory ? historyName : newHistories[historyId].name,
            recordDate: isFirstHistory ? now : newHistories[historyId].recordDate,
            attachIds: newAttachIds,
            qcqaDocumentRecordAttachIds: newDocumentRecordAttachIds,
          };
        });

        return {
          ...prev,
          files: newFiles,
          histories: newHistories,
          columns: {
            ...prev.columns,
            [columnId]: {
              ...prev.columns[columnId],
              historyIds: isFirstHistory
                ? newColumnsHistoryIds.concat([historyId])
                : newColumnsHistoryIds,
              attachIds: newColumnsAttachIds,
            },
          },
        };
      });
    };

  const handleFileDelete = (columnId: string, historyId: string) => (fileIndex: number) => {
    const fileInfoList =
      fileMapBeforeUploading[isNaN(Number(historyId)) ? historyId : Number(historyId)];

    if (!fileInfoList) return;
    const fileInfo = fileInfoList[fileIndex];

    openAlertModal({
      content: `‘${fileInfo.filename}’ 파일을 삭제하시겠습니까?`,
      okText: '확인',
      closeText: '취소',
      onOk: () => {
        if (!fileInfo.attachId) {
          message.warning('새로고침 후 다시 이용해주세요.');
          return;
        }
        fileInfoList.splice(fileIndex, 1);
        setFileMapBeforeUploading({ ...fileMapBeforeUploading });

        if (!docsList) return;

        setDocsList((list) => {
          if (!list) return;
          const docs = { ...list };
          const newAttachIds = fileInfoList.map((item) => String(item.attachId));
          const newDocumentRecordAttachIds = docs.histories[historyId].qcqaDocumentRecordAttachIds;

          const docFileIndex = docs.histories[historyId].qcqaDocumentRecordAttachIds.indexOf(
            String(fileInfo.attachId),
          );
          newDocumentRecordAttachIds.splice(docFileIndex, 1);

          list.histories[historyId].attachIds = newAttachIds;
          list.histories[historyId].qcqaDocumentRecordAttachIds = newDocumentRecordAttachIds;

          delete docs.files[fileInfo.attachId];
          delete docs.files[fileInfo.qcqaDocumentRecordAttachId];

          return docs;
        });

        message.success('삭제되었습니다.');
      },
    });
  };

  const handleFileDownload = (columnId: string, historyId: string) => (fileIndex: number) => {
    const fileInfo = (fileMapBeforeUploading[
      isNaN(Number(historyId)) ? historyId : Number(historyId)
    ] ?? [])[fileIndex];

    if (!fileInfo) return;
    downloadFile(fileInfo.uploadFileUrl, fileInfo.filename);
    message.success('정상적으로 다운로드되었습니다.');
  };

  const customSort = (
    a: {
      qcqaDocumentRecordId?: string | number | undefined;
      name: string;
      recordDate: string;
      attachIds: string[];
    },
    b: {
      qcqaDocumentRecordId?: string | number | undefined;
      name: string;
      recordDate: string;
      attachIds: string[];
    },
  ) => {
    const dateA = new Date(a.recordDate.slice(0, 10));
    const dateB = new Date(b.recordDate.slice(0, 10));
    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }

    const koreanOrderA = a.name;
    const koreanOrderB = b.name;
    if (koreanOrderA < koreanOrderB) {
      return -1;
    }
    if (koreanOrderA > koreanOrderB) {
      return 1;
    }

    return 0;
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1 && !isRowDragging) {
      setColFileDragState('none');
    }
  };

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = (e) => {
    setHoverCount((count) => count + 1);

    setColFileDragState('dragging');
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if ([...e.dataTransfer.files].filter((file) => file.type === '').length > 0) {
      message.warning('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }
    if (!column) return;

    const fileUpload = handleFileUpload(column.id, `ht-${moment.now()}`, true);
    fileUpload(e.dataTransfer.files);
  };

  if (column) {
    return (
      <CollapseContainer
        open={column.open}
        dir="column"
        align="center"
        ref={_ref}
        fileDragging={column.open && colFileDragState === 'dragging' && !isRowDragging}
        onDragEnter={column.open ? handleFileDragEnter : undefined}
        onDragLeave={column.open ? handleFileDragLeave : undefined}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={column.open && !isRowDragging ? handleFileDragUpload : undefined}
      >
        <CollapseHeaderContainer
          justify="space-between"
          onClick={() => {
            handleCollapse();
          }}
        >
          <Flex align="center" gap={8}>
            <DocTitle style={{ fontWeight: 500 }} visible={column.visible}>
              {column.name}
            </DocTitle>
            {column.open && column.description && (
              <div onClick={handleStopAction}>
                <Tip trigger="click">{column.description}</Tip>
              </div>
            )}
            {column.open && column.isForCertification && (
              <CertificatonTip align="center" justify="center" textAlign="center">
                인증 활용
              </CertificatonTip>
            )}
            {column?.editable && column.open && (
              <Icon
                name="write"
                size={18}
                color="SLATE_GRAY70"
                style={{ cursor: 'pointer' }}
                onClick={(e: React.MouseEvent) => {
                  handleStopAction(e);
                  openDocsItemModal({
                    columnId: column.id,
                    updateMode: true,
                    docItemName: docItemNameValue,
                    onSubmit: (itemName) => {
                      if (!docsList) return;
                      setDocsList({
                        ...docsList,
                        columns: {
                          ...docsList.columns,
                          [column.id]: {
                            ...docsList.columns[column.id],
                            name: itemName,
                          },
                        },
                      });
                      setDocItemNameValue({
                        ...docItemNameValue,
                        [column.id]: itemName,
                      });
                      message.success('수정되었습니다.');
                    },
                  });
                }}
              />
            )}
          </Flex>
          <Flex gap={16} align="center">
            <Icon name={column.open ? 'up' : 'down'} color="SLATE_GRAY70" />
          </Flex>
        </CollapseHeaderContainer>
        {column.open && (
          <CollapseBodyContainer
            open={column.open}
            dir="column"
            align="center"
            gap={16}
            onClick={() => {
              handleCollapse();
            }}
          >
            {column.code === FORMULA_BREAKDOWN_CODE && (
              <ScreeningDiv>
                전성분표를 스크리닝하여 등록하면 해외 인증 서비스 진행시 간편하게 사용할 수
                있습니다.
              </ScreeningDiv>
            )}
            <Button
              type="dashed"
              block
              icon={<Icon name="plus" size={14} color="PRIMARY50" />}
              style={{
                width: '968px',
                height: 44,
                marginTop: 16,
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={(e) => {
                handleStopAction(e);
                addHistoryItem(column.id);
              }}
            >
              개정 이력 추가
            </Button>
            <HistoriesContainer dir="column" gap={16}>
              {histories &&
                histories.length > 0 &&
                histories?.sort(customSort)?.map((history, htIdx) => {
                  if (!history?.qcqaDocumentRecordId) return <></>;
                  const files = docsList?.histories[history.qcqaDocumentRecordId]?.attachIds?.map(
                    (id) => docsList.files[id],
                  );

                  const historyId = String(history?.qcqaDocumentRecordId);

                  // if (uploadingDocumentsMap[historyId]) {
                  //   return (
                  //     <UploadingContainer key={history?.qcqaDocumentRecordId}>
                  //       <LoadingIndicator size="sm" />
                  //       <Typography.Text type="SMALL" color="SLATE_GRAY70" bold>
                  //         파일을 업로드 하는 중...
                  //       </Typography.Text>
                  //     </UploadingContainer>
                  //   );
                  // }

                  return (
                    <HistoryItem
                      qcqaProductDetailId={qcqaProductDetailId}
                      key={history?.qcqaDocumentRecordId}
                      index={htIdx}
                      history={history}
                      column={column}
                      files={files}
                      docsList={docsList}
                      setDocsList={setDocsList}
                      fileInfoList={fileMapBeforeUploading[history?.qcqaDocumentRecordId]}
                      loading={uploadingDocumentsMap[history?.qcqaDocumentRecordId]}
                      rowFileDragState={rowFileDragState}
                      setRowFileDragState={setRowFileDragState}
                      colFileDragState={colFileDragState}
                      screeningFile={screeningFileData}
                      onUpload={handleFileUpload(column?.id, historyId)}
                      onDownload={handleFileDownload(column?.id, historyId)}
                      onDelete={handleFileDelete(column?.id, historyId)}
                      docHistoryItemNameValue={docHistoryItemNameValue}
                      docHistoryDtValue={docHistoryDtValue}
                      setDocHistoryItemNameValue={setDocHistoryItemNameValue}
                      setDocHistoryDtValue={setDocHistoryDtValue}
                      setColFileDragState={setColFileDragState}
                      onDeleteHistoryItem={onDeleteHistoryItem}
                    />
                  );
                })}
            </HistoriesContainer>
          </CollapseBodyContainer>
        )}
      </CollapseContainer>
    );
  } else {
    return <></>;
  }
};

const ProductListContainer = styled.div`
  background-color: ${palette.SLATE_GRAY10};
  padding: 16px 40px;
  text-align: start;
`;

const QCQAProductDocs = ({
  screeningFileData,
  qcqaProductId,
  qcqaUserDocumentId,
  docsList,
  fileMapBeforeUploading,
  setFileMapBeforeUploading,
  setDocsList,
  setScreeningFileData,
  isMounted,
  setIsMounted,
}: {
  screeningFileData: Record<string, File | undefined>;
  qcqaProductId: number;
  qcqaUserDocumentId: number;
  docsList: CompanyDocsDataParmas | undefined;
  fileMapBeforeUploading: Record<string, CompanyDocsAttach[]>;
  setFileMapBeforeUploading: React.Dispatch<
    React.SetStateAction<{
      [key: string]: CompanyDocsAttach[];
    }>
  >;
  setDocsList: React.Dispatch<React.SetStateAction<CompanyDocsDataParmas | undefined>>;
  setScreeningFileData: React.Dispatch<React.SetStateAction<Record<string, File | undefined>>>;
  isMounted: boolean;
  setIsMounted: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const refs = useRef<HTMLDivElement[]>([]);
  const [docItemNameValue, setDocItemNameValue] = useState<{
    [columnId: string]: string;
  }>({});

  const { getProductsWithDocsHistory } = useGetProductsWithDocsHistory();
  const { openAlertModal } = useModal();

  const handleDeleteHistoryItem = ({ column, history }: DeleteHistoryItemProps) => {
    const openDeleteSubmitModal = () => {
      openAlertModal({
        content: '개정 이력을 삭제하시겠습니까?',
        onOk: () => {
          setDocsList((list) => {
            if (!list) return;

            const newHistoryIds = [...list.columns[column.id].historyIds];
            const idx = newHistoryIds.indexOf(String(history.qcqaDocumentRecordId));
            newHistoryIds.splice(idx, 1);

            const docs = { ...list };
            if (!history.qcqaDocumentRecordId) return;
            delete docs.histories[history.qcqaDocumentRecordId];

            return {
              ...docs,
              columns: {
                ...docs.columns,
                [column.id]: {
                  ...docs.columns[column.id],
                  historyIds: newHistoryIds,
                },
              },
            };
          });

          message.success('삭제되었습니다.');
        },
      });
    };

    const qcqaRelationDocumentRecordId = Number(history.qcqaDocumentRecordId || '0');
    if (!Number.isInteger(qcqaRelationDocumentRecordId)) {
      openDeleteSubmitModal();
      return;
    }

    getProductsWithDocsHistory(
      { qcqaUserDocumentId: Number(column.id), qcqaRelationDocumentRecordId },
      {
        onSuccess: ({ data: { result } }) => {
          if (result.length > 0) {
            openAlertModal({
              width: 400,
              content: (
                <>
                  <Typography.Text type="BODY_2" style={{ textAlign: 'center', marginBottom: 16 }}>
                    아래 제품에 사용 중인
                    <br />
                    개정 이력이므로 삭제하실 수 없습니다.
                  </Typography.Text>
                  <ProductListContainer>
                    {result.map((product) => (
                      <Typography.Text
                        style={{
                          color: palette.SLATE_GRAY70,
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                        key={product.qcqaProductId}
                      >
                        {`${product.productNameKo || product.productNameEn} : ${product.lotNos
                          .map(({ lotNo }) => lotNo)
                          .join(', ')}`}
                      </Typography.Text>
                    ))}
                  </ProductListContainer>
                </>
              ),
              noCancelButton: true,
            });
          } else {
            openDeleteSubmitModal();
          }
        },
      },
    );
  };

  useEffect(() => {
    if (!qcqaUserDocumentId || isMounted || !docsList) {
      return;
    }
    const index = docsList.columnOrder.findIndex((order) => Number(order) === qcqaUserDocumentId);
    const element = refs.current[index];
    if (element) {
      window.scrollTo({
        // 스크롤 높이 + 요소 위치 - (내비게이션바 높이 80px + margin 16px)
        top: element.getBoundingClientRect().top + window.scrollY - 96,
        behavior: 'smooth',
      });
    }

    const handleCollapse = () => {
      const column = docsList.columns[qcqaUserDocumentId];
      if (!column || !docsList) return;

      const newColumns = {
        ...docsList.columns,
      };
      for (const id of Object.keys(docsList.columns)) {
        if (id !== column.id) {
          newColumns[id].open = false;
        } else {
          newColumns[column.id].open = !docsList.columns[column.id].open;
        }
      }
      setDocsList({
        ...docsList,
        columns: newColumns,
      });
      setIsMounted(true);
    };
    handleCollapse();
  }, [isMounted, docsList]);

  return (
    <Container>
      <Typography.Text
        type="TITLE_1"
        style={{ color: palette.GRAY90, fontWeight: 400, letterSpacing: 0 }}
      >
        제품 서류
      </Typography.Text>
      <Spin size="small" spinning={false}>
        <DocsContainer dir="column">
          {docsList?.columnOrder.map((columnId, index) => {
            const column = docsList.columns[columnId];

            const files = column?.attachIds.map((fileId) => docsList.files[fileId]);

            const histories = column?.historyIds?.map((htId) => docsList.histories[htId]);

            return (
              <CollapseColumn
                _ref={(ref: HTMLDivElement | null) => {
                  if (ref) {
                    refs.current[index] = ref;
                  }
                }}
                qcqaProductDetailId={qcqaProductId}
                column={column}
                files={files}
                histories={histories}
                key={column?.id}
                docsList={docsList}
                setDocsList={setDocsList}
                docItemNameValue={docItemNameValue}
                setDocItemNameValue={setDocItemNameValue}
                fileInfoList={fileMapBeforeUploading[column?.id]}
                fileMapBeforeUploading={fileMapBeforeUploading}
                setFileMapBeforeUploading={setFileMapBeforeUploading}
                screeningFileData={screeningFileData}
                setScreeningFileData={setScreeningFileData}
                onDeleteHistoryItem={handleDeleteHistoryItem}
              />
            );
          })}
        </DocsContainer>
      </Spin>
    </Container>
  );
};

export default QCQAProductDocs;

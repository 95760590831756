import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';

import { downloadFile, getReadableFileSize } from 'lib/file';
import { ProductEtcData } from 'types/product';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const EtcDataTableBlock = styled.div`
  .ant-table {
    position: relative;
    border-radius: 0 !important;
    min-height: 200px;
    overflow: visible;
    border: 1px solid #d3d3d3;
    border-top: none;

    &::before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      height: 42px;
      width: calc(100% + 2px);
      border-left: 1px solid #f5f9ff;
      border-right: 1px solid #f5f9ff;
      border-bottom: 1px solid #d3d3d3;
      z-index: 100;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #f0faff;
    }
    th {
      background: transparent;
      border: none;
    }
    td {
      border-bottom: none;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    th,
    td {
      color: #222;
    }
    th:first-of-type,
    td:first-of-type {
      padding-left: 16px;
    }
  }
`;

const ProductEtcDataTable = ({
  etcDatas,
  loading,
  onDeleteFile,
}: {
  etcDatas: ProductEtcData[];
  loading: boolean;
  onDeleteFile: (index: number) => void;
}) => {
  const columns: ColumnsType<ProductEtcData> = [
    { title: '파일명', width: '45%', dataIndex: 'filename' },
    {
      title: '다운로드',
      align: 'center',
      render: (_, { uploadFileUrl, filename }) => (
        <Icon
          name="download"
          color={uploadFileUrl ? 'GRAY70' : 'GRAY50'}
          size={20}
          onClick={() => downloadFile(uploadFileUrl, filename)}
          style={{ margin: '0 auto' }}
        />
      ),
    },
    {
      title: '크기',
      render: (_, { fileSize }) => getReadableFileSize(fileSize),
    },
    {
      title: '업로드 날짜',
      align: 'center',
      render: (_, { registerDt }) => registerDt.slice(0, 10),
    },
    {
      title: '삭제',
      align: 'center',
      render: (_, __, index) => (
        <Icon
          name="delete"
          color="GRAY70"
          size={20}
          onClick={() => onDeleteFile(index)}
          style={{ margin: '0 auto' }}
        />
      ),
    },
  ];
  return (
    <EtcDataTableBlock>
      <Table
        columns={columns}
        dataSource={etcDatas}
        rowKey={({ productEtcDataId, file }) => productEtcDataId || file!.name}
        loading={loading}
        pagination={false}
      />
    </EtcDataTableBlock>
  );
};

export default ProductEtcDataTable;

import { apiUrls, useBasicMutation, useBasicQuery } from 'service/core';
import { CompanySize, ManufacturerInfo } from 'types/product';

export const useManufacturerInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  // 해외_인증_제조사에서_제조사_정보_조회_로그인_필요_pmc013
  const { data: manufacturerInfo, isLoading } = useBasicQuery<ManufacturerInfo>({
    apiUrl: apiUrls.productManufacturerInfo,
    urlParams: { productId },
    qs: { countryId },
  });

  // 해외_인증_제조사에서_제조사_정보_수정_로그인_필요_pmc014
  const { mutate: updateManufacturerInfo, isLoading: updateManufacturerInfoLoading } =
    useBasicMutation<{
      countryId: number;
      manufacturerIsSmallCompany?: CompanySize;
      manufacturerFeiNumber?: string;
    }>({
      method: 'patch',
      apiUrl: apiUrls.productManufacturerInfo,
      urlParams: { productId },
    });

  return {
    manufacturerInfo,
    isLoading,
    updateManufacturerInfo,
    updateManufacturerInfoLoading,
  };
};

import { APIResponse } from 'types/common';
import { IFRA, IFRAAdd, IFRADefault, IFRAUpdate } from 'types/material/ifra';
import client from '../client';

export const getIFRADefault = async () => {
  const res = await client.get<APIResponse<IFRADefault[]>>(`/material/allergen/tests`);
  return res.data;
};

export const getIFRAs = async (materialId: number) => {
  const res = await client.get<APIResponse<IFRA[]>>(`/material/IFRA?materialId=${materialId}`);
  return res.data;
};

export const getIFRAsByMaterialAllergenListId = async (materialAllergenListId: number) => {
  const res = await client.get<APIResponse<IFRA[]>>(
    `/material/allergen/${materialAllergenListId}/IFRA`,
  );
  return res.data;
};

export const addIFRAs = async (params: IFRAAdd) => {
  const { materialAllergenListId, ...rest } = params;
  const res = await client.post<APIResponse<null>>(
    `/material/allergen/${materialAllergenListId}/IFRA`,
    rest.ingredients,
  );
  return res.data;
};

export const updateIFRAs = async (params: IFRAUpdate) => {
  const { materialAllergenListId, ...rest } = params;
  const res = await client.patch<APIResponse<null>>(
    `/material/allergen/${materialAllergenListId}/IFRA`,
    rest.ingredients,
  );
  return res.data;
};

import styled, { css } from 'styled-components';
import { Menu, MenuProps } from 'antd';
import { useLocation } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

import path from 'lib/path';
import { useToggleDrawer } from 'service/navigator';

const StyledMenu = styled(Menu)`
  ${(props) =>
    props.mode !== 'inline' &&
    css`
      display: flex;
      margin: 0 auto;
      justify-content: center;

      &::before,
      &::after {
        display: none;
      }
    `}

  .ant-menu-title-content {
    font-size: 18px;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-size: 16px;
`;

type MenuItem = Required<MenuProps>['items'][number];

const TopMenu = ({ inlineMode }: { inlineMode: boolean }) => {
  const { pathname } = useLocation();
  const toggleDrawer = useToggleDrawer();

  const menuItems: MenuItem[] = [
    {
      key: '1',
      label: '원료·성분',
      children: [
        {
          key: path.material.rawMaterial.search,
          label: <StyledNavLink to={path.material.rawMaterial.search}>원료 검색</StyledNavLink>,
        },
        {
          key: path.material.ingredient.dictionary,
          label: <StyledNavLink to={path.material.ingredient.dictionary}>성분 사전</StyledNavLink>,
        },
        {
          key: path.formulaScreening,
          label: <StyledNavLink to={path.formulaScreening}>성분 스크리닝</StyledNavLink>,
        },
      ],
    },
    {
      key: '2',
      label: '샘플 처방',
      children: [
        {
          key: path.manufacturer.productSample.basic,
          label: (
            <StyledNavLink to={path.manufacturer.productSample.basic}>신규 샘플 등록</StyledNavLink>
          ),
        },
        {
          key: path.manufacturer.productSample.readyList,
          label: (
            <StyledNavLink to={path.manufacturer.productSample.readyList}>
              등록 중 샘플 관리
            </StyledNavLink>
          ),
        },
        {
          key: path.manufacturer.productSample.list,
          label: (
            <StyledNavLink to={path.manufacturer.productSample.list}>
              확정 완료 샘플 관리
            </StyledNavLink>
          ),
        },
      ],
    },
    {
      key: '3',
      label: '제품 등록',
      children: [
        {
          key: path.manufacturer.product.basic,
          label: <StyledNavLink to={path.manufacturer.product.basic}>신규 제품 등록</StyledNavLink>,
        },
        {
          key: path.manufacturer.product.readyList,
          label: (
            <StyledNavLink to={path.manufacturer.product.readyList}>
              등록 중 제품 관리
            </StyledNavLink>
          ),
        },
        {
          key: path.manufacturer.product.list,
          label: (
            <StyledNavLink to={path.manufacturer.product.list}>등록 완료 제품 관리</StyledNavLink>
          ),
        },
      ],
    },
    {
      key: '4',
      label: <Link to={path.service}>고객센터</Link>,
    },
  ];

  return (
    <StyledMenu
      mode={inlineMode ? 'inline' : 'horizontal'}
      selectedKeys={[pathname]}
      triggerSubMenuAction="click"
      onClick={() => {
        if (inlineMode) {
          toggleDrawer();
        }
      }}
      items={menuItems}
    />
  );
};

export default TopMenu;

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { useParams } from 'react-router-dom';
import { DocumentCode } from 'types/brand/retailer/retailerApply';

const ProductPSPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="제품표준서"
      notices={['제조사 양식 그대로 PDF 파일로 업로드해 주세요.', <MistakeNoticeItem />]}
    >
      <DocumentsUpload productId={productId} documentCode={DocumentCode.PS} />
    </ProductPageTemplate>
  );
};

export default ProductPSPage;

import { Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ContractGuide from 'components/contract/ContractGuide';
import EstimateListContainer from 'containers/estimate/EstimateListContainer';

const Container = styled.div``;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 36px;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

const BrandContractsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultActiveKey = searchParams.get('type') || undefined;

  return (
    <Container>
      <StyledTabs
        defaultActiveKey={defaultActiveKey}
        items={[
          {
            key: 'estimate-list',
            label: '인증 컨설팅 견적서',
            children: <EstimateListContainer />,
          },
          {
            key: 'contract-guide',
            label: '결제 / 계약서 송부 가이드',
            children: <ContractGuide />,
          },
        ]}
      />
    </Container>
  );
};

export default BrandContractsPage;

import { Form, message } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit, pick } from 'lodash';

import ManagerForm from 'components/auth/ManagerForm';
import { Typography } from 'components/system';
import { useManuMode } from 'hook/company';
import { useModal } from 'hook/useModal';
import { deleteUser, resetPassword, updateCompany, updateUser } from 'modules/auth';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { messages } from 'lib/consts';
import { isObjectChanged } from 'lib/form';

const ManagerUpdateContainer = ({ directMode, manager }) => {
  const dispatch = useDispatch();
  const { data: manufacturers = [] } = useManufacturerCompanies();
  const { companyNameKo, submitLoading, deleteLoading } = useSelector(({ company, loading }) => ({
    companyNameKo: company.company.companyNameKo,
    submitLoading: loading['auth/UPDATE_USER'] || loading['auth/UPDATE_COMPANY'],
    deleteLoading: loading['auth/DELETE_USER'],
  }));
  const manuMode = useManuMode();
  const [form] = Form.useForm();
  const { openAlertModal, openChangePasswordModal } = useModal();

  const onSubmit = (user) => {
    const formData = omit(user, [
      'password',
      'userType',
      manager.userType === 'BRAND' ? 'manufacturerId' : '',
    ]);
    const originFormData = pick(manager, [
      'deptName',
      'email',
      manager.userType === 'BRAND' ? 'isPermitFormulaBreakdown' : '',
      'mobile',
      'name',
      'positionName',
      manager.userType === 'MATERIAL' ? 'directTelNumber' : '',
    ]);

    if (!isObjectChanged({ object1: originFormData, object2: formData })) {
      return message.warning(messages.NO_NEED_TO_UPDATE);
    }
    openAlertModal({
      content: (
        <Typography.Text style={{ fontSize: '14px' }}>정말로 수정하시겠습니까?</Typography.Text>
      ),
      onOk: () => {
        if (!directMode) {
          dispatch(updateUser({ ...user, userId: manager.userId }));
        } else {
          dispatch(updateCompany({ user: { ...user, userId: manager.userId } }));
        }
      },
    });
  };
  const onDelete = () => {
    openAlertModal({
      content: (
        <Typography.Text style={{ fontSize: '14px' }}>정말로 삭제하시겠습니까?</Typography.Text>
      ),
      onOk: () => dispatch(deleteUser(manager.userId)),
    });
  };
  const onResetPassword = () => {
    if (!directMode) {
      openAlertModal({
        content: (
          <Typography.Text style={{ fontSize: '14px' }}>
            비밀번호를 초기화하시겠습니까?
          </Typography.Text>
        ),
        onOk: () => dispatch(resetPassword(manager.userId)),
      });
    } else {
      openChangePasswordModal({});
    }
  };
  useEffect(() => {
    form.setFieldsValue({ ...manager, ...(directMode && { companyNameKo }) });
  }, [manager]);
  return (
    <ManagerForm
      updateMode
      directMode={directMode}
      manuMode={manuMode}
      form={form}
      manufacturers={manufacturers}
      submitLoading={submitLoading}
      deleteLoading={deleteLoading}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onResetPassword={onResetPassword}
    />
  );
};
export default ManagerUpdateContainer;

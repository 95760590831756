import { useRoutes } from 'react-router-dom';

import Error404Page from 'pages/error/Error404Page';
import RawMaterialRouter from './RawMaterialRouter';
import MaterialRegisterManagePage from 'pages/material/MaterialRegisterManagePage';
import IngredientRouter from './IngredientRouter';
import ICIDRouter from './ICIDRouter';
import NMPARouter from './NMPARouter';

const MaterialRouter = () => {
  const element = useRoutes([
    {
      path: 'raw-material/*',
      element: <RawMaterialRouter />,
    },
    {
      path: 'ingredient/*',
      element: <IngredientRouter />,
    },
    {
      path: 'manage/:status',
      element: <MaterialRegisterManagePage />,
    },
    {
      path: 'icid/*',
      element: <ICIDRouter />,
    },
    {
      path: 'nmpa/*',
      element: <NMPARouter />,
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);

  return element;
};

export default MaterialRouter;

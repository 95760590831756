import { useRoutes } from 'react-router-dom';

import Error404Page from 'pages/error/Error404Page';
import { DocumentCode } from 'types/material/icid/icid';
import { MSDSPage, TDSPage, CompositionPage, COAPage, MFCPage } from 'pages/material/icid';
import ICIDOngoingPage from 'pages/material/icid/ICIDOngoingPage';
import ICIDRegisteredPage from 'pages/material/icid/ICIDRegisteredPage';
import ICIDBasicPage from 'pages/material/icid/ICIDBasicPage';
import ICIDDocumentListPage from 'pages/material/icid/ICIDDocumentListPage';
import ICIDDetailPage from 'pages/material/icid/ICIDDetailPage';
import ICIDRequestPage from 'pages/material/icid/ICIDRequestPage';
import ICIDRequestListPage from 'pages/material/icid/ICIDRequestListPage';
import ICIDContractPage from 'pages/material/icid/ICIDContractPage';
import ICIDRegisteredDetailPage from 'pages/material/icid/ICIDRegisteredDetailPage';

const ICIDRouter = () => {
  const element = useRoutes([
    { path: 'request', element: <ICIDRequestPage /> },
    { path: 'basic', element: <ICIDBasicPage /> },
    {
      path: `:icidApplicationId/${DocumentCode.BASIC}`,
      element: <ICIDBasicPage />,
    },
    { path: `request-list`, element: <ICIDRequestListPage /> },
    { path: `:icidApplicationId/contract`, element: <ICIDContractPage /> },
    { path: `registered`, element: <ICIDRegisteredPage /> },
    {
      path: `:icidApplicationId/registered`,
      element: <ICIDRegisteredDetailPage />,
    },
    { path: `ongoing`, element: <ICIDOngoingPage /> },
    { path: `:icidApplicationId`, element: <ICIDDocumentListPage /> },
    {
      path: `:icidApplicationId`,
      children: [
        {
          element: <ICIDDocumentListPage />,
          index: true,
        },
        {
          path: `${DocumentCode.DETAIL}`,
          element: <ICIDDetailPage />,
        },
        {
          path: `${DocumentCode.MSDS}`,
          element: <MSDSPage />,
        },
        {
          path: `${DocumentCode.TDS}`,
          element: <TDSPage />,
        },
        {
          path: `${DocumentCode.MCI}`,
          element: <CompositionPage />,
        },
        {
          path: `${DocumentCode.COA}`,
          element: <COAPage />,
        },
        {
          path: `${DocumentCode.MFC}`,
          element: <MFCPage />,
        },
      ],
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);

  return element;
};

export default ICIDRouter;

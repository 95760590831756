import { useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { message } from 'antd';

import * as rawMaterialApi from 'lib/api/material/rawMaterial';
import { APIResponse } from 'types/common';
import path from 'lib/path';
import { base64ToFile, createFormData, downloadFile } from 'lib/file';
import client from 'lib/api/client';
import { DocumentCode, RegisteredRawMaterial } from 'types/material/rawMaterial';
import { useNavigate } from 'react-router-dom';

const queryKeys = {
  detailCategoryInfo: 'material/detailCategoryInfo',
  getMaterialDetail: 'material/getMaterialDetail',
  getKCIAIngredients: 'material/getKCIAIngredients',
  getKCIAIngredientsIsUpdatable: 'material/getKCIAIngredientsIsUpdatable',
};

export interface Efficacy {
  efficacyName: string;
}

export interface EfficacyGet extends Efficacy {
  efficacyCategoryId: number;
  materialCount: number;
  isDefault: boolean;
}

export interface FormulaPurpose {
  formulaPurposeName: string;
}

export interface FormulaPurposeGet extends FormulaPurpose {
  formulaPurposeCategoryId: number;
  materialCount: number;
  isDefault: boolean;
}
export interface RawMaterialBase {
  materialNameEn: string;
  materialNameKo?: string;
  originManufacturerName: string;
  isSelfProduction: boolean;
}

export interface MarketingKeyword {
  keyword: string;
}
export interface MarketingKeywordGet extends MarketingKeyword {
  marketingKeywordId: number;
  keyword: string;
}

export interface RawMaterialGet extends RawMaterialBase {
  materialId: number;
  isDiscontinued: boolean;
  status: 'RM_RDY' | 'RM_REG';
  isLaterUpload: boolean;
  efficacies: EfficacyGet[];
  formulaPurposes: FormulaPurposeGet[];
  marketingKeywords: MarketingKeywordGet[];
  kciaIngredients: {
    kciaIngredientId: number;
    nameEn: string;
    registerDt: string;
  }[];
  retentionCharacteristics: {
    materialCategoryId: number;
    type: string;
    code: string;
    nameKo: string;
    nameEn: string;
    nameCn: string;
    description: string;
  }[];
}
export interface RawMaterialBasicCreateParams {
  materialNameKo?: string;
  materialNameEn: string;
  originManufacturerName: string;
  isSelfProduction: boolean;
  kciaIngredientIds: number[];
  efficacies: Efficacy[];
  formulaPurposes: FormulaPurpose[];
  marketingKeywords?: MarketingKeyword[];
  retentionCharacteristicsIds?: number[];
}

export interface RawMaterialBasicUpdateParams extends RawMaterialBasicCreateParams {}

export interface Efficacy {
  efficacyName: string;
}

export interface FormulaPurpose {
  formulaPurposeName: string;
}

export interface MarketingKeyword {
  keyword: string;
}

export interface RawMaterialDetailCreateParams {
  materialId: number;
  originType: string;
  effectiveIngredients?: {
    ingredientName: string;
    ingredientMinContent: string;
    ingredientUnit: string;
  }[];
  combinationWarningText?: string;
  materialPatents?: {
    patentNumber: string;
  }[];
  madeCountry?: string;
  hsCode?: string;
  manageCode?: string;
  isExperienceSpecialBrandDelivery?: boolean;
  specialBrands?: {
    brandCode: string;
    docResponseType: string;
  };
  materialRemarks?: MaterialRemark[];
  etcRemark?: string;
  materialTheses?: {
    uploadFile: File;
  }[];
}

export interface RawMaterialDetailUpdateParams extends RawMaterialDetailCreateParams {
  deleteMaterialPatents?: boolean;
  deleteMaterialTheses?: boolean;
  deleteMaterialRemarks?: boolean;
  deleteEffectiveIngredients?: boolean;
}

export interface RawMaterialDetail {
  combinationWarningText: string | null;
  hsCode: string | null;
  isExperienceSpecialBrandDelivery: boolean | null;
  madeCountry: string | null;
  materialDetailId: number;
  originType: string;
  specialBrands: SpecialBrand[];
  materialPatents: MaterialPatent[];
  materialTheses: MaterialThesis[];
  materialRemarks: MaterialRemark[];
}

export interface MaterialPatent {
  materialPatentId: number;
  patentNumber: string;
}

export interface MaterialRemark {
  materialRemarkId?: number;
  materialCategoryId: number;
  etcRemark: string;
}

export interface MaterialThesis {
  materialThesisId: number;
  attachUrl: string;
  fileName: string;
}

export interface SpecialBrand {
  specialBrandId: number;
  brandCode: string;
  brandName: string;
  docResponseType: string;
}

export interface DetailCategoryInfo {
  specialBrandTypes: SpecialBrandType[];
  remarkCategories: RemarkCategory[];
}

export interface RemarkCategory {
  materialCategoryId: number;
  type: string;
  code: string;
  nameKo: string;
  nameEn: string;
  nameCn: string;
  description: null;
}

export interface SpecialBrandType {
  name: string;
  code: string;
}

export interface KCIAIngredient {
  kciaIngredientId: number;
  nameEn: string;
  registerDt: string;
}

/**
 * Water 최상단, 숫자, 영문, 기호 순 정렬
 */
const getSortedKCIAIngredients = (kciaIngredients: KCIAIngredient[]) => {
  const ingredients = kciaIngredients.filter(
    ({ nameEn }) => nameEn && nameEn !== '-' && nameEn !== '',
  );
  const patternNumber = /[0-9]/;
  const patternAlphabet = /[a-z]/i;
  const patternOthers = /[^0-9a-z]/i;
  const orderLevelDesc = [patternOthers, patternAlphabet, patternNumber];

  const getLevel = (str: string) => {
    const index = orderLevelDesc.findIndex((pattern) => pattern.test(str));
    return index;
  };

  return ingredients.sort(({ nameEn: a }, { nameEn: b }) => {
    const aLevel = a === 'Water' ? orderLevelDesc.length : getLevel(a.charAt(0));
    const bLevel = b === 'Water' ? orderLevelDesc.length : getLevel(b.charAt(0));

    if (aLevel === bLevel) {
      return a.charCodeAt(0) - b.charCodeAt(0);
    }
    return bLevel - aLevel;
  });
};

export const useRawMaterialDocuments = (materialId: number) => {
  const { data: documents = [], isFetching: getLoading } = useQuery(
    ['material/rawMaterial/getRawMaterialDocuments', materialId],
    () => rawMaterialApi.getDocuments(materialId),
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(() => ({ documents, getLoading }), [documents, getLoading]);
};

export const useRawMaterialNameEnDuplicateCheck = () => {
  const { mutateAsync: rawMaterialNameEnDuplicateCheck } = useMutation((materialNameEn: string) =>
    rawMaterialApi.rawMaterialNameEnDuplicateCheck(materialNameEn),
  );
  return rawMaterialNameEnDuplicateCheck;
};

export const useMadeCountries = () => {
  const { data: madeCountries = [], isFetching: getLoading } = useQuery(
    ['country-list'],
    rawMaterialApi.getMadeCountries,
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(() => ({ madeCountries, getLoading }), [madeCountries, getLoading]);
};

export const useKCIAIngredients = () => {
  const queryClient = useQueryClient();

  const { data: kciaIngredients = [], isLoading: getKCIAIngredientsLoading } = useQuery(
    queryKeys.getKCIAIngredients,
    () => client.get<APIResponse<KCIAIngredient[]>>(`/v1/kcia-ingredients`),
    {
      select: (res) => getSortedKCIAIngredients(res.data.result),
      staleTime: Number.MAX_VALUE,
    },
  );

  const { data: isKCIAIngredientsUpdatable = false } = useQuery(
    queryKeys.getKCIAIngredientsIsUpdatable,
    () =>
      client.get<APIResponse<boolean>>(`/v1/kcia-ingredients/is-updatable`, {
        params: {
          targetDate: kciaIngredients[0].registerDt.slice(0, 10),
        },
      }),
    {
      select: (res) => res.data.result,
      enabled: kciaIngredients.length > 0,
    },
  );

  useEffect(() => {
    if (isKCIAIngredientsUpdatable) {
      queryClient.invalidateQueries(queryKeys.getKCIAIngredients);
    }
  }, [isKCIAIngredientsUpdatable]);

  const kciaIngredientsSelectOptions = useMemo(
    () =>
      kciaIngredients.map((item) => ({
        label: item.nameEn,
        value: item.kciaIngredientId,
      })),
    [kciaIngredients],
  );

  return {
    kciaIngredients,
    getKCIAIngredientsLoading,
    isKCIAIngredientsUpdatable,
    kciaIngredientsSelectOptions,
  };
};

export const useRawMaterial = (materialId?: number) => {
  const navigate = useNavigate();
  const { data: rawMaterial = null, isFetching: getLoading } = useQuery(
    ['material/rawMaterial/getRawMaterial', materialId],
    () => client.get<APIResponse<RawMaterialGet>>(`/materials/${materialId}`),
    {
      enabled: typeof materialId !== 'undefined',
      select: (res) => res.data.result,
    },
  );

  const { mutate: addRawMaterial, isLoading: addLoading } = useMutation(
    (params: RawMaterialBasicCreateParams) =>
      client.post<APIResponse<RawMaterialGet>>('/materials', params),
    {
      onSuccess: () => {
        message.success('저장되었습니다.');
        navigate(`${path.material.manage.ready}`);
      },
    },
  );

  const { mutate: updateRawMaterial, isLoading: updateLoading } = useMutation(
    (params: RawMaterialBasicUpdateParams) => client.patch(`/materials/${materialId}`, params),
    {
      onSuccess: () => {
        message.success('수정되었습니다.');
        navigate(-1);
      },
    },
  );

  const { mutate: registerRawMaterial, isLoading: registerLoading } = useMutation(
    (materialId: number) => client.post(`/material/register/${materialId}`),
  );

  return useMemo(
    () => ({
      rawMaterial,
      getLoading,
      addRawMaterial,
      addLoading,
      updateRawMaterial,
      updateLoading,
      registerRawMaterial,
      registerLoading,
      readOnly: rawMaterial?.status === 'RM_REG',
    }),
    [
      rawMaterial,
      getLoading,
      addRawMaterial,
      addLoading,
      updateRawMaterial,
      updateLoading,
      registerRawMaterial,
      registerLoading,
    ],
  );
};

export const useRegisteredRawMaterials = ({
  enabled,
  documentCode,
}: {
  enabled?: boolean;
  documentCode: DocumentCode;
}) => {
  const { data: registeredRawMaterials = [], isFetching: getLoading } = useQuery(
    'material/rawMaterial/getRegisteredRawMaterials',
    () =>
      client.get<APIResponse<RegisteredRawMaterial[]>>('/materials/registered', {
        params: {
          documentCode,
        },
      }),
    {
      select: (res) => res.data.result,
      enabled,
    },
  );

  return useMemo(
    () => ({
      registeredRawMaterials,
      getLoading,
    }),
    [registeredRawMaterials, getLoading],
  );
};

export const useDetailCategoryInfo = () => {
  const {
    data: detailCategoryInfo = {
      remarkCategories: [],
      specialBrandTypes: [],
    },
    isLoading,
  } = useQuery(
    [queryKeys.detailCategoryInfo],
    () => client.get<APIResponse<DetailCategoryInfo>>('/v1/material/detail-category-info'),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  return {
    detailCategoryInfo,
    isLoading,
  };
};

export const useMaterialDetail = (materialId?: number) => {
  const { mutate: createMaterialDetail, isLoading: isCreateMaterialDetailLoading } = useMutation(
    ({ materialId, ...params }: RawMaterialDetailCreateParams) =>
      client.post(`/v1/materials/${materialId}/details`, createFormData(params)),
  );

  const { mutate: updateMaterialDetail, isLoading: isUpdateMaterialDetailLoading } = useMutation(
    ({ materialId, ...params }: RawMaterialDetailUpdateParams) =>
      client.post(`/v1/materials/${materialId}/details/update`, createFormData(params)),
  );

  const { data: materialDetail = undefined, isLoading: isMaterialDetailLoading } = useQuery(
    [queryKeys.getMaterialDetail, materialId],
    () => client.get<APIResponse<RawMaterialDetail>>(`/v1/materials/${materialId}/details`),
    {
      enabled: materialId !== undefined,
      select: (res) => res.data.result,
    },
  );

  return {
    createMaterialDetail,
    isCreateMaterialDetailLoading,
    updateMaterialDetail,
    isUpdateMaterialDetailLoading,
    materialDetail,
    isMaterialDetailLoading,
  };
};

// Composition information
export const useCIDocumentForm = () => {
  const { mutate: downloadCIDocumentForm, isLoading } = useMutation(
    ({ materialId }: { materialId: number }) =>
      client.get<APIResponse<ArrayBuffer>>(
        `/v1/materials/${materialId}/composition-infos/document-form`,
      ),
    {
      onSuccess: (res) => {
        const file = base64ToFile(
          `data:application/pdf;base64,${res.data.result}`,
          'Composition Information.xlsx',
        );
        downloadFile(file);
      },
    },
  );

  return {
    downloadCIDocumentForm,
    isLoading,
  };
};

import { InputRef, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SearchInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import { useICIDRegistered } from 'service/material/icid/icid';
import {
  ICIDRegisteredSearchType,
  ICIDRegisteredParam,
  ICIDRegisteredPageState,
} from 'types/material/icid/icid';
import path from 'lib/path';
import Table from 'components/ui/Table/Table';

const Container = styled.div`
  .ant-table-thead > tr > th {
    padding: 10px 16px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 18px 16px;
  }
`;

const searchTypeOptions: {
  label: string;
  value: ICIDRegisteredSearchType;
}[] = [
  { label: '전체', value: ICIDRegisteredSearchType.ALL },
  { label: '원료명', value: ICIDRegisteredSearchType.TRADE_NAME },
  { label: 'INCI명', value: ICIDRegisteredSearchType.INCI },
];

const ICIDRegistered = ({
  initialPageState,
  onChangePageState,
}: {
  initialPageState: ICIDRegisteredPageState;
  onChangePageState: (state: ICIDRegisteredPageState) => void;
}) => {
  const navigate = useNavigate();
  const inputRef = useRef<InputRef | null>(null);
  const { page, searchType, searchValue } = initialPageState;
  const [searchTypeBuffer, setSearchTypeBuffer] = useState<ICIDRegisteredSearchType>(searchType);
  const [searchValueBuffer, setSearchValueBuffer] = useState(searchValue);
  const { icidRegistered, total, isLoading } = useICIDRegistered({
    page,
    searchType,
    searchValue,
  });

  const handleChangeSearchType = useCallback((searchType: ICIDRegisteredSearchType) => {
    setSearchValueBuffer('');
    setSearchTypeBuffer(searchType);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSearch = useCallback(
    (searchValue: string) => {
      onChangePageState({
        page: 1,
        searchValue,
        searchType: searchTypeBuffer,
      });
    },
    [searchTypeBuffer],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      onChangePageState({
        page,
        searchValue,
        searchType,
      });
    },
    [searchValue, searchType],
  );

  const columns: ColumnsType<ICIDRegisteredParam> = [
    {
      title: '등재 신청 번호',
      align: 'center',
      width: '19%',
      render: (_, { applicationCode }) => (
        <Typography.Text medium type="BODY_2" color="GRAY90">
          {applicationCode}
        </Typography.Text>
      ),
    },
    {
      title: '원료명(영문)',
      width: '28%',
      render: (_, { materialIcidApplicationId, tradeName }) => (
        <Typography.Text
          medium
          type="BODY_2"
          color="GRAY90"
          onClick={() => {
            navigate(`${path.material.icid.root}/${materialIcidApplicationId}/registered`);
          }}
        >
          {tradeName}
        </Typography.Text>
      ),
    },
    {
      title: 'INCI명',
      align: 'center',
      width: '33%',
      render: (_, { inciName }) => (
        <Typography.Text medium type="BODY_2" color="GRAY90">
          {inciName}
        </Typography.Text>
      ),
    },
    {
      title: '등재 완료일',
      align: 'center',
      width: '19%',
      render: (_, { finishDt }) => finishDt,
    },
  ];

  return (
    <Container>
      <Flex justify="right">
        <Select
          value={searchTypeBuffer}
          options={searchTypeOptions}
          style={{ width: 140, marginRight: '8px' }}
          onChange={handleChangeSearchType}
        />
        <SearchInput
          inputRef={inputRef}
          value={searchValueBuffer}
          defaultValue={searchValue}
          onChange={(e) => setSearchValueBuffer(e.target.value)}
          onSearch={handleSearch}
          placeholder="검색"
          style={{ width: 320 }}
        />
      </Flex>
      <Table
        style={{ marginTop: 16 }}
        loading={isLoading}
        columns={columns}
        dataSource={icidRegistered}
        pagination={{ current: page, onChange: handleChangePage, total: total }}
        rowKey="materialIcidApplicationId"
      />
    </Container>
  );
};

export default ICIDRegistered;

import ProductCountryContainer from 'containers/product/ProductCountryContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '제품 등록 중, 또는 제품 등록완료 이후에도 국가는 계속 추가하실 수 있습니다.',
  'certicos는 하단의 선택하신 국가의 인증에 필요한 정보만을 요구합니다.',
];

const ProductCountryPage = () => {
  return (
    <ProductPageTemplate subtitle="국가 선택" hasSkip={false} notices={notices}>
      <ProductCountryContainer />
    </ProductPageTemplate>
  );
};

export default ProductCountryPage;

import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import { ICIDContractStatus, ICIDRequestListPageState } from 'types/material/icid/icid';
import ICIDRequestList from 'components/material/icid/ICIDRequestList';

const ICIDRequestListPage = () => {
  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateBlock"]');
    if (!rootPageTemplete) return;
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  // 상태 추출 및 기본값 설정
  const ICIDRequestListPageState: ICIDRequestListPageState = location.state || {
    page: 1,
    searchValue: '',
    isFinishedContract: ICIDContractStatus.ALL,
  };

  const handleChangePageState = (state: ICIDRequestListPageState) => {
    navigate(path.material.icid.requestList, { state, replace: true });
  };

  return (
    <PageTemplate
      title="등재 의뢰 리스트"
      description="기본정보를 입력해주신 원료입니다. 진행 확정 버튼 클릭으로 견적서를 확인하고 한 번에 등재 계약 체결까지 진행하실 수 있습니다."
      back={false}
    >
      <ICIDRequestList
        initialPageState={ICIDRequestListPageState}
        onChangePageState={handleChangePageState}
      />
    </PageTemplate>
  );
};

export default ICIDRequestListPage;

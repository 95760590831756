import { useLayoutEffect } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ManuProductCGMPContainer from 'containers/product/ManuProductCGMPContainer';
import ProductCGMPContainer from 'containers/product/ProductCGMPContainer';
import { useManuMode } from 'hook/company';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const brandNotices = [
  '실 제품을 제조하는 제조 공장의 cGMP 또는 ISO22716 서류의 PDF 파일을 업로드해 주세요.',
  'ISO22716 서류의 경우, 추후 인증 진행시 Expiry Date에 따른 제약이 있을 수 있으므로, 유효기간을 입력해 주세요.',
  <MistakeNoticeItem />,
];
const manuNotices = [
  '회원가입 시 등록하신 cGMP (ISO22716) 서류와 입력하신 주소가 정확히 일치하는지 확인하여주세요.',
  '추후 브랜드사에게 전달하실 cGMP (ISO22716) 원본 서류도 해당 주소로 동일하게 발급해 주세요.',
  <MistakeNoticeItem />,
];

const ProductCGMPPage = () => {
  const manuMode = useManuMode();

  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateStyle"]');
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <ProductPageTemplate
      subtitle="cGMP (ISO22716)"
      notices={!manuMode ? brandNotices : manuNotices}
      exampleImg="cGMP_ISO22716.jpg"
      exampleImgWidth={920}
      magnify={false}
      maxWidth={1040}
    >
      {!manuMode ? <ProductCGMPContainer /> : <ManuProductCGMPContainer />}
    </ProductPageTemplate>
  );
};

export default ProductCGMPPage;

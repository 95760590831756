import styled from 'styled-components';
import { useCallback } from 'react';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { downloadFile } from 'lib/file';
import { MaterialIcidApplicationPcpcQuestionAttach } from 'types/material/icid/pcpc';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledChat = styled.div`
  width: 100%;
  background: ${palette.SLATE_GRAY10};
  border-radius: 0px 16px 16px 16px;
  padding: 16px;
`;

const Chat = ({
  className,
  content,
  registerDt,
  attaches,
}: {
  className?: string;
  content: React.ReactNode;
  registerDt: string;
  attaches: MaterialIcidApplicationPcpcQuestionAttach[] | null;
}) => {
  const handleClickFile = useCallback((url: string, name: string) => {
    downloadFile(url, name);
  }, []);

  return (
    <Container className={className}>
      <StyledChat>
        {content}
        {attaches?.map(({ materialIcidApplicationPcpcQuestionAttachId, filename, fileUrl }) => (
          <Flex
            gap={8}
            style={{ marginTop: 18, cursor: 'pointer', width: 'fit-content' }}
            align="center"
            key={materialIcidApplicationPcpcQuestionAttachId}
            onClick={() => handleClickFile(fileUrl, filename)}
          >
            <Icon name="page" color="GRAY70" />
            <Typography.Text type="BODY_2" color="GRAY70">
              {filename}
            </Typography.Text>
          </Flex>
        ))}
        <Typography.Text
          type="SMALL"
          color="GRAY60"
          style={{
            marginTop: 8,
            alignSelf: 'flex-end',
          }}
        >
          {registerDt}
        </Typography.Text>
      </StyledChat>
    </Container>
  );
};

export default Chat;

import { useRef } from 'react';
import { Checkbox, Form } from 'antd';

import * as S from './Styled';
import { Typography } from 'components/system';
import { generateFormRules } from 'lib/validate';
import InputNumber from 'components/ui/InputNumber';

const SpecificGravityForm = ({
  isOnlyCN,
  readOnlyMode,
  onChangeSpecificGravityRadioGroup,
}: {
  isOnlyCN: boolean;
  readOnlyMode: boolean;
  onChangeSpecificGravityRadioGroup: (notApplicableSpecificGravity: boolean) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <S.StyledLabelSpaceBetween>
        <S.StyledLabel isOnlyCN={isOnlyCN}>Specific Gravity</S.StyledLabel>
        <Form.Item
          name="notApplicableSpecificGravity"
          initialValue={false}
          valuePropName="checked"
          noStyle
        >
          <Checkbox
            disabled={readOnlyMode}
            onChange={(e) => onChangeSpecificGravityRadioGroup(e.target.checked)}
          >
            <S.CheckBoxLabel>해당 없음</S.CheckBoxLabel>
          </Checkbox>
        </Form.Item>
      </S.StyledLabelSpaceBetween>
      <S.StyledRow style={{ gap: 16 }}>
        <S.StyledColumn>
          <Typography.Text gutter={{ right: 4, bottom: 8 }}>정상 범위</Typography.Text>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.notApplicableSpecificGravity !== next.notApplicableSpecificGravity ||
              prev.specificGravityLimitMax !== next.specificGravityLimitMax ||
              prev.specificGravityLimitMin !== next.specificGravityLimitMin
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const notApplicableSpecificGravity = getFieldValue('notApplicableSpecificGravity');
              const specificGravityLimitMax = getFieldValue('specificGravityLimitMax');

              return (
                <S.StyledRow>
                  {!notApplicableSpecificGravity ? (
                    <>
                      <S.AntdExplainWrapper>
                        <Form.Item
                          initialValue={0.9}
                          name="specificGravityLimitMin"
                          dependencies={['specificGravityLimitMax']}
                          rules={[
                            ...generateFormRules({
                              required: !(isOnlyCN || notApplicableSpecificGravity),
                            }),
                            {
                              validator: (_, specificGravityLimitMinValue) => {
                                if (specificGravityLimitMinValue > specificGravityLimitMax) {
                                  return Promise.reject('좌측 값은 우측 값보다 클 수 없음');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            precision={5}
                            step={0.1}
                            min={0.5}
                            max={1.9}
                            disabled={readOnlyMode}
                            style={{ width: 160 }}
                            inputwidth={82}
                          />
                        </Form.Item>
                      </S.AntdExplainWrapper>
                      <Typography.Text
                        style={{
                          display: 'inline-block',
                          width: '16px',
                          textAlign: 'center',
                        }}
                      >
                        ~
                      </Typography.Text>
                      <Form.Item
                        name="specificGravityLimitMax"
                        initialValue={1.1}
                        rules={generateFormRules({
                          required: !(isOnlyCN || notApplicableSpecificGravity),
                        })}
                      >
                        <InputNumber
                          precision={5}
                          step={0.1}
                          min={0.5}
                          max={1.9}
                          disabled={readOnlyMode}
                          style={{ width: 160 }}
                          inputwidth={82}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item>
                        <InputNumber disabled style={{ width: 160 }} />
                      </Form.Item>
                      <Typography.Text
                        style={{
                          display: 'inline-block',
                          width: '16px',
                          textAlign: 'center',
                        }}
                      >
                        ~
                      </Typography.Text>
                      <Form.Item>
                        <InputNumber disabled style={{ width: 160 }} />
                      </Form.Item>
                    </>
                  )}
                </S.StyledRow>
              );
            }}
          </Form.Item>
        </S.StyledColumn>

        <S.StyledColumn>
          <Typography.Text gutter={{ bottom: 8 }}>수치</Typography.Text>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.notApplicableSpecificGravity !== next.notApplicableSpecificGravity ||
              prev.specificGravityLimitMax !== next.specificGravityLimitMax ||
              prev.specificGravityLimitMin !== next.specificGravityLimitMin
            }
            noStyle
          >
            {({ getFieldValue, setFieldsValue }) => {
              const notApplicableSpecificGravity = getFieldValue('notApplicableSpecificGravity');
              const specificGravityLimitMax = getFieldValue('specificGravityLimitMax');
              const specificGravityLimitMin = getFieldValue('specificGravityLimitMin');
              return (
                <>
                  {!notApplicableSpecificGravity ? (
                    <Form.Item
                      name="specificGravity"
                      required={!(isOnlyCN || notApplicableSpecificGravity)}
                      dependencies={['specificGravityLimitMin', 'specificGravityLimitMax']}
                      rules={[
                        ...generateFormRules({
                          required: !(isOnlyCN || notApplicableSpecificGravity),
                        }),

                        !(isOnlyCN || notApplicableSpecificGravity)
                          ? {
                              validator: (_, specificGravity) => {
                                if (
                                  specificGravity < specificGravityLimitMin ||
                                  specificGravity > specificGravityLimitMax
                                ) {
                                  return Promise.reject('정상 범위를 벗어난 수치');
                                }
                                return Promise.resolve();
                              },
                            }
                          : {
                              validator(_, specificGravity) {
                                if (
                                  specificGravity !== '' &&
                                  specificGravity !== null &&
                                  (specificGravity < specificGravityLimitMin ||
                                    specificGravity > specificGravityLimitMax) &&
                                  specificGravityLimitMin !== null &&
                                  specificGravityLimitMax !== null
                                ) {
                                  return Promise.reject('정상 범위를 벗어난 수치');
                                }
                                return Promise.resolve();
                              },
                            },
                      ]}
                      // 빈 값을 입력하면 value가 0으로 떨어져 ref를 통해 빈 값을 구분함
                      normalize={(value) =>
                        ref.current?.value === '' && value === 0 ? null : value
                      }
                    >
                      <InputNumber
                        ref={ref}
                        precision={5}
                        step={0.1}
                        disabled={readOnlyMode}
                        style={{ width: 160 }}
                        // 빈 값은 onChange로 전달 받지 않아 onInput 이용
                        onInput={(value) => {
                          if (value === '') {
                            setFieldsValue({
                              specificGravity: '',
                            });
                          }
                        }}
                        inputwidth={82}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <InputNumber disabled style={{ width: 160 }} />
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Item>
        </S.StyledColumn>
      </S.StyledRow>
    </>
  );
};

export default SpecificGravityForm;

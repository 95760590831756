import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';

import { createRequestSaga, createRequestActionTypes } from 'lib/saga';
import * as companyApi from 'lib/api/company';

const [GET_COMPANY, GET_COMPANY_SUCCESS] = createRequestActionTypes('company/GET_COMPANY');
const [GET_COMPANY_STATS, GET_COMPANY_STATS_SUCCESS] = createRequestActionTypes(
  'company/GET_COMPANY_STATS',
);
const INITIALIZE_COMPANY = 'company/INITIALIZE_COMPANY';
const INITIALIZE_FACTORY = 'company/INITIALIZE_FACTORY';
const GET_FACTORIES_SUCCESS = 'company/GET_FACTORIES_SUCCESS';

export const getCompany = createAction(GET_COMPANY);
const getCompanySaga = createRequestSaga(GET_COMPANY, companyApi.getCompany);
export const getCompanyStats = createAction(GET_COMPANY_STATS);
const getCompanyStatsSaga = createRequestSaga(GET_COMPANY_STATS, companyApi.getCompanyStats);

export const companySaga = function* () {
  yield takeLatest(GET_COMPANY, getCompanySaga);
  yield takeLatest(GET_COMPANY_STATS, getCompanyStatsSaga);
};

const initialState = {
  company: null,
  factories: [],
  stats: {
    productOnCount: 0,
    productFinCount: 0,
    certFinCount: 0,
    certOnCount: 0,
    certFinCountryCount: 0,
  },
};

export default handleActions(
  {
    [GET_COMPANY_SUCCESS]: (state, { payload: company }) =>
      produce(state, (draft) => {
        draft.company = company;
      }),
    [INITIALIZE_COMPANY]: (state) =>
      produce(state, (draft) => {
        draft.company = null;
      }),
    [INITIALIZE_FACTORY]: (state) =>
      produce(state, (draft) => {
        draft.factories = [];
      }),
    [GET_COMPANY_STATS_SUCCESS]: (state, { payload: stats }) =>
      produce(state, (draft) => {
        draft.stats = stats;
      }),
    [GET_FACTORIES_SUCCESS]: (state, { payload: factory }) =>
      produce(state, (draft) => {
        draft.factories = factory;
      }),
  },
  initialState,
);

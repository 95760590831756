import PageTemplate from 'templates/PageTemplate';
import TermsRouter from 'routers/TermsRouter';

const TermsPage = () => {
  return (
    <PageTemplate back={false} title={'서비스 정책'}>
      <TermsRouter />
    </PageTemplate>
  );
};

export default TermsPage;

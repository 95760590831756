import ProductSamplePageTemplate from 'templates/manufacturer/ProductSamplePageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSampleBasic from 'components/manufacturer/productSample/ProductSampleBasic';
import { useProductSampleId } from 'service/manufacturer/productSample';

const notices = [
  '입력하고자 하는 책임 판매사가 없는 경우 하단의 전화나 하단의 ‘이용 문의’로 문의해 주세요.',
  <MistakeNoticeItem />,
];

const ProductSamplePageTemplateBasicPage = () => {
  const productSampleId = useProductSampleId();
  return (
    <ProductSamplePageTemplate
      back={typeof productSampleId !== 'undefined'}
      notices={notices}
      subtitle="기본 정보"
    >
      <ProductSampleBasic />
    </ProductSamplePageTemplate>
  );
};

export default ProductSamplePageTemplateBasicPage;

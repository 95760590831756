import parser from 'react-html-parser';
import styled, { css } from 'styled-components';
import 'suneditor/dist/css/suneditor.min.css';

import { TermsType } from 'types/terms';

const checkIsServiceTerms = (termsType: TermsType | undefined) =>
  termsType === TermsType.SERVICE_BRAND ||
  termsType === TermsType.SERVICE_MANUFACTURER ||
  termsType === TermsType.SERVICE_MATERIAL;

const checkIsCIBIServiceTerms = (termsType: TermsType | undefined) =>
  termsType === TermsType.CI_BI_SERVICE_BRAND ||
  termsType === TermsType.CI_BI_SERVICE_MANUFACTURER ||
  termsType === TermsType.CI_BI_SERVICE_MATERIAL;

const Container = styled.div<{ termsType: TermsType | undefined }>`
  margin-top: 16px;
  min-height: 200px;
  padding: 0px;
  font-size: 14px;

  h2 {
    margin: 0 0 12px 0;
    font-weight: 600 !important;
    font-size: 20px;
    line-height: 1.5;
    color: #000000d9;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
  }

  p {
    margin-bottom: 0 !important;
  }

  table {
    margin: 0 auto;
    width: 100%;
  }

  ul {
    margin-bottom: 0;
    counter-reset: key;
  }

  & > ul > li {
    ${({ termsType }) =>
      (checkIsServiceTerms(termsType) || checkIsCIBIServiceTerms(termsType)) &&
      css`
        &::before {
          display: flex;
          counter-increment: key;
          position: absolute;
          top: 3px;
          left: -20px;
          width: 14px;
          height: 14px;
          content: counter(key);
          font-size: 10px;
          border: 1px solid #333;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
        }
      `}
  }

  li {
    position: relative;
    margin: 16px 0 0;
    font-size: 14px;

    li {
      margin-top: 8px;
    }
  }

  .sun-editor-editable * {
    font-size: 14px;
  }

  table thead {
    border-bottom: none !important;
    font-weight: 600;
    text-align: start;
  }
  table th {
    background-color: #f9f9f9 !important;
    text-align: start;
    color: #222222;
    border: 1px solid #e7e7e7;
  }
  table th.se-table-selected-cell {
    outline: none !important;
  }
`;

const TermsEditorViewer = ({ content, termsType }: { content: string; termsType?: TermsType }) => {
  return (
    <Container className="sun-editor-editable" termsType={termsType}>
      {parser(content)}
    </Container>
  );
};

export default TermsEditorViewer;

import { Button, Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import { Flex } from 'components/ui';
import Tags from 'components/ui/Tags';
import { CompanySize, DocumentCode, ManufacturerInfo } from 'types/product';
import UpdateLog from './UpdateLog';
import { useModal } from 'hook/useModal';
import { useManufacturerInfo } from 'service/brand/product/manufacturerInfo';
import { useProductDocStatus } from 'service/product';
import { messages } from 'lib/consts';
import { useNavigate } from 'react-router-dom';

const ProductManufacturerInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const navigate = useNavigate();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [manufacturerIsSmallCompany, setManufacturerIsSmallCompany] = useState<CompanySize>();
  const [manufacturerFeiNumber, setManufacturerFeiNumber] = useState<string>();
  const { manufacturerInfo, updateManufacturerInfo, updateManufacturerInfoLoading, isLoading } =
    useManufacturerInfo({ productId, countryId });
  const { openManufacturerInfoModal } = useModal();
  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.MF,
  });
  const updateMode = manufacturerInfo !== undefined && docStatus !== null;

  const handleSubmit = () => {
    if (!manufacturerIsSmallCompany) {
      message.warning('중소기업 여부를 등록해 주세요.');
      return;
    }

    if (
      updateMode &&
      manufacturerIsSmallCompany === manufacturerInfo.manufacturerIsSmallCompany &&
      manufacturerFeiNumber === (manufacturerInfo.manufacturerFeiNumber || undefined)
    ) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    updateManufacturerInfo(
      {
        countryId,
        manufacturerIsSmallCompany,
        manufacturerFeiNumber: manufacturerFeiNumber || '',
      },
      {
        onSuccess: () => {
          message.success(`${updateMode ? '보완 완료' : '등록'}되었습니다.`);
          navigate(-1);
        },
      },
    );
  };

  const openModal = () => {
    openManufacturerInfoModal({
      updateMode,
      readOnlyMode,
      manufacturerInfo,
      manufacturerIsSmallCompany,
      manufacturerFeiNumber,
      setManufacturerIsSmallCompany,
      setManufacturerFeiNumber,
    });
  };

  const columns: ColumnsType<ManufacturerInfo> = [
    {
      title: '화장품제조업자명 (국문)',
      align: 'center',
      width: 160,
      render: (_, { manufacturerNameKo }) => manufacturerNameKo,
    },
    {
      title: '화장품제조업자명 (영문)',
      align: 'center',
      width: 160,
      render: (_, { manufacturerNameEn }) => manufacturerNameEn,
    },
    {
      title: '화장품제조업자 주소 (영문)',
      align: 'center',
      width: 400,
      render: (_, { manufacturerAddressEn }) => manufacturerAddressEn,
    },
    {
      title: '연락처',
      align: 'center',
      width: 160,
      render: (_, { manufacturerTel }) => manufacturerTel,
    },
    {
      title: '중소기업 여부 (FEI No.)',
      align: 'center',
      width: 160,
      render: () =>
        !manufacturerIsSmallCompany ? (
          <Tags.Mark onClick={openModal} type="arrow" color="blue" style={{ width: 71 }}>
            등록하기
          </Tags.Mark>
        ) : manufacturerIsSmallCompany === CompanySize.CORPORATE && manufacturerFeiNumber ? (
          manufacturerFeiNumber
        ) : (
          '중소기업'
        ),
    },
  ];

  useEffect(() => {
    if (manufacturerInfo) {
      const manufacturerIsSmallCompany = !manufacturerInfo.manufacturerIsSmallCompany
        ? undefined
        : manufacturerInfo.manufacturerIsSmallCompany;
      const manufacturerFeiNumber = !manufacturerInfo.manufacturerFeiNumber
        ? undefined
        : manufacturerInfo.manufacturerFeiNumber;

      if (manufacturerIsSmallCompany) {
        setManufacturerIsSmallCompany(manufacturerIsSmallCompany);
      }
      if (manufacturerFeiNumber) {
        setManufacturerFeiNumber(manufacturerFeiNumber);
      }
    }
  }, [manufacturerInfo]);

  return (
    <Container>
      <Flex justify="end" gap={8}>
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.MF}
        />
        {updateMode && (
          <UpdateLog productId={productId} countryId={countryId} documentCode={DocumentCode.MF} />
        )}
      </Flex>
      <Table
        columns={columns}
        dataSource={manufacturerInfo ? [manufacturerInfo] : []}
        pagination={false}
        loading={isLoading}
        rowKey={({ manufacturerId }) => manufacturerId}
        onRow={() =>
          !!manufacturerIsSmallCompany
            ? {
                onClick: openModal,
                style: {
                  cursor: 'pointer',
                },
              }
            : {}
        }
      />
      <FooterBox>
        <ReadOnlyBackButton readOnly={readOnlyMode}>
          {updateMode && <CorrectRequestWithoutChangeButton documentCode={DocumentCode.MF} />}
          <Button type="primary" loading={updateManufacturerInfoLoading} onClick={handleSubmit}>
            {!updateMode ? '등록' : '보완 완료'}
          </Button>
        </ReadOnlyBackButton>
      </FooterBox>
    </Container>
  );
};

const Container = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      padding: 8px 12px;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      padding: 17px 12px;
    }
  }

  .ant-table-cell {
    word-break: break-all;
  }
`;

export default ProductManufacturerInfo;

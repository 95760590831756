import { Button, Drawer, List, Tag } from 'antd';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import moment from 'moment';

import palette from 'lib/styles/palette';
import Typography from 'components/system/general/Typography';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

const StyledList = styled(List)`
  height: calc(100vh - 58px);
  overflow: scroll;

  .ant-list-item {
    padding: 16px 10px;
    display: block;
  }
`;

const UserNoticeItem = styled(List.Item)<{ $isConfirm: boolean; $hasLink: boolean }>`
  cursor: ${(props) => (!props.$isConfirm || props.$hasLink ? 'pointer' : 'default')};
  padding: 16px;
  border: 1px solid #f3f3f3;
  background-color: #f6f6f6;
  color: #4e5968;

  ${(props) =>
    props.$isConfirm &&
    css`
      .ant-tag {
        color: ${palette.GRAY50};
      }
    `}

  p {
    margin-bottom: 0;
    max-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 16px;
  }
`;

const DrawerHeader = styled(Flex)`
  padding: 16px;
`;

const ReadAllButton = styled(Button)`
  position: relative;
  height: 24px;
  width: 63px;
  font-size: 12px;
  padding: 5px 7px;
  border-color: ${palette.PRIMARY50};
`;

const UserNoticeDrawer = ({
  visible,
  userNotices,
  onClickUserNoticeItem,
  onClickConfirmAll,
  onCloseUserNoticeDrawer,
}: {
  visible: boolean;
  userNotices: any;
  onClickUserNoticeItem: (userNotice: any) => void;
  onClickConfirmAll: VoidFunction;
  onCloseUserNoticeDrawer: VoidFunction;
}) => {
  return (
    <Drawer
      width={320}
      open={visible}
      closable={false}
      maskClosable={true}
      styles={{
        body: {
          padding: 0,
        },
      }}
      onClose={onCloseUserNoticeDrawer}
    >
      <DrawerHeader align="center" justify="space-between">
        <Flex gap={8} align="center">
          <Typography.Text type="TITLE_2">알림 목록</Typography.Text>
          <ReadAllButton onClick={onClickConfirmAll}>모두 읽음</ReadAllButton>
        </Flex>
        <Icon name="close" size={32} onClick={onCloseUserNoticeDrawer} />
      </DrawerHeader>
      <StyledList
        dataSource={userNotices}
        rowKey={(item: any) => item.userNoticeId}
        renderItem={(userNotice: any) => (
          <UserNoticeItem
            $isConfirm={userNotice.isConfirm}
            $hasLink={userNotice.noticePath !== null}
            onClick={() => onClickUserNoticeItem(userNotice)}
          >
            <Flex gap={8} style={{ marginBottom: 8 }} justify="space-between" align="center">
              <Tag
                color={userNotice.isConfirm ? palette.GRAY30 : '#4e5968'}
                style={{
                  margin: 0,
                  padding: '0 4px',
                  height: 24,
                  fontWeight: 500,
                  lineHeight: '24px',
                }}
              >
                {userNotice.title || '알림'}
              </Tag>
              <Typography.Text
                style={{
                  fontSize: 10,
                  color: palette.GRAY50,
                }}
              >
                {moment(userNotice.registerDt).fromNow()}
              </Typography.Text>
            </Flex>
            <Typography.Text color="SLATE_GRAY70" type="BODY_2" style={{ padding: '0 6px' }}>
              {parse(userNotice.content)}
            </Typography.Text>
          </UserNoticeItem>
        )}
      />
    </Drawer>
  );
};

export default UserNoticeDrawer;

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import path from 'lib/path';
import QCQALotList from 'components/brand/qcqa/QCQALotList';
import QCQAPageTemplate from 'templates/QCQAPageTemplate';
import { useSearchParams } from 'hook/useSearchParams';

export interface QCQALotListPageSearchParams {
  page: number;
  productYear: number;
}

export interface LocationState {
  rootPage: number;
  brandName: string;
  productName: string;
}

const QCQALotListPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const today = moment();
  const params = useParams<{
    qcqaProductId: string;
  }>();
  const qcqaProductId = Number(params.qcqaProductId);
  const { searchParams, replaceSearchParams } = useSearchParams({
    path: `${path.qcqa.management.root}/${qcqaProductId}/lot`,
  });

  const queryParams: QCQALotListPageSearchParams = {
    page: Number(searchParams.get('page')) || 1,
    productYear: Number(searchParams.get('productYear')) || today.year(),
  };

  return (
    <QCQAPageTemplate
      title="제조번호 관리"
      onBack={() => {
        navigate(
          `${path.qcqa.management.root}${
            location?.state
              ? `?page=${location.state?.rootPage}&productName=${location.state?.productName}&brandName=${location.state?.brandName}`
              : ''
          }`,
          { replace: true },
        );
      }}
    >
      <QCQALotList
        locationState={location?.state}
        queryParams={queryParams}
        replaceSearchParams={replaceSearchParams}
        qcqaProductId={qcqaProductId}
      />
    </QCQAPageTemplate>
  );
};

export default QCQALotListPage;

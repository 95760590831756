import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { nanoid } from 'nanoid';

import palette from 'lib/styles/palette';
import { useModal } from 'hook/useModal';
import { Flex } from 'components/ui';
import { Typography } from 'components/system';
import PaymentInfoModal from './PaymentInfoModal';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

interface EstimateAddtional {
  estimateAdditionalCode: string;
  registerDt: string;
  estimatePrice: number;
  fileUrl: string | null;
}

export interface EstimateAdditionalsModalProps {
  estimateAdditionals: EstimateAddtional[];
  onClose?: () => void;
}

const TotalPriceContainer = styled(Flex)`
  margin-top: 8px;
  background-color: ${palette.PRIMARY10};
  height: 56px;
  padding: 16px 40px;
  box-sizing: border-box;
`;

const EstimateAdditionalsModal = ({
  estimateAdditionals,
  onClose,
}: EstimateAdditionalsModalProps) => {
  const [previewedPaymentEstimateUrl, setPreviewedPaymentEstimateUrl] = useState<string | null>(
    null,
  );
  const { openDocumentPreviewModal } = useModal();
  const closePaymentInfoModal = () => {
    setPreviewedPaymentEstimateUrl(null);
  };

  const totalEstimatePrice = useMemo(
    () => estimateAdditionals.reduce((total, e) => total + Math.floor(e.estimatePrice * 1.1), 0),
    [],
  );

  const columns = useMemo<ColumnsType<EstimateAddtional>>(
    () => [
      {
        title: 'No.',
        align: 'center',
        render: (_, __, index) => index + 1,
      },
      {
        title: '견적서 번호',
        align: 'center',
        dataIndex: 'estimateAdditionalCode',
      },
      {
        title: '견적 발행일',
        align: 'center',
        render: (_, { registerDt }) => registerDt.slice(0, 10),
      },
      {
        title: '견적 금액',
        align: 'center',
        render: (_, { estimatePrice }) => `${Math.floor(estimatePrice * 1.1).toLocaleString()}원`,
      },
      {
        title: '견적서 미리보기',
        align: 'center',
        render: (_, { fileUrl }) => (
          <Icon
            name="page"
            style={{ margin: '0 auto' }}
            onClick={() =>
              openDocumentPreviewModal({
                documentUrls: fileUrl,
              })
            }
          />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <PaymentInfoModal estimateUrl={previewedPaymentEstimateUrl} onClose={closePaymentInfoModal} />
      <Modal visible={estimateAdditionals !== null} width={880} onCancel={onClose} footer={null}>
        <Typography.Text type="TITLE_1" gutter={{ bottom: 24 }}>
          추가 견적 정보
        </Typography.Text>
        <Table
          columns={columns}
          rowKey={nanoid()}
          dataSource={estimateAdditionals}
          pagination={false}
        />
        <TotalPriceContainer align="center" justify="flex-end" columnGap={52}>
          <Typography.Text medium>추가 견적 총 합계</Typography.Text>
          <Typography.Text medium color="PRIMARY50">
            {totalEstimatePrice.toLocaleString()}원
          </Typography.Text>
        </TotalPriceContainer>
      </Modal>
    </>
  );
};

export default EstimateAdditionalsModal;

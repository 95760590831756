import { useIsLoggedIn } from 'service/auth';
import IntroPage from 'pages/main/IntroPage';
import BrandMainPage from 'pages/brand/main/MainPage';
import ManuMainPage from 'pages/manufacturer/main/MainPage';
import MaterialMainPage from 'pages/material/main/MainPage';
import { useCompanyType } from 'service/company';
import { CompanyType } from 'types/company';

const MainRouter = () => {
  const isLoggedIn = useIsLoggedIn();
  const companyType = useCompanyType();

  if (!isLoggedIn) return <IntroPage />;
  if (companyType === CompanyType.BRAND) return <BrandMainPage />;
  if (companyType === CompanyType.MANUFACTURE) return <ManuMainPage />;
  if (companyType === CompanyType.MATERIAL) return <MaterialMainPage />;

  return null;
};

export default MainRouter;

import { useMutation, useQuery } from 'react-query';
import { useMemo } from 'react';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import {
  ICIDApplicationDetail,
  ICIDApplicationTmpDetail,
  ICIDDetailAddParams,
  ICIDCategory,
  ICIDDocumentCode,
  UploadDocument,
} from 'types/material/icid/icid';
import { createFormData } from 'lib/file';

export const useDetail = (icidApplicationId: number) => {
  const { data: detail, isLoading: isDetailLoading } = useQuery(
    ['material/icid-applications/details', icidApplicationId],
    () =>
      client.get<APIResponse<ICIDApplicationDetail>>(
        `/v1/material/icid-applications/${icidApplicationId}/details`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return { detail, isDetailLoading };
};

export const useCategories = () => {
  const { data = [], isLoading } = useQuery(
    ['material/icid-application/functions'],
    () => client.get<APIResponse<ICIDCategory[]>>(`/v1/material/icid-application/functions`),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  const categories = useMemo(
    () =>
      data.map(({ materialCategoryId, categoryName }) => ({
        value: materialCategoryId,
        label: categoryName,
      })),
    [data],
  );

  return { categories, isLoadingCategories: isLoading };
};

export const useAddDetail = () => {
  const { mutate: addDetail, isLoading } = useMutation((params: ICIDDetailAddParams) => {
    const { icidApplicationId, ...rest } = params;
    return client.post(`/v1/material/icid-applications/${icidApplicationId}/details`, rest);
  });

  return {
    addDetail,
    isLoading,
  };
};

export const useTmpDetail = (icidApplicationId: number) => {
  const { data: tmpDetail, isLoading } = useQuery(
    ['material/icid-applications/tmp-save-detail', icidApplicationId],
    () =>
      client.get<APIResponse<ICIDApplicationTmpDetail>>(
        `/v1/material/icid-applications/${icidApplicationId}/tmp-save-detail`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return { tmpDetail, isLoading };
};

export const useSaveDetail = () => {
  const { mutate: saveDetail, isLoading } = useMutation((params: ICIDDetailAddParams) => {
    const { icidApplicationId, ...rest } = params;
    return client.post(
      `/v1/material/icid-applications/${icidApplicationId}/tmp-save-detail `,
      rest,
    );
  });

  return {
    saveDetail,
    isSaveLoading: isLoading,
  };
};

export const useDocument = ({
  icidApplicationId,
  documentCode,
}: {
  icidApplicationId: number;
  documentCode: ICIDDocumentCode;
}) => {
  const { data: document, isLoading } = useQuery(
    ['material/icid-applications', icidApplicationId, documentCode],
    () =>
      client.get<APIResponse<UploadDocument>>(
        `/v1/material/icid-applications/${icidApplicationId}/${documentCode}`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return { document, isLoading };
};

export const useUploadDocument = () => {
  const { mutate: uploadDocument, isLoading } = useMutation(
    ({
      icidApplicationId,
      documentCode,
      file,
    }: {
      icidApplicationId: number;
      documentCode: ICIDDocumentCode;
      file: File;
    }) =>
      client.post(
        `/v1/material/icid-applications/${icidApplicationId}/${documentCode}`,
        createFormData({
          documentFile: file,
        }),
      ),
  );

  return { uploadDocument, isLoading };
};

import { useParams } from 'react-router-dom';

import PageTemplate from 'templates/PageTemplate';
import ICIDRegisteredDetail from './ICIDRegisteredDetail';

const notices = [
  '실제 ICID에 등재시 사용된 서류 파일입니다.',
  '최종 등재 완료된 INCI명을 포함한 부가적인 등재 완료 정보는 PCPC Letter 에서 확인하실 수 있습니다.',
];

const ICIDRegisteredDetailPage = () => {
  const params = useParams<{ icidApplicationId: string }>();
  const icidApplicationId = Number(params.icidApplicationId);

  return (
    <PageTemplate title="등재 완료 정보" notices={notices}>
      <ICIDRegisteredDetail icidApplicationId={icidApplicationId} />
    </PageTemplate>
  );
};

export default ICIDRegisteredDetailPage;

import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { message, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

import * as materialApi from 'lib/api/material/allergen';
import {
  AllergenIngredientBase,
  AllergenIngredientFormType,
  AllergensListAdd,
  AllergensListUpdate,
} from 'types/material/allergen';
import { messages } from 'lib/consts';

export const useAllergen = (materialId?: number, materialStatus?: 'RM_RDY' | 'RM_REG') => {
  const navigate = useNavigate();

  const { mutate: addAllergensList, isLoading: addLoading } = useMutation(
    (payload: AllergensListAdd) => materialApi.addAllergensList(payload),
  );

  const { mutate: updateAllergensList, isLoading: updateLoading } = useMutation(
    (payload: AllergensListUpdate) => materialApi.updateAllergensList(payload),
  );

  const { data: allergensList = null, isFetching: getListLoading } = useQuery(
    [`/material/allergen`, materialId, updateLoading],
    () => materialApi.getAllergensList(materialId!),
    {
      enabled: typeof materialId !== 'undefined',
      select: (res) => res.result,
    },
  );

  const { data: allergensDefaultList = null, isFetching: getDefaultListLoading } = useQuery(
    [`/material/allergen/`],
    () => materialApi.getAllergensDefaultList(),
    {
      enabled: _.isEmpty(allergensList?.materialAllergenIngredients),
      select: (res) => res.result,
    },
  );

  const getIngredients = (formValue: AllergenIngredientFormType) => {
    let ingredients: AllergenIngredientBase[] = [];
    // HINT : 등록시에 향료 사용 불가 또는 알러지 프리 일 경우 ingredients의 percent가 empty string인 배열을 보내주어야 함.
    if (!formValue.canUseForFragrance || formValue.isAllergenFree) {
      allergensDefaultList?.forEach((allergenDefault) => {
        ingredients.push({
          materialCategoryId: allergenDefault.materialCategoryId,
          ingredientTotalPercent: '',
        });
      });
    } else {
      ingredients = Object.keys(formValue)
        .filter((k) => k !== 'isAllergenFree' && k !== 'canUseForFragrance')
        .map((k) => ({
          materialCategoryId: Number(k),
          ingredientTotalPercent: formValue[k],
        }));
    }
    return ingredients;
  };

  const getTobeUpdatedIngredients = (formValue: AllergenIngredientFormType) => {
    const tobeUpdatedIngredients: AllergenIngredientBase[] = [];

    const filteredKeyList = Object.keys(formValue).filter(
      (key) => key !== 'isAllergenFree' && key !== 'canUseForFragrance',
    );

    if (!formValue.canUseForFragrance || formValue.isAllergenFree) {
      allergensList?.materialAllergenIngredients?.forEach((ingredient) => {
        tobeUpdatedIngredients.push({
          materialCategoryId: ingredient.materialCategoryId,
          ingredientTotalPercent: '',
        });
      });
    } else {
      filteredKeyList.forEach((id) => {
        const allergen = allergensList?.materialAllergenIngredients?.find(
          (allergenIngredient) => allergenIngredient.materialAllergenIngredientId === Number(id),
        );
        if (allergen?.ingredientTotalPercent !== formValue[id]) {
          tobeUpdatedIngredients.push({
            materialAllergenIngredientId: Number(id),
            ingredientTotalPercent: formValue[id],
          });
        }
      });
    }
    return tobeUpdatedIngredients;
  };

  const checkUserInsertIngredient = (formValue: AllergenIngredientFormType) => {
    const userIngredient = Object.keys(formValue)
      .filter((k) => k !== 'isAllergenFree' && k !== 'canUseForFragrance')
      .find((val) => formValue[val]);

    return userIngredient;
  };
  const handleSubmit = (formValue: AllergenIngredientFormType) => {
    if (formValue.canUseForFragrance && !formValue.isAllergenFree) {
      if (!checkUserInsertIngredient(formValue))
        return message.warning('함유량은 1개 이상 입력해 주세요.');
    }

    if (!allergensList?.materialAllergenListId) {
      addAllergensList(
        {
          materialId: materialId!,
          canUseForFragrance: formValue.canUseForFragrance,
          ...(formValue.isAllergen !== null && {
            isAllergenFree: formValue.isAllergenFree,
          }),
          ingredients: getIngredients(formValue),
        },
        {
          onSuccess: () => {
            message.success('저장되었습니다.');
            navigate(-1);
          },
        },
      );
      return;
    }

    if (!formValue.canUseForFragrance) {
      if (formValue.canUseForFragrance === allergensList?.canUseForFragrance)
        return message.warning(messages.NO_NEED_TO_UPDATE);
    } else {
      if (formValue.isAllergenFree) {
        if (formValue.isAllergenFree === allergensList?.isAllergenFree)
          return message.warning(messages.NO_NEED_TO_UPDATE);
      }
    }

    const ingredients = getTobeUpdatedIngredients(formValue);
    if (materialStatus === 'RM_REG') {
      if (
        (!allergensList?.canUseForFragrance && !formValue.isAllergenFree) ||
        (allergensList?.isAllergenFree && !formValue.isAllergenFree)
      ) {
        Modal.confirm({
          width: 480,
          icon: null,
          title: '원료 정보를 수정하시겠습니까?',
          content:
            '수정시 ‘IFRA Certificate’ 가 필요하여 원료 검색 리스트에서 제외됩니다. ‘IFRA Certificate’ 정보 입력 후 원료 등록을 다시 해주세요.',
          okText: '수정',
          closable: true,
          cancelText: '취소',
          onOk: () => {
            updateAllergensList(
              {
                materialAllergenListId: allergensList?.materialAllergenListId!,
                canUseForFragrance: formValue.canUseForFragrance,
                ...(formValue.canUseForFragrance && {
                  isAllergenFree: formValue.isAllergenFree,
                }),
                ingredients,
              },
              {
                onSuccess: () => {
                  message.success('수정되었습니다.');
                  navigate(-1);
                },
              },
            );
          },
        });
        return;
      }
    }

    if (ingredients.length > 0 || formValue.isAllergenFree !== allergensList?.isAllergenFree) {
      updateAllergensList(
        {
          materialAllergenListId: allergensList?.materialAllergenListId!,
          canUseForFragrance: formValue.canUseForFragrance,
          ...(formValue.canUseForFragrance && {
            isAllergenFree: formValue.isAllergenFree,
          }),
          ingredients,
        },
        {
          onSuccess: () => {
            message.success('수정되었습니다.');
            navigate(-1);
          },
        },
      );
      return;
    }

    return message.warning(messages.NO_NEED_TO_UPDATE);
  };

  return useMemo(
    () => ({
      allergensList,
      getListLoading,
      allergensDefaultList,
      getDefaultListLoading,
      addAllergensList,
      addLoading,
      updateAllergensList,
      updateLoading,
      getIngredients,
      getTobeUpdatedIngredients,
      onSubmit: handleSubmit,
      materialAllergenListId: allergensList?.materialAllergenListId,
    }),
    [materialId, allergensList, getListLoading, getDefaultListLoading, addLoading, updateLoading],
  );
};

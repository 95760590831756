import { Button, message, Select, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Typography } from 'components/system';
import SearchInput from 'components/system/form/SearchInput';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { useModal } from 'hook/useModal';
import { registerManageSearchTypeOptions } from 'lib/consts';
import path from 'lib/path';
import { MaterialRegisterMangeSearchParams } from 'pages/material/MaterialRegisterManagePage';
import { useMaterialRegisterManage } from 'service/material/manage';
import { MaterialRegister, RegisterSearchType, RegisterStatus } from 'types/material/register';

const MaterialReadyBlock = styled.div``;

const MaterialReady = ({
  onChangeQueryParams,
  queryParams,
}: {
  queryParams: URLSearchParams;
  onChangeQueryParams: (queryParams: Partial<MaterialRegisterMangeSearchParams>) => void;
}) => {
  const navigate = useNavigate();
  const searchKeyword = queryParams.get('searchKeyword') || '';
  const searchType =
    (queryParams.get('searchType') as RegisterSearchType) || RegisterSearchType.ALL;
  const page = Number(queryParams.get('page')) || 1;

  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);
  const [searchTypeBuffer, setSearchTypeBuffer] = useState<RegisterSearchType>(
    RegisterSearchType.ALL,
  );

  const {
    materialRegisters,
    getMaterialRegistersLoading,
    deleteMaterials,
    deleteMaterialsLoading,
  } = useMaterialRegisterManage({
    status: RegisterStatus.RM_RDY,
    page,
    searchType,
    searchKeyword,
  });

  const { openAlertModal } = useModal();

  const handleClickMaterial = (materialId: number) => {
    navigate(`${path.material.rawMaterial.root}/${materialId}`);
  };

  const handleDeleteMaterials = () => {
    if (!selectedMaterialIds.length) return;
    openAlertModal({
      content: (
        <Typography.Text style={{ fontSize: '14px' }}>정말 삭제하시겠습니까?</Typography.Text>
      ),
      onOk: () => {
        deleteMaterials(selectedMaterialIds);
        setSelectedMaterialIds([]);
        message.success('삭제되었습니다.');
      },
    });
  };

  const handleSearch = (searchKeyword: string) => {
    onChangeQueryParams({
      page: 1,
      searchType: searchTypeBuffer,
      searchKeyword,
    });
  };

  const handleChangePage = (page: number) => {
    onChangeQueryParams({ page });
  };

  const columns: ColumnsType<MaterialRegister> = useMemo(
    () => [
      {
        title: '원료명',
        dataIndex: 'materialNameEn',
        render: (materialNameEn, record) => (
          <Typography.Text
            type="BODY_2"
            color="GRAY90"
            style={{ fontWeight: 500, cursor: 'pointer' }}
            onClick={() => handleClickMaterial(record.materialId)}
          >
            {materialNameEn}
          </Typography.Text>
        ),
      },
      {
        title: '원료 생산 회사',
        dataIndex: 'originManufacturerName',
        align: 'center',
      },
      {
        title: '최근 수정 일시',
        dataIndex: 'updateDt',
        align: 'center',
        render: (updateDt) => {
          return updateDt ? updateDt.replace('T', ' ') : '-';
        },
      },
    ],
    [],
  );

  return (
    <MaterialReadyBlock>
      <Space
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          marginBottom: 24,
        }}
      >
        <Select
          options={registerManageSearchTypeOptions}
          value={searchTypeBuffer}
          onChange={setSearchTypeBuffer}
          style={{ width: 140 }}
        />
        <SearchInput
          defaultValue={searchKeyword}
          placeholder="검색"
          onSearch={handleSearch}
          style={{ width: 280 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={materialRegisters?.content}
        rowKey={({ materialId }) => materialId}
        pagination={{
          current: page,
          pageSize: 10,
          total: materialRegisters?.totalElements,
          onChange: handleChangePage,
        }}
        rowSelection={{
          selectedRowKeys: selectedMaterialIds,
          onChange: (value) => {
            setSelectedMaterialIds(value as number[]);
          },
        }}
        loading={getMaterialRegistersLoading || deleteMaterialsLoading}
        footer={() => (
          <Space
            align="center"
            style={{
              marginTop: '16px',
              width: '100%',
            }}
          >
            <Button
              type="default"
              icon={<Icon name="delete" size={14} color="PRIMARY50" />}
              onClick={handleDeleteMaterials}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              선택 삭제
            </Button>
          </Space>
        )}
      />
    </MaterialReadyBlock>
  );
};

export default MaterialReady;

import { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import RegisterTypePage from 'pages/auth/RegisterTypePage';
import { useLocation } from 'react-router-dom';

import path from 'lib/path';
import RegisterTermsPage from 'pages/auth/RegisterTermsPage';
import BrandRegisterFormPage from 'pages/auth/brand/BrandRegisterFormPage';
import ManuRegisterFormPage from 'pages/auth/manu/ManuRegisterFormPage';
import MaterialRegisterFormPage from 'pages/auth/material/MaterialRegisterFormPage';
import RegisterSuccessPage from 'pages/auth/RegisterSuccessPage';
import Error404Page from 'pages/error/Error404Page';

const RegisterRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.startsWith(path.register.form)) {
      navigate(path.register.type, { replace: true });
    }
  }, []);

  const element = useRoutes([
    {
      path: 'type',
      element: <RegisterTypePage />,
    },
    {
      path: `terms/:companyType`,
      element: <RegisterTermsPage />,
    },
    {
      path: 'form/brand',
      element: <BrandRegisterFormPage />,
    },
    {
      path: `form/manu`,
      element: <ManuRegisterFormPage />,
    },
    {
      path: 'form/material',
      element: <MaterialRegisterFormPage />,
    },
    {
      path: `success`,
      element: <RegisterSuccessPage />,
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);

  return element;
};

export default RegisterRouter;

import styled from 'styled-components';

import { deviceSize } from 'lib/styles';
import { ReactComponent as ArrowIcon } from 'asset/svg/intro/arrow_gradation.svg';
import { H1, H2, H4 } from './introStyles';
import { Flex } from 'components/ui';
import COLOR from './colors';

const Container = styled.div`
  padding: 200px 24px;
  text-align: center;

  @media ${deviceSize.sm} {
    padding: 90px 0;
  }
`;

const ReducedCostWrapper = styled(Flex)`
  margin-top: 80px;

  @media (max-width: 1200px) {
    gap: 60px;
  }

  @media ${deviceSize.md} {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }

  @media ${deviceSize.sm} {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
`;

const ReducedCostInfo = styled(Flex)`
  @media ${deviceSize.sm} {
    gap: 20px;

    h4 {
      font-size: 20px;
      line-height: 30x;
      font-weight: 500;
    }
  }
`;

const ReducedCost = styled(Flex)`
  @media ${deviceSize.sm} {
    gap: 20px;
  }
`;

const ReducedCostAmount = styled(H2)<{ content: string }>`
  font-size: 70px;
  line-height: 88px;
  color: ${COLOR.C_MAIN};

  &::after {
    font-size: 42px;
    line-height: 0;
    content: ' ${({ content }) => content}';
  }

  @media ${deviceSize.sm} {
    font-size: 64px;
    line-height: 80px;

    &::after {
      font-size: 32px;
    }
  }
`;

const ArrowDownAnimationWrapper = styled.div`
  overflow: hidden;
`;

const ArrowDownAnimation = styled(ArrowIcon)`
  transition: all 0.2s ease;
  animation-name: move;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 0.8s;
  -webkit-animation-name: move;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 0.8s;

  @keyframes move {
    0% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(34px);
      transform: translateY(34px);
      opacity: 0;
    }
  }
`;

const SectionI = () => {
  return (
    <Container>
      <H1>비용을 절감하세요.</H1>
      <ReducedCostWrapper justify="center" gap={130}>
        <ReducedCostInfo gap={30} dir="column" align="center">
          <ReducedCost align="center" gap={30}>
            <ArrowDownAnimationWrapper>
              <ArrowDownAnimation />
            </ArrowDownAnimationWrapper>
            <ReducedCostAmount content="%">50</ReducedCostAmount>
          </ReducedCost>
          <H4>1년 인허가 예산 감소 비율</H4>
        </ReducedCostInfo>
        <ReducedCostInfo gap={30} dir="column" align="center">
          <ReducedCost align="center" gap={30}>
            <ArrowDownAnimationWrapper>
              <ArrowDownAnimation />
            </ArrowDownAnimationWrapper>
            <ReducedCostAmount content="%">30</ReducedCostAmount>
          </ReducedCost>
          <H4>
            1년 인체적용시험
            <br />
            예산 감소 비율
          </H4>
        </ReducedCostInfo>
        <ReducedCostInfo gap={30} dir="column" align="center">
          <ReducedCost align="center" gap={30}>
            <ArrowDownAnimationWrapper>
              <ArrowDownAnimation />
            </ArrowDownAnimationWrapper>
            <ReducedCostAmount content="원">
              3
              <span
                style={{
                  fontSize: 60,
                  display: 'inline-block',
                  transform: 'translateY(-4px)',
                }}
              >
                억
              </span>
            </ReducedCostAmount>
          </ReducedCost>
          <H4>
            연매출 100억 회사의
            <br />
            연간 비용 절감액{' '}
          </H4>
        </ReducedCostInfo>
      </ReducedCostWrapper>
    </Container>
  );
};

export default SectionI;

import { Button, Collapse, Form, FormInstance, Input, message, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import produce from 'immer';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { MinusIcon, Typography } from 'components/system';
import { AllergenBackgroundRadio, Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import {
  exceptKoreanOrAlphabetRule,
  exceptKoreanRule,
  generateFormRules,
  requireRule,
} from 'lib/validate';
import { useAllergenListIngredients } from 'service/brand/product/allergensList';
import { useGetPhysicalProperties } from 'service/brand/product/physicalProperties';
import {
  AllergenListIngredientData,
  AllergensListFormValues,
  FragranceBasic,
} from 'types/brand/product/allergensList';
import InputGuideButton from './InputGuideButton';
import { useCurrentProduct } from 'service/brand/product/product';

// HINT: 유향일 때 향료정보가 필수 입력인 국가 목록
const fragranceInfoRequiredCountries = ['EU', 'UK', 'TH', 'SA', 'AE', 'QA', 'KW', 'BH', 'IN'];

const AllergenOdourFormList = ({
  activeKey,
  onChangeActiveKey,
  validatePanels,
  setDeletedProductAllergenListFragranceIds,
  productId,
  countryId,
  form,
  readOnlyMode,
}: {
  activeKey: string | string[];
  onChangeActiveKey: (activeKey: string | string) => void;
  validatePanels: () => Promise<void>;
  setDeletedProductAllergenListFragranceIds: React.Dispatch<React.SetStateAction<number[]>>;
  productId: number;
  countryId?: number;
  form: FormInstance<AllergensListFormValues>;
  readOnlyMode: boolean;
}) => {
  const [visibleGuide, setVisibleGuide] = useState(false);

  const [checkIngredients, setCheckIngredients] = useState<
    | {
        ingredientName: string;
        casNo: string;
        ingredientContent: number;
      }[]
    | null
  >([]);

  const { openAlertModal } = useModal();
  const { allergenListIngredients, isLoading, ingredientsMap } = useAllergenListIngredients();
  const { productPhysicalProperty } = useGetPhysicalProperties({
    productId,
    countryId,
  });
  const currentProduct = useCurrentProduct();

  const isFragranceInfoRequired = currentProduct.productCountries.some((productCountry) =>
    fragranceInfoRequiredCountries.includes(productCountry.country.countryCode),
  );

  const handleChangeActiveKeys = (keys: string | string[]) => {
    onChangeActiveKey(Array.isArray(keys) ? keys[keys.length - 1] : keys);
  };

  const ingredientsOptions = useMemo(() => {
    if (allergenListIngredients) {
      return allergenListIngredients.map(({ allergenListIngredientId, ingredientName }) => ({
        label: ingredientName,
        value: allergenListIngredientId,
      }));
    }

    return [];
  }, [allergenListIngredients]);

  const columns: ColumnsType<AllergenListIngredientData> = [
    {
      title: '성분명',
      align: 'center',
      width: '70%',
      dataIndex: 'ingredientName',
      render: (_, { ingredientName, allergenListIngredientId, name }, index) => (
        <Form.Item
          key={name}
          noStyle
          shouldUpdate={(prev, next) =>
            prev.fragrances[name]?.allergicIngredients?.length !==
            next.fragrances[name]?.allergicIngredients?.length
          }
        >
          {({ getFieldValue, setFieldsValue }) => {
            const fragrances = getFieldValue('fragrances');
            const fragrance = fragrances[name].allergicIngredients[index];
            return (
              <div style={{ position: 'relative' }}>
                {!readOnlyMode && (
                  <MinusIcon
                    style={{ position: 'absolute', left: -32 }}
                    onClick={() => {
                      setFieldsValue({
                        fragrances: produce(getFieldValue('fragrances'), (fragrances: any) => {
                          fragrances[name].allergicIngredients[index] = {
                            name,
                            allergenListIngredientId,
                            isDelete: true,
                            ingredientContent: fragrance.ingredientContent,
                            ingredientName: fragrance.ingredientName,
                          };
                        }),
                      });
                    }}
                  />
                )}
                {ingredientName}
              </div>
            );
          }}
        </Form.Item>
      ),
    },
    {
      title: 'CAS No.',
      width: '15%',
      align: 'center',
      dataIndex: 'casNo',
    },
    {
      title: '향료 속 함량',
      align: 'left',
      width: '15%',
      render: (_, { name, allergenListIngredientId }, index) => (
        <>
          <Form.Item
            style={{ paddingLeft: 20 }}
            name={[name, 'allergicIngredients', index, 'ingredientContent']}
            rules={[
              requireRule,
              exceptKoreanRule,
              {
                pattern: /^([1-9](\d?)|0)(\.\d{1,8})?$/,
                message:
                  '양의 정수는 최대 2자리, 소수점은 아래 8자리까지 가능, 음수 입력 불가, “0” 입력 불가.',
              },
            ]}
          >
            <Input style={{ height: 32, width: 160 }} addonAfter="%" disabled={readOnlyMode} />
          </Form.Item>
          <Form.Item
            noStyle
            name={[name, 'allergicIngredients', index, 'allergenListIngredientId']}
            initialValue={allergenListIngredientId}
          >
            <div></div>
          </Form.Item>
        </>
      ),
    },
  ];

  const productType: 'LEAVE_ON' | 'RINCE_OFF' | null = useMemo(() => {
    if (productPhysicalProperty) {
      return productPhysicalProperty.productType;
    }

    return null;
  }, [productPhysicalProperty]);

  const handleClickCheckButton = () => {
    validatePanels()
      .then(() => {
        const fragrances: Partial<FragranceBasic>[] = form.getFieldValue('fragrances');
        // Step1: fragrances 배열 안의 allergicIngredients를 ingredientName을 key 값으로 한 map을 생성
        if (fragrances) {
          const checkIngredientMap = fragrances.reduce(
            (map, fragrance) => {
              const usageLevel = Number(fragrance.usageLevel);
              fragrance.allergicIngredients?.forEach(
                ({ ingredientName, ingredientContent, casNo }) => {
                  const value = map.get(ingredientName);
                  const numberIngredientContent = usageLevel * Number(ingredientContent) * 0.01;
                  const newIngredientContent = parseFloat(numberIngredientContent.toPrecision(15));
                  return map.set(
                    ingredientName,
                    value
                      ? {
                          ...value,
                          ingredientContent: value.ingredientContent + newIngredientContent,
                        }
                      : {
                          ingredientName,
                          ingredientContent: newIngredientContent,
                          casNo,
                        },
                  );
                },
              );
              return map;
            },
            new Map<
              string,
              {
                ingredientName: string;
                casNo: string;
                ingredientContent: number;
              }
            >(),
          );
          // Step2: 생성된 Map을 통해 InfoBox에 기재할 성분을 배열에 추가함.
          const uniqueCheckIngredients = [...checkIngredientMap.values()];
          // HINT: leave_on (0.001이상) / rinse_off (0.01 이상)
          const filteredIngredients = uniqueCheckIngredients.filter(({ ingredientContent }) =>
            productType === 'LEAVE_ON' ? ingredientContent >= 0.001 : ingredientContent >= 0.01,
          );
          setCheckIngredients(filteredIngredients.length > 0 ? filteredIngredients : null);
        }
      })
      .catch(() => message.warning('필수 정보를 입력해야합니다.'));
  };

  return (
    <Container>
      <Form.List name="fragrances" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            <Flex justify="space-between" align="center" style={{ marginBottom: 16 }}>
              <Flex align="center" gap={8}>
                <Typography.Text asterisk={isFragranceInfoRequired} type="TITLE_2">
                  향료 정보
                </Typography.Text>
                <InputGuideButton
                  visible={visibleGuide}
                  onClick={() => setVisibleGuide(!visibleGuide)}
                  onClose={() => setVisibleGuide(false)}
                />
              </Flex>
              {!readOnlyMode && (
                <Button
                  icon={<Icon name="plus" size={18} color="PRIMARY50" />}
                  style={{
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px 7px',
                  }}
                  onClick={() => {
                    if (fields.length === 10) {
                      return message.warning('최대 10개까지 등록 가능합니다.');
                    }

                    // HINT: 향료 정보 추가시에 입력되지 않은 폼이 있으면 해당 Panels를 오픈함.
                    validatePanels()
                      .then(() => {
                        add();
                        onChangeActiveKey(fields.length.toString());
                      })
                      .catch(() => console.debug('입력되지 않은 정보가 있습니다.'));
                  }}
                >
                  향료 정보 추가
                </Button>
              )}
            </Flex>
            <Collapse
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <Icon name="up" size={20} style={{ position: 'absolute', top: 18, right: 36 }} />
                ) : (
                  <Icon
                    name="down"
                    size={20}
                    style={{ position: 'absolute', top: 18, right: 36 }}
                  />
                )
              }
              activeKey={activeKey}
              onChange={(key) => {
                handleChangeActiveKeys(key);
              }}
            >
              {fields.map((field, index) => (
                <Collapse.Panel
                  key={field.name}
                  header={
                    <Form.Item
                      key={field.name}
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.fragrances[field.name]?.fragranceName !==
                          next.fragrances[field.name]?.fragranceName ||
                        prev.fragrances[field.name]?.usageLevel !==
                          next.fragrances[field.name]?.usageLevel
                      }
                    >
                      {({ getFieldValue }) => {
                        const name = getFieldValue(['fragrances', field.name, 'fragranceName']);
                        const level = getFieldValue(['fragrances', field.name, 'usageLevel']);
                        const productAllergenListFragranceId = getFieldValue([
                          'fragrances',
                          field.name,
                          'productAllergenListFragranceId',
                        ]);
                        return (
                          <div style={{ position: 'relative' }}>
                            {!readOnlyMode && (fields.length > 1 || !isFragranceInfoRequired) && (
                              <MinusIcon
                                style={{ position: 'absolute', left: -70 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openAlertModal({
                                    content: '정말 삭제하시겠습니까?',
                                    okText: '삭제',
                                    onOk: () => {
                                      if (productAllergenListFragranceId) {
                                        setDeletedProductAllergenListFragranceIds((draft) =>
                                          draft.concat(productAllergenListFragranceId),
                                        );
                                      }
                                      remove(field.name);
                                    },
                                  });
                                }}
                              />
                            )}
                            <Flex align="center" gap={8} gutter={{ right: 36 }}>
                              <Icon name="experiment" size={18} />
                              <Typography.Text
                                color="SLATE_GRAY70"
                                medium
                                style={{
                                  wordBreak: 'break-all',
                                }}
                              >
                                {`${name || `향료${index + 1}`} / ${level || '-'}`}%
                              </Typography.Text>
                            </Flex>
                          </div>
                        );
                      }}
                    </Form.Item>
                  }
                  forceRender
                >
                  <Wrapper>
                    <Form.Item
                      key={field.name}
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.fragrances[field.name]?.fragranceName !==
                          next.fragrances[field.name]?.fragranceName ||
                        prev.fragrances[field.name]?.usageLevel !==
                          next.fragrances[field.name]?.usageLevel
                      }
                    >
                      {({ getFieldsValue }) => {
                        return (
                          <>
                            <NumberTitle>
                              <Typography.Text type="TITLE_2">
                                기본 정보를 입력해 주세요.
                              </Typography.Text>
                            </NumberTitle>
                            <FlexInputWrapper gap={16}>
                              <Form.Item
                                label="Fragrance Name"
                                name={[field.name, 'fragranceName']}
                                rules={[
                                  requireRule,
                                  exceptKoreanRule,
                                  {
                                    type: 'string',
                                    max: 100,
                                    message: '최대 100자 입력',
                                  },
                                  {
                                    validator: (_, value) => {
                                      const fields = getFieldsValue();
                                      const filteredFragrances = (
                                        fields.fragrances as Partial<FragranceBasic[]>
                                      )?.filter((_, index) => index !== field.name);
                                      if (
                                        value &&
                                        filteredFragrances.some(
                                          (fragrance) => fragrance?.fragranceName === value,
                                        )
                                      ) {
                                        return Promise.reject('중복된 Fragrance Name');
                                      }

                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="향료명 입력"
                                  style={{ width: 256 }}
                                  disabled={readOnlyMode}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Usage Level (Dosage)"
                                name={[field.name, 'usageLevel']}
                                rules={[
                                  requireRule,
                                  exceptKoreanOrAlphabetRule,
                                  {
                                    pattern: /^([1-9](\d?)|0)(\.\d{1,5})?$/,
                                    message:
                                      '양의 정수는 최대 2자리, 소수점은 아래 5자리까지 가능, 음수 입력 불가',
                                  },
                                ]}
                                style={{ position: 'relative' }}
                              >
                                <Input
                                  placeholder="해당 향료의 사용량 입력"
                                  maxLength={100}
                                  addonAfter="%"
                                  style={{ width: 278 }}
                                  disabled={readOnlyMode}
                                />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[field.name, 'productAllergenListFragranceId']}
                              >
                                <div></div>
                              </Form.Item>
                            </FlexInputWrapper>
                          </>
                        );
                      }}
                    </Form.Item>
                    <NumberTitle style={{ marginTop: 32 }}>
                      <Typography.Text type="TITLE_2">구분을 선택해 주세요.</Typography.Text>
                    </NumberTitle>
                    <StyledFormItem
                      required
                      name={[field.name, 'isNonAllergenOdour']}
                      rules={generateFormRules({
                        required: true,
                      })}
                      style={{ marginTop: 24 }}
                    >
                      <AllergenBackgroundRadio
                        disabled={readOnlyMode}
                        bodyStyle={{
                          width: 550,
                          padding: '16px 24px',
                          margin: '0 auto',
                        }}
                        options={[
                          {
                            title: '알러지 프리 (Non-Allergen) 향료',
                            value: true,
                          },
                          {
                            title: '알러지 유발 성분 포함 향료',
                            value: false,
                          },
                        ]}
                      />
                    </StyledFormItem>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.fragrances[field.name]?.isNonAllergenOdour !==
                        next.fragrances[field.name]?.isNonAllergenOdour
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue(['fragrances', field.name, 'isNonAllergenOdour']) ===
                          false && (
                          <>
                            <NumberTitle style={{ marginTop: 32 }}>
                              <Typography.Text type="TITLE_2">
                                한 장의 Allergens List에 포함되어 있는 알레르기 유발성분을 모두
                                선택해 주세요.
                              </Typography.Text>
                            </NumberTitle>
                            <InputWrapper>
                              <Form.Item
                                key={field.name}
                                noStyle
                                shouldUpdate={(prev, next) =>
                                  prev.fragrances[field.name]?.allergicIngredients?.length !==
                                  next.fragrances[field.name]?.allergicIngredients?.length
                                }
                              >
                                {({ getFieldValue, setFieldsValue }) => {
                                  const ingredients: AllergenListIngredientData[] | undefined =
                                    getFieldValue([
                                      'fragrances',
                                      field.name,
                                      'allergicIngredients',
                                    ]);

                                  return (
                                    <Form.Item
                                      required
                                      label="알레르기 유발성분 선택"
                                      name={[field.name, 'category']}
                                      rules={!ingredients ? [requireRule] : []}
                                      normalize={(value) => {
                                        if (value) return null;
                                      }}
                                    >
                                      <Select
                                        disabled={readOnlyMode}
                                        placeholder="알레르기 유발성분 검색 또는 선택"
                                        filterOption={(input, option) => {
                                          return (option?.label as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase());
                                        }}
                                        showSearch
                                        options={ingredientsOptions}
                                        onSelect={(id: number) => {
                                          const ingredient = ingredientsMap?.get(id);
                                          const inputElements = document.getElementsByClassName(
                                            'ant-select-selection-search-input',
                                          );
                                          const inputElement = inputElements[field.name];

                                          const duplicatedIngredientIndex = ingredients?.findIndex(
                                            ({ allergenListIngredientId }) =>
                                              allergenListIngredientId === id,
                                          );

                                          const fragrances = getFieldValue('fragrances');

                                          // HINT : 중복된 알레르기 유발 성분이 있는 경우
                                          if (
                                            ingredients &&
                                            typeof duplicatedIngredientIndex !== 'undefined' &&
                                            duplicatedIngredientIndex !== -1
                                          ) {
                                            if (!ingredients[duplicatedIngredientIndex].isDelete) {
                                              message.warning('이미 선택한 항목입니다.');
                                              if (inputElement) {
                                                (inputElement as HTMLInputElement).blur();
                                              }
                                              return;
                                            }

                                            // 삭제했다가 다시 추가하는 경우
                                            setFieldsValue({
                                              fragrances: produce(fragrances, (proxy: any) => {
                                                proxy[field.name].allergicIngredients[
                                                  duplicatedIngredientIndex
                                                ] = {
                                                  // name: field.name,
                                                  ...ingredient,
                                                };
                                              }),
                                            });
                                            if (inputElement) {
                                              (inputElement as HTMLInputElement).blur();
                                            }
                                            message.success('선택되었습니다.');
                                            return;
                                          }

                                          if (ingredient) {
                                            const allergicIngredients =
                                              getFieldValue([
                                                'fragrances',
                                                field.name,
                                                'allergicIngredients',
                                              ]) || [];
                                            setFieldsValue({
                                              fragrances: produce(fragrances, (proxy: any) => {
                                                proxy[field.name].allergicIngredients =
                                                  allergicIngredients.concat({
                                                    // name: field.name,
                                                    ...ingredient,
                                                  });
                                              }),
                                            });
                                          }

                                          if (inputElement) {
                                            (inputElement as HTMLInputElement).blur();
                                          }

                                          message.success('선택되었습니다.');
                                        }}
                                      />
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </InputWrapper>
                          </>
                        )
                      }
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={(prev, next) =>
                        prev.fragrances[field.name]?.allergicIngredients?.length !==
                          next.fragrances[field.name]?.allergicIngredients?.length ||
                        prev.fragrances[field.name]?.isNonAllergenOdour !==
                          next.fragrances[field.name]?.isNonAllergenOdour
                      }
                      noStyle
                    >
                      {({ getFieldValue }) => {
                        const allergicIngredients: AllergenListIngredientData[] | undefined =
                          getFieldValue(['fragrances', field.name, 'allergicIngredients'])?.map(
                            (ingredient: any) => {
                              return {
                                ...ingredient,
                                name: field.name,
                              };
                            },
                          );

                        const isAllergenOdour =
                          getFieldValue(['fragrances', field.name, 'isNonAllergenOdour']) === false;

                        const filteredIngredients = allergicIngredients?.filter(
                          ({ isDelete }) => !isDelete,
                        );

                        return (
                          isAllergenOdour &&
                          filteredIngredients &&
                          filteredIngredients.length > 0 && (
                            <>
                              <NumberTitle style={{ marginTop: 32 }}>
                                <Typography.Text medium>
                                  선택하신 성분의 함량을 입력해 주세요.
                                </Typography.Text>
                              </NumberTitle>
                              <TableWrapper>
                                <Typography.Text type="SMALL" color="SLATE_GRAY70">
                                  *향료 속 함량 : Allergens List 의&nbsp;
                                  <Typography.Text type="SMALL" color="PRIMARY50" inline>
                                    % in Fragrance 값
                                  </Typography.Text>
                                  으로, 향료 속에 포함되어 있는 알레르기 유발성분의 함량
                                  <br /> 해당 값은 미량이라도 포함되어 있다면 실제 함량 값의 확인이
                                  가능하도록 소수점 끝자리까지 입력해 주세요.
                                </Typography.Text>
                                <Table
                                  style={{ marginTop: 24 }}
                                  columns={columns}
                                  dataSource={allergicIngredients}
                                  pagination={false}
                                  loading={isLoading}
                                  rowKey="allergenListIngredientId"
                                  rowClassName={({ isDelete }: any) => (isDelete ? 'isDelete' : '')}
                                />
                              </TableWrapper>
                            </>
                          )
                        );
                      }}
                    </Form.Item>
                  </Wrapper>
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
      </Form.List>
      {!productType && (
        <Flex
          align="center"
          justify="center"
          style={{
            padding: 24,
            background: palette.PRIMARY10,
            borderRadius: 8,
          }}
          gutter={{ top: 32 }}
        >
          <Typography.Text color="SLATE_GRAY70" align="center">
            제품 유형에 따라 필수로 기재해야 할 알레르기 유발성분이 달라질 수 있어,
            <br />
            Physical Properties 입력 정보의 Product Type을 먼저 선택해주세요.
          </Typography.Text>
        </Flex>
      )}

      {productType && (
        <>
          {checkIngredients ? (
            <>
              <Flex style={{ marginTop: 60 }} align="center">
                <Icon name="check" size={18} color="PRIMARY50" style={{ marginRight: 4 }} />
                <Typography.Text type="TITLE_2" gutter={{ right: 16 }}>
                  제품에 기재해야 할 알레르기 유발성분을 확인하세요.
                </Typography.Text>

                <Button style={{ height: 32 }} onClick={handleClickCheckButton}>
                  확인하기
                </Button>
              </Flex>
              <Typography.Text type="SMALL" gutter={{ left: 24, top: 6 }}>
                *수출국에 따라 필수 기재 여부가 상이할 수 있습니다.
              </Typography.Text>
            </>
          ) : (
            <Flex style={{ marginTop: 60 }} align="center">
              <Typography.Text type="TITLE_2" gutter={{ right: 16 }}>
                필수로 기재해야 할 알레르기 유발성분이 없습니다.
              </Typography.Text>
              <Button style={{ height: 32 }} onClick={handleClickCheckButton}>
                확인하기
              </Button>
            </Flex>
          )}
          {checkIngredients && checkIngredients.length > 0 && (
            <InfoBox>
              <Typography.Text inline type="BODY_2">
                ･Product Type :&nbsp;
              </Typography.Text>
              <Typography.Text inline type="BODY_2" color="PRIMARY50">
                {productType || '-'}
              </Typography.Text>
              <Flex>
                <Typography.Text type="BODY_2">
                  ･기재해야 할 알레르기 유발성분 :&nbsp;
                </Typography.Text>
                <Flex dir="column">
                  {checkIngredients.map(({ ingredientName, casNo, ingredientContent }) => (
                    <Typography.Text type="BODY_2" color="PRIMARY50" key={ingredientName}>
                      {ingredientName} - {casNo} - {ingredientContent}%
                    </Typography.Text>
                  ))}
                </Flex>
              </Flex>
            </InfoBox>
          )}
        </>
      )}
    </Container>
  );
};

const Wrapper = styled.div`
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 32px;
`;

const FlexInputWrapper = styled(Flex)`
  width: 550px;
  margin: 24px auto 0;

  .ant-input-group-addon {
    padding: 0 0 0 8px;
    width: fit-content;
    font-size: 14px;
  }

  .ant-input-group {
    width: 256px;

    .ant-input {
      width: 256px;
    }
  }
`;

const InputWrapper = styled.div`
  width: 550px;
  margin: 24px auto 0;
`;

const TableWrapper = styled.div`
  width: 768px;
  margin: 0 0 0 26px;

  .ant-table-wrapper {
    overflow: visible;
  }
  .ant-table {
    overflow: visible;
    margin-left: 6px;
  }

  .ant-form-item-control-input-content {
    padding: 0px;

    .ant-input-group-wrapper {
      height: 32px;
      margin: 0 auto;
      width: fit-content;
    }

    .ant-input-group {
      width: fit-content;

      .ant-input {
        width: 140px;
        height: 32px;
      }
    }

    .ant-input-group-addon {
      padding: 0 0 0 8px;
      width: fit-content;
      font-size: 14px;
    }
  }

  .isDelete {
    display: none;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: center !important;
  }
`;

const NumberTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Container = styled.div`
  margin: 0 auto;
  counter-reset: no;

  ${NumberTitle} {
    &::before {
      display: inline-block;
      counter-increment: no;
      content: counter(no);
      background-color: ${palette.PRIMARY50};
      color: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 8px 4px;

    :last-child {
      padding: 16px 4px 0px;
    }
  }
`;

const InfoBox = styled.div`
  width: 920px;
  background-color: ${palette.PRIMARY10};
  border-radius: 4px;
  padding: 24px;
  margin-top: 32px;
`;

const StyledFormItem = styled(Form.Item)`
  .ant-col.ant-form-item-control {
    margin: 0 auto !important;
    width: fit-content !important;
  }
`;

export default AllergenOdourFormList;

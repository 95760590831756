import styled from 'styled-components';

import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import React from 'react';

type Props = {
  children?: React.ReactNode;
};

const StickyButtonWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Flex style={{ width: 1040, margin: '0 auto' }} justify="flex-end" gap={16}>
        {children}
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 12px 0px;
  background-color: #fff;
  border-top: 1px solid ${palette.GRAY40};
`;

export default StickyButtonWrapper;

import { Typography } from 'components/system';
import PageTemplate from 'templates/PageTemplate';
import VCRPAccount from './VCRPAccount';

const notices = [
  <>
    인증 진행 현황에서 입력하신{' '}
    <Typography.Text type="BODY_2" medium inline>
      VCRP
    </Typography.Text>{' '}
    계정 정보를 확인하실 수 있는 페이지 입니다.
  </>,
  <>
    <Typography.Text type="BODY_2" medium inline>
      VCRP
    </Typography.Text>{' '}
    계정의{' '}
    <Typography.Text type="BODY_2" medium inline>
      사용자명 (ID), 비밀번호
    </Typography.Text>
    는 오직 귀사의 제품 인증을 위해서만 사용됩니다. 기타 다른 목적으로 사용되지 않습니다.
  </>,
  <>
    계정 정보 변경시,{' '}
    <Typography.Text type="BODY_2" medium inline>
      VCRP
    </Typography.Text>{' '}
    등록을 신청하신 모든 제품에 변경된 계정정보가 반영됩니다.
  </>,
];

const VCRPAccountPage = () => {
  return (
    <PageTemplate back={false} notices={notices}>
      <VCRPAccount />
    </PageTemplate>
  );
};

export default VCRPAccountPage;

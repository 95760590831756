import { Form, message } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as certApi from 'lib/api/certificate';
import {
  CountryVendorForm,
  CountryVendorInfoAdd,
  CountryVendorInfoUpdate,
} from 'types/certificate';
import { getUpdatingObject } from 'lib/form';
import * as certificateActions from 'modules/certificate';
import { messages } from 'lib/consts';
import { useProductDocStatus } from 'service/product';
import { DocumentCode } from 'types/product';
import { useChecklist } from '../checklist';
import useGA, { EGAActionType } from 'hook/useGA';

export const useProductCountryVendor = (productId: number, countryId: number) => {
  const navigate = useNavigate();
  const { data: countryVender = null } = useQuery(
    ['partner-vendor', countryId],
    () => certApi.getCountryVendor(countryId),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );
  const [form] = Form.useForm<CountryVendorForm>();
  const setSkinGardenForm = useCallback(() => {
    if (countryVender) {
      const { businessStartHour, businessEndHour, ...rest } = countryVender;
      form.setFieldsValue({
        ...rest,
        businessHours: [moment(businessStartHour, 'HH:mm'), moment(businessEndHour, 'HH:mm')],
      });
    } else {
      throw new Error('파트너사 정보를 불러오지 못했습니다.');
    }
  }, [form, countryVender]);
  const initForm = useCallback(
    () =>
      form.setFieldsValue({
        vendorName: undefined,
        vendorTel: undefined,
        businessHours: undefined,
        isOpenMonday: true,
        isOpenTuesday: true,
        isOpenWednesday: true,
        isOpenThursday: true,
        isOpenFriday: true,
        isOpenSaturday: false,
        isOpenSunday: false,
      }),
    [form],
  );
  const { sendEventToGA } = useGA();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.CV,
  });
  const { getLoading: getCheckListLoading, getChecklistByCode } = useChecklist({
    productId,
    countryId,
    updateMode: true,
  });

  const updateMode = productDocStatus ? productDocStatus.status !== 'INP' : false;
  const { data: countryVenderInfo = null, isFetching: getLoading } = useQuery(
    ['country-vendor', productId, countryId],
    () => certApi.getCountryVendorInfo({ productId, countryId }),
    {
      select: (res) => res.data.result,
      enabled: updateMode,
    },
  );
  const { mutate: addCountryVendor, isLoading: addLoading } = useMutation(
    (countryVenderInfoAdd: CountryVendorInfoAdd) => certApi.addCountryVendor(countryVenderInfoAdd),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '판매처 정보',
          actionType: EGAActionType.REGISTER,
        });
        message.success('입력되었습니다.');
        navigate(-1);
      },
    },
  );
  const { mutate: updateCountryVendor, isLoading: updateLoading } = useMutation(
    (countryVenderInfoUpdate: CountryVendorInfoUpdate) =>
      certApi.updateCountryVendor(countryVenderInfoUpdate),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '판매처 정보',
          actionType: EGAActionType.MODIFY,
        });
        dispatch(certificateActions.fixDocument(productDocStatus!.productDocStatusId));
        message.success('보완 완료되었습니다.');
        navigate(-1);
      },
    },
  );
  const onSubmit = useCallback(
    (formValue: CountryVendorForm) => {
      let { businessHours, ...rest } = formValue;
      const newFormValue = {
        ...(businessHours && {
          businessStartHour: moment(businessHours[0]).format('HH:mm'),
        }),
        ...(businessHours && {
          businessEndHour: moment(businessHours[1]).format('HH:mm'),
        }),
        ...rest,
      };
      if (!updateMode) {
        addCountryVendor({
          productId,
          countryId,
          ...newFormValue,
        });
      } else {
        const updatingObject = getUpdatingObject(newFormValue, countryVenderInfo);
        if (!updatingObject) {
          return message.warning(messages.NO_NEED_TO_UPDATE);
        }
        updateCountryVendor({
          productId,
          countryId,
          ...updatingObject,
        });
      }
    },
    [countryVenderInfo, updateMode],
  );

  const isUsePartnerAgency: boolean = useMemo(() => {
    const checklist = getChecklistByCode('PARTNER_BUYER');
    return checklist?.answer?.isYes || false;
  }, [getChecklistByCode]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (countryVenderInfo) {
      const { productCountryVendorId, businessStartHour, businessEndHour, vendorTel, ...rest } =
        countryVenderInfo;
      form.setFieldsValue({
        ...rest,
        ...(vendorTel && vendorTel === '0352911808'
          ? {
              vendorTel: '03-5291-1808',
            }
          : { vendorTel }),
        ...(businessStartHour && {
          businessHours: [moment(businessStartHour, 'HH:mm'), moment(businessEndHour, 'HH:mm')],
        }),
      });
    }
  }, [countryVenderInfo]);

  return useMemo(
    () => ({
      form,
      isUsePartnerAgency,
      getCheckListLoading,
      countryVenderInfo,
      updateMode,
      onSubmit,
      setSkinGardenForm,
      initForm,
      getLoading,
      fetchLoading: addLoading || updateLoading,
    }),
    [
      form,
      isUsePartnerAgency,
      countryVenderInfo,
      updateMode,
      getCheckListLoading,
      onSubmit,
      setSkinGardenForm,
      initForm,
      getLoading,
      addLoading,
      updateLoading,
    ],
  );
};

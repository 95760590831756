import styled from 'styled-components';
import { Button, Form, FormInstance, message, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

import {
  Flex,
  OrderList,
  OrderListItem,
  OrderListItemContent,
  OrderListItemLabel,
} from 'components/ui';
import UpdateLog from './UpdateLog';
import { DocumentCode } from 'types/product';
import {
  EfficacyDescriptionCode,
  EfficacyChina,
  ProductEfficacyChinaEvaluationListItem,
  useCountryId,
  useCurrentProduct,
  useProductEfficacyEvaluation,
} from 'service/brand/product/product';
import { focusToInvalidatedField } from 'lib/form';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { useProductDocStatus } from 'service/product';
import { Collapse, Typography } from 'components/system';
import { generateFormRules } from 'lib/validate';
import palette from 'lib/styles/palette';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import { messages } from 'lib/consts';
import { guideTextMap } from './ProductEfficacy';
import { instanceOf } from 'lib/common';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import Icon from 'components/ui/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import FullLoading from 'components/FullLoading';

export interface EfficacyEvaluationAttachUpload {
  url: string;
  name: string;
  productEfficacyEvaluationDataChinaId: number;
}

const Container = styled.div``;

const ProductEfficacyEvaluationUIContainer = styled.div``;

const HighlightedLetter = styled.span`
  color: ${palette.PRIMARY50};
`;

const GuideTextWrapper = styled.div`
  padding: 16px;
  background-color: ${palette.PRIMARY10};
  border-radius: 4px;

  > *:first-of-type {
    margin-bottom: 4px;
  }
`;

export interface ProductEfficacyEvalutaionForm {
  productEfficacyChinaEvaluation: {
    files: (File | EfficacyEvaluationAttachUpload)[];
    productEfficacyChinaId: number;
  }[];
}

export const ProductEfficacyEvaluation = () => {
  const navigate = useNavigate();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<ProductEfficacyEvalutaionForm>();
  const [form] = Form.useForm<ProductEfficacyEvalutaionForm>();

  const { sendEventToGA } = useGA();
  const countryId = useCountryId() || 0;
  const { productId } = useCurrentProduct();
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PEEC,
  });

  const {
    productEfficacyChinaEvaluationListItems,
    isGetproductEfficacyChinaEvaluationListItemsLoading,
    updateProductEfficacyChinaEvaluation,
    isUpdateProductEfficacyChinaEvaluationLoading,
  } = useProductEfficacyEvaluation(productId);

  const updateMode = docStatus !== null && docStatus.status !== 'INP';
  const readOnly = useSelector(({ certificate }: any) => certificate.readOnlyMode);

  const cta = useMemo(() => {
    if (updateMode && docStatus?.status === 'MOD') {
      return '보완 완료';
    }
    return '등록';
  }, [updateMode, docStatus]);

  const handleSubmit = (formValues: ProductEfficacyEvalutaionForm) => {
    if (updateMode && isEqual(initialFormValues, formValues)) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    // HINT :
    // file만 보낼 시 등록,
    // file + productEfficacyEvaluationDataChinaId 보낼 시 수정(또는 유지)
    updateProductEfficacyChinaEvaluation(
      {
        countryId,
        productEfficacyEvaluationChinas: formValues.productEfficacyChinaEvaluation.map(
          ({ productEfficacyChinaId, files }) => ({
            productEfficacyChinaId,
            fileInfos: files
              ? files.map((file) => ({
                  ...(instanceOf<EfficacyEvaluationAttachUpload>(
                    file,
                    'productEfficacyEvaluationDataChinaId',
                  ) && {
                    productEfficacyEvaluationDataChinaId: file.productEfficacyEvaluationDataChinaId,
                  }),
                  ...(file instanceof File && { file }),
                }))
              : [],
          }),
        ),
      },
      {
        onSuccess: () => {
          sendEventToGA({
            documentName: '효능 평가 자료',
            actionType: cta === '보완 완료' ? EGAActionType.MODIFY : EGAActionType.REGISTER,
          });
          message.success(`${cta}되었습니다.`);
          navigate(-1);
        },
      },
    );
  };

  useEffect(() => {
    if (productEfficacyChinaEvaluationListItems.length > 0) {
      const initialFormValues = {
        productEfficacyChinaEvaluation: productEfficacyChinaEvaluationListItems.map(
          ({ productEfficacyChinaId, productEfficacyEvaluationChinaFileResponseDtos }) => ({
            productEfficacyChinaId,
            files: productEfficacyEvaluationChinaFileResponseDtos?.map(
              ({ filename, uploadFileUrl, productEfficacyEvaluationDataChinaId }) => ({
                name: filename,
                url: uploadFileUrl,
                productEfficacyEvaluationDataChinaId,
              }),
            ),
          }),
        ),
      };
      setInitialFormValues(initialFormValues);
      form?.setFieldsValue(initialFormValues);
    }
  }, [productEfficacyChinaEvaluationListItems]);

  return (
    <Container>
      {isGetproductEfficacyChinaEvaluationListItemsLoading && <FullLoading />}
      <Flex justify="end" gap={8} style={{ marginBottom: 24 }}>
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.PEEC}
        />
        {updateMode && (
          <UpdateLog productId={productId} countryId={countryId} documentCode={DocumentCode.PEEC} />
        )}
      </Flex>
      {productEfficacyChinaEvaluationListItems.length > 0 && (
        <ProductEfficacyEvaluationUI
          updateMode={updateMode}
          productEfficacyChinaEvaluationListItems={productEfficacyChinaEvaluationListItems}
          readOnly={readOnly}
          onSubmit={handleSubmit}
          submitting={isUpdateProductEfficacyChinaEvaluationLoading}
          cta={cta}
          form={form}
        />
      )}
    </Container>
  );
};

const ProductEfficacyEvaluationUI = ({
  form,
  cta,
  readOnly = false,
  onSubmit,
  submitting = false,
  productEfficacyChinaEvaluationListItems,
  updateMode = false,
}: {
  form?: FormInstance<ProductEfficacyEvalutaionForm>;
  cta?: string;
  readOnly?: boolean;
  onSubmit?: (formValues: ProductEfficacyEvalutaionForm) => void;
  submitting?: boolean;
  productEfficacyChinaEvaluationListItems: ProductEfficacyChinaEvaluationListItem[];
  updateMode?: boolean;
}) => {
  const [activeKey, setActiveKey] = useState<string[]>();

  const handleCollapseActiveKeyChange = (key: string[]) => {
    setActiveKey(key.length > 0 ? [key[key.length - 1]] : undefined);
  };

  const handleFinishFailed = (e: ValidateErrorEntity<ProductEfficacyEvalutaionForm>) => {
    const { errorFields } = e;
    const errorKeys = [...new Set<string>(errorFields.map((field: any) => field.name[1])).values()];
    setActiveKey(errorKeys);

    if (form) focusToInvalidatedField({ form, offsetY: -300 });
  };

  const getEfficacyGuideText = (descriptionCode: EfficacyChina['descriptionCode']) => {
    if (!descriptionCode) return undefined;

    const { text, subtext } = guideTextMap[descriptionCode];

    return (
      <>
        {text}
        {subtext}
      </>
    );
  };

  useEffect(() => {
    if (!updateMode && productEfficacyChinaEvaluationListItems.length > 0) {
      const activeIndex = productEfficacyChinaEvaluationListItems.findIndex(
        ({ descriptionCode }) =>
          descriptionCode !== EfficacyDescriptionCode.PROCEEDING_WITHOUT_EFFICACY_EVALUATION &&
          descriptionCode !== EfficacyDescriptionCode.SPECIAL_EFFICACY,
      );

      setActiveKey([activeIndex.toString()]);
    } else {
      setActiveKey(['0']);
    }
  }, [productEfficacyChinaEvaluationListItems, updateMode]);

  return (
    <ProductEfficacyEvaluationUIContainer>
      <Form layout="vertical" form={form} onFinish={onSubmit} onFinishFailed={handleFinishFailed}>
        <Form.List
          name="productEfficacyChinaEvaluation"
          initialValue={[{ efficacyChinaId: 1 }, { efficacyChinaId: 2 }]}
        >
          {(fields) => (
            <Collapse
              activeKey={activeKey}
              onActiveKeyChange={handleCollapseActiveKeyChange}
              items={fields.map(({ key, name }) => {
                const descriptionCode =
                  productEfficacyChinaEvaluationListItems[name]?.descriptionCode;
                const efficacyName = productEfficacyChinaEvaluationListItems[name]?.efficacyName;
                const productEfficacyChinaId =
                  productEfficacyChinaEvaluationListItems[name]?.productEfficacyChinaId;

                return {
                  key,
                  forceRender: true,
                  label: (
                    <Collapse.Header
                      headerIcon={<Icon name="protect" size={18} />}
                      headerText={efficacyName}
                    />
                  ),
                  children: (
                    <Form.Item key={name} noStyle>
                      <OrderList>
                        <OrderListItem>
                          <OrderListItemLabel type="BODY_1" medium>
                            선택하신 제품의 효능
                          </OrderListItemLabel>
                          <OrderListItemContent padding={{ top: 8, left: 28, right: 28 }}>
                            <Typography.Text type="SMALL">
                              [제품 효능 정보] 에서 선택하신 제품의 효능입니다. 수정을 원하시는
                              경우, [제품 효능 정보] 에서만 수정이 가능합니다.
                            </Typography.Text>
                            <Flex justify="center" style={{ marginTop: 24 }}>
                              <Form.Item
                                name={[name, 'productEfficacyChinaId']}
                                rules={generateFormRules({ required: true })}
                                noStyle
                              >
                                <Select
                                  showSearch
                                  style={{ width: 520 }}
                                  disabled={true}
                                  options={[
                                    {
                                      label: efficacyName,
                                      value: productEfficacyChinaId,
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </Flex>
                          </OrderListItemContent>
                        </OrderListItem>
                        <OrderListItem>
                          <OrderListItemLabel type="BODY_1" medium>
                            선택하신 효능 <HighlightedLetter>{efficacyName}</HighlightedLetter>
                            는(은) 아래와 같은 방법으로 증빙이 가능합니다.
                          </OrderListItemLabel>
                          <OrderListItemContent padding={{ top: 24, left: 24, right: 24 }}>
                            <GuideTextWrapper>
                              {getEfficacyGuideText(descriptionCode)}
                            </GuideTextWrapper>
                          </OrderListItemContent>
                        </OrderListItem>
                        {descriptionCode !==
                          EfficacyDescriptionCode.PROCEEDING_WITHOUT_EFFICACY_EVALUATION &&
                          descriptionCode !== EfficacyDescriptionCode.SPECIAL_EFFICACY && (
                            <OrderListItem>
                              <OrderListItemLabel type="BODY_1" medium>
                                선택하신 효능의 증빙자료를 업로드해 주세요.
                              </OrderListItemLabel>
                              <OrderListItemContent
                                padding={{
                                  top: 24,
                                  left: 20,
                                  right: 20,
                                  bottom: 8,
                                }}
                              >
                                <Form.Item
                                  name={[name, 'files']}
                                  rules={generateFormRules({
                                    required: true,
                                  })}
                                >
                                  <EfficacyEvaluationUpload readOnly={readOnly} />
                                </Form.Item>
                              </OrderListItemContent>
                            </OrderListItem>
                          )}
                      </OrderList>
                    </Form.Item>
                  ),
                };
              })}
            />
          )}
        </Form.List>
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnly}>
            {cta === '보완 완료' && (
              <CorrectRequestWithoutChangeButton documentCode={DocumentCode.PEEC} />
            )}
            <Button type="primary" htmlType="submit" loading={submitting}>
              {cta}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </ProductEfficacyEvaluationUIContainer>
  );
};

const EfficacyEvaluationUpload = ({
  value,
  onChange,
  readOnly,
}: {
  value?: (File | EfficacyEvaluationAttachUpload)[];
  onChange?: (e: { target: { value?: (File | EfficacyEvaluationAttachUpload)[] } }) => void;
  readOnly: boolean;
}) => {
  const [files, setFiles] = useState<(File | EfficacyEvaluationAttachUpload)[]>([]);
  const [deleteAttachIds, setDeleteAttachIds] = useState<number[]>([]);

  useEffect(() => {
    if (value !== files && (value?.length || files?.length)) {
      onChange?.({
        target: { value: files },
      });
    }
  }, [files]);

  useEffect(() => {
    if (value && value !== files) {
      setFiles(value);
    }
  }, [value]);

  const handleUpload = (file: File) => {
    if (value?.find((item) => item.name === file.name)) {
      message.warning(messages.DUPLICATE_FILE_NAME);
      return false;
    }

    setFiles((draft) => (draft ? draft?.concat(file) : [file]));
    return true;
  };

  const handleDelete = (file: File | EfficacyEvaluationAttachUpload) => {
    if (instanceOf<EfficacyEvaluationAttachUpload>(file, 'productEfficacyEvaluationDataChinaId')) {
      setDeleteAttachIds([...deleteAttachIds, file.productEfficacyEvaluationDataChinaId]);
    }

    setFiles((draft) => draft?.filter((item) => item.name !== file.name));
    return false;
  };

  return (
    <FileUploadContainer
      accept=".docx,.pdf,.xlsx,.hwp"
      max={10}
      files={files}
      readOnly={readOnly}
      onUpload={handleUpload}
      onDelete={handleDelete}
    />
  );
};

export default ProductEfficacyEvaluationUI;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Empty, List, Spin } from 'antd';
import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';
import FooterBox from 'components/FooterBox';
import Typography from 'components/system/general/Typography';
import { useProductCategory } from 'service/brand/product/category';
import { ThirdCategoryType } from 'types/product';
import { Flex } from 'components/ui';

const ProductCategoryBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const CategorySelectBlock = styled(Card)<{
  $active: boolean;
  disabled: boolean;
}>`
  flex: 0 1 248px;
  border: none;

  .ant-card-head {
    min-height: auto;
    margin-bottom: 16px;
    padding: 0;
    border-bottom: none;

    .ant-card-head-title {
      padding: 0 !important;

      span {
        display: flex;
        align-items: center;
        font-size: 18px;

        .no {
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-right: 8px;
          border-radius: 50%;
          background-color: ${palette.PRIMARY50};
          color: #fff;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  .ant-card-body {
    height: 647px;
    padding: 4px;
    border: 1px solid ${palette.GRAY40};
    border-radius: 4px;
    overflow-y: scroll;

    ${({ $active }) =>
      $active &&
      css`
        border-color: ${palette.PRIMARY50};
        box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
      `}

    .ant-list-item {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      min-height: 44px;
      color: ${palette.GRAY50};
      letter-spacing: -0.5px;
      word-break: keep-all;

      &.selected {
        background-color: ${({ disabled }) => (disabled ? palette.GRAY30 : palette.LIGHT_BLUE20)};
        color: ${({ disabled }) => !disabled && palette.GRAY90};
      }

      &:hover {
        ${({ disabled }) =>
          !disabled &&
          css`
            background-color: ${palette.LIGHT_BLUE10};
            color: ${palette.GRAY90};
          `}
      }
      &:last-child {
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }
`;

const ExampleBlock = styled.div`
  width: 500px;
  margin: 56px auto 0;
`;

const CategorySelect = ({
  title,
  categories,
  selectedCategoryId,
  active,
  empty,
  onChange,
  disabled,
}: any) => {
  return (
    <CategorySelectBlock title={title} size="small" $active={active} disabled={disabled}>
      <List
        size="small"
        dataSource={categories}
        locale={empty}
        renderItem={(category: any) => (
          <List.Item
            className={
              category.cosmeticCategoryId === selectedCategoryId ||
              category.functionalCategoryId === selectedCategoryId
                ? 'selected'
                : ''
            }
            onClick={() =>
              !disabled && onChange(category.cosmeticCategoryId || category.functionalCategoryId)
            }
          >
            {category.name}
          </List.Item>
        )}
      />
    </CategorySelectBlock>
  );
};

const ProductCategory = ({ productId }: { productId: number }) => {
  const navigate = useNavigate();

  const {
    productCategory,
    firstCategories,
    secondCategories,
    detailCategories,
    selectedFirstCategoryId,
    setSelectedFirstCategoryId,
    selectedSecondCategoryId,
    setSelectedSecondCategoryId,
    selectedThirdCategory,
    setSelectedThirdCategory,
    selectedDetailCategoryId,
    setSelectedDetailCategoryId,
    getCategoryLoading,
    categoryExample,
    addProductCategoryLoading,
    onSubmit,
  } = useProductCategory(productId);

  useEffect(() => {
    if (productCategory) {
      setSelectedFirstCategoryId(productCategory.cosmeticCategoryIdDepth1);
      setSelectedSecondCategoryId(productCategory.cosmeticCategoryIdDepth2);
      setSelectedThirdCategory(
        productCategory.isFunctional ? ThirdCategoryType.FUNCTIONAL : ThirdCategoryType.NORMAL,
      );
      setSelectedDetailCategoryId(productCategory.functionalCategoryId);
    }
  }, [productCategory]);

  useEffect(() => {
    if (!productCategory) {
      setSelectedSecondCategoryId(null);
    }
  }, [selectedFirstCategoryId]);

  useEffect(() => {
    if (!productCategory) {
      setSelectedThirdCategory(null);
    }
  }, [selectedSecondCategoryId]);

  useEffect(() => {
    if (!productCategory) {
      setSelectedDetailCategoryId(null);
    }
  }, [selectedThirdCategory]);

  return (
    <Spin spinning={getCategoryLoading}>
      <ProductCategoryBlock>
        <Flex gap={16} style={{ width: '100%' }}>
          <CategorySelect
            title={
              <Typography.Text type="TITLE_2">
                <span className="no">1</span>대분류
              </Typography.Text>
            }
            categories={firstCategories}
            selectedCategoryId={selectedFirstCategoryId}
            active={!selectedFirstCategoryId}
            onChange={setSelectedFirstCategoryId}
            disabled={productCategory}
          />
          <CategorySelect
            title={
              <Typography.Text type="TITLE_2">
                <span className="no">2</span>중분류
              </Typography.Text>
            }
            categories={secondCategories}
            selectedCategoryId={selectedSecondCategoryId}
            active={selectedFirstCategoryId && !selectedSecondCategoryId}
            empty={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="대분류를 선택해 주세요." />
              ),
            }}
            onChange={setSelectedSecondCategoryId}
            disabled={productCategory}
          />
          <CategorySelectBlock
            title={
              <Typography.Text type="TITLE_2">
                <span className="no">3</span>소분류
              </Typography.Text>
            }
            size="small"
            $active={
              selectedFirstCategoryId !== null &&
              selectedSecondCategoryId !== null &&
              selectedThirdCategory === null
            }
            disabled={productCategory !== null}
          >
            <List
              size="small"
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="중분류를 선택해 주세요"
                  />
                ),
              }}
            >
              {selectedSecondCategoryId !== null && (
                <>
                  <List.Item
                    className={selectedThirdCategory === ThirdCategoryType.NORMAL ? 'selected' : ''}
                    onClick={() =>
                      !productCategory && setSelectedThirdCategory(ThirdCategoryType.NORMAL)
                    }
                  >
                    일반 화장품
                  </List.Item>
                  <List.Item
                    className={
                      (selectedThirdCategory === ThirdCategoryType.FUNCTIONAL) === true
                        ? 'selected'
                        : ''
                    }
                    onClick={() =>
                      !productCategory && setSelectedThirdCategory(ThirdCategoryType.FUNCTIONAL)
                    }
                  >
                    기능성 화장품
                  </List.Item>
                </>
              )}
            </List>
          </CategorySelectBlock>
          {detailCategories.length > 0 && (
            <CategorySelect
              title={
                <Typography.Text type="TITLE_2">
                  <span className="no">4</span>상세분류
                </Typography.Text>
              }
              categories={detailCategories}
              selectedCategoryId={selectedDetailCategoryId}
              active={selectedThirdCategory !== null && !selectedDetailCategoryId}
              onChange={setSelectedDetailCategoryId}
              disabled={productCategory}
            />
          )}
        </Flex>
        {categoryExample && (
          <ExampleBlock>
            <Typography.Text type="TITLE_2" gutter={{ bottom: 16 }} style={{ textAlign: 'center' }}>
              카테고리 예시
            </Typography.Text>
            <Typography.Text
              type="BODY_2"
              style={{
                textAlign: 'center',
                border: `1px solid ${palette.PRIMARY50}`,
                borderRadius: 4,
                padding: '12px',
                wordBreak: 'keep-all',
              }}
            >
              {categoryExample}
            </Typography.Text>
          </ExampleBlock>
        )}
        <FooterBox>
          <Button
            type="primary"
            loading={addProductCategoryLoading}
            onClick={productCategory ? () => navigate(-1) : onSubmit}
          >
            {productCategory ? '뒤로' : '등록'}
          </Button>
        </FooterBox>
      </ProductCategoryBlock>
    </Spin>
  );
};

export default ProductCategory;

import { useQuery, useMutation } from 'react-query';
import { useMemo } from 'react';
import qs from 'qs';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import {
  AllergenListIngredient,
  AllergensList,
  AllergensListAddParams,
  AllergensListUpdateParams,
} from 'types/brand/product/allergensList';

export const useAllergensList = (productId: number, countryId?: number) => {
  const { data: allergensList, isLoading } = useQuery(
    ['product-allergen-list', productId, countryId],
    () =>
      client.get<APIResponse<AllergensList>>(`/products/${productId}/allergen-lists`, {
        params: {
          countryId,
        },
      }),
    {
      select: (res) => res.data.result,
    },
  );

  return { allergensList, isLoading };
};

export const useAddAllergensList = () => {
  const { mutate: addAllergensList, isLoading } = useMutation((params: AllergensListAddParams) => {
    const { productId, ...rest } = params;
    return client.post<APIResponse<null>>(`/v1/products/${productId}/allergen-lists`, rest);
  });

  return {
    addAllergensList,
    isLoading,
  };
};

export const useUpdateAllergensList = () => {
  const { mutate: updateAllergensList, isLoading } = useMutation(
    (params: AllergensListUpdateParams) => {
      const { productId, countryId, ...rest } = params;
      return client.patch<APIResponse<null>>(
        `/v1/products/${productId}/allergen-lists?${qs.stringify({
          countryId,
        })}`,
        rest,
      );
    },
  );

  return {
    updateAllergensList,
    isLoading,
  };
};

export const useUpdateAllergensListOld = () => {
  const { mutate: updateAllergensListOld, isLoading } = useMutation(
    (productAllergensList: {
      productId: number;
      countryId?: number;
      isOdourless: boolean;
      isNonAllergenOdour: boolean;
      documentFile: File;
    }) => {
      return client.post<APIResponse<null>>(
        `/product-allergen-list/file-upload`,
        productAllergensList,
      );
    },
  );

  return {
    updateAllergensListOld,
    isLoading,
  };
};

export const useAllergenListIngredients = () => {
  const { data: allergenListIngredients, isLoading } = useQuery(
    ['allergen-list-ingredients'],
    () => client.get<APIResponse<AllergenListIngredient[]>>(`/allergen-list-ingredients`),
    {
      select: (res) => res.data.result,
    },
  );

  const ingredientsMap = useMemo(
    () =>
      allergenListIngredients?.reduce(
        (map, ingredients) => map.set(ingredients.allergenListIngredientId, ingredients),
        new Map<number, AllergenListIngredient>(),
      ),
    [allergenListIngredients],
  );

  return { allergenListIngredients, isLoading, ingredientsMap };
};

import { Card } from 'antd';
import palette from 'lib/styles/palette';
import styled from 'styled-components';

const StatisticCard = styled(Card)`
  background-color: ${palette.SLATE_GRAY10};
  border: none;

  .ant-card-body {
    padding: 24px 16px;
  }
`;

export default StatisticCard;

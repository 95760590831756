import { useLayoutEffect } from 'react';

import RawMaterialSearch from 'components/material/rawMaterial/RawMaterialSearch';
import PageTemplate from 'templates/PageTemplate';

const RawMaterialSearchPage = () => {
  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateBlock"]');
    if (!rootPageTemplete) return;
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <PageTemplate
      title="원료 검색"
      description="필요한 원료의 정보를 찾아 열람 가능합니다. (원료사에서 직접 등록한 데이터입니다.)"
      back={false}
    >
      <RawMaterialSearch />
    </PageTemplate>
  );
};

export default RawMaterialSearchPage;

import { Suspense, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { message, Spin } from 'antd';

import * as certificateActions from 'modules/certificate';
import * as productActions from 'modules/product';
import CertificateDetail from 'components/certificate/CertificateDetail';
import CompanyInfoModal from 'components/modal/certificate/CompanyInfoModal';
import ProductNameModal from 'components/modal/certificate/ProductNameModal';
import JapaneseProductNameModal from 'components/modal/certificate/JapaneseProductNameModal';
import ShelfLifeDeclarationModal from 'components/modal/certificate/ShelfLifeDeclarationModal';
import SeriesProductInfoModal from 'components/modal/certificate/SeriesProductInfoModal';
import { useSetCurrentProduct } from 'service/brand/product/product';
import { DocumentCode } from 'types/product';
import { useGetProductDocStatus } from 'service/product';

const CertificateDetailContainer = () => {
  const navigate = useNavigate();
  const { certTargetId } = useParams();
  const dispatch = useDispatch();

  const {
    certificate,
    productDetail,
    documents,
    fetchLoading,
    fixDocumentLoading,
    productNameModalVisible,
    japaneseProductNameModalVisible,
    seriesProductInfoModalVisible,
    companyInfoModalVisible,
    shelfLifeMonthModalVisible,
  } = useSelector(
    ({ certificate, product, loading }) => ({
      certificate: certificate.certificate,
      productNameModalVisible: certificate.productNameModalVisible,
      japaneseProductNameModalVisible: certificate.japaneseProductNameModalVisible,
      seriesProductInfoModalVisible: certificate.seriesProductInfoModalVisible,
      companyInfoModalVisible: certificate.companyInfoModalVisible,
      shelfLifeMonthModalVisible: certificate.shelfLifeMonthModalVisible,
      productDetail: product.productDetail,
      documents: certificate.documents,
      fetchLoading: loading['certificate/GET_DOCUMENTS'],
      fixDocumentLoading: loading['certificate/FIX_DOCUMENT'],
    }),
    shallowEqual,
  );

  const getProductDocStatus = useGetProductDocStatus();
  const [fixingDocumentIndex, setFixingDocumentIndex] = useState(0);
  const isJapan = certificate?.estimateTarget.productDetail.country.countryCode === 'JP';
  const { productId, countryId } = { ...certificate?.estimateTarget };
  useSetCurrentProduct({ productId, countryId });
  const showDocument = useCallback(
    (document) => {
      if (document.isPermit === false) {
        message.warning('슈퍼유저에 의해 전성분표 열람이 제한되어 있습니다.');
        return;
      }

      if (
        document.documentCode === DocumentCode.PEEC &&
        documents.find((item) => item.documentCode === DocumentCode.PEI)?.status === 'INP'
      ) {
        message.warning('제품 효능 정보를 먼저 입력해 주세요.');
        return;
      }

      const { documentCode, status } = document;
      if (documentCode === DocumentCode.PN) {
        dispatch(certificateActions.setReadOnlyMode(status === 'ONG' || status === 'FIN'));
      }

      if (
        documentCode === DocumentCode.SPI ||
        documentCode === DocumentCode.CI ||
        documentCode === DocumentCode.SLD
      ) {
        dispatch(certificateActions.setReadOnlyMode(status === 'ONG' || status === 'FIN'));
        getProductDocStatus(
          { productId, countryId, documentCode },
          {
            onSuccess: (res) => {
              dispatch(productActions.setCurrentDocStatus(res.data.result));
            },
          },
        );
      }

      switch (documentCode) {
        case DocumentCode.PN:
          if (isJapan) {
            dispatch(certificateActions.toggleJapaneseProductNameModalVisible());
          } else {
            dispatch(certificateActions.toggleProductNameModalVisible());
          }
          break;
        case DocumentCode.SPI:
          dispatch(certificateActions.toggleSeriesProductInfoModalVisible());
          break;
        case DocumentCode.CI:
          dispatch(certificateActions.toggleCompanyInfoModalVisible());
          break;
        case DocumentCode.SLD:
          dispatch(certificateActions.toggleShelfLifeMonthModalVisible());
          break;
        default: {
          navigate(`/product/${productId}/${documentCode}?countryId=${countryId}`);
        }
      }
    },
    [productId, countryId, documents],
  );

  const fixDocument = (productDocStatusId) => {
    dispatch(certificateActions.fixDocument(productDocStatusId));
  };

  useEffect(() => {
    dispatch(certificateActions.getCertificate(certTargetId));
    dispatch(productActions.setSkipMode(false));
    dispatch(productActions.setCurrentDocStatus(null));
    dispatch(certificateActions.getDocuments(certTargetId));

    return () => {
      dispatch(certificateActions.setCertificate(null));
    };
  }, [certTargetId]);

  if (!certificate) return null;

  return (
    <>
      {productNameModalVisible && <ProductNameModal certTargetId={certTargetId} />}
      {japaneseProductNameModalVisible && <JapaneseProductNameModal certTargetId={certTargetId} />}
      {seriesProductInfoModalVisible && <SeriesProductInfoModal certTargetId={certTargetId} />}
      {companyInfoModalVisible && <CompanyInfoModal />}
      {shelfLifeMonthModalVisible && <ShelfLifeDeclarationModal />}
      <CertificateDetail
        certificate={certificate}
        productDetail={productDetail}
        documents={documents}
        fetchLoading={fetchLoading}
        fixDocumentLoading={fixDocumentLoading}
        fixingDocumentIndex={fixingDocumentIndex}
        onClickDocument={showDocument}
        onFixDocument={fixDocument}
        setFixingDocumentIndex={setFixingDocumentIndex}
      />
    </>
  );
};

const SuspendedCertificateDetailContainer = () => {
  return (
    <Suspense fallback={<Spin />}>
      <CertificateDetailContainer />
    </Suspense>
  );
};

export default SuspendedCertificateDetailContainer;

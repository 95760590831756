import RawMaterialCertification from 'components/material/certification/RawMaterialCertification';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const RawMaterialCertificationPage = () => {
  const notices = [
    '원료의 인증 정보 및 그 외 특이사항을 선택합니다. 항목 선택 시 관련 증빙자료에 대한 업로드 하실 수 있습니다.',
    '유효기간이 있는 서류의 경우, 유효기간을 입력해 주시면 유효기간 1개월 전에 certicos 알림을 받아보실 수 있습니다.',
    '이 곳에 올려주시는 데이터는 원료사의 승인 없이는 외부로 반출되지 않습니다.',
    <MistakeNoticeItem />,
  ];
  return (
    <RawMaterialPageTemplate documentName="인증 및 특이사항" notices={notices}>
      <RawMaterialCertification />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialCertificationPage;

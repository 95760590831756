import { memo } from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';

import { requireRule, exceptKoreanRule } from 'lib/validate';
import AlphabetLabel from './AlphabetLabel';

const MSDSTransportBlock = styled.div`
  max-width: 536px;
  padding-left: 16px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const MSDSTransport = () => {
  return (
    <MSDSTransportBlock>
      <Form.Item
        label={<AlphabetLabel alphabet="A">IMDG</AlphabetLabel>}
        name={['transport', 'imdg']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="No data"
      >
        <Input.TextArea
          placeholder="국제 해상 위험물 규칙"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Form.Item
        label={<AlphabetLabel alphabet="B">IATA</AlphabetLabel>}
        name={['transport', 'iata']}
        rules={[requireRule, exceptKoreanRule]}
        required={false}
        initialValue="No data"
      >
        <Input.TextArea
          placeholder="국제 항공 운송 협회"
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={1000}
          showCount
        />
      </Form.Item>
    </MSDSTransportBlock>
  );
};

export default memo(MSDSTransport);

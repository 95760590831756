import { APIResponse } from 'types/common';
import {
  AllergenIngredient,
  AllergensListGet,
  AllergensListAdd,
  AllergensListUpdate,
} from 'types/material/allergen';
import client from 'lib/api/client';

// HINT : 초기 등록한 값이 없을 때 호출하는 Default AllergensList
export const getAllergensDefaultList = async () => {
  const res = await client.get<APIResponse<AllergenIngredient[]>>(`/material/allergen/INCIs`);
  return res.data;
};

export const getAllergensList = async (materialId: number) => {
  const res = await client.get<APIResponse<AllergensListGet>>(
    `/material/allergen?materialId=${materialId}`,
  );
  return res.data;
};

export const getAllergensListByMaterialAllergenListId = async (materialAllergenListId: number) => {
  const res = await client.get<APIResponse<AllergensListGet>>(
    `/material/allergen/${materialAllergenListId}`,
  );
  return res.data;
};

export const addAllergensList = async (allergen: AllergensListAdd) => {
  const res = await client.post<APIResponse<null>>(`/material/allergen`, allergen);
  return res.data;
};

export const updateAllergensList = async (params: AllergensListUpdate) => {
  const { materialAllergenListId, ...rest } = params;
  const res = await client.patch<APIResponse<null>>(
    `/material/allergen/${materialAllergenListId}`,
    rest,
  );
  return res.data;
};

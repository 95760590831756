import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { useEffect } from 'react';

import ManagerPage from 'pages/auth/ManagerPage';
import UserInfoPage from 'pages/auth/UserInfoPage';
import BuyerPage from 'pages/buyer/BuyerPage';
import Error404Page from 'pages/error/Error404Page';
import BrandContractsPage from 'pages/estimate/BrandContractsPage';
import ManufacturerManagePage from 'pages/manufacturer/ManufacturerManagePage';
import MaterialContractsPage from 'pages/material/estimate/MaterialContractsPage';
import NMPACompanyInfoPage from 'pages/material/nmpa/NMPACompanyInfoPage';
import SharedDocsPage from 'pages/my/SharedDocsPage';
import VCRPAccountPage from 'pages/vcrp/VCRPAccountPage';
import { UserType } from 'types/auth';
import MyRPPage from 'pages/my/MyRPPage';
import MyFDAPage from 'pages/my/MyFDAPage';

const MyRouter = () => {
  const { authority, userType } = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="MyPageBlock"]');
    if (rootPageTemplete) (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  const element = useRoutes([
    {
      path: '/',
      index: true,
      element: <UserInfoPage />,
    },
    {
      path: 'userInfo',
      element: <UserInfoPage />,
    },
    userType === UserType.BRAND
      ? {
          path: 'docs',
          element: <SharedDocsPage />,
        }
      : {},
    userType === UserType.BRAND
      ? {
          path: 'estimate',
          element: <BrandContractsPage />,
        }
      : {},

    userType === UserType.BRAND
      ? {
          path: 'manufacturer',
          element: <ManufacturerManagePage />,
        }
      : {},
    userType === UserType.MATERIAL
      ? {
          path: 'estimate',
          element: <MaterialContractsPage />,
        }
      : {},
    userType === UserType.MATERIAL
      ? {
          path: 'nmpa',
          element: <NMPACompanyInfoPage />,
        }
      : {},
    authority === 'SUPER_USER'
      ? {
          path: 'manager',
          element: <ManagerPage />,
        }
      : {},

    userType === UserType.BRAND
      ? {
          path: 'buyer',
          element: <BuyerPage />,
        }
      : {},
    userType === UserType.BRAND
      ? {
          path: 'rp',
          element: <MyRPPage />,
        }
      : {},
    userType === UserType.BRAND
      ? {
          path: 'fdaar',
          element: <MyFDAPage />,
        }
      : {},
    userType === UserType.BRAND
      ? {
          path: 'vcrp',
          element: <VCRPAccountPage />,
        }
      : {},
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);

  return element;
};

export default MyRouter;

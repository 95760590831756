import { Menu, MenuProps } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useManuMode } from 'hook/company';
import path from 'lib/path';
import * as navigatorActions from 'modules/navigator';
import { useUserType } from 'service/auth';
import { UserType } from 'types/auth';
import { Typography } from 'components/system';

const StyledMenu = styled(Menu)<{ is_inline_mode: string }>`
  ${(props) =>
    props.is_inline_mode === 'false' &&
    css`
      justify-content: center;
    `}

  .ant-menu-title-content {
    font-size: 18px;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-size: 16px;
`;

type MenuItem = Required<MenuProps>['items'][number];

const BrandTopMenu = ({ inlineMode }: { inlineMode: boolean }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const manuMode = useManuMode();
  const toggleDrawer = () => {
    dispatch(navigatorActions.toggleDrawer());
  };
  const userType = useUserType();

  const menuItems: MenuItem[] = [
    ...(inlineMode
      ? []
      : [
          {
            label: '제품 등록',
            key: '1',
            children: [
              {
                key: path.product.basic,
                label: <StyledNavLink to={path.product.basic}>신규 제품 등록</StyledNavLink>,
              },
              userType === UserType.BRAND
                ? {
                    key: path.product.import,
                    label: <StyledNavLink to={path.product.import}>코드로 제품 등록</StyledNavLink>,
                  }
                : null,
              {
                key: path.product.ready,
                label: <StyledNavLink to={path.product.ready}>등록 중 제품 관리</StyledNavLink>,
              },
              {
                key: path.product.registered,
                label: (
                  <StyledNavLink to={path.product.registered}>등록 완료 제품 관리</StyledNavLink>
                ),
              },
            ],
          },
        ]),
    ...(userType === UserType.BRAND
      ? [
          {
            label: '해외 인증',
            key: '2',
            children: [
              {
                key: path.estimate.pick,
                label: <StyledNavLink to={path.estimate.pick}>셀프 견적</StyledNavLink>,
              },
              {
                key: path.certificate.ongoing,
                label: <StyledNavLink to={path.certificate.ongoing}>인증 진행 현황</StyledNavLink>,
              },
              {
                key: path.certificate.complete,
                label: (
                  <StyledNavLink to={path.certificate.complete}>인증 완료 제품 관리</StyledNavLink>
                ),
              },
              {
                key: path.notarization,
                label: <StyledNavLink to={path.notarization}>공증 서류 양식 발행</StyledNavLink>,
              },
            ],
          },
          {
            label: 'QC',
            key: '3',
            children: [
              {
                key: path.qcqa.registration.root,
                label: (
                  <StyledNavLink to={path.qcqa.registration.root}>품질 제품 등록</StyledNavLink>
                ),
              },
              {
                key: path.qcqa.management.root,
                label: <StyledNavLink to={path.qcqa.management.root}>품질 제품 관리</StyledNavLink>,
              },
              {
                key: path.qcqa.selfInspect.root,
                label: (
                  <StyledNavLink to={path.qcqa.selfInspect.root}>자율 점검 관리</StyledNavLink>
                ),
              },
            ],
          },
        ]
      : []),
    ...(!manuMode
      ? [
          {
            label: '유통 플랫폼',
            key: '4',
            children: [
              {
                key: path.retailer.retailerApply.product.list,
                label: (
                  <StyledNavLink to={path.retailer.retailerApply.product.list}>
                    국내 입점 서류 관리
                  </StyledNavLink>
                ),
              },
              {
                key: '',
                label: (
                  <Typography.Text inline color="GRAY50">
                    해외 입점 서류 관리 (서비스 예정)
                  </Typography.Text>
                ),
              },
            ],
          },
        ]
      : []),
    {
      key: '5',
      label: <Link to={path.service}>고객센터</Link>,
    },
  ];

  return (
    <StyledMenu
      mode={inlineMode ? 'inline' : 'horizontal'}
      selectedKeys={[pathname]}
      triggerSubMenuAction="click"
      is_inline_mode={inlineMode ? 'true' : 'false'}
      onClick={() => {
        inlineMode && toggleDrawer();
      }}
      items={menuItems}
    />
  );
};

export default BrandTopMenu;

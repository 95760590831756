import { Suspense, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga4';

import path from 'lib/path';
import FullLoading from 'components/FullLoading';
import { useDetectNewVersion } from 'hook/etc';
import PrivateRoute from 'components/PrivateRoute';
import LoginPage from 'pages/auth/LoginPage';
import RegisterRouter from 'routers/RegisterRouter';
import ChangePasswordPage from 'pages/auth/ChangePasswordPage';
import LogoutPage from 'pages/auth/LogoutPage';
import MainRouter from './MainRouter';
import ProductRouter from './ProductRouter';
import EstimateRouter from './EstimateRouter';
import FormulaScreeningPage from 'pages/brand/formulaScreening/FormulaScreeningPage';
import ArtworkScreeningPage from 'pages/brand/artworkScreening/ArtworkScreeningPage';
import CertificateRouter from './CertificateRouter';
import NotarizationPage from 'pages/notarization/NotarizationPage';
import QCQARouter from './QCQARouter';
import RetailerRouter from './retailer/RetailerRouter';
import ServicePage from 'pages/service/ServicePage';
import AdInquiryPage from 'pages/common/AdInquiryPage';
import UserInquiryPage from 'pages/common/UserInquiryPage';
import MyPage from 'pages/my/MyPage';
import TermsPage from 'pages/terms/TermsPage';
import ManufactureRouter from 'routers/manufacturer/ManufacturerRouter';
import MaterialRouter from './material/MaterialRouter';
import Error404Page from 'pages/error/Error404Page';
import { initIngredientToBox } from 'modules/material/ingredient';
import RightBottomIconBar from 'components/common/RightBottomIconBar';
import IngredientCompareBox from 'components/material/ingredient/IngredientCompareBox';
import RootLayout from 'components/ui/Layout/RootLayout';

const AppRouter = () => {
  const user = useSelector(({ auth }) => auth.user);
  const isLoggedIn = useMemo(() => user !== null, [user]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route path={path.logout} element={<LogoutPage />} />
        <Route element={<PrivateRoute check={!isLoggedIn} redirect={path.main} />}>
          <Route path={`${path.register.root}/*`} element={<RegisterRouter />} />
          <Route path={path.login} element={<LoginPage />} />
        </Route>
        {isLoggedIn && user.isResetPassword && (
          <Route path={path.main} element={<ChangePasswordPage />} />
        )}
        <Route path={path.main} element={<MainRouter />} />
        <Route path={path.formulaScreening} element={<FormulaScreeningPage />} />
        <Route path={`${path.artworkScreening}/:screeningId`} element={<ArtworkScreeningPage />} />
        <Route path={path.artworkScreening} element={<ArtworkScreeningPage />} />
        {/* ProductRouter */}
        <Route element={<PrivateRoute check={isLoggedIn} redirect={path.login} />}>
          <Route
            path="/*"
            element={
              <Suspense fallback={<FullLoading />}>
                <ProductRouter />
              </Suspense>
            }
          />
        </Route>
        {/* EstimateRouter */}
        <Route element={<PrivateRoute check={isLoggedIn} redirect={path.login} />}>
          <Route
            path={`${path.estimate.root}/*`}
            element={
              <Suspense fallback={<FullLoading />}>
                <EstimateRouter />
              </Suspense>
            }
          />
        </Route>
        {/* CertificateRouter */}
        <Route element={<PrivateRoute check={isLoggedIn} redirect={path.login} />}>
          <Route
            path={`${path.certificate.root}/*`}
            element={
              <Suspense fallback={<FullLoading />}>
                <CertificateRouter />
              </Suspense>
            }
          />
        </Route>
        <Route path={path.notarization} element={<NotarizationPage />} />
        {/* QCQARouter */}
        <Route element={<PrivateRoute check={isLoggedIn} redirect={path.login} />}>
          <Route
            path={`${path.qcqa.root}/*`}
            element={
              <Suspense fallback={<FullLoading />}>
                <QCQARouter />
              </Suspense>
            }
          />
        </Route>
        {/* RetailerRouter */}
        <Route element={<PrivateRoute check={isLoggedIn} redirect={path.login} />}>
          <Route
            path={`${path.retailer.retailerApply.root}/*`}
            element={
              <Suspense fallback={<FullLoading />}>
                <RetailerRouter />
              </Suspense>
            }
          />
        </Route>
        <Route path={path.service} element={<ServicePage />}>
          <Route path={`:tab`} element={<ServicePage />} />
        </Route>
        <Route path={path.adInquiry} element={<AdInquiryPage />} />
        <Route path={path.userInquiry} element={<UserInquiryPage />} />
        <Route element={<PrivateRoute check={isLoggedIn} redirect={path.login} />}>
          <Route path={`${path.my}/*`} element={<MyPage />}>
            <Route path={`:tab`} element={<MyPage />} />
          </Route>
        </Route>
        <Route path={`${path.terms.root}/:tab`} element={<TermsPage />} />
        {/* ManufactureRouter */}
        <Route element={<PrivateRoute check={isLoggedIn} redirect={path.login} />}>
          <Route
            path={`${path.manufacturer.root}/*`}
            element={
              <Suspense fallback={<FullLoading />}>
                <ManufactureRouter />
              </Suspense>
            }
          />
        </Route>
        {/* MaterialRouter */}
        <Route element={<PrivateRoute check={isLoggedIn} redirect={path.login} />}>
          <Route
            path={`${path.material.root}/*`}
            element={
              <Suspense fallback={<FullLoading />}>
                <MaterialRouter />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<Error404Page />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

const Root = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname, search } = location;
  const [initialized, setInitialized] = useState(false);

  useDetectNewVersion();

  const { auth } = useSelector(
    ({ auth }: any) => ({
      auth,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (process.env.REACT_APP_SERVER === 'DEV') {
        ReactGA.initialize('G-YS2YDNBCMT');
        setInitialized(true);
      } else if (process.env.REACT_APP_SERVER === 'REAL') {
        ReactGA.initialize('G-SRX29TG92Q');
        setInitialized(true);
      }
    }
    // HINT: local 환경 테스트시 port 5173 설정후 테스트 (export PORT=5173 && npm start)
    // else {
    //   ReactGA.initialize('G-3CC880NPRT');
    //   setInitialized(true);
    // }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({
        userId: auth?.user?.userId ? auth.user.userId : null,
      });
    }
  }, [initialized, auth]);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send('pageview');
    }
  }, [initialized, location]);

  useEffect(() => {
    if (
      !pathname.startsWith(path.material.ingredient.dictionary) &&
      !pathname.startsWith(path.material.ingredient.detail)
    ) {
      dispatch(initIngredientToBox());
    }
  }, [pathname]);

  // 관리자 권한으로 성분 스크리닝 페이지 접속 시
  if (pathname === path.formulaScreening && new URLSearchParams(search).get('source') === 'admin') {
    return (
      <RootLayout hasFooter={false} isAdmin={true}>
        <FormulaScreeningPage isAdmin={true} />
      </RootLayout>
    );
  }

  return (
    <RootLayout
      extra={
        <>
          <RightBottomIconBar />
          <IngredientCompareBox />
        </>
      }
    >
      <Outlet />
    </RootLayout>
  );
};

export default AppRouter;

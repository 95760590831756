import { Button, message } from 'antd';
import styled from 'styled-components';

import { deviceSize } from 'lib/styles';
import FooterBox from 'components/FooterBox';
import SearchInput from 'components/system/form/SearchInput';
import Typography from 'components/system/general/Typography';
import CountryItem from 'components/country/CountryItem';

const ProductCountryBlock = styled.div`
  padding-top: 20px;
`;

const CountryListBlock = styled.div`
  display: grid;
  grid-gap: 10px 10px;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;

  @media ${deviceSize.sm} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ProductCountry = ({
  updateMode,
  registered,
  deselectedCountries,
  selectedCountries,
  isSearching,
  fetchLoading,
  onSelectCountry,
  onDeselectCountry,
  onSubmit,
  onReset,
  onSearch,
}: any) => {
  return (
    <ProductCountryBlock>
      <Typography.Text light type="HEADLINE_1" align="center" mark>
        <mark>인증할 국가</mark>를 선택해 주세요.
      </Typography.Text>
      <SearchInput
        style={{ maxWidth: 500, margin: '32px auto' }}
        onChange={(e) => onSearch(e.target.value)}
        onSearch={onSearch}
      />
      {deselectedCountries.length !== 0 && (
        <CountryListBlock>
          {!isSearching && (
            <CountryItem
              country={{
                countryNameKo: 'ALL',
                countryCode: '',
                isOriginal: false,
              }}
              selected={false}
              onClick={() =>
                onSelectCountry(
                  deselectedCountries.map(({ countryId }: { countryId: number }) => countryId),
                )
              }
            />
          )}
          {deselectedCountries.map((country: any) => (
            <CountryItem
              key={country.countryId}
              selected={false}
              country={country}
              onClick={() => onSelectCountry(country.countryId)}
            />
          ))}
        </CountryListBlock>
      )}
      <Typography.Text light type="HEADLINE_1" align="center" mark gutter={{ top: 56, bottom: 32 }}>
        <mark>총 {selectedCountries.length} 개의 국가</mark>가 선택되었습니다.
      </Typography.Text>
      <CountryListBlock>
        {selectedCountries.map((country: any) => (
          <CountryItem
            key={country.countryId}
            selected={true}
            country={country}
            registered={registered}
            onClick={() => {
              if (country.isOriginal && registered) {
                return message.warning(
                  '등록이 완료된 제품은 기존에 추가했던 국가를 제거할 수 없습니다.',
                );
              }
              onDeselectCountry(country.countryId);
            }}
          />
        ))}
      </CountryListBlock>
      <FooterBox>
        <Button onClick={onReset}>다시 선택</Button>
        <Button type="primary" loading={fetchLoading} onClick={onSubmit}>
          {!updateMode ? '등록' : '수정'}
        </Button>
      </FooterBox>
    </ProductCountryBlock>
  );
};

export default ProductCountry;

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PageTemplate from 'templates/PageTemplate';
import ICIDDocumentList from 'components/material/icid/ICIDDocumentList';
import path from 'lib/path';

const ICIDDocumentListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ icidApplicationId: string }>();

  const { status } = location.state || {};
  const icidApplicationId = Number(params.icidApplicationId);

  return (
    <PageTemplate
      title="원료 입력정보"
      notices={[
        '모든 입력정보에 자료 등록이 완료되면 가장 하단의 등록 완료 버튼을 클릭해주세요. 그 이후, ICID 등재가 시작됩니다.',
        '문의사항은 전화나 하단의 ‘이용 문의’로 문의 부탁드립니다.',
      ]}
      onBack={() =>
        status === 'contracted'
          ? navigate(path.material.icid.ongoing, { replace: true })
          : navigate(-1)
      }
    >
      <ICIDDocumentList icidApplicationId={icidApplicationId} />
    </PageTemplate>
  );
};

export default ICIDDocumentListPage;

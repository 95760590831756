import ProductPageTemplate from 'templates/ProductPageTemplate';
import ProductSeparateWaste from 'components/product/ProductSeparateWaste';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = ['선택하는 용기 재질에 따라, 해당 주의사항이 라벨에 작성되어 발행됩니다.'];

const ProductSeparateWastePage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();

  return (
    <ProductPageTemplate subtitle="분리배출 용기 재질 표기 선택" hasSkip={false} notices={notices}>
      <ProductSeparateWaste productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductSeparateWastePage;

import styled from 'styled-components';
import { Tabs } from 'antd';
import { useLocation } from 'react-router-dom';

import ContractGuide from 'components/contract/ContractGuide';
import ICIDEstimateList from 'components/material/estimate/ICIDEstimateList';
import NmpaEstimateList from 'components/material/nmpa/EstimateList';

const Container = styled.div``;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 36px;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

const MaterialContractsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultActiveKey = searchParams.get('type') || undefined;

  return (
    <Container>
      <StyledTabs defaultActiveKey={defaultActiveKey}>
        <Tabs.TabPane key="estimate-list" tab="ICID 등재 견적서">
          <ICIDEstimateList />
        </Tabs.TabPane>
        <Tabs.TabPane key="nmpa-estimate-list" tab="중국신고번호 견적서">
          <NmpaEstimateList />
        </Tabs.TabPane>
        <Tabs.TabPane key="contract-guide" tab="결제 / 계약서 가이드">
          <ContractGuide />
        </Tabs.TabPane>
      </StyledTabs>
    </Container>
  );
};

export default MaterialContractsPage;

import { useParams } from 'react-router-dom';

import { Typography } from 'components/system';
import { useRawMaterial } from 'service/material/rawMaterial';
import PageTemplate, { PageTemplateProps } from 'templates/PageTemplate';
import * as S from 'templates/PageTemplateStyle';
import styled from 'styled-components';

const RawMaterialPageTemplateBlock = styled.div<{ document: boolean }>`
  .ant-page-header-heading-sub-title {
    padding-top: ${(props) => (props.document ? 8 : 10)}px !important;
  }
`;

interface RawMaterialPageTemplateProp extends PageTemplateProps {
  documentName?: string;
}

const RawMaterialPageTemplate = ({
  title,
  documentName,
  ...props
}: RawMaterialPageTemplateProp) => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId) || undefined;
  const { rawMaterial } = useRawMaterial(materialId);
  return (
    <RawMaterialPageTemplateBlock document={!!documentName}>
      <S.PageTemplateBlock>
        <PageTemplate
          {...props}
          title={
            title || (rawMaterial && rawMaterial.status === 'RM_REG' ? '원료 관리' : '원료 등록')
          }
          subtitle={
            <S.NameContainer>
              {documentName && (
                <Typography.Text style={{ fontSize: 20 }} gutter={{ right: 16 }}>
                  {documentName}
                </Typography.Text>
              )}
              {rawMaterial && (
                <div>
                  <Typography.Text type="BODY_2" inline gutter={{ right: 4 }} className="label">
                    원료명
                  </Typography.Text>
                  <Typography.Text
                    type="TITLE_2"
                    medium
                    className="product-name"
                    inline
                    style={{ maxWidth: 580 }}
                  >
                    {rawMaterial.materialNameEn}
                  </Typography.Text>
                </div>
              )}
            </S.NameContainer>
          }
        />
      </S.PageTemplateBlock>
    </RawMaterialPageTemplateBlock>
  );
};

export default RawMaterialPageTemplate;

import { useParams } from 'react-router-dom';

import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';
import NMPAEDT from 'components/material/nmpa/NMPAEDT';

const notices = [
  '국제 공인 기관에서 별도 평가받은 원료 자료를 입력해 주세요.',
  '평가 자료의 증빙자료는 가급적 함께 업로드 하는 것을 권장드립니다.',
  '입력된 내용은 취득 진행에 적합한 내용으로 수정될 수 있습니다.',
];

const NMPAEDTPage = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);

  return (
    <NMPAPageTemplate
      title="원료 신고 정보"
      documentName="국제 공인 기관 평가결론"
      notices={notices}
    >
      <NMPAEDT materialId={materialId} />
    </NMPAPageTemplate>
  );
};

export default NMPAEDTPage;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Col, Form, message, Row, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { CoaFormParam, CoaGet, CoaItemAdd } from 'types/material/coa';
import { useCoa, useCoaTests } from 'service/material/coa';
import FooterBox from 'components/FooterBox';
import PhaseSelect from './PhaseSelect';
import CoaItemList from './CoaItemList';
import CoaForm from './CoaForm';
import CoaWayChoice from './CoaWayChoice';
import { useUpdateMode } from 'hook/material';
import RouteLeaveGuard from 'components/RouteLeaveGuard';

const RawMaterialCoaBlock = styled.div``;

const RawMaterialCoa = () => {
  const params = useParams<{ materialId: string; coaId?: string }>();
  const materialId = Number(params.materialId);
  const updateMode = useUpdateMode();
  const [isImportWay, setIsImportWay] = useState<boolean>();
  const [phaseMaterialCategoryId, setPhaseMaterialCategoryId] = useState<number | undefined>();
  const { coaTests, getCoaTestsLoading } = useCoaTests(phaseMaterialCategoryId);
  const {
    coa,
    getLoading,
    addCoa,
    addLoading,
    addTemporaryCoa,
    addTemporaryLoading,
    updateCoa,
    updateLoading,
    isAddSuccess,
  } = useCoa(materialId);
  const optionalCoaTests = coaTests.filter(({ isMandatory }) => !isMandatory);
  const [form] = useForm<CoaFormParam>();

  const handleSelectRegisteredMaterial = ({ materialCategoryId, materialCOAItems }: CoaGet) => {
    form.setFieldsValue({ materialCOAItems });
    setPhaseMaterialCategoryId(materialCategoryId);
  };

  const handleSubmit = (coaForm: CoaFormParam) => {
    if (!updateMode) {
      addCoa({
        ...coaForm,
        materialCOAItems: coaForm.materialCOAItems as CoaItemAdd[],
        materialId,
        materialCategoryId: phaseMaterialCategoryId!,
      });
    } else {
      updateCoa({
        ...coaForm,
        materialCOAItems: coaForm.materialCOAItems as CoaItemAdd[],
        materialId,
      });
    }
  };

  const handleSaveCoa = () => {
    const coaForm = form.getFieldsValue();
    addTemporaryCoa({
      ...coaForm,
      materialCOAItems: coaForm.materialCOAItems as CoaItemAdd[],
      materialId,
      materialCategoryId: phaseMaterialCategoryId!,
    });
  };

  useEffect(() => {
    if (coaTests && !updateMode && isImportWay === false) {
      form.setFieldsValue({
        materialCOAItems: coaTests.map(({ materialCategoryId, code, name, isMandatory }) => ({
          materialCategoryId,
          testName: name,
          isNoData: isMandatory
            ? code === 'MP' && phaseMaterialCategoryId === 3
              ? true
              : false
            : undefined,
          code,
        })),
      });
    }
  }, [coaTests, updateMode]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && !updateMode) {
      form.setFieldsValue({
        lotNo: 'lotNo - test',
        expiryDate: '2022-03-23',
        manufacturingDate: '2021-03-23',
        shelfLife: 12,
        storageCondition: 'storageCondition - test',
      });
    }
  }, []);

  useEffect(() => {
    if (!updateMode) {
      setPhaseMaterialCategoryId(undefined);
    }
  }, [isImportWay]);

  useEffect(() => {
    if (coa) {
      form.setFieldsValue(coa);
      setPhaseMaterialCategoryId(coa.materialCategoryId);
      if (!updateMode) {
        message.success('임시 저장된 데이터를 불러왔습니다.');
      }
    }
  }, [coa]);

  return (
    <RawMaterialCoaBlock>
      <RouteLeaveGuard
        isChanged={!updateMode && !isAddSuccess}
        content={`임시 저장 혹은 저장하지 않고 페이지를 벗어날 경우,\n지금까지 작성한 내용이 사라집니다.\n페이지를 나가시겠습니까?`}
        okText="나가기"
        closeText="계속입력"
      />
      {!updateMode && !coa && (
        <CoaWayChoice isImportWay={isImportWay} onChangeIsImportWay={setIsImportWay} />
      )}
      {!updateMode && typeof isImportWay !== 'undefined' && (
        <PhaseSelect
          isImportWay={isImportWay}
          materialCategoryId={phaseMaterialCategoryId}
          onChangeMaterialCategoryId={setPhaseMaterialCategoryId}
          onSelectRegisteredMaterial={handleSelectRegisteredMaterial}
        />
      )}
      <Spin spinning={getLoading}>
        {typeof phaseMaterialCategoryId !== 'undefined' && (
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <CoaForm />
            <Form.List name="materialCOAItems">
              {(_, { add, remove }) => (
                <Form.Item
                  shouldUpdate={(prev, next) => prev.materialCOAItems !== next.materialCOAItems}
                >
                  {() => (
                    <CoaItemList
                      form={form}
                      coaItems={form.getFieldValue('materialCOAItems')}
                      optionalCoaTests={optionalCoaTests}
                      loading={!coa && getCoaTestsLoading}
                      onAddItem={add}
                      onRemoveItem={remove}
                    />
                  )}
                </Form.Item>
              )}
            </Form.List>
            <FooterBox>
              <Row gutter={16}>
                {!updateMode && (
                  <Col>
                    <Button loading={addTemporaryLoading} onClick={handleSaveCoa}>
                      임시 저장
                    </Button>
                  </Col>
                )}
                <Col>
                  <Button type="primary" htmlType="submit" loading={addLoading || updateLoading}>
                    {!updateMode ? '저장' : '수정'}
                  </Button>
                </Col>
              </Row>
            </FooterBox>
          </Form>
        )}
      </Spin>
    </RawMaterialCoaBlock>
  );
};

export default RawMaterialCoa;

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';

import { useServerDateTimeNow } from 'service/common';

export enum EGAActionType {
  REGISTER = '등록',
  UPDATE = '수정',
  MODIFY = '보완',
  CHECK = '확인',
}

export const useGA = () => {
  const [startTime, setStartTime] = useState<number>(0);
  const { auth } = useSelector(
    ({ auth }: any) => ({
      auth,
    }),
    shallowEqual,
  );
  const { now } = useServerDateTimeNow();
  const date = moment(now);

  const getElapsedTime = () => {
    const endTime = performance.now();
    const elapsedTime = Math.round((endTime - startTime) / 1000);
    return elapsedTime;
  };

  const sendEventToGA = ({
    documentName,
    actionType,
  }: {
    documentName: string;
    actionType: EGAActionType;
  }) => {
    const elapsedTime = getElapsedTime();
    date.add(elapsedTime, 'seconds');
    /**
     * action: 이벤트 이름
     * value: 소요시간 (초)
     * document_name: 문서 이름
     * page_view_time: 페이지 진입 시간
     * page_action_end_time: 서류 작성 완료 시간
     * page_action_type: 작업 종류 (등록 / 수정 / 보완)
     * user_id: 유저 id
     */
    ReactGA.event('서류 작성', {
      value: elapsedTime,
      category: '인허가',
      document_name: documentName,
      page_view_time: now,
      page_action_end_time: date.format('YYYY-MM-DD HH:mm:ss'),
      page_action_type: actionType,
      user_id: auth?.user?.userId,
    });
  };

  useEffect(() => {
    setStartTime(performance.now());
  }, []);

  return { sendEventToGA };
};

export default useGA;

import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { BrandEstimateHistory } from 'types/brand/estimate';
import { MaterialIcidEstimateHistory, MaterialNmpaEstimateHistory } from 'types/material/estimate';
import { EstimateHistoryDomainType, EstimateHistory } from 'types/estimate';
import { useBasicQuery } from './core';
import * as estimateApi from 'lib/api/estimate';
import { setSelectedEstimateTargetIds } from 'modules/estimate';
import path from 'lib/path';

const isBrandEstimateHistories = (
  estimates: BrandEstimateHistory[] | MaterialIcidEstimateHistory[] | MaterialNmpaEstimateHistory[],
): estimates is BrandEstimateHistory[] =>
  estimates.length > 0 && !('materialIcidApplicationEstimateHistoryId' in estimates[0]);

const isNmpaEstimateHistories = (
  estimates: BrandEstimateHistory[] | MaterialIcidEstimateHistory[] | MaterialNmpaEstimateHistory[],
): estimates is MaterialNmpaEstimateHistory[] =>
  estimates.length > 0 && !('materialNmpaEstimateHistoryId' in estimates[0]);

export const useEstimateHistories = ({
  estimateId,
  type,
}: {
  estimateId: number;
  type: EstimateHistoryDomainType;
}) => {
  const getURI = (type: EstimateHistoryDomainType, estimateId: number) => {
    switch (type) {
      case 'brand':
        return `/estimate/history/${estimateId}`;
      case 'materialIcid':
        return `/material/icid-application/estimates/${estimateId}/history`;
      case 'materialNmpa':
        return `/material/nmpa/estimates/${estimateId}/histories`;
      default:
        return `/estimate/history/${estimateId}`;
    }
  };
  const { data = [], isFetching } = useQuery(
    ['estimate/history', type, estimateId],
    () =>
      client.get<
        APIResponse<
          BrandEstimateHistory[] | MaterialIcidEstimateHistory[] | MaterialNmpaEstimateHistory[]
        >
      >(getURI(type, estimateId)),
    {
      select: (res) => res.data.result,
    },
  );

  // HINT: API 응답 필드 값은 같으나 필드 이름이 다른 관계로 이름을 통일시켜줌
  const estimateHistories: EstimateHistory[] = useMemo(() => {
    if (isBrandEstimateHistories(data)) {
      return data.map((history, index) => ({
        ...history,
        no: data.length - index,
      }));
    } else if (!isNmpaEstimateHistories(data)) {
      return data.map(
        (
          {
            materialIcidApplicationEstimateHistoryId,
            materialIcidApplicationEstimateId,
            estimateCode,
            estimatePrice,
            fileUrl,
            registerDt,
          },
          index,
        ) => ({
          estimateHistoryId: materialIcidApplicationEstimateHistoryId,
          estimateId: materialIcidApplicationEstimateId,
          estimateCode,
          estimatePrice,
          registerDt,
          fileUrl,
          no: data.length - index,
        }),
      );
    } else {
      return data.map(
        (
          {
            materialNmpaEstimateHistoryId,
            materialNmpaEstimateId,
            estimateCode,
            estimatePrice,
            fileUrl,
            registerDt,
          },
          index,
        ) => ({
          estimateHistoryId: materialNmpaEstimateHistoryId,
          estimateId: materialNmpaEstimateId,
          estimateCode,
          estimatePrice,
          registerDt,
          fileUrl,
          no: data.length - index,
        }),
      );
    }
  }, [data]);

  return {
    estimateHistories,
    isFetching,
  };
};

export const useContractDocuments = () => {
  const { data: contractDocuments = [], isLoading: getContractDocumentsLoading } = useBasicQuery<
    {
      standardDocumentId: number;
      fileKey: string;
      attachUrl: string;
      filename: string;
    }[]
  >({
    apiUrl: '/documents/contract',
    option: {
      staleTime: Number.MAX_VALUE,
    },
  });

  return {
    contractDocuments,
    getContractDocumentsLoading,
  };
};
//견적서 발행
export const usePublishEstimate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate: publishEstimate, isLoading: publishEstimateLoading } = useMutation(
    estimateApi.publishEstimate,
    {
      onSuccess: () => {
        dispatch(setSelectedEstimateTargetIds([]));
        navigate(path.estimate.contract);
      },
    },
  );

  return {
    publishEstimate,
    publishEstimateLoading,
  };
};

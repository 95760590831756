import { Button, message } from 'antd';
import { useDispatch } from 'react-redux';

import { useModal } from 'hook/useModal';
import * as certificateActions from 'modules/certificate';
import { useProductCorrectWithoutChange } from 'service/brand/certificate/certificate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { DocumentCode } from 'types/product';
import { useNavigate } from 'react-router-dom';

const CorrectRequestWithoutChangeButton = ({
  productId: productIdProp,
  countryId: countryIdProp,
  certTargetId,
  documentCode,
  onSubmit,
}: {
  productId?: number;
  countryId?: number;
  certTargetId?: number;
  documentCode: DocumentCode;
  onSubmit?: VoidFunction;
}) => {
  const navigate = useNavigate();
  const { openAlertModal } = useModal();
  const dispatch = useDispatch();
  const currentProduct = useCurrentProduct();
  const countryIdParam = useCountryId();
  const productId = productIdProp || currentProduct.productId;
  const countryId = countryIdProp || countryIdParam;

  const { productCorrectRequestWithoutChange, productCorrectRequestWithoutChangeLoading } =
    useProductCorrectWithoutChange({
      productId,
    });

  const handleNoUpdateSubmit = () => {
    openAlertModal({
      content: `
  '보완할 내용 없음' 버튼을 클릭하시면, 관리자의 보완 요청이 있기 전까지는 수정이 불가합니다.

  보완할 내용 없이 처리 하시겠습니까?
          `,
      onOk: () => {
        if (countryId) {
          productCorrectRequestWithoutChange(
            { countryId, documentCode: `${documentCode.toUpperCase()}01` },
            {
              onSuccess: () => {
                message.success('보완 내용 없이 완료 처리되었습니다.');

                if (onSubmit) {
                  onSubmit();
                  if (certTargetId) {
                    dispatch(certificateActions.getDocuments(certTargetId));
                  }
                } else {
                  navigate(-1);
                }
              },
            },
          );
        }
      },
    });
  };

  return (
    <Button loading={productCorrectRequestWithoutChangeLoading} onClick={handleNoUpdateSubmit}>
      보완할 내용 없음
    </Button>
  );
};

export default CorrectRequestWithoutChangeButton;

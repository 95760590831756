import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';
import { useMemo } from 'react';

import { DocumentCode, Document } from 'types/brand/retailer/retailerApply';
import * as retailerApplyApi from 'lib/api/brand/retailer/retailerApply';
import { messages } from 'lib/consts';

export const useDocuments = ({
  productId,
  documentCode,
}: {
  productId?: number;
  documentCode: DocumentCode;
}) => {
  const { data: documents = [], isLoading: getLoading } = useQuery(
    ['retailer/retailerApply/getDocuments', productId, documentCode],
    () => retailerApplyApi.getDocuments({ productId, documentCode }),
    { select: (res) => res.data.result },
  );

  const { mutate: uploadDocuments, isLoading: uploadLoading } = useMutation(
    (files: (File | Document)[]) => {
      if (!productId) {
        // HINT : 공통 서류
        // HINT : 새로운 파일이 업로드 되지 않았을 경우
        if (files.every((file) => !(file instanceof File))) {
          throw Error(messages.NO_NEED_TO_UPDATE);
        }
        // HINT : 전부 새로운 파일일 경우
        if (files.every((file) => file instanceof File)) {
          return retailerApplyApi.uploadCommonDocuments(documentCode, files as File[]);
        }
        // HINT : 하나의 파일만 변경된 경우
        const index = files.findIndex((file) => file instanceof File);
        return retailerApplyApi.uploadCommonDocuments(
          documentCode,
          [files[index]] as File[],
          index === 0 ? 'F' : 'B',
        );
      }

      // HINT : 제품별 개별 서류
      const newFiles = files.filter((file) => file instanceof File) as File[];
      const remainIds = files.reduce((acc, file) => {
        if (!(file instanceof File)) {
          acc.push(file.retailerApplyDocStatusAttachId);
        }
        return acc;
      }, [] as number[]);

      const deleteFileIds = documents
        .filter(
          ({ retailerApplyDocStatusAttachId }) =>
            !remainIds.includes(retailerApplyDocStatusAttachId),
        )
        .map(({ retailerApplyDocStatusAttachId }) => retailerApplyDocStatusAttachId);
      if (newFiles.length === 0 && deleteFileIds.length === 0) {
        throw Error(messages.NO_NEED_TO_UPDATE);
      }
      return retailerApplyApi.uploadProductDocuments(productId, documentCode, {
        files: newFiles,
        deleteFileIds,
      });
    },
    {
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warning(errorMessage);
      },
    },
  );

  return useMemo(
    () => ({ documents, getLoading, uploadDocuments, uploadLoading }),
    [documents, getLoading, uploadDocuments, uploadLoading],
  );
};

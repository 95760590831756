import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import path from 'lib/path';
import { manuSetNextPathname, setNextPathname } from 'modules/product';
import { CompanyType } from 'types/company';

export const useMovePathname = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { manuMode, nextPathname, skipMode } = useSelector(
    ({ company, product }: any) => ({
      manuMode: company.company.companyType === CompanyType.MANUFACTURE,
      nextPathname: product.nextPathname,
      skipMode: product.skipMode,
      productId: product.currentProduct?.productId,
    }),
    shallowEqual,
  );

  const movePathname = ({ productId }: { productId: number }) => {
    if (skipMode) {
      if (nextPathname === 'finish') {
        if (!manuMode) {
          navigate(`/product/${productId}`, { replace: true });
        } else {
          navigate(`/manufacturer/product/${productId}`, { replace: true });
        }
      } else {
        navigate(`/product/${productId}/${nextPathname}`, {
          replace: true,
          state:
            nextPathname === 'phy' && manuMode
              ? { referer: path.manufacturer.product.basic }
              : null,
        });

        if (!manuMode) {
          dispatch(setNextPathname(nextPathname));
        } else {
          dispatch(manuSetNextPathname(nextPathname));
        }
      }
    } else {
      navigate(-1);
      message.success('입력되었습니다.');
    }
  };

  return {
    movePathname,
  };
};

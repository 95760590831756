import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import PageTemplate from 'templates/PageTemplate';
import { DocumentCode } from 'types/brand/retailer/retailerApply';

const COBPage = () => {
  return (
    <PageTemplate
      title="국내 입점 서류 관리"
      subtitle="통장 사본"
      exampleImg="COB.png"
      notices={[
        '국내 유통사에 납품한 상품에 대한 대금을 받을 계좌의 통장 사본을 PDF 파일로 업로드해 주세요.',
        '해당 서류는 모든 유통사에 기본적으로 포함되는 서류로, 한 번만 업로드하시면 모든 유통사별 서류 다운로드 시 함께 받아보실 수 있습니다. ',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload documentCode={DocumentCode.COB} />
    </PageTemplate>
  );
};

export default COBPage;

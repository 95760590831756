import { useCallback, useEffect, useRef } from 'react';

const useIntersectionObserver = <V extends HTMLElement = HTMLDivElement>({
  onIntersect,
  threshold = 0.5,
  repeat = true,
}: {
  onIntersect: (isIntersect: boolean) => void;
  threshold?: number;
  repeat?: boolean;
}) => {
  const target = useRef<V>(null);

  const handleIntersect: IntersectionObserverCallback = useCallback(
    ([entry], observer) => {
      onIntersect(entry.isIntersecting);
      if (!repeat && entry.isIntersecting) {
        observer.disconnect();
      }
      return () => {
        observer.disconnect();
      };
    },
    [onIntersect, repeat],
  );

  useEffect(() => {
    if (target.current) {
      const observer = new IntersectionObserver(handleIntersect, {
        threshold,
      });
      observer.observe(target.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [handleIntersect, threshold]);

  return target;
};

export default useIntersectionObserver;

import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { pdfjs } from 'react-pdf';
import { ConfigProvider } from 'antd';
import koKR from 'antd/es/locale/ko_KR';
import moment from 'moment';
import 'moment/locale/ko';
import { createRoot } from 'react-dom/client';

import rootReducer, { rootSaga } from 'modules';
import 'index.less';
import history from 'lib/history';
import GlobalStyle from 'lib/styles/GlobalStyle';
import { ModalContextProvider } from 'hook/useModal';
import App from 'App';
import { theme } from 'lib/styles/theme';

moment.locale('ko');

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});
const sagaMiddleware = createSagaMiddleware({
  context: {
    history,
    queryClient,
  },
});
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);
const container = document.getElementById('root') as HTMLDivElement;
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ModalContextProvider>
        <ConfigProvider locale={koKR} theme={theme}>
          <GlobalStyle />
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </ConfigProvider>
      </ModalContextProvider>
    </QueryClientProvider>
  </Provider>,
);

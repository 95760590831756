import QCQASelfInspect from 'components/brand/qcqa/QCQASelfInspect';
import styled from 'styled-components';

const QCQASelfInspectPage = () => {
  return (
    <Container>
      <QCQASelfInspect />
    </Container>
  );
};

const Container = styled.div`
  max-width: none !important;
  margin: -80px auto !important;
`;

export default QCQASelfInspectPage;

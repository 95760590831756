import styled from 'styled-components';
import { Radio, RadioChangeEvent } from 'antd';

import palette from 'lib/styles/palette';
import Flex from '../Flex/Flex';

const Container = styled(Flex)`
  padding: 16px 24px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;

  .ant-col:first-child {
    margin-right: 16px;
  }

  .ant-radio-wrapper {
    line-height: 24px;
  }

  .ant-radio-group.ant-radio-group-outline {
    width: 100%;
  }

  .ant-radio-wrapper.ant-radio-wrapper-disabled {
    cursor: not-allowed;
  }
`;

const BackgroundRadio = ({
  value,
  onChange,
  options,
  disabled = false,
  itemWidth = 0,
  gap = 80,
  justify = 'center',
  bodyStyle,
}: {
  value?: boolean;
  onChange?: (e: RadioChangeEvent) => void;
  options: { title: string; value: boolean | string }[];
  disabled?: boolean;
  bodyStyle?: React.CSSProperties;
  itemWidth?: number;
  gap?: number;
  justify?: React.CSSProperties['justifyContent'];
}) => {
  return (
    <Container justify={justify} style={bodyStyle}>
      <Radio.Group value={value} onChange={onChange} disabled={disabled}>
        <Flex justify={justify} style={{ width: '100%' }} gap={gap}>
          {options.map(({ title, value }) => (
            <Flex key={title} style={{ flex: itemWidth ? `0 0 ${itemWidth}px` : '0 0 auto' }}>
              <Radio value={value}>{title}</Radio>
            </Flex>
          ))}
        </Flex>
      </Radio.Group>
    </Container>
  );
};

export default BackgroundRadio;

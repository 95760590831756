import { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Form, Input, message, Row, Checkbox, FormInstance } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { RegisterInquiryParams } from 'types/common';
import { useModal } from 'hook/useModal';
import { useRegisterInquiry } from 'service/common';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';
import InquiryForm from 'components/common/InquiryForm';
import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';

export interface InquiryFormProps {
  form?: FormInstance<any>;
  isAgreePrivacyTerms?: boolean;
  handleSubmit?: (values: any) => void;
  handleAgreePrivacyTermsClick?: MouseEventHandler;
  submitting?: boolean;
}

const Container = styled.div`
  max-width: 520px;
  margin: 0 auto;

  textarea {
    resize: none;
  }
`;

const InquiryFormUI = ({
  form,
  isAgreePrivacyTerms,
  handleSubmit,
  handleAgreePrivacyTermsClick,
  submitting,
}: InquiryFormProps) => {
  return (
    <Container>
      <Form
        form={form}
        colon={false}
        layout="vertical"
        style={{ marginTop: 4 }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="회사(소속)명"
          name="companyName"
          rules={generateFormRules({
            required: true,
            maxLength: 50,
          })}
        >
          <Input />
        </Form.Item>
        <Row gutter={8} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="담당자 이름"
              name="managerName"
              rules={generateFormRules({
                required: true,
                exceptNumber: true,
                maxLength: 30,
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              label="휴대폰 번호"
              name="managerPhone"
              rules={generateFormRules({
                minLength: 10,
                maxLength: 12,
                onlyNumber: true,
              })}
            >
              <Input placeholder="숫자만 입력 가능" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="이메일 주소"
          name="managerEmail"
          rules={generateFormRules({
            required: true,
            maxLength: 100,
            email: true,
          })}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="내용"
          name="inquiryContent"
          required
          rules={generateFormRules({
            required: true,
            maxLength: 2000,
          })}
        >
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item
          name="isAgreePrivacyTerms"
          rules={[
            {
              validator: () => (isAgreePrivacyTerms ? Promise.resolve() : Promise.reject('필수')),
            },
          ]}
        >
          <Checkbox
            checked={isAgreePrivacyTerms}
            onClick={handleAgreePrivacyTermsClick}
            style={{
              width: '100%',
              backgroundColor: palette.SLATE_GRAY10,
              borderRadius: 8,
              padding: 16,
            }}
          >
            <Typography.Text color="SLATE_GRAY70" inline medium>
              개인 정보 수집 및 이용 동의
            </Typography.Text>
          </Checkbox>
        </Form.Item>
        <FooterBox>
          <Button type="primary" htmlType="submit" loading={submitting}>
            문의하기
          </Button>
        </FooterBox>
      </Form>
    </Container>
  );
};

export const AdInquiryForm = () => {
  const { openPrivacyModal } = useModal();
  const [isAgreePrivacyTerms, setIsAgreePrivacyTerms] = useState(false);
  const [form] = Form.useForm<RegisterInquiryParams>();
  const { registerInquiry, isLoading } = useRegisterInquiry({ type: 'ad' });

  const handleSubmit = (values: RegisterInquiryParams) => {
    registerInquiry(
      {
        ...values,
        isAgreePrivacyTerms,
      },
      {
        onSuccess: () => {
          form.resetFields();
          setIsAgreePrivacyTerms(false);
          message.success('등록되었습니다.');
        },
      },
    );
  };

  const handleAgreePrivacyTermsClick = () => {
    openPrivacyModal({
      type: 'adInquiry',
      onAgree: () => {
        setIsAgreePrivacyTerms(true);
        form.validateFields(['isAgreePrivacyTerms']);
      },
      onCancel: () => {
        setIsAgreePrivacyTerms(false);
        form.validateFields(['isAgreePrivacyTerms']);
      },
    });
  };

  return (
    <InquiryForm
      form={form}
      isAgreePrivacyTerms={isAgreePrivacyTerms}
      handleSubmit={handleSubmit}
      handleAgreePrivacyTermsClick={handleAgreePrivacyTermsClick}
      submitting={isLoading}
    />
  );
};

export const UserInquiryForm = () => {
  const { openPrivacyModal } = useModal();
  const [isAgreePrivacyTerms, setIsAgreePrivacyTerms] = useState(false);
  const [form] = Form.useForm<RegisterInquiryParams>();
  const { registerInquiry, isLoading } = useRegisterInquiry({
    type: 'user',
  });

  const handleSubmit = (values: RegisterInquiryParams) => {
    registerInquiry(
      {
        ...values,
        isAgreePrivacyTerms,
      },
      {
        onSuccess: () => {
          form.resetFields();
          setIsAgreePrivacyTerms(false);
          message.success('등록되었습니다.');
        },
      },
    );
  };

  const handleAgreePrivacyTermsClick = () => {
    openPrivacyModal({
      type: 'adInquiry',
      onAgree: () => {
        setIsAgreePrivacyTerms(true);
        form.validateFields(['isAgreePrivacyTerms']);
      },
      onCancel: () => {
        setIsAgreePrivacyTerms(false);
        form.validateFields(['isAgreePrivacyTerms']);
      },
    });
  };

  return (
    <InquiryForm
      form={form}
      isAgreePrivacyTerms={isAgreePrivacyTerms}
      handleSubmit={handleSubmit}
      handleAgreePrivacyTermsClick={handleAgreePrivacyTermsClick}
      submitting={isLoading}
    />
  );
};

export default InquiryFormUI;

export enum ScreeningStatus {
  INPUT_INGREDIENT = 'INPUT_INGREDIENT', // 성분표 입력 완료
  INPUT_ARTWORK = 'INPUT_ARTWORK', // 입력 완료
  ANALYZE = 'ANALYZE', // 전성분 스크리닝 분석 완료
  COMPLETE = 'COMPLETE', // 전성분 스크리닝 완료
}

export enum InquiryErrorType {
  OCR = 'OCR', // OCR 문자 인식 오류
  MERGER = 'MERGER', // 전성분 병합 오류
  CLASSIFICATION = 'CLASSIFICATION', // 텍스트 분류 오류
  OTHER = 'OTHER', // 기타
}

export enum RuleType {
  TYPO = 'TYPO',
  MISS = 'MISS',
  NOT_MATCH = 'NOT_MATCH',
  ORDER = 'ORDER',
}

export enum RuleCode {
  FCAR01 = 'FCAR01', // 중복 표기 불가
  FCAR02 = 'FCAR02', // 올바른 성분명으로 표기
  FCAR03 = 'FCAR03', // ARTWORK 전성분에 존재하지 않음
  FCAR04 = 'FCAR04', // 전성분표에 존재하지 않음
  FCAR05 = 'FCAR05', // 색소 성분 표기법 확인
  FCAR06 = 'FCAR06', // 국가 표기법으로 사용
  FCAR07 = 'FCAR07', // 함량 순으로 표기
}

export enum RuleSubCode {
  FCAR01_01 = 'FCAR01_01', // 중복 표기 불가
  FCAR02_01 = 'FCAR02_01', // 올바른 성분명으로 표기
  FCAR03_01 = 'FCAR03_01', // ARTWORK 전성분에 존재하지 않음
  FCAR04_01 = 'FCAR04_01', // 전성분표에 존재하지 않음
  FCAR05_01 = 'FCAR05_01', // 일반 명칭이 아닌 CI 표기법으로 사용
  FCAR05_02 = 'FCAR05_02', // CI 표기법이 아닌 일반 명칭으로 사용
  FCAR06_01 = 'FCAR06_01', // 국가 표기법으로 사용
  FCAR06_02 = 'FCAR06_02', // 국가 표기법으로 사용
  FCAR07_01 = 'FCAR07_01', // 함량이 많은 순으로 기재, 1% 미만 부터는 순서에 관계 없음
  FCAR07_02 = 'FCAR07_02', // 함량이 많은 순으로 기재, 1% 이하 부터는 순서에 관계 없음
}

export interface FormulaArtworkScreeningJson {
  formulaScreeningId?: number;
  ingredients: {
    name: string;
    wt: string;
    casNo: string;
  }[];
}

export interface FormulaArtworkScreeningItem {
  list: FormulaArtworkScreeningItemList[];
  isRowError?: boolean;
  formulaScreeningId: number;
}

export interface FormulaArtworkScreeningItemList {
  ingredientId: number;
  ingredientName: string;
  wt: string;
  casNo: string;
  isNameValid: boolean;
  isWtValid: boolean;
}

export interface ArtworkScreeningResult {
  availableCountryCodes: string[];
  artworkImage: ArtworkImage;
  rules: Rule[];
  analyzeResult: AnalyzeResultListItem[];
}

export interface ArtworkImage {
  formulaScreeningArtworkImageId: number;
  url: string;
  filename: string;
  pointX1: number;
  pointY1: number;
  pointX2: number;
  pointY2: number;
}

export interface Rule {
  ruleType: RuleType;
  ruleCode: RuleCode;
  countryRules: CountryRule[] | null;
}

export interface CountryRule {
  countryCode: string;
  ruleSubCode: RuleSubCode;
  ruleDescription: string;
}

export interface AnalyzeResultListItem {
  formulaScreeningAnalyzeItemId: number;
  ingredient: Ingredient | null; // 전성분표 성분 정보
  imageIngredient: ImageIngredient | null; // 전성분 이미지의 성분 리스트
  analysisItems: Record<RuleType, AnalysisItem[]> | null;
}

export interface Ingredient {
  formulaScreeningIngredientId: number;
  formulaScreeningId: number;
  ingredientId: number | null;
  ingredientName: string;
  formulaWt: string;
  casNo: string | null;
}

export interface ImageIngredient {
  formulaScreeningArtworkImageIngredientId: number;
  ingredientId: number | null;
  ingredientName: string;
  additionalIngredientName: string | null;
  additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  coordinates?: Coordinate[];
}

export interface Coordinate {
  formulaScreeningArtworkImageIngredientCoordinateId: number;
  points: Point[];
}

export interface Point {
  formulaScreeningArtworkImageIngredientCoordinatePointId: number;
  pointX: number;
  pointY: number;
}

export interface AnalysisItem {
  ruleCode: RuleCode;
  countryRules: CountryRules;
  preIngredient: ImageIngredient[] | ImageIngredient | null;
  pre?: ImageIngredient | null;
  post?: {
    formulaScreeningArtworkImageIngredientId: number;
    ingredientId: number | null;
    ingredientName: string;
    additionalIngredientName: string | null;
    additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  } | null;
  postIngredient: PostIngredientListItem[] | PostIngredient | null;
}

export interface CountryRules {
  [key: string]: {
    formulaScreeningAnalyzeRuleSubId: number;
    description: string;
    subCode: RuleSubCode;
  }[];
}

export interface PostIngredientListItem {
  ruleSubCode: RuleSubCode[];
  updateIngredient: UpdateIngredient[];
  description: string;
}

export interface UpdateIngredient {
  formulaScreeningArtworkImageIngredientId: number | null;
  formulaScreeningArtworkIngredientId: number | null;
  ingredientName: string;
  additionalIngredientName?: string;
  additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
}

export interface PostIngredient {
  ruleSubCode: RuleSubCode[];
  description: string;
  pre: ImageIngredient | null;
  post: {
    formulaScreeningArtworkImageIngredientId: number;
    ingredientId: number | null;
    ingredientName: string;
    additionalIngredientName: string | null;
    additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  } | null;
  updateIngredient: UpdateIngredient[] | null;
}

export interface IngredientCoordinate {
  formulaScreeningArtworkImageIngredientCoordinateId: number;
  points: {
    formulaScreeningArtworkImageIngredientCoordinatePointId: number;
    pointX: number;
    pointY: number;
  }[];
}

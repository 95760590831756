import { useLocation, useNavigate } from 'react-router-dom';

import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import ICIDRegistered from 'components/material/icid/ICIDRegistered';
import { ICIDRegisteredSearchType, ICIDRegisteredPageState } from 'types/material/icid/icid';

const ICIDRegisteredPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const ICIDRegisteredPageState: ICIDRegisteredPageState = location.state || {
    page: 1,
    searchType: ICIDRegisteredSearchType.ALL,
    searchValue: '',
  };

  const handleChangePageState = (state: ICIDRegisteredPageState) => {
    navigate(path.material.icid.registered, { state });
  };

  return (
    <PageTemplate title="등재 완료 원료 관리" back={false}>
      <ICIDRegistered
        initialPageState={ICIDRegisteredPageState}
        onChangePageState={handleChangePageState}
      />
    </PageTemplate>
  );
};

export default ICIDRegisteredPage;

import { useParams, useRoutes } from 'react-router-dom';

import path from 'lib/path';
import ProductReadyPage from 'pages/product/ProductReadyPage';
import ProductBasicPage from 'pages/product/ProductBasicPage';
import ProductCountryPage from 'pages/product/ProductCountryPage';
import ProductCategoryPage from 'pages/product/ProductCategoryPage';
import ProductArtworkPage from 'pages/product/ProductArtworkPage';
import ProductCGMPPage from 'pages/product/ProductCGMPPage';
import ProductStabilityTestPage from 'pages/product/ProductStabilityTestPage';
import ProductChallengeTestPage from 'pages/product/ProductChallengeTestPage';
import ProductPackingAttestationPage from 'pages/product/ProductPackingAttestationPage';
import ProductManufacturingProcessChinaPage from 'pages/product/ProductManufacturingProcessChinaPage';
import ProductFormulaBreakdownPage from 'pages/product/ProductFormulaBreakdownPage';
import ProductFormulaBreakdownChinaPage from 'pages/product/ProductFormulaBreakdownChinaPage';
import ProductIFRAPage from 'pages/product/ProductIFRAPage';
import ProductAllergensListPage from 'pages/product/ProductAllergensListPage';
import ProductThirdPartyTestReportPage from 'pages/product/ProductThirdPartyTestReportPage';
import ProductRawMaterialCoAPage from 'pages/product/ProductRawMaterialCoAPage';
import ProductRawMaterialMSDSPage from 'pages/product/ProductRawMaterialMSDSPage';
import ProductDetailPage from 'pages/product/ProductDetailPage';
import Error404Page from 'pages/error/Error404Page';
import ProductCFSPage from 'pages/product/ProductCFSPage';
import ProductChinaCertificateTrademarkPage from 'pages/product/ProductChinaCertificateTrademarkPage';
import ProductInternalResponsibleCompanyPage from 'pages/product/ProductInternalResponsibleCompanyPage';
import ProductLogoPage from 'pages/product/ProductLogoPage';
import ProductCOBRMPage from 'pages/product/ProductCOBRMPage';
import ProductROPQMPage from 'pages/product/ProductROPQMPage';
import { useManuMode } from 'hook/company';
import PrivateRoute from 'components/PrivateRoute';
import { messages } from 'lib/consts';
import ProductBuyersPage from 'pages/product/ProductBuyersPage';
import ProductSpecPage from 'pages/product/ProductSpecPage';
import ProductCountryVendorPage from 'pages/product/ProductCountryVendorPage';
import ProductKeepWarningPage from 'pages/product/ProductKeepWarningPage';
import ProductSeparateWastePage from 'pages/product/ProductSeparateWastePage';
import {
  useCountryId,
  useSetCurrentProduct,
  useSetCurrentProductDocStatus,
} from 'service/brand/product/product';
import { DocumentCode } from 'types/product';
import ProductSaleInfoPage from 'pages/product/ProductSaleInfoPage';
import ProductFDACertificatedColorPage from 'pages/product/ProductFDACertificatedColorPage';
import ProductVCRPAccountPage from 'pages/product/ProductVCRPAccountPage';
import ProductVCRPAccountRequestPage from 'pages/product/ProductVCRPAccountRequestPage';
import ProductManufacturerDeclarationPage from 'pages/product/ProductManufacturerDeclarationPage';
import ProductPeriodOnMarketsPage from 'pages/product/ProductPeriodOnMarketsPage';
import ProductStatementMicrobiologicalPurityPage from 'pages/product/ProductStatementMicrobiologicalPurityPage';
import ProductImagesPage from 'pages/product/ProductImagesPage';
import ProductInformationChinaPage from 'pages/product/ProductInformationChinaPage';
import ProductAgencyPage from 'pages/product/ProductAgencyPage';
import ProductHeavyMetalsAndMicrobiologicalStandardPage from 'pages/product/ProductHeavyMetalsAndMicrobiologicalStandardPage';
import ProductEfficacyPage from 'pages/product/ProductEfficacyPage';
import ProductPMAPage from 'pages/product/ProductPMAPage';
import ProductPQSPage from 'pages/product/ProductPQSPage';
import ProductPCPAPage from 'pages/product/ProductPCPAPage';
import ProductEfficacyEvaluationPage from 'pages/product/ProductEfficacyEvaluationPage';
import ProductArtworkAIScreeningPage from 'pages/product/ProductArtworkAIScreeningPage';
import ProductEtcDataPage from 'pages/product/ProductEtcDataPage';
import ProductAnnex14Page from 'pages/product/ProductAnnex14Page';
import ProductEtcFilePage from 'pages/product/ProductETCFilePage';
import ProductManufacturerInfoPage from 'pages/product/ProductManufacturerInfoPage';
import ProductRPPage from 'pages/product/ProductRPPage';
import ProductFDAPage from 'pages/product/ProductFDAPage';
import ProductImportPage from 'pages/product/ProductImportPage';
import ProductRegisteredPage from 'pages/product/ProductRegisteredPage';
import ProductPhysicalPropertyPage from 'pages/product/ProductPhysicalPropertyPage';

const ProductRouter = () => {
  const manuMode = useManuMode();
  const params = useParams();
  const productId = Number(params['*']?.split('/')[1]) || undefined;
  const documentCode = params['*']?.split('/')[2] as DocumentCode;

  const countryId = useCountryId();

  useSetCurrentProduct({ productId });
  useSetCurrentProductDocStatus({ productId, countryId, documentCode });

  const element = useRoutes([
    {
      element: (
        <PrivateRoute
          // HINT : 기존에 사용하던 key={nanoid()} 를 삭제. 혹시 nanoid가 필요해 롤백이 필요할 수 있어 표기를 위해 임시 key 기입
          check={!manuMode}
          redirect={`${path.main}`}
          key={'ProductReadyPage'}
          message={messages.NO_PERMISSION}
        />
      ),
      children: [
        {
          path: path.product.ready,
          element: <ProductReadyPage />,
        },
      ],
    },
    {
      element: (
        <PrivateRoute
          // HINT : 기존에 사용하던 key={nanoid()} 를 삭제. 혹시 nanoid가 필요해 롤백이 필요할 수 있어 표기를 위해 임시 key 기입
          check={!manuMode}
          redirect={`${path.main}`}
          key={'ProductRegisteredPage'}
          message={messages.NO_PERMISSION}
        />
      ),
      children: [
        {
          path: path.product.registered,
          element: <ProductRegisteredPage />,
        },
      ],
    },
    {
      element: (
        <PrivateRoute
          // HINT : 기존에 사용하던 key={nanoid()} 를 삭제. 혹시 nanoid가 필요해 롤백이 필요할 수 있어 표기를 위해 임시 key 기입
          check={!manuMode}
          redirect={`${path.main}`}
          message={messages.NO_PERMISSION}
        />
      ),
      children: [
        {
          path: path.product.import,
          element: <ProductImportPage />,
        },
      ],
    },
    {
      path: `${path.product.root}/*`,
      element: <ProductBasicPage />,
      children: [
        {
          path: `:productId/${DocumentCode.BASIC}`,
          element: <ProductBasicPage />,
        },
        {
          path: `${DocumentCode.BASIC}`,
          element: <ProductBasicPage />,
        },
      ],
    },
    {
      path: `product/:productId/${DocumentCode.COUNTRY}`,
      element: <ProductCountryPage />,
    },
    {
      path: `product/:productId/${DocumentCode.CAT}`,
      element: <ProductCategoryPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PHY}`,
      element: <ProductPhysicalPropertyPage />,
    },
    {
      path: `product/:productId/${DocumentCode.HMMS}`,
      element: <ProductHeavyMetalsAndMicrobiologicalStandardPage />,
    },
    {
      path: `product/:productId/${DocumentCode.FORMBR}`,
      element: <ProductFormulaBreakdownPage />,
    },
    {
      path: `product/:productId/${DocumentCode.FORMBRC}`,
      element: <ProductFormulaBreakdownChinaPage />,
    },
    {
      path: `product/:productId/${DocumentCode.ANE}`,
      element: <ProductAnnex14Page />,
    },
    {
      path: `product/:productId/${DocumentCode.ART}`,
      element: <ProductArtworkPage />,
    },
    {
      path: `product-art-screening/:productId`,
      element: <ProductArtworkAIScreeningPage />,
    },
    {
      path: `product/:productId/${DocumentCode.CGMP}`,
      element: <ProductCGMPPage />,
    },
    {
      path: `product/:productId/${DocumentCode.STAB}`,
      element: <ProductStabilityTestPage />,
    },
    {
      path: `product/:productId/${DocumentCode.CHAL}`,
      element: <ProductChallengeTestPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PACK}`,
      element: <ProductPackingAttestationPage />,
    },
    {
      path: `product/:productId/${DocumentCode.ALLE}`,
      element: <ProductAllergensListPage />,
    },
    {
      path: `product/:productId/${DocumentCode.IFRA}`,
      element: <ProductIFRAPage />,
    },
    {
      path: `product/:productId/${DocumentCode.RMCOA}`,
      element: <ProductRawMaterialCoAPage />,
    },
    {
      path: `product/:productId/${DocumentCode.RMMSDS}`,
      element: <ProductRawMaterialMSDSPage />,
    },
    {
      path: `product/:productId/${DocumentCode.CLOD}`,
      element: <ProductEtcDataPage />,
    },
    // 인증 단계에서 받는 추가 서류
    {
      path: `product/:productId/${DocumentCode.MANUC}`,
      element: <ProductManufacturingProcessChinaPage />,
    },
    {
      path: `product/:productId/${DocumentCode.AI}`,
      element: <ProductAgencyPage />,
    },
    {
      path: `product/:productId/${DocumentCode.BI}`,
      element: <ProductBuyersPage />,
    },
    {
      path: `product/:productId/${DocumentCode.RP}`,
      element: <ProductRPPage />,
    },
    {
      path: `product/:productId/${DocumentCode.FDAAR}`,
      element: <ProductFDAPage />,
    },
    {
      path: `product/:productId/${DocumentCode.THIRD}`,
      element: <ProductThirdPartyTestReportPage />,
    },
    {
      path: `product/:productId/${DocumentCode.CFS}`,
      element: <ProductCFSPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PCC}`,
      element: <ProductChinaCertificateTrademarkPage />,
    },
    {
      path: `product/:productId/${DocumentCode.IRC}`,
      element: <ProductInternalResponsibleCompanyPage />,
    },
    {
      path: `product/:productId/${DocumentCode.LOGOAI}`,
      element: <ProductLogoPage />,
    },
    {
      path: `product/:productId/${DocumentCode.COBRM}`,
      element: <ProductCOBRMPage />,
    },
    {
      path: `product/:productId/${DocumentCode.ROPQM}`,
      element: <ProductROPQMPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PS}`,
      element: <ProductSpecPage />,
    },
    {
      path: `product/:productId/${DocumentCode.CV}`,
      element: <ProductCountryVendorPage />,
    },
    {
      path: `product/:productId/${DocumentCode.KW}`,
      element: <ProductKeepWarningPage />,
    },
    {
      path: `product/:productId/${DocumentCode.SWI}`,
      element: <ProductSeparateWastePage />,
    },
    {
      path: `product/:productId/${DocumentCode.UPSI}`,
      element: <ProductSaleInfoPage />,
    },
    {
      path: `product/:productId/${DocumentCode.FCCAD}`,
      element: <ProductFDACertificatedColorPage />,
    },
    {
      path: `product/:productId/${DocumentCode.VCRPAR}`,
      element: <ProductVCRPAccountPage />,
    },
    {
      path: `product/:productId/${DocumentCode.VCRPAC}`,
      element: <ProductVCRPAccountRequestPage />,
    },
    {
      path: `product/:productId/${DocumentCode.MD}`,
      element: <ProductManufacturerDeclarationPage />,
    },
    {
      path: `product/:productId/${DocumentCode.SMP}`,
      element: <ProductStatementMicrobiologicalPurityPage />,
    },
    {
      path: `product/:productId/${DocumentCode.POM}`,
      element: <ProductPeriodOnMarketsPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PI}`,
      element: <ProductImagesPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PEI}`,
      element: <ProductEfficacyPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PMA}`,
      element: <ProductPMAPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PIF}`,
      element: <ProductInformationChinaPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PQS}`,
      element: <ProductPQSPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PCPA}`,
      element: <ProductPCPAPage />,
    },
    {
      path: `product/:productId/${DocumentCode.PEEC}`,
      element: <ProductEfficacyEvaluationPage />,
    },
    {
      path: `product/:productId/${DocumentCode.EDC}`,
      element: <ProductEtcFilePage />,
    },
    {
      path: `product/:productId/${DocumentCode.MF}`,
      element: <ProductManufacturerInfoPage />,
    },
    {
      path: `product/:productId/${DocumentCode.RP}`,
      element: <ProductRPPage />,
    },
    {
      element: (
        <PrivateRoute check={!manuMode} redirect={path.main} message={messages.NO_PERMISSION} />
      ),
      children: [
        {
          path: 'product/:productId',
          element: <ProductDetailPage />,
        },
      ],
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);

  return element;
};

export default ProductRouter;

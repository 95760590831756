import { useState } from 'react';
import styled from 'styled-components';

import BrandRegisterFormContainer from 'containers/auth/brand/BrandRegisterFormContainer';
import NoticeCard from 'components/system/data/NoticeCard';
import Typography from 'components/system/general/Typography';

const BrandRegisterFormPageBlock = styled.div`
  max-width: 920px;
`;

const BrandRegisterFormPage = () => {
  const [tab, setTab] = useState<'company' | 'user'>('company');

  return (
    <BrandRegisterFormPageBlock>
      <Typography.Text light type="HEADLINE_1">
        정보 입력
      </Typography.Text>
      <Typography.Text type="BODY_2" gutter={{ top: 4, bottom: 18 }} color="GRAY70">
        정보를 정확하게 입력해 주세요.
      </Typography.Text>
      <NoticeCard title="안내사항">
        <ul>
          {tab === 'company' ? (
            <>
              <li>한 사업자당 한 계정만 가입이 가능합니다.</li>
              <li>
                회사 정보의{' '}
                <Typography.Text type="BODY_2" color="PRIMARY50" inline>
                  회사명, 사업자등록번호, 사업장 소재지, 대표자 성명은 사업자등록증에 내용과 동일
                </Typography.Text>
                하게 입력해 주세요.
              </li>
              <li>
                영문 사업장 소재지 경우,{' '}
                <Typography.Text type="BODY_2" color="PRIMARY50" inline>
                  국가명이 없다면 국가명을 포함하여 입력
                </Typography.Text>
                해 주세요. 예) 000, Seolleung-ro, Gangnam-gu, Seoul,{' '}
                <Typography.Text type="BODY_2" color="PRIMARY50" inline>
                  Republic of Korea
                </Typography.Text>
              </li>
              <li>영문 사업자등록증 없으신 경우, 전화나 하단의 ‘이용 문의’로 문의바랍니다.</li>
            </>
          ) : (
            <>
              <li>
                슈퍼유저 정보에 기재하신 이메일로 가입 승인 및 알림이 전송되며, 자동 발행 양식에
                해당 이메일이 사용되므로 책임자의 이메일을 입력해 주세요.
              </li>
              <li>담당자 계정은 슈퍼유저 계정 가입 후 언제든지 추가 및 수정이 가능합니다.</li>
            </>
          )}
        </ul>
      </NoticeCard>
      <BrandRegisterFormContainer tabKey={tab} setTabKey={setTab} />
    </BrandRegisterFormPageBlock>
  );
};

export default BrandRegisterFormPage;

import PageTemplate from 'templates/PageTemplate';
import { AdInquiryForm } from 'components/common/InquiryForm';

const AdInquiryPage = () => {
  return (
    <PageTemplate
      back={false}
      title="광고 및 제휴 문의"
      description={`certicos와 함께 성장할 광고주를 모십니다.\n배너 광고 또는 제휴 등의 문의는 아래에 내용을 기재하여 보내주세요. 담당자 확인 후 빠른 시일 내에 연락드리겠습니다.`}
    >
      <AdInquiryForm />
    </PageTemplate>
  );
};

export default AdInquiryPage;

import SharedDocsTabs from 'components/brand/mypage/SharedDocsTabs';
import { useSearchParams } from 'hook/useSearchParams';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';

const SharedDocsPage = () => {
  const notices = [
    '화장품책임판매업자의 품질서류관리 및 보관 의무 [화장품법 시행규칙 제 12조] 이행을 위해 하단의 서류들을 보관하시기 바랍니다.',
    '공통 서류 관리는 QC에서 사용될 서류 항목을 추가 및 삭제하실 수 있습니다.',
    '회사 서류는 개정 이력으로 관리되며, 제품과 제조번호 서류는 QC > 품질 제품 관리에서 관리하실 수 있습니다.',
    '인증 활용 서류는 영문 자료와 함께 등록해 두시면 해외인증 서비스 진행 시 간편하게 사용할 수 있습니다.',
    '숨긴 서류 항목은 제품, 제조번호 등록 시 서류 항목에 노출되지 않습니다.',
  ];

  const { searchParams } = useSearchParams({
    path: `${path.my}/docs`,
  });

  const { qcqaUserDocumentId }: { qcqaUserDocumentId: number } = {
    qcqaUserDocumentId: Number(searchParams.get('qcqaUserDocumentId')) || 0,
  };

  return (
    <PageTemplate back={false} notices={notices}>
      <SharedDocsTabs qcqaUserDocumentId={qcqaUserDocumentId} />
    </PageTemplate>
  );
};

export default SharedDocsPage;

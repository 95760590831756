import { Select, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import path from 'lib/path';
import { useTermsByType, useTermsDetail } from 'service/terms';
import { Terms, TermsType } from 'types/terms';
import TermsEditorViewer from 'components/terms/TermsEditorViewer';

const AllTermsPage = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const versionId = new URLSearchParams(search).get('id') as string;

  const [selectedTermsId, setSelectedTermsId] = useState<number>();
  const [terms, setTerms] = useState<Pick<Terms, 'serviceTermId' | 'publishDate'>[]>();
  const [content, setContent] = useState<string>('');
  const [tab, setTab] = useState<string>(pathname.split('/')[2]);

  const { getTermsByType } = useTermsByType();

  const { termsDetail } = useTermsDetail();

  useEffect(() => {
    if (versionId) setSelectedTermsId(Number(versionId));
  }, [versionId]);

  useEffect(() => {
    const termPathType = () => {
      switch (pathname) {
        case path.terms.privacyProcess:
          return TermsType.PRIVACY_PROCESS_BRAND;
        case path.terms.privacyService:
          return TermsType.PRIVACY_SERVICE_BRAND;
        case path.terms.privacyThirdParty:
          return TermsType.PRIVACY_THIRD_PARTY_BRAND;
        case path.terms.cibiService:
          return TermsType.CI_BI_SERVICE_BRAND;
        default:
          return TermsType.SERVICE_BRAND;
      }
    };

    getTermsByType(
      { termType: termPathType() },
      {
        onSuccess: (res) => {
          const data = res.data.result;

          if (!versionId) setSelectedTermsId(data?.[0].serviceTermId);
          setTerms(data);
          setTab(pathname.split('/')[2]);
        },
      },
    );
  }, [pathname, tab]);

  useEffect(() => {
    if (selectedTermsId)
      termsDetail(
        { serviceTermId: selectedTermsId },
        {
          onSuccess: (res) => {
            const data = res.data.result;
            if (res) setContent(data?.content);
          },
        },
      );
  }, [selectedTermsId]);

  const versions = useMemo(() => {
    return terms?.map(({ publishDate, serviceTermId }) => ({
      label: publishDate,
      value: serviceTermId,
    }));
  }, [terms]);

  const handleChangeTab = (key: string) => {
    navigate(`${path.terms.root}/${key.split('_')[0].toLowerCase()}`);
  };

  const handleSelectVersion = (val: number) => {
    setSelectedTermsId(val);
    navigate(`${path.terms.root}/${tab.split('_')[0].toLowerCase()}?id=${val}`);
  };

  return (
    <div>
      {terms && terms?.length > 0 && (
        <Select
          style={{ width: 160, marginBottom: 12 }}
          options={versions}
          value={selectedTermsId}
          onSelect={(val) => handleSelectVersion(val)}
        />
      )}
      <Tabs
        activeKey={tab}
        onChange={handleChangeTab}
        items={[
          {
            key: 'service',
            label: '이용약관',
          },
          {
            key: 'privacy-process',
            label: '개인정보 처리방침',
          },
          {
            key: 'privacy-service',
            label: '개인정보 수집·이용',
          },
          {
            key: 'privacy-third-party',
            label: '개인정보 제3자 제공',
          },
          {
            key: 'ci-bi-service',
            label: '로고 사용 및 게시 동의',
          },
        ]}
      />
      <TermsEditorViewer content={content || ''}></TermsEditorViewer>
    </div>
  );
};

export default AllTermsPage;

import RPList from 'components/brand/mypage/RPList';
import PageTemplate from 'templates/PageTemplate';

const notices = [
  '인증 진행 현황에서 입력하신 RP를 확인하실 수 있는 페이지입니다.',
  '이 페이지에서 RP를 추가해놓으시면, 인증 진행 현황에서도 편리하게 선택하실 수 있습니다.',
  'RP 정보 수정 시, 기존에 등록한 인증 제품의 RP 정보가 모두 변경됩니다. 모두 변경을 원하지 않으실 경우 새로운 RP로 추가하여 사용해 주세요.',
];

const MyRPPage = () => {
  return (
    <PageTemplate back={false} notices={notices}>
      <RPList />
    </PageTemplate>
  );
};

export default MyRPPage;

import { ComponentProps, useCallback } from 'react';
import SelectRange from './SelectRange';

const SelectMonth = ({
  start = 1,
  end = 12,
  placeholder = '월',
  ...props
}: ComponentProps<typeof SelectRange>) => {
  const customLabel = useCallback((value: number) => value + '월', []);
  return (
    <SelectRange
      width={72}
      start={start}
      end={end}
      placeholder={placeholder}
      customLabel={customLabel}
      {...props}
    />
  );
};

export default SelectMonth;

import { shallowEqual, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Row, Col, Drawer, Divider, Badge } from 'antd';
import { useDispatch } from 'react-redux';

import TopSubMenuContainer from 'containers/menu/TopSubMenuContainer';
import UserNoticeDrawerContainer from 'containers/service/UserNoticeDrawerContainer';
import { deviceSize } from 'lib/styles';
import MaterialTopMenu from './material/menu/TopMenu';
import ManufactureTopMenu from './manufacturer/menu/TopMenu';
import { CompanyType } from 'types/company';
import BrandTopMenu from './menu/BrandTopMenu';
import Logo from './system/general/icon/Logo';
import Icon from './ui/Icon/Icon';
import { Flex } from './ui';
import { toggleDrawer } from 'modules/navigator';
import { setUserNoticeDrawerVisible } from 'modules/service';
import { useCompanyType } from 'service/company';

const NavigatorBlock = styled.div`
  max-width: 1040px;
  margin: 0 auto;
`;

const CollapsableCol = styled(Col)<{
  collapsedsize: 'sm' | 'mdOver';
  position: 'relative' | 'static';
}>`
  position: ${({ position }) => position};
  ${({ collapsedsize }) => css`
    @media ${deviceSize[collapsedsize]} {
      display: none;
    }
  `}
`;

const DrawerHeader = styled(Flex)``;

const Navigator = () => {
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });
  const dispatch = useDispatch();
  const companyType = useCompanyType();
  const { user, drawerVisible, newUserNoticeCount } = useSelector(
    ({ auth, navigator, service }: any) => ({
      user: auth.user,
      drawerVisible: navigator.drawer.visible,
      newUserNoticeCount: service.userNotices.reduce(
        (acc: any, curr: any) => (curr.isConfirm ? acc : acc + 1),
        0,
      ),
    }),
    shallowEqual,
  );

  const isLoggedIn = user !== null;

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  const handleClickUserNotice = () => {
    dispatch(setUserNoticeDrawerVisible(true));
  };

  const renderTopMenu = (inlineMode = false) => {
    switch (companyType) {
      case CompanyType.MANUFACTURE:
        return <ManufactureTopMenu inlineMode={inlineMode} />;
      case CompanyType.MATERIAL:
        return <MaterialTopMenu inlineMode={inlineMode} />;
      default:
        return <BrandTopMenu inlineMode={inlineMode} />;
    }
  };

  return (
    <NavigatorBlock>
      <Flex
        align="center"
        justify="space-between"
        style={{
          height: isMobile ? 56 : 80,
          maxWidth: '1040px',
          margin: '0 auto',
        }}
      >
        <Link to="/" style={{ flex: '0 0 166px' }}>
          <Logo height={isMobile ? 24 : 32} />
        </Link>
        <CollapsableCol flex="1 1 100%" position="static" collapsedsize="sm">
          {renderTopMenu()}
        </CollapsableCol>
        <TopSubMenuContainer />
        <CollapsableCol collapsedsize="mdOver" position="relative">
          <Row gutter={30}>
            {isLoggedIn && (
              <Col>
                <Badge
                  dot={newUserNoticeCount > 0}
                  style={{
                    top: -2,
                    right: -2,
                    width: 5,
                    height: 5,
                    minWidth: 5,
                  }}
                >
                  <Icon
                    name="bell"
                    size={18}
                    onClick={handleClickUserNotice}
                    style={{ position: 'relative', top: -4, cursor: 'pointer' }}
                  />
                </Badge>
              </Col>
            )}
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name="hamburgerMenu"
                size={24}
                onClick={handleToggleDrawer}
                style={{ marginBottom: 4 }}
              />
            </Col>
          </Row>
        </CollapsableCol>
      </Flex>
      <Drawer
        placement="right"
        closable={false}
        open={drawerVisible}
        maskClosable
        styles={{
          body: {
            padding: 12,
          },
        }}
        onClose={handleToggleDrawer}
      >
        <DrawerHeader onClick={handleToggleDrawer} justify="flex-end">
          <Icon name="close" size={16} style={{ cursor: 'pointer' }} />
        </DrawerHeader>
        <Divider style={{ margin: '12px -12px', width: 265 }} />
        {renderTopMenu(true)}
        <TopSubMenuContainer inlineMode />
      </Drawer>
      <UserNoticeDrawerContainer />
    </NavigatorBlock>
  );
};

export default Navigator;

import { useLocation, useNavigate } from 'react-router-dom';

import path from 'lib/path';
import { NMPAOngoingPageState } from 'types/material/nmpa/nmpa';
import NMPAOngoing from 'components/material/nmpa/NMPAOngoing';
import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';

const NMPAOngoingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // 현재 위치에서 상태를 가져오고, 기본값을 설정
  const pageState = (location.state as NMPAOngoingPageState) || {
    page: 1,
    tradeName: '',
  };

  // 상태를 변경하고 페이지를 탐색하는 핸들러
  const handlePageStateChange = (state: NMPAOngoingPageState) => {
    navigate(path.material.nmpa.ongoing, { state });
  };

  return (
    <NMPAPageTemplate
      title="취득 중 원료 관리"
      back={false}
      description="취득 중인 원료를 관리해보세요."
    >
      <NMPAOngoing pageState={pageState} onChangePageState={handlePageStateChange} />
    </NMPAPageTemplate>
  );
};

export default NMPAOngoingPage;

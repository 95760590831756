import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { CompanyType } from 'types/company';
import Typography from '../general/Typography';

const CheckBoxContainer = styled.div`
  background: ${palette.SLATE_GRAY10};
  width: 100%;
  padding: 16px 36px;
  border-radius: 8px;
  margin-top: 24px;
`;

const RequiredText = styled.span`
  color: ${palette.MESSAGE_INFO};
  font-size: 14px;
  font-weight: 400;
  vertical-align: text-bottom;
`;

const DescriptionContainer = styled.div`
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  padding-left: 24px;
  margin-top: 8px;
`;

const CheckBoxCard = ({
  companyType,
  disabled = false,
  onChange,
}: {
  companyType: CompanyType;
  disabled?: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
}) => {
  return (
    <CheckBoxContainer>
      <Checkbox disabled={disabled} onChange={onChange}>
        <Typography.Text
          inline
          color="SLATE_GRAY70"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ fontWeight: 500, fontSize: '16px' }}
        >
          약정에 동의합니다. <RequiredText>(필수)</RequiredText>
        </Typography.Text>
      </Checkbox>
      <DescriptionContainer>
        {`${
          companyType === CompanyType.BRAND
            ? '브랜드'
            : companyType === CompanyType.MANUFACTURE
            ? '제조'
            : '원료'
        }사는 본 플랫폼에 기재된 사항이 사실과 일치한다는 사실을
        확인하였으며, 기재사항이 사실과 달라 발생하는 일체의 법률적 문제 및
        불이익에 대하여 전적인 책임을 부담할 것을 약정합니다.`}
      </DescriptionContainer>
    </CheckBoxContainer>
  );
};

export default CheckBoxCard;

import { useMemo } from 'react';

import { useProductDocStatus } from 'service/product';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { CorrectButton } from 'components/ui';
import { useModal } from 'hook/useModal';
import { DocumentCode } from 'types/product';

export const ProductCorrectButton = ({
  documentCode,
  productId: productIdProp,
  countryId: countryIdProp,
  disableAnim,
  isFirstOpenChatModal,
  onChangeIsFirstOpenChatModal,
}: {
  documentCode: DocumentCode;
  productId?: number;
  countryId?: number;
  disableAnim?: boolean;
  isFirstOpenChatModal: boolean;
  onChangeIsFirstOpenChatModal: (isFirstOpenChatModal: boolean) => void;
}) => {
  const currentProduct = useCurrentProduct();
  const countryIdParam = useCountryId();
  const productId = productIdProp || currentProduct.productId;
  const countryId = countryIdProp || countryIdParam;
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode,
  });
  const { openProductCorrectChatModal } = useModal();

  const targetCountryProductDetail = useMemo(() => {
    if (!countryId || Number.isNaN(countryId) || !currentProduct.productDetails)
      return currentProduct?.productDetail;

    const nCountryId = Number(countryId);

    return currentProduct.productDetails.find(
      (productDetail: any) => productDetail.countryId === nCountryId,
    );
  }, [currentProduct, countryId]);

  const productName = `${targetCountryProductDetail?.productNameEn || '-'}`;

  const fullNetWeight = `(${currentProduct?.netWeight} ${currentProduct?.netWeightUnit})`;

  if (productDocStatus === null || productDocStatus.status !== 'MOD' || !countryId) {
    return null;
  }

  return (
    <CorrectButton
      onClick={() => {
        openProductCorrectChatModal({
          productName,
          fullNetWeight,
          productId,
          countryId,
          productDocStatusId: productDocStatus.productDocStatusId,
          documentCode,
          isFirstOpenChatModal,
        });
        onChangeIsFirstOpenChatModal(false);
      }}
      disableAnim={disableAnim}
    />
  );
};

export default ProductCorrectButton;

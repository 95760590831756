import { Menu, MenuProps } from 'antd';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import path from 'lib/path';
import { useToggleDrawer } from 'service/navigator';

const StyledMenu = styled(Menu)`
  ${(props) =>
    props.mode !== 'inline' &&
    css`
      display: flex;
      margin: 0 auto;
      justify-content: center;

      &::before,
      &::after {
        display: none;
      }
    `}

  .ant-menu-title-content {
    font-size: 18px;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-size: 16px;
`;

type MenuItem = Required<MenuProps>['items'][number];

const TopMenu = ({ inlineMode }: { inlineMode: boolean }) => {
  const { pathname } = useLocation();
  const toggleDrawer = useToggleDrawer();

  const menuItems: MenuItem[] = [
    {
      key: '1',
      label: '원료·성분',
      children: [
        {
          key: path.material.rawMaterial.search,
          label: <StyledNavLink to={path.material.rawMaterial.search}>원료 검색</StyledNavLink>,
        },
        {
          key: path.material.ingredient.dictionary,
          label: <StyledNavLink to={path.material.ingredient.dictionary}>성분 사전</StyledNavLink>,
        },
      ],
    },
    {
      key: '2',
      label: '원료 등록',
      children: [
        {
          key: path.material.rawMaterial.basic,
          label: <StyledNavLink to={path.material.rawMaterial.basic}>신규 원료 등록</StyledNavLink>,
        },
        {
          key: path.material.manage.ready,
          label: <StyledNavLink to={path.material.manage.ready}>등록 중 원료 관리</StyledNavLink>,
        },
        {
          key: path.material.manage.done,
          label: <StyledNavLink to={path.material.manage.done}>등록 완료 원료 관리</StyledNavLink>,
        },
      ],
    },
    {
      key: '3',
      label: 'ICID 등재',
      children: [
        {
          key: path.material.icid.request,
          label: <StyledNavLink to={path.material.icid.request}>신규 등재 의뢰</StyledNavLink>,
        },
        {
          key: path.material.icid.requestList,
          label: (
            <StyledNavLink to={path.material.icid.requestList}>등재 의뢰 리스트</StyledNavLink>
          ),
        },
        {
          key: path.material.icid.ongoing,
          label: <StyledNavLink to={path.material.icid.ongoing}>등재 중 원료 관리</StyledNavLink>,
        },
        {
          key: path.material.icid.registered,
          label: (
            <StyledNavLink to={path.material.icid.registered}>등재 완료 원료 관리</StyledNavLink>
          ),
        },
      ],
    },
    {
      key: '4',
      label: '중국 NMPA 신고번호 취득',
      children: [
        {
          key: path.material.nmpa.estimate.request,
          label: (
            <StyledNavLink to={path.material.nmpa.estimate.request}>신규 취득 의뢰</StyledNavLink>
          ),
        },
        {
          key: path.material.nmpa.requestList,
          label: (
            <StyledNavLink to={path.material.nmpa.requestList}>취득 의뢰 리스트</StyledNavLink>
          ),
        },
        {
          key: path.material.nmpa.material.list,
          label: (
            <StyledNavLink to={path.material.nmpa.material.list}>원료 자료 입력</StyledNavLink>
          ),
        },
        {
          key: path.material.nmpa.ongoing,
          label: <StyledNavLink to={path.material.nmpa.ongoing}>취득 중 원료 관리</StyledNavLink>,
        },
        {
          key: path.material.nmpa.registered,
          label: (
            <StyledNavLink to={path.material.nmpa.registered}>취득 완료 원료 관리</StyledNavLink>
          ),
        },
      ],
    },
    {
      key: '5',
      label: <Link to={path.service}>고객센터</Link>,
    },
  ];

  return (
    <StyledMenu
      mode={inlineMode ? 'inline' : 'horizontal'}
      selectedKeys={[pathname]}
      triggerSubMenuAction="click"
      onClick={() => {
        if (inlineMode) {
          toggleDrawer();
        }
      }}
      items={menuItems}
    />
  );
};

export default TopMenu;

import { ComponentProps, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from 'components/system';
import PageTemplate from './PageTemplate';
import { Flex } from 'components/ui';
import { useQCQAProduct } from 'service/brand/qcqa/qcqa';
import { searchParamToNum } from 'lib/common';
import styled from 'styled-components';
import palette from 'lib/styles/palette';

const Container = styled.div`
  .product_name:hover {
    .tooltip {
      opacity: 1;
    }
  }
  .tooltip {
    opacity: 0;
    position: absolute;
    bottom: -3px;
    padding: 2px 8px;
    background: ${palette.GRAY30};
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
`;

const QCQAPageTemplate = ({ subtitle, ...props }: ComponentProps<typeof PageTemplate>) => {
  const productNameRef = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const { qcqaProductId: productId } = useParams<{ qcqaProductId: string }>(); //params에 qcqaProductId가 포함되는 페이지인 있는 경우가 있어 추가
  const [qcqaProductId, setQcqaProductId] = useState(0);
  const [productInfo, setProductInfo] = useState<{
    productNameKo: string;
    productNameEn: string;
  }>();
  const { qcqaProductId: sQcqaProductId = '0' } = useParams<{
    qcqaProductId?: string;
  }>();
  const qcqaProductIdFromQs = searchParamToNum('qcqaProductId', 0);
  const { qcProduct } = useQCQAProduct({
    qcqaProductId: Number(productId) || qcqaProductId,
  });

  useEffect(() => {
    const productNameCurrent = productNameRef.current as HTMLDivElement;

    setIsOverflowed(productNameCurrent?.scrollWidth > 630);
  }, [productInfo]);

  useEffect(() => {
    if (!sQcqaProductId || !qcqaProductIdFromQs) return;
    setQcqaProductId(Number(sQcqaProductId) || qcqaProductIdFromQs);
  }, [sQcqaProductId, qcqaProductIdFromQs]);

  useEffect(() => {
    if ((qcqaProductId || productId) && qcProduct) {
      const { productNameKo, productNameEn } = qcProduct;
      setProductInfo({
        productNameKo,
        productNameEn,
      });
    }
  }, [qcProduct, productId]);

  return (
    <Container>
      <PageTemplate
        {...props}
        subtitle={
          <Flex align="center">
            {subtitle && (
              <Typography.Text type="TITLE_2" color="PRIMARY50" gutter={{ right: 16 }}>
                {subtitle}
              </Typography.Text>
            )}
            {(productInfo || productId) && (
              <Flex align="center">
                <Typography.Text
                  type="SMALL"
                  color="SLATE_GRAY70"
                  style={{ opacity: 0.5 }}
                  gutter={{ right: 4 }}
                  inline
                >
                  제품명
                </Typography.Text>
                <span ref={productNameRef}>
                  <Typography.Text
                    medium
                    color="SLATE_GRAY70"
                    style={{
                      maxWidth: 631,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    className="product_name"
                  >
                    {productInfo?.productNameKo || productInfo?.productNameEn || '-'}
                    {isOverflowed && (
                      <div className="tooltip">
                        {productInfo?.productNameKo || productInfo?.productNameEn}
                      </div>
                    )}
                  </Typography.Text>
                </span>
              </Flex>
            )}
          </Flex>
        }
      />
    </Container>
  );
};

export default QCQAPageTemplate;

import { useRoutes } from 'react-router-dom';

import ProductListPage from 'pages/brand/retailer/retailerApply/product/ProductListPage';
import ProductBasicPage from 'pages/brand/retailer/retailerApply/product/ProductBasicPage';
import ProductCategoryPage from 'pages/product/ProductCategoryPage';
import ProductDetailPage from 'pages/brand/retailer/retailerApply/product/ProductDetailPage';
import BLPage from 'pages/brand/retailer/retailerApply/BLPage';
import COBPage from 'pages/brand/retailer/retailerApply/COBPage';
import CRCSBPage from 'pages/brand/retailer/retailerApply/CRCSBPage';
import OBDPage from 'pages/brand/retailer/retailerApply/OBDPage';
import ProductCMBCPage from 'pages/brand/retailer/retailerApply/product/ProductCMBCPage';
import ProductPSPage from 'pages/brand/retailer/retailerApply/product/ProductPSPage';
import ProductINGSPage from 'pages/brand/retailer/retailerApply/product/ProductINGSPage';
import ProductBCOAPage from 'pages/brand/retailer/retailerApply/product/ProductBCOAPage';
import ProductCOAPage from 'pages/brand/retailer/retailerApply/product/ProductCOAPage';
import ProductKARTPage from 'pages/brand/retailer/retailerApply/product/ProductKARTPage';
import ProductAIMGPage from 'pages/brand/retailer/retailerApply/product/ProductAIMGPage';
import ProductFRPage from 'pages/brand/retailer/retailerApply/product/ProductFRPage';
import ProductCFIRCPage from 'pages/brand/retailer/retailerApply/product/ProductCFIRCPage';
import ProductRHSPage from 'pages/brand/retailer/retailerApply/product/ProductRHSPage';
import ProductRNISPage from 'pages/brand/retailer/retailerApply/product/ProductRNISPage';
import ProductHATRPage from 'pages/brand/retailer/retailerApply/product/ProductHATRPage';
import ProductPFAPage from 'pages/brand/retailer/retailerApply/product/ProductPFAPage';
import ProductPSRPage from 'pages/brand/retailer/retailerApply/product/ProductPSRPage';
import ProductPLIPage from 'pages/brand/retailer/retailerApply/product/ProductPLIPage';
import ProductCRCPage from 'pages/brand/retailer/retailerApply/product/ProductCRCPage';
import ProductCPRSPage from 'pages/brand/retailer/retailerApply/product/ProductCPRSPage';
import ProductLPBPage from 'pages/brand/retailer/retailerApply/product/ProductLPBPage';
import ProductCSIGPage from 'pages/brand/retailer/retailerApply/product/ProductCSIGPage';
import Error404Page from 'pages/error/Error404Page';

const RetailerRouter = () => {
  const element = useRoutes([
    {
      path: 'product/list',
      element: <ProductListPage />,
    },
    {
      path: 'product/:productId',
      element: <ProductDetailPage />,
    },
    {
      path: 'product/basic',
      element: <ProductBasicPage />,
    },
    {
      path: 'product/category/:productId',
      element: <ProductCategoryPage />,
    },
    {
      path: 'BL/*',
      element: <BLPage />,
    },
    {
      path: 'COB/*',
      element: <COBPage />,
    },
    {
      path: 'CRCSB/*',
      element: <CRCSBPage />,
    },
    {
      path: 'OBD/*',
      element: <OBDPage />,
    },
    {
      path: 'product/CMBC/:productId',
      element: <ProductCMBCPage />,
    },
    {
      path: 'product/PS/:productId',
      element: <ProductPSPage />,
    },
    {
      path: 'product/INGS/:productId',
      element: <ProductINGSPage />,
    },
    {
      path: 'product/BCOA/:productId',
      element: <ProductBCOAPage />,
    },
    {
      path: 'product/COA/:productId',
      element: <ProductCOAPage />,
    },
    {
      path: 'product/KART/:productId',
      element: <ProductKARTPage />,
    },
    {
      path: 'product/AIMG/:productId',
      element: <ProductAIMGPage />,
    },
    {
      path: 'product/FR/:productId',
      element: <ProductFRPage />,
    },
    {
      path: 'product/CFIRC/:productId',
      element: <ProductCFIRCPage />,
    },
    {
      path: 'product/RHS/:productId',
      element: <ProductRHSPage />,
    },
    {
      path: 'product/RNIS/:productId',
      element: <ProductRNISPage />,
    },
    {
      path: 'product/HATR/:productId',
      element: <ProductHATRPage />,
    },
    {
      path: 'product/PFA/:productId',
      element: <ProductPFAPage />,
    },
    {
      path: 'product/PSR/:productId',
      element: <ProductPSRPage />,
    },
    {
      path: 'product/PLI/:productId',
      element: <ProductPLIPage />,
    },
    {
      path: 'product/CRC/:productId',
      element: <ProductCRCPage />,
    },
    {
      path: 'product/CPRS/:productId',
      element: <ProductCPRSPage />,
    },
    {
      path: 'product/LPB/:productId',
      element: <ProductLPBPage />,
    },
    {
      path: 'product/CSIG/:productId',
      element: <ProductCSIGPage />,
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);
  return element;
};

export default RetailerRouter;

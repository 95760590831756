import RawMaterialBasic from 'components/material/rawMaterial/basic/RawMaterialBasic';
import { useParams } from 'react-router-dom';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const RawMaterialBasicPage = () => {
  const notices = [
    '원료에 대한 기본적인 정보를 입력해 주세요.',
    '이곳의 정보만 입력하셔도 원료 등록이 가능하며, 등록 시 원료 검색에서 원료의 정보가 자동으로 노출됩니다.',
  ];
  const { materialId } = useParams<{ materialId: string }>();
  return (
    <RawMaterialPageTemplate
      documentName="기본 정보"
      notices={notices}
      back={typeof materialId !== 'undefined'}
    >
      <RawMaterialBasic />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialBasicPage;

import { Col, Row, Spin } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CompanyStat from 'components/main/CompanyStat';
import CompanyStatsBlock from 'components/main/CompanyStatsBlock';
import Shortcut from 'components/main/Shortcut';
import Typography from 'components/system/general/Typography';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import path from 'lib/path';
import { useMain } from 'service/main';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import { ReactComponent as ArtworkScreeningImg } from 'asset/svg/artwork_screening.svg';

const MainPage = () => {
  const navigate = useNavigate();
  const { openStibeePopUpModal } = useModal();
  const { companyName, stats, getStatsLoading } = useMain();
  const {
    sampleConfirmationCount = 0,
    productOnCount = 0,
    issueAuthCodeCount = 0,
    brandCompanyAuthenticated = 0,
  } = { ...stats };

  useEffect(() => {
    const expireDate = window.localStorage.getItem('ST_POP');
    let today = new Date();

    if (!expireDate || Number(today.getTime()) > Number(expireDate)) {
      openStibeePopUpModal({});
    }
    if (expireDate === 'Infinity') {
      return;
    }
  }, []);

  const handleAddProduct = () => {
    navigate(path.manufacturer.product.basic);
  };

  return (
    <MainPageBlock>
      <Row gutter={[16, 32]} justify="center">
        <Col>
          <CompanyStatsBlock>
            <Typography.Text mark light type="HEADLINE_1" align="center">
              안녕하세요.
              <br className="mobile-br" />
              <mark>{companyName}</mark> 님!
              <br /> 화장품 산업 발전 연구소 CDRI 입니다.
            </Typography.Text>
            <Spin spinning={getStatsLoading}>
              <Row gutter={16} style={{ marginTop: 36 }} justify="center">
                <Col>
                  <CompanyStat
                    amount={sampleConfirmationCount || 0}
                    opacity={0.25}
                    label="확정 완료 샘플"
                    onClick={() => navigate(path.manufacturer.productSample.list)}
                  />
                </Col>
                <Col>
                  <CompanyStat
                    amount={productOnCount || 0}
                    opacity={0.5}
                    label="등록 중 제품"
                    onClick={() => navigate(path.manufacturer.product.readyList)}
                  />
                </Col>
                <Col>
                  <CompanyStat
                    amount={issueAuthCodeCount || 0}
                    opacity={0.75}
                    label="코드 발행 제품"
                    onClick={() => navigate(path.manufacturer.product.list)}
                  />
                </Col>
                <Col>
                  <CompanyStat
                    amount={brandCompanyAuthenticated || 0}
                    opacity={1}
                    label="사용된 코드"
                    onClick={() => navigate(path.manufacturer.product.list)}
                  />
                </Col>
              </Row>
            </Spin>
          </CompanyStatsBlock>
        </Col>
      </Row>
      <Flex gap={16} gutter={{ top: 32 }} align="end">
        <FavoriteMenu>
          <Typography.Text type="TITLE_1">자주 찾는 메뉴</Typography.Text>
          <Flex gap={16} wrap="true" gutter={{ top: 16 }}>
            <Shortcut
              icon={<Icon name="book" color="PRIMARY50" />}
              label="성분 사전"
              description="제품에 포함된 성분 정보 및 규제를 확인해 보세요."
              onClick={() => navigate(path.material.ingredient.dictionary)}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="flask" color="PRIMARY50" />}
              label="신규 샘플 등록"
              description="샘플 처방을 등록하여 관리해 보세요."
              onClick={() => navigate(path.manufacturer.productSample.basic)}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="multipleAdd" color="PRIMARY50" />}
              label="신규 제품 등록"
              description="한 번의 입력으로 인증 서류 대응을 완료해 보세요. "
              onClick={handleAddProduct}
              flex={'1 1 calc(50% - 8px)'}
            />
            <Shortcut
              icon={<Icon name="code" color="PRIMARY50" />}
              label="코드 발행"
              description="브랜드사에게 제품 정보를 전달할 수 있는 코드를 발행해 보세요."
              onClick={() => navigate(path.manufacturer.product.list)}
              flex={'1 1 calc(50% - 8px)'}
            />
          </Flex>
        </FavoriteMenu>
        {/* Artwork Screening */}
        <ArtworkScreeningContainer
          justify="center"
          align="center"
          onClick={() => navigate(path.artworkScreening)}
        >
          <Flex dir="column" justify="center" align="center" gap={24}>
            <Flex dir="column" gap={4} align="center">
              <Typography.Text type="TITLE_2" color="PRIMARY50" style={{ fontWeight: 600 }}>
                아트웍 스크리닝
              </Typography.Text>
              <Typography.Text type="BODY_2" color="GRAY90">
                AI 기술을 활용한 Artwork의 전성분 라벨링 확인
              </Typography.Text>
            </Flex>
            <ArtworkScreeningImg style={{ height: 190 }} />
          </Flex>
        </ArtworkScreeningContainer>
      </Flex>
    </MainPageBlock>
  );
};

const MainPageBlock = styled.div`
  padding-top: 40px;
  max-width: 1024px !important;
`;

const FavoriteMenu = styled.div``;

const ArtworkScreeningContainer = styled(Flex)`
  border-radius: 12px;
  background-color: ${palette.PRIMARY10};
  border: 1px solid transparent;
  height: 100%;
  height: 312px;
  padding: 24px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${palette.PRIMARY50};
    box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
  }
`;

export default MainPage;

import styled from 'styled-components';

import NMPAChecklist from 'components/material/nmpa/NMPAChecklist';
import PageTemplate from 'templates/PageTemplate';

const Container = styled.div`
  max-width: 400px !important;
  margin: 0 auto;
`;

const NMPAChecklistPage = () => {
  return (
    <Container>
      <PageTemplate title="신규 취득 의뢰" back={false}>
        <NMPAChecklist />
      </PageTemplate>
    </Container>
  );
};

export default NMPAChecklistPage;

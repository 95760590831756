import styled from 'styled-components';

import { deviceSize } from 'lib/styles';

const CompanyStatsBlock = styled.div`
  flex: 0 0 512px;

  span {
    position: relative;
    z-index: 2;
  }
  br.mobile-br {
    display: none;
    @media ${deviceSize.sm} {
      display: block;
    }
  }

  @media ${deviceSize.sm} {
    padding-top: 32px;
  }

  .count {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
  }
`;

export default CompanyStatsBlock;

import { Button, Form, Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import { useIFRA, useIFRADefault } from 'service/material/ifra';
import { IFRADefault } from 'types/material/ifra';
import { deviceSize } from 'lib/styles';
import { useAllergen } from 'service/material/allergen';
import PreventEnterInput from 'components/system/form/PreventEnterInput';

const IFRACertificateBlock = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: fit-content;
  height: 800px;
  max-width: 600px;
  margin: 0 10%;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const MyRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin: 0 0 16px 88px;

  @media ${deviceSize.sm} {
    display: block;
    margin-left: 0px;
  }
`;

const IFRACertificate = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId) || undefined;
  const [form] = Form.useForm();

  const { ifras, getListLoading, addLoading, updateLoading, onSubmit } = useIFRA(materialId);
  // HINT : ifra는 allergensList에 종속되는 데이터이기 때문에 수정시에 materialAllergenListId를 사용
  const { materialAllergenListId } = useAllergen(materialId);
  const { ifraDefaults, getDefaultLoading } = useIFRADefault({
    ifras,
    materialId,
    getListLoading,
  });

  const generateCodename = (code: string) => {
    const len = code.length - 1;
    return code[len] === '_' ? `Category ${code.slice(0, len)}` : code;
  };

  const generateDefaultIFRAs = (ifras?: IFRADefault[]): any => {
    if (ifras) {
      return ifras
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((ifra) => (
          <React.Fragment key={ifra.code}>
            <MyRow>
              <StyledFormItem
                initialValue={''}
                label={generateCodename(ifra.code)}
                name={ifra.materialCategoryId}
                rules={[
                  {
                    pattern: /^([1-9](\d?)|0)(\.\d{1,10})?$|(^100$)/,
                    message: '입력 형식에 맞지 않습니다.',
                  },
                ]}
              >
                <PreventEnterInput
                  style={{
                    width: 120,
                    marginRight: '8px',
                    textAlign: 'right',
                  }}
                />
              </StyledFormItem>
              <Typography.Text>%</Typography.Text>
            </MyRow>
          </React.Fragment>
        ));
    } else return;
  };

  const fetchLoading = getListLoading || getDefaultLoading || addLoading || updateLoading;

  return (
    <IFRACertificateBlock>
      <Spin spinning={fetchLoading}>
        <Form
          form={form}
          colon={false}
          onFinish={(formValue) => onSubmit(formValue, materialAllergenListId)}
        >
          <Container>
            {ifraDefaults &&
              !!ifras?.length === false &&
              ifraDefaults.map((ifra) => generateDefaultIFRAs(ifra.subCategories))}
            {ifras &&
              ifras
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((ifra) => (
                  <React.Fragment key={ifra.subCode}>
                    <MyRow>
                      <StyledFormItem
                        initialValue={ifra.categoryPercent || ''}
                        label={generateCodename(ifra.subCode)}
                        name={ifra.materialAllergenIfraId}
                        rules={[
                          {
                            pattern: /^([1-9](\d?)|0)(\.\d{1,10})?$|(^100$)/,
                            message: '입력 형식에 맞지 않습니다.',
                          },
                        ]}
                      >
                        <PreventEnterInput
                          style={{
                            width: 120,
                            marginRight: '8px',
                            textAlign: 'right',
                          }}
                        />
                      </StyledFormItem>
                      <Typography.Text>%</Typography.Text>
                    </MyRow>
                  </React.Fragment>
                ))}
          </Container>
          <FooterBox>
            <Button type="primary" htmlType="submit">
              {!_.isEmpty(ifras) ? '수정' : '등록'}
            </Button>
          </FooterBox>
        </Form>
      </Spin>
    </IFRACertificateBlock>
  );
};

export default IFRACertificate;

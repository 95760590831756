import { Flex, message, Upload, UploadFile } from 'antd';
import styled from 'styled-components';

import { s3AssetDomain } from 'lib/consts';
import palette from 'lib/styles/palette';
import { UploadChangeParam } from 'antd/es/upload';
import { PackingAttestationAttache } from 'types/brand/product/packingAttestation';
import Icon from './ui/Icon/Icon';
import { useReadOnlyMode } from 'hook/certificate';
import { useCallback, useState } from 'react';
import ImageBox from './ui/ImageBox';
import { FormInstance } from 'antd/lib';

interface UploadDraggerProps {
  width?: number;
  height?: number;
  disabled?: boolean;
  style?: React.CSSProperties;
  initialImageUrl?: string;
  imageDescription: string;
  className?: string;
  onDragger?: (file: File) => void;
  onChange?: (info: UploadChangeParam<UploadFile<any>>) => void;
  packingFiles: (PackingAttestationAttache | File)[];
  setPackingFiles: React.Dispatch<React.SetStateAction<(File | PackingAttestationAttache)[]>>;
  currentFile?: PackingAttestationAttache | File;
  setCurrentFile: React.Dispatch<
    React.SetStateAction<File | PackingAttestationAttache | undefined>
  >;
  isFile: (file: any) => file is File;
  setDeletedFiles: React.Dispatch<React.SetStateAction<(PackingAttestationAttache | File)[]>>;
  form: FormInstance;
}

const getNameOfFile = (file?: File | PackingAttestationAttache) => {
  return file instanceof File ? file.name : file?.filename;
};

const UploadDragger = ({
  width,
  height,
  disabled,
  style,
  initialImageUrl,
  imageDescription,
  className,
  onDragger,
  onChange,
  packingFiles,
  setPackingFiles,
  currentFile,
  setCurrentFile,
  isFile,
  setDeletedFiles,
  form,
}: UploadDraggerProps) => {
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

  const readOnlyMode = useReadOnlyMode();

  const getImageUrl = useCallback(
    (file?: PackingAttestationAttache | File) =>
      file ? (isFile(file) ? URL.createObjectURL(file) : file.attachUrl) : undefined,
    [isFile],
  );

  const handleClickPlus = useCallback((className: string) => {
    const dragger: HTMLElement | null = document.querySelector(className);

    if (dragger) {
      dragger.click();
    }
  }, []);

  return (
    <div>
      <StyledUploadDragger
        fileList={fileList}
        style={style}
        disabled={disabled}
        width={width}
        height={height}
        name="uploadFile"
        accept=".png, .jpeg, .jpg"
        onChange={(info) => {
          if (info.fileList.length > 0) {
            const file = info.fileList[info.fileList.length - 1].originFileObj as File;

            if (packingFiles.length === 5) {
              return message.warning('최대 5개까지 업로드 가능합니다.');
            }
            if (packingFiles.some((item) => getNameOfFile(item) === file.name)) {
              return message.warning('같은 이름의 파일은 업로드 할 수 없습니다.');
            }
            onChange?.(info);
            setFileList(info.fileList);
            onDragger?.(file);
          }
        }}
        beforeUpload={() => {
          return false;
        }}
        itemRender={() => null}
      >
        <PictureNoticeWrapper width={width} height={height} className={className}>
          {!initialImageUrl ? (
            <>
              <AlbumIcon src={`${s3AssetDomain}/album_icon.png`} alt="album" />
              <StyledText>
                클릭 혹은 이미지 끌어서 넣기 <br />
                크기 {`${imageDescription}`} 이하 권장
              </StyledText>
            </>
          ) : (
            <img
              src={initialImageUrl}
              alt="file"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          )}
        </PictureNoticeWrapper>
      </StyledUploadDragger>
      {packingFiles.length > 0 && (
        <BoxContainer>
          {!readOnlyMode && (
            <PlusBox
              justify="center"
              align="center"
              onClick={() => handleClickPlus(`.${className}`)}
            >
              <Icon name="plus" size={16} color="SLATE_GRAY70" />
            </PlusBox>
          )}
          {packingFiles.map((file, index) => (
            <div
              key={index}
              style={{
                width: 100,
                position: 'relative',
                background: palette.SLATE_GRAY10,
                marginLeft: index === 0 ? 8 : 16,
              }}
            >
              {!readOnlyMode && (
                <DeleteWrapper
                  align="center"
                  justify="center"
                  onClick={() => {
                    if (getNameOfFile(currentFile) === getNameOfFile(file)) {
                      setCurrentFile(undefined);
                    }

                    setPackingFiles((draft) =>
                      draft.filter((item) => getNameOfFile(item) !== getNameOfFile(file)),
                    );
                    setFileList(
                      fileList.filter(
                        (item) => getNameOfFile(item.originFileObj) !== getNameOfFile(file),
                      ),
                    );
                    form.setFieldsValue({
                      packingContainer1Image: packingFiles.filter(
                        (item) => getNameOfFile(item) !== getNameOfFile(file),
                      ),
                    });

                    if (!isFile(file)) {
                      setDeletedFiles((draft) => draft.concat(file));
                    }
                  }}
                >
                  <Icon name="smallClose" size={20} color="ETC_WHITE" circleColor="SLATE_GRAY60" />
                </DeleteWrapper>
              )}

              <ImageBox
                key={getNameOfFile(file)}
                width="100px"
                height="72px"
                borderRadius="4px"
                src={getImageUrl(file) || ''}
                alt={getNameOfFile(file) || ''}
                objectFit="contain"
                onClick={() => setCurrentFile(file)}
              />
            </div>
          ))}
        </BoxContainer>
      )}
    </div>
  );
};

const StyledUploadDragger = styled(Upload.Dragger)<{
  height?: number;
  width?: number;
}>`
  display: block;
  width: ${(props) => (props.width ? props.width : 350)}px !important;
  height: ${(props) => (props.height ? props.height : 175)}px !important;

  border: none !important;
  background: ${palette.SLATE_GRAY10} !important;

  padding: 0px !important;

  .ant-upload {
    padding: 0px !important;
  }
`;

const PictureNoticeWrapper = styled.div<{
  height?: number;
  width?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width : 350)}px !important;
  height: ${(props) => (props.height ? props.height : 175)}px !important;
`;

const AlbumIcon = styled.img`
  width: 56px;
  height: 56px;

  margin-bottom: 8px;
`;

const StyledText = styled.div`
  height: 15px;
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.45px;
  color: #a9a6a6;
`;

const BoxContainer = styled(Flex)`
  padding-top: 12px;
  padding-bottom: 4px;
  max-width: 520px;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${palette.SLATE_GRAY70};
    border-radius: 55px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const PlusBox = styled(Flex)`
  flex: 0 0 100px;
  height: 72px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
  cursor: pointer;
`;

const DeleteWrapper = styled(Flex)`
  position: absolute;
  top: -4px;
  right: -8px;
  border-radius: 52px;
  z-index: 99;
  cursor: pointer;
`;

export default UploadDragger;

import { FormInstance, Select } from 'antd';
import { isEqual } from 'lodash';
import { ComponentProps } from 'react';

export function getUpdatingObject<T extends object>(
  newObject: T,
  originObject: any,
): Partial<T> | undefined {
  const updatedObject: Partial<T> = {};
  (Object.keys(newObject) as Array<keyof T>).forEach((key) => {
    const newValue = newObject[key];
    const originValue = originObject[key];
    if (newValue !== originValue) {
      updatedObject[key] = newValue;
    }
  });
  return Object.keys(updatedObject).length > 0 ? updatedObject : undefined;
}

export function getUndefinedFilteredObject<T extends object>(obj: T): Partial<T> {
  const newObj = (Object.keys(obj) as Array<keyof T>).reduce<Partial<T>>((acc, key) => {
    if (typeof obj[key] !== 'undefined') {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
  return newObj;
}

export const parseWeightUnit = (unit: string): string | undefined => {
  const lowerUnit = unit.toLowerCase();
  if (lowerUnit === 'g') {
    return 'g';
  } else if (lowerUnit === 'ml') {
    return 'mL';
  } else if (lowerUnit === 'oz' || lowerUnit === 'oz.') {
    return 'Oz.';
  } else if (lowerUnit.replace(/\s/gi, '').includes('fl.')) {
    return 'fl. Oz.';
  }
};

export const toNoExponents = (exponents: number) => {
  const data = String(exponents).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z = '',
    sign = exponents < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
};

export const filterOptionForStringLabel: ComponentProps<typeof Select>['filterOption'] = (
  keyword,
  option,
) => (option?.label as string).toLowerCase().includes(keyword.toLowerCase());

export const focusToInvalidatedField =
  ({ form, offsetY = 0 }: { form: FormInstance; offsetY?: number }) =>
  (errorInfo: any) => {
    if (errorInfo?.errorFields?.length > 0) {
      form.scrollToField(errorInfo.errorFields[0].name, {
        behavior: (actions) => {
          actions.forEach(({ el, top, left }: any) => {
            el.scrollTop = top + offsetY;
            el.scrollLeft = left;
          });
        },
      });
    }
  };

export const ifBExistAssignToAForObject = (
  valueA: { [key: string]: any },
  valueB: { [key: string]: any },
  keyPairs: [string, string][],
) => {
  for (const [keyA, keyB] of keyPairs) {
    if (valueB[keyB] !== undefined && valueB[keyB] !== null) {
      valueA[keyA] = valueB[keyB];
    }
  }
};

export const isEmptyString = (str: any) => str === '' || str === undefined || str === null;

// HINT: 맨 앞에 공백 / 연속으로 공백 인 경우 제거
export const normalizeBlank = (value: string) => {
  if (value.startsWith(' ')) {
    return value.trimStart();
  }

  return value.replace(/\s+/g, ' ');
};

export const isObjectChanged = ({ object1, object2 }: { object1: Object; object2: Object }) => {
  return !isEqual(object1, object2);
};

import { useLocation, useNavigate } from 'react-router-dom';

import path from 'lib/path';
import ProductList from 'components/product/ProductList';
import PageTemplate from 'templates/PageTemplate';
import { ProductSearchType, ProductListPageState } from 'types/product';

const ProductRegisteredPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageState = (location.state as ProductListPageState) || {
    page: 1,
    searchType: ProductSearchType.ALL,
    searchKeyword: '',
  };

  const handleChangePageState = (state: ProductListPageState) => {
    navigate(path.product.registered, { state, replace: true });
  };

  return (
    <PageTemplate
      title="등록 완료 제품 관리"
      back={false}
      description="아래 등록 완료된 제품을 관리해보세요."
    >
      <ProductList pageState={pageState} onChangePageState={handleChangePageState} />
    </PageTemplate>
  );
};

export default ProductRegisteredPage;

import { useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { messages, productSpecificationsOption } from 'lib/consts';
import { getUpdatingObject } from 'lib/form';
import { useProductDocStatus } from 'service/product';
import { DocumentCode, ProductSpecification } from 'types/product';
import useGA, { EGAActionType } from 'hook/useGA';
import client from 'lib/api/client';

const queryKey = '/product-specification';

export const useProductSpec = (productId: number, countryId: number) => {
  const navigate = useNavigate();

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { sendEventToGA } = useGA();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.PS,
  });

  const { data: productSpecifications, isLoading: getLoading } = useQuery(
    [queryKey, productId, countryId],
    () => client.get(`/product-specification/${productId}/${countryId}`),
    {
      select: (res) => res.data.result,
    },
  );

  const { mutate: saveProductSpecifications, isLoading: saveLoading } = useMutation(
    ({
      productId,
      countryId,
      productSpecifications,
    }: {
      productId: number;
      countryId: number;
      productSpecifications: ProductSpecification;
    }) => client.post(`/product-specification/${productId}/${countryId}`, productSpecifications),
    {
      onSuccess: () => {
        message.success('입력하신 데이터가 임시 저장되었습니다.');
      },
    },
  );

  const { mutate: addProductSpecifications, isLoading: addLoading } = useMutation(
    ({
      productId,
      countryId,
      productSpecifications,
    }: {
      productId: number;
      countryId: number;
      productSpecifications: ProductSpecification;
    }) => client.post(`/product-specification/${productId}/${countryId}`, productSpecifications),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: 'Product Specifications',
          actionType: EGAActionType.REGISTER,
        });
        navigate(-1);
        message.success('입력되었습니다.');
      },
    },
  );

  const { mutate: updateProductSpecifications, isLoading: updateLoading } = useMutation(
    ({
      productId,
      countryId,
      productSpecifications,
    }: {
      productId: number;
      countryId: number;
      productSpecifications: ProductSpecification;
    }) => client.patch(`/product-specification/${productId}/${countryId}`, productSpecifications),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: 'Product Specifications',
          actionType: EGAActionType.MODIFY,
        });
        message.success('보완 완료되었습니다.');
        navigate(-1);
      },
    },
  );

  const updateMode = productDocStatus && productDocStatus.status !== 'INP';
  const [form] = Form.useForm();

  const onSaveDraft = useCallback(() => {
    const formValues = form.getFieldsValue();
    onSubmit(formValues, true);
  }, [form]);

  const onSubmit = useCallback(
    (formValues: any, tempSaved = false) => {
      setSubmitSuccess(true);
      let {
        productPeriod,
        productPeriodUnit,
        productPeriodUnitDirect,
        pao,
        paoUnit,
        targetAge,
        targetAgeUnit,
        targetAgeUnitDirect,
        skinType,
        skinTypeUnit,
        skinTypeUnitDirect,
        ...restValues
      } = formValues;
      productPeriod = Number(productPeriod);
      pao = paoUnit === 'use immediately' ? null : Number(pao);

      targetAge = targetAgeUnit !== 'others' ? targetAgeUnit : targetAgeUnitDirect;
      skinType = skinTypeUnit !== 'others' ? skinTypeUnit : skinTypeUnitDirect;

      let newProductSpecifications: any = {};
      if (!updateMode) {
        newProductSpecifications = {
          ...restValues,
          productPeriod,
          productPeriodUnit,
          ...(pao && { pao }),
          paoUnit,
          targetAge,
          skinType,
          tempSaved,
        };
      } else {
        // HINT: 관리자에서 자유양식 업로드한 경우, productSpecifications이 null이 되어 getUpdatingObject가 불필요
        newProductSpecifications = productSpecifications
          ? getUpdatingObject(
              {
                ...restValues,
                productPeriod,
                productPeriodUnit,
                ...(pao && { pao }),
                paoUnit,
                targetAge,
                skinType,
              },
              productSpecifications,
            )
          : {
              ...restValues,
              productPeriod,
              productPeriodUnit,
              ...(pao && { pao }),
              paoUnit,
              targetAge,
              skinType,
            };

        if (!newProductSpecifications || Object.keys(newProductSpecifications).length === 0) {
          return message.warning(messages.NO_NEED_TO_UPDATE);
        }
        newProductSpecifications.tempSaved = false;
      }

      if (tempSaved) {
        saveProductSpecifications({
          productId,
          countryId,
          productSpecifications: newProductSpecifications,
        });
      } else if (!updateMode) {
        addProductSpecifications({
          productId,
          countryId,
          productSpecifications: newProductSpecifications,
        });
      } else {
        updateProductSpecifications({
          productId,
          countryId,
          productSpecifications: newProductSpecifications,
        });
      }
    },
    [productSpecifications],
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        countryOrigin: 'KR',
        productPeriod: '12',
        productPeriodUnit: 'year(s)',
        targetAgeUnit: 'over3',
        skinType: 'Dry Skin Type',
        applicationArea: 'Skin',
        frequencyOfUse: 123.44,
        frequencyOfUseUnit: 'day',
        appliedPerUseUnit: 'mg',
        appliedPerUse: 123.456775,
        productType: 'Rinse-Off',
        useManual: 'Apply an appropriate amount to face blahblah',
        warnings: 'For external use only',
        productFeature: 'Anti-wrinkle',
        marketingClaims: 'marketing claims',
        miscellaneous: 'miscellaneous',
        pao: 1,
        paoUnit: 'year(s)',
      });
    }
  }, []);

  useEffect(() => {
    if (productSpecifications) {
      let { targetAge, pao, paoUnit, skinType } = productSpecifications;

      let targetAgeUnitDirect = '';
      let skinTypeUnitDirect = '';
      if (
        !productSpecificationsOption.productTargetAgeOptions
          .map(({ value }) => value)
          .includes(targetAge)
      ) {
        targetAgeUnitDirect = targetAge;
        targetAge = 'others';
      }
      if (
        !productSpecificationsOption.skinTypeOptions.map(({ value }) => value).includes(skinType)
      ) {
        skinTypeUnitDirect = skinType;
        skinType = 'others';
      }
      if (paoUnit === 'use immediately') {
        pao = '';
        paoUnit = 'use immediately';
      }
      form.setFieldsValue({
        ...productSpecifications,
        targetAgeUnit: targetAge,
        targetAgeUnitDirect,
        skinTypeUnit: skinType,
        skinTypeUnitDirect,
        pao,
        paoUnit,
      });
    }
  }, [productSpecifications]);

  return useMemo(
    () => ({
      form,
      updateMode,
      getLoading,
      saveLoading,
      fetchLoading: !!(addLoading || updateLoading),
      submitSuccess,
      onSaveDraft,
      onSubmit,
    }),
    [
      form,
      updateMode,
      getLoading,
      saveLoading,
      addLoading,
      updateLoading,
      submitSuccess,
      onSaveDraft,
      onSubmit,
    ],
  );
};

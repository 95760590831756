import { Col, Descriptions, InputRef, Row, Select, Spin } from 'antd';
import qs from 'qs';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AdBannerCarousel from 'components/ad/AdBannerCarousel';
import { Typography } from 'components/system';
import SearchInput from 'components/system/form/SearchInput';
import InfoIcon from 'components/system/general/icon/InfoIcon';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import {
  useAdMaterials,
  useRawMaterialCategories,
  useRawMaterialSearch,
} from 'service/material/rawMaterialSearch';
import { AdProductCode } from 'types/ad';
import { RawMaterialSearchType } from 'types/material/rawMaterialSearch';
import AdRawMaterialSearchTable from './AdRawMaterialSearchTable';
import RawMaterialSearchTable from './RawMaterialSearchTable';

const RawMaterialSearchBlock = styled.div`
  .ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view {
    border-radius: 0;
    border: none;
  }
  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-label,
  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-content {
    border-right: none;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    vertical-align: top;
    padding: 12px 12px 24px 12px;
  }
`;

const EfficacyBlock = styled.div`
  cursor: pointer;
  user-select: none;
`;

const SearchKeywordBlock = styled.div`
  margin-top: 36px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #ededed;
`;

const SearchKeywordLabelBlock = styled.span`
  position: relative;
  border: 1px solid ${palette.PRIMARY50};
  padding: 4px 20px 4px 8px;
  border-radius: 12px;
  background-color: ${palette.PRIMARY10};
  cursor: pointer;

  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 11px;
    right: 6px;
    width: 11px;
    height: 1px;
    background-color: ${palette.SLATE_GRAY70};
    transform: rotate(45deg);
  }

  ::after {
    transform: rotate(-45deg);
  }
`;

const TableContainer = styled.div``;

const RawMaterialSearch = () => {
  const inputRef = useRef<InputRef | null>(null);
  const { efficacies, formulaPurposes, retentionCharacteristics, getLoading } =
    useRawMaterialCategories();
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const inciName = query?.inciName ? (query.inciName as string) : undefined;
  const {
    selectedEfficacies,
    selectEfficacy,
    selectedFormulaPurposes,
    selectFormulaPurpose,
    searchTypeOptions,
    searchType,
    setSearchType,
    searchKeyword,
    setSearchKeyword,
    refreshSearch,
    efficacyIds,
    purposeIds,
    retentionCharacteristicIds,
    selectedCharacteristics,
    selectCharacteristic,
  } = useRawMaterialSearch(inciName);
  const handleChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleSearchType = (searchType: RawMaterialSearchType) => {
    setSearchType(searchType);
    if (inputRef.current) {
      if (inputRef.current.input) inputRef.current.input.value = '';
      inputRef.current.focus();
    }
  };

  const memoizedSearchType = useMemo(
    () => searchType,
    [searchKeyword, inciName, efficacyIds, purposeIds],
  );

  // AD
  const { adRawMaterials, isAdLoading } = useAdMaterials({
    inciName,
    efficacyIds,
    purposeIds,
    retentionCharacteristicIds,
    searchKeyword,
    searchType,
  });

  return (
    <RawMaterialSearchBlock>
      <Spin spinning={getLoading}>
        <Descriptions
          bordered
          column={1}
          labelStyle={{
            width: 130,
            backgroundColor: palette.SLATE_GRAY30,
            borderRight: 'none',
          }}
          style={{ borderCollapse: 'collapse' }}
        >
          <Descriptions.Item label={<Typography.Text medium>효능</Typography.Text>}>
            <Row gutter={[12, 12]}>
              {efficacies.map((efficacy) => (
                <Col flex="0 16%" key={efficacy.efficacyCategoryId}>
                  <EfficacyBlock onClick={() => selectEfficacy(efficacy)}>
                    <Typography.Text
                      type="BODY_2"
                      medium={selectedEfficacies.includes(efficacy)}
                      color={selectedEfficacies.includes(efficacy) ? 'SLATE_GRAY70' : 'GRAY50'}
                      inline
                    >
                      {efficacy.efficacyName}
                    </Typography.Text>
                  </EfficacyBlock>
                </Col>
              ))}
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label={<Typography.Text medium>배합 목적</Typography.Text>}>
            <Row gutter={[12, 12]}>
              {formulaPurposes.map((formulaPurpose) => (
                <Col flex="0 16%" key={formulaPurpose.formulaPurposeCategoryId}>
                  <EfficacyBlock onClick={() => selectFormulaPurpose(formulaPurpose)}>
                    <Typography.Text
                      type="BODY_2"
                      medium={selectedFormulaPurposes.includes(formulaPurpose)}
                      color={
                        selectedFormulaPurposes.includes(formulaPurpose) ? 'SLATE_GRAY70' : 'GRAY50'
                      }
                      inline
                    >
                      {formulaPurpose.formulaPurposeName}
                    </Typography.Text>
                  </EfficacyBlock>
                </Col>
              ))}
            </Row>
          </Descriptions.Item>
          <Descriptions.Item
            style={{ paddingBottom: 12 }}
            label={<Typography.Text medium>자주 찾는 키워드</Typography.Text>}
          >
            <Row gutter={[12, 12]}>
              {retentionCharacteristics.map((characteristic) => (
                <Col flex="0 16%" key={characteristic.materialCategoryId}>
                  <EfficacyBlock onClick={() => selectCharacteristic(characteristic)}>
                    <Typography.Text
                      type="BODY_2"
                      medium={
                        selectedCharacteristics.find(
                          (selectedCharacteristic) =>
                            selectedCharacteristic.materialCategoryId ===
                            characteristic.materialCategoryId,
                        ) !== undefined
                      }
                      color={
                        selectedCharacteristics.find(
                          (selectedCharacteristic) =>
                            selectedCharacteristic.materialCategoryId ===
                            characteristic.materialCategoryId,
                        ) !== undefined
                          ? 'SLATE_GRAY70'
                          : 'GRAY50'
                      }
                      inline
                    >
                      {characteristic.nameEn}
                    </Typography.Text>
                  </EfficacyBlock>
                </Col>
              ))}
            </Row>
          </Descriptions.Item>
        </Descriptions>
      </Spin>
      <AdBannerCarousel productCode={AdProductCode.MATERIAL_SEARCH} />
      <Row justify="center" gutter={4} wrap={false} style={{ marginTop: 16, marginBottom: 24 }}>
        <Col>
          <InfoIcon />
        </Col>
        <Col>
          <Typography.Text align="center" medium color="SLATE_GRAY70">
            효능·배합 목적에서 원하시는 단어가 없다면 아래 ‘전체’ 검색에서 자유롭게 검색할 수
            있습니다.
          </Typography.Text>
        </Col>
      </Row>
      <Row style={{ maxWidth: 560, marginLeft: 'auto', marginRight: 'auto' }} wrap={false}>
        <Col flex="1 1 200px" style={{ marginRight: 4 }}>
          <Select
            value={searchType}
            options={searchTypeOptions}
            onChange={handleSearchType}
            style={{ width: '100%' }}
          />
        </Col>
        <Col flex="1 1 360px" style={{ marginRight: 16 }}>
          <SearchInput
            inputRef={inputRef}
            defaultValue={searchKeyword}
            onSearch={handleChangeSearchKeyword}
          />
        </Col>
      </Row>
      {(selectedEfficacies.length > 0 ||
        selectedFormulaPurposes.length > 0 ||
        selectedEfficacies.length > 0 ||
        selectedCharacteristics.length > 0 ||
        searchKeyword !== '') && (
        <SearchKeywordBlock>
          <Row gutter={16} wrap={false}>
            <Col flex="0 0 92px" style={{ cursor: 'pointer' }}>
              <Typography.Text
                type="BODY_2"
                inline
                style={{
                  color: palette.GRAY80,
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={refreshSearch}
              >
                <Icon name="refresh" color="GRAY80" size={16} style={{ marginRight: 4 }} />
                전체 해제
              </Typography.Text>
            </Col>
            <Col>
              <Row gutter={[8, 8]}>
                {selectedEfficacies.map((efficacy) => (
                  <Col key={efficacy.efficacyCategoryId}>
                    <SearchKeywordLabelBlock onClick={() => selectEfficacy(efficacy)}>
                      {efficacy.efficacyName}
                    </SearchKeywordLabelBlock>
                  </Col>
                ))}
                {selectedFormulaPurposes.map((formulaPurpose) => (
                  <Col key={formulaPurpose.formulaPurposeCategoryId}>
                    <SearchKeywordLabelBlock onClick={() => selectFormulaPurpose(formulaPurpose)}>
                      {formulaPurpose.formulaPurposeName}
                    </SearchKeywordLabelBlock>
                  </Col>
                ))}
                {selectedCharacteristics.map((characteristic) => (
                  <Col key={characteristic.materialCategoryId}>
                    <SearchKeywordLabelBlock onClick={() => selectCharacteristic(characteristic)}>
                      {characteristic.nameEn}
                    </SearchKeywordLabelBlock>
                  </Col>
                ))}
                {searchKeyword !== '' && (
                  <Col>
                    <SearchKeywordLabelBlock onClick={() => handleChangeSearchKeyword('')}>
                      {searchKeyword}
                    </SearchKeywordLabelBlock>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </SearchKeywordBlock>
      )}
      <TableContainer>
        {adRawMaterials && adRawMaterials.length > 0 && (
          <AdRawMaterialSearchTable adRawMaterials={adRawMaterials} isAdLoading={isAdLoading} />
        )}
        <RawMaterialSearchTable
          inciName={inciName}
          efficacyIds={efficacyIds}
          purposeIds={purposeIds}
          retentionCharacteristicIds={retentionCharacteristicIds}
          searchKeyword={searchKeyword}
          searchType={memoizedSearchType}
        />
      </TableContainer>
    </RawMaterialSearchBlock>
  );
};

export default RawMaterialSearch;

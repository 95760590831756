import { Button, Col, Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ColumnsType } from 'antd/lib/table';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import ManagerUpdateContainer from 'containers/auth/ManagerUpdateContainer';
import palette from 'lib/styles/palette';
import { useUserType } from 'service/auth';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { Manager, UserType } from 'types/auth';

const ManagerListBlock = styled.div`
  & > .ant-table-wrapper table {
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  .ant-table-row {
    cursor: pointer;

    & > .ant-table-cell {
      border: none;
      background-color: #f6f7f9;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-left: 36px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 36px;
      }
    }
  }

  .ant-table-expanded-row {
    & > .ant-table-cell {
      background-color: #fff;
      border-bottom: none;
    }
  }

  .ant-table-title {
    padding: 0px;
  }

  .ant-tabs-tab {
    width: 160px;
    margin-right: 0px;
    justify-content: center;
  }

  .ant-table-wrapper tr.ant-table-expanded-row:hover > td {
    background-color: transparent;
  }
`;

const TableTitle = styled.div`
  display: flex;
  padding: 10px 36px;
  height: 40px;
  background-color: ${palette.PRIMARY20};
`;

enum ManagerTabKey {
  ALL = 'ALL',
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
}

const ManagerList = ({
  getLoading = false,
  managers: initialManagers,
  onRegisterManager,
}: {
  getLoading: boolean;
  managers: Manager[];
  onRegisterManager: () => void;
}) => {
  const [managers, setManagers] = useState<Manager[]>();
  const { data: manufacturers = [] } = useManufacturerCompanies();
  useEffect(() => {
    if (initialManagers.length > 0) {
      setManagers(initialManagers);
    }
  }, [initialManagers]);

  const handleTabClick = (key: ManagerTabKey) => {
    if (key === ManagerTabKey.ALL) {
      setManagers(initialManagers);
    } else {
      setManagers(
        initialManagers.filter((manager) =>
          key === ManagerTabKey.BRAND
            ? !(manager.userType === UserType.MANUFACTURE)
            : manager.userType === UserType.MANUFACTURE,
        ),
      );
    }
  };
  const columns: ColumnsType<Manager> = [
    {
      width: 200,
      render: (_, { company, manufacturerId }) => (
        <Typography.Text type="BODY_2">
          {manufacturerId
            ? manufacturers.find((manu) => manu.manufacturerId === manufacturerId)?.companyNameKo
            : company.companyNameKo}
        </Typography.Text>
      ),
    },
    {
      width: 200,
      dataIndex: 'deptName',
    },
    {
      width: 200,
      render: (_, { username, name }) => (
        <Typography.Text type="BODY_2" style={{ maxWidth: 200 }}>
          {name} ({username})
        </Typography.Text>
      ),
    },
    { dataIndex: 'positionName', width: 160 },
  ];
  const userType = useUserType();

  return (
    <ManagerListBlock>
      {userType === UserType.BRAND && (
        <Tabs
          onTabClick={(key) => handleTabClick(key as ManagerTabKey)}
          items={[
            {
              key: ManagerTabKey.ALL,
              label: '전체',
            },
            {
              key: ManagerTabKey.BRAND,
              label: '브랜드사',
            },
            {
              key: ManagerTabKey.MANUFACTURE,
              label: '제조사',
            },
          ]}
        />
      )}
      <Table
        columns={columns}
        dataSource={managers}
        loading={getLoading}
        showHeader={false}
        rowKey="userId"
        title={() => (
          <TableTitle>
            <Typography.Text type="BODY_2" style={{ marginRight: 154 }}>
              회사명
            </Typography.Text>
            <Typography.Text type="BODY_2" style={{ marginRight: 186 }}>
              부서명
            </Typography.Text>
            <Typography.Text type="BODY_2" style={{ marginRight: 150 }}>
              이름 (아이디)
            </Typography.Text>
            <Typography.Text type="BODY_2">직급</Typography.Text>
          </TableTitle>
        )}
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: 4,
          expandIcon: ({ expanded, record, onExpand }) => (
            <Icon
              name={expanded ? 'up' : 'down'}
              color="GRAY70"
              size={20}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)}
            />
          ),
          expandedRowRender: (manager) => (
            <ManagerUpdateContainer manager={manager} directMode={undefined} />
          ),
        }}
        footer={() => (
          <Row justify="end" style={{ marginTop: 12 }}>
            <Col>
              <Button
                onClick={onRegisterManager}
                icon={<Icon name="plus" color="PRIMARY50" size={14} />}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                담당자 추가
              </Button>
            </Col>
          </Row>
        )}
      />
    </ManagerListBlock>
  );
};

export default ManagerList;

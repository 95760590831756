import { useLocation, useNavigate } from 'react-router-dom';

import { NMPAMaterialProgressStatus, NMPAMaterialListPageState } from 'types/material/nmpa/nmpa';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import NMPAMaterialList from 'components/material/nmpa/NMPAMaterialList';

const NMPAMaterialListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageState: NMPAMaterialListPageState = {
    ...{
      page: 1,
      progressStatus: searchParams.get('type') || NMPAMaterialProgressStatus.INPUTTING,
    },
    ...(location.state || {}),
  };

  const handlePageStateChange = (state: NMPAMaterialListPageState) => {
    navigate(path.material.nmpa.material.list, { state });
  };

  return (
    <PageTemplate
      title="원료 자료 입력"
      description="원료의 정보 입력을 완료하시면, 원료의 신고번호 개별 취득이 가능합니다."
      back={false}
    >
      <NMPAMaterialList pageState={pageState} onPageStateChange={handlePageStateChange} />
    </PageTemplate>
  );
};

export default NMPAMaterialListPage;

import styled from 'styled-components';

import ContractAccount from 'components/contract/ContractAccount';
import ContractAddress from 'components/contract/ContractAddress';
import ContractSendGuide from 'components/contract/ContractSendGuide';
import ContractDocuments from './ContractDocuments';
import NMPACompanyInfoGuide from './NMPACompanyInfoGuide';

const ContractGuideBlock = styled.div``;

const ContractGuide = ({ isNMPAEstimate = false }: { isNMPAEstimate?: boolean }) => {
  return (
    <ContractGuideBlock>
      {!isNMPAEstimate && <ContractDocuments />}
      <ContractAccount />
      <ContractAddress />
      <ContractSendGuide />
      {isNMPAEstimate && <NMPACompanyInfoGuide />}
    </ContractGuideBlock>
  );
};

export default ContractGuide;

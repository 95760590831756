import { Row, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import SearchInput from 'components/system/form/SearchInput';
import Typography from 'components/system/general/Typography';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useNMPAOngoingMaterials } from 'service/material/nmpa/nmpa';
import { DocumentStatus } from 'types/common';
import { NMPAOngoingMaterial, NMPAOngoingPageState } from 'types/material/nmpa/nmpa';

const Container = styled.div`
  .ant-table-container table > thead > tr:first-child {
    th:first-child {
      padding-left: 16px;
    }
    th:last-child {
      padding-right: 16px;
    }
  }
  .ant-table-thead > tr > th {
    padding: 10px 8px;
  }

  .ant-table-tbody > tr > td {
    padding: 10px 8px;
  }
`;

const NMPAOngoing = ({
  pageState,
  onChangePageState,
}: {
  pageState: NMPAOngoingPageState;
  onChangePageState: (state: NMPAOngoingPageState) => void;
}) => {
  const navigate = useNavigate();
  const { tradeName, page } = pageState;
  const { nmpaOngoingMaterials, total, isLoading } = useNMPAOngoingMaterials({
    page,
    tradeName,
  });

  const handlePageChange = useCallback(
    (page: number) => {
      onChangePageState({
        page,
        tradeName,
      });
    },
    [tradeName],
  );

  const handleSearch = useCallback((tradeName: string) => {
    onChangePageState({
      page: 1,
      tradeName,
    });
  }, []);

  const columns: ColumnsType<NMPAOngoingMaterial> = useMemo(
    () => [
      {
        title: '취득 신청 번호 / 취득 신청일',
        width: 220,
        align: 'center',
        render: (_, { applicationCode, applicationDt }) => (
          <Row style={{ flexDirection: 'column' }} align="middle">
            <Typography.Text medium type="BODY_2" color="GRAY90">
              {applicationCode}
            </Typography.Text>
            <Typography.Text type="BODY_2" color="GRAY70">
              {`(${applicationDt?.slice(0, 10)})`}
            </Typography.Text>
          </Row>
        ),
      },
      {
        title: '원료명(상품명)',
        render: (_, { tradeName, materialNmpaBasicInfoId }) => (
          <Typography.Text
            type="BODY_2"
            style={{
              width: 240,
              fontWeight: 500,
              color: palette.GRAY90,
            }}
            onClick={() =>
              navigate(`${path.material.nmpa.material.root}/${materialNmpaBasicInfoId}`)
            }
          >
            {tradeName || '-'}
          </Typography.Text>
        ),
      },
      {
        title: '진행 단계',
        width: 176,
        align: 'center',
        render: (_, { status, materialNmpaBasicInfoId }) =>
          status === DocumentStatus.MOD ? (
            <Tags.Mark
              type="arrow"
              color="blue"
              onClick={() =>
                navigate(`${path.material.nmpa.material.root}/${materialNmpaBasicInfoId}`)
              }
            >
              보완 필요
            </Tags.Mark>
          ) : (
            <Typography.Text
              type="BODY_2"
              style={{
                width: 176,
              }}
              color="GRAY90"
            >
              검토중
            </Typography.Text>
          ),
      },
    ],
    [],
  );

  return (
    <Container>
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <SearchInput
          placeholder="원료명(상품명) 검색"
          defaultValue={tradeName}
          onSearch={handleSearch}
          style={{ width: 400 }}
        />
      </Space>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={nmpaOngoingMaterials}
        rowKey="materialNmpaBasicInfoId"
        pagination={{
          current: page,
          onChange: handlePageChange,
          total,
        }}
        style={{ marginTop: 24 }}
      />
    </Container>
  );
};

export default NMPAOngoing;

import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import * as ciApi from 'lib/api/material/compositionInformation';

export const useCompositionInformation = (materialId: number) => {
  const {
    data: compositionInformations = [],
    refetch,
    isFetching: getLoading,
  } = useQuery(
    [`materials/rawMaterial/getCompositionInformation`, materialId],
    () => ciApi.getCompositionInformation(materialId!),
    {
      select: (res) => res.data.result,
    },
  );

  const { mutate: uploadCompositionInformation, isLoading: uploadLoading } = useMutation(
    (params: { materialId: number; file: File }) => ciApi.uploadCompositionInformation(params),
    {
      onSuccess: (res) => {
        if (res.data.result.invalidCodes.length === 0) {
          refetch();
        }
      },
    },
  );

  return useMemo(
    () => ({
      compositionInformations,
      getLoading,
      uploadCompositionInformation,
      uploadLoading,
    }),
    [compositionInformations, getLoading, uploadLoading],
  );
};

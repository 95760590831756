import NMPADetail from 'components/material/nmpa/NMPADetail';
import { useParams } from 'react-router-dom';

import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';

const NMPADetailPage = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);

  return (
    <NMPAPageTemplate
      documentName="상세 정보"
      title="원료 신고 정보"
      notices={[
        '필수로 입력하셔야 하는 항목을 다 입력하셔야 등록이 가능하며, 그전에는 임시저장이 가능합니다.',
        '작성해주신 내용에 따라 근거 자료 요청 및 보완 사항이 발생할 수 있습니다.',
        '입력된 내용은 취득 진행에 적합한 내용으로 수정될 수 있습니다.',
      ]}
    >
      <NMPADetail materialId={materialId} />
    </NMPAPageTemplate>
  );
};

export default NMPADetailPage;

import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { DocumentCode } from 'types/brand/retailer/retailerApply';

const ProductCRCPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="신용평가 확인서"
      notices={[
        '협력업체를 선정할 때 회사의 신용능력을 검증하는 자료입니다.',
        '민간기업용으로 발급 받은 후 업로드 해주세요.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload
        productId={productId}
        documentCode={DocumentCode.CRC}
        accept="application/pdf"
      />
    </ProductPageTemplate>
  );
};

export default ProductCRCPage;

import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { DocumentCode } from 'types/brand/retailer/retailerApply';

const ProductLPBPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="채권포기 확약서"
      notices={[
        '해당 서류는 개인사업자이면서 공동대표인 경우에만 필요한 서류입니다.',
        <MistakeNoticeItem />,
      ]}
    >
      <DocumentsUpload documentCode={DocumentCode.LPB} accept="application/pdf" />
    </ProductPageTemplate>
  );
};

export default ProductLPBPage;

import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { checkMobile } from 'lib/common';
import palette from 'lib/styles/palette';
import InfoIcon from '../general/icon/InfoIcon';

const TipBlock = styled.div<{
  $isMobile: boolean;
}>`
  position: ${({ $isMobile }) => ($isMobile ? 'static' : 'relative')};
  vertical-align: middle;
`;

const IconWrap = styled.div``;

const TipBodyBlock = styled(Flex)<{
  $visible: boolean;
  $isMobile: boolean;
  $trigger: 'hover' | 'click';
}>`
  width: auto;
  // HINT: 데스크탑의 경우 opacity로 on / off
  opacity: ${({ $isMobile, $visible }) => ($isMobile || $visible ? 1 : 0)};
  // HINT: 모바일의 경우 display로 on / off
  display: ${({ $isMobile, $visible }) => (!$isMobile || $visible ? 'flex' : 'none')};
  transform-origin: left top;
  transform: scale(${({ $isMobile, $visible }) => ($isMobile || $visible ? 1 : 0)});
  ${({ $isMobile }) =>
    !$isMobile
      ? css`
          position: absolute;
          top: 0;
          left: calc(100% + 8px);
          transition: opacity 0.2s ease, transform 0.1s ease;
        `
      : css`
          position: fixed;
          top: 50%;
          left: 50%;
          max-width: calc(100vw - 32px);
          transform: translate(-50%, -50%);
          opacity: 1;
        `}
  color: ${palette.GRAY90};
  min-width: 50px;
  z-index: 10;
  white-space: nowrap;
  padding: 8px 16px;
  border: 1px solid ${palette.PRIMARY50};
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(162, 162, 162, 0.23);
  font-size: 12px;
`;

interface Props {
  children?: React.ReactNode;
  icon?: React.ReactChild;
  trigger?: 'hover' | 'click';
  isVisible?: boolean;
  isBodyVisible?: boolean;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  visibleCloseIcon?: boolean;
  closeIconStyle?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const Tip = ({
  children,
  icon,
  trigger = 'hover',
  isVisible,
  isBodyVisible = true,
  style,
  iconStyle,
  bodyStyle,
  visibleCloseIcon = true,
  closeIconStyle,
  onClick,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const isMobile = checkMobile();

  useEffect(() => {
    setVisible(!!isVisible);
  }, [isVisible]);
  return (
    <TipBlock style={style} $isMobile={isMobile}>
      <IconWrap
        onClick={() => {
          (isMobile || trigger === 'click') && setVisible((draft) => !draft);
        }}
        onMouseEnter={() => !isMobile && trigger === 'hover' && setVisible(true)}
        onMouseLeave={() => !isMobile && trigger === 'hover' && setVisible(false)}
      >
        {icon ? icon : <InfoIcon style={{ cursor: 'pointer', ...iconStyle }} onClick={onClick} />}
      </IconWrap>
      {isBodyVisible && (
        <TipBodyBlock
          $visible={visible}
          $isMobile={isMobile}
          $trigger={trigger}
          gap={8}
          justify="space-between"
          style={bodyStyle}
        >
          <div>{children}</div>
          {(isMobile || trigger === 'click') && visibleCloseIcon && (
            <Icon
              name="close"
              color="PRIMARY50"
              size={16}
              onClick={(e) => {
                e.stopPropagation();

                (isMobile || trigger === 'click') && setVisible(false);
              }}
              style={closeIconStyle}
            />
          )}
        </TipBodyBlock>
      )}
    </TipBlock>
  );
};

export default Tip;

import { useMemo } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductImages from 'components/product/ProductImages';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = [
  '해당 이미지는 유럽 CPNP 포털에 실제로 업로드될 이미지로, 실제 판매용 제품과 동일하게 유럽용 라벨을 부착하신 제품의 사진을 업로드해 주세요.',
  '단상자가 있는 제품의 경우, 1차 포장 용기와 2차 포장 용기를 하나의 사진으로 업로드해 주세요.',
  '이미지 형식 (jpg, jpeg, pdf 등) 으로 최대 1개의 파일만 업로드가 가능합니다.',
  <MistakeNoticeItem />,
];

const ProductImagesPage = () => {
  const countryId = useCountryId();
  const currentProduct = useCurrentProduct();

  const currentCountry = useMemo(
    () =>
      currentProduct?.productCountries?.find(
        (productCountry) => productCountry.countryId === countryId,
      ),
    [currentProduct, countryId],
  );

  const isEngland = useMemo(() => currentCountry?.country?.countryCode === 'GB', [currentCountry]);

  const newNotices = useMemo(() => {
    if (!isEngland) return notices;

    const newNotices = [...notices];
    newNotices[0] = newNotices[0]
      .toString()
      .replace('유럽 CPNP', '영국 SCPN')
      .replace('유럽', '영국');
    return newNotices;
  }, [isEngland]);

  return (
    <ProductPageTemplate
      subtitle="Product Images"
      notices={newNotices}
      exampleImg="Product_Images.png"
    >
      <ProductImages />
    </ProductPageTemplate>
  );
};

export default ProductImagesPage;

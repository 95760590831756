import { Button, Col, message, Modal, Row, Select, Space } from 'antd';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/system';
import { RegisterSearchType, RegisterStatus, MaterialRegister } from 'types/material/register';
import SearchInput from 'components/system/form/SearchInput';
import { useMaterialRegisterManage } from 'service/material/manage';
import palette from 'lib/styles/palette';
import { registerManageSearchTypeOptions } from 'lib/consts';
import path from 'lib/path';
import { MaterialRegisterMangeSearchParams } from 'pages/material/MaterialRegisterManagePage';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const MaterialDoneBlock = styled.div``;

const StyledBox = styled.div<{ isDone: boolean }>`
  height: 24px;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: ${(props) => (props.isDone ? palette.GRAY30 : '#ffe6e6')};
  color: ${(props) => (props.isDone ? palette.GRAY50 : palette.MESSAGE_ERROR)};
`;

const MaterialDone = ({
  onChangeQueryParams,
  queryParams,
}: {
  queryParams: URLSearchParams;
  onChangeQueryParams: (queryParams: Partial<MaterialRegisterMangeSearchParams>) => void;
}) => {
  const navigate = useNavigate();
  const searchKeyword = queryParams.get('searchKeyword') || '';
  const searchType =
    (queryParams.get('searchType') as RegisterSearchType) || RegisterSearchType.ALL;
  const page = Number(queryParams.get('page')) || 1;

  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);
  const [searchTypeBuffer, setSearchTypeBuffer] = useState<RegisterSearchType>(
    RegisterSearchType.ALL,
  );

  const {
    materialRegisters,
    getMaterialRegistersLoading,
    discontinueMaterials,
    discontinueMaterialsLoading,
  } = useMaterialRegisterManage({
    status: RegisterStatus.RM_REG,
    page,
    searchType,
    searchKeyword,
  });

  const handleClickMaterial = (materialId: number) => {
    navigate(`${path.material.rawMaterial.root}/${materialId}`);
  };

  const handleDiscontinueMaterials = () => {
    if (!selectedMaterialIds.length) return;
    Modal.confirm({
      style: { textAlign: 'center' },
      title: '해당 원료를 단종 처리하시겠습니까?',
      content:
        '원료 정보는 현재와 같이 유지되지만 단종 원료이므로, 제조사로부터 원료 자료 요청을 받을 수 없게 됩니다.',
      icon: null,
      onOk: () => {
        discontinueMaterials(selectedMaterialIds);
        setSelectedMaterialIds([]);
        message.success('단종 처리되었습니다.');
      },
    });
  };

  const handleSearch = (searchKeyword: string) => {
    onChangeQueryParams({
      page: 1,
      searchType: searchTypeBuffer,
      searchKeyword,
    });
  };

  const handleChangePage = (page: number) => {
    onChangeQueryParams({ page });
  };

  const columns: ColumnsType<MaterialRegister> = useMemo(
    () => [
      {
        title: '원료명',
        dataIndex: 'materialNameEn',
        align: 'center',
        render: (materialNameEn, record) => (
          <Row align="middle" gutter={[8, 8]}>
            <Col>
              <Typography.Text
                type="BODY_2"
                color="GRAY90"
                style={{ fontWeight: 500, cursor: 'pointer' }}
                onClick={() => handleClickMaterial(record.materialId)}
              >
                {materialNameEn}
              </Typography.Text>
            </Col>
            {record.isDiscontinued && (
              <Col>
                <StyledBox isDone>단종 원료</StyledBox>
              </Col>
            )}
            {!record.isDiscontinued &&
              record.uploadExpiredDate !== null &&
              typeof record.uploadExpiredDate !== 'undefined' &&
              record.uploadExpiredDate >= 0 && (
                <Col>
                  <StyledBox isDone={false}>
                    {record.uploadExpiredDate
                      ? `업로드 만료 D-${record.uploadExpiredDate}`
                      : '업로드 만료일'}
                  </StyledBox>
                </Col>
              )}
          </Row>
        ),
      },
      {
        title: '원료 생산 회사',
        align: 'center',
        dataIndex: 'originManufacturerName',
      },
      {
        title: '원료 등록일',
        align: 'center',
        dataIndex: 'finishRegisterDt',
        render: (finishRegisterDt) => {
          return finishRegisterDt ? finishRegisterDt.replace('T', ' ') : '-';
        },
      },
      {
        title: '원료 등록자',
        align: 'center',
        dataIndex: 'finishRegisterName',
        render: (finishRegisterName, record) => (
          <div>{`${finishRegisterName || '-'} ${
            record.finishRegisterDeptName ? `(${record.finishRegisterDeptName})` : ''
          }`}</div>
        ),
      },
    ],
    [],
  );

  return (
    <MaterialDoneBlock>
      <Space
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          marginBottom: 24,
        }}
      >
        <Select
          options={registerManageSearchTypeOptions}
          value={searchTypeBuffer}
          onChange={setSearchTypeBuffer}
          style={{ width: 140 }}
        />
        <SearchInput
          defaultValue={searchKeyword}
          placeholder="검색"
          onSearch={handleSearch}
          style={{ width: 280 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={materialRegisters?.content}
        rowKey={({ materialId }) => materialId}
        pagination={{
          current: page,
          pageSize: materialRegisters?.size,
          total: materialRegisters?.totalElements,
          onChange: handleChangePage,
        }}
        rowSelection={{
          selectedRowKeys: selectedMaterialIds,
          onChange: (value) => {
            setSelectedMaterialIds(value as number[]);
          },
        }}
        loading={getMaterialRegistersLoading || discontinueMaterialsLoading}
        footer={() => (
          <Space
            align="center"
            style={{
              marginTop: '16px',
              width: '100%',
            }}
          >
            <Button
              type="default"
              icon={<Icon size={18} name="flaskDelete" color="PRIMARY50" rectColor="PRIMARY50" />}
              onClick={handleDiscontinueMaterials}
            >
              단종 처리
            </Button>
          </Space>
        )}
      />
    </MaterialDoneBlock>
  );
};

export default MaterialDone;

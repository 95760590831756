import { Button, Form, Input, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import FooterBox from 'components/FooterBox';

import { useBuyer } from 'hook/buyer';
import { emailRule, exceptKoreanRule, numbersRule, phoneRule, requireRule } from 'lib/validate';

const BuyerDetailModal = () => {
  const { buyer, form, countries, updateLoading, setBuyer, updateBuyer } = useBuyer();
  return (
    <Modal
      title="바이어 수정"
      open={buyer !== null}
      destroyOnClose
      footer={null}
      onCancel={() => setBuyer(null)}
    >
      <Form form={form} layout="vertical" onFinish={updateBuyer}>
        <Form.Item name="countryId" label="국가" rules={[requireRule]}>
          <Select
            options={countries.map(({ countryId, countryNameKo }) => ({
              label: countryNameKo,
              value: countryId,
            }))}
          />
        </Form.Item>
        <Form.Item name="companyName" label="회사명 (영문)" rules={[requireRule, exceptKoreanRule]}>
          <Input />
        </Form.Item>
        <Form.Item shouldUpdate={(prev, curr) => prev.countryId !== curr.countryId}>
          {({ getFieldValue }) => (
            <>
              {getFieldValue('countryId') === 5 && (
                <Form.Item
                  name="countryCompanyName"
                  label="회사명 (일본어)"
                  rules={[
                    requireRule,
                    exceptKoreanRule,
                    {
                      type: 'string',
                      max: 30,
                      message: '최대 30자',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item
                name="companyTel"
                label="전화번호"
                rules={[requireRule, numbersRule, phoneRule]}
              >
                <Input placeholder="숫자만 입력해 주세요" />
              </Form.Item>
              <Form.Item
                name="companyAddress"
                label="주소 (영문)"
                rules={[requireRule, exceptKoreanRule]}
              >
                <Input placeholder="국가를 포함한 주소를 입력해 주세요." />
              </Form.Item>
              {getFieldValue('countryId') === 5 && (
                <Form.Item
                  name="countryCompanyAddress"
                  label="주소 (일본어)"
                  rules={[
                    requireRule,
                    exceptKoreanRule,
                    {
                      type: 'string',
                      max: 100,
                      message: '최대 100자',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </>
          )}
        </Form.Item>
        <Form.Item name="ceoName" label="대표 성함 (영문)" rules={[requireRule, exceptKoreanRule]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="representativeName"
          label="담당자 성함 (영문)"
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="companyEmail" label="이메일" rules={[requireRule, emailRule]}>
          <Input />
        </Form.Item>
        <FooterBox>
          <Button type="primary" loading={updateLoading} htmlType="submit">
            수정
          </Button>
        </FooterBox>
      </Form>
    </Modal>
  );
};

export default BuyerDetailModal;

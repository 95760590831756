import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';

import ProductCGMP from 'components/product/ProductCGMP';

const ManuProductCGMPContainer = () => {
  const factory = useSelector(({ company, product }) =>
    company.factories.find(
      ({ companyFactoryId }) => companyFactoryId === product.currentProduct.companyFactoryId,
    ),
  );
  const [form] = Form.useForm();
  const file = useMemo(
    () => ({
      name: 'Uploaded File.pdf',
      url: factory.cgmpUploadFileUrl || factory.iso22716UploadFileUrl,
    }),
    [],
  );
  useEffect(() => {
    form.setFieldsValue({
      isCGMP: !!factory.cgmpUploadFileUrl,
      ISOExpiredDate: factory.iso22716ExpireDate
        ? factory.iso22716ExpireDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
        : '',
    });
  }, [factory]);
  return <ProductCGMP manuMode form={form} file={file} />;
};

export default ManuProductCGMPContainer;

import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import EstimateList from 'components/estimate/EstimateList';
import * as estimateActions from 'modules/estimate';
import { useModal } from 'hook/useModal';

const EstimateListContainer = () => {
  const dispatch = useDispatch();
  const { companyId, estimates, getLoading } = useSelector(
    ({ auth, estimate, loading }) => ({
      companyId: auth.user.companyId,
      estimates: estimate.estimates,
      getLoading: loading['estimate/GET_ESTIMATES'],
    }),
    shallowEqual,
  );
  const [page, setPage] = useState(1);
  const { openDocumentPreviewModal, openEstimateHistoriesModal, openEstimateAdditionalsModal } =
    useModal();

  const getEstimates = (page) => {
    setPage(page);
    dispatch(
      estimateActions.getEstimates({
        companyId,
        pageNo: page,
      }),
    );
  };

  useEffect(() => {
    getEstimates(1);
  }, []);
  return (
    <>
      <EstimateList
        estimates={estimates}
        page={page}
        getLoading={getLoading}
        onChangePage={getEstimates}
        onPreview={(fileUrl) =>
          openDocumentPreviewModal({
            documentUrls: fileUrl,
          })
        }
        onShowEstimateHistories={(estimateId) =>
          openEstimateHistoriesModal({
            estimateId,
            type: 'brand',
          })
        }
        onShowEstimateAdditionals={(estimateAdditionals) =>
          openEstimateAdditionalsModal({
            estimateAdditionals: estimateAdditionals.map(
              ({ estimateAdditionalCode, registerDt, estimatePrice, fileUrl }) => ({
                estimateAdditionalCode,
                registerDt,
                estimatePrice,
                fileUrl,
              }),
            ),
          })
        }
      />
    </>
  );
};

export default EstimateListContainer;

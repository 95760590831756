import { useState } from 'react';
import { Button, Col, Form, Radio, Row, Spin } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import UpdateLog from 'components/product/UpdateLog';
import { useProductKeepWarning } from 'service/brand/product/keepWarning';
import { requireRule } from 'lib/validate';
import { s3AssetDomain } from 'lib/consts';
import { DocumentCode } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

const ProductKeepWarningBlock = styled.div`
  .ant-radio-wrapper {
    margin: 16px 0 0;
    line-height: 24px;

    .ant-radio {
      top: -1.5px;
    }
  }
`;

const RadioWrapper = styled.div`
  width: 336px;
  height: 223px;
  text-align: center;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProductKeepWarning = ({ productId, countryId }: { productId: number; countryId: number }) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { form, updateMode, getLoading, fetchLoading, onSubmit } = useProductKeepWarning(
    productId,
    countryId,
  );
  const readOnlyMode = useSelector(({ certificate }: any) => certificate.readOnlyMode);
  return (
    <ProductKeepWarningBlock>
      <Row justify="end" gutter={8} style={{ marginBottom: 16 }}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.KW}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog productId={productId} countryId={countryId} documentCode={DocumentCode.KW} />
          </Col>
        )}
      </Row>
      <Spin spinning={getLoading}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item name="productLine" rules={[requireRule]}>
            <Radio.Group disabled={readOnlyMode} style={{ justifyContent: 'space-between' }}>
              <RadioWrapper>
                <Image src={`${s3AssetDomain}/cap_cover.png`} alt="Image" />
                <Radio value="LID_PRODUCT">캡/뚜껑 있는 제품</Radio>
              </RadioWrapper>
              <RadioWrapper>
                <Image src={`${s3AssetDomain}/maskpack.png`} alt="Image" />
                <Radio value="MASK_PACK">마스크팩</Radio>
              </RadioWrapper>
              <RadioWrapper>
                <Image src={`${s3AssetDomain}/keep_warning_basic.png`} alt="Image" />
                <Radio value="NOT_APPLICABLE">해당 없음</Radio>
              </RadioWrapper>
            </Radio.Group>
          </Form.Item>
          <FooterBox style={{ paddingTop: 80 }}>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              {updateMode && <CorrectRequestWithoutChangeButton documentCode={DocumentCode.KW} />}
              <Button loading={fetchLoading} type="primary" htmlType="submit">
                {!updateMode ? '등록' : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Spin>
    </ProductKeepWarningBlock>
  );
};

export default ProductKeepWarning;

import React from 'react';

import Icon from 'components/ui/Icon/Icon';

const InfoIcon = ({
  style,
  onClick,
}: {
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
}) => {
  return <Icon name="info" size={16} style={{ height: '100%', ...style }} onClick={onClick} />;
};

export default InfoIcon;

import palette from 'lib/styles/palette';
import styled from 'styled-components';

const CloseCircleIconBlock = styled.div.attrs({
  className: 'close-circle-icon',
})`
  position: relative;
  width: 16px;
  height: 16px;
  background-color: ${palette.SLATE_GRAY70};
  border-radius: 50%;

  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 8px;
    height: 1px;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: 0 0;
    content: '';
    background-color: #fff;
  }

  &:before {
    position: absolute;
    display: block;
    width: 8px;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: '';
    background-color: #fff;
  }
`;

const CloseCircleIcon = () => {
  return <CloseCircleIconBlock />;
};

export default CloseCircleIcon;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { useCountryId } from 'service/brand/product/product';
import { APIPageableResponse } from 'types/common';
import client from 'lib/api/client';
import { CertificateListItem } from 'types/certificate';
import { SearchType } from 'pages/certificate/CertificateCompletePage';

const queryKeys = {
  getCertificates: 'cert-targets/all',
};

export const useCertificates = ({
  isFinish,
  page,
  searchType,
  searchValue,
  finishDateLower,
  finishDateUpper,
}: {
  isFinish: boolean;
  page: number;
  searchType: SearchType;
  searchValue: string;
  finishDateLower?: string;
  finishDateUpper?: string;
}) => {
  const companyId = useSelector(({ auth }: any) => auth.user.companyId);

  const { data: certificates, isLoading } = useQuery(
    [queryKeys.getCertificates, companyId, isFinish],
    () =>
      client.post<APIPageableResponse<CertificateListItem[]>>(`/v1/cert-targets/all/${page}/10`, {
        ...(finishDateLower && { certStartDate: finishDateLower }),
        ...(finishDateUpper && { certEndDate: finishDateUpper }),
        ...(searchType === SearchType.PRODUCT_NAME_EN && {
          productNameEn: searchValue,
        }),
        ...(searchType === SearchType.COUNTRY_NAMEK_KO && {
          countryNameKo: searchValue,
        }),
        ...(searchType === SearchType.ALL && {
          allSearchText: searchValue,
          isAllSearch: true,
        }),
        ...(searchType === SearchType.MANUFACTURER_NAME_EN && {
          manufacturerNameEn: searchValue,
        }),
        companyId,
        isFinish,
      }),
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(
    () => ({
      certificatesListItems: certificates?.content || [],
      total: certificates?.totalElements || 0,
      isLoading,
    }),
    [certificates, isLoading],
  );
};

export const useCertificateMode = () => {
  const countryId = useCountryId();
  return typeof countryId !== 'undefined';
};
export const useReadOnlyMode = () => {
  const readOnlyMode = useSelector(({ certificate }: any) => certificate.readOnlyMode);
  return readOnlyMode;
};

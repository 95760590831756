export enum DocumentCode {
  DETAIL = 'detail',
  MSDS = 'msds',
  TDS = 'tds',
  MCI = 'mci',
  COA = 'coa',
  MFC = 'mfc',
}

export interface UpdateLogs {
  logs: {
    documentFieldId: number;
    list: UpdateLogListItem[];
  }[];
}

export interface UpdateLogListItem {
  icidDocumentChangeLogId: number;
  userId: number;
  companyName: string;
  documentSeqId: number;
  materialIcidApplicationId: number;
  name: string;
  content: string | null;
  filename: string | null;
  uploadFileUrl: string | null;
  registerDt: string;
}

import { Spin } from 'antd';
import styled from 'styled-components';

const SpinWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-width: none !important;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 101;
`;

const FullLoading = () => (
  <SpinWrap>
    <Spin size="large" />
  </SpinWrap>
);

export default FullLoading;

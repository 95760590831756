import { useLocation, useNavigate } from 'react-router-dom';

export const useSearchParams = <T extends Record<string, any>>({ path }: { path: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const handleChangeSearchParams = (params: Partial<T>) => {
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value?.toString());
    }
    navigate(`${path}?${searchParams.toString()}`, { state: location?.state });
  };

  const replaceSearchParams = (params: Partial<T>) => {
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value?.toString());
    }
    navigate(`${path}?${searchParams.toString()}`, { state: location?.state, replace: true });
  };

  return {
    searchParams,
    onChangeSearchParams: handleChangeSearchParams,
    replaceSearchParams,
  };
};

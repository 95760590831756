import { message } from 'antd';
import { useCallback, useMemo, useState } from 'react';

import { useMutation } from 'react-query';

import * as notarizationApi from 'lib/api/notarization';
import { Buyer, ProductBuyer } from 'types/brand/buyer';
import { NotarizationDocumentCode } from 'types/brand/notarization';
import LoAModalContent from 'components/brand/notarization/LoAModalContent';
import StyleNoModalContent from 'components/brand/notarization/StyleNoModalContent';
import { ProductBasic } from 'types/product';
import ProductCategoryModalContent from 'components/brand/notarization/ProductCategoryModalContent';
import { useModal } from 'hook/useModal';

export const usePublishNotarization = () => {
  const [publishedDocumentUrl, setPublishedDocumentUrl] = useState<string | string[] | null>(null);
  const [loadingDocumentCode, setLoadingDocumentCode] = useState<NotarizationDocumentCode | null>(
    null,
  );
  const publishNotarization = useCallback(
    ({
      documentCode,
      buyer,
      productBuyers,
    }: {
      buyer: Buyer;
      documentCode: NotarizationDocumentCode;
      productBuyers: ProductBuyer[];
    }) => {
      switch (documentCode) {
        case NotarizationDocumentCode.LOA: {
          publishLoa({
            countryId: buyer.countryId,
            buyerId: buyer.buyerId,
            productIds: productBuyers.map(({ productId }) => productId),
          });
          break;
        }
        case NotarizationDocumentCode.SC:
        case NotarizationDocumentCode.BSEFL: {
          publishBseSc({
            countryId: buyer.countryId,
            docType: documentCode.charAt(0) as 'B' | 'S',
            products: productBuyers.map(({ product }) => product),
          });
          break;
        }
        case NotarizationDocumentCode.LTU: {
          publishLtu({
            countryId: buyer.countryId,
            productIds: productBuyers.map(({ productId }) => productId),
          });
          break;
        }
        case NotarizationDocumentCode.RL: {
          publishRl({
            countryId: buyer.countryId,
            products: productBuyers.map(({ product }) => product),
          });
          break;
        }
        default: {
          publishAut({
            countryId: buyer.countryId,
            buyerId: buyer.buyerId,
            productIds: productBuyers.map(({ productId }) => productId),
          });
        }
      }
    },
    [],
  );

  const { openConfirmModal, closeConfirmModal } = useModal();
  const { mutate: publishLoaMutate } = useMutation(
    (params: Parameters<typeof notarizationApi.publishLoa>[0]) =>
      notarizationApi.publishLoa(params),
    {
      onMutate: () => {
        setLoadingDocumentCode(NotarizationDocumentCode.LOA);
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warning(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  const publishLoa = ({
    countryId,
    buyerId,
    productIds,
  }: Omit<Parameters<typeof notarizationApi.publishLoa>[0], 'loaExpireYears'>) => {
    openConfirmModal({
      title: 'LoA 유효기간',
      width: 400,
      content: (
        <LoAModalContent
          onSubmit={(loaExpireYears: string) => {
            publishLoaMutate({
              countryId,
              buyerId,
              productIds,
              loaExpireYears,
            });
            closeConfirmModal();
          }}
        />
      ),
      footer: null,
    });
  };

  const { mutate: publishBseScMutate } = useMutation(
    (params: Parameters<typeof notarizationApi.publishBseSc>[0]) =>
      notarizationApi.publishBseSc(params),
    {
      onMutate: ({ docType }) => {
        setLoadingDocumentCode(
          docType === 'B' ? NotarizationDocumentCode.BSEFL : NotarizationDocumentCode.SC,
        );
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warning(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  const publishBseSc = ({
    countryId,
    docType,
    products,
  }: Pick<Parameters<typeof notarizationApi.publishBseSc>[0], 'countryId' | 'docType'> & {
    products: ProductBasic[];
  }) => {
    openConfirmModal({
      title: '제품별 Style No.',
      width: 800,
      content: (
        <StyleNoModalContent
          products={products}
          onSubmit={(productCodes: string[]) => {
            publishBseScMutate({
              countryId,
              docType,
              productIds: products.map(({ productId }) => productId),
              productCodes,
            });
            closeConfirmModal();
          }}
        />
      ),
      footer: null,
    });
  };

  const { mutate: publishLtu } = useMutation(
    (params: Parameters<typeof notarizationApi.publishLtu>[0]) =>
      notarizationApi.publishLtu(params),
    {
      onMutate: () => {
        setLoadingDocumentCode(NotarizationDocumentCode.LTU);
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warning(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  const { mutate: publishRlMutate } = useMutation(
    (params: Parameters<typeof notarizationApi.publishRl>[0]) => notarizationApi.publishRl(params),
    {
      onMutate: () => {
        setLoadingDocumentCode(NotarizationDocumentCode.RL);
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warning(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  const publishRl = ({ countryId, products }: { countryId: number; products: ProductBasic[] }) => {
    openConfirmModal({
      title: '회장품 카테고리',
      width: 800,
      content: (
        <ProductCategoryModalContent
          products={products}
          onSubmit={(productCategories: string[]) => {
            publishRlMutate({
              countryId,
              productIds: products.map(({ productId }) => productId),
              productCategories,
            });
            closeConfirmModal();
          }}
        />
      ),
      footer: null,
    });
  };

  const { mutate: publishAut } = useMutation(
    (params: Parameters<typeof notarizationApi.publishAut>[0]) =>
      notarizationApi.publishAut(params),
    {
      onMutate: () => {
        setLoadingDocumentCode(NotarizationDocumentCode.A);
      },
      onSuccess: (res) => {
        setPublishedDocumentUrl(res.data.result);
      },
      onError: ({ message: errorMessage }: { message: string }) => {
        message.warning(errorMessage);
      },
      onSettled: () => {
        setLoadingDocumentCode(null);
      },
    },
  );

  return useMemo(
    () => ({
      publishNotarization,
      publishedDocumentUrl,
      initPublishedDocumentUrl: () => setPublishedDocumentUrl(null),
      loadingDocumentCode,
    }),
    [publishNotarization, publishedDocumentUrl, loadingDocumentCode],
  );
};

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import produce from 'immer';
import styled from 'styled-components';
import { Button, Col, Collapse, Form, message, Row, Spin, Modal } from 'antd';
import { useUpdateMode } from 'hook/material';

import { FirstAidForm, MsdsForm, MsdsGet } from 'types/material/msds';
import palette from 'lib/styles/palette';
import { scrollToFirstErrorOption } from 'lib/consts';
import { useGetStatementMap, useMsds, useMsdsDefaultInfo } from 'service/material/msds';
import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import MSDSBasicInfo from './MSDSBasicInfo';
import MSDSHazards from './MSDSHazards';
import MSDSFirstAid from './MSDSFirstAid';
import MSDSFirefighting from './MSDSFirefighting';
import MSDSPhysicalChemical from './MSDSPhysicalChemical';
import MSDSCompositionInformations from './MSDSCompositionInformations';
import MSDSAccidentalRelease from './MSDSAccidentalRelease';
import MSDSHandlingStorage from './MSDSHandlingStorage';
import MSDSExposureControl from './MSDSExposureControl';
import MSDSStabilityReactivity from './MSDSStabilityReactivity';
import MSDSToxicological from './MSDSToxicological';
import MSDSEcological from './MSDSEcological';
import MSDSDisposal from './MSDSDisposal';
import MSDSTransport from './MSDSTransport';
import MSDSRegulatory from './MSDSRegulatory';
import MSDSOther from './MSDSOther';
import path from 'lib/path';
import RouteLeaveGuard from 'components/RouteLeaveGuard';
import MSDSImportModal from './MSDSImportModal';
import Icon from 'components/ui/Icon/Icon';

const GroupHeaderBlock = styled(Row)``;

const GroupHeader = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <GroupHeaderBlock gutter={12} align="middle">
      <Col>
        <Typography.Text type="BODY_2" color="SLATE_GRAY70" medium>
          {title}
        </Typography.Text>
      </Col>
      <Col>
        <Typography.Text type="BODY_2" color="SLATE_GRAY70" style={{ fontSize: 12 }}>
          {subtitle}
        </Typography.Text>
      </Col>
    </GroupHeaderBlock>
  );
};

const RawMaterialMSDSBlock = styled.div`
  .ant-collapse {
    border: none;
    background-color: #fff;
    counter-reset: no;

    .ant-collapse-item {
      border-bottom: none;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .ant-collapse-header {
        background-color: ${palette.SLATE_GRAY10};
        padding: 16px 58px;

        &::before {
          position: absolute;
          left: 34px;
          top: 17px;
          display: block;
          counter-increment: no;
          content: counter(no);
          background-color: ${palette.PRIMARY50};
          color: #fff;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          text-align: center;
          line-height: 18px;
          letter-spacing: -0.8px;
          padding-right: 1px;
          font-size: 12px;
        }

        .ant-collapse-arrow {
          top: 0;
          right: 0;
          padding: 0;
        }
      }

      .ant-collapse-content {
        border-top: none;

        .ant-collapse-content-box {
          padding: 32px 0 0px;
        }
      }
    }
  }
`;

const RawMaterialMSDS = () => {
  const navigate = useNavigate();
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);
  const updateMode = useUpdateMode();
  const { msdsDefaultInfo, getLoading: getDefaultInfoLoading } = useMsdsDefaultInfo(materialId);
  const [isTemporary, setIsTemporary] = useState(false);
  const {
    msds,
    getLoading: getMsdsLoading,
    addMsds,
    addLoading,
    updateMsds,
    updateLoading,
    isAddSuccess,
  } = useMsds(materialId);
  const { getStatementMap } = useGetStatementMap();
  const [importModalVisible, setImportModalVisible] = useState(false);
  const handleClickImportModalButton = () => {
    setImportModalVisible(true);
  };
  const handleCloseImportModal = () => {
    setImportModalVisible(false);
  };
  const handleImport = ({ msdsBasicInfo, ...msds }: MsdsGet) => {
    form.setFieldsValue(msds);
  };
  const [form] = Form.useForm<MsdsForm>();
  const [activeKeys, setActiveKeys] = useState(['msdsBasicInfo']);
  const handleValidationFail = ({ errorFields }: ValidateErrorEntity<any>) => {
    const errorFieldNameSet = new Set<string>();
    errorFields.forEach(({ name: [errorFieldName] }) => {
      errorFieldNameSet.add(errorFieldName as string);
    });
    setActiveKeys((draft) =>
      produce(draft, (proxy) => {
        errorFieldNameSet.forEach((errorFieldName) => {
          if (!draft.includes(errorFieldName)) {
            proxy.push(errorFieldName);
          }
        });
      }),
    );
  };

  const handleChangeActiveKeys = (keys: string | string[]) => {
    setActiveKeys(Array.isArray(keys) ? keys : [keys]);
  };

  const handleSubmit = (msdsForm: MsdsForm) => {
    msdsForm.hazards.pictograms = form.getFieldValue(['hazards', 'pictograms']);
    msdsForm.compositionInformations = form.getFieldValue('compositionInformations');
    if (!updateMode) {
      Modal.confirm({
        icon: null,
        width: 500,
        content: (
          <Typography.Text type="BODY_2">
            1번부터 16번까지의 항목은 필수 입력 항목입니다.
            <br />
            해당 내용을 확인하고 등록 원료에 맞게 작성하였음을 확인하였습니까?
            <br />
            (잘못 입력된 데이터로 인해 담당자에게 책임의 소지가 발생할 수 있습니다.)
          </Typography.Text>
        ),
        onOk: () => {
          addMsds(
            { ...msdsForm, isTemporary: false },
            {
              onSuccess: () => {
                message.success('저장되었습니다.');
                navigate(-1);
              },
            },
          );
        },
      });
    } else {
      updateMsds(msdsForm);
    }
  };

  const handleSave = () => {
    setIsTemporary(true);
    const msdsForm = form.getFieldsValue();
    msdsForm.hazards.pictograms = form.getFieldValue(['hazards', 'pictograms']) || [];
    addMsds(
      { ...msdsForm, isTemporary: true },
      {
        onSuccess: () => {
          message.success('임시 저장되었습니다.');
          navigate(-1);
        },
      },
    );
  };

  useEffect(() => {
    if (!updateMode && msdsDefaultInfo) {
      const {
        productName,
        companyName,
        originManufacturerName,
        ceoTel,
        fax,
        emergencyTel,
        compositionInformations,
        appearanceForm,
        appearanceColor,
        odour,
        ph,
        meltingPoint,
      } = msdsDefaultInfo;
      if (compositionInformations.length === 0) {
        message.error('올바르지 않은 접근입니다.');
        navigate(path.main, { replace: true });
      }

      form.setFieldsValue({
        msdsBasicInfo: {
          productName,
          companyName,
          originManufacturerName,
          ceoTel,
          fax,
          emergencyTel,
        },
        compositionInformations,
        physicalChemical: {
          appearanceForm,
          appearanceColor,
          odour,
          ph,
          meltingPoint,
        },
        other: {
          listOfReferences: `Follow ${originManufacturerName}`,
        },
      });
    }
  }, [msdsDefaultInfo]);

  useEffect(() => {
    if (msds) {
      if (!updateMode) {
        form.setFieldsValue({
          ...msds,
          hazards: {
            ...msds.hazards,
            ghsClassifications:
              msds.hazards.ghsClassifications.length > 0 ? msds.hazards.ghsClassifications : [{}],
          },
        });
        message.success('임시 저장된 데이터를 불러왔습니다.');
      } else {
        form.setFieldsValue(msds);
      }
      if (msds.hazards.ghsClassifications.length > 0) {
        const materialCategoryIds = msds.hazards.ghsClassifications.map(
          ({ depth2GHSId }) => depth2GHSId,
        );

        getStatementMap(materialCategoryIds, {
          onSuccess: (res) => {
            const statementMap = res.data.result;
            const draftFirstAid = form.getFieldValue(['firstAid']) as FirstAidForm;
            form.setFieldsValue({
              firstAid: produce(draftFirstAid, (proxy: FirstAidForm) => {
                const eyeStatements = statementMap.eyeAids.map(({ nameEn, nameKo }) => ({
                  statementEn: nameEn,
                  statementKo: nameKo,
                }));
                proxy.eyeStatementOptions = eyeStatements;

                const skinStatements = statementMap.skinAids.map(({ nameEn, nameKo }) => ({
                  statementEn: nameEn,
                  statementKo: nameKo,
                }));
                proxy.skinStatementOptions = skinStatements;

                const inhalationStatements = statementMap.inhalationAids.map(
                  ({ nameEn, nameKo }) => ({
                    statementEn: nameEn,
                    statementKo: nameKo,
                  }),
                );
                proxy.inhalationStatementOptions = inhalationStatements;

                const ingestionStatements = statementMap.ingestionAids.map(
                  ({ nameEn, nameKo }) => ({
                    statementEn: nameEn,
                    statementKo: nameKo,
                  }),
                );
                proxy.ingestionStatementOptions = ingestionStatements;

                const physicianStatements = statementMap.physicianAids.map(
                  ({ nameEn, nameKo }) => ({
                    statementEn: nameEn,
                    statementKo: nameKo,
                  }),
                );
                proxy.physicianStatementOptions = physicianStatements;
              }),
            });
          },
        });
      }
    }
  }, [msds]);

  return (
    <RawMaterialMSDSBlock>
      <RouteLeaveGuard
        isChanged={!updateMode && !isAddSuccess}
        content={`현재 페이지를 벗어나면 입력하신 데이터가 사라집니다.\n데이터가 유지되길 원하시는 경우, ‘계속 입력’ 버튼을 누른 후\n임시 저장 혹은 저장 하시길 바랍니다.`}
        okText="나가기"
        closeText="계속입력"
      />
      <Spin spinning={getDefaultInfoLoading || getMsdsLoading}>
        {!updateMode && (
          <>
            <MSDSImportModal
              visible={importModalVisible}
              onClose={handleCloseImportModal}
              onImport={handleImport}
            />
            <Row justify="end" style={{ marginBottom: 16 }}>
              <Col>
                <Button
                  icon={<Icon name="find" size={20} color="PRIMARY50" />}
                  onClick={handleClickImportModalButton}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  등록된 원료 불러오기
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError={scrollToFirstErrorOption}
          onFinishFailed={handleValidationFail}
          onFinish={handleSubmit}
        >
          <Collapse
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <Icon
                name={isActive ? 'up' : 'down'}
                size={20}
                style={{ position: 'absolute', top: 16, right: 36 }}
              />
            )}
            activeKey={activeKeys}
            onChange={handleChangeActiveKeys}
          >
            <Collapse.Panel
              key="msdsBasicInfo"
              header={
                <GroupHeader
                  title="화학 제품과 회사에 관한 정보"
                  subtitle="Identification of the substance or mixture and of the supplier"
                />
              }
              forceRender
            >
              <MSDSBasicInfo form={form} />
            </Collapse.Panel>
            <Collapse.Panel
              key="hazards"
              header={<GroupHeader title="유해성·위험성" subtitle="Hazards identification" />}
              forceRender
            >
              <MSDSHazards form={form} />
            </Collapse.Panel>
            <Collapse.Panel
              key="compositionInformations"
              header={
                <GroupHeader
                  title="구성 성분의 명칭 및 함유량"
                  subtitle="Composition/information on ingredients"
                />
              }
              forceRender
            >
              <MSDSCompositionInformations />
            </Collapse.Panel>
            <Collapse.Panel
              key="firstAid"
              header={<GroupHeader title="응급조치 요령" subtitle="First aid measures" />}
              forceRender
            >
              <MSDSFirstAid />
            </Collapse.Panel>
            <Collapse.Panel
              key="fireFighting"
              header={<GroupHeader title="폭발·화재시 대처방법" subtitle="Firefighting measures" />}
              forceRender
            >
              <MSDSFirefighting />
            </Collapse.Panel>
            <Collapse.Panel
              key="accidentalRelease"
              header={
                <GroupHeader title="누출 사고 시 대처방법" subtitle="Accidental release measures" />
              }
              forceRender
            >
              <MSDSAccidentalRelease />
            </Collapse.Panel>
            <Collapse.Panel
              key="handlingStorage"
              header={<GroupHeader title="취급 및 저장방법" subtitle="Handling and storage" />}
              forceRender
            >
              <MSDSHandlingStorage />
            </Collapse.Panel>
            <Collapse.Panel
              key="exposureControl"
              header={
                <GroupHeader
                  title="노출방지 및 개인보호구"
                  subtitle="Exposure controls/personal protection"
                />
              }
              forceRender
            >
              <MSDSExposureControl />
            </Collapse.Panel>
            <Collapse.Panel
              key="physicalChemical"
              header={
                <GroupHeader title="물리화학적 특성" subtitle="Physical and chemical properties" />
              }
              forceRender
            >
              <MSDSPhysicalChemical />
            </Collapse.Panel>
            <Collapse.Panel
              key="stabilityReactivity"
              header={<GroupHeader title="안전성 및 반응성" subtitle="Stability and reactivity" />}
              forceRender
            >
              <MSDSStabilityReactivity />
            </Collapse.Panel>
            <Collapse.Panel
              key="toxicological"
              header={<GroupHeader title="독성에 관한 정보" subtitle="Toxicological information" />}
              forceRender
            >
              <MSDSToxicological />
            </Collapse.Panel>
            <Collapse.Panel
              key="ecological"
              header={<GroupHeader title="환경에 미치는 영향" subtitle="Ecological information" />}
              forceRender
            >
              <MSDSEcological />
            </Collapse.Panel>
            <Collapse.Panel
              key="disposal"
              header={<GroupHeader title="폐기시 주의사항" subtitle="Disposal considerations" />}
              forceRender
            >
              <MSDSDisposal />
            </Collapse.Panel>
            <Collapse.Panel
              key="transport"
              header={<GroupHeader title="운송에 필요한 정보" subtitle="Transport information" />}
              forceRender
            >
              <MSDSTransport />
            </Collapse.Panel>
            <Collapse.Panel
              key="regulatory"
              header={<GroupHeader title="법적 규제현황" subtitle="Regulatory information" />}
              forceRender
            >
              <MSDSRegulatory />
            </Collapse.Panel>
            <Collapse.Panel
              key="other"
              header={<GroupHeader title="그 밖의 참고사항" subtitle="Other information" />}
              forceRender
            >
              <MSDSOther updateMode={updateMode} />
            </Collapse.Panel>
          </Collapse>
          <FooterBox>
            {!updateMode && (
              <Button loading={addLoading && isTemporary} onClick={handleSave}>
                임시 저장
              </Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              loading={(addLoading && !isTemporary) || updateLoading}
              onClick={() => setIsTemporary(false)}
            >
              {!updateMode ? '저장' : '수정'}
            </Button>
          </FooterBox>
        </Form>
      </Spin>
    </RawMaterialMSDSBlock>
  );
};

export default RawMaterialMSDS;

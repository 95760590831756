import { Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import BuyerAddModal from 'components/modal/buyer/BuyerAddModal';
import BuyerDetailModal from 'components/modal/buyer/BuyerDetailModal';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { useBuyer } from 'hook/buyer';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';

const BuyerListBlock = styled.div``;

const StyledButton = styled(Button)`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${palette.PRIMARY50};
      transition: all 200ms ease;
    }
  }

  svg,
  path {
    transition: all 200ms ease;
  }
`;

const BuyerList = ({ buyers, getLoading }: any) => {
  const [selectedBuyerIds, setSelectedBuyerIds] = useState<number[]>([]);
  const { countries, deleteLoading, toggleShowAddModal, setBuyer, deleteBuyers } = useBuyer();
  const { openAlertModal } = useModal();
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: '국가',
        width: '33%',
        align: 'center',
        render: ({ countryId }) =>
          countries.find((country) => country.countryId === countryId)?.countryNameKo,
        filters: countries.map(({ countryId, countryNameKo }) => ({
          text: countryNameKo,
          value: countryId,
        })),
        onFilter: (value, record) => record.countryId === value,
      },
      {
        title: '바이어',
        width: '34%',
        align: 'center',
        render: (buyer) => (
          <Typography.Link underline onClick={() => setBuyer(buyer)}>
            {buyer.companyName}
          </Typography.Link>
        ),
      },
      {
        title: '대표',
        width: '33%',
        align: 'center',
        render: ({ ceoName }) => ceoName,
      },
    ],
    [countries],
  );
  return (
    <BuyerListBlock>
      <BuyerAddModal />
      <BuyerDetailModal />
      <Space style={{ width: '100%', marginBottom: 8, justifyContent: 'flex-end' }}>
        <Button
          icon={<Icon name="plus" size={18} color="PRIMARY50" />}
          onClick={toggleShowAddModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          바이어 추가
        </Button>
        <StyledButton
          loading={deleteLoading}
          type="default"
          icon={<Icon name="delete" color="PRIMARY50" size={14} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            if (!selectedBuyerIds.length) return;
            openAlertModal({
              content: (
                <Typography.Text style={{ fontSize: '14px' }}>
                  정말 삭제하시겠습니까?
                </Typography.Text>
              ),
              onOk: () => {
                deleteBuyers(selectedBuyerIds);
                setSelectedBuyerIds([]);
              },
            });
          }}
        >
          선택한 바이어 삭제
        </StyledButton>
      </Space>
      <Table
        rowKey={({ buyerId }) => buyerId}
        rowSelection={{
          selectedRowKeys: selectedBuyerIds,
          onChange: (key) => setSelectedBuyerIds(key as number[]),
        }}
        columns={columns}
        dataSource={buyers}
        loading={getLoading}
      />
    </BuyerListBlock>
  );
};

export default BuyerList;

import { Button, Col, Modal, Row, Spin, Upload } from 'antd';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import FooterBox from 'components/FooterBox';
import { Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';
import Icon from 'components/ui/Icon/Icon';
import Tags from 'components/ui/Tags';
import useUpdateMode from 'hook/useUpdateMode';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import {
  useIsProductSampleConfirmed,
  useProductSampleId,
  useProductSampleRecipeDetail,
} from 'service/manufacturer/productSample';
import { useCountryProhibitsUpdatedDate } from 'service/material/ingredient';
import { ProductSampleRecipeDetailDraft } from 'types/manufacturer/productSample';
import RecipeDetailTable from './RecipeDetailTable';

const RecipeDetailDraftBlock = styled.div<{
  success: 'true' | 'false';
  sum_error_class: string;
}>`
  td.ant-table-cell.error {
    outline: 1px solid ${palette.MESSAGE_ERROR};
    outline-offset: -1px;
  }

  ${({ success }) =>
    css`
      .ant-table-wrapper {
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: ${success === 'true' ? palette.MESSAGE_SUCCESS : palette.MESSAGE_ERROR};
      }
    `}

  td.ant-table-cell.remark {
    color: ${palette.MESSAGE_ERROR} !important;
  }
  ${({ sum_error_class }) =>
    sum_error_class &&
    css`
      ${sum_error_class} {
        color: ${palette.MESSAGE_ERROR} !important;
      }
    `}
`;

const RecipeDetailDraft = ({ recipeDraft }: { recipeDraft: ProductSampleRecipeDetailDraft }) => {
  const { recipeDetailItems, invalidCodes, formulaExcelUrl, errorFileUrl } = recipeDraft;
  const isSuccess = invalidCodes.length === 0;
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  return (
    <RecipeDetailDraftBlock
      success={isSuccess ? 'true' : 'false'}
      sum_error_class={invalidCodes
        .filter(({ code }) => code.startsWith('INVALID_TOTAL'))
        .map(
          ({ code }) =>
            `.${code.replace('INVALID_TOTAL_', '').replace(/_/g, '-').toLocaleLowerCase()}`,
        )
        .join(', ')}
    >
      <Row justify="space-between" align="bottom">
        <Col>
          {isSuccess ? (
            <Typography.Text
              type="BODY_2"
              gutter={{ bottom: 8 }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <StatusIcon status="success" style={{ marginRight: '8px' }} />
              정상적으로 업로드 되었습니다.
            </Typography.Text>
          ) : (
            invalidCodes.map(({ code, message }, index) => (
              <Typography.Text
                type="BODY_2"
                key={code}
                gutter={{ bottom: index === invalidCodes.length - 1 ? 8 : 4 }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <StatusIcon status="error" style={{ marginRight: '8px' }} />
                {code.startsWith('INVALID_TOTAL') ? (
                  message
                ) : (
                  <>
                    업로드한 파일에 오류가 있습니다.{' '}
                    <a
                      href={errorFileUrl!}
                      style={{
                        textDecoration: 'underline',
                        color: palette.GRAY90,
                      }}
                    >
                      여기
                    </a>
                    를 클릭하여 다운로드 후 수정하여 업로드해 주세요.
                  </>
                )}
              </Typography.Text>
            ))
          )}
        </Col>
        <Col style={{ paddingBottom: 8 }}>
          <Tags.Mark color="lightGray" style={{ display: 'inline-block' }}>
            DB 업데이트
          </Tags.Mark>
          <Typography.Text type="SMALL" medium color="SLATE_GRAY70" inline gutter={{ left: 8 }}>
            [국가별 성분 규제]
          </Typography.Text>
          <Typography.Text type="SMALL" medium color="SLATE_GRAY70" inline gutter={{ left: 4 }}>
            {prohibitDataUpdatedDate}
          </Typography.Text>
        </Col>
      </Row>
      <RecipeDetailTable recipeDetailItems={recipeDetailItems} formulaExcelUrl={formulaExcelUrl} />
    </RecipeDetailDraftBlock>
  );
};

const ProductSampleRecipeDetailBlock = styled.div``;

const RecipeDetailTableWrap = styled.div``;

const ProductSampleRecipeDetail = () => {
  const productSampleId = useProductSampleId();
  const isProductSampleConfirmed = useIsProductSampleConfirmed(productSampleId);
  const updateMode = useUpdateMode();
  const [file, setFile] = useState<File | null>(null);
  const {
    validateProductSampleRecipeDetail,
    validateLoading,
    recipeDetailDraft,
    addProductSampleRecipeDetail,
    addLoading,
    productSampleRecipeDetail,
    getLoading,
  } = useProductSampleRecipeDetail(productSampleId!, updateMode);
  const handleAddProductSampleRecipeDetail = () => {
    if (!updateMode) {
      addProductSampleRecipeDetail({
        productSampleId: productSampleId!,
        file: file!,
      });
    } else {
      Modal.confirm({
        icon: null,
        closable: true,
        width: 400,
        bodyStyle: { paddingTop: 16 },
        content: (
          <Typography.Text type="BODY_2" align="center">
            처방 상세를 수정하는 경우,
            <br />
            공정도 페이지의 입력이 초기화되며
            <br />
            새로 입력이 필요합니다. 수정하시겠습니까?
          </Typography.Text>
        ),
        onOk: () => {
          addProductSampleRecipeDetail({
            productSampleId: productSampleId!,
            file: file!,
          });
        },
      });
    }
  };

  return (
    <ProductSampleRecipeDetailBlock>
      <Spin spinning={getLoading}>
        {!isProductSampleConfirmed && (
          <Row justify="center" gutter={16} style={{ marginBottom: 24 }}>
            <Col>
              <Button
                type="dashed"
                icon={<Icon name="download" size={18} color="PRIMARY50" />}
                onClick={() =>
                  downloadFile(
                    'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Recipe Details.xlsx',
                  )
                }
                style={{ display: 'flex', alignItems: 'center' }}
              >
                엑셀 서식 다운로드
              </Button>
            </Col>
            <Col>
              <Upload
                accept=".xlsx"
                beforeUpload={(file) => {
                  validateProductSampleRecipeDetail(file, {
                    onSuccess: () => {
                      setFile(file);
                    },
                  });
                  return false;
                }}
                itemRender={() => null}
              >
                <Button
                  loading={validateLoading}
                  icon={<Icon name="upload" size={18} color="PRIMARY50" />}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  파일 업로드
                </Button>
              </Upload>
            </Col>
          </Row>
        )}
        {recipeDetailDraft && <RecipeDetailDraft recipeDraft={recipeDetailDraft} />}
        {productSampleRecipeDetail && !recipeDetailDraft && (
          <RecipeDetailTableWrap>
            <RecipeDetailTable
              recipeDetailItems={productSampleRecipeDetail.recipeDetailItems}
              formulaExcelUrl={productSampleRecipeDetail.formulaExcelUrl}
            />
          </RecipeDetailTableWrap>
        )}

        <FooterBox>
          {file && recipeDetailDraft && recipeDetailDraft.invalidCodes.length === 0 && (
            <Button
              type="primary"
              loading={addLoading}
              onClick={handleAddProductSampleRecipeDetail}
            >
              {!updateMode ? '등록' : '수정'}
            </Button>
          )}
        </FooterBox>
      </Spin>
    </ProductSampleRecipeDetailBlock>
  );
};

export default ProductSampleRecipeDetail;

import { message } from 'antd';
import moment from 'moment';
import { ChangeEventHandler, DragEventHandler, useRef, useState } from 'react';
import styled from 'styled-components';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import Tags from 'components/ui/Tags';
import { useModal } from 'hook/useModal';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { useCompanyDocsList } from 'service/brand/company';
import { useUploadFiles } from 'service/common';
import { CompanyDocsList, CompanyDocsUserType } from 'types/brand/company';
import { FileInfo } from 'types/brand/qcqa';
import { UploadFile } from 'types/common';
import { messages } from 'lib/consts';

// 전성분표 코드
const FORMULA_BREAKDOWN_CODE = 'CODE_044';

const Container = styled.div`
  margin-top: 40px;
`;

const FileUploadCardList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;
`;

const QCQAProductDocument = ({
  fileMapBeforeUploading,
  onChangeFileMapBeforeUploading,
  screeningFileData,
  onChangescreeningFileData,
  addLoading,
}: {
  fileMapBeforeUploading: Record<number, FileInfo[]>;
  onChangeFileMapBeforeUploading: React.Dispatch<React.SetStateAction<Record<number, FileInfo[]>>>;
  screeningFileData?: {
    fileInfo: UploadFile;
    file: File;
  };
  onChangescreeningFileData: React.Dispatch<
    React.SetStateAction<
      | {
          fileInfo: UploadFile;
          file: File;
        }
      | undefined
    >
  >;
  addLoading: boolean;
}) => {
  const { openAlertModal, closeQCQAFormulaBreakdownModal } = useModal();
  const [uploadingDocumentsMap, setUploadingDocumentsMap] = useState<Record<number, boolean>>({});

  const { uploadFilesAsync } = useUploadFiles();
  const { companyDocsList } = useCompanyDocsList({
    useType: CompanyDocsUserType.PRODUCT,
  });

  const handleFileUpload =
    (qcqaDocumentId: number) =>
    async (fileList: FileList | File[], isScreening: boolean = false) => {
      // 로딩중인지
      if (uploadingDocumentsMap[qcqaDocumentId]) {
        return;
      }

      setUploadingDocumentsMap((prevMap) => {
        prevMap[qcqaDocumentId] = true;

        return { ...prevMap };
      });

      const now = moment().format('YYYY-MM-DD');
      const files: File[] = [];
      for (const file of fileList) {
        files.push(file);
      }

      if (!(qcqaDocumentId in fileMapBeforeUploading)) {
        fileMapBeforeUploading[qcqaDocumentId] = [];
      }

      const res = await uploadFilesAsync({
        files,
        uploadDestinationType: 'QCQA',
      });
      const newFileList: FileInfo[] = [];

      for (const uploadFileRes of res.data.result) {
        newFileList.push({
          ...uploadFileRes,
          registerDt: now,
        });
      }

      onChangeFileMapBeforeUploading((prevMap) => {
        prevMap[qcqaDocumentId] = prevMap[qcqaDocumentId].concat(newFileList);

        return { ...prevMap };
      });

      if (newFileList.length === 0) {
        message.warning(messages.SHOULD_CHECK_FILE);
        // 전성분표 파일일 때
      } else if (isScreening) {
        onChangescreeningFileData({
          fileInfo: res.data.result[0],
          file: files[0],
        });
        message.success('전성분표가 업로드되었습니다.');
        closeQCQAFormulaBreakdownModal();
      } else {
        message.success('정상적으로 업로드되었습니다.');
      }

      setUploadingDocumentsMap((prevMap) => {
        prevMap[qcqaDocumentId] = false;

        return { ...prevMap };
      });
    };

  const handleFileDownload = (qcqaDocumentId: number) => (fileIndex: number) => {
    const fileInfo = (fileMapBeforeUploading[qcqaDocumentId] ?? [])[fileIndex];
    if (!fileInfo) return;

    downloadFile(fileInfo.uploadFileUrl, fileInfo.filename).then(() => {
      message.success('정상적으로 다운로드되었습니다.');
    });
  };

  const handleFileDelete = (qcqaDocumentId: number) => (fileIndex: number) => {
    const fileInfoList = fileMapBeforeUploading[qcqaDocumentId];
    if (!fileInfoList) return;
    const fileInfo = fileInfoList[fileIndex];
    openAlertModal({
      content: `‘${fileInfo.filename}’ 파일을 삭제하시겠습니까?`,
      okText: '확인',
      closeText: '취소',
      onOk: () => {
        fileInfoList.splice(fileIndex, 1);
        onChangeFileMapBeforeUploading({ ...fileMapBeforeUploading });
        message.success('삭제되었습니다.');
      },
    });
  };

  return (
    <Container>
      <Typography.Text type="TITLE_1">제품 서류</Typography.Text>
      <FileUploadCardList>
        {companyDocsList
          .filter(({ isDisplay }) => isDisplay)
          .map((doc) => (
            <FileUploadCard
              key={doc.code}
              screeningFile={screeningFileData?.file}
              preventFileHandling={addLoading}
              fileInfoList={fileMapBeforeUploading[doc.qcqaUserDocumentId]}
              onUpload={handleFileUpload(doc.qcqaUserDocumentId)}
              onDownload={handleFileDownload(doc.qcqaUserDocumentId)}
              onDelete={handleFileDelete(doc.qcqaUserDocumentId)}
              loading={uploadingDocumentsMap[doc.qcqaUserDocumentId]}
              document={doc}
            />
          ))}
      </FileUploadCardList>
    </Container>
  );
};

const FileUploadCardContainer = styled.div<{
  dragging: boolean;
}>`
  padding: 14px 30px;
  background: ${palette.ETC_WHITE};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border: 2px dashed transparent;
  border-radius: 8px;
  transition: border 300ms ease;

  ${({ dragging }) =>
    dragging &&
    `
    border: 2px dashed ${palette.PRIMARY50};
    background-color: ${palette.PRIMARY10};
  `}
`;

const FileUploadCardHead = styled(Flex)``;

const ScreeningButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  padding-left: 7px;
  border-radius: 4px;
  border: 1px solid ${palette.SLATE_GRAY40};
  cursor: pointer;
`;

const FileUploadGuide = styled.div`
  background-color: ${palette.PRIMARY20};
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 10px;
`;

const FileUploadCardBody = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const FileUploadCard = ({
  screeningFile,
  fileInfoList = [],
  preventFileHandling,
  onUpload,
  onDownload,
  onDelete,
  loading,
  document,
}: {
  screeningFile?: File;
  fileInfoList?: FileInfo[];
  preventFileHandling: boolean;
  onUpload: (filesList: FileList | File[], isScreening?: boolean) => void;
  onDownload: (fileIndex: number) => void;
  onDelete: (fileIndex: number) => void;
  loading: boolean;
  document: CompanyDocsList;
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [hoverCount, setHoverCount] = useState(0);
  const [fileDragState, setFileDragState] = useState<'none' | 'dragging'>('none');

  const { openQCQAFormulaBreakdownModal } = useModal();

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count + 1);
    setFileDragState('dragging');
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1) {
      setFileDragState('none');
    }
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if ([...e.dataTransfer.files].filter((file) => file.type === '').length > 0) {
      message.warning('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    onUpload(e.dataTransfer.files);
  };

  const handleScreeningClick = () => {
    if (preventFileHandling) return;

    openQCQAFormulaBreakdownModal({
      qcqaProductDetailId: 0,
      onRegister: onUpload,
      screeningFile,
      qcqaRelationDocumentRecordId: 0,
    });
  };

  const referToFile = () => fileRef.current?.click();

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) return;
    onUpload(e.target.files);
    e.target.value = '';
  };

  return (
    <FileUploadCardContainer
      dragging={fileDragState === 'dragging'}
      onDragEnter={preventFileHandling ? undefined : handleFileDragEnter}
      onDragLeave={handleFileDragLeave}
      onDragOver={(e) => e.preventDefault()}
      onDrop={preventFileHandling ? undefined : handleFileDragUpload}
    >
      <FileUploadCardHead align="center" justify="space-between">
        <Flex align="center">
          <Typography.Text color="SLATE_GRAY70" bold>
            {document.name}
          </Typography.Text>
          {document.description && (
            <Tip trigger="click" iconStyle={{ marginLeft: 8 }}>
              {document.description}
            </Tip>
          )}
          {document.isForCertification && (
            <Tags.Chip color="YELLOW_20" style={{ marginLeft: 16 }}>
              인증 활용
            </Tags.Chip>
          )}
        </Flex>
        <Flex align="center" columnGap={16}>
          {
            // 전성분표
            document.code === FORMULA_BREAKDOWN_CODE && (
              <ScreeningButton role="button" onClick={handleScreeningClick}>
                <Icon name="screening" size={18} />
                <Typography.Text type="SMALL" bold>
                  스크리닝
                </Typography.Text>
              </ScreeningButton>
            )
          }
          <Flex
            role="button"
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={preventFileHandling ? undefined : referToFile}
          >
            <Icon name="plus" size={16} color="SLATE_GRAY70" />
            <Typography.Text type="BODY_2" color="SLATE_GRAY70">
              파일추가
            </Typography.Text>
            <input
              type="file"
              ref={fileRef}
              multiple={true}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
          </Flex>
        </Flex>
      </FileUploadCardHead>
      {
        // 전성분표
        document.code === FORMULA_BREAKDOWN_CODE && (
          <FileUploadGuide>
            <Typography.Text type="SMALL" color="SLATE_GRAY70" medium>
              전성분표를 스크리닝하여 등록하면 해외 인증 서비스 진행시 간편하게 사용할 수 있습니다.
            </Typography.Text>
          </FileUploadGuide>
        )
      }
      {loading && (
        <Flex justify="center" align="center" dir="column" rowGap={4}>
          <LoadingIndicator size="sm" />
          <Typography.Text type="SMALL" color="SLATE_GRAY70" bold>
            파일을 업로드 하는 중...
          </Typography.Text>
        </Flex>
      )}
      {!loading && fileInfoList.length > 0 && (
        <FileUploadCardBody>
          {fileInfoList.map((fileInfo, fileIdx) => (
            <FileUploadItem
              key={fileIdx}
              fileInfo={fileInfo}
              onDownload={() => onDownload(fileIdx)}
              onDelete={preventFileHandling ? undefined : () => onDelete(fileIdx)}
            />
          ))}
        </FileUploadCardBody>
      )}
    </FileUploadCardContainer>
  );
};

const FileUploadItemWrapper = styled(Flex)`
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
  padding: 8px 16px;
`;

const FileUploadItem = ({
  fileInfo,
  onDownload,
  onDelete,
}: {
  fileInfo: FileInfo;
  onDownload: VoidFunction;
  onDelete?: VoidFunction;
}) => {
  return (
    <FileUploadItemWrapper justify="space-between" align="center">
      <Typography.Text type="SMALL" color="SLATE_GRAY70">
        {fileInfo.filename}
      </Typography.Text>
      <Flex columnGap={26}>
        <Typography.Text type="SMALL" color="GRAY50">
          {fileInfo.registerDt.slice(0, 10)}
        </Typography.Text>
        <Flex align="center" style={{ height: 18, cursor: 'pointer' }} onClick={onDownload}>
          <Icon name="download" size={16} color="GRAY60" />
        </Flex>
        <Flex align="center" style={{ height: 18, cursor: 'pointer' }} onClick={onDelete}>
          <Icon name="delete" size={18} color="GRAY60" />
        </Flex>
      </Flex>
    </FileUploadItemWrapper>
  );
};

export default QCQAProductDocument;

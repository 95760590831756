import styled from 'styled-components';
import { Button, message, Progress, Upload } from 'antd';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import FooterBox from 'components/FooterBox';
import { getReadableFileSize } from 'lib/file';
import { Flex } from 'components/ui';
import { useProductEtcDatas } from 'service/product';
import ProductEtcDataTable from './ProductEtcDataTable';
import { messages } from 'lib/consts';
import useGA, { EGAActionType } from 'hook/useGA';
import Icon from 'components/ui/Icon/Icon';
import { useNavigate } from 'react-router-dom';

const ProductEtcDataContainer = styled.div`
  .ant-progress-bg::before {
    display: none;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td,
  .ant-table-wrapper .ant-table-thead > tr > th {
    border: none;
  }
`;

const StyledUploadDragger = styled(Upload.Dragger)`
  display: block;
  width: 100%;
  height: 222px !important;
  border: 1px solid ${palette.GRAY40} !important;
  border-radius: 0 !important;
  background-color: #fff !important;

  .ant-btn {
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: ${palette.GRAY70};

    .material-icons {
      margin-right: 0;
      color: ${palette.GRAY60};
    }
  }

  .ant-upload p {
    padding-top: 16px;
    font-size: 10px;
    color: ${palette.GRAY70};
  }
`;

const ProductEtcData = ({ productId }: { productId: number }) => {
  const navigate = useNavigate();
  const { sendEventToGA } = useGA();
  const {
    updateMode,
    etcDatas,
    addedEtcDatas,
    deletedEtcDatas,
    getLoading,
    addEtcData,
    deleteFile,
    totalFileSize,
    isFileSizeExceed,
    addProductEtcDatas,
    deleteProductEtcDatas,
    saveLoading,
  } = useProductEtcDatas(productId);

  const ctaText = updateMode ? '수정' : '저장';

  const handleSave = async () => {
    if (isFileSizeExceed) {
      return message.warning('최대 용량인 100 MB를 초과했습니다.');
    }

    if (addedEtcDatas.length === 0 && deletedEtcDatas.length === 0) {
      return message.warning(messages.NO_NEED_TO_UPDATE);
    }

    if (addedEtcDatas.length > 0 && deletedEtcDatas.length > 0) {
      try {
        Promise.all([
          addProductEtcDatas({ files: addedEtcDatas }),
          deleteProductEtcDatas({ productEtcDataIds: deletedEtcDatas }),
        ]).then(() => {
          sendEventToGA({
            documentName: '기타 자료 (클라우드)',
            actionType: updateMode ? EGAActionType.UPDATE : EGAActionType.REGISTER,
          });
          message.success(`${ctaText}되었습니다.`);
          navigate(-1);
        });
      } catch (e) {
        console.log(e);
      }
    } else if (addedEtcDatas.length > 0) {
      addProductEtcDatas(
        { files: addedEtcDatas },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: '기타 자료 (클라우드)',
              actionType: updateMode ? EGAActionType.UPDATE : EGAActionType.REGISTER,
            });
            message.success(`${ctaText}되었습니다.`);
            navigate(-1);
          },
        },
      );
    } else if (deletedEtcDatas.length > 0) {
      deleteProductEtcDatas(
        { productEtcDataIds: deletedEtcDatas },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: '기타 자료 (클라우드)',
              actionType: updateMode ? EGAActionType.UPDATE : EGAActionType.REGISTER,
            });
            message.success(`${ctaText}되었습니다.`);
            navigate(-1);
          },
        },
      );
    }
  };

  return (
    <ProductEtcDataContainer>
      <ProductEtcDataTable etcDatas={etcDatas} loading={getLoading} onDeleteFile={deleteFile} />
      <Flex align="flex-end" dir="column">
        <Progress
          percent={(totalFileSize * 100) / (100 * 10 ** 6)}
          showInfo={false}
          status={totalFileSize > 100 * 10 ** 6 ? 'exception' : 'active'}
          style={{ width: 240 }}
        />
        <Typography.Text align="right" medium>
          <Typography.Text inline color={isFileSizeExceed ? 'MESSAGE_ERROR' : 'PRIMARY50'} medium>
            {getReadableFileSize(totalFileSize)}
          </Typography.Text>{' '}
          / 100 MB
        </Typography.Text>
        <Typography.Text type="CAPTION" align="right">
          (사용된 용량 / 제품 할당 용량)
        </Typography.Text>
      </Flex>
      <Typography.Text type="BODY_2" gutter={{ top: 16, bottom: 8 }}>
        파일 업로드
      </Typography.Text>
      <StyledUploadDragger
        multiple
        beforeUpload={(file) => {
          addEtcData(file);
          return false;
        }}
        itemRender={() => null}
      >
        <Button>
          <Icon name="plus" color="GRAY60" style={{ margin: '0 auto' }} />
          파일 선택
        </Button>
        <Typography.Text type="BODY_2" gutter={{ top: 8 }} color="GRAY70">
          버튼 클릭 혹은 드래그하여
          <br />
          파일을 첨부하세요.
        </Typography.Text>
      </StyledUploadDragger>
      <FooterBox>
        <Button type="primary" onClick={handleSave} loading={saveLoading}>
          {ctaText}
        </Button>
      </FooterBox>
    </ProductEtcDataContainer>
  );
};

export default ProductEtcData;

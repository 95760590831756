import { memo, useEffect } from 'react';
import styled from 'styled-components';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useAdStatisticCount } from 'service/ad/ad';
import { AdRawMaterial } from 'types/material/rawMaterialSearch';
import { StyledTable, columns } from './common';
import { useNavigate } from 'react-router-dom';

const Container = styled.div``;

const AdBox = styled(Flex)`
  height: 24px;
  padding: 0px 8px;
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  cursor: pointer;
`;

const AdRawMaterialSearchTable = ({
  adRawMaterials,
  isAdLoading,
}: {
  adRawMaterials?: AdRawMaterial[];
  isAdLoading: boolean;
}) => {
  const navigate = useNavigate();
  const { addAdClickCount, addAdExposureCount } = useAdStatisticCount();

  useEffect(() => {
    if (adRawMaterials && adRawMaterials.length !== 0) {
      addAdExposureCount(
        adRawMaterials.map(({ companyPurchaseProductId }) => companyPurchaseProductId),
      );
    }
  }, [adRawMaterials]);

  return (
    <Container>
      <Flex align="center" justify="space-between" style={{ margin: '16px 0 8px' }}>
        <Flex gap={8} align="center">
          <Icon name="good" size={20} />
          <Typography.Text medium color="SLATE_GRAY70">
            주목할 만한 원료
          </Typography.Text>
        </Flex>
        <Flex gap={4}>
          <Tip
            visibleCloseIcon={false}
            trigger="click"
            icon={
              <AdBox align="center" gap={4}>
                <Icon name="info" size={14} circleColor="GRAY50" />
                <Typography.Text type="BODY_2" color="GRAY60">
                  AD
                </Typography.Text>
              </AdBox>
            }
          >
            <Typography.Text type="CAPTION" style={{ letterSpacing: 0 }}>
              광고 진행 원료 자료를 기반으로 검색 키워드,
              <br /> 필터에 맞는 검색결과가 노출됩니다.
            </Typography.Text>
          </Tip>
        </Flex>
      </Flex>
      <StyledTable
        scroll={{
          y: '100%',
          x: '100%',
        }}
        isAd
        loading={isAdLoading}
        columns={columns as any}
        dataSource={adRawMaterials}
        rowKey="materialId"
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              addAdClickCount((record as AdRawMaterial).companyPurchaseProductId);
              navigate(
                `${path.material.rawMaterial.detailView}/${(record as AdRawMaterial).materialId}`,
              );
            },
          };
        }}
      />
    </Container>
  );
};

export default memo(AdRawMaterialSearchTable, (prevProps, nextProps) => {
  for (const [prevKey, prevValue] of Object.entries(prevProps)) {
    if (
      prevValue !==
      nextProps[
        prevKey as keyof {
          adRawMaterials?: AdRawMaterial[];
          isAdLoading: boolean;
        }
      ]
    ) {
      return false;
    }
  }

  return true;
});

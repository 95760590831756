import qs from 'qs';

import client from 'lib/api/client';
import { createFormData } from 'lib/file';
import { APIResponse } from 'types/common';
import {
  MaterialCompanyFactory,
  CompanyRegister,
  CompanyUpdate,
  ManufactureCompanyFactory,
  ManufactureCompanyFactoryUpdate,
} from 'types/company';

export const getCompany = (companyId: number) => client.get(`/v1/companies/${companyId}`);
export const registerCompany = (company: CompanyRegister) => client.post('/v1/companies', company);
export const updateCompany = (company: CompanyUpdate) => client.patch('/v1/companies', company);
export const getCompanyStats = (companyId: number) => client.get(`/company-stats/${companyId}`);
export const registerFactory = (factory: ManufactureCompanyFactory) =>
  client.post('/pub/company/factory/register', factory);
export const getFactories = (companyId: number) => client.get(`/company/factories/${companyId}`);
export const updateFactory = (factory: ManufactureCompanyFactoryUpdate) =>
  client.post(
    `/company/factory/patch/${factory.companyId}/${factory.companyFactoryId}`,
    createFormData(factory),
  );
export const deleteFactory = (companyFactoryId: number) =>
  client.delete(`/company/factory?companyFactoryId=${companyFactoryId}`);

// Material
export const getMaterialFactories = (companyId: number) =>
  client.get(`/v2/company/factories/${companyId}`);
export const registerMaterialFactory = (materialFactory: MaterialCompanyFactory[]) =>
  client.post('/v2/pub/company/factory/register', materialFactory);
export const updateMaterialFactory = ({
  companyId,
  factories,
}: {
  companyId: number;
  factories: MaterialCompanyFactory[];
}) => client.patch(`/v2/company/${companyId}/factories/patch`, factories);
export const deleteMaterialFactory = (materialFactoryIds: number[]) =>
  client.delete(`/v2/company/factory?${qs.stringify({ materialFactoryIds }, { indices: false })}`);
export const getManufacturerBizLicenseEnUrl = (productId: number) =>
  client.get<APIResponse<string>>(`/v1/companies/biz-license/en/${productId}`);

import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { message } from 'antd';

interface PrivateRouteProps {
  check?: boolean;
  redirect: string;
  message?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ check, redirect, message: msg }) => {
  useEffect(() => {
    if (!check && msg) {
      message.warning(msg);
    }
  }, []);

  return check ? <Outlet /> : <Navigate to={redirect} />;
};

export default PrivateRoute;

import ProductArtworkAIScreeningContainer from 'containers/product/ProductArtworkAIScreeningContainer';
import { useCurrentProduct } from 'service/brand/product/product';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '하늘색 점선으로 표기된 영역은 보완 사항이 있는 부분으로, 클릭하시면 상세 내역을 확인하실 수 있습니다.',
  '다운로드하여 더 큰 이미지를 확인하실 수 있으며, 보완사항은 수정하여 재업로드 해주시기 바랍니다.',
];

const ProductArtworkAIScreeningPage = () => {
  const currentProduct = useCurrentProduct();

  return (
    <ProductPageTemplate
      subtitle=""
      notices={notices}
      noNeedEtc={true}
      fixedTitle={'AI 스크리닝 결과'}
    >
      <ProductArtworkAIScreeningContainer
        enName={currentProduct ? currentProduct.productDetail.productNameEn : ''}
        netWeight={currentProduct ? currentProduct.netWeight : 0}
        newWeightUnit={currentProduct ? currentProduct.netWeightUnit : ''}
      />
    </ProductPageTemplate>
  );
};

export default ProductArtworkAIScreeningPage;

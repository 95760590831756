import { useParams } from 'react-router-dom';

import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';
import { RawMaterialDetail } from 'components/material/rawMaterial/RawMaterialDetail';
import MistakeNoticeItem from 'components/MistakeNoticeItem';

const RawMaterialDetailPage = () => {
  const notices = [
    '원료에 대한 상세한 정보를 입력해 주세요.',
    '원료의 상세 정보를 입력하시면, 원료 검색에서 더 많은 정보가 노출됩니다.',
    <MistakeNoticeItem />,
  ];
  const { materialId } = useParams<{ materialId: string }>();

  return (
    <RawMaterialPageTemplate documentName="상세 정보" notices={notices}>
      <RawMaterialDetail materialId={Number(materialId)} />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialDetailPage;

import ProductPageTemplate from 'templates/ProductPageTemplate';
import { useCurrentProduct } from 'service/brand/product/product';
import ProductEtcData from 'components/product/ProductEtcData';

const notices = [
  '현재 페이지는 슈퍼 유저와 하위 담당자에게만 공개되며, 자료를 직접 업로드하여 자유롭게 관리할 수 있습니다.',
  '한 제품 당 최대 100 MB 까지 업로드할 수 있습니다.',
];

const ProductEtcDataPage = () => {
  const currentProduct = useCurrentProduct();
  const productId = currentProduct.productId;

  return (
    <ProductPageTemplate subtitle="기타 자료 (클라우드)" notices={notices}>
      <ProductEtcData productId={productId} />
    </ProductPageTemplate>
  );
};

export default ProductEtcDataPage;

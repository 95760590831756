import { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Radio, Row, Space, Spin, TimePicker } from 'antd';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import { useProductCountryVendor } from 'service/brand/product/countryVendor';
import { Tip, Typography } from 'components/system';
import DayLabel from 'components/system/form/DayLabel';
import UpdateLog from 'components/product/UpdateLog';
import { DocumentCode } from 'types/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

const ProductCountryVendorBlock = styled.div<{
  addModeWithUsingPartnerAgency: boolean;
}>`
  width: 520px;
  margin: 0 auto;

  .ant-form-item-control-input {
    min-height: 0px;
  }

  .ant-checkbox {
    display: none;
  }

  .ant-checkbox + span {
    padding-left: 0px;
    padding-right: 4px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  // HINT : 등록시 스킨가든 정보를 사용한 form은 disabled이지만 ui는 active한 상태로 보여줘야 함. (수정 모드일 때는 ui도 disabled)
  ${(props) =>
    props.addModeWithUsingPartnerAgency &&
    css`
      input.ant-input[disabled] {
        color: rgba(0, 0, 0, 0.85) !important;
        background-color: #fff !important;
      }
      .ant-picker-input > input[disabled],
      .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator,
      .ant-picker.ant-picker-disabled .ant-picker-suffix {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .ant-picker.ant-picker-disabled {
        background: #fff;
      }
    `}
`;

const dayOptions = [
  {
    label: '월요일',
    value: 'isOpenMonday',
  },
  {
    label: '화요일',
    value: 'isOpenTuesday',
  },
  {
    label: '수요일',
    value: 'isOpenWednesday',
  },
  {
    label: '목요일',
    value: 'isOpenThursday',
  },
  {
    label: '금요일',
    value: 'isOpenFriday',
  },
  {
    label: '토요일',
    value: 'isOpenSaturday',
  },
  {
    label: '일요일',
    value: 'isOpenSunday',
  },
];

const InputFormItems = ({
  isUsePartnerVendor = false,
  readOnlyMode,
}: {
  isUsePartnerVendor?: boolean;
  readOnlyMode: boolean;
}) => {
  const addModeWithUsingPartnerVendor = isUsePartnerVendor && !readOnlyMode;
  return (
    <>
      <Form.Item
        label="판매처"
        name="vendorName"
        required
        rules={[
          requireRule,
          exceptKoreanRule,
          {
            pattern: /^.{1,30}$/i,
            message: '최대 30자',
          },
        ]}
      >
        <Input disabled={isUsePartnerVendor || readOnlyMode} />
      </Form.Item>
      <Form.Item
        label={
          <Row gutter={6} align="middle">
            <Col>전화번호</Col>
            <Col>
              <Tip bodyStyle={{ width: 360 }} trigger="click">
                <Typography.Text type="SMALL">
                  상품에 관한 상담 문의를 할 수 있는 곳의 전화번호를 입력해 주세요.
                </Typography.Text>
              </Tip>
            </Col>
          </Row>
        }
        rules={[
          {
            pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z]*$/i,
            message: '숫자 및 기호 입력가능',
          },
          {
            pattern: /^.{1,20}$/i,
            message: '최대 20자',
          },
        ]}
        name="vendorTel"
      >
        <Input disabled={isUsePartnerVendor || readOnlyMode} />
      </Form.Item>
      <Form.Item
        label={
          <Row gutter={6} align="middle">
            <Col>회사 운영 시간</Col>
            <Col>
              <Tip bodyStyle={{ width: 360 }} trigger="click">
                <Typography.Text type="SMALL">
                  상품에 관한 상담 문의를 할 수 있는 곳의 운영 시간을 입력해 주세요.
                </Typography.Text>
              </Tip>
            </Col>
          </Row>
        }
        name="businessHours"
      >
        <TimePicker.RangePicker
          format="HH:mm"
          placeholder={['시작 시간', '끝 시간']}
          style={{ width: '100%' }}
          disabled={isUsePartnerVendor || readOnlyMode}
        />
      </Form.Item>
      <Form.Item label="영업일">
        <Form.Item
          shouldUpdate={(prev, next) => prev.isUsePartnerVendor !== next.isUsePartnerVendor}
        >
          {({ getFieldValue }) =>
            dayOptions.map(({ label, value }) => (
              <Form.Item
                noStyle
                name={value}
                initialValue={false}
                key={value}
                valuePropName="checked"
              >
                <Checkbox disabled={isUsePartnerVendor || readOnlyMode}>
                  {isUsePartnerVendor ? (
                    <DayLabel
                      label={label}
                      initialSelected
                      disabled={isUsePartnerVendor || readOnlyMode}
                      opacity={addModeWithUsingPartnerVendor ? 1 : undefined}
                    />
                  ) : (
                    <DayLabel
                      label={label}
                      initialSelected={
                        getFieldValue(value) ??
                        (value !== 'isOpenSaturday' && value !== 'isOpenSunday')
                      }
                      disabled={isUsePartnerVendor || readOnlyMode}
                      opacity={addModeWithUsingPartnerVendor ? 1 : undefined}
                    />
                  )}
                </Checkbox>
              </Form.Item>
            ))
          }
        </Form.Item>
      </Form.Item>
    </>
  );
};

const ProductCountryVendor = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const {
    form,
    isUsePartnerAgency,
    updateMode,
    getCheckListLoading,
    getLoading,
    fetchLoading,
    onSubmit,
    setSkinGardenForm,
    initForm,
  } = useProductCountryVendor(productId, countryId);
  const readOnlyMode = useSelector(({ certificate }: any) => certificate.readOnlyMode);
  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.CV}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog productId={productId} countryId={countryId} documentCode={DocumentCode.CV} />
          </Col>
        )}
      </Row>
      <ProductCountryVendorBlock
        addModeWithUsingPartnerAgency={isUsePartnerAgency && !readOnlyMode}
      >
        <Spin spinning={getCheckListLoading || getLoading}>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Form.Item
              label="판매처 정보를 입력하시겠습니까?"
              name="isRegisterVendor"
              rules={[requireRule]}
            >
              <Radio.Group
                onChange={(e) =>
                  form.setFieldsValue({
                    isUsePartnerVendor: undefined,
                    vendorName: undefined,
                    vendorTel: undefined,
                    businessHours: undefined,
                    isOpenMonday: true,
                    isOpenTuesday: true,
                    isOpenWednesday: true,
                    isOpenThursday: true,
                    isOpenFriday: true,
                    isOpenSaturday: false,
                    isOpenSunday: false,
                  })
                }
                disabled={readOnlyMode}
              >
                <Space direction="vertical">
                  <Radio value={true} style={{ marginBottom: 8 }}>
                    네
                  </Radio>
                  <Radio value={false}>아니오 (라벨 데이터에 판매처 정보가 기재되지 않음)</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, next) => prev.isRegisterVendor !== next.isRegisterVendor}
              noStyle
            >
              {({ getFieldValue }) =>
                getFieldValue('isRegisterVendor') && (
                  <>
                    {isUsePartnerAgency ? (
                      <>
                        <Form.Item
                          label="certicos의 현지 판매처 (스킨가든) 정보를 사용하시겠습니까?"
                          name="isUsePartnerVendor"
                          rules={[requireRule]}
                        >
                          <Radio.Group disabled={readOnlyMode}>
                            <Space direction="vertical">
                              <Radio
                                value={true}
                                style={{ marginBottom: 8 }}
                                onChange={setSkinGardenForm}
                              >
                                네
                              </Radio>
                              <Radio value={false} onChange={initForm}>
                                아니요
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          shouldUpdate={(prev, next) =>
                            prev.isUsePartnerVendor !== next.isUsePartnerVendor
                          }
                          noStyle
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('isUsePartnerVendor') !== undefined && (
                              <InputFormItems
                                isUsePartnerVendor={getFieldValue('isUsePartnerVendor')}
                                readOnlyMode={readOnlyMode}
                              />
                            )
                          }
                        </Form.Item>
                      </>
                    ) : (
                      <InputFormItems readOnlyMode={readOnlyMode} />
                    )}
                  </>
                )
              }
            </Form.Item>
            <FooterBox style={{ paddingTop: 40 }}>
              <ReadOnlyBackButton readOnly={readOnlyMode}>
                {updateMode && <CorrectRequestWithoutChangeButton documentCode={DocumentCode.CV} />}
                <Button loading={fetchLoading} type="primary" htmlType="submit">
                  {!updateMode ? '등록' : '보완 완료'}
                </Button>
              </ReadOnlyBackButton>
            </FooterBox>
          </Form>
        </Spin>
      </ProductCountryVendorBlock>
    </>
  );
};

export default ProductCountryVendor;

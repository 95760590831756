import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { message, Typography } from 'antd';

import palette from 'lib/styles/palette';
import * as productActions from 'modules/product';
import ProductDetail from 'components/product/ProductDetail';
import useUpdateEffect from 'hook/useUpdateEffect';
import { useRegisterProduct } from 'service/brand/product/product';
import { ProductStatus } from 'types/product';

const ProductDetailContainer = () => {
  const { productId } = useParams<{
    productId?: string;
  }>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { registerProduct } = useRegisterProduct();
  const { documents, currentProduct, fetchLoading, registerLoading } = useSelector(
    ({ product, loading }: any) => ({
      documents: product.documents,
      currentProduct: product.currentProduct,
      fetchLoading: loading['product/GET_DOCUMENTS'] || loading['product/GET_DOC_STATUS'],
      registerLoading: loading['product/REGISTER_PRODUCT'],
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(productActions.getDocuments(Number(productId)));
    dispatch(productActions.setSkipMode(false));
  }, [productId]);

  useUpdateEffect(() => {
    if (documents.length && registerable) {
      message.info(
        <>
          모든 정보가 입력 완료되어, 인증을 진행하실 수 있습니다.{' '}
          <Typography.Text style={{ color: palette.PRIMARY50, fontSize: 16 }}>
            '등록 완료'
          </Typography.Text>{' '}
          버튼을 눌러주세요.
        </>,
      );
    }
  }, [documents]);

  const registerable = useMemo(
    () =>
      documents.length !== 0 &&
      documents.every(({ status }: any) => status !== 'INP') &&
      currentProduct.status === 'PRD-RDY',
    [documents],
  );

  const handleClickRegister = () => {
    registerProduct({
      productId: Number(productId),
      status: ProductStatus.PRD_REG,
    });
  };

  const handleClickDocument = (document: any) => {
    if (document.isPermit === false) {
      message.warning('슈퍼유저에 의해 전성분표 열람이 제한되어 있습니다.');
      return;
    }
    navigate(`/product/${productId}/${document.documentCode}`);
  };

  return (
    <ProductDetail
      productId={productId}
      product={currentProduct}
      documents={documents}
      fetchLoading={fetchLoading}
      registerLoading={registerLoading}
      registerable={registerable}
      onClickDocument={handleClickDocument}
      onClickRegister={handleClickRegister}
    />
  );
};

export default ProductDetailContainer;

import { Button, Col, Modal, Row } from 'antd';
import styled, { css } from 'styled-components';
import { useCallback, useMemo, useState } from 'react';

import { useUpdateLog } from 'hook/product';
import TabButton from 'components/TabButton';
import { Typography } from 'components/system';
import { downloadFile, downloadFiles, getFilenameFromUrl } from 'lib/file';
import usePreviewModal from 'hook/usePreviewModal';
import { AllDocumentCodeType, DocumentCode } from 'types/product';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

const UpdateLogBlock = styled.div<{
  gutter?: number | { top?: number; bottom?: number; left?: number; right?: number };
}>`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;

  ${({ gutter }) => {
    if (typeof gutter === 'number') {
      return css`
        margin: ${gutter}px;
      `;
    }
    if (typeof gutter === 'object') {
      return css`
        margin-top: ${gutter.top ? `${gutter.top}px` : 0};
        margin-bottom: ${gutter.bottom ? `${gutter.bottom}px` : 0};
        margin-left: ${gutter.left ? `${gutter.left}px` : 0};
        margin-right: ${gutter.right ? `${gutter.right}px` : 0};
      `;
    }
  }}

  .ant-btn-gray {
    height: 32px;
  }
`;

// TODO : 현재 효능 평가 자료 fileUploadUrl 이 null로 오고 있어 임시 방편으로 작성.
// fileUploadUrl 추가되면 필요에 따라 재수정 필요.
const fileModeDocumentCodes: AllDocumentCodeType[] = [DocumentCode.PEEC];

const checkIsDeleted = (content: string | null) => {
  const splitContent = (content || '').split('___');

  // TODO : 삭제된 경우를 판별할 필드가 있는 경우 수정
  return (
    splitContent.length >= 2 &&
    splitContent[1] === '해당 효능이 [제품 효능 정보]에서 삭제되었습니다.'
  );
};

const UpdateLog = ({
  productId,
  countryId,
  documentCode,
  validCodes,
  gutter,
}: {
  productId: number;
  countryId?: number;
  documentCode: AllDocumentCodeType;
  validCodes?: string[];
  gutter?: number | { top?: number; bottom?: number; left?: number; right?: number };
}) => {
  const [isVisibleLogs, setIsVisibleLogs] = useState(false);
  const { fields, selectedField, setSelectedField, allDocumentLogs, loading } = useUpdateLog({
    productId,
    countryId,
    documentCode,
    validCodes,
    isVisibleLogs,
  });
  const { PreviewModal, setUrl } = usePreviewModal();

  const documentLogs = useMemo(() => {
    return (
      allDocumentLogs.find((item: any) => item.documentFieldId === selectedField?.documentFieldId)
        ?.list || []
    );
  }, [allDocumentLogs, selectedField]);

  const fileMode = useMemo(
    () =>
      documentLogs.length > 0 &&
      (documentLogs[0].uploadFileUrl !== null || fileModeDocumentCodes.includes(documentCode)),
    [documentLogs],
  );

  const columns = useMemo(
    () => [
      ...(documentCode === DocumentCode.PEEC
        ? [
            {
              title: '제품 효능',
              align: 'center' as const,
              width: '18%',
              dataIndex: 'content',
              render: (content: string | null) =>
                checkIsDeleted(content)
                  ? {
                      props: { colSpan: 0 },
                    }
                  : content?.split('___')[0] || '-',
            },
          ]
        : []),
      {
        title: '내용',
        align: 'center' as const,
        width: fileMode ? (documentCode === DocumentCode.PEEC ? '23%' : '40%') : '52%',
        render: ({ content }: { content: string | null; filename: string }) => {
          return {
            props: { colSpan: checkIsDeleted(content) ? 5 : 1 },
            children: (
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {documentCode === DocumentCode.PEEC
                  ? (content?.split('___')?.length || 0) >= 2
                    ? content?.split('___')[1]
                    : '-'
                  : content}
              </pre>
            ),
          };
        },
      },
      ...(fileMode && documentCode !== DocumentCode.FORMBR && documentCode !== DocumentCode.FORMBRC
        ? [
            {
              title: '업로드 파일',
              align: 'center' as const,
              width: '16%',
              render: ({
                uploadFileUrl,
                content,
              }: {
                uploadFileUrl: string;
                content: string | null;
              }) =>
                checkIsDeleted(content)
                  ? {
                      props: { colSpan: 0 },
                    }
                  : uploadFileUrl && (
                      <Icon
                        name="page"
                        style={{ margin: '0 auto' }}
                        onClick={() => {
                          if (uploadFileUrl.includes(',https://')) {
                            const uploadFileUrls = uploadFileUrl
                              .split(',https://')
                              .map((url, index) => (index === 0 ? url : `https://${url}`));
                            if (
                              uploadFileUrls.some(
                                (url) =>
                                  url.endsWith('.xlsx') ||
                                  url.endsWith('.ai') ||
                                  url.endsWith('.zip'),
                              )
                            ) {
                              downloadFiles(
                                uploadFileUrls.map((url) => ({
                                  name: getFilenameFromUrl(url),
                                  url,
                                })),
                              );
                            } else {
                              setUrl(uploadFileUrls);
                            }
                          } else {
                            if (
                              uploadFileUrl.endsWith('.xlsx') ||
                              uploadFileUrl.endsWith('.ai') ||
                              uploadFileUrl.endsWith('.zip')
                            ) {
                              downloadFile(uploadFileUrl);
                            } else {
                              setUrl(uploadFileUrl);
                            }
                          }
                        }}
                      />
                    ),
            },
          ]
        : []),
      {
        title: '입력자 명',
        align: 'center' as const,
        width: '20%',
        render: ({
          name,
          companyName,
          content,
        }: {
          name: string;
          companyName: string;
          content: string | null;
        }) =>
          checkIsDeleted(content)
            ? {
                props: { colSpan: 0 },
              }
            : `${name} (${companyName})`,
      },
      {
        title: '입력 일시',
        width: '23%',
        align: 'center' as const,
        render: ({ registerDt, content }: { registerDt: string; content: string | null }) =>
          checkIsDeleted(content)
            ? {
                props: { colSpan: 0 },
              }
            : registerDt.replace('T', ' '),
      },
    ],
    [fileMode],
  );
  const handleClickShowLog = useCallback(() => {
    setSelectedField(fields[0]);
    setIsVisibleLogs(true);
  }, [fields]);
  const handleClickClose = useCallback(() => {
    setSelectedField(null);
    setIsVisibleLogs(false);
  }, []);

  return (
    <UpdateLogBlock gutter={gutter}>
      <PreviewModal />
      <Modal open={isVisibleLogs} width={800} footer={null} centered onCancel={handleClickClose}>
        <Typography.Text type="TITLE_1" gutter={{ bottom: 24 }}>
          수정 이력 보기
        </Typography.Text>
        {fields && fields.length > 1 && (
          <Row gutter={[4, 4]}>
            {fields.map((field: any) => (
              <Col key={field.code}>
                <TabButton
                  selected={field.code === (selectedField as null | any)?.code}
                  onClick={() => setSelectedField(field)}
                >
                  {field.name}
                </TabButton>
              </Col>
            ))}
          </Row>
        )}
        {documentCode === DocumentCode.PEI && <TabButton selected={true}>제품 효능</TabButton>}
        <Table
          columns={columns}
          loading={loading}
          dataSource={documentLogs}
          rowKey={(item: any) => item.documentChangeLogId}
          pagination={{ pageSize: 5 }}
          style={{ marginTop: 24, height: 320, overflowY: 'scroll' }}
        />
      </Modal>
      {fields && (
        <Button
          className="ant-btn-gray"
          icon={<Icon name="history" size={16} color="GRAY70" />}
          onClick={handleClickShowLog}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography.Text type="BODY_2" inline>
            수정 이력
          </Typography.Text>
        </Button>
      )}
    </UpdateLogBlock>
  );
};

export default UpdateLog;

import { useRoutes } from 'react-router-dom';

import RawMaterialBasicPage from 'pages/material/rawMaterial/RawMaterialBasicPage';
import RawMaterialDocumentsPage from 'pages/material/rawMaterial/RawMaterialDocumentsPage';
import RawMaterialSearchPage from 'pages/material/rawMaterial/RawMaterialSearchPage';
import RawMaterialDetailViewPage from 'pages/material/rawMaterial/RawMaterialDetailViewPage';
import RawMaterialCertificationPage from 'pages/material/rawMaterial/RawMaterialCertificationPage';
import RawMaterialCoaPage from 'pages/material/rawMaterial/RawMaterialCoaPage';
import RawMaterialMSDSPage from 'pages/material/rawMaterial/RawMaterialMSDSPage';
import RawMaterialMarketingPage from 'pages/material/rawMaterial/RawMaterialMarketingPage';
import Error404Page from 'pages/error/Error404Page';
import RawMaterialAllergensListPage from 'pages/material/rawMaterial/RawMaterialAllergensListPage';
import RawMaterialCompositionInformationPage from 'pages/material/rawMaterial/RawMaterialCompositionInformationPage';
import RawMaterialIFRACertificatePage from 'pages/material/rawMaterial/RawMaterialIFRACertificatePage';
import RawMaterialEtcDataPage from 'pages/material/rawMaterial/RawMaterialEtcDataPage';
import RawMaterialDetailPage from 'pages/material/rawMaterial/RawMaterialDetailPage';

const RawMaterialRouter = () => {
  const element = useRoutes([
    { path: 'basic', element: <RawMaterialBasicPage /> },
    { path: 'basic/:materialId', element: <RawMaterialBasicPage /> },
    { path: 'search', element: <RawMaterialSearchPage /> },
    { path: 'detail-view/:materialId', element: <RawMaterialDetailViewPage /> },
    { path: ':materialId', element: <RawMaterialDocumentsPage /> },
    { path: 'detail/:materialId', element: <RawMaterialDetailPage /> },
    {
      path: 'certification/:materialId',
      element: <RawMaterialCertificationPage />,
    },
    { path: 'coa/:materialId', element: <RawMaterialCoaPage /> },
    { path: 'msds/:materialId', element: <RawMaterialMSDSPage /> },
    { path: 'marketing/:materialId', element: <RawMaterialMarketingPage /> },
    { path: 'allergens-list/:materialId', element: <RawMaterialAllergensListPage /> },
    {
      path: 'composition-information/:materialId',
      element: <RawMaterialCompositionInformationPage />,
    },
    { path: 'ifra-certificate/:materialId', element: <RawMaterialIFRACertificatePage /> },
    { path: 'etc-data/:materialId', element: <RawMaterialEtcDataPage /> },
    { path: '*', element: <Error404Page /> },
  ]);

  return element;
};

export default RawMaterialRouter;

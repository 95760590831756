import palette from './palette';

export const theme = {
  components: {
    Layout: {
      headerHeight: 80,
    },
    Menu: {
      itemHeight: 40,
      itemPaddingInline: 20,
    },
    Message: {
      contentPadding: '16px 20px 12px',
    },
    token: {
      colorPrimary: palette.PRIMARY50,
    },
  },
};

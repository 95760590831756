import { Button, Table } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import type { ColumnsType } from 'antd/es/table';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import RangePicker from 'components/ui/DatePicker/RangePicker';
import Icon from 'components/ui/Icon/Icon';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { QCQAOutputListPageSearchParams } from 'pages/brand/qcqa/QCQAOutputPage';
import { useProductOutputs } from 'service/brand/qcqa/qcqa';

interface OutputColumnData {
  qcqaProductId: number;
  productNameKo: string;
  productNameEn: string;
  lotNo: string;
  qcqaProductLotnoId: number;
  netWeight: number;
  netWeightUnit: string;
  manufacturerNameKo: string;
  manufacturingDate: string;
  output: number;
  rowSpan: number;
}

const QCQAOutputList = ({ queryParams }: { queryParams: QCQAOutputListPageSearchParams }) => {
  const navigate = useNavigate();
  const { outputDateLower, outputDateUpper } = queryParams;
  const today = moment();
  const currYear = String(today.year());
  const [selectedYear, setSelectedYear] = useState<string | null>(String(currYear));
  const [outputDateBuffer, setOutputDateBuffer] = useState({
    outputDateLower,
    outputDateUpper,
  });
  const [currentProductId, setCurrentProductId] = useState<number | null>(null);
  const [currentLotId, setCurrentLotId] = useState<number | null>(null);
  const {
    productOutputs,
    isProductOutputsLoading,
    downloadProductOutputExcel,
    downloadProductOutputExcelLoading,
  } = useProductOutputs({
    from: outputDateBuffer.outputDateLower,
    to: outputDateBuffer.outputDateUpper,
  });

  const pastFiveYears: number[] = [];

  useEffect(() => {
    if (selectedYear === currYear) {
      setOutputDateBuffer({
        outputDateLower: `${currYear}-01-01`,
        outputDateUpper: today.format('YYYY-MM-DD'),
      });
    }
  }, [selectedYear]);

  const tableData = useMemo(() => {
    const newMap: Record<number, OutputColumnData> = {};
    const lots = productOutputs?.map(({ lotnos }) => lotnos).flat(1);

    lots?.forEach(
      (
        {
          lotNo,
          qcqaProductLotnoId: lotId,
          netWeight,
          netWeightUnit,
          manufacturerNameKo,
          manufacturingDate,
          output,
        },
        idx,
      ) => {
        const productInfo = productOutputs?.find(({ lotnos }) =>
          lotnos.find(({ qcqaProductLotnoId }) => qcqaProductLotnoId === lotId),
        );
        if (!productInfo) return;

        const lotSeq = productInfo.lotnos
          .map(({ qcqaProductLotnoId }) => qcqaProductLotnoId)
          .indexOf(lotId);

        newMap[idx] = {
          qcqaProductId: productInfo?.qcqaProductId,
          productNameKo: productInfo?.productNameKo,
          productNameEn: productInfo?.productNameEn,
          lotNo,
          qcqaProductLotnoId: lotId,
          netWeight,
          netWeightUnit,
          manufacturerNameKo,
          manufacturingDate,
          output,
          rowSpan: lotSeq === 0 ? productInfo.lotnos.length : 0,
        };
      },
    );
    return Object.values(newMap);
  }, [productOutputs]);

  Array(5)
    .fill(0)
    .forEach((val, i) => {
      pastFiveYears.push(Number(currYear) - i);
    });

  const columns: ColumnsType<OutputColumnData> = [
    {
      title: '제품명',
      width: '30%',
      dataIndex: 'qcqaProductId',
      render: (_, { productNameEn, productNameKo, rowSpan }) => {
        return {
          props: {
            rowSpan,
          },
          children: (
            <Typography.Text
              style={{
                fontSize: 14,
                fontWeight: 500,
                maxWidth: 248,
                color: palette.GRAY90,
              }}
            >{`${productNameKo || productNameEn}`}</Typography.Text>
          ),
        };
      },
      onCell: ({ qcqaProductId }) => {
        return {
          style: {
            backgroundColor: currentProductId === qcqaProductId ? '#fafafa' : 'white',
            cursor: 'pointer',
          },
          onMouseEnter: () => {
            setCurrentProductId(qcqaProductId);
            setCurrentLotId(null);
          },
          onMouseLeave: () => {
            setCurrentProductId(null);
            setCurrentLotId(null);
          },
          onClick: () => {
            navigate(`${path.qcqa.management.root}/${qcqaProductId}`);
          },
        };
      },
    },

    {
      title: 'Lot No. (용량)',
      width: '19%',
      dataIndex: 'qcqaProductLotnoId',
      render: (_, { lotNo, netWeight, netWeightUnit }) => (
        <Typography.Text
          style={{ fontSize: 14, fontWeight: 500, color: palette.GRAY90 }}
        >{`${lotNo} (${netWeight} ${netWeightUnit})`}</Typography.Text>
      ),
      onCell: ({ qcqaProductId, qcqaProductLotnoId }, index) => {
        return {
          style: {
            backgroundColor:
              (currentProductId === qcqaProductId && currentLotId === qcqaProductLotnoId) ||
              (currentProductId === qcqaProductId && !currentLotId)
                ? '#fafafa'
                : 'white',
            cursor: 'pointer',
          },
          onMouseEnter: () => {
            setCurrentProductId(qcqaProductId);
            setCurrentLotId(qcqaProductLotnoId);
          },
          onMouseLeave: () => {
            setCurrentProductId(null);
            setCurrentLotId(null);
          },
          onClick: () => {
            navigate(`${path.qcqa.management.root}/${qcqaProductId}/lot/${qcqaProductLotnoId}`);
          },
        };
      },
    },
    {
      title: '생산량',
      dataIndex: 'output',
      align: 'center',
      width: '12%',
      render: (_, { output }) => (
        <Typography.Text style={{ fontSize: 14, fontWeight: 400, color: palette.GRAY70 }}>
          {output.toLocaleString()}
        </Typography.Text>
      ),
      onCell: ({ qcqaProductId, qcqaProductLotnoId }, index) => {
        return {
          style: {
            backgroundColor:
              (currentProductId === qcqaProductId && currentLotId === qcqaProductLotnoId) ||
              (currentProductId === qcqaProductId && !currentLotId)
                ? '#fafafa'
                : 'white',
            cursor: 'pointer',
          },
          onMouseEnter: () => {
            setCurrentProductId(qcqaProductId);
            setCurrentLotId(qcqaProductLotnoId);
          },
          onMouseLeave: () => {
            setCurrentProductId(null);
            setCurrentLotId(null);
          },
          onClick: () => {
            navigate(`${path.qcqa.management.root}/${qcqaProductId}/lot/${qcqaProductLotnoId}`);
          },
        };
      },
    },
    {
      title: '제조사',
      dataIndex: 'manufacturerNameKo',
      width: '27%',
      render: (_, { manufacturerNameKo }) => (
        <Typography.Text style={{ fontSize: 14, fontWeight: 400, color: palette.GRAY70 }}>
          {manufacturerNameKo}
        </Typography.Text>
      ),
      onCell: ({ qcqaProductId, qcqaProductLotnoId }, index) => {
        return {
          style: {
            backgroundColor:
              (currentProductId === qcqaProductId && currentLotId === qcqaProductLotnoId) ||
              (currentProductId === qcqaProductId && !currentLotId)
                ? '#fafafa'
                : 'white',
            cursor: 'pointer',
          },
          onMouseEnter: () => {
            setCurrentProductId(qcqaProductId);
            setCurrentLotId(qcqaProductLotnoId);
          },
          onMouseLeave: () => {
            setCurrentProductId(null);
            setCurrentLotId(null);
          },
          onClick: () => {
            navigate(`${path.qcqa.management.root}/${qcqaProductId}/lot/${qcqaProductLotnoId}`);
          },
        };
      },
    },
    {
      title: '제조일자',
      width: '12%',
      dataIndex: 'manufacturingDate',
      render: (_, { manufacturingDate }) => (
        <Typography.Text style={{ fontSize: 14, fontWeight: 400, color: palette.GRAY70 }}>
          {manufacturingDate}
        </Typography.Text>
      ),
      onCell: ({ qcqaProductId, qcqaProductLotnoId }, index) => {
        return {
          style: {
            backgroundColor:
              (currentProductId === qcqaProductId && currentLotId === qcqaProductLotnoId) ||
              (currentProductId === qcqaProductId && !currentLotId)
                ? '#fafafa'
                : 'white',
            cursor: 'pointer',
          },
          onMouseEnter: () => {
            setCurrentProductId(qcqaProductId);
            setCurrentLotId(qcqaProductLotnoId);
          },
          onMouseLeave: () => {
            setCurrentProductId(null);
            setCurrentLotId(null);
          },
          onClick: () => {
            navigate(`${path.qcqa.management.root}/${qcqaProductId}/lot/${qcqaProductLotnoId}`);
          },
        };
      },
    },
  ];

  const handleChangeFinishDate = ([outputDateLower, outputDateUpper]: [string, string]) => {
    if (outputDateLower === '' && outputDateUpper === '') {
      setSelectedYear(null);
      setOutputDateBuffer({ outputDateLower: '', outputDateUpper: '' });
    }

    if (outputDateLower === 'Invalid date' || outputDateUpper === 'Invalid date') {
      setOutputDateBuffer({ outputDateLower: '', outputDateUpper: '' });
    } else {
      setOutputDateBuffer({ outputDateLower, outputDateUpper });
    }
  };

  const handleSelectYear = (year: string) => {
    setSelectedYear(year);
    setOutputDateBuffer({
      outputDateLower: `${year}-01-01`,
      outputDateUpper: `${year}-12-31`,
    });
  };

  const handleDownExcel = () => {
    downloadProductOutputExcel({
      from: outputDateBuffer.outputDateLower,
      to: outputDateBuffer.outputDateUpper,
    });
  };

  useEffect(() => {
    if (
      !(
        /\d{4}-01-01/.test(outputDateBuffer.outputDateLower) &&
        (/\d{4}-12-31/.test(outputDateBuffer.outputDateUpper) ||
          outputDateBuffer.outputDateUpper === today.format('YYYY-MM-DD'))
      )
    ) {
      setSelectedYear(null);
    }
  }, [outputDateBuffer]);

  return (
    <Container>
      <PeriodContainer dir="column" gap={16}>
        <Flex gap={8} align="center">
          <Typography.Text style={{ fontSize: 18, fontWeight: 600, color: palette.GRAY90 }}>
            기간 선택
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14, fontWeight: 400, color: palette.GRAY80 }}>
            생산실적 현황은 최대 1년까지 조회 가능합니다.
          </Typography.Text>
        </Flex>
        <Flex gap={16} justify="space-between" style={{ width: '100%' }}>
          <Flex>
            {pastFiveYears.map((year, idx) => (
              <YearItem
                selectedIdx={pastFiveYears.indexOf(Number(selectedYear))}
                idx={idx}
                key={year}
                justify="center"
                align="center"
                onClick={() => handleSelectYear(String(year))}
              >
                <Typography.Text
                  style={{
                    minWidth: 40,
                    textAlign: 'center',
                    color: selectedYear === String(year) ? palette.PRIMARY50 : palette.GRAY50,
                    fontWeight: selectedYear === String(year) ? 500 : 400,
                  }}
                >
                  {year}
                </Typography.Text>
              </YearItem>
            ))}
          </Flex>
          <RangePicker
            startDt={outputDateBuffer.outputDateLower}
            endDt={outputDateBuffer.outputDateUpper}
            onChange={handleChangeFinishDate}
            yearRestriction={1}
          />
        </Flex>
      </PeriodContainer>
      <Flex justify="space-between" style={{ marginTop: 32, width: '100%' }} align="center">
        <Flex align="center">
          <Typography.Text
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: palette.GRAY90,
            }}
          >
            총{' '}
            <Typography.Text inline color="PRIMARY50">
              {tableData?.length}
            </Typography.Text>
            개
          </Typography.Text>
        </Flex>
        <Button
          type="primary"
          icon={
            <Icon
              name="download"
              size={18}
              style={{ fontSize: 14 }}
              color={
                (outputDateBuffer.outputDateLower === '' &&
                  outputDateBuffer.outputDateUpper === '') ||
                !productOutputs ||
                productOutputs.length === 0
                  ? 'SLATE_GRAY60'
                  : 'ETC_WHITE'
              }
            />
          }
          loading={downloadProductOutputExcelLoading}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleDownExcel}
          disabled={
            (outputDateBuffer.outputDateLower === '' && outputDateBuffer.outputDateUpper === '') ||
            !productOutputs ||
            productOutputs.length === 0
          }
        >
          엑셀 다운로드
        </Button>
      </Flex>
      <Table
        style={{ width: 1040, marginTop: 16 }}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        rowKey="qcqaProductLotnoId"
        loading={isProductOutputsLoading}
      />
    </Container>
  );
};

const Container = styled(Flex)`
  flex-direction: column;

  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 10px 16px !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 18px 16px !important;
  }
`;

const PeriodContainer = styled(Flex)`
  min-width: 1040px;
  background-color: ${palette.GRAY10};
  border-radius: 8px;
  padding: 40px;
`;

const YearItem = styled(Flex)<{ idx: number; selectedIdx: number }>`
  width: 137px;
  height: 44px;
  cursor: pointer;
  border: 1px solid ${palette.GRAY40};
  background-color: white;

  ${({ idx, selectedIdx }) =>
    css`
      border-color: ${selectedIdx === idx ? palette.PRIMARY50 : palette.GRAY40};
    `}

  ${({ idx, selectedIdx }) =>
    idx === 0 &&
    css`
      border-radius: 4px 0 0 4px;
      border-right: ${idx === selectedIdx ? `1px solid ${palette.PRIMARY50}` : `0px`};
    `}

  ${({ idx, selectedIdx }) =>
    idx === 4 &&
    css`
      border-radius: 0 4px 4px 0;
      border-right: 1px solid ${selectedIdx === idx ? palette.PRIMARY50 : palette.GRAY40};
      border-left: ${idx === selectedIdx ? `1px solid ${palette.PRIMARY50}` : `0px`};
    `}
  
  ${({ selectedIdx, idx }) =>
    selectedIdx === idx &&
    css`
      border-color: ${selectedIdx === idx ? palette.PRIMARY50 : palette.GRAY40};
    `}

  ${({ idx, selectedIdx }) =>
    idx === 1 &&
    css`
      border: ${idx === selectedIdx
        ? `1px solid ${palette.PRIMARY50}`
        : `1px solid ${palette.GRAY40}`};
      border-right: ${idx === selectedIdx ? `1px solid ${palette.PRIMARY50}` : `0px`};
      border-left: ${idx === selectedIdx
        ? `1px solid ${palette.PRIMARY50}`
        : selectedIdx === 0
        ? `0px`
        : `1px solid ${palette.GRAY40}`};
    `}
    ${({ idx, selectedIdx }) =>
    idx === 2 &&
    css`
      border: ${idx === selectedIdx
        ? `1px solid ${palette.PRIMARY50}`
        : `1px solid ${palette.GRAY40}`};
      border-right: ${idx === selectedIdx ? `1px solid ${palette.PRIMARY50}` : `0px`};
      border-left: ${idx === selectedIdx
        ? `1px solid ${palette.PRIMARY50}`
        : selectedIdx === 1
        ? `0px`
        : `1px solid ${palette.GRAY40}`};
    `}
    ${({ idx, selectedIdx }) =>
    idx === 3 &&
    css`
      border: ${idx === selectedIdx
        ? `1px solid ${palette.PRIMARY50}`
        : `1px solid ${palette.GRAY40}`};
      border-right: ${idx === selectedIdx
        ? `1px solid ${palette.PRIMARY50}`
        : selectedIdx !== 4
        ? `1px solid ${palette.GRAY40}`
        : `0px`};
      border-left: ${idx === selectedIdx
        ? `1px solid ${palette.PRIMARY50}`
        : selectedIdx === 2
        ? `0px`
        : `1px solid ${palette.GRAY40}`};
    `}
`;

export default QCQAOutputList;

import { DocumentStatus } from 'types/common';

export enum DocumentCode {
  BASIC = 'basic',
  DETAIL = 'detail',
  MSDS = 'msds',
  TDS = 'tds',
  MCI = 'composition',
  COA = 'coa',
  MFC = 'manufacturing-flow-chart',
}

export enum ICIDDocumentCode {
  DETAIL = 'DETAIL',
  MATERIAL_SAFETY_DATA_SHEET = 'MATERIAL_SAFETY_DATA_SHEET',
  TDS = 'TDS',
  COMPOSITION_INFORMATION = 'COMPOSITION_INFORMATION',
  CERTIFICATE_OF_ANALYSIS = 'CERTIFICATE_OF_ANALYSIS',
  MANUFACTURING_FLOW_CHART = 'MANUFACTURING_FLOW_CHART',
}

export enum ICIDApplicationStatus {
  INPUT = 'INPUT',
  ONGOING = 'ONGOING',
  APPLYING = 'APPLYING',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL',
}

export enum ICIDContractStatus {
  ALL = '',
  FIN = 'true',
  ONG = 'false',
}

export enum ICIDDepositStatus {
  READY = 'READY',
  PARTIAL = 'PARTIAL',
  FINISH = 'FINISH',
}

export interface PCPCCouncilOpenDates {
  openDates: PCPCCouncilOpenDate[];
  openYear: string;
}

export interface PCPCCouncilOpenDate {
  pcpcCouncilOpenId: number;
  openDate: string;
}

export enum ICIDRegisteredSearchType {
  ALL = 'ALL',
  TRADE_NAME = 'TRADE_NAME',
  INCI = 'INCI',
}

export interface ICIDCategory {
  materialCategoryId: number;
  categoryName: string;
  code: string;
  displayOrder: number;
}

export interface ICIDBasicParam {
  tradeName: string;
  materialCategoryId: number;
}

export interface ICIDApplication {
  materialIcidApplicationId: number;
  companyId: number;
  category: ICIDCategory;
  tradeName: string;
  applicationCode: string | null;
  status: ICIDApplicationStatus;
  listedInciName: string | null;
  finishDt: string | null;
  pcpcLetterUrl: string | null;
  registerDt: string;
}

export interface ICIDRequest {
  materialIcidApplicationId: number;
  categoryName: string;
  tradeName: string;
  registerDt: string;
  depositStatus: ICIDDepositStatus | null;
  finishedContract: boolean;
  receivedContract: boolean;
}

export interface ICIDRequestListPageState {
  page: number;
  searchValue: string;
  isFinishedContract: ICIDContractStatus;
}

export interface ICIDEstimateParam {
  estimateFileUrl: string;
  materialIcidApplicationEstimateId: number;
}

export interface ICIDOngoingPageState {
  page: number;
  tradeName: string;
}

export interface MaterialIcidApplicationOngoing {
  materialIcidApplicationId: number;
  applicationCode: string;
  contractDt: string;
  tradeName: string;
  status: ICIDApplicationStatus;
  processStatus: DocumentStatus;
  hasPcpcQuestion: boolean;
}
export interface ICIDOngoingDocs {
  materialIcidApplicationDocStatusId: number | null;
  documentCode: string;
  documentName: string;
  status: DocumentStatus | null;
  isOptional: boolean;
  isUserUpload: boolean;
  modifierId: number | null;
  modifierName: string | null;
  modifierCompanyName: string | null;
  modifiedDt: string | null;
}

export interface ICIDApplicationDetailBasic {
  materialIcidApplicationId: number;
  isPublic: boolean;
  inciName: string;
  casNo: string;
  compositionRatio: string;
  solvent: string;
  referenceInfo: string;
  sourceOrigin: string;
  productType: string;
  manufacturingFlow: string;
  additionalInfo: string;
}

interface MaterialCategory {
  materialIcidApplicationDetailId: number;
  materialCategoryId: number;
}
export interface ICIDApplicationDetail extends ICIDApplicationDetailBasic {
  status: DocumentStatus;
  materialIcidApplicationDetailId: number;
  materialIcidApplicationDetailFunctionMaterialCategories: MaterialCategory[];
  correctRequestMessage: string | null;
}

interface TmpMaterialCategory {
  materialIcidApplicationDetailTmpId: number;
  materialCategoryId: number;
}
export interface ICIDApplicationTmpDetail extends ICIDApplicationDetailBasic {
  materialIcidApplicationDetailTmpId: number;
  materialIcidApplicationDetailTmpFunctionMaterialCategories: TmpMaterialCategory[];
  isSolvent: boolean;
}

export interface ICIDDetailFormValues {
  tradeName?: string;
  isPublic: boolean;
  inciName: string;
  casNo: string;
  compositionRatio: string;
  referenceInfo: string;
  solvent?: string;
  sourceOrigin: string;
  productType: string;
  manufacturingFlow: string;
  additionalInfo: string;
  materialCategoryIds: number[];
}

export interface ICIDDetailAddParams extends ICIDDetailFormValues {
  isSolvent?: boolean;
  icidApplicationId: number;
}

export interface ICIDRegisteredPageState {
  page: number;
  searchValue: string;
  searchType: ICIDRegisteredSearchType;
}

export interface ICIDRegisteredParam {
  materialIcidApplicationId: number;
  applicationCode: string;
  tradeName: string;
  inciName: string;
  finishDt: string;
}

export interface ICIDRegisteredDetailParam {
  materialIcidApplicationDocStatusId: number;
  documentCode: DocumentCode;
  documentName: string;
  uploadedFiles:
    | {
        fileUrl: string;
        filename: string;
      }[]
    | null;
}

export interface ICIDApplicationDocType {
  materialIcidApplicationDocTypeId: number;
  materialIcidApplicationId: number;
  fileUrl: string;
  filename: string;
}

export interface UploadDocument {
  icidApplicationDocTypes: ICIDApplicationDocType[];
  status: DocumentStatus;
  correctRequestMessage: string;
}

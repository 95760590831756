import { Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import ProductBasicForm from 'components/product/ProductBasicForm';
import UpdateLog from 'components/product/UpdateLog';
import useGA, { EGAActionType } from 'hook/useGA';
import { useModal } from 'hook/useModal';
import { messages } from 'lib/consts';
import palette from 'lib/styles/palette';
import {
  initializeDocuments,
  setCurrentProduct,
  setNextPathname,
  setSkipMode,
} from 'modules/product';
import { useGetDuplicatedProduct } from 'service/brand/product/product';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { useProductBasic } from 'service/product';
import { UserType } from 'types/auth';
import { DocumentCode } from 'types/product';
import { netWeightUnitOptions } from 'lib/selectOption';
import { useMovePathname } from 'hook/useMovePathname';

const ProductBasicContainer = () => {
  const navigate = useNavigate();
  const { movePathname } = useMovePathname();
  const [isNetWeightUnitDirect, setIsNetWeightUnitDirect] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams<{
    productId: string;
  }>();

  const updateMode = typeof params.productId !== 'undefined';
  const productId = Number(params.productId);
  const { openAlertModal } = useModal();
  const { sendEventToGA } = useGA();

  const { data: manufacturers = [] } = useManufacturerCompanies();
  const { user, company, currentProduct } = useSelector(
    ({ auth, company, product }: any) => ({
      user: auth.user,
      company: company.company,
      currentProduct: product.currentProduct,
    }),
    shallowEqual,
  );
  const { manufacturerId, userType, companyId } = user;

  const { addProductBasic, addProductBasicLoading, updateProductBasic, updateProductBasicLoading } =
    useProductBasic();
  const { getDuplicatedProduct, getDuplicatedProductLoading } = useGetDuplicatedProduct();

  const productDupCheckHook = () => {
    let netWeightUnit = form.getFieldValue('netWeightUnit');

    if (isNetWeightUnitDirect) netWeightUnit = form.getFieldValue('netWeightUnitDirect');
    if (!netWeightUnit) {
      message.warning(isNetWeightUnitDirect ? '단위를 입력해 주세요.' : '단위를 선택해 주세요.');
      return;
    }

    if (updateMode) {
      const { productNameEn: currentProductNameEn } = currentProduct.productDetail;
      const { netWeight: currentNetWeight, netWeightUnit: currentNetWeightUnit } = currentProduct;

      if (
        currentProductNameEn === form.getFieldValue('productNameEn') &&
        currentNetWeight === form.getFieldValue('netWeight') &&
        currentNetWeightUnit === netWeightUnit
      ) {
        submitProduct();

        return;
      }
    }

    getDuplicatedProduct(
      {
        productNameEn: form.getFieldValue('productNameEn'),
        netWeight: form.getFieldValue('netWeight'),
        netWeightUnit,
      },
      {
        onSuccess: (res) => {
          const { isDuplicate } = res.data.result;
          if (!isDuplicate) {
            openAlertModal({
              content: (
                <>
                  <span style={{ color: palette.PRIMARY50 }}>*</span>
                  화장품제조업자(영문)와 <span style={{ color: palette.PRIMARY50 }}>*</span>
                  화장품제조업자 주소(영문)를 cGMP 또는 ISO22716에 등록된 영문명과 영문 주소로
                  <br /> 동일하게 입력하셨습니까?
                </>
              ),
              okText: '네, 동일하게 입력했습니다.',
              okLoading: addProductBasicLoading || updateProductBasicLoading,
              closeText: '아니오',
              onOk: () => submitProduct(),
            });
          } else {
            openAlertModal({
              noCancelButton: true,
              content: `이미 사용중인 제품명입니다.\n다른 제품명을 사용하시거나 고객센터로 문의해주세요.`,
            });
          }
        },
      },
    );
  };

  const submitProduct = () => {
    let { netWeightUnitDirect, netWeightUnit, ...restFormValues } = form.getFieldsValue();

    if (isNetWeightUnitDirect) netWeightUnit = netWeightUnitDirect;

    if (!updateMode) {
      addProductBasic(
        {
          ...restFormValues,
          netWeightUnit,
          manufacturerNameKo: manufacturers.find(
            ({ manufacturerId }) => manufacturerId === form.getFieldValue('manufacturerId'),
          )?.companyNameKo,
          companyId,
        },
        {
          onSuccess: (res) => {
            dispatch(setCurrentProduct(res.data.result));
            const productId = res.data.result.productId;
            if (productId) {
              movePathname({ productId });
            }

            sendEventToGA({
              documentName: '기본 정보',
              actionType: EGAActionType.REGISTER,
            });
          },
        },
      );
    } else {
      const { productDetail } = currentProduct;
      let {
        brandNameEn,
        productNameKo,
        productNameEn,
        manufacturerId,
        manufacturerNameEn,
        manufacturerAddressEn,
        manufacturerTel,
        manufacturerZipCode,
        netWeight,
        netWeightUnit,
        netWeightUnitDirect,
      } = form.getFieldsValue();

      if (isNetWeightUnitDirect) netWeightUnit = netWeightUnitDirect;
      const updateFormValues = {
        ...(brandNameEn !== productDetail.brandNameEn && { brandNameEn }),
        ...(productNameKo !== productDetail.productNameKo && { productNameKo }),
        ...(productNameEn !== productDetail.productNameEn && { productNameEn }),
        ...(netWeight !== currentProduct.netWeight && { netWeight }),
        ...(netWeightUnit !== currentProduct.netWeightUnit && {
          netWeightUnit,
        }),
        ...(productNameEn !== productDetail.productNameEn && { productNameEn }),
        ...(manufacturerId !== productDetail.manufacturerId && {
          manufacturerId,
        }),
        ...(manufacturerId !== productDetail.manufacturerId && {
          manufacturerNameKo: manufacturers.find(
            (manufacturer) => manufacturer.manufacturerId === manufacturerId,
          )?.companyNameKo,
        }),
        ...(manufacturerNameEn !== productDetail.manufacturerNameEn && {
          manufacturerNameEn,
        }),
        ...(manufacturerAddressEn !== productDetail.manufacturerAddressEn && {
          manufacturerAddressEn,
        }),
        ...(manufacturerTel !== productDetail.manufacturerTel && {
          manufacturerTel,
        }),
        ...(manufacturerZipCode !== productDetail.manufacturerZipCode && {
          manufacturerZipCode,
        }),
      };
      if (Object.keys(updateFormValues).length === 0) {
        return message.warning(messages.NO_NEED_TO_UPDATE);
      }

      updateProductBasic(
        {
          productId,
          ...updateFormValues,
        },
        {
          onSuccess: (res) => {
            sendEventToGA({
              documentName: '기본 정보',
              actionType: EGAActionType.UPDATE,
            });
            message.success('수정되었습니다.');
            dispatch(setCurrentProduct(res.data.result));
            navigate(-1);
          },
        },
      );
    }
  };

  useEffect(() => {
    dispatch(initializeDocuments());
    if (!updateMode) {
      form.resetFields();
      dispatch(setSkipMode(true));
    }
    form.setFieldsValue({
      brandCompanyNameKo: company.companyNameKo,
      brandCompanyNameEn: company.companyNameEn,
      brandCompanyAddressKo: company.addressKo,
      brandCompanyAddressEn: company.addressEn,
    });
  }, [updateMode]);

  useEffect(() => {
    if (!updateMode) {
      dispatch(setNextPathname('basic'));
    } else {
      const {
        brandNameEn,
        productNameKo,
        productNameEn,
        manufacturerId,
        manufacturerNameEn,
        manufacturerAddressEn,
        manufacturerTel,
        manufacturerZipCode,
      } = currentProduct.productDetail;
      const { netWeight, netWeightUnit } = currentProduct;
      const isNetWeightUnitDirect = !netWeightUnitOptions.some(
        (item) => item.value === netWeightUnit,
      );
      form.setFieldsValue({
        brandNameEn,
        productNameKo,
        productNameEn,
        manufacturerId,
        manufacturerNameEn,
        manufacturerAddressEn,
        manufacturerTel,
        manufacturerZipCode,
        netWeight,
        ...(isNetWeightUnitDirect
          ? {
              netWeightUnitDirect: netWeightUnit,
            }
          : {
              netWeightUnit,
            }),
      });
      setIsNetWeightUnitDirect(isNetWeightUnitDirect);
    }
  }, []);

  useEffect(() => {
    if (!updateMode && manufacturerId && manufacturers.length > 0) {
      form.setFieldsValue({
        manufacturerNameEn: manufacturers.find(
          (manufacturer) => manufacturer.manufacturerId === manufacturerId,
        )?.companyNameEn,
        manufacturerId,
      });
    }
  }, [manufacturers]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        brandNameEn: 'Dowith',
        productNameKo: '두윗 세럼',
        productNameEn: `Dowith Serum-${moment().format('YY-MM-DD HH:mm')}`,
        ...(userType !== UserType.MANUFACTURE && { manufacturerId: 1 }),
        manufacturerNameEn: 'LK Systems',
        manufacturerAddressEn:
          '12-11 Doekgogae-gil, Jeonui-myeon, Sejong Metropolitan Autonomous City',
        manufacturerTel: '0212345678',
        manufacturerZipCode: '123321',
        netWeight: 10,
        netWeightUnit: 'mL',
      });
    }
  }, [updateMode]);

  return (
    <>
      {updateMode && <UpdateLog productId={productId} documentCode={DocumentCode.BASIC} />}
      <ProductBasicForm
        updateMode={updateMode}
        form={form}
        fetchLoading={
          getDuplicatedProductLoading || addProductBasicLoading || updateProductBasicLoading
        }
        manufacturers={manufacturers}
        isManufacturer={userType === UserType.MANUFACTURE}
        onFormFinished={productDupCheckHook}
        isNetWeightUnitDirect={isNetWeightUnitDirect}
        onChangeIsNetWeightUnitDirect={setIsNetWeightUnitDirect}
      />
    </>
  );
};

export default ProductBasicContainer;

import { Collapse, Empty, message } from 'antd';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { isArray, isEqual } from 'lodash';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import {
  AnalysisItem,
  AnalyzeResultListItem,
  CountryRules,
  ImageIngredient,
  Ingredient,
  PostIngredient,
  PostIngredientListItem,
  RuleCode,
  RuleType,
} from 'types/brand/artworkScreening/artworkScreening';
import {
  AllError,
  UpdatedIngredient,
  UpdatedIngredientItem,
  getIngerdientNameWithAdditional,
  getTextColorByRule,
  ruleCategories,
} from '../ArtworkStep4';
import { useFormulaScreeningArtworkReport } from 'service/brand/artworkScreening/artworkScreening';
import { usePubCountries } from 'service/country';
import { GroupedRule } from './CollapseIngredient';

export enum TranslateLanguageCode {
  EN = 'EN',
  KO = 'KO',
  JP = 'JP',
  CN = 'CN',
}

const getPreIngredientList = (preIngredient: ImageIngredient[] | ImageIngredient | null) => {
  if (!preIngredient) return [];

  return isArray(preIngredient) ? preIngredient : [preIngredient];
};

const ReportModalContent = ({
  originalIngredients,
  updatedIngredients,
  analyzeResult,
  selectedCountryCodes,
  availableCountryCodes,
}: {
  originalIngredients: UpdatedIngredient[];
  updatedIngredients: UpdatedIngredientItem[];
  analyzeResult: (AnalyzeResultListItem & {
    allErrors: AllError[];
  })[];
  selectedCountryCodes: string[];
  availableCountryCodes: string[];
}) => {
  const { Panel } = Collapse;
  const countries = usePubCountries();

  const getInitialLanguageCode = () => {
    const str = updatedIngredients.map((item) => item.initialIngredientName).join('');
    const koreanRegex = /[가-힣]/g;
    const englishRegex = /[a-zA-Z]/g;

    const koreanCount = (str.match(koreanRegex) || []).length;
    const englishCount = (str.match(englishRegex) || []).length;

    if (koreanCount > englishCount) {
      return TranslateLanguageCode.KO;
    } else if (englishCount > koreanCount) {
      return TranslateLanguageCode.EN;
    } else {
      return TranslateLanguageCode.EN;
    }
  };

  const initialLanguageCode = getInitialLanguageCode();
  const [languageCode, setLanguageCode] = useState(initialLanguageCode);
  const [translatedIngredients, setTranslatedIngredients] = useState(updatedIngredients);

  const { translateIngredients } = useFormulaScreeningArtworkReport({
    languageCode,
  });

  const tabs = [
    {
      label: '영문',
      value: TranslateLanguageCode.EN,
    },
    {
      label: '국문',
      value: TranslateLanguageCode.KO,
    },
    {
      label: '일문',
      value: TranslateLanguageCode.JP,
    },
    {
      label: '중문',
      value: TranslateLanguageCode.CN,
    },
  ];

  useEffect(() => {
    if (initialLanguageCode !== languageCode) {
      translateIngredients(
        updatedIngredients.map((item) => ({
          name: item.directName || item.ingredientName,
        })),
        {
          onSuccess: (res) => {
            setTranslatedIngredients(
              translatedIngredients.map((item, idx) => ({
                ...item,
                name: res.data.result[idx].translateName || updatedIngredients[idx].ingredientName,
              })),
            );
          },
        },
      );
    } else {
      setTranslatedIngredients(updatedIngredients);
    }
  }, [languageCode]);

  const totalAnalyzeResult = () => {
    const newResultArr: (AnalysisItem & {
      ruleType: string;
      ingredient?: Ingredient | null;
      imageIngredient?: ImageIngredient | null;
      allDescriptions: string[];
      errors: {
        countryRules: CountryRules;
        ruleCode: RuleCode;
        postIngredient: PostIngredientListItem | PostIngredient;
      }[];
    })[] = [];

    if (!analyzeResult) return;

    const finalAnalyzeResult = analyzeResult
      .filter(({ analysisItems, allErrors }) => analysisItems && allErrors.length > 0)
      .map(({ ingredient, imageIngredient, allErrors }) => ({
        ingredient,
        imageIngredient,
        allErrors,
      }));

    for (const res of finalAnalyzeResult) {
      for (const { ruleType, errors, analysisItems } of res.allErrors) {
        const ruleCode = errors[0].ruleCode;
        const allDescriptions: string[] = errors.map((item) => item.postIngredient.description);
        const { preIngredient } = analysisItems[0];

        if (ruleType === RuleType.ORDER) {
          const preIngredientsInfo = (preIngredient as ImageIngredient[])?.map((item) => ({
            ruleCode: ruleCode,
            formulaScreeningArtworkImageIngredientId: item.formulaScreeningArtworkImageIngredientId,
            ingredientName: item.ingredientName,
          }));

          const hasSameAnalysisItems = newResultArr.find(
            ({ ruleCode: code, preIngredient }) =>
              code === ruleCode &&
              getPreIngredientList(preIngredient).some(
                ({ formulaScreeningArtworkImageIngredientId: id }) =>
                  preIngredientsInfo
                    .map(({ formulaScreeningArtworkImageIngredientId: compareId }) => compareId)
                    .includes(id),
              ),
          );

          if (hasSameAnalysisItems) {
            break;
          }
        } else if (ruleType === RuleType.MISS) {
          const preIngredientsInfo = isArray(preIngredient)
            ? (preIngredient as ImageIngredient[])?.map((item) => ({
                ruleCode: ruleCode,
                ingredientId: item.ingredientId,
                ingredientName: item.ingredientName,
              }))
            : [];

          const hasSameAnalysisItems = newResultArr.find(
            ({ ruleCode: code, preIngredient }) =>
              code === ruleCode &&
              getPreIngredientList(preIngredient).some(({ ingredientId: id }) =>
                preIngredientsInfo.map(({ ingredientId: compareId }) => compareId).includes(id),
              ),
          );

          if (hasSameAnalysisItems) {
            break;
          }
        } else if (ruleType === RuleType.TYPO) {
          const postIngredients = errors[0].postIngredient;

          if (!isArray(postIngredients)) {
            const ruleSubcode = postIngredients?.ruleSubCode;

            const hasSameAnalysisItems = newResultArr.find(
              ({ ruleCode: code, postIngredient, preIngredient }) =>
                code === ruleCode &&
                isEqual((postIngredient as PostIngredient)?.ruleSubCode, ruleSubcode) &&
                isArray(preIngredient),
            );

            if (hasSameAnalysisItems) {
              break;
            }
          }
        }
        newResultArr.push({
          ...analysisItems[0],
          ruleType,
          ingredient: res.ingredient,
          imageIngredient: res.imageIngredient,
          allDescriptions,
          errors,
        });
      }
    }

    return newResultArr;
  };

  return (
    <Container>
      <Tabs gap={8}>
        {tabs.map(({ label, value }) => (
          <Tab
            key={value}
            isActive={languageCode === value}
            justify="center"
            onClick={() => setLanguageCode(value)}
          >
            {label}
          </Tab>
        ))}
      </Tabs>
      <Flex gutter={{ top: 16 }}>
        <ArtworkIngredientList>
          <Typography.Text
            color="SLATE_GRAY70"
            medium
            style={{
              padding: '16px 23px',
              background: palette.SLATE_GRAY10,
              borderRadius: '8px 0 0 0',
              borderBottom: `1px solid ${palette.GRAY30}`,
            }}
          >
            적용 전
          </Typography.Text>
          <div
            style={{
              padding: 24,
              height: 316,
              overflowY: 'scroll',
              verticalAlign: 'center',
            }}
          >
            {originalIngredients.map(({ initialColor, initialIngredientName }, idx) => (
              <Typography.Text key={idx} color={initialColor} type="BODY_2" inline>
                {initialIngredientName}
                <Typography.Text type="BODY_2" color="SLATE_GRAY70" inline>
                  {idx < originalIngredients.length - 1 ? ', ' : ''}
                </Typography.Text>
              </Typography.Text>
            ))}
            <Icon
              name="copy"
              size={16}
              color="SLATE_GRAY50"
              style={{ marginTop: 16 }}
              onClick={() => {
                navigator.clipboard
                  .writeText(originalIngredients.map((item) => item.ingredientName).join(', '))
                  .then(() => {
                    message.success('텍스트를 복사했습니다.');
                  });
              }}
            />
          </div>
        </ArtworkIngredientList>
        <AnalysisResult>
          <Flex
            gap={8}
            style={{
              padding: '16px 23px',
              background: palette.SLATE_GRAY10,
              borderRadius: '0 8px 0 0',
              borderBottom: `1px solid ${palette.GRAY30}`,
            }}
          >
            <Typography.Text color="SLATE_GRAY70" medium>
              적용 후
            </Typography.Text>
            <Tip
              trigger="click"
              bodyStyle={{
                whiteSpace: 'pre-wrap',
                width: 304,
                height: 48,
                lineHeight: 1.3,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              {`성분의 정보가 없는 경우 번역이 되지 않아 성분명이\n그대로 보이게 됩니다.`}
            </Tip>
          </Flex>
          <div style={{ padding: 24, height: 316, overflowY: 'scroll' }}>
            {translatedIngredients.map(({ ingredientName, color, name }, idx) => {
              return (
                <Typography.Text key={`${ingredientName}${idx}`} type="BODY_2" inline>
                  <Typography.Text color={color} type="BODY_2" inline>
                    {name}
                  </Typography.Text>
                  <Typography.Text type="BODY_2" color="SLATE_GRAY70" inline>
                    {idx < translatedIngredients.length - 1 ? ', ' : ''}
                  </Typography.Text>
                </Typography.Text>
              );
            })}
            {translatedIngredients && translatedIngredients?.length > 0 && (
              <Icon
                name="copy"
                size={16}
                color="SLATE_GRAY50"
                style={{ marginTop: 16 }}
                onClick={() => {
                  navigator.clipboard
                    .writeText(translatedIngredients.map((item) => item.name).join(', '))
                    .then(() => {
                      message.success('텍스트를 복사했습니다.');
                    });
                }}
              />
            )}
          </div>
        </AnalysisResult>
      </Flex>
      <div
        style={{
          padding: '24px 12px 0 24px',
          border: `1px solid ${palette.GRAY30}`,
          borderTop: 'none',
          borderRadius: '0 0 8px 8px',
        }}
      >
        <Flex gap={16}>
          {ruleCategories.map(({ type, text }) => (
            <Flex key={text} gap={4}>
              <div
                style={{
                  width: 6,
                  height: 6,
                  background: palette[getTextColorByRule(type)],
                  borderRadius: '50%',
                }}
              ></div>
              <Typography.Text medium color="SLATE_GRAY70" type="BODY_2">
                {text}
              </Typography.Text>
            </Flex>
          ))}
        </Flex>
        <Flex
          dir="column"
          gap={8}
          style={{
            maxHeight: 243,
            overflowY: 'scroll',
            marginTop: 8,
            paddingBottom: 24,
            paddingRight: 12,
          }}
        >
          {totalAnalyzeResult()?.map((item, idx) => {
            const preIngredient = item?.preIngredient;
            const ruleType = item.ruleType;
            const allDescriptions = item.allDescriptions;
            const errors = item.errors;

            const preIngredientName = isArray(preIngredient)
              ? preIngredient
                  .map((item) =>
                    getIngerdientNameWithAdditional({
                      ingredientName: item.ingredientName,
                      additionalIngredientName: item.additionalIngredientName,
                      additionalIngredientNameSeparator: item.additionalIngredientNameSeparator,
                    }),
                  )
                  .join(', ')
              : getIngerdientNameWithAdditional({
                  ingredientName: preIngredient?.ingredientName,
                  additionalIngredientName: preIngredient?.additionalIngredientName,
                  additionalIngredientNameSeparator:
                    preIngredient?.additionalIngredientNameSeparator,
                });

            const countryRules = errors.find(
              (error) => Object.keys(error.countryRules).length !== 0,
            )?.countryRules;

            const sortedSelectedCountryCodes = selectedCountryCodes.sort((a: string, b: string) => {
              const indexA = availableCountryCodes?.indexOf(a);
              const indexB = availableCountryCodes?.indexOf(b);

              return indexA - indexB;
            });

            const countryRulesInfo = sortedSelectedCountryCodes.reduce((acc, countryCode) => {
              if (countryRules?.[countryCode]) {
                acc[countryCode] = countryRules[countryCode];
              }
              return acc;
            }, {} as CountryRules);

            const groupedByRules: { [rulesKey: string]: GroupedRule } = {};

            for (const countryCode in countryRulesInfo) {
              const rules = countryRulesInfo[countryCode];
              const rulesKey = JSON.stringify(rules.map((rule) => rule.subCode).sort());

              if (!groupedByRules[rulesKey]) {
                groupedByRules[rulesKey] = {
                  rules: rules.map((rule) => ({
                    subCode: rule.subCode,
                    description: rule.description,
                  })),
                  countryCodes: [],
                };
              }
              groupedByRules[rulesKey].countryCodes.push(countryCode);
            }

            const groupedCountryRules: GroupedRule[] = Object.values(groupedByRules);

            return (
              <AnalysisItemContainer dir="column" gap={8} key={idx}>
                <Typography.Text
                  key={idx}
                  style={{
                    color: palette[getTextColorByRule(ruleType as RuleType)],
                  }}
                  inline
                  type="BODY_2"
                >
                  {preIngredientName}
                </Typography.Text>
                {allDescriptions
                  .flatMap((description) => description.split('\n'))
                  .map((description, idx) => (
                    <Flex key={idx} align="center" gap={8}>
                      <DotDiv />
                      <Typography.Text
                        color="SLATE_GRAY70"
                        type="BODY_2"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {description || '-'}
                      </Typography.Text>
                    </Flex>
                  ))}
                {Object.keys(countryRulesInfo).length > 0 && (
                  <Collapse
                    defaultActiveKey={1}
                    bordered={false}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <Icon name="up" size={16} color="PRIMARY50" />
                      ) : (
                        <Icon name="down" size={16} color="PRIMARY50" />
                      )
                    }
                  >
                    <Panel
                      key={1}
                      header={
                        <Flex align="center" gap={4}>
                          <Typography.Text type="SMALL" color="PRIMARY50">
                            국가별 규칙 보기
                          </Typography.Text>
                        </Flex>
                      }
                    >
                      <Flex dir="column" gap={8}>
                        {groupedCountryRules.map(({ countryCodes, rules }, idx) => {
                          return (
                            <CountryRuleItem dir="column" key={idx}>
                              <Flex>
                                {countryCodes.map((countryCode) => {
                                  return (
                                    <CountryImgDiv
                                      justify="center"
                                      align="center"
                                      key={countryCode}
                                    >
                                      <img
                                        src={
                                          countryCode === 'ETC'
                                            ? `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/etc.svg`
                                            : `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countries
                                                ?.find((item) => item.countryCode === countryCode)
                                                ?.countryCode.toLowerCase()}.png`
                                        }
                                        className="logo"
                                        alt=""
                                        style={{
                                          width: 20,
                                          marginRight: 8,
                                        }}
                                      />
                                      <ShowTooltipDiv justify="center" dir="column" align="center">
                                        {countryCode === 'ETC'
                                          ? '기타'
                                          : countries?.find(
                                              (item) => item.countryCode === countryCode,
                                            )?.countryNameKo}
                                      </ShowTooltipDiv>
                                    </CountryImgDiv>
                                  );
                                })}
                              </Flex>
                              <div style={{ marginTop: 8 }}>
                                {rules.map(({ description }, idx) => (
                                  <Flex gap={4} align="center" gutter={{ top: 4 }} key={idx}>
                                    <Bullet />
                                    <Typography.Text
                                      color="SLATE_GRAY70"
                                      type="BODY_2"
                                      style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                      {description}
                                    </Typography.Text>
                                  </Flex>
                                ))}
                              </div>
                            </CountryRuleItem>
                          );
                        })}
                      </Flex>
                    </Panel>
                  </Collapse>
                )}
              </AnalysisItemContainer>
            );
          })}
        </Flex>
        {!totalAnalyzeResult() ||
          (totalAnalyzeResult()?.length === 0 && (
            <Flex justify="center" align="center" style={{ width: '100%' }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="데이터 없음" />
            </Flex>
          ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  ${Flex} {
    align-items: center;
  }

  text-align: left;

  .ant-empty-normal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 208px;
    margin: 0;
  }

  .ant-collapse-header {
    background-color: none !important;
    border: none !important;
  }

  .ant-collapse {
    border-radius: 8px;
    background-color: transparent !important;
    /* border: 1px solid ${palette.GRAY30}; */
    width: 100%;
  }

  .ant-collapse-item {
    border: none;
    width: 100% !important;
  }
  .ant-collapse-content {
    border: none;
    background-color: white;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0 40px 0 0;
    align-items: center;
    max-width: 140px;

    .ant-collapse-expand-icon {
      padding-inline-start: 4px;
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    padding-top: 8px !important;
    width: 100%;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 0 !important;
    padding: 0 !important;
    right: 20px;
  }
`;

const Tabs = styled(Flex)``;

const Tab = styled(Flex)<{ isActive: boolean }>`
  border-radius: 4px;
  height: 40px;
  width: 57px;
  font-size: 14px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
          background: ${palette.SLATE_GRAY20};
          font-weight: 500;
        `
      : css`
          border: 1px solid ${palette.GRAY40};
          color: ${palette.SLATE_GRAY60};
          background: #fff;
        `}
`;

const ArtworkIngredientList = styled.div`
  flex: 1;
  max-width: 376px;
  border-radius: 8px 0 0 0;
  border: 1px solid ${palette.GRAY30};
  word-break: break-word;
`;

const AnalysisResult = styled.div`
  flex: 1;
  max-width: 376px;
  border-radius: 0 8px 0 0;
  border: 1px solid ${palette.GRAY30};
  border-left: none;
  word-break: break-word;
`;

const AnalysisItemContainer = styled(Flex)`
  width: 100%;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
  padding: 16px;
  align-items: start !important;
`;

const CountryRuleItem = styled(Flex)`
  width: 100%;
  background-color: white;
  border: 1px solid ${palette.GRAY30};
  border-radius: 8px;
  padding: 16px 24px;
  align-items: start !important;
`;

const DotDiv = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${palette.SLATE_GRAY50};
`;

const ShowTooltipDiv = styled(Flex)`
  bottom: 24px;
  left: calc(50% - 5px);
  transform: translateX(-50%);
  position: absolute;
  z-index: 10;
  width: max-content;
  display: none;
  background-color: ${palette.SLATE_GRAY70};
  border-radius: 4px;
  color: ${palette.ETC_WHITE};
  font-size: 12px;
  padding: 4px 10px;
`;

const CountryImgDiv = styled(Flex)`
  position: relative;

  &:hover {
    ${ShowTooltipDiv} {
      display: flex;
    }
  }
`;

const Bullet = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${palette.SLATE_GRAY50};
  align-self: flex-start;
  margin-top: 9px;
`;

export default ReportModalContent;

import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

import { messages } from 'lib/consts';
import { useCountryId } from 'service/brand/product/product';

const GoBackIfThereIsNoCountryId = () => {
  const navigate = useNavigate();
  const countryId = useCountryId();

  useLayoutEffect(() => {
    if (countryId === undefined) {
      Modal.warn({
        content: messages.INVALID_APPROACH,
        onOk: () => {
          navigate(-1);
        },
      });
    }
  }, [countryId]);

  return null;
};

export default GoBackIfThereIsNoCountryId;

import { useEffect, useMemo, useState } from 'react';
import { Empty, Spin, Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { useCurrentProduct, useProductCountries } from 'service/brand/product/product';
import FileViewer from './FileViewer';
import { ProductArtworkCountry, ProductCountryArtwork } from 'types/product';
import { messages } from 'lib/consts';
import Icon from 'components/ui/Icon/Icon';

export interface FileItemProp {
  filename: string;
  productArtworkId: number;
  uploadFileUrl: string;
}

export interface ArtworkItem {
  countryNameKo?: string;
  countryNameEn?: string;
  countryId: number;
  countryCode?: string;
  files?: (File | FileItemProp)[];
}

const FileList = ({
  selectedFile,
  files,
  onFileClick,
  onDelete,
}: {
  selectedFile?: any;
  files?: (File | FileItemProp)[];
  onFileClick: (file: any) => void;
  onDelete: (file: any) => void;
}) => {
  const realFiles = useMemo(() => {
    if (!files || typeof files === 'number') {
      return [];
    }
    return files instanceof Array ? files : [files];
  }, [files]);
  return (
    <ArtworkList
      dir={realFiles.length === 0 ? 'row' : 'column'}
      align={realFiles.length === 0 ? 'center' : 'flex-start'}
      justify={realFiles.length === 0 ? 'center' : 'flex-start'}
    >
      {realFiles.length > 0 ? (
        realFiles?.map((file: File | FileItemProp, idx) => {
          return (
            <FileItem
              key={idx}
              justify="space-between"
              align="center"
              onClick={() => onFileClick(file)}
              selected={file === selectedFile}
            >
              <Typography.Text
                style={{
                  color: palette.GRAY90,
                  fontSize: 14,
                  maxWidth: 370,
                  wordBreak: 'break-all',
                }}
              >
                {file instanceof File ? file.name : file.filename}
              </Typography.Text>
              <Icon
                name="delete"
                color="GRAY60"
                onClick={(e) => {
                  onDelete(file);
                }}
              />
            </FileItem>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </ArtworkList>
  );
};

const FileUpload = ({
  files,
  setSelectedCountryFiles,
  selectedCountryId,
  previewedFile,
  viewerVisible = true,
  accept = 'application/pdf',
  max,
  onUpload,
  onFileClick,
  onDelete,
}: {
  files: any;
  setSelectedCountryFiles: React.Dispatch<
    React.SetStateAction<Record<number, (File | FileItemProp)[]>>
  >;
  selectedCountryId: number | undefined;
  previewedFile?: any;
  viewerVisible?: boolean;
  accept?: string;
  max?: number;
  onUpload: (file: File) => void;
  onFileClick: (file: any) => void;
  onDelete: (file: any) => void;
}) => {
  const multiple = Array.isArray(files);
  const [uploadedFileList, setUploadedFileList] = useState<RcFile[]>([]);
  const [isDuplicatedFilename, setIsDuplicatedFilename] = useState<boolean>();
  const [isMaxFileCount, setIsMaxFileCount] = useState<boolean>();

  // TODO: files의 type이 any로 되어 있어 추후 변경
  const filenameList = multiple
    ? files.map((item: any) => (item instanceof File ? item.name : Object.values(item))).flat(2)
    : [...(files ? [files instanceof File ? files.name : Object.values(files)] : [])].flat(2);

  useEffect(() => {
    if (!uploadedFileList?.length) return;

    const existFileNameAndShowWarning = (filename: string) => {
      const exist = filenameList?.includes(filename);

      return exist;
    };

    // 단일 파일
    if (!multiple) {
      const file = uploadedFileList[0];
      if (existFileNameAndShowWarning(uploadedFileList[0].name)) return;
      onUpload(file);
      if (selectedCountryId) {
        setSelectedCountryFiles((prevMap) => {
          const prevArr = prevMap[selectedCountryId] || [];
          prevMap[selectedCountryId] = prevArr.concat(file);
          return { ...prevMap };
        });
      }
      return;
    }

    // 여러 파일
    let availableToUploadCnt = (max || Number.MAX_VALUE) - files.length;

    if (isMaxFileCount) {
      message.warning(`최대 ${max}개의 파일만 업로드 가능합니다.`);
    }

    if (isDuplicatedFilename) {
      message.warning(messages.DUPLICATE_FILE_NAME);
    }

    for (const file of uploadedFileList) {
      if (availableToUploadCnt <= 0) {
        setIsMaxFileCount(true);
        continue;
      } else if (existFileNameAndShowWarning(file.name)) {
        setIsDuplicatedFilename(true);
        // message.warning(messages.DUPLICATE_FILE_NAME);
        continue;
      }

      onUpload(file);

      if (selectedCountryId) {
        setSelectedCountryFiles((prevMap) => {
          const prevArr = prevMap[selectedCountryId] || [];
          prevMap[selectedCountryId] = prevArr.concat(file);
          return { ...prevMap };
        });
      }
      availableToUploadCnt--;
    }
  }, [uploadedFileList, max, isDuplicatedFilename, isMaxFileCount]);

  return (
    <UploadContainer>
      <Flex dir="column">
        <FileSelectText gap={4} align="center">
          <Icon name="plus" size={16} color="PRIMARY50" />
          <Upload
            accept="application/pdf"
            multiple={multiple}
            beforeUpload={(_, fileList) => {
              if (!selectedCountryId) return false;

              setUploadedFileList(fileList);
              setIsDuplicatedFilename(false);
              setIsMaxFileCount(false);

              return false;
            }}
            itemRender={() => null}
          >
            <Typography.Text style={{ color: palette.PRIMARY50, fontSize: 14 }}>
              파일 선택
            </Typography.Text>
          </Upload>
        </FileSelectText>
        <Flex gap={16} dir="column">
          <FileList
            onFileClick={onFileClick}
            onDelete={onDelete}
            selectedFile={previewedFile}
            files={files}
          />
          {viewerVisible && accept !== '.ai' && accept !== '.zip' && (
            <FileViewer
              file={previewedFile instanceof File ? previewedFile : previewedFile?.uploadFileUrl}
              bodyStyle={{
                height: 442,
                alignItems: !previewedFile ? 'center' : 'flex-start',
              }}
            />
          )}
        </Flex>
      </Flex>
    </UploadContainer>
  );
};

const ArtworkFileUploadContainer = ({
  files,
  selectedCountryFiles,
  setSelectedCountryFiles,
  selectedCountryId,
  viewerVisible = true,
  accept,
  max,
  onUpload,
  onDelete,
}: {
  files: (File | FileItemProp)[];
  selectedCountryFiles: Record<number, (File | FileItemProp)[]>;
  setSelectedCountryFiles: React.Dispatch<
    React.SetStateAction<Record<number, (File | FileItemProp)[]>>
  >;
  selectedCountryId: number | undefined;
  viewerVisible?: boolean;
  accept?: string;
  max?: number;
  onUpload: (file: File) => boolean;
  onDelete?: (file: File) => boolean;
}) => {
  const [previewedFile, setPreviewedFile] = useState<any>(null);
  const handleUpload = (file: File) => {
    const success = onUpload(file);
    if (success !== false) {
      setPreviewedFile(file);
    }
  };

  const handleFileClick = (file: any) => {
    setPreviewedFile(file);
  };
  const handleDelete = (file: any) => {
    if (!(file instanceof File)) {
      setSelectedCountryFiles((prevMap) => {
        if (!selectedCountryId) return { ...prevMap };
        const newFiles = prevMap[selectedCountryId].filter((item) => item !== file);

        prevMap[selectedCountryId] = newFiles;

        return { ...prevMap };
      });
    } else {
      if (!selectedCountryId) return;

      const newFiles = selectedCountryFiles[selectedCountryId].filter((item) => item !== file);

      setSelectedCountryFiles((prevMap) => {
        prevMap[selectedCountryId] = newFiles;

        return prevMap;
      });
    }
    const result = onDelete?.(file);

    if (!result) {
      setPreviewedFile(null);
    }
  };

  useEffect(() => {
    if (!previewedFile) {
      if (Array.isArray(files)) {
        if (files.length > 0) {
          setPreviewedFile(files[0]);
        }
      }
    } else {
      if (Array.isArray(files)) {
        if (files.length > 0) {
          setPreviewedFile(files[0]);
        } else {
          setPreviewedFile(null);
        }
      } else {
        setPreviewedFile(files);
      }
    }
  }, [files]);

  return (
    <FileUpload
      files={files}
      setSelectedCountryFiles={setSelectedCountryFiles}
      selectedCountryId={selectedCountryId}
      previewedFile={previewedFile}
      viewerVisible={viewerVisible}
      accept={accept}
      max={max}
      onUpload={handleUpload}
      onFileClick={handleFileClick}
      onDelete={handleDelete}
    />
  );
};

const ArtworkCountryFileUpload = ({
  artworks,
  savedArtworks,
  setArtworkRegisterData,
  onUpload,
  onDelete,
}: {
  artworks: (File | FileItemProp)[];
  savedArtworks: ProductCountryArtwork[];
  setArtworkRegisterData: React.Dispatch<React.SetStateAction<Record<number, ArtworkItem>>>;
  onUpload: (file: File) => boolean;
  onDelete: (file: File) => boolean;
}) => {
  const [artworkDatas, setArtworkDatas] = useState<Record<number, ArtworkItem>>({});
  const [selectedCountryFiles, setSelectedCountryFiles] = useState<
    Record<number, (File | FileItemProp)[]>
  >({});

  const { productId } = useCurrentProduct();
  const { productArtworkCountries, isProductCountriesLoading } = useProductCountries(productId);
  const [selectedCountryId, setSelectedCountryId] = useState<number | undefined>(
    productArtworkCountries?.[0]?.country.countryId,
  );

  const sortedProductArtworkCountries = useMemo(() => {
    const newMap: Record<number, ProductArtworkCountry> = {};

    if (!productArtworkCountries) return [];

    const china = productArtworkCountries.find(({ country }) => country.countryCode === 'CN');
    const others = productArtworkCountries.filter(({ country }) => country.countryCode !== 'CN');

    others.forEach((data, idx) => {
      newMap[idx + 1] = data;
    });
    if (!china) return Object.values(newMap);

    newMap[0] = china;

    return Object.values(newMap);
  }, [productArtworkCountries]);

  useEffect(() => {
    if (!productArtworkCountries) return;
    setSelectedCountryId(productArtworkCountries?.[0]?.country.countryId);
  }, [productArtworkCountries]);

  useEffect(() => {
    // HINT: updateMode시
    if (savedArtworks?.length > 0) {
      const newArtworkDatas: Record<number, ArtworkItem> = {};
      const newCountryFiles: Record<number, (File | FileItemProp)[]> = [];

      for (const artwork of savedArtworks) {
        const countryFiles = savedArtworks.filter(
          (item) => item.targetCountryId === artwork.targetCountryId,
        );

        newArtworkDatas[artwork.targetCountryId] = {
          ...artworkDatas[artwork.targetCountryId],
          files: countryFiles.map((file) => ({
            filename: file.filename,
            productArtworkId: file.productArtworkId,
            uploadFileUrl: file.uploadFileUrl,
          })),
        };
        newCountryFiles[artwork.targetCountryId] = countryFiles.map((file) => ({
          filename: file.filename,
          productArtworkId: file.productArtworkId,
          uploadFileUrl: file.uploadFileUrl,
        }));
      }

      setArtworkDatas(newArtworkDatas);
      setSelectedCountryFiles(newCountryFiles);
    }
  }, [savedArtworks]);

  useEffect(() => {
    // HINT: 초기 렌더링시
    if (!productArtworkCountries) return;

    const newArtworkDatas: Record<number, ArtworkItem> = {};
    const newCountryFiles: Record<number, File[]> = [];

    for (const { country } of productArtworkCountries) {
      newArtworkDatas[country.countryId] = {
        countryCode: country.countryCode,
        countryId: country.countryId,
        countryNameEn: country.countryNameEn,
        countryNameKo: country.countryNameKo,
      };
      newCountryFiles[country.countryId] = [];
    }
    setArtworkDatas(newArtworkDatas);
    setSelectedCountryId(sortedProductArtworkCountries[0].country.countryId);
    setSelectedCountryFiles(newCountryFiles);
  }, [productArtworkCountries]);

  useEffect(() => {
    const newArtworkDatas: Record<number, ArtworkItem> = {};

    for (const [key, value] of Object.entries(selectedCountryFiles)) {
      newArtworkDatas[Number(key)] = {
        ...artworkDatas[Number(key)],
        countryId: Number(key),
        files: value,
      };
    }

    setArtworkDatas(newArtworkDatas);
    setArtworkRegisterData(newArtworkDatas);
  }, [selectedCountryFiles[selectedCountryId || 0]]);

  return (
    <Container>
      <Spin size="small" spinning={isProductCountriesLoading}>
        <Flex gap={16} justify="center">
          <Flex gap={8} dir="column">
            <Flex align="center" gap={8}>
              <Title>국가</Title>
              <Typography.Text
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: palette.SLATE_GRAY70,
                }}
              >
                * 중국 Artwork은 <span style={{ color: palette.PRIMARY50 }}>필수 업로드</span>{' '}
                해주세요.
              </Typography.Text>
            </Flex>

            <CountryContainer dir="column" align="center">
              {sortedProductArtworkCountries?.map((country) => {
                if (!selectedCountryId) return null;
                const selected = country.countryId === selectedCountryId;
                const fileCount = artworkDatas[country.countryId]?.files?.length;

                return (
                  <CountryItem
                    key={country.countryId}
                    selected={selected}
                    align="center"
                    justify="space-between"
                    onClick={() => {
                      setSelectedCountryId(country.countryId);
                    }}
                  >
                    <Flex>
                      {country.country.countryCode && (
                        <img
                          src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${country.country.countryCode.toLowerCase()}.png`}
                          className="logo"
                          alt=""
                        />
                      )}
                      <CountryNameText selected={selected}>
                        {country.country.countryNameKo}
                      </CountryNameText>
                    </Flex>
                    <FileCountItem
                      selected={fileCount !== undefined && fileCount > 0}
                      align="cetner"
                    >
                      {fileCount || 0}개 업로드
                    </FileCountItem>
                  </CountryItem>
                );
              })}
            </CountryContainer>
          </Flex>
          <Flex gap={8} dir="column" style={{ width: 408 }}>
            <Flex align="center" justify="space-between" style={{ width: '100%' }}>
              <Title>Artwork 파일</Title>
            </Flex>
            <ArtworkFileUploadContainer
              files={selectedCountryFiles[selectedCountryId || 0]}
              onUpload={onUpload}
              onDelete={onDelete}
              max={10}
              selectedCountryFiles={selectedCountryFiles}
              setSelectedCountryFiles={setSelectedCountryFiles}
              selectedCountryId={selectedCountryId}
            />
          </Flex>
        </Flex>
      </Spin>
    </Container>
  );
};

const Container = styled.div`
  .ant-empty-normal {
    margin: 0 !important;
  }
  img.logo {
    width: 20px;
    margin-right: 8px;
  }
`;

const CountryContainer = styled(Flex)`
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  padding: 4px 8px;
  width: 408px;
  height: 592px;
  overflow: scroll;
  overflow-x: hidden;
`;

const ArtworkList = styled(Flex)`
  width: 408px;
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  height: 106px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 4px 8px;
`;

const Title = styled(Typography.Text)`
  color: ${palette.GRAY90};
`;

const CountryItem = styled(Flex)<{ selected: boolean }>`
  width: 100%;
  border-bottom: 1px solid ${palette.GRAY20};
  padding: 12px 16px;
  background-color: ${({ selected }) => (selected ? palette.LIGHT_BLUE20 : palette.ETC_WHITE)};
  cursor: pointer;
`;

const CountryNameText = styled(Typography.Text)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? palette.GRAY90 : palette.GRAY70)};
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  font-size: 14px;
`;

const FileCountItem = styled(Flex)<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? palette.PRIMARY30 : palette.GRAY20)};
  color: ${({ selected }) => (selected ? palette.PRIMARY50 : palette.GRAY50)};
  border-radius: 4px;
  padding: 3px 4px;
  font-size: 12px;
  font-weight: 500;
`;

const FileItem = styled(Flex)<{ selected: boolean }>`
  width: 100%;
  padding: 10px 16px;
  background-color: ${({ selected }) => (selected ? palette.LIGHT_BLUE10 : palette.ETC_WHITE)};
  svg {
    width: 18px !important;
  }
  cursor: pointer;
`;

const UploadContainer = styled.div`
  position: relative;
`;

const FileSelectText = styled(Flex)`
  position: absolute;
  top: -30px;
  cursor: pointer;
  right: 0;
`;

export default ArtworkCountryFileUpload;

import { MouseEvent, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import Flex from './Flex/Flex';
import Icon from './Icon/Icon';

type Direction = 'top' | 'left';

const Container = styled(Flex)<{
  visible: boolean;
  direction: Direction;
}>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  position: absolute;
  width: fit-content;
  padding: 8px 12px;
  background: ${palette.SLATE_GRAY70};
  border-radius: 16px;
  white-space: nowrap;
  z-index: 1;
  cursor: default;
  pointer-events: none;

  svg {
    cursor: pointer;
    pointer-events: auto;
  }

  ${({ direction }) => {
    if (direction === 'top') {
      return `
        bottom: calc(100% + 12px);
        left: 50%;
        transform: translateX(-50%);
      `;
    } else if (direction === 'left') {
      return `
        right: calc(100% + 12px);
        top: 50%;
        transform: translateY(-50%);
      `;
    }

    return '';
  }}

  &::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    display: block;

    ${({ direction }) => {
      if (direction === 'top') {
        return `
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid ${palette.SLATE_GRAY70};
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        `;
      } else if (direction === 'left') {
        return `
          border-left: 4px solid ${palette.SLATE_GRAY70};
          border-bottom: 4px solid transparent;
          border-top: 4px solid transparent;
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
        `;
      }

      return '';
    }}
  }
`;

Container.defaultProps = {
  align: 'center',
  justify: 'center',
};

// 해당 컴포넌트는 부모의 position을 relative로 바꿉니다.
const Tooltip = ({
  direction = 'top',
  title = '',
  visible = false,
  onClose,
}: {
  direction?: Direction;
  title?: string;
  visible?: boolean;
  onClose?: VoidFunction;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleCloseClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClose?.();
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const parentEl = containerRef.current.parentElement;
    if (!parentEl) return;

    const originPosition = parentEl.style.position;

    if (visible) {
      parentEl.style.position = 'relative';
    }

    return () => {
      parentEl.style.position = originPosition;
    };
  }, [visible]);

  return (
    <Container ref={containerRef} visible={visible} direction={direction}>
      <Typography.Text
        type="BODY_2"
        color="ETC_WHITE"
        gutter={{ right: 12 }}
        style={{ height: 20 }}
      >
        {title}
      </Typography.Text>
      <Icon name="close" color="ETC_WHITE" size={20} onClick={handleCloseClick} />
    </Container>
  );
};

export default Tooltip;

import styled from 'styled-components';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { Typography } from 'components/system';
import path from 'lib/path';
import { s3AssetDomain } from 'lib/consts';
import Category from 'components/system/general/Typography/Category';
import { Flex } from 'components/ui';

const Container = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: 56px;
  width: 200px;
  height: 56px;
  font-size: 16px;
`;

const StyledImg = styled.img`
  margin-top: 16px;
  width: 520px;
  height: 260px;
`;

const advantages = ['저렴한 가격', '간편한 프로세스', '편리한 입력', '빠른 취득'];

const NMPARequest = () => {
  return (
    <Container>
      <Typography.Text type="TITLE_1" bold style={{ justifyContent: 'center' }}>
        중국 원료 신고번호를 취득하고 싶으신가요?
      </Typography.Text>
      <StyledImg src={`${s3AssetDomain}/Certificate.png`} alt="nmpa_request" />
      <Flex style={{ marginTop: 16 }} gap={12} justify="center">
        {advantages.map((item) => (
          <Category
            medium
            type="primary"
            inline
            key={item}
            style={{ padding: '4px 10px', borderRadius: '31px' }}
          >
            {item}
          </Category>
        ))}
      </Flex>
      <Typography.Text light type="HEADLINE_1" gutter={{ top: 40 }}>
        certicos만의 자동화 솔루션을
        <br />
        특별한 가격에 만나보세요.
      </Typography.Text>
      <StyledButton type="primary" style={{ marginTop: 40 }}>
        <Link to={path.material.nmpa.estimate.checkList}>견적 확인해 보기</Link>
      </StyledButton>
    </Container>
  );
};

export default NMPARequest;

import ProductPageTemplate from 'templates/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { ProductEfficacyEvaluation } from 'components/product/ProductEfficacyEvaluation';
import GoBackIfThereIsNoCountryId from 'components/common/GoBackIfThereIsNoCountryId';

const notices = [
  '인체 효능 평가 시험, 소비자 사용 테스트, 실험실 시험 레포트로 제출하시는 경우 해당 테스트 레포트는 원문과 중문이 모두 필요합니다.',
  '중문 번역이 필요하신 경우는 전화나 하단의 ‘이용 문의’로 certicos의 중문 번역 서비스를 이용하실 수 있습니다. (추가 비용 발생)',
  <MistakeNoticeItem />,
];

const ProductEfficacyEvaluationPage = () => {
  return (
    <ProductPageTemplate
      subtitle="효능 평가 자료"
      notices={notices}
      exampleImg="Efficacy+Evaluation+Data.png"
      exampleImgWidth={920}
      childrenStyle={{ paddingTop: 0 }}
    >
      <GoBackIfThereIsNoCountryId />
      <ProductEfficacyEvaluation />
    </ProductPageTemplate>
  );
};

export default ProductEfficacyEvaluationPage;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, message } from 'antd';

import RegisterTerms from 'components/auth/RegisterTerms';
import path from 'lib/path';
import * as authActions from 'modules/auth.js';

const RegisterTermsContainer = () => {
  const navigate = useNavigate();
  const { companyType } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isTermsChecked, setTermsChecked] = useState(false);
  const [isPrivateChecked, setPrivateChecked] = useState(false);
  const [isThirdPartyChecked, setThirdPartyChecked] = useState(false);
  const [isPolicyChecked, setPolicyChecked] = useState(false);
  const isLogoChecked = useSelector(({ auth }) => auth.register.isUseOfLogoAgreed);
  const onCheckAll = (isChecked: boolean) => {
    form.setFieldsValue({
      terms: isChecked,
      private: isChecked,
      thirdParty: isChecked,
      policy: isChecked,
      logo: isChecked,
    });
    setTermsChecked(isChecked);
    setPrivateChecked(isChecked);
    setThirdPartyChecked(isChecked);
    setPolicyChecked(isChecked);
    dispatch(authActions.setLogoTermsAgreed(isChecked));
  };
  const handleCheckLogo = (isChecked: boolean) => {
    dispatch(authActions.setLogoTermsAgreed(isChecked));
  };
  const handleClickNext = () => {
    navigate(`${path.register.form}/${companyType}`);
  };
  const onFail = () => {
    message.warning('필수 항목에 동의해 주세요.');
  };
  useEffect(() => {
    dispatch(authActions.setLogoTermsAgreed(false));
  }, []);
  return (
    <RegisterTerms
      companyType={companyType}
      form={form}
      isTermsChecked={isTermsChecked}
      isPrivateChecked={isPrivateChecked}
      isThirdPartyChecked={isThirdPartyChecked}
      isPolicyChecked={isPolicyChecked}
      isLogoChecked={isLogoChecked}
      onCheckAll={onCheckAll}
      onCheckTerm={setTermsChecked}
      onCheckPrivate={setPrivateChecked}
      onCheckThirdParty={setThirdPartyChecked}
      onCheckPolicy={setPolicyChecked}
      onCheckLogo={handleCheckLogo}
      onClickNext={handleClickNext}
      onFail={onFail}
    />
  );
};

export default RegisterTermsContainer;

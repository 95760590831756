import { Button, ButtonProps } from 'antd';
import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';

interface Props extends ButtonProps {
  selected: boolean;
}

const StyledTabButton = styled(Button)<{ selected: boolean }>`
  &.ant-btn {
    padding: 10px 16px;
    border-color: ${palette.GRAY40};
    color: ${palette.SLATE_GRAY60};
    height: 40px;
    padding: 9px 16px;
    line-height: 20px;

    &:hover {
      border-color: ${palette.GRAY40};
      color: ${palette.SLATE_GRAY60};
    }

    ${({ selected }) =>
      selected &&
      css`
        border-color: ${palette.SLATE_GRAY20};
        background-color: ${palette.SLATE_GRAY20};
        color: ${palette.SLATE_GRAY70};
        cursor: default;
        -webkit-text-stroke: 0.4px ${palette.SLATE_GRAY70};

        &:hover {
          border-color: ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
        }
      `}
  }
`;

const TabButton = (props: Props) => {
  return <StyledTabButton {...props}>{props.children}</StyledTabButton>;
};

export default TabButton;

import { Button, Card, Form, Input, message, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import path from 'lib/path';
import { firstChangePassword } from 'modules/auth.js';
import { requireRule } from 'lib/validate';
import { useNavigate } from 'react-router-dom';

const ChangePasswordPageBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading } = useSelector(({ auth, loading }) => ({
    user: auth.user,
    loading: loading['auth/FIRST_CHANGE_PASSWORD'],
  }));
  const changePassword = ({
    prevPassword,
    password,
    passwordCheck,
  }: {
    prevPassword: string;
    password: string;
    passwordCheck: string;
  }) => {
    if (password !== passwordCheck) return message.warning('비밀번호가 서로 일치하지 않습니다.');
    dispatch(
      firstChangePassword({
        userId: user.userId,
        prevPassword,
        password,
        userType: user.userType,
      }),
    );
  };
  const logout = () => {
    navigate(path.logout, { replace: true });
  };
  return (
    <ChangePasswordPageBlock>
      <Card style={{ width: '100%', maxWidth: 480 }}>
        <Typography.Title level={5}>새로운 비밀번호 설정</Typography.Title>
        <Typography.Paragraph style={{ marginBottom: 32 }}>
          새로운 비밀번호 설정이 필요합니다.
        </Typography.Paragraph>
        <Form layout="vertical" colon={false} onFinish={changePassword}>
          <Form.Item label="임시 비밀번호" name="prevPassword" rules={[requireRule]}>
            <Input.Password />
          </Form.Item>
          <Form.Item label="새로운 비밀번호" name="password" rules={[requireRule]}>
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item label="새로운 비밀번호 확인" name="passwordCheck" rules={[requireRule]}>
            <Input.Password />
          </Form.Item>
          <FooterBox>
            <Button onClick={logout}>취소</Button>
            <Button loading={loading} type="primary" htmlType="submit">
              비밀번호 변경하기
            </Button>
          </FooterBox>
        </Form>
      </Card>
    </ChangePasswordPageBlock>
  );
};

export default ChangePasswordPage;

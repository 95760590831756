import { ReactComponent as Back } from 'asset/svg/arrows/back.svg';
import { ReactComponent as Down } from 'asset/svg/arrows/down.svg';
import { ReactComponent as Front } from 'asset/svg/arrows/front.svg';
import { ReactComponent as Left } from 'asset/svg/arrows/left.svg';
import { ReactComponent as Refresh } from 'asset/svg/arrows/refresh.svg';
import { ReactComponent as Right } from 'asset/svg/arrows/right.svg';
import { ReactComponent as Top } from 'asset/svg/arrows/top.svg';
import { ReactComponent as Up } from 'asset/svg/arrows/up.svg';
import { ReactComponent as Logo } from 'asset/svg/brandIdentity/logo.svg';
import { ReactComponent as ControlMinus } from 'asset/svg/control.svg';
import { ReactComponent as Check } from 'asset/svg/control/check.svg';
import { ReactComponent as CheckCircleActived } from 'asset/svg/control/check_circle_actived.svg';
import { ReactComponent as CheckCircleHover } from 'asset/svg/control/check_circle_hover.svg';
import { ReactComponent as CheckCircleInactived } from 'asset/svg/control/check_circle_inactived.svg';
import { ReactComponent as Close } from 'asset/svg/control/close.svg';
import { ReactComponent as Error } from 'asset/svg/control/error.svg';
import { ReactComponent as Filter } from 'asset/svg/control/filter.svg';
import { ReactComponent as Find } from 'asset/svg/control/find.svg';
import { ReactComponent as HamburgerMenu } from 'asset/svg/control/hamburger_menu.svg';
import { ReactComponent as Hide } from 'asset/svg/control/hide.svg';
import { ReactComponent as Info } from 'asset/svg/control/info.svg';
import { ReactComponent as Lock } from 'asset/svg/control/lock.svg';
import { ReactComponent as LockFill } from 'asset/svg/control/lock_fill.svg';
import { ReactComponent as Move } from 'asset/svg/control/move.svg';
import { ReactComponent as Newpage } from 'asset/svg/control/newpage.svg';
import { ReactComponent as Num } from 'asset/svg/control/num.svg';
import { ReactComponent as Search } from 'asset/svg/control/search.svg';
import { ReactComponent as Send } from 'asset/svg/control/send.svg';
import { ReactComponent as Show } from 'asset/svg/control/show.svg';
import { ReactComponent as SmallClose } from 'asset/svg/control/small_close.svg';
import { ReactComponent as Success } from 'asset/svg/control/success.svg';
import { ReactComponent as Warning } from 'asset/svg/control/warning.svg';
import { ReactComponent as ZoomIn } from 'asset/svg/control/zoom_in.svg';
import { ReactComponent as ZoomOut } from 'asset/svg/control/zoom_out.svg';
import { ReactComponent as DocGlass } from 'asset/svg/custom/doc_glass.svg';
import { ReactComponent as DocSettingBlue } from 'asset/svg/custom/doc_setting_blue.svg';
import { ReactComponent as DocStarBlue } from 'asset/svg/custom/doc_star_blue.svg';
import { ReactComponent as DocStarGray } from 'asset/svg/custom/doc_star_gray.svg';
import { ReactComponent as Folder } from 'asset/svg/custom/folder.svg';
import { ReactComponent as FolderBlue } from 'asset/svg/custom/folder_blue.svg';
import { ReactComponent as Robot } from 'asset/svg/custom/robot.svg';
import { ReactComponent as Screening } from 'asset/svg/custom/screening.svg';
import { ReactComponent as Clause } from 'asset/svg/document/clause.svg';
import { ReactComponent as Clip } from 'asset/svg/document/clip.svg';
import { ReactComponent as Clipboard } from 'asset/svg/document/clipboard.svg';
import { ReactComponent as Download } from 'asset/svg/document/download.svg';
import { ReactComponent as DownloadActived } from 'asset/svg/document/download_actived.svg';
import { ReactComponent as DownloadDisabled } from 'asset/svg/document/download_disabled.svg';
import { ReactComponent as FolderAddFill } from 'asset/svg/document/folder_add_fill.svg';
import { ReactComponent as Image } from 'asset/svg/document/image.svg';
import { ReactComponent as List } from 'asset/svg/document/list.svg';
import { ReactComponent as ListCheck } from 'asset/svg/document/list_check.svg';
import { ReactComponent as MultipleAdd } from 'asset/svg/document/multiple_add.svg';
import { ReactComponent as Page } from 'asset/svg/document/page.svg';
import { ReactComponent as PageAdd } from 'asset/svg/document/page_add.svg';
import { ReactComponent as Upload } from 'asset/svg/document/upload.svg';
import { ReactComponent as Copy } from 'asset/svg/edit/copy.svg';
import { ReactComponent as Delete } from 'asset/svg/edit/delete.svg';
import { ReactComponent as Minus } from 'asset/svg/edit/minus.svg';
import { ReactComponent as Plus } from 'asset/svg/edit/plus.svg';
import { ReactComponent as Question } from 'asset/svg/edit/question.svg';
import { ReactComponent as QuestionDisable } from 'asset/svg/edit/question_disable.svg';
import { ReactComponent as TextBackcolor } from 'asset/svg/edit/text_backcolor.svg';
import { ReactComponent as TextBold } from 'asset/svg/edit/text_bold.svg';
import { ReactComponent as TextColor } from 'asset/svg/edit/text_color.svg';
import { ReactComponent as TextUnderline } from 'asset/svg/edit/text_underline.svg';
import { ReactComponent as Textsms } from 'asset/svg/edit/textsms.svg';
import { ReactComponent as Write } from 'asset/svg/edit/write.svg';
import { ReactComponent as FormulaDirect } from 'asset/svg/formula_direct.svg';
import { ReactComponent as FormulaTemplete } from 'asset/svg/formula_templete.svg';
import { ReactComponent as Advertisement } from 'asset/svg/others/advertisement.svg';
import { ReactComponent as Book } from 'asset/svg/others/book.svg';
import { ReactComponent as Building } from 'asset/svg/others/building.svg';
import { ReactComponent as Chart } from 'asset/svg/others/chart.svg';
import { ReactComponent as Code } from 'asset/svg/others/code.svg';
import { ReactComponent as Court } from 'asset/svg/others/court.svg';
import { ReactComponent as Empty } from 'asset/svg/others/empty.svg';
import { ReactComponent as EtcImgUpload } from 'asset/svg/others/etc_img_upload.svg';
import { ReactComponent as Experiment } from 'asset/svg/others/experiment.svg';
import { ReactComponent as Factory } from 'asset/svg/others/factory.svg';
import { ReactComponent as Flask } from 'asset/svg/others/flask.svg';
import { ReactComponent as FlaskDelete } from 'asset/svg/others/flask_delete.svg';
import { ReactComponent as FormulaIcon } from 'asset/svg/others/formula.svg';
import { ReactComponent as Good } from 'asset/svg/others/good.svg';
import { ReactComponent as Headset } from 'asset/svg/others/headset.svg';
import { ReactComponent as Paid } from 'asset/svg/others/paid.svg';
import { ReactComponent as Protect } from 'asset/svg/others/protect.svg';
import { ReactComponent as Scanning } from 'asset/svg/others/scanning.svg';
import { ReactComponent as StibeeIcon } from 'asset/svg/stibee.svg';
import { ReactComponent as StibeeCloseIcon } from 'asset/svg/stibeeCloseIcon.svg';
import { ReactComponent as Badge } from 'asset/svg/system/badge.svg';
import { ReactComponent as Bell } from 'asset/svg/system/bell.svg';
import { ReactComponent as Exit } from 'asset/svg/system/exit.svg';
import { ReactComponent as Person } from 'asset/svg/system/person.svg';
import { ReactComponent as Print } from 'asset/svg/system/print.svg';
import { ReactComponent as Setting } from 'asset/svg/system/setting.svg';
import { ReactComponent as Calendar } from 'asset/svg/time/calendar.svg';
import { ReactComponent as Clock } from 'asset/svg/time/clock.svg';
import { ReactComponent as History } from 'asset/svg/time/history.svg';
import { ReactComponent as UploadDocIcon } from 'asset/svg/upload_doc.svg';
import { ReactComponent as Resizer } from 'asset/svg/resizer.svg';
import { ReactComponent as BlueDown } from 'asset/svg/blue_down.svg';
import { ReactComponent as TooltipArrow2 } from 'asset/svg/tooltip_arrow2.svg';
import { ReactComponent as ToDo } from 'asset/svg/to_do.svg';

export const icons = {
  // Arrows
  back: Back,
  front: Front,
  top: Top,
  down: Down,
  up: Up,
  left: Left,
  right: Right,
  refresh: Refresh,
  // Control
  smallClose: SmallClose,
  close: Close,
  check: Check,
  checkCircleInactived: CheckCircleInactived,
  checkCircleHover: CheckCircleHover,
  checkCircleActived: CheckCircleActived,
  zoomIn: ZoomIn,
  zoomOut: ZoomOut,
  search: Search,
  find: Find,
  hamburgerMenu: HamburgerMenu,
  show: Show,
  hide: Hide,
  filter: Filter,
  newpage: Newpage,
  move: Move,
  lock: Lock,
  lockFill: LockFill,
  num: Num,
  info: Info,
  error: Error,
  success: Success,
  warning: Warning,
  send: Send,
  // Edit
  plus: Plus,
  minus: Minus,
  delete: Delete,
  write: Write,
  copy: Copy,
  textsms: Textsms,
  question: Question,
  questionDisable: QuestionDisable,
  textBold: TextBold,
  textUnderline: TextUnderline,
  textColor: TextColor,
  textBackcolor: TextBackcolor,
  // System
  exit: Exit,
  print: Print,
  setting: Setting,
  person: Person,
  bell: Bell,
  badge: Badge,
  // Time
  calendar: Calendar,
  clock: Clock,
  history: History,
  // Document
  download: Download,
  upload: Upload,
  downloadActived: DownloadActived,
  downloadDisabled: DownloadDisabled,
  clipboard: Clipboard,
  clause: Clause,
  page: Page,
  image: Image,
  pageAdd: PageAdd,
  multipleAdd: MultipleAdd,
  folderAddFill: FolderAddFill,
  list: List,
  liskCheck: ListCheck,
  clip: Clip,
  // Custom
  docStarGray: DocStarGray,
  docStarBlue: DocStarBlue,
  screening: Screening,
  docSettingBlue: DocSettingBlue,
  docGlass: DocGlass,
  folderBlue: FolderBlue,
  folder: Folder,
  robot: Robot,
  // Others
  empty: Empty,
  etcImgUpload: EtcImgUpload,
  experiment: Experiment,
  good: Good,
  protect: Protect,
  flask: Flask,
  flaskDelete: FlaskDelete,
  paid: Paid,
  book: Book,
  scanning: Scanning,
  code: Code,
  building: Building,
  court: Court,
  advertisement: Advertisement,
  headset: Headset,
  chart: Chart,
  factory: Factory,
  formulaTemplete: FormulaTemplete,
  formulaDirect: FormulaDirect,
  controlMinus: ControlMinus,
  stibeeIcon: StibeeIcon,
  stibeeCloseIcon: StibeeCloseIcon,
  formulaIcon: FormulaIcon,
  uploadDocIcon: UploadDocIcon,
  resizer: Resizer,
  toDo: ToDo,
  // Brand Identity
  logo: Logo,
  blueDown: BlueDown,
  tooltipArrow2: TooltipArrow2,
};

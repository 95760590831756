import { useState, Fragment, useRef } from 'react';
import styled from 'styled-components';
import { Button, Col, Form, FormInstance, Input, Row, Checkbox, Select, InputRef } from 'antd';
import produce from 'immer';
import { useLocation } from 'react-router-dom';

import { emailRule, exceptKoreanRule, phoneRule, requireRule } from 'lib/validate';
import * as authApi from 'lib/api/auth';
import palette from 'lib/styles/palette';
import { FileUpload, Tip, Typography } from 'components/system';
import { MinusIcon } from 'components/system/general/icon';
import { CompanyType } from 'types/company';
import { MaterialCompany } from 'types/material/company';
import Icon from 'components/ui/Icon/Icon';
import path from 'lib/path';
import { Flex } from 'components/ui';

const MaterialFormBlock = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  .previewText {
    visibility: hidden;
  }
`;

const StyledFormItem = styled(Form.Item)`
  label.ant-form-item-no-colon {
    display: flex;
    width: 100%;
  }
`;

const MaterialForm = ({
  visible,
  form,
  materials,
  updateMode,
  bizLicenseEnUrl,
}: {
  visible: boolean;
  form: FormInstance;
  materials: MaterialCompany[];
  updateMode: boolean;
  bizLicenseEnUrl: string | null;
}) => {
  const location = useLocation();
  const mypageRoute = location.pathname.includes(path.my);

  const [isBizNumberCheckSuccess, setIsBizNumberCheckSuccess] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const handleSelectSearch = (value: string) => {
    setSearchValue(value.replace(/\s/gi, ''));
  };
  const [isEqualCompanyAddressWithFactoryAddress, setIsEqualCompanyAddressWithFactoryAddress] =
    useState(false);
  const isMyPage = !!mypageRoute;

  const handleChangeIsEqualCompanyAddressWithFactoryAddress = (checked: boolean) => {
    if (checked) {
      const factories = form.getFieldValue('factories');
      const addressKo = form.getFieldValue('addressKo');
      const addressEn = form.getFieldValue('addressEn');
      form.setFieldsValue({
        factories: produce(factories, (proxy: any) => {
          proxy[0] = { addressKo, addressEn };
        }),
      });
    }
    setIsEqualCompanyAddressWithFactoryAddress(checked);
  };
  const inputRef = useRef<InputRef>(null);

  return (
    <MaterialFormBlock visible={visible}>
      {!isMyPage && (
        <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
          1. 회사 정보
        </Typography.Text>
      )}
      <Form.Item
        shouldUpdate={(prev, next) => prev.companyNameKo !== next.companyNameKo}
        label={
          <Row gutter={8} align="middle">
            <Col>
              <Typography.Text type="TITLE_2">회사명 (국문/영문)</Typography.Text>
            </Col>
            <Col>
              <Tip trigger="click">
                <Typography.Text type="SMALL">
                  사업자등록증의 사명과 동일하게 입력하되, 띄어쓰기 없이 입력해 주세요.
                  <br />
                  중복 데이터 또는 오인 표기를 줄이기 위함이므로,
                </Typography.Text>
                <Typography.Text type="SMALL" color="PRIMARY50">
                  회사명에 띄어쓰기가 입력되지 않아도 무방합니다.
                </Typography.Text>
              </Tip>
            </Col>
          </Row>
        }
        style={{ marginBottom: 0 }}
        required
      >
        {({ getFieldValue, setFieldsValue }) => (
          <Row gutter={8} wrap={false}>
            <Col flex="auto">
              <Form.Item name="companyNameKo" rules={[requireRule]} style={{ marginBottom: 0 }}>
                <Select
                  searchValue={searchValue}
                  onSearch={handleSelectSearch}
                  showSearch
                  placeholder="국문 회사명 검색 또는 선택"
                  notFoundContent={
                    getFieldValue('companyNameKo') !== 'companyNameKoDirect' ? (
                      <Typography.Text
                        style={{ cursor: 'pointer' }}
                        color="GRAY90"
                        type="BODY_2"
                        onClick={() => {
                          setFieldsValue({
                            companyNameKo: 'companyNameKoDirect',
                          });
                        }}
                      >
                        직접입력
                      </Typography.Text>
                    ) : null
                  }
                  disabled={updateMode}
                  onSelect={(value) => {
                    if (value === 'companyNameKoDirect') {
                      setTimeout(() => inputRef?.current?.focus());
                    }
                  }}
                >
                  <Select.Option key="companyNameKoDirect" value="companyNameKoDirect">
                    직접입력
                  </Select.Option>
                  {materials.map((material) => (
                    <Select.Option key={material.materialCompanyId} value={material.companyNameKo}>
                      {material.companyNameKo}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="0 0 50%">
              <Form.Item
                name={
                  getFieldValue('companyNameKo') !== 'companyNameKoDirect'
                    ? 'companyNameKo'
                    : 'companyNameKoDirect'
                }
                normalize={(value) => value.replace(/\s/gi, '')}
                rules={[requireRule]}
                style={{ marginBottom: 8 }}
              >
                <Input
                  ref={inputRef}
                  disabled={getFieldValue('companyNameKo') !== 'companyNameKoDirect' || updateMode}
                  placeholder={
                    getFieldValue('companyNameKo') === 'companyNameKoDirect'
                      ? '국문 회사명 여기에 입력'
                      : undefined
                  }
                  style={
                    !updateMode
                      ? {
                          color: palette.GRAY90,
                          backgroundColor: palette.ETC_WHITE,
                        }
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.Item>
      <Form.Item name="companyNameEn" rules={[requireRule, exceptKoreanRule]}>
        <Input placeholder="영문 회사명" disabled={updateMode} />
      </Form.Item>
      <Form.Item
        name="bizNumber"
        label="사업자등록번호"
        validateStatus={isBizNumberCheckSuccess ? 'success' : undefined}
        help={isBizNumberCheckSuccess ? '사용 가능한 사업자등록번호' : undefined}
        normalize={(value) => {
          if (value.length > 12) return value.substring(0, 12);
          if (/[^0-9-]/.test(value)) return value.replace(/[^0-9-]/, '');
          if (value.slice(-1) === '-') return value;
          return value
            .replace(/[-]/g, '')
            .replace(/^(\d{3})(\d{1,})/, '$1-$2')
            .replace(/^(\d{3}-\d{2})(\d{1,})/, '$1-$2');
        }}
        rules={[
          requireRule,
          {
            pattern: /^\d{3}-\d{2}-\d{5}$/,
            message: '올바르지 않은 사업자등록번호 형식',
          },
          {
            validator: async (_, bizNumber) => {
              if (updateMode || !/^\d{3}-\d{2}-\d{5}$/.test(bizNumber)) {
                return;
              }
              try {
                await authApi.bizNumberDupCheck({
                  bizNumber,
                  companyType: CompanyType.MATERIAL,
                });
                setIsBizNumberCheckSuccess(true);
              } catch (e) {
                setIsBizNumberCheckSuccess(false);
                throw new Error('이미 가입되어 있는 사업자등록번호');
              }
              return;
            },
          },
        ]}
      >
        <Input
          disabled={updateMode}
          autoComplete="off"
          onChange={() => setIsBizNumberCheckSuccess(false)}
        />
      </Form.Item>
      <Form.Item label="회사 본사 주소 (국문/영문)" name="addressKo" required rules={[requireRule]}>
        <Input
          placeholder="국문: 사업자 등록증 상 주소와 동일하게 입력"
          onChange={() => setIsEqualCompanyAddressWithFactoryAddress(false)}
        />
      </Form.Item>
      <Form.Item name="addressEn" required={false} rules={[requireRule, exceptKoreanRule]}>
        <Input
          placeholder="영문"
          onChange={() => setIsEqualCompanyAddressWithFactoryAddress(false)}
        />
      </Form.Item>
      <Form.List name="factories" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Fragment key={field.key}>
                <Row wrap={false} align="middle" gutter={8}>
                  <Col flex="0 0 100%">
                    <StyledFormItem
                      label={
                        updateMode || field.name !== 0 ? (
                          `제 ${field.name + 1}공장 소재지`
                        ) : (
                          <Row justify="space-between" style={{ width: '100%' }}>
                            <Col>제 {field.name + 1}공장 소재지</Col>
                            <Col>
                              <Checkbox
                                checked={isEqualCompanyAddressWithFactoryAddress}
                                onChange={(e) =>
                                  handleChangeIsEqualCompanyAddressWithFactoryAddress(
                                    e.target.checked,
                                  )
                                }
                              >
                                회사 본사 주소와 동일
                              </Checkbox>
                            </Col>
                          </Row>
                        )
                      }
                      name={[field.name, 'addressKo']}
                      rules={field.name !== 0 || fields.length >= 2 ? [requireRule] : [{}]}
                      required={false}
                    >
                      <Input
                        placeholder="국문"
                        onChange={() =>
                          field.name === 0 && setIsEqualCompanyAddressWithFactoryAddress(false)
                        }
                      />
                    </StyledFormItem>
                  </Col>
                  {field.name !== 0 && (
                    <Col>
                      <MinusIcon
                        style={{
                          position: 'relative',
                          top: 10,
                        }}
                        onClick={() => {
                          remove(field.name);
                          if (fields.length === 2) {
                            setTimeout(() => {
                              form.validateFields([
                                ['factories', 0, 'addressKo'],
                                ['factories', 0, 'addressEn'],
                              ]);
                            });
                          }
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <Form.Item
                  name={[field.name, 'addressEn']}
                  rules={[
                    exceptKoreanRule,
                    ...(field.name !== 0 || fields.length >= 2 ? [requireRule] : []),
                  ]}
                >
                  <Input
                    placeholder="영문"
                    onChange={() =>
                      field.name === 0 && setIsEqualCompanyAddressWithFactoryAddress(false)
                    }
                  />
                </Form.Item>
              </Fragment>
            ))}
            <Button
              type="dashed"
              block
              icon={<Icon name="plus" size={14} color="PRIMARY50" />}
              onClick={() => {
                add({ addressKo: undefined, addressEn: undefined });
                if (fields.length === 1) {
                  setTimeout(() => {
                    form.validateFields([
                      ['factories', 0, 'addressKo'],
                      ['factories', 0, 'addressEn'],
                    ]);
                  });
                }
              }}
              style={{
                marginBottom: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              공장 소재지 추가
            </Button>
          </>
        )}
      </Form.List>
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name="ceoNameKo" label="대표자 성명 (국문/영문)" rules={[requireRule]}>
            <Input placeholder="국문" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name="ceoNameEn" required rules={[requireRule, exceptKoreanRule]}>
            <Input placeholder="영문" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name="tel" label="회사 전화번호" rules={[requireRule, phoneRule]}>
            <Input placeholder="숫자만 입력 가능" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name="fax" label="팩스번호 (FAX)" rules={[phoneRule]}>
            <Input placeholder="숫자만 입력 가능" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="회사 이메일" name="companyEmail" rules={[requireRule, emailRule]} required>
        <Input placeholder="회사 이메일 입력" />
      </Form.Item>
      <Form.Item name="mainItem" label="주요 품목" required rules={[requireRule]}>
        <Input.TextArea
          placeholder="주요 취급 원료의 품목을 입력해 주세요."
          cols={2}
          style={{ resize: 'none', padding: '10px 16px' }}
        />
      </Form.Item>
      <Form.Item name="introduction" label="회사 소개 (설명)" required rules={[requireRule]}>
        <Input.TextArea
          placeholder="회사 소개를 간단하게 입력해 주세요."
          cols={2}
          style={{ resize: 'none', padding: '10px 16px' }}
        />
      </Form.Item>
      <Flex gap={16} dir={updateMode ? 'row' : 'column'}>
        <Form.Item
          label={
            <Row gutter={8} align="middle">
              <Col>국문 사업자등록증 (PDF)</Col>
              {updateMode && (
                <Col>
                  <Tip trigger="click">
                    <Typography.Text type="SMALL">
                      - 사업자등록증의 내용이 기존과 달라졌을 경우, 반드시 수정이
                      <br />
                      필요합니다.
                    </Typography.Text>
                    <Typography.Text type="SMALL">
                      - 수정을 원하시면{' '}
                      <Typography.Text type="SMALL" medium color="PRIMARY50" inline>
                        전화나 하단의 ‘이용 문의’로 문의바랍니다.
                      </Typography.Text>
                    </Typography.Text>
                  </Tip>
                </Col>
              )}
            </Row>
          }
          required
          name="bizLicenseFile"
          rules={[requireRule]}
        >
          <FileUpload readOnly={updateMode} bodyStyle={{ height: 448 }} />
        </Form.Item>
        {!(updateMode && !bizLicenseEnUrl) && (
          <Form.Item
            label={
              <Row gutter={8} align="middle">
                <Col>영문 사업자등록증 (PDF)</Col>
                <Col>
                  {!updateMode ? (
                    <Tip bodyStyle={{ width: 340 }} trigger="click">
                      <Typography.Text type="SMALL">
                        영문 사업자등록증이 없으신가요?
                        <br />
                        영문 사업자등록증은 인증 진행시 필요한 서류이므로
                        <br />
                        없으신 경우 전화나 하단의 ‘이용 문의’로 문의바랍니다.
                      </Typography.Text>
                    </Tip>
                  ) : (
                    <Tip trigger="click">
                      <Typography.Text type="SMALL">
                        - 사업자등록증의 내용이 기존과 달라졌을 경우, 반드시 수정이
                        <br />
                        필요합니다.
                      </Typography.Text>
                      <Typography.Text type="SMALL">
                        - 수정을 원하시면{' '}
                        <Typography.Text type="SMALL" color="PRIMARY50" inline>
                          전화나 하단의 ‘이용 문의’로 문의바랍니다.
                        </Typography.Text>
                      </Typography.Text>
                    </Tip>
                  )}
                </Col>
              </Row>
            }
            required
            name="bizLicenseEnFile"
            rules={[requireRule]}
          >
            <FileUpload readOnly={updateMode} bodyStyle={{ height: 448 }} />
          </Form.Item>
        )}
      </Flex>
    </MaterialFormBlock>
  );
};

export default MaterialForm;

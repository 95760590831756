import { Form, message } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as certApi from 'lib/api/certificate';
import * as certificateActions from 'modules/certificate';
import { messages } from 'lib/consts';
import { ProductKeepWarningBasic, ProductKeepWarningFetch } from 'types/certificate';
import { useProductDocStatus } from 'service/product';
import { DocumentCode } from 'types/product';
import useGA, { EGAActionType } from 'hook/useGA';

export const useProductKeepWarning = (productId: number, countryId: number) => {
  const navigate = useNavigate();
  const { sendEventToGA } = useGA();
  const { productDocStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.KW,
  });
  const updateMode = productDocStatus ? productDocStatus.status !== 'INP' : false;
  const { data: productKeepWarning = null, isFetching: getLoading } = useQuery(
    ['keep-warning', productId, countryId],
    () => certApi.getProductKeepWarning({ productId, countryId: countryId! }),
    {
      select: (res) => res.data.result,
      enabled: updateMode,
    },
  );

  const { mutate: addProductKeepWarning, isLoading: addLoading } = useMutation(
    (countryVenderInfoAdd: ProductKeepWarningFetch) =>
      certApi.addProductKeepWarning(countryVenderInfoAdd),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '사용 보관상의 주의사항 선택',
          actionType: EGAActionType.REGISTER,
        });
        message.success('입력되었습니다.');
        navigate(-1);
      },
    },
  );

  const dispatch = useDispatch();
  const { mutate: updateProductKeepWarning, isLoading: updateLoading } = useMutation(
    (countryVenderInfoUpdate: ProductKeepWarningFetch) =>
      certApi.updateProductKeepWarning(countryVenderInfoUpdate),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: '사용 보관상의 주의사항 선택',
          actionType: EGAActionType.MODIFY,
        });
        dispatch(certificateActions.fixDocument(productDocStatus!.productDocStatusId));
        message.success('보완 완료되었습니다.');
        navigate(-1);
      },
    },
  );

  const onSubmit = useCallback(
    (formValue: ProductKeepWarningBasic) => {
      const { productLine } = formValue;
      if (!updateMode) {
        addProductKeepWarning({
          productId: productId!,
          countryId: countryId!,
          productLine,
        });
      } else {
        const updatingObject = {
          ...(productLine !== productKeepWarning?.productLine && {
            productLine,
          }),
        };
        if (typeof updatingObject.productLine === 'undefined') {
          return message.warning(messages.NO_NEED_TO_UPDATE);
        } else {
          updateProductKeepWarning({
            productId: productId!,
            countryId: countryId!,
            productLine: updatingObject.productLine,
          });
        }
      }
    },
    [productKeepWarning, updateMode],
  );

  const [form] = Form.useForm<ProductKeepWarningBasic>();
  useEffect(() => {
    if (productKeepWarning) {
      const { productLine } = productKeepWarning;
      form.setFieldsValue({
        productLine,
      });
    }
  }, [productKeepWarning]);

  return useMemo(
    () => ({
      form,
      updateMode,
      getLoading,
      fetchLoading: addLoading || updateLoading,
      onSubmit,
    }),
    [form, updateMode, getLoading, addLoading, updateLoading, onSubmit],
  );
};

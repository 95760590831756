export enum DocumentCode {
  BL = 'BL', // Business license
  COB = 'COB', // Copy of bank, 통장 사본
  CRCSB = 'CRCSB', // Certificate of registration for cosmetics sales business, 화장품책임판매업 등록필증
  OBD = 'OBD', // Online business declaration, 통신판매업 신고증
  CMBC = 'CMBC', // Cosmetics manufacturing business certificate, 화장품 제조업 등록필증
  PS = 'PS', // Product standard, 제품 표준서
  INGS = 'INGS', // Ingredients, 전성분표
  BCOA = 'BCOA', // Bulk Certificate of Analysis, 반제품 성적서
  COA = 'COA', // Certificate of Analysis, 완제품 성적서
  KART = 'KART', // Korean artwork, 표시기재 파일 (국문 아트웍)
  AIMG = 'AIMG', // Actual image, 실사 이미지
  FR = 'FR', // Functional report, 기능성 보고서
  CFIRC = 'CFIRC', // Check the functional ingredients and report on content, 기능성 원료 확인 및 함량 성적서
  RHS = 'RHS', // Report on harmful substances, 유해물질 성적서
  RNIS = 'RNIS', // Report on non-detection of the issue substance, 이슈물질 불검출 성척서
  HATR = 'HATR', // Human application (clinical) test result report, 인체적용(임상)시험 결과보고서
  PFA = 'PFA', // Proof for advertisement, 광고 입증 자료
  PSR = 'PSR', // Packing space ratio report, 포장공간비율 성적서
  PLI = 'PLI', // PL insurance, 생산물백상책임(PL)보험,
  CRC = 'CRC', // Credit rating confirmation, 신용평가 확인서
  CPRS = 'CPRS', // Confirmation of payment account report from subcontractor, 협력업체 결제계좌 신고 확인서
  LPB = 'LPB', // Letter of promise to give up on bonds, 채권포기 확약서
  CSIG = 'CSIG', // Certificate of seal impression of the person who gave up, 채권포기자의 인감증명서
}

export interface Document {
  retailerApplyDocStatusAttachId: number;
  url: string;
  filename: string;
}

export interface ProductListPageState {
  page: number;
  searchValue: string;
}

export interface ProductListItem {
  productId: number;
  productNameEn: string;
  productNameKo: string;
  netWeight: number;
  netWeightUnit: string;
  manufacturerNameEn: string;
  manufacturerNameKo: string;
  productCategoryId: number | null;
  isFunctional: boolean | null;
}

export enum ProductListSearchType {
  ALL = 'all',
  PRODUCT_NAME_EN = 'productNameEn',
  PRODUCT_NAME_KO = 'productNameKo',
}

export interface ProductListPageState {
  page: number;
  searchValue: string;
}

export interface ProductListItem {
  productId: number;
  productNameEn: string;
  productNameKo: string;
  netWeight: number;
  netWeightUnit: string;
  manufacturerNameEn: string;
  manufacturerNameKo: string;
  productCategoryId: number | null;
  isFunctional: boolean | null;
}

export interface DocumentRelatedId {
  retailerApplyDocId: number;
  retailerId: number;
  retailerApplyDocSeqId: number;
}
export interface ProductDocument {
  retailerApplyDocSeqId: number;
  documentName: string;
  documentCode: string;
  displayOrder: number;
  forAllPlatform: boolean;
  common: boolean;
  optional: boolean;
  forFunctional: boolean;
  retailerApplyDocStatus: ProductDocumentStatus;
}

export enum ProductDocumentStatusCode {
  INP = 'INP',
  OPT = 'OPT',
  FIN = 'FIN',
}

export interface ProductDocumentStatus {
  retailerApplyDocStatusId: number;
  status: ProductDocumentStatusCode;
  userId: number;
  name: string | null;
  companyName: string | null;
  registerDt: string | null;
  retailerApplyFile: RetailerApplyFile[];
}

export interface RetailerApplyFile {
  retailerApplyDocStatusAttachId: number;
  url: string;
  filename: string;
}
export interface Retailer {
  retailerId: number;
  name: string;
}

import { Button, Col, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import MarketingFileUpload from 'components/file/MarketingFileUpload';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import { useMarketingUpload } from 'service/material/marketing';
import { useRawMaterial } from 'service/material/rawMaterial';
import { MarketingUploadType, UploadType } from 'types/material/marketing';

const MarketingUploadBlock = styled.div``;

const MarketingFileUploadWrapper = styled.div`
  .ant-col {
    flex: 0 0 100% !important;
    max-width: 100%;
  }
`;

const StyledRow = styled(Row)`
  margin: 30px 0 16px;
`;

const MarketingUpload = ({
  materialId,
  onChangeMarketingUploads,
}: {
  materialId?: number;
  onChangeMarketingUploads: React.Dispatch<React.SetStateAction<MarketingUploadType[]>>;
}) => {
  const [publicUploadFile, setPublicUploadFile] = useState<MarketingUploadType>(null);
  const [limitUploadFile, setLimitUploadFile] = useState<MarketingUploadType>(null);
  const {
    marketingUploadTypeData,
    getMarketingUploadDataLoading,
    addMarketingUploadLoading,
    updateMarketingUploadLoading,
    deleteMarketingLoading,
    onSubmit,
  } = useMarketingUpload(materialId);
  const { rawMaterial } = useRawMaterial(materialId);

  const handleDataChange = useCallback(
    (
      setData: React.Dispatch<React.SetStateAction<MarketingUploadType>>,
      data: UploadType | null,
    ) => {
      if (data) {
        setData({
          materialMarketingDataId: data.materialMarketingDataId,
          name: data.filename,
          url: data.attachUrl,
        });
      } else {
        setData(null);
      }
    },
    [],
  );

  useEffect(() => {
    handleDataChange(setPublicUploadFile, marketingUploadTypeData.public);
    handleDataChange(setLimitUploadFile, marketingUploadTypeData.limit);
  }, [marketingUploadTypeData]);

  useEffect(() => {
    onChangeMarketingUploads([publicUploadFile, limitUploadFile]);
  }, [publicUploadFile, limitUploadFile]);

  const handleSubmit = async () => {
    try {
      await onSubmit(publicUploadFile, limitUploadFile);
    } catch (error) {}
  };

  return (
    <MarketingUploadBlock>
      <Spin
        spinning={
          getMarketingUploadDataLoading ||
          addMarketingUploadLoading ||
          updateMarketingUploadLoading ||
          deleteMarketingLoading
        }
      >
        <Row gutter={58}>
          <Col span={12}>
            <StyledRow gutter={8} align="middle">
              <Col>
                <Icon name="page" color="PRIMARY50" size={24} />
              </Col>
              <Col>
                <Typography.Text type="TITLE_1">공개형</Typography.Text>
              </Col>
            </StyledRow>
            <MarketingFileUploadWrapper>
              <MarketingFileUpload
                disabled={rawMaterial?.isDiscontinued}
                accept="application/pdf,.zip"
                readOnly={false}
                file={publicUploadFile}
                onSetFile={setPublicUploadFile}
              />
            </MarketingFileUploadWrapper>
          </Col>
          <Col span={12}>
            <StyledRow gutter={8} align="middle">
              <Col>
                <Icon name="lock" color="PRIMARY50" size={24} />
              </Col>
              <Col>
                <Typography.Text type="TITLE_1">제한형</Typography.Text>
              </Col>
            </StyledRow>
            <MarketingFileUploadWrapper>
              <MarketingFileUpload
                disabled={rawMaterial?.isDiscontinued}
                accept="application/pdf,.zip"
                readOnly={false}
                file={limitUploadFile}
                onSetFile={setLimitUploadFile}
              />
            </MarketingFileUploadWrapper>
          </Col>
        </Row>
        {!rawMaterial?.isDiscontinued && (
          <FooterBox>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              {marketingUploadTypeData.public || marketingUploadTypeData.limit ? '수정' : '저장'}
            </Button>
          </FooterBox>
        )}
      </Spin>
    </MarketingUploadBlock>
  );
};

export default MarketingUpload;

import { Layout } from 'antd';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { deviceSize, size } from 'lib/styles';
import Flex from '../Flex/Flex';
import Logo from 'components/system/general/icon/Logo';
import Footer from 'components/system/layout/Footer';
import palette from 'lib/styles/palette';
import ScrollToTop from 'components/ScrollToTop';
import Navigator from 'components/Navigator';

const RootLayout = ({
  children,
  isAdmin = false,
  hasFooter = true,
  extra,
}: {
  children: React.ReactNode;
  isAdmin?: boolean;
  hasFooter?: boolean;
  extra?: React.ReactNode;
}) => {
  return (
    <LayoutBlock>
      <ScrollToTop />
      <HeaderBlock>{isAdmin ? <AdminHeader /> : <Navigator />}</HeaderBlock>
      <ContentBlock id="app-content-block">{children}</ContentBlock>
      {extra}
      {hasFooter && <Footer />}
    </LayoutBlock>
  );
};

const LayoutBlock = styled(Layout)`
  min-height: 100vh;
  background-color: #fff;
  padding: 0;
`;

const HeaderBlock = styled(Layout.Header)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 0 32px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: rgb(0, 0, 0, 0.06) 0px 0px 30px;
  z-index: 100;

  @media ${deviceSize.sm} {
    height: 56px;
    padding: 0 16px;
  }
`;

const ContentBlock = styled(Layout.Content)`
  width: 100%;
  margin: 80px auto 0;
  padding: 80px 0;
  display: flex;

  & > div {
    width: 100%;
    max-width: ${size.layout.contentMax};
    margin: 0 auto;
  }

  @media ${deviceSize.sm} {
    padding: 16px 0 40px;
  }
`;

const AdminHeader = () => {
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        height: isMobile ? 56 : 80,
        maxWidth: 1088,
        margin: '0 auto',
      }}
    >
      <Logo height={isMobile ? 24 : 32} />
      <Flex
        align="center"
        justify="center"
        style={{
          fontSize: 14,
          fontWeight: 500,
          border: `2px solid ${palette.PRIMARY50}`,
          borderRadius: '74px',
          width: 136,
          height: 40,
          userSelect: 'none',
        }}
      >
        관리자로 로그인 중
      </Flex>
    </Flex>
  );
};

export default RootLayout;

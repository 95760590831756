import styled from 'styled-components';
import { Checkbox, Card, Button, Form, Collapse, Spin } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import FooterBox from 'components/FooterBox';
import path from 'lib/path';
import { deviceSize } from 'lib/styles';
import Typography from 'components/system/general/Typography';
import palette from 'lib/styles/palette';
import { useAllTerms } from 'service/terms';
import { TermsType } from 'types/terms';
import TermsEditorViewer from 'components/terms/TermsEditorViewer';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';

const RegisterTermsBlock = styled.div`
  flex: 0 1 992px;

  @media ${deviceSize.sm} {
    padding: 20px 0;
  }

  .ant-form-item-control .ant-form-item-control-input {
    min-height: 24px;
  }

  .ant-collapse {
    border: unset;
    background-color: white;
  }

  .ant-collapse-header {
    background-color: ${palette.SLATE_GRAY10};
    padding: 16px 0px 0px 16px !important;
    border-radius: 8px;

    .ant-collapse-arrow {
      top: 0 !important;
      right: 0 !important;
      padding: 0 !important;
    }
  }

  .ant-collapse-content {
    &.ant-collapse-content-active {
      border-top: none;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: unset;
  }

  .ant-collapse-item {
    margin-bottom: 16px;
  }
`;

const CheckAllBlock = styled.div`
  display: flex;
  padding: 16px;
  background-color: ${palette.PRIMARY20};
  border: 1px solid #d7e7fe;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const TermsCard = styled(Card)`
  margin: 8px 0 8px;

  .ant-card-body {
    height: 200px;
    padding: 8px 48px 0;
    overflow: auto;

    @media ${deviceSize.sm} {
      padding: 12px 16px;
    }
  }
`;

const RegisterTerms = ({
  form,
  isTermsChecked,
  isPrivateChecked,
  isThirdPartyChecked,
  isPolicyChecked,
  isLogoChecked,
  onCheckAll,
  onCheckTerm,
  onCheckPrivate,
  onCheckThirdParty,
  onCheckPolicy,
  onCheckLogo,
  onClickNext,
  onFail,
}: any) => {
  const navigate = useNavigate();
  const checkedLength = useMemo(
    () =>
      [
        isTermsChecked,
        isPrivateChecked,
        isThirdPartyChecked,
        isPolicyChecked,
        isLogoChecked,
      ].filter((el) => el).length,
    [isTermsChecked, isPrivateChecked, isThirdPartyChecked, isPolicyChecked, isLogoChecked],
  );

  const { data: allTerms, isLoading } = useAllTerms();

  const serviceTerms =
    allTerms?.find((item) => item.termType === TermsType.SERVICE_BRAND)?.content || '';

  const privacyServiceTerms =
    allTerms?.find((item) => item.termType === TermsType.PRIVACY_SERVICE_BRAND)?.content || '';

  const privacyThirdPartyTerms =
    allTerms?.find((item) => item.termType === TermsType.PRIVACY_THIRD_PARTY_BRAND)?.content || '';

  const privacyProcessTerms =
    allTerms?.find((item) => item.termType === TermsType.PRIVACY_PROCESS_BRAND)?.content || '';

  const cibiServiceTerms =
    allTerms?.find((item) => item.termType === TermsType.CI_BI_SERVICE_BRAND)?.content || '';

  return (
    <RegisterTermsBlock>
      <Form
        form={form}
        colon={false}
        onFinish={onClickNext}
        onFinishFailed={onFail}
        style={{ marginTop: 32 }}
      >
        <Spin spinning={isLoading}>
          <CheckAllBlock onClick={() => onCheckAll(checkedLength !== 5)}>
            <Checkbox
              indeterminate={checkedLength > 0 && checkedLength < 5}
              checked={checkedLength === 5}
            >
              <Typography.Text
                style={{ fontSize: 18 }}
                inline
                color="SLATE_GRAY70"
                medium
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                모든 약관에 동의
              </Typography.Text>
            </Checkbox>
          </CheckAllBlock>
          <Collapse
            defaultActiveKey={['terms', 'private', 'thirdParty', 'policy', 'logo']}
            expandIconPosition="end"
            expandIcon={({ isActive }) =>
              isActive ? (
                <Flex>
                  <Icon name="up" size={20} style={{ position: 'absolute', top: 18, right: 30 }} />
                </Flex>
              ) : (
                <Flex>
                  <Icon
                    name="down"
                    size={20}
                    style={{ position: 'absolute', top: 18, right: 30 }}
                  />
                </Flex>
              )
            }
            items={[
              {
                key: 'terms',
                label: (
                  <Form.Item
                    name="terms"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject('필수 체크항목입니다.'),
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(e) => {
                        onCheckTerm(e.target.checked);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Typography.Text
                        color="SLATE_GRAY70"
                        inline
                        medium
                        onClick={(e) => e.stopPropagation()}
                      >
                        이용 약관 동의
                      </Typography.Text>
                      <Typography.Text
                        color="PRIMARY50"
                        inline
                        gutter={{ left: 4 }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        (필수)
                      </Typography.Text>
                    </Checkbox>
                  </Form.Item>
                ),
                children: (
                  <TermsCard size="small">
                    <TermsEditorViewer
                      content={serviceTerms}
                      termsType={TermsType.SERVICE_BRAND}
                    ></TermsEditorViewer>
                  </TermsCard>
                ),
              },
              {
                key: 'private',
                label: (
                  <Form.Item
                    name="private"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject('필수 체크항목입니다.'),
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(e) => onCheckPrivate(e.target.checked)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Typography.Text
                        color="SLATE_GRAY70"
                        inline
                        medium
                        onClick={(e) => e.stopPropagation()}
                      >
                        개인정보 수집·이용 동의
                      </Typography.Text>
                      <Typography.Text
                        color="PRIMARY50"
                        inline
                        gutter={{ left: 4 }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        (필수)
                      </Typography.Text>
                    </Checkbox>
                  </Form.Item>
                ),
                children: (
                  <TermsCard size="small">
                    <TermsEditorViewer content={privacyServiceTerms}></TermsEditorViewer>
                  </TermsCard>
                ),
              },
              {
                key: 'thirdParty',
                label: (
                  <Form.Item
                    name="thirdParty"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject('필수 체크항목입니다.'),
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(e) => onCheckThirdParty(e.target.checked)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Typography.Text
                        color="SLATE_GRAY70"
                        inline
                        medium
                        onClick={(e) => e.stopPropagation()}
                      >
                        개인정보 제3자 제공 동의
                      </Typography.Text>
                      <Typography.Text
                        color="PRIMARY50"
                        inline
                        gutter={{ left: 4 }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        (필수)
                      </Typography.Text>
                    </Checkbox>
                  </Form.Item>
                ),
                children: (
                  <TermsCard size="small">
                    <TermsEditorViewer content={privacyThirdPartyTerms}></TermsEditorViewer>
                  </TermsCard>
                ),
              },
              {
                key: 'policy',
                label: (
                  <Form.Item
                    name="policy"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject('필수 체크항목입니다.'),
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(e) => {
                        onCheckPolicy(e.target.checked);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Typography.Text
                        color="SLATE_GRAY70"
                        inline
                        medium
                        onClick={(e) => e.stopPropagation()}
                      >
                        개인정보 처리방침 동의
                      </Typography.Text>
                      <Typography.Text
                        color="PRIMARY50"
                        inline
                        gutter={{ left: 4 }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        (필수)
                      </Typography.Text>
                    </Checkbox>
                  </Form.Item>
                ),
                children: (
                  <TermsCard size="small">
                    <TermsEditorViewer content={privacyProcessTerms}></TermsEditorViewer>
                  </TermsCard>
                ),
              },
              {
                key: 'logo',
                label: (
                  <Form.Item name="logo" valuePropName="checked">
                    <Checkbox
                      onChange={(e) => onCheckLogo(e.target.checked)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Typography.Text
                        color="SLATE_GRAY70"
                        inline
                        medium
                        onClick={(e) => e.stopPropagation()}
                      >
                        로고 사용 및 게시 동의
                      </Typography.Text>
                      <Typography.Text
                        color="SLATE_GRAY70"
                        inline
                        gutter={{ left: 4 }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        (선택)
                      </Typography.Text>
                    </Checkbox>
                  </Form.Item>
                ),
                children: (
                  <TermsCard size="small">
                    <TermsEditorViewer
                      content={cibiServiceTerms}
                      termsType={TermsType.CI_BI_SERVICE_BRAND}
                    ></TermsEditorViewer>
                  </TermsCard>
                ),
              },
            ]}
          />
          <FooterBox>
            <Button onClick={() => navigate(path.login)}>취소</Button>
            <Button type="primary" htmlType="submit">
              다음
            </Button>
          </FooterBox>
        </Spin>
      </Form>
    </RegisterTermsBlock>
  );
};

export default RegisterTerms;

import React from 'react';
import styled, { CSSProperties, css } from 'styled-components';

import palette from 'lib/styles/palette';

interface ChipProps {
  children: React.ReactNode;
  color: keyof typeof palette;
  bordered?: boolean;
  size?: 'normal' | 'small';
  style?: CSSProperties;
  type?: 'default' | 'line';
  onClick?: VoidFunction;
}

const StyledChip = styled.div<ChipProps>`
  display: flex;
  align-items: center;
  line-height: 0;
  padding: 2px 8px;
  width: max-content;

  ${({ type, color }) =>
    type === 'default'
      ? css`
          font-size: 12px;
          color: ${palette.SLATE_GRAY70};
          font-weight: 600;
          border-radius: 3px;
          background: ${palette[color]};
          height: 20px;
        `
      : css`
          font-size: 10px;
          color: ${palette[color]};
          padding: 1px 7px;
          border: 1px solid ${palette[color]};
          background: ${palette.ETC_WHITE};
          width: max-content;
          margin: 0 auto;
          font-weight: 500;
          border-radius: 4px;
          height: 18px;
        `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

StyledChip.defaultProps = {
  bordered: false,
  size: 'normal',
  type: 'default',
};

const Chip = ({ children, onClick, ...props }: ChipProps) => {
  return (
    <StyledChip {...props} onClick={onClick}>
      {children}
    </StyledChip>
  );
};

export default Chip;

import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

import { Flex } from 'components/ui';
import { s3AssetDomain } from 'lib/consts';
import { Body1, H1, H2, H3 } from './introStyles';
import { breakpoint, deviceSize } from 'lib/styles';
import useIntersectionObserver from 'hook/useIntersectionObserver';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  /* HINT : calc (이미지 높이 ÷ 이미지 가로 × 100 %) */
  padding-top: calc(988 / 1920 * 100%);
  background-image: url(${s3AssetDomain}/intro_cert_count_circle.png);
  background-repeat: no-repeat;
  background-size: cover;

  @media ${deviceSize.sm} {
    padding-top: calc(665 / 360 * 100%);
    background-image: url(${s3AssetDomain}/intro_cert_count_circle_m.png);
  }
`;

const InnerContainer = styled.div`
  padding: 150px 24px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 1200px) {
    padding: 70px 24px;
  }

  @media ${deviceSize.sm} {
    padding: 70px 24px;
  }
`;

const TitleWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
`;

const Title = styled(H1)`
  @media (max-width: ${breakpoint.lg}px) {
    font-size: 36px;
    line-height: 48px;
  }

  @media ${deviceSize.md} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceSize.sm} {
    max-width: 200px;
    font-size: 28px;
    line-height: 38px;
  }
`;

const SubTitle = styled(H3)`
  margin-top: 30px;

  @media ${deviceSize.md} {
    margin-top: 20px;
    font-size: 20px;
    line-height: 28px;
  }

  @media ${deviceSize.sm} {
    margin-top: 20px;
  }
`;

const CountCircle = styled(Flex)`
  position: absolute;

  width: 34.066%;
  height: 66.2%;
  top: 30.26%;
  right: 6%;

  > svg {
    position: absolute;
    stroke: orange;
    stroke-width: 1px;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    transform: rotate(-62deg);
    animation: circle-ani 4s linear infinite;
  }

  @media ${deviceSize.sm} {
    width: 73.61%;
    height: 39.85%;
    top: 50%;
    right: 12%;
    > svg {
      transform: rotate(-58deg);
    }
  }

  @keyframes circle-ani {
    0% {
      stroke-dashoffset: 2000;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -2000;
    }
  }
`;

const AccuracyText = styled(H2)`
  @media (max-width: ${breakpoint.lg}px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media ${deviceSize.md} {
    font-size: 12px;
    line-height: 15px;
  }

  @media ${deviceSize.sm} {
    font-size: 12px;
    line-height: 15px;
  }
`;

const PercentNumber = styled.div`
  color: #fff;
  font-size: 74px;
  font-weight: 700;
  line-height: 92.65px;

  &::after {
    content: '%';
    font-size: 40px;
    line-height: 0;
  }

  @media (max-width: ${breakpoint.lg}px) {
    font-size: 48px;
    line-height: 64px;

    &::after {
      font-size: 24px;
    }
  }

  @media ${deviceSize.md} {
    font-size: 30px;
    line-height: 38px;

    &::after {
      font-size: 17px;
      line-height: 21px;
    }
  }

  @media ${deviceSize.sm} {
    font-size: 30px;
    line-height: 38px;

    &::after {
      font-size: 17px;
      line-height: 21px;
    }
  }
`;

const DataWrapper = styled.div<{
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  mobileTop?: string;
  mobileRight?: string;
  mobileBottom?: string;
  mobileLeft?: string;
  align?: 'left' | 'center' | 'right';
}>`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};

  text-align: ${({ align }) => align || 'left'};

  @media ${deviceSize.sm} {
    top: ${({ mobileTop }) => mobileTop};
    right: ${({ mobileRight }) => mobileRight};
    bottom: ${({ mobileBottom }) => mobileBottom};
    left: ${({ mobileLeft }) => mobileLeft};
  }
`;

const DataTitle = styled(Body1)`
  @media (min-width: ${breakpoint.sm}px) and (max-width: ${breakpoint.lg}px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceSize.sm} {
    font-size: 10px;
    line-height: 12px;
  }
`;

const DataAmount = styled(H2)`
  font-size: 40px;
  line-height: 50px;
  margin-top: 10px;

  @media (max-width: ${breakpoint.lg}px) {
    font-size: 28px;
    line-height: 40px;

    &::after {
      font-size: 18px;
    }
  }

  @media (max-width: 1200px) {
    font-size: 22px;
    line-height: 28px;
    margin-top: 5px;

    &::after {
      font-size: 11px;
    }
  }

  @media ${deviceSize.sm} {
    font-size: 18px;
    line-height: 22px;
    margin-top: 3px;

    &::after {
      font-size: 11px;
    }
  }
`;

const UnitText = styled.span`
  color: #fff;
  font-size: 24px;

  @media (max-width: ${breakpoint.lg}px) {
    font-size: 18px;
  }

  @media (max-width: 1200px) {
    font-size: 11px;
  }
`;

const dataInfos: {
  title: string;
  amount: string;
  unit: string;
  align?: 'left' | 'center' | 'right';
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  mobileTop?: string;
  mobileRight?: string;
  mobileBottom?: string;
  mobileLeft?: string;
}[] = [
  {
    title: '고객사 수',
    amount: '301',
    unit: '개',
    top: '-15%',
    left: '64%',
  },
  {
    title: '재 의뢰율',
    unit: '%',
    amount: '72',
    bottom: '22%',
    right: '8%',
    mobileBottom: '24%',
    mobileRight: '11%',
  },
  {
    title: '누적 임상시험 수',
    unit: '건',
    amount: '105',
    align: 'center',
    bottom: '22%',
    left: '-24%',
    mobileBottom: '-8%',
    mobileLeft: '-8%',
  },
  {
    title: '누적 인허가 수',
    unit: '개',
    amount: '2257',
    top: '-5%',
    left: '-15%',
    mobileTop: '-4%',
    mobileLeft: '-7%',
  },
];

const SectionC = () => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const counterRef = useRef<{
    [key: string]: { start: VoidFunction | undefined };
  }>({
    '고객사 수': { start: undefined },
    '재 의뢰율': { start: undefined },
    '누적 임상시험 수': { start: undefined },
    '누적 인허가 수': { start: undefined },
  });

  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0.25,
  });

  useEffect(() => {
    if (!isIntersecting || isAnimated) return;
    setIsAnimated(true);

    const counterData = counterRef.current;
    const keys = Object.keys(counterData);

    for (const key of keys) {
      const { start } = counterData[key];
      if (!start) continue;

      start();
    }
  }, [isIntersecting]);

  return (
    <Container>
      <InnerContainer ref={target}>
        <TitleWrapper>
          <Title color="WHITE">AI 화장품 인허가 NO.1 플랫폼</Title>
          <SubTitle color="TYPO5">인허가 품질은 경험치와 비례합니다.</SubTitle>
        </TitleWrapper>
        <CountCircle align="center" justify="center" dir="column">
          <svg
            width={'100%'}
            height={'100%'}
            viewBox="0 0 592 592"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.4"
              cx="296"
              cy="296"
              r="295.548"
              stroke="white"
              strokeWidth="0.903817"
            />
          </svg>
          <AccuracyText style={{ marginBottom: 0 }} color="WHITE">
            데이터 정확도
          </AccuracyText>
          <PercentNumber>99</PercentNumber>
          {dataInfos.map(
            ({
              title,
              amount,
              unit,
              align,
              top,
              left,
              right,
              bottom,
              mobileBottom,
              mobileLeft,
              mobileRight,
              mobileTop,
            }) => (
              <CountUp
                key={title}
                start={0}
                end={Number(amount)}
                duration={3}
                separator=","
                delay={isIntersecting ? 0 : null}
              >
                {({ countUpRef, start }) => {
                  if (!counterRef.current[title].start) {
                    counterRef.current[title].start = start;
                  }

                  return (
                    <DataWrapper
                      align={align}
                      top={top}
                      right={right}
                      bottom={bottom}
                      left={left}
                      mobileTop={mobileTop}
                      mobileRight={mobileRight}
                      mobileBottom={mobileBottom}
                      mobileLeft={mobileLeft}
                    >
                      <DataTitle color="WHITE" style={{ opacity: 0.7 }}>
                        {title}
                      </DataTitle>
                      <DataAmount inline ref={countUpRef as any} color="WHITE" />
                      <UnitText>{` ${unit}`}</UnitText>
                    </DataWrapper>
                  );
                }}
              </CountUp>
            ),
          )}
        </CountCircle>
      </InnerContainer>
    </Container>
  );
};

export default SectionC;

import { useMemo } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { s3AssetDomain } from 'lib/consts';
import { deviceSize } from 'lib/styles';
import { H1, LogoText } from './introStyles';

const clientsImageUrls = [
  '/clients/intro_main_client_1.png',
  '/clients/intro_main_client_2.png',
  '/clients/intro_main_client_3.png',
  '/clients/intro_main_client_4.png',
  '/clients/intro_main_client_5.png',
  '/clients/intro_main_client_6.png',
  '/clients/intro_main_client_7.png',
  '/clients/intro_main_client_8.png',
  '/clients/intro_main_client_9.png',
  '/clients/intro_main_client_10.png',
  '/clients/intro_main_client_11.png',
  '/clients/intro_main_client_12.png',
  '/clients/intro_main_client_13.png',
  '/clients/intro_main_client_14.png',
  '/clients/intro_main_client_15.png',
  '/clients/intro_main_client_16.png',
  '/clients/intro_main_client_17.png',
  '/clients/intro_main_client_18.png',
  '/clients/intro_main_client_19.png',
  '/clients/intro_main_client_20.png',
  '/clients/intro_main_client_21.png',
  '/clients/intro_main_client_22.png',
  '/clients/intro_main_client_23.png',
  '/clients/intro_main_client_24.png',
  '/clients/intro_main_client_25.png',
  '/clients/intro_main_client_26.png',
  '/clients/intro_main_client_27.png',
  '/clients/intro_main_client_28.png',
];

const Container = styled.div`
  padding: 170px 24px;
  position: relative;
  overflow: hidden;

  @media ${deviceSize.sm} {
    padding: 90px 24px;
  }

  > *:first-child {
    text-align: center;
    margin-bottom: 80px;

    @media ${deviceSize.sm} {
      margin-bottom: 50px;
    }
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  overflow-x: auto;

  @media ${deviceSize.sm} {
    width: fit-content;
    animation: linear infinite;
    animation-name: run;
    animation-duration: 20s;

    @keyframes run {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
  }
`;

const LogoList = styled.div`
  display: grid;
  max-width: 1920px;
  margin: auto;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 4vw;
  row-gap: 3vw;

  @media ${deviceSize.sm} {
    max-width: 1020px;
    column-gap: 30px;
    row-gap: 20px;
    margin-right: 4vw;
  }

  > img {
    width: 100%;

    @media ${deviceSize.sm} {
      width: 120px;
      height: 50px;
    }
  }
`;

const SectionF = () => {
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  const imageTags = useMemo(() => {
    const components = [];

    for (const imageUrl of clientsImageUrls) {
      components.push(<img key={imageUrl} src={`${s3AssetDomain}${imageUrl}`} alt="client logo" />);
    }

    return components;
  }, []);

  return (
    <Container>
      <H1>
        <LogoText>certicos</LogoText>와 함께하는 고객사{' '}
        <H1 inline color="C_MAIN">
          600+
        </H1>
      </H1>
      <LogoWrapper>
        {isMobile && <LogoList>{imageTags}</LogoList>}
        <LogoList>{imageTags}</LogoList>
      </LogoWrapper>
    </Container>
  );
};

export default SectionF;

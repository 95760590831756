import NMPADIF from 'components/material/nmpa/NMPADIF';
import { useParams } from 'react-router-dom';

import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';

const NMPADIFPage = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);

  return (
    <NMPAPageTemplate
      documentName="위험 정보 및 통제 지표"
      title="원료 신고 정보"
      notices={[
        '선택 입력 사항이며 원료 등록에 있어 추가 설명이 필요한 정보를 입력해 주세요.',
        '지표별 관리 항목이 상이합니다. 선택에 유의하여 입력해 주세요.',
        '입력된 내용은 취득 진행에 적합한 내용으로 수정될 수 있습니다.',
      ]}
    >
      <NMPADIF materialId={materialId} />
    </NMPAPageTemplate>
  );
};

export default NMPADIFPage;

import { apiUrls, useBasicQuery } from './core';
import { UseGuide, UseGuideItem } from 'types/service';

export const useGuides = (page: number) => {
  // 서비스 이용안내 목록 조회 (usg001)
  const { data: useGuideData, isLoading } = useBasicQuery<UseGuide>({
    apiUrl: apiUrls.serviceUseGuide,
    qs: {
      page,
    },
  });

  return {
    useGuideData,
    isLoading,
  };
};

export const useGuideDetail = (useGuideId: number) => {
  // 서비스 이용안내 상세 조회 (usg001)
  const { data: guideDetail, isLoading } = useBasicQuery<UseGuideItem>({
    apiUrl: apiUrls.serviceUseGuideDetail,
    urlParams: { useGuideId },
  });

  return {
    guideDetail,
    isLoading,
  };
};

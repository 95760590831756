import NMPABAT from 'components/material/nmpa/NMPABAT';
import { useParams } from 'react-router-dom';

import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';

const NMPABATPage = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);

  return (
    <NMPAPageTemplate
      documentName="기본 특성"
      title="원료 신고 정보"
      notices={[
        '필수 입력 사항이며 원료 등록에 있어 설명이 필요한 정보를 입력해 주세요.',
        '지표별 관리 항목이 상이합니다. 선택에 유의하여 입력해 주세요.',
        '입력된 내용은 취득 진행에 적합한 내용으로 수정될 수 있습니다.',
      ]}
    >
      <NMPABAT nmpaBasicInfoId={materialId} />
    </NMPAPageTemplate>
  );
};

export default NMPABATPage;

import { Form, message } from 'antd';
import { useEffect, useMemo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  manuUpdateProduct,
  manuSetNextPathname,
  manuInitializeDocuments,
  setSkipMode,
} from 'modules/product';
import { messages } from 'lib/consts';
import { ProductBasicForm } from 'types/manufacturer/product';
import { getUpdatingObject } from 'lib/form';
import { netWeightUnitOptions } from 'lib/selectOption';
import { ManufactureCompanyFactory } from 'types/company';
import { useUser } from 'service/auth';
import { useAddManuProduct, useProduct } from './product';

export const useProductBasic = (productId?: number) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<ProductBasicForm>();
  const updateMode = typeof productId !== 'undefined';
  const { factories, fetchProductLoading, updateProductLoading } = useSelector(
    ({ company, product, loading }: any) => ({
      factories: company.factories as ManufactureCompanyFactory[],
      fetchProductLoading: loading['product/MANU_ADD_PRODUCT'],
      updateProductLoading: loading['product/MANU_UPDATE_PRODUCT'],
    }),
    shallowEqual,
  );
  const { product, getProductLoading } = useProduct(productId);
  const { manufacturerId } = useUser();
  const { addManuProduct } = useAddManuProduct();

  const submitProduct = useCallback(
    (formValues: ProductBasicForm) => {
      let { netWeightUnit, netWeightUnitDirect, ...restFormValues } = formValues;
      if (netWeightUnit === 'others') netWeightUnit = netWeightUnitDirect;
      if (!updateMode) {
        addManuProduct({
          manufacturerId,
          netWeightUnit,
          ...restFormValues,
        });
      } else if (product) {
        const {
          isForEurope,
          isForChina,
          isForUs,
          labNo,
          isUsedProductSample,
          productSampleId = null,
          addressEn,
          ...rest
        } = restFormValues;
        const updatingObject = getUpdatingObject(
          { productSampleId, isUsedProductSample, ...rest },
          product,
        );
        const { productCountries } = product;
        const updateFormValues = {
          ...updatingObject,
          ...(netWeightUnit !== product.netWeightUnit && {
            netWeightUnit,
          }),
          ...(isForEurope !== productCountries.some(({ countryId }) => countryId === 1) && {
            isForEurope,
          }),
          ...(isForChina !== productCountries.some(({ countryId }) => countryId === 3) && {
            isForChina,
          }),
          ...(isForUs !== productCountries.some(({ country }) => country.countryCode === 'US') && {
            isForUs,
          }),
          ...(labNo !== product.productLabNos[0].labNo && {
            labNo,
          }),
        };
        if (Object.keys(updateFormValues).length === 0) {
          return message.warning(messages.NO_NEED_TO_UPDATE);
        }
        dispatch(
          manuUpdateProduct({
            productId,
            ...updateFormValues,
          }),
        );
      }
    },
    [updateMode, product],
  );

  useEffect(() => {
    if (!updateMode) {
      dispatch(manuSetNextPathname('basic'));
    } else if (product) {
      const checkNetWeightIsDirectedInput = () =>
        !netWeightUnitOptions.find(({ value }) => value === netWeightUnit);
      let {
        isUsedProductSample,
        productSampleId,
        brandCompanyNameKo,
        manufacturerItemNameKo,
        manufacturerItemNo,
        companyFactoryId,
        productLabNos,
        productCountries,
        netWeight,
        netWeightUnit,
        manufacturerZipCode,
        productDetail,
      } = product;
      let netWeightUnitDirect = '';
      if (checkNetWeightIsDirectedInput()) {
        netWeightUnitDirect = netWeightUnit;
        netWeightUnit = 'others';
      }
      form.setFieldsValue({
        isUsedProductSample,
        productSampleId,
        brandCompanyNameKo,
        manufacturerItemNameKo,
        manufacturerZipCode: manufacturerZipCode || productDetail.manufacturerZipCode,
        manufacturerItemNo,
        companyFactoryId,
        addressEn: factories.find((factory) => factory.companyFactoryId === companyFactoryId)!
          .addressEn,
        labNo: productLabNos[0].labNo,
        isForEurope: productCountries.some(({ countryId }) => countryId === 1),
        isForChina: productCountries.some(({ countryId }) => countryId === 3),
        isForUs: productCountries.some(({ country }) => country.countryCode === 'US'),
        netWeight,
        netWeightUnit,
        netWeightUnitDirect,
      });
    }
  }, [product]);

  useEffect(() => {
    dispatch(manuInitializeDocuments());
    if (!updateMode) {
      form.resetFields();
      dispatch(setSkipMode(true));
    }
  }, [updateMode]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        brandCompanyNameKo: 'Dowith',
        manufacturerItemNameKo: 'Serum',
        manufacturerItemNo: 'S001',
        labNo: 'LAB001',
        netWeight: 12,
        netWeightUnit: 'g',
        manufacturerZipCode: '12345678',
      });
    }
  }, [updateMode]);

  return useMemo(
    () => ({
      form,
      product,
      getProductLoading,
      factories,
      fetchLoading: fetchProductLoading || updateProductLoading,
      submitProduct,
    }),
    [
      factories,
      product,
      getProductLoading,
      fetchProductLoading,
      updateProductLoading,
      submitProduct,
    ],
  );
};

import styled from 'styled-components';
import { Button, Input, Modal } from 'antd';

import { Typography } from 'components/system';
import FooterBox from 'components/FooterBox';
import { CloseIcon } from 'components/system/general/icon';

const StyledTextArea = styled(Input.TextArea)`
  margin-top: 16px;
  cursor: default;

  &:hover,
  &:focus {
    border-color: #d9d9d9;
  }
`;

export interface CorrectRequestMessageModalProps {
  correctMessage: string;
  onClose?: () => void;
}

const CorrectRequestMessageModal = ({
  correctMessage,
  onClose,
}: CorrectRequestMessageModalProps) => (
  <Modal visible width={800} footer={null} onCancel={onClose} closeIcon={<CloseIcon />}>
    <Typography.Text type="TITLE_1">보완 문구 보기</Typography.Text>
    <StyledTextArea rows={5} value={correctMessage} readOnly />
    <FooterBox style={{ paddingTop: 24 }}>
      <Button type="primary" onClick={onClose}>
        확인
      </Button>
    </FooterBox>
  </Modal>
);

export default CorrectRequestMessageModal;

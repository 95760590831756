import ManagerListContainer from 'containers/auth/ManagerListContainer';
import { useCompanyType } from 'service/company';
import PageTemplate from 'templates/PageTemplate';
import { CompanyType } from 'types/company';

const ManagePage = () => {
  const companyType = useCompanyType();
  const notices =
    companyType === CompanyType.BRAND
      ? [
          '제품정보 입력을 위해 제조사 계정을 생성하여, 해당 제조사 담당자에게 부여해 주세요.',
          '제조사 계정으로는 해당 계정의 제조사가 제조한 제품의 제품정보만 등록 및 확인할 수 있으며, 인증신청/인증진행 메뉴 접근이 제한됩니다.',
        ]
      : companyType === CompanyType.MANUFACTURE
      ? ['제품 정보 입력을 위해 하위 담당자 계정을 추가해 주세요.']
      : ['원료 정보 입력을 위해 하위 담당자 계정을 추가해 주세요.'];
  return (
    <PageTemplate notices={notices}>
      <ManagerListContainer />
    </PageTemplate>
  );
};

export default ManagePage;

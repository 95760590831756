import { useLocation, useParams } from 'react-router-dom';

import NMPAEstimate from 'components/material/nmpa/NMPAEstimate';
import PageTemplate from 'templates/PageTemplate';

const NMPAEstimatePage = () => {
  const location = useLocation();
  const params = useParams<{ materialNmpaEstimateId: string }>();
  const materialNmpaEstimateId = Number(params.materialNmpaEstimateId);
  const { estimateFileUrl } = (location.state as { estimateFileUrl?: string }) || {};

  return (
    <PageTemplate
      back={false}
      title="견적 확인"
      description="아래 견적서를 확인 후 계약을 진행해 보세요."
    >
      <NMPAEstimate
        estimateFileUrl={estimateFileUrl}
        materialNmpaEstimateId={materialNmpaEstimateId}
      />
    </PageTemplate>
  );
};

export default NMPAEstimatePage;

import { Input, InputProps, InputRef } from 'antd';
import Icon from 'components/ui/Icon/Icon';
import { MutableRefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';

const SearchInputWrap = styled.div`
  position: relative;

  .ant-input {
    padding-right: 40px;
  }
`;

interface SearchInputProps extends InputProps {
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  defaultValue?: string;
  onSearch: (keyword: string) => void;
  inputRef?: MutableRefObject<InputRef | null>;
}

const SearchInput = ({
  style,
  inputStyle,
  disabled,
  defaultValue = '',
  onSearch,
  inputRef: externalInputRef,
  ...restProps
}: SearchInputProps) => {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (!externalInputRef) return;

    externalInputRef.current = inputRef.current;
  }, [externalInputRef]);

  return (
    <SearchInputWrap style={style}>
      <Input
        {...restProps}
        defaultValue={defaultValue}
        ref={inputRef}
        disabled={disabled}
        style={inputStyle}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch(inputRef.current?.input?.value || '');
          }
        }}
      />
      <Icon
        name="search"
        size={20}
        color={disabled ? 'GRAY50' : 'GRAY70'}
        style={{
          position: 'absolute',
          top: 12,
          right: 16,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        onClick={() => {
          if (!disabled) {
            onSearch(inputRef.current?.input?.value || '');
          }
        }}
      />
    </SearchInputWrap>
  );
};

export default SearchInput;

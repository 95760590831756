import Typography from 'components/system/general/Typography';
import { s3AssetDomain, totalNumOfServiceCountries } from 'lib/consts';
import { deviceSize } from 'lib/styles';
import palette from 'lib/styles/palette';
import styled from 'styled-components';

const WorldMapBlock = styled.div`
  flex: 1;
  position: relative;
  height: 306px;

  @media ${deviceSize.sm} {
    width: 100%;
    min-width: max-content;
  }

  .description {
    position: absolute;
    left: calc(50% - 12px);
    top: 84%;
    transform: translateX(-50%);
    padding: 4px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
const WorldMapImg = styled.img`
  position: absolute;
  width: 480px;
  height: auto;
  z-index: 0;
`;
const PieBlock = styled.div`
  position: absolute;
  z-index: 2;
  left: calc(50% - 12px);
  top: 50%;
  width: 180px;
  height: 180px;
  background-color: ${palette.GRAY40};
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 15px 0 rgba(107, 159, 255, 0.5);
`;

const PieContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  width: 120px;
  height: 125px;
  border-radius: 50%;

  .percent {
    display: flex;
    align-items: center;
    font-size: 36px;
    line-height: 1;
    letter-spacing: -1px;
    font-weight: 500;

    .unit {
      padding-left: 4px;
      font-size: 18px;
      line-height: 1;
      letter-spacing: -0.82px;
      font-weight: 400;
    }
  }

  .count {
    font-size: 18px;
    letter-spacing: -1px;
    color: #a6a6a6;
  }
`;
const PieSegmentLeft = styled.div<{ degree: number }>`
  position: absolute;
  width: 180px;
  height: 180px;
  transform: translate(0, -50%) rotate(90deg);
  transform-origin: 0 50%;
  overflow: hidden;

  &::before {
    background-color: ${palette.PRIMARY50};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(0, -100%) ${(props) => `rotate(${props.degree}deg)`};
    transform-origin: 50% 100%;
    transition: 0.3s transform linear;
    transition-delay: 0.3s;
  }
`;
const PieSegmentRight = styled.div<{ degree: number }>`
  position: absolute;
  width: 180px;
  height: 180px;
  transform: translate(0, -50%) rotate(90deg);
  transform-origin: 50% 100%;
  overflow: hidden;

  &::before {
    background-color: ${palette.PRIMARY50};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(0, 100%) ${(props) => `rotate(${props.degree}deg)`};
    transform-origin: 50% 0;
    transition: 0.3s transform linear;
  }
`;

const WorldMap = ({ count }: { count: number }) => {
  const percent = Math.floor((count / totalNumOfServiceCountries) * 100);
  const degree = (percent / 100) * 360;

  return (
    <WorldMapBlock>
      <Typography.Text
        type="TITLE_1"
        gutter={{ bottom: 24 }}
        style={{ position: 'relative', zIndex: 1 }}
      >
        해외 진출 현황
      </Typography.Text>
      <PieBlock>
        <PieSegmentLeft degree={degree > 180 ? degree - 180 : 0} />
        <PieSegmentRight degree={degree > 180 ? 180 : degree} />
        <PieContent>
          <div className="percent">
            {percent}
            <span className="unit">%</span>
          </div>
          <div className="count">
            {count} / {totalNumOfServiceCountries}
          </div>
        </PieContent>
      </PieBlock>
      <Typography.Text type="BODY_2" className="description" color="GRAY70">
        인증 완료 국가 / 인증 가능 국가
      </Typography.Text>
      <WorldMapImg src={`${s3AssetDomain}/map_gray.png`} />
    </WorldMapBlock>
  );
};

export default WorldMap;

import { ComponentProps, useMemo } from 'react';
import { Select } from 'antd';

interface SelectRangeProps
  extends Omit<ComponentProps<typeof Select>, 'onChange' | 'value' | 'defaultValue'> {
  start?: number;
  end?: number;
  value?: number;
  defaultValue?: number;
  width?: string | number;
  onChange?: (value: number) => void;
  disableOption?: (value: number) => boolean;
  customLabel?: (value: number) => string;
}

const generateOptions = ({
  start,
  end,
  disableOption,
  customLabel,
}: {
  start: number;
  end: number;
  disableOption?: (month: number) => boolean;
  customLabel?: (value: number) => string;
}) => {
  const options: { label: string; value: number; disabled?: boolean }[] = [];

  for (let number = start; number <= end; number++) {
    options.push({
      label: customLabel ? customLabel(number) : number.toString(),
      value: number,
      disabled: disableOption?.(number) || false,
    });
  }

  return options;
};

const SelectRange = ({
  start = 0,
  end = 0,
  value,
  defaultValue,
  onChange,
  width,
  disableOption,
  customLabel,
  style,
  ...props
}: SelectRangeProps) => {
  const options = useMemo(() => {
    return generateOptions({
      start,
      end,
      disableOption,
      customLabel,
    });
  }, [start, end, disableOption]);

  const realValue = useMemo(() => options.find((o) => o.value === value), [value]);

  const realDefaultValue = useMemo(() => options.find((o) => o.value === defaultValue), []);

  const customStyle = useMemo(
    () => ({
      width,
      ...style,
    }),
    [width, style],
  );

  return (
    <Select
      labelInValue
      options={options}
      value={
        realValue && {
          label: realValue.label,
          value: realValue.value,
        }
      }
      defaultValue={realDefaultValue}
      onChange={(args) => onChange?.((args as { value: number }).value)}
      style={customStyle}
      {...props}
    />
  );
};

export default SelectRange;

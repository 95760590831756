import { Button, Form, FormInstance, message } from 'antd';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import UpdateLog from 'components/product/UpdateLog';
import { Typography } from 'components/system';
import { BackgroundRadio, Flex } from 'components/ui';
import useGA, { EGAActionType } from 'hook/useGA';
import { getChangedValues } from 'lib/common';
import { messages } from 'lib/consts';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';
import { manuSetNextPathname, setNextPathname } from 'modules/product';
import { useAddAllergensList, useUpdateAllergensList } from 'service/brand/product/allergensList';
import { useProductDocStatus } from 'service/product';
import {
  AllergensList,
  AllergensListFormValues,
  AllergicIngredient,
  Fragrance,
  StimulationMethod,
} from 'types/brand/product/allergensList';
import { CompanyType } from 'types/company';
import { DocumentCode, Product } from 'types/product';
import AllergenOdourFormList from './AllergenOdourFormList';

const Container = styled.div`
  max-width: 864px;
  margin: 0 auto;

  .ant-form-item-control-input {
    min-height: auto;
  }
  .ant-radio-wrapper {
    line-height: 24px;
  }

  .ant-collapse {
    border: none;
    background-color: #fff;
    counter-reset: no;

    .ant-collapse-item {
      border-bottom: none;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .ant-collapse-header {
        background-color: ${palette.SLATE_GRAY10};
        padding: 18px 36px;
        border-radius: 8px;

        .ant-collapse-arrow {
          top: 0;
          right: 0;
          padding: 0;
        }
      }

      .ant-collapse-content {
        border-top: none;

        .ant-collapse-content-box {
          padding: 16px 0 0px;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-header {
        background-color: ${palette.LIGHT_BLUE20};
      }
    }
  }
`;

interface ProductAllergensListFormProps {
  productId: number;
  countryId?: number;
  allergensList?: AllergensList;
}

// 미국 기준 향료 선택 나타나는 국가 목록
const stimulationMethodRequiredCountries = ['US'];

const ProductAllergensListForm = ({
  productId,
  countryId,
  allergensList,
}: ProductAllergensListFormProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<AllergensListFormValues>();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const updateMode = allergensList !== null;
  const { readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const { sendEventToGA } = useGA();
  const { addAllergensList } = useAddAllergensList();
  const { updateAllergensList } = useUpdateAllergensList();
  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.ALLE,
  });
  const dispatch = useDispatch();
  const [deletedProductAllergenListFragranceIds, setDeletedProductAllergenListFragranceIds] =
    useState<number[]>([]);
  const { manuMode, nextPathname, skipMode, currentProduct } = useSelector(
    ({ company, product }: any) => ({
      manuMode: company.company.companyType === CompanyType.MANUFACTURE,
      nextPathname: product.nextPathname,
      skipMode: product.skipMode,
      currentProduct: product.currentProduct as Product,
    }),
    shallowEqual,
  );

  const isStimulationMethodRequired = currentProduct.productCountries.some((productCountry) =>
    stimulationMethodRequiredCountries.includes(productCountry.country.countryCode),
  );

  const onSubmit = () => {
    const formValues: AllergensListFormValues = form.getFieldsValue(true);
    formValues.fragrances =
      formValues.fragrances?.map((item) => ({
        ...item,
        ...(item.allergicIngredients &&
          item.allergicIngredients?.length > 0 && {
            allergicIngredients: item.allergicIngredients,
          }),
      })) || [];

    const { isOdourless, stimulationMethod, fragrances } = formValues;

    if (!updateMode) {
      addAllergensList(
        {
          productId,
          isOdourless,
          ...(stimulationMethod && {
            stimulationMethod,
          }),
          ...(!isOdourless &&
            fragrances.length > 0 && {
              fragrances: fragrances,
            }),
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Allergens List',
              actionType: EGAActionType.REGISTER,
            });
            if (!skipMode) {
              message.success('등록되었습니다.');
              navigate(-1);
            } else if (!manuMode) {
              dispatch(setNextPathname(nextPathname));
              navigate(`/product/${productId}/${nextPathname}`, {
                replace: true,
              });
            } else {
              dispatch(manuSetNextPathname(nextPathname));
              navigate(`/product/${productId}/${nextPathname}`, {
                replace: true,
              });
            }
          },
        },
      );
    } else {
      const isStimulationMethodChanged = stimulationMethod !== allergensList?.stimulationMethod;
      const deletedFragrances = deletedProductAllergenListFragranceIds.map((id) => {
        const target = allergensList?.fragrances.find(
          (item) => item.productAllergenListFragranceId === id,
        );

        return {
          ...target,
          isDelete: true,
          allergicIngredients: target?.allergicIngredients.map((item) => ({
            ...item,
            isDelete: true,
          })),
        };
      });

      const newFragrances =
        fragrances && isOdourless
          ? (fragrances.map((item) => ({
              ...item,
              isDelete: true,
              allergicIngredients: item.allergicIngredients?.map((item) => ({
                ...item,
                isDelete: true,
              })),
            })) as Partial<Fragrance>[])
          : [];

      if (fragrances) {
        for (const fragrance of fragrances) {
          let found = false;
          const newFragrance = structuredClone(fragrance);
          newFragrance.allergicIngredients = newFragrance.allergicIngredients?.map(
            ({ name, ...rest }: AllergicIngredient) => ({
              ...rest,
              key: 'allergenListIngredientId',
            }),
          );

          for (const originalFragrance of allergensList?.fragrances || []) {
            if (
              (fragrance as { productAllergenListFragranceId?: number })
                .productAllergenListFragranceId === originalFragrance.productAllergenListFragranceId
            ) {
              found = true;
              const oldFragrance = structuredClone(originalFragrance);
              oldFragrance.allergicIngredients = oldFragrance.allergicIngredients?.map(
                (item: AllergicIngredient) => ({
                  ...item,
                  key: 'allergenListIngredientId',
                }),
              );

              const changedValues: Partial<Fragrance> = getChangedValues({
                obj1: oldFragrance,
                obj2: newFragrance,
                returnArrayKey: true,
                useKeyName: true,
              });

              if (Object.values(changedValues).length > 0) {
                newFragrances.push({
                  ...fragrance,
                  allergicIngredients: fragrance.allergicIngredients?.map(
                    (item: AllergicIngredient) => {
                      if (
                        changedValues.allergicIngredients
                          ?.map((item: AllergicIngredient) => item.allergenListIngredientId)
                          .includes(item.allergenListIngredientId)
                      ) {
                        return { ...item, isUpdate: true };
                      }

                      return item;
                    },
                  ),
                });
              }

              break;
            }
          }

          if (found) continue;

          newFragrances.push(fragrance);
        }
      }
      const fragrancesParam = newFragrances.concat(deletedFragrances);

      if (
        isOdourless === allergensList?.isOdourless &&
        ((isOdourless === false && fragrancesParam.length === 0 && !isStimulationMethodChanged) ||
          isOdourless === true)
      ) {
        message.warning(messages.NO_NEED_TO_UPDATE);
        return;
      }

      updateAllergensList(
        {
          productId,
          countryId,
          ...(isOdourless !== allergensList?.isOdourless && {
            isOdourless,
          }),
          // HINT: 알러지 유발 성분 포함 향료 -> 알러지 프리 향료로 변경하는 경우
          ...(!isOdourless && {
            fragrances: fragrancesParam.map((item, index) => ({
              ...item,
              allergicIngredients: item.isNonAllergenOdour
                ? fragrances?.[index]?.allergicIngredients?.map((item) => ({
                    ...item,
                    isDelete: true,
                  }))
                : item.allergicIngredients,
            })),
          }),
          ...(isStimulationMethodChanged && { stimulationMethod }),
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Allergens List',
              actionType: docStatus?.status === 'MOD' ? EGAActionType.MODIFY : EGAActionType.UPDATE,
            });
            message.success(`${docStatus?.status === 'MOD' ? '보완 완료' : '수정'}되었습니다.`);
            navigate(-1);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (allergensList) {
      const fragrances = allergensList.fragrances.map(
        (
          {
            fragranceName,
            usageLevel,
            productAllergenListFragranceId,
            allergicIngredients,
            isNonAllergenOdour,
          },
          index,
        ) => ({
          productAllergenListFragranceId,
          fragranceName,
          usageLevel,
          isNonAllergenOdour,
          ...(allergicIngredients.length > 0 && {
            allergicIngredients: allergicIngredients.map((ingredient) => ({
              name: index,
              ...ingredient,
            })),
          }),
        }),
      );
      form.setFieldsValue({
        isOdourless: allergensList.isOdourless,
        ...(allergensList.stimulationMethod && {
          stimulationMethod: allergensList.stimulationMethod,
        }),
        fragrances:
          fragrances.length > 0 ? fragrances : allergensList.stimulationMethod ? [] : [{}],
      });
    }
  }, [allergensList, form]);

  const [activeKey, setActiveKey] = useState<string | string[]>('0');

  useEffect(() => {
    if (readOnlyMode && allergensList) {
      setActiveKey(allergensList.fragrances.map((_, index) => index.toString()));
    }
  }, [readOnlyMode]);

  const validatePanels = async (form: FormInstance<any>) => {
    try {
      await form.validateFields();
    } catch (error) {
      const errorFields = (error as any).errorFields;
      const errorKeys = errorFields.map((field: any) => field.name[1]);
      const uniqueKeys = [...new Set(errorKeys)] as string[];
      setActiveKey(uniqueKeys);
      return Promise.reject(new Error());
    }
  };

  return (
    <Container>
      <Flex justify="end" gap={8}>
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.ALLE}
        />
        {updateMode && (
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={DocumentCode.ALLE}
            validCodes={[
              'ALLE01-ODL',
              'ALLE01-NAG',
              'ALLE01-FRN',
              'ALLE01-FRU',
              'ALLE01-AI',
              'ALLE01-STM',
            ]}
          />
        )}
      </Flex>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.isOdourless !== next.isOdourless}>
          {({ getFieldValue }) => (
            <Form.Item
              label={
                <>
                  향료 유무{' '}
                  <Typography.Text type="SMALL" gutter={{ left: 8 }}>
                    *향료: 전성분표상
                    <Typography.Text type="SMALL" color="PRIMARY50" inline>
                      &nbsp;Function을 Fragrance
                    </Typography.Text>
                    로 사용한 성분
                  </Typography.Text>
                </>
              }
              name="isOdourless"
              required
              rules={generateFormRules({
                required: getFieldValue('isOdourless') === undefined,
              })}
            >
              <BackgroundRadio
                disabled={readOnlyMode}
                gap={160}
                itemWidth={160}
                options={[
                  {
                    title: '무향 (향료가 없음)',
                    value: true,
                  },
                  {
                    title: '유향 (향료가 있음)',
                    value: false,
                  },
                ]}
              />
            </Form.Item>
          )}
        </Form.Item>
        {isStimulationMethodRequired && (
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.isOdourless !== next.isOdourless}>
            {({ getFieldValue }) =>
              getFieldValue('isOdourless') === false && (
                <Form.Item
                  label="미국 기준 향료 선택"
                  name="stimulationMethod"
                  required
                  rules={generateFormRules({
                    required: true,
                  })}
                >
                  <BackgroundRadio
                    disabled={readOnlyMode}
                    gap={48}
                    itemWidth={160}
                    options={[
                      {
                        title: 'Flavor',
                        value: StimulationMethod.FLAVOR,
                      },
                      {
                        title: 'Fragrance',
                        value: StimulationMethod.FRAGRANCE,
                      },
                      {
                        title: 'Fragrance&Flavor',
                        value: StimulationMethod.FLAVOR_AND_FRAGRANCE,
                      },
                    ]}
                  />
                </Form.Item>
              )
            }
          </Form.Item>
        )}
        <Form.Item shouldUpdate={(prev, curr) => prev.isOdourless !== curr.isOdourless} noStyle>
          {({ getFieldValue }) =>
            getFieldValue('isOdourless') === false ? (
              <div>
                <AllergenOdourFormList
                  readOnlyMode={readOnlyMode}
                  form={form}
                  productId={productId}
                  countryId={countryId}
                  activeKey={activeKey}
                  onChangeActiveKey={(key: string | string[]) => setActiveKey(key)}
                  validatePanels={() => validatePanels(form)}
                  setDeletedProductAllergenListFragranceIds={
                    setDeletedProductAllergenListFragranceIds
                  }
                />
              </div>
            ) : null
          }
        </Form.Item>
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            {updateMode && docStatus?.status === 'MOD' && (
              <CorrectRequestWithoutChangeButton documentCode={DocumentCode.ALLE} />
            )}
            <Button
              type="primary"
              htmlType="submit"
              // HINT: 등록시에 입력되지 않은 폼이 있으면 해당 Panels를 오픈함.
              onClick={() => {
                validatePanels(form).catch(() => console.log('입력되지 않은 정보가 있습니다.'));
              }}
            >
              {!updateMode ? '등록' : docStatus?.status !== 'MOD' ? '수정' : '보완 완료'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </Container>
  );
};

export default ProductAllergensListForm;

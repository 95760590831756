import styled from 'styled-components';

import { Typography } from 'components/system';
import { Button, Col, Form, Input, Row } from 'antd';
import { ProductBasic } from 'types/product';
import { ColumnsType } from 'antd/lib/table';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import Table from 'components/ui/Table/Table';

const StyleNoModalContentBlock = styled.div`
  text-align: left;

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-explain {
      text-align: left;
    }
  }
`;

const columns: ColumnsType<ProductBasic> = [
  {
    title: '영문 제품명 (용량)',
    width: '67%',
    align: 'center',
    dataIndex: ['productDetails', 0, 'productNameEn'],
    render: (productNameEn: string, product) => (
      <Typography.Text type="BODY_2" medium color="GRAY90">
        {`${productNameEn} ${
          product.netWeight ? `(${product.netWeight} ${product.netWeightUnit})` : '( - )'
        }`}
      </Typography.Text>
    ),
  },
  {
    title: 'Style No. (Reference No.)',
    align: 'center',
    render: (_, __, i) => (
      <Form.Item name={['productCodes', i]} rules={[requireRule, exceptKoreanRule]}>
        <Input placeholder="Style No. 입력" maxLength={20} />
      </Form.Item>
    ),
  },
];

const StyleNoModalContent = ({
  products,
  onSubmit,
}: {
  products: ProductBasic[];
  onSubmit: (productCodes: string[]) => void;
}) => {
  return (
    <StyleNoModalContentBlock>
      <Form onFinish={({ productCodes }) => onSubmit(productCodes)}>
        <Typography.Text type="BODY_2" gutter={{ bottom: 24 }}>
          제조사/브랜드사에서 부여한 제품 번호로, 제품 식별 시 사용하시는 제품 코드를 제품별로 직접
          입력해 주세요.
        </Typography.Text>
        <Table
          columns={columns}
          dataSource={products}
          rowKey={({ productId }) => productId}
          pagination={false}
        />
        <Row justify="center" style={{ marginTop: 24 }}>
          <Col>
            <Button type="primary" htmlType="submit" style={{ width: 84 }}>
              입력 완료
            </Button>
          </Col>
        </Row>
      </Form>
    </StyleNoModalContentBlock>
  );
};

export default StyleNoModalContent;

import { APIResponse } from 'types/common';
import client from 'lib/api/client';

export const deleteMaterials = async (materialIds: number[]) => {
  const res = await client.delete<APIResponse<null>>(`/material?materialIds=${materialIds}`);
  return res.data;
};

export const discontinueMaterials = async (materialIds: number[]) => {
  const res = await client.patch<APIResponse<null>>(
    `/material/discontinue?materialIds=${materialIds}`,
  );
  return res.data;
};

import { useState } from 'react';
import styled from 'styled-components';

import MaterialRegisterForm from 'components/auth/material/MaterialRegisterForm';
import ManagerUpdateContainer from 'containers/auth/ManagerUpdateContainer';
import RegisterFormContainer from 'containers/auth/brand/BrandRegisterFormContainer';
import ManuRegisterFormContainer from 'containers/auth/manu/ManuRegisterFormContainer';
import { useAuthority, useUser, useUserType } from 'service/auth';
import { UserType } from 'types/auth';

const UserInfoPageBlock = styled.div`
  padding-top: 12px;
`;

const UserInfoPage = () => {
  const [tab, setTab] = useState('company');
  const authority = useAuthority();
  const userType = useUserType();
  const user = useUser();

  return (
    <UserInfoPageBlock>
      {authority === 'SUPER_USER' ? (
        userType === UserType.BRAND ? (
          <RegisterFormContainer tabKey={tab} setTabKey={setTab} />
        ) : userType === UserType.MANUFACTURE ? (
          <ManuRegisterFormContainer />
        ) : (
          <MaterialRegisterForm />
        )
      ) : (
        <ManagerUpdateContainer directMode manager={user} />
      )}
    </UserInfoPageBlock>
  );
};

export default UserInfoPage;

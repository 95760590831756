import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Empty, Row, Spin } from 'antd';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import { downloadFile, printFile } from 'lib/file';
import { checkMobile } from 'lib/common';
import * as estimateActions from 'modules/estimate';
import { useEstimate } from 'hook/estimate';
import FooterBox from 'components/FooterBox';
import FileViewer from 'components/file/FileViewer';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';

const EstimateViewerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${palette.PRIMARY50};
      transition: all 200ms ease;
    }
  }

  svg,
  path {
    transition: all 200ms ease;
  }
`;

const EstimateViewer = () => {
  const dispatch = useDispatch();
  const { estimate, loading: getLoading } = useEstimate();
  const isMobile = checkMobile();

  const handleDownload = () => {
    downloadFile(estimate.fileUrl);
  };

  const handlePrint = () => {
    printFile(estimate.fileUrl);
  };

  const handleMoveToNext = () => {
    dispatch(estimateActions.setContractStep('contract'));
  };

  if (getLoading) {
    return <Spin style={{ width: '100%', height: '400px' }} />;
  }
  if (!estimate) {
    return (
      <EstimateViewerBlock>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="발행한 견적내역이 없거나 이미 계약이 체결되었습니다."
        />
        <Row gutter={16}>
          <Col>
            <Link
              to={path.estimate.pick}
              style={{ textDecoration: 'underline', color: palette.PRIMARY50 }}
            >
              새로운 견적 받기
            </Link>
          </Col>
          <Col>
            <Link
              to={`${path.my}/estimate`}
              style={{
                textDecoration: 'underline',
                marginTop: 16,
                color: palette.PRIMARY50,
              }}
            >
              계약 보관함으로 이동
            </Link>
          </Col>
        </Row>
      </EstimateViewerBlock>
    );
  }
  return (
    <EstimateViewerBlock>
      <Flex gap={8} justify="flex-end" gutter={{ bottom: 16 }} style={{ width: '100%' }}>
        {!isMobile && (
          <StyledButton
            icon={<Icon name="download" size={14} />}
            style={{
              width: 144,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleDownload}
          >
            다운로드
          </StyledButton>
        )}
        <StyledButton
          icon={<Icon name="print" size={14} />}
          style={{
            width: 144,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handlePrint}
        >
          인쇄하기
        </StyledButton>
      </Flex>
      <FileViewer
        title={' '}
        file={estimate.fileUrl}
        style={{ maxWidth: 1040 }}
        bodyStyle={{ height: 'auto' }}
      />
      <FooterBox>
        <Button type="primary" onClick={handleMoveToNext}>
          이 견적으로 계속 진행
        </Button>
      </FooterBox>
    </EstimateViewerBlock>
  );
};

export default EstimateViewer;

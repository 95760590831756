import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserType } from 'types/auth';
import path from './path';

export const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return () => setValue(value + 1);
};

export const useCheckManufacturer = () => {
  const isManufacturer = useSelector(({ auth }) => auth.user.userType === UserType.MANUFACTURE);
  const navigate = useNavigate();
  useEffect(() => {
    if (isManufacturer) {
      message.warning('올바르지 않은 접근입니다.');
      navigate(path.main, { replace: true });
    }
  }, [isManufacturer]);
};

import styled from 'styled-components';
import React from 'react';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

const Container = styled(Flex)`
  width: 640px;
  height: 66px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 0px 8px;
`;

const Thumbnail = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

const DocIconWrapper = styled(Flex)`
  width: 48px;
  height: 48px;
`;

const FileListItem = ({ file, onDelete }: { file: File; onDelete: (file: File) => void }) => {
  return (
    <Container justify="space-between" align="center">
      <Flex gap={8} justify="space-between" align="center">
        {file.type.startsWith('image') ? (
          <Thumbnail src={URL.createObjectURL(file)} alt="thumbnail" />
        ) : (
          <DocIconWrapper justify="center" align="center">
            <Icon name="page" />
          </DocIconWrapper>
        )}
        <Typography.Text type="BODY_2">{file.name}</Typography.Text>
        <Typography.Text type="BODY_2" color="GRAY70">
          {`${(file.size / (1024 * 1024)).toFixed(1)}MB`}
        </Typography.Text>
      </Flex>
      <Icon
        name="delete"
        color="GRAY60"
        onClick={() => onDelete(file)}
        style={{ margin: 0, cursor: 'pointer' }}
      />
    </Container>
  );
};

export default React.memo(FileListItem);

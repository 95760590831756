import { Button, Checkbox, Spin, message } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import {
  ChangeEventHandler,
  DragEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import styled, { css } from 'styled-components';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { useModal } from 'hook/useModal';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { CompanyDocsDataParmas, useCompanyDocsList } from 'service/brand/company';
import { useUploadFiles } from 'service/common';
import {
  CompanyDocsAttach,
  CompanyDocsList,
  CompanyDocsListParams,
  CompanyDocsUserType,
} from 'types/brand/company';
import { messages } from 'lib/consts';

const Container = styled.div`
  width: 100%;
  position: relative;

  .placeholder {
    position: absolute;
    background-color: white;
    border-bottom: solid 4px ${palette.SLATE_GRAY40};
    transform: translateY(14px);
  }
`;

const HeaderContainer = styled(Flex)``;

const DocsContainer = styled(Flex)`
  padding-top: 24px;
  width: 100%;
`;

const DocsColumnContainer = styled(Flex)`
  margin-bottom: 16px;
  width: 100%;
  position: relative;
`;

const RowContainer = styled(Flex)<{
  isDragging: boolean;
  visible?: boolean;
  isDraggableIconHover?: boolean;
  fileDragging?: boolean;
}>`
  background-color: ${palette.ETC_WHITE};
  border-radius: 8px;
  width: 1040px;
  border: 1px solid transparent;
  margin-bottom: 16px;
  transition: border 200ms ease;
  padding: 14px 32px;
  box-shadow: ${({ isDragging }) =>
    !isDragging ? '0px 1px 5px rgba(0, 0, 0, 0.1)' : '0px 2px 22px rgba(162, 162, 162, 0.23)'};

  transition: background-color 300ms ease;
  transition: box-shadow 300ms ease;
  transition: display 300ms ease;
  position: relative;

  .draggable_icon {
    display: none;
  }

  &:hover {
    .draggable_icon {
      cursor: pointer;
      position: absolute;
      left: 12px;
      display: flex;
      align-items: center;
    }
  }

  ${({ isDraggableIconHover }) =>
    isDraggableIconHover &&
    css`
      transition: display 200ms ease;
      .tooltip {
        display: block;
      }
    `};

  border: 2px dashed transparent;
  transition: border 200ms ease;

  ${({ fileDragging }) =>
    fileDragging &&
    `
    border: 2px dashed ${palette.PRIMARY50};
    background-color: ${palette.PRIMARY10};
  `}
`;

const Tooltip = styled(Flex)`
  display: none;
  font-size: 14px;
  position: absolute;
  left: -168px;
  height: 40px;
  background-color: ${palette.SLATE_GRAY70};
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 400;
  .tooltip_text1 {
    color: white;
  }

  .tooltip_text2 {
    color: ${palette.MESSAGE_WARNING};
    font-weight: 500;
  }
`;

const DocColumnContainer = styled(Flex)``;

const DocsColumnHeader = styled(Flex)`
  width: 856px;
`;

const DocTitle = styled(Typography.Text)<{ visible: boolean }>`
  font-weight: 700;
  font-size: 16px;
  color: ${({ visible }) => (visible ? palette.SLATE_GRAY70 : palette.SLATE_GRAY40)};
`;

const FileListContainer = styled(Flex)`
  width: 856px;
  margin-top: 16px;
`;

const FileItemContainer = styled(Flex)`
  width: 100%;
  padding: 8px 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;
`;

const ButtonContainer = styled(Flex)`
  margin-top: 20px;
`;

const CertificatonTip = styled(Flex)`
  width: 64px;
  height: 20px;
  background-color: ${palette.YELLOW_20};
  border-radius: 3px;
  color: ${palette.SLATE_GRAY70};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

interface DocsColumnProps {
  column?: {
    description?: string;
    id: string;
    relationType: string;
    name: string;
    visible: boolean;
    editable: boolean;
    open?: boolean;
    attachIds: string[];
    historyIds: string[];
    isForCertification?: boolean;
  };
  files?: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  }[];
  index: number;
  docsList: CompanyDocsDataParmas | undefined;
  docItemNameValue: {
    [key: string]: string;
  };
  fileInfoList?: CompanyDocsAttach[];
  preventFileHandling?: boolean;
  loading?: boolean;
  onUpload: (filesList: FileList | File[], isScreening?: boolean) => void;
  onDownload: (fileIndex: number) => void;
  onDelete: (fileIndex: number) => void;

  setDocsList: React.Dispatch<React.SetStateAction<CompanyDocsDataParmas | undefined>>;
  setDocItemNameValue: React.Dispatch<React.SetStateAction<{ [columnId: string]: string }>>;
  _ref: (ref: HTMLDivElement | null) => void;
}

const FileItem = ({
  file,
  index,
  onDownload,
  onDelete,
}: {
  file: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  };
  index: number;
  onDownload: VoidFunction;
  onDelete?: VoidFunction;
}) => {
  return (
    <FileItemContainer justify="space-between">
      <Typography.Text type="SMALL" color="SLATE_GRAY70">
        {file.filename}
      </Typography.Text>
      <Flex columnGap={26}>
        <Typography.Text type="SMALL" color="GRAY50">
          {file.registerDt.slice(0, 10)}
        </Typography.Text>
        <Flex align="center" style={{ height: 18, cursor: 'pointer' }} onClick={onDownload}>
          <Icon name="download" size={16} color="GRAY60" />
        </Flex>
        <Flex align="center" style={{ height: 18, cursor: 'pointer' }} onClick={onDelete}>
          <Icon name="delete" size={18} color="GRAY60" />
        </Flex>
      </Flex>
    </FileItemContainer>
  );
};

const DocColumn = ({
  column,
  index,
  docsList,
  docItemNameValue,
  preventFileHandling,
  fileInfoList,
  loading,
  onUpload,
  onDownload,
  onDelete,
  setDocsList,
  setDocItemNameValue,
  _ref,
}: DocsColumnProps) => {
  const [isDraggableIconHover, setIsDraggableIconHover] = useState<boolean>();
  const [fileDragState, setFileDragState] = useState<'none' | 'dragging'>('none');
  const [hoverCount, setHoverCount] = useState(0);
  const fileRef = useRef<HTMLInputElement>(null);
  const { openDocsItemModal, openAlertModal } = useModal();

  useEffect(() => {
    if (!column || !docsList) return;
    setDocItemNameValue({
      ...docItemNameValue,
      [column.id]: docsList?.columns[column.id].name,
    });
  }, [docsList]);

  const handleVisible = (type: 'off' | 'on') => {
    if (!docsList || !column) return;
    setDocsList({
      ...docsList,
      columns: {
        ...docsList.columns,
        [column.id]: {
          ...docsList.columns[column.id],
          visible: type === 'off' ? false : true,
        },
      },
    });
  };

  const handleDeleteItem = () => {
    if (!column) return;

    openAlertModal({
      content: '항목을 삭제 하시겠습니까?',
      okText: '삭제',
      onOk: () => {
        setDocsList((list) => {
          if (!list) return;
          const docs = { ...list };
          delete docs.columns[column.id];
          return docs;
        });
        message.success('삭제되었습니다.');
      },
    });
  };

  const referToFile = () => fileRef.current?.click();

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) return;
    onUpload(e.target.files);
    e.target.value = '';
  };

  const handleFileDownload = (fileIndex: number) => () => onDownload(fileIndex);

  const handleFileDelete = (fileIndex: number) => () => onDelete(fileIndex);

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = (e) => {
    setHoverCount((count) => count + 1);
    setFileDragState('dragging');
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1) {
      setFileDragState('none');
    }
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if ([...e.dataTransfer.files].filter((file) => file.type === '').length > 0) {
      message.warning('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    onUpload(e.dataTransfer.files);
  };

  if (column) {
    return (
      <Draggable draggableId={column?.id} index={index}>
        {(provided, snapshot) => {
          return (
            <>
              <RowContainer
                dir="row"
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                gap={16}
                isDragging={snapshot.isDragging}
                align="center"
                visible={column?.visible}
                isDraggableIconHover={isDraggableIconHover}
                fileDragging={fileDragState === 'dragging'}
                onDragEnter={preventFileHandling ? undefined : handleFileDragEnter}
                onDragLeave={handleFileDragLeave}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={preventFileHandling ? undefined : handleFileDragUpload}
              >
                {isDraggableIconHover && (
                  <Tooltip className="tooltip">
                    <span className="tooltip_text1">드래그하여</span>
                    <span className="tooltip_text2"> 순서변경</span>
                  </Tooltip>
                )}
                <Flex
                  {...provided.dragHandleProps}
                  className="draggable_icon"
                  onMouseEnter={() => setIsDraggableIconHover(true)}
                  onMouseLeave={() => setIsDraggableIconHover(false)}
                  style={{
                    height: '100%',
                    width: 25,
                    left: '0px !important',
                  }}
                >
                  <Icon name="move" size={18} style={{ position: 'absolute', left: -5 }} />
                </Flex>
                <DocColumnContainer dir="column" ref={_ref}>
                  <DocsColumnHeader justify="space-between" align="center">
                    <input
                      type="file"
                      ref={fileRef}
                      multiple={true}
                      style={{ display: 'none' }}
                      onChange={handleFileUpload}
                    />
                    <Flex gap={8} align="center">
                      <DocTitle visible={column?.visible}>{column?.name}</DocTitle>
                      {column.description && <Tip trigger="click">{column.description}</Tip>}
                      {column.isForCertification && (
                        <CertificatonTip align="center" justify="center" textAlign="center">
                          인증 활용
                        </CertificatonTip>
                      )}
                      {column?.editable && (
                        <Icon
                          name="write"
                          size={18}
                          color="SLATE_GRAY70"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            openDocsItemModal({
                              columnId: column.id,
                              updateMode: true,
                              docItemName: docItemNameValue,
                              onSubmit: (itemName) => {
                                if (!docsList) return;
                                setDocsList({
                                  ...docsList,
                                  columns: {
                                    ...docsList.columns,
                                    [column.id]: {
                                      ...docsList.columns[column.id],
                                      name: itemName,
                                    },
                                  },
                                });

                                setDocItemNameValue({
                                  ...docItemNameValue,
                                  [column.id]: itemName,
                                });

                                message.success('수정되었습니다.');
                              },
                            });
                          }}
                        />
                      )}
                    </Flex>
                    <Flex gap={16}>
                      <>
                        {!column.editable && column.visible && (
                          <Flex
                            gap={4}
                            role="button"
                            align="center"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleVisible('off')}
                          >
                            <Icon name="hide" size={16} color="SLATE_GRAY70" />
                            <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                              숨기기
                            </Typography.Text>
                          </Flex>
                        )}
                        {column.editable && (
                          <Flex
                            gap={4}
                            role="button"
                            align="center"
                            style={{ cursor: 'pointer' }}
                            onClick={handleDeleteItem}
                          >
                            <Icon name="delete" size={16} color="SLATE_GRAY70" />
                            <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                              삭제
                            </Typography.Text>
                          </Flex>
                        )}
                        {!column?.visible && (
                          <Flex
                            gap={4}
                            role="button"
                            align="center"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleVisible('on')}
                          >
                            <Icon name="show" size={16} color="SLATE_GRAY70" />
                            <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                              보기
                            </Typography.Text>
                          </Flex>
                        )}
                        <Flex
                          gap={4}
                          role="button"
                          align="center"
                          style={{ cursor: 'pointer' }}
                          onClick={preventFileHandling ? undefined : referToFile}
                        >
                          <Icon name="plus" size={16} color="SLATE_GRAY70" />
                          <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                            파일추가
                          </Typography.Text>
                        </Flex>
                      </>
                    </Flex>
                  </DocsColumnHeader>
                  {loading && (
                    <Flex
                      justify="center"
                      align="center"
                      dir="column"
                      rowGap={4}
                      style={{ width: '100%' }}
                    >
                      <LoadingIndicator size="sm" />
                      <Typography.Text type="SMALL" color="SLATE_GRAY70" bold>
                        파일을 업로드 하는 중...
                      </Typography.Text>
                    </Flex>
                  )}
                  {!loading && fileInfoList && fileInfoList?.length > 0 && (
                    <FileListContainer dir="column" justify="space-between" gap={8}>
                      {fileInfoList.map((file, fileIdx) => {
                        return (
                          <FileItem
                            key={file?.attachId}
                            file={file}
                            index={fileIdx}
                            onDownload={handleFileDownload(fileIdx)}
                            onDelete={preventFileHandling ? undefined : handleFileDelete(fileIdx)}
                          />
                        );
                      })}
                    </FileListContainer>
                  )}
                </DocColumnContainer>
              </RowContainer>
            </>
          );
        }}
      </Draggable>
    );
  } else {
    return <></>;
  }
};

const CompanyDocsListRegistration = ({
  tabKey,
  onChangeIsBlockTab,
  companyDocsList,
  isCompanyDocsListLoading,
  isMounted,
  setIsMounted,
  qcqaUserDocumentId,
}: {
  tabKey: string;
  onChangeIsBlockTab: React.Dispatch<React.SetStateAction<boolean>>;
  companyDocsList: CompanyDocsList[] | undefined;
  isCompanyDocsListLoading: boolean;
  isMounted: boolean;
  setIsMounted: React.Dispatch<React.SetStateAction<boolean>>;
  qcqaUserDocumentId: number;
}) => {
  const { addCompanyDocsList, iaAddCompanyDocsListLoading } = useCompanyDocsList({
    useType: CompanyDocsUserType.COMPANY,
  });
  const refs = useRef<HTMLDivElement[]>([]);
  const [docsList, setDocsList] = useState<CompanyDocsDataParmas | undefined>();
  const [docsCheckList, setDocsCheckList] = useState<CompanyDocsDataParmas | undefined>();
  const [showHideItems, setShowHideItems] = useState<boolean>(true);
  const [placeholderProps, setPlaceholderProps] = useState<{
    clientY: number;
    clientX: number;
    clientHeight: number;
    clientWidth: number;
  }>();
  const [docItemNameValue, setDocItemNameValue] = useState<{
    [columnId: string]: string;
  }>({});
  const [fileMapBeforeUploading, setFileMapBeforeUploading] = useState<
    Record<string, CompanyDocsAttach[]>
  >({});
  const [uploadingDocumentsMap, setUploadingDocumentsMap] = useState<Record<string, boolean>>({});

  const { openDocsItemModal, openAlertModal } = useModal();
  const { uploadFilesAsync } = useUploadFiles();

  useEffect(() => {
    if (!companyDocsList) return;
    docsListToData();
  }, [companyDocsList]);

  useEffect(() => {
    if (!companyDocsList) return;

    const newColumnMap: Record<string, CompanyDocsAttach[]> = {};

    companyDocsList.forEach((doc) => {
      doc.records?.forEach((history) => {
        newColumnMap[doc.qcqaUserDocumentId] = history.attaches;
      });
    });

    setFileMapBeforeUploading(newColumnMap);
  }, [companyDocsList]);

  const docsListToData = useCallback(() => {
    if (!companyDocsList) return;
    const newFiles = { ...docsList?.files };
    const newHistories = { ...docsList?.histories };
    const newColumns = { ...docsList?.columns };
    const newColumnOrder: string[] = [];
    companyDocsList.forEach((doc) => {
      if (!doc) return;
      newColumns[doc.qcqaUserDocumentId] = {
        id: String(doc.qcqaUserDocumentId),
        relationType: doc.relationType,
        name: doc.name,
        visible: doc.isDisplay,
        editable: doc.relationType === 'BASIC' ? false : true,
        description: doc.description,
        open: false,
        isForCertification: doc.isForCertification,
        attachIds:
          doc?.records && doc.records.length > 0
            ? doc.records
                ?.map((history) => history.attaches?.map((attach) => String(attach.attachId)))
                .flat(1)
            : [],
        historyIds:
          doc.records && doc.records.length > 0
            ? doc.records?.map((record) => String(record.qcqaDocumentRecordId))
            : [],
      };
      doc.records?.forEach((history) => {
        if (!history.qcqaDocumentRecordId) return;

        newHistories[history.qcqaDocumentRecordId] = {
          qcqaDocumentRecordId: history.qcqaDocumentRecordId,
          name: history.name,
          recordDate: history.recordDate,
          attachIds: history.attaches?.map((attach) => String(attach.attachId)),
          qcqaDocumentRecordAttachIds: history.attaches?.map((attach) =>
            String(attach.qcqaDocumentRecordAttachId),
          ),
        };

        history?.attaches?.forEach((attach) => {
          newFiles[attach.attachId] = {
            attachId: attach.attachId,
            filename: attach.filename,
            uploadFileUrl: attach.uploadFileUrl,
            registerDt: attach.registerDt,
            qcqaDocumentRecordAttachId: attach.qcqaDocumentRecordAttachId,
          };
        });
      });
      newColumnOrder.push(String(doc.qcqaUserDocumentId));
    });

    const newDocList = {
      ...docsList,
      columns: newColumns,
      columnOrder: newColumnOrder,
      histories: newHistories,
      files: newFiles,
    };
    setDocsList(newDocList);
    setDocsCheckList(structuredClone(newDocList));
  }, [companyDocsList]);

  const addDocsItem = () => {
    if (!docsList) return;

    openDocsItemModal({
      onSubmit: (itemName) => {
        const newDocIdName = `doc-${itemName}_${String(docsList.columnOrder.length) + 1}`;

        const newColumns = {
          ...docsList?.columns,
          [newDocIdName]: {
            id: newDocIdName,
            relationType: 'CUSTOM',
            name: itemName,
            visible: true,
            editable: true,
            open: false,
            attachIds: [],
            historyIds: [],
          },
        };

        setDocItemNameValue({
          ...docItemNameValue,
          [newDocIdName]: itemName,
        });

        setDocsList({
          ...docsList,
          columns: newColumns,
          columnOrder: [...docsList.columnOrder, newDocIdName],
        });
        message.success('추가되었습니다.');
      },
    });
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    if (!docsList) return;

    if (destination.droppableId === source.droppableId && source.index === destination.index)
      return;

    const newDocsColumnOrder = Array.from(docsList.columnOrder);
    const [targetItem] = newDocsColumnOrder.splice(source.index, 1);

    newDocsColumnOrder.splice(destination.index, 0, targetItem);

    setDocsList({
      ...docsList,
      columnOrder: newDocsColumnOrder,
    });

    return;
  };

  const queryAttr = 'data-rfd-drag-handle-draggable-id';

  const onDragStart = (event: any) => {
    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) return;

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;

    const borderHeight = 4;

    let clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom + borderHeight;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const onDragUpdate = (event: any) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const borderHeight = 4;

    let clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom + borderHeight;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const now = moment().format('YYYY-MM-DD');

  const handleFileUpload =
    (columnId: string) =>
    async (fileList: FileList | File[], isScreening: boolean = false) => {
      if (uploadingDocumentsMap[columnId]) {
        return;
      }

      setUploadingDocumentsMap((prevMap) => {
        prevMap[columnId] = true;

        return { ...prevMap };
      });

      const files: File[] = [];
      for (const file of fileList) {
        files.push(file);
      }

      if (!(columnId in fileMapBeforeUploading)) {
        fileMapBeforeUploading[columnId] = [];
      }

      const res = await uploadFilesAsync({
        files,
        uploadDestinationType: 'QCQA',
      });
      const fileLists: CompanyDocsAttach[] = [];

      for (const uploadFileRes of res.data.result) {
        fileLists.push({
          ...uploadFileRes,
          qcqaDocumentRecordAttachId: uploadFileRes.attachId,
          registerDt: now,
        });
      }

      setFileMapBeforeUploading((prevMap) => {
        prevMap[columnId] = prevMap[columnId].concat(fileLists);

        return { ...prevMap };
      });

      if (fileLists.length === 0) {
        message.warning(messages.SHOULD_CHECK_FILE);
      } else {
        message.success('정상적으로 업로드되었습니다.');
      }

      setUploadingDocumentsMap((prevMap) => {
        prevMap[columnId] = false;

        return { ...prevMap };
      });

      if (!docsList) return;
      const targetColumn = docsList.columns[columnId];
      const newHistoryIdName = `${targetColumn.name}_${now}`;

      //HINT: 파일 업로드시 files,columns,histories 수정
      setDocsList((prev) => {
        if (!prev) return;
        const newAttachIds = [...(prev.histories[newHistoryIdName]?.attachIds || [])];
        const newDocumentRecordAttachIds = [
          ...(prev.histories[newHistoryIdName]?.qcqaDocumentRecordAttachIds || []),
        ];
        const newColumnsAttachIds = [...(prev.columns[columnId].attachIds || [])];
        const newColumnsHistoryIds = [...(prev.columns[columnId].historyIds || [])];

        const newFiles = prev.files;
        const newHistories = prev.histories;

        fileLists.forEach((file) => {
          newAttachIds.push(String(file.attachId));
          newDocumentRecordAttachIds.push(String(file.qcqaDocumentRecordAttachId));
          newColumnsAttachIds.push(String(file.attachId));
          newColumnsHistoryIds.push(newHistoryIdName);

          newFiles[file.attachId] = {
            attachId: file.attachId,
            filename: file.filename,
            uploadFileUrl: file.uploadFileUrl,
            registerDt: file.registerDt,
            qcqaDocumentRecordAttachId: file.qcqaDocumentRecordAttachId,
          };

          newHistories[newHistoryIdName] = {
            name: `${targetColumn.name}_${now}`,
            recordDate: now,
            attachIds: newAttachIds,
            qcqaDocumentRecordAttachIds: newDocumentRecordAttachIds,
          };
        });

        return {
          ...prev,
          files: newFiles,
          histories: newHistories,
          columns: {
            ...prev.columns,
            [columnId]: {
              ...prev.columns[columnId],
              attachIds: newColumnsAttachIds,
              historyIds: newColumnsHistoryIds,
            },
          },
        };
      });
    };

  const handleFileDelete = (columnId: string) => (fileIndex: number) => {
    const fileInfoList =
      fileMapBeforeUploading[isNaN(Number(columnId)) ? columnId : Number(columnId)];

    if (!fileInfoList) return;
    const fileInfo = fileInfoList[fileIndex];

    openAlertModal({
      content: `‘${fileInfo.filename}’ 파일을 삭제하시겠습니까?`,
      okText: '확인',
      onOk: () => {
        if (!fileInfo.attachId) {
          message.warning('새로고침 후 다시 이용해주세요.');
          return;
        }
        fileInfoList.splice(fileIndex, 1);
        setFileMapBeforeUploading({ ...fileMapBeforeUploading });

        if (!docsList) return;

        setDocsList((list) => {
          if (!list) return;
          const docs = { ...list };
          const newAttachIds = docs.columns[columnId].attachIds;

          const fileIndex = docs.columns[columnId].attachIds.indexOf(String(fileInfo.attachId));
          newAttachIds.splice(fileIndex, 1);

          list.columns[columnId].attachIds = newAttachIds;

          delete docs.files[fileInfo.attachId];

          return docs;
        });

        message.success('삭제되었습니다.');
      },
    });
  };

  const handleFileDownload = (columnId: string) => (fileIndex: number) => {
    const fileInfo = (fileMapBeforeUploading[
      isNaN(Number(columnId)) ? columnId : Number(columnId)
    ] ?? [])[fileIndex];

    if (!fileInfo) return;
    downloadFile(fileInfo.uploadFileUrl, fileInfo.filename);
    message.success('정상적으로 다운로드되었습니다.');
  };

  const handleSubmit = () => {
    const docsListData: CompanyDocsListParams[] = [];

    if (!docsList) return;

    Object.entries(docsList.columnOrder).forEach(([key, order]) => {
      const column = docsList.columns[order];
      const { name, visible, historyIds, attachIds } = column;
      const newRecords: Record<
        string,
        {
          qcqaDocumentRecordId?: number;
          name: string;
          recordDate: string;
          attaches: number[];
        }
      > = {};

      //attachIds가 없을 경우 record 이력 리셋
      if (attachIds.length > 0 && historyIds.length > 0) {
        historyIds.forEach((id) => {
          const history = docsList.histories[id];

          newRecords[history.name] = {
            name: history.name,
            recordDate: history.recordDate,
            attaches: history.attachIds.map((id) => Number(id)),
          };
        });
      } else {
        for (const record in newRecords) {
          delete newRecords[record];
        }
      }

      docsListData.push({
        name: name,
        isDisplay: visible,
        displayOrder: Number(key) + 1,
        records: Object.values(newRecords),
        ...(!isNaN(Number(column.id)) && {
          qcqaUserDocumentId: Number(column.id),
        }),
      });
    });

    addCompanyDocsList(docsListData, {
      onSuccess: () => {
        message.success('등록되었습니다.');
        onChangeIsBlockTab(false);
      },
    });
  };

  const checkIsEqaul = () => {
    const docsListCopy = structuredClone(docsList);
    const docsCheckListCopy = structuredClone(docsCheckList);

    if (docsListCopy && docsCheckListCopy) {
      for (const key of Object.keys(docsListCopy.columns)) {
        delete docsListCopy.columns[key].open;
      }

      for (const key of Object.keys(docsCheckListCopy.columns)) {
        delete docsCheckListCopy.columns[key].open;
      }

      return isEqual(docsListCopy, docsCheckListCopy);
    }

    return true;
  };

  useEffect(() => {
    onChangeIsBlockTab(!checkIsEqaul());
  }, [checkIsEqaul()]);

  useEffect(() => {
    if (!qcqaUserDocumentId || isMounted || !docsList) {
      return;
    }
    const index = docsList.columnOrder.findIndex((order) => Number(order) === qcqaUserDocumentId);

    const element = refs.current[index];
    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - 107,
        behavior: 'smooth',
      });

      setIsMounted(true);
    }
  }, [isMounted, docsList]);

  return (
    <Container>
      <HeaderContainer justify="space-between" align="center">
        <Typography.Text style={{ fontSize: 24 }}>회사 서류</Typography.Text>
        <Flex columnGap={8} align="center">
          <Checkbox checked={showHideItems} onChange={(e) => setShowHideItems(e.target.checked)}>
            <Typography.Text type="SMALL" color="SLATE_GRAY70" bold inline>
              숨긴 항목도 보기
            </Typography.Text>
          </Checkbox>
        </Flex>
      </HeaderContainer>
      <Spin
        size="small"
        spinning={tabKey !== CompanyDocsUserType.COMPANY || isCompanyDocsListLoading}
      >
        <DocsContainer>
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
          >
            <Droppable droppableId="all-docs" type="column">
              {(provided, snapshot) => (
                <DocsColumnContainer
                  dir="column"
                  align="center"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {docsList?.columnOrder.map((columnId: string, index: number) => {
                    const column = docsList.columns[columnId];
                    const filteredColumn =
                      docsList.columns[columnId]?.visible === true
                        ? docsList.columns[columnId]
                        : undefined;

                    const files = column?.attachIds.map((attachId) => docsList.files[attachId]);
                    return (
                      <DocColumn
                        _ref={(ref: HTMLDivElement | null) => {
                          if (ref) {
                            refs.current[index] = ref;
                          }
                        }}
                        column={!showHideItems ? filteredColumn : column}
                        files={files}
                        key={column?.id}
                        index={index}
                        setDocsList={setDocsList}
                        docsList={docsList}
                        fileInfoList={fileMapBeforeUploading[column?.id]}
                        docItemNameValue={docItemNameValue}
                        loading={uploadingDocumentsMap[column?.id]}
                        onUpload={handleFileUpload(column?.id)}
                        onDownload={handleFileDownload(column?.id)}
                        onDelete={handleFileDelete(column?.id)}
                        setDocItemNameValue={setDocItemNameValue}
                      />
                    );
                  })}
                  {provided.placeholder}
                  {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                    <div
                      className="placeholder"
                      style={{
                        top: placeholderProps?.clientY,
                        left: placeholderProps?.clientX,
                        height: placeholderProps?.clientHeight,
                        width: placeholderProps?.clientWidth,
                      }}
                    />
                  )}
                </DocsColumnContainer>
              )}
            </Droppable>
          </DragDropContext>
        </DocsContainer>
        <ButtonContainer align="center" gap={58} dir="column">
          <Button
            type="dashed"
            block
            icon={<Icon name="plus" size={14} color="PRIMARY50" />}
            style={{
              width: 520,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={addDocsItem}
          >
            항목 추가
          </Button>
          <Button
            type="primary"
            block
            style={{ width: 200, height: 56, fontSize: 18 }}
            onClick={handleSubmit}
            disabled={checkIsEqaul()}
            loading={iaAddCompanyDocsListLoading}
          >
            등록
          </Button>
        </ButtonContainer>
      </Spin>
    </Container>
  );
};

export default CompanyDocsListRegistration;

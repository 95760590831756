import { useRoutes } from 'react-router-dom';

import QCQARegistrationPage from 'pages/brand/qcqa/QCQARegistrationPage';
import QCQAProductPage from 'pages/brand/qcqa/QCQAProductPage';
import QCQALotPage from 'pages/brand/qcqa/QCQALotPage';
import QCQAProductListPage from 'pages/brand/qcqa/QCQAProductListPage';
import QCQALotListPage from 'pages/brand/qcqa/QCQALotListPage';
import QCQAProductUpdatePage from 'pages/brand/qcqa/QCQAProductUpdatePage';
import QCQAOutputPage from 'pages/brand/qcqa/QCQAOutputPage';
import QCQASelfInspectPage from 'pages/brand/qcqa/QCQASelfInspectPage';

const QCQARouter = () => {
  const element = useRoutes([
    // 품질 제품 등록
    {
      path: 'registration',
      element: <QCQARegistrationPage />,
    },
    {
      path: 'product/registration',
      element: <QCQAProductPage />,
    },
    {
      path: 'lot/registration',
      element: <QCQALotPage />,
    },
    {
      path: `product/:qcqaProductId/lot/:qcqaProductLotnoId`,
      element: <QCQALotPage />,
    },
    // 품질 제품 관리
    { path: `product`, element: <QCQAProductListPage /> },
    { path: `product/:qcqaProductId`, element: <QCQAProductUpdatePage /> },
    { path: `product/:qcqaProductId/lot`, element: <QCQALotListPage /> },
    // 생산 실적 현황
    {
      path: `product-outputs`,
      element: <QCQAOutputPage />,
    },
    // 자율 점검 관리
    {
      path: `self-inspect`,
      element: <QCQASelfInspectPage />,
    },
  ]);
  return element;
};

export default QCQARouter;

import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Typography from 'components/system/general/Typography';
import path from 'lib/path';
import palette from 'lib/styles/palette';

const RegisterTypePageBlock = styled.div`
  padding-top: 40px;
  max-width: 576px !important;
  margin: 0 auto;

  .ant-btn {
    width: 176px;
    height: 200px;
    font-size: 18px;
    letter-spacing: -1px;
    line-height: 1.33;
    background-color: #fff;
    color: ${palette.GRAY90};
    border: 1px solid ${palette.GRAY40};
  }

  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #fff;
    border-color: ${palette.PRIMARY50};
    box-shadow: 0 2px 22px 0 rgba(162, 162, 162, 0.23);
    color: ${palette.GRAY90};
  }
`;

const RegisterTypePage = () => {
  return (
    <RegisterTypePageBlock>
      <Typography.Text light type="HEADLINE_1" gutter={{ bottom: 56 }} align="center">
        지금 회원 가입하고
        <br />
        서비스를 이용해 보세요.
      </Typography.Text>
      <Row gutter={[0, 24]} justify="space-between">
        <Col>
          <Link to={`${path.register.terms}/brand`}>
            <Button type="primary" style={{ display: 'block' }}>
              브랜드사로
              <br /> 가입하기
            </Button>
          </Link>
        </Col>
        <Col>
          <Link to={`${path.register.terms}/manu`}>
            <Button type="primary" style={{ display: 'block' }}>
              제조사로
              <br />
              가입하기
            </Button>
          </Link>
        </Col>
        <Col>
          <Link to={`${path.register.terms}/material`}>
            <Button type="primary" style={{ display: 'block' }}>
              원료사로
              <br />
              가입하기
            </Button>
          </Link>
        </Col>
      </Row>
    </RegisterTypePageBlock>
  );
};

export default RegisterTypePage;

import RawMaterialCoa from 'components/material/coa/RawMaterialCoa';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const RawMaterialCoaPage = () => {
  const notices = [
    '입력되는 CoA 자료는 원료사의 승인 없이는 공개되지 않습니다.',
    'CoA 자료는 항상 최신본으로 유지해 주세요. 1년마다 갱신 요청 알람이 전송될 예정입니다.',
    '분석 항목 또는 분석값이 없는 경우, ‘No data’을 눌러 진행해 주세요. (‘No data’ 선택 시, 해당 항목은 성적서에 표출되지 않습니다.)',
    <MistakeNoticeItem />,
  ];
  return (
    <RawMaterialPageTemplate documentName="Certificate of Analysis" notices={notices}>
      <RawMaterialCoa />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialCoaPage;

import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModal } from 'hook/useModal';
import { useCompanyDocsList } from 'service/brand/company';
import { CompanyDocsUserType } from 'types/brand/company';
import CompanyDocsListRegistration from './CompanyDocsListRegistration';
import CompanyDocsListUpdate from './CompanyDocsListUpdate';
import LotDocsList from './LotDocsList';
import ProductDocsList from './ProductDocsList';
import RouteLeaveGuard from 'components/RouteLeaveGuard';

const Container = styled.div``;

const StyledTabs = styled(Tabs)`
  overflow: visible;

  .ant-tabs-nav {
    margin-bottom: 36px;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;

export enum MyDocTabKey {
  COMPANY = 'COMPANY',
  PRODUCT = 'PRODUCT',
  LOT_NO = 'LOT_NO',
}

const SharedDocsTabs = ({ qcqaUserDocumentId }: { qcqaUserDocumentId: number }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [type, setType] = useState<'update' | 'register'>('register');
  const [tabKey, setTabKey] = useState<string>(MyDocTabKey.COMPANY);
  const [isBlockTab, setIsBlockTab] = useState<boolean>(false);
  const { openAlertModal } = useModal();
  const { companyDocsList, isCompanyDocsListLoading } = useCompanyDocsList({
    useType:
      tabKey === CompanyDocsUserType.COMPANY
        ? CompanyDocsUserType.COMPANY
        : tabKey === CompanyDocsUserType.PRODUCT
        ? CompanyDocsUserType.PRODUCT
        : CompanyDocsUserType.LOT_NO,
  });

  useEffect(() => {
    const isUpdateMode =
      companyDocsList.filter((doc) => doc.relationType === 'CUSTOM').length > 0 ||
      companyDocsList.find((doc) => doc.records?.filter((record) => record.attaches?.length > 0));

    if (!isUpdateMode) {
      setType('register');
    } else {
      setType('update');
    }
  }, [companyDocsList]);

  return (
    <Container>
      <RouteLeaveGuard
        isChanged={isBlockTab}
        content={
          tabKey === MyDocTabKey.COMPANY && type === 'register'
            ? '서류가 등록되지 않았습니다.\n페이지를 이동하시겠습니까?'
            : '변경 사항이 저장되지 않았습니다.\n페이지를 이동하시겠습니까?'
        }
      />
      <StyledTabs
        defaultActiveKey={tabKey}
        activeKey={tabKey}
        items={[
          {
            key: MyDocTabKey.COMPANY,
            label: '회사 서류',
            children:
              type === 'register' ? (
                <CompanyDocsListRegistration
                  tabKey={tabKey}
                  onChangeIsBlockTab={setIsBlockTab}
                  companyDocsList={companyDocsList}
                  isCompanyDocsListLoading={isCompanyDocsListLoading}
                  qcqaUserDocumentId={qcqaUserDocumentId}
                  isMounted={isMounted}
                  setIsMounted={setIsMounted}
                />
              ) : (
                <CompanyDocsListUpdate
                  onChangeIsBlockTab={setIsBlockTab}
                  tabKey={tabKey}
                  companyDocsList={companyDocsList}
                  isCompanyDocsListLoading={isCompanyDocsListLoading}
                  qcqaUserDocumentId={qcqaUserDocumentId}
                  isMounted={isMounted}
                  setIsMounted={setIsMounted}
                />
              ),
          },
          {
            key: MyDocTabKey.PRODUCT,
            label: '제품 서류',
            children: (
              <ProductDocsList
                companyDocsList={companyDocsList}
                tabKey={tabKey}
                onChangeIsBlockTab={setIsBlockTab}
                isCompanyDocsListLoading={isCompanyDocsListLoading}
              />
            ),
          },
          {
            key: MyDocTabKey.LOT_NO,
            label: '제조번호 서류',
            children: (
              <LotDocsList
                companyDocsList={companyDocsList}
                tabKey={tabKey}
                onChangeIsBlockTab={setIsBlockTab}
                isCompanyDocsListLoading={isCompanyDocsListLoading}
              />
            ),
          },
        ]}
        onTabClick={(key: string) => {
          if (key !== tabKey && isBlockTab) {
            openAlertModal({
              content: '변경사항이 저장되지 않았습니다.\n페이지를 이동하시겠습니까?',
              okText: '이동하기',
              closeText: '취소',
              onOk: () => {
                setTabKey(key);
              },
            });
          } else {
            setTabKey(key);
          }
        }}
      />
    </Container>
  );
};

export default SharedDocsTabs;

import Category from 'components/system/general/Typography/Category';
import Text from 'components/system/general/Typography/Text';
import palette from 'lib/styles/palette';

const Typography = { Text, Category };

export interface TypographyBlockProps {
  type?:
    | 'primary'
    | 'secondary'
    | 'HEADLINE_1'
    | 'HEADLINE_2'
    | 'HEADLINE_3'
    | 'TITLE_1'
    | 'TITLE_2'
    | 'BODY_1'
    | 'BODY_2'
    | 'SMALL'
    | 'CAPTION';
  gutter?: number | { top?: number; bottom?: number; left?: number; right?: number };
  align?: 'left' | 'center' | 'right';
  asterisk?: boolean;
  asteriskSize?: number;
  light?: boolean;
  medium?: boolean;
  semiBold?: boolean;
  bold?: boolean;
  inline?: boolean;
  color?: keyof typeof palette;
  underline?: boolean;
  mark?: boolean;
}

export interface TypographyProps extends TypographyBlockProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
}

export default Typography;

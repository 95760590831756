import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { Document, MadeCountry } from 'types/material/rawMaterial';

export const getDocuments = (materialId: number) =>
  client.get<APIResponse<Document[]>>(`/materials/${materialId}/pre-list`);

export const rawMaterialNameEnDuplicateCheck = (materialNameEn: string) =>
  client.get(`/material/name/duplicate`, {
    params: {
      materialNameEn,
    },
  });

export const getMadeCountries = () => client.get<APIResponse<MadeCountry[]>>('/country-list');

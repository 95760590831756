import { useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { useDetailCategoryInfo } from 'service/material/rawMaterial';
import { useRawMaterialDetail } from 'service/material/rawMaterialDetail';
import CompositionInformations from './CompositionInformations';
import Description from './Description';
import Documents from './Documents';

const RawMaterialDetailViewBlock = styled.div``;

const RawMaterialDetailView = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);
  const { rawMaterialDetail } = useRawMaterialDetail(materialId);
  const { detailCategoryInfo } = useDetailCategoryInfo();
  const remarkCategoryNames = useMemo(() => {
    return (
      (rawMaterialDetail?.materialDetail?.materialRemarks || [])
        .map(
          (remark) =>
            detailCategoryInfo.remarkCategories.find(
              (category) => remark.materialCategoryId === category.materialCategoryId,
            )?.nameKo || null,
        )
        .filter((item) => item !== null)
        .join(', ') || '없음'
    );
  }, [rawMaterialDetail, detailCategoryInfo]);

  if (!rawMaterialDetail)
    return (
      <Spin spinning>
        <RawMaterialDetailViewBlock />
      </Spin>
    );

  const documents =
    rawMaterialDetail.materialFiles?.map(({ documentDisplayName }) => documentDisplayName) || [];

  return (
    <RawMaterialDetailViewBlock>
      <Description
        rawMaterialDetail={rawMaterialDetail}
        remarkCategoryNames={remarkCategoryNames}
      />
      <CompositionInformations
        compositionInformations={rawMaterialDetail.compositionInformations}
      />
      <Documents documents={documents} />
    </RawMaterialDetailViewBlock>
  );
};

export default RawMaterialDetailView;

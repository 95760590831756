import { useRef, useState } from 'react';
import styled from 'styled-components';
import { Select, Col, Form, Input, Row, InputRef } from 'antd';

import * as authApi from 'lib/api/auth';
import { exceptKoreanRule, requireRule, phoneRule, urlRule } from 'lib/validate';
import Typography from 'components/system/general/Typography';
import FileUpload from 'components/system/form/FileUpload';
import { Tip } from 'components/system';
import palette from 'lib/styles/palette';
import { CompanyType } from 'types/company';
import { Flex } from 'components/ui';

const BrandFormBlock = styled.div<{ $visible: boolean }>`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};

  .ant-select-selection-item {
    color: ${palette.GRAY50};
  }

  .previewText {
    visibility: hidden;
  }
`;
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

const BrandForm = ({
  visible,
  bizLicenseEnUrl,
  updateMode,
  brands,
  isMyPage,
}: {
  visible: boolean;
  bizLicenseEnUrl: string;
  updateMode: boolean;
  brands: any;
  isMyPage: boolean;
}) => {
  const [isBizNumberCheckSuccess, setIsBizNumberCheckSuccess] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const handleSelectSearch = (value: string) => {
    setSearchValue(value.replace(/\s/gi, ''));
  };
  const inputRef = useRef<InputRef | null>(null);

  return (
    <BrandFormBlock $visible={visible}>
      {!isMyPage && (
        <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
          1. 회사 정보
        </Typography.Text>
      )}
      <Form.Item
        shouldUpdate={(prev, next) => prev.companyNameKo !== next.companyNameKo}
        label={
          <Row gutter={8} align="middle">
            <Col>
              <Typography.Text type="TITLE_2">회사명 (국문/영문)</Typography.Text>
            </Col>
            {!isMyPage && (
              <Col>
                <Tip trigger="click">
                  <Typography.Text type="SMALL">
                    사업자등록증의 사명과 동일하게 입력하되, 띄어쓰기 없이 입력해 주세요.
                    <br />
                    중복 데이터 또는 오인 표기를 줄이기 위함이므로,
                  </Typography.Text>
                  <Typography.Text type="SMALL" color="PRIMARY50">
                    회사명에 띄어쓰기가 입력되지 않아도 무방합니다.
                  </Typography.Text>
                </Tip>
              </Col>
            )}
          </Row>
        }
        style={{ marginBottom: 0 }}
        required
      >
        {({ getFieldValue, setFieldsValue }) => (
          <Row gutter={8} wrap={false}>
            <Col flex="auto">
              <Form.Item name="companyNameKo" rules={[requireRule]} style={{ marginBottom: 0 }}>
                <Select
                  showSearch
                  searchValue={searchValue}
                  onSearch={handleSelectSearch}
                  placeholder="국문 회사명 검색 또는 선택"
                  notFoundContent={
                    getFieldValue('companyNameKo') !== 'companyNameKoDirect' ? (
                      <Typography.Text
                        style={{ cursor: 'pointer' }}
                        color="GRAY90"
                        type="BODY_2"
                        onClick={() => {
                          setFieldsValue({
                            companyNameKo: 'companyNameKoDirect',
                          });
                        }}
                      >
                        직접입력
                      </Typography.Text>
                    ) : null
                  }
                  onSelect={(value) => {
                    if (value === 'companyNameKoDirect') {
                      setTimeout(() => inputRef?.current?.focus());
                    }
                  }}
                  disabled={updateMode}
                >
                  <Select.Option key="companyNameKoDirect" value="companyNameKoDirect">
                    직접입력
                  </Select.Option>
                  {brands.map((brand: { brandCompanyId: number; companyNameKo: string }) => (
                    <Select.Option key={brand.brandCompanyId} value={brand.companyNameKo}>
                      {brand.companyNameKo}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="0 0 50%">
              <Form.Item
                name={
                  getFieldValue('companyNameKo') !== 'companyNameKoDirect'
                    ? 'companyNameKo'
                    : 'companyNameKoDirect'
                }
                normalize={(value) => value.replace(/\s/gi, '')}
                rules={[requireRule]}
                style={{ marginBottom: 8 }}
              >
                <Input
                  ref={inputRef}
                  disabled={getFieldValue('companyNameKo') !== 'companyNameKoDirect' || updateMode}
                  placeholder={
                    getFieldValue('companyNameKo') === 'companyNameKoDirect'
                      ? '국문 회사명 여기에 입력'
                      : undefined
                  }
                  style={
                    !updateMode
                      ? {
                          color: palette.GRAY90,
                          backgroundColor: palette.ETC_WHITE,
                        }
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.Item>
      <Form.Item name="companyNameEn" rules={[requireRule, exceptKoreanRule]}>
        <Input placeholder="영문 회사명" disabled={updateMode} />
      </Form.Item>
      <Form.Item
        label="사업자등록번호"
        validateStatus={isBizNumberCheckSuccess ? 'success' : undefined}
        help={isBizNumberCheckSuccess ? '사용 가능한 사업자등록번호' : undefined}
        name="bizNumber"
        normalize={(value) => {
          if (value.length > 12) return value.substring(0, 12);
          if (/[^0-9-]/.test(value)) return value.replace(/[^0-9-]/, '');
          if (value.slice(-1) === '-') return value;
          return value
            .replace(/[-]/g, '')
            .replace(/^(\d{3})(\d{1,})/, '$1-$2')
            .replace(/^(\d{3}-\d{2})(\d{1,})/, '$1-$2');
        }}
        rules={[
          requireRule,
          {
            pattern: /^\d{3}-\d{2}-\d{5}$/,
            message: '올바르지 않은 사업자등록번호 형식',
          },
          {
            validator: async (_, bizNumber) => {
              if (updateMode || !/^\d{3}-\d{2}-\d{5}$/.test(bizNumber)) {
                return;
              }
              try {
                await authApi.bizNumberDupCheck({
                  bizNumber,
                  companyType: CompanyType.BRAND,
                });
                setIsBizNumberCheckSuccess(true);
              } catch (e) {
                setIsBizNumberCheckSuccess(false);
                throw new Error('이미 가입되어 있는 사업자등록번호');
              }
              return;
            },
          },
        ]}
      >
        <Input
          disabled={updateMode}
          autoComplete="off"
          onChange={() => setIsBizNumberCheckSuccess(false)}
        />
      </Form.Item>
      <StyledFormItem
        label="사업장 소재지 (국문/영문)"
        name="addressKo"
        required
        rules={[requireRule]}
      >
        <Input placeholder="국문" />
      </StyledFormItem>
      <Form.Item name="addressEn" required={false} rules={[requireRule, exceptKoreanRule]}>
        <Input placeholder="영문 (국가명을 포함한 영문 사업장 소재지)" />
      </Form.Item>
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="대표자 성명 (국문/영문)"
            name="ceoNameKo"
            required
            rules={[requireRule]}
          >
            <Input placeholder="국문" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name="ceoNameEn" required rules={[requireRule, exceptKoreanRule]}>
            <Input placeholder="영문" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="회사 전화번호" name="tel" rules={[requireRule, phoneRule]} required>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="팩스번호 (FAX)" name="fax" rules={[phoneRule]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="홈페이지 URL" name="homepageUrl" rules={[urlRule]}>
        <Input placeholder="예) https://www.certicos.pro" />
      </Form.Item>
      <Flex gap={16} dir={isMyPage ? 'row' : 'column'}>
        <Form.Item
          label={
            <Row gutter={8} align="middle">
              <Col>
                <Typography.Text type="TITLE_2">국문 사업자등록증 (PDF)</Typography.Text>
              </Col>
              {updateMode && (
                <Col>
                  <Tip trigger="click">
                    <Typography.Text type="SMALL">
                      - 사업자등록증의 내용이 기존과 달라졌을 경우, 반드시 수정이
                      <br />
                      필요합니다.
                    </Typography.Text>
                    <Typography.Text type="SMALL">
                      - 수정을 원하시면{' '}
                      <Typography.Text type="SMALL" medium color="PRIMARY50" inline>
                        전화나 하단의 ‘이용 문의’로 문의바랍니다.
                      </Typography.Text>
                    </Typography.Text>
                  </Tip>
                </Col>
              )}
            </Row>
          }
          required
          name="bizLicenseFile"
          rules={[requireRule]}
        >
          <FileUpload readOnly={updateMode} bodyStyle={{ height: 448 }} />
        </Form.Item>
        {!(updateMode && !bizLicenseEnUrl) && (
          <Form.Item
            label={
              <Row gutter={8} align="middle">
                <Col>
                  <Typography.Text type="TITLE_2">영문 사업자등록증 (PDF)</Typography.Text>
                </Col>
                <Col>
                  {!updateMode ? (
                    <Tip trigger="click">
                      <Typography.Text type="SMALL">
                        영문 사업자등록증이 없으신가요?
                        <br />
                        영문 사업자등록증은 인증 진행시 필요한 서류이므로
                        <br />
                        없으신 경우 전화나 하단의 ‘이용 문의’로 문의바랍니다.
                      </Typography.Text>
                    </Tip>
                  ) : (
                    <Tip trigger="click">
                      <Typography.Text type="SMALL">
                        - 사업자등록증의 내용이 기존과 달라졌을 경우, 반드시 수정이
                        <br />
                        필요합니다.
                      </Typography.Text>
                      <Typography.Text type="SMALL">
                        - 수정을 원하시면{' '}
                        <Typography.Text type="SMALL" color="PRIMARY50" inline>
                          전화나 하단의 ‘이용 문의’로 문의바랍니다.
                        </Typography.Text>
                      </Typography.Text>
                    </Tip>
                  )}
                </Col>
              </Row>
            }
            required
            name="bizLicenseEnFile"
            rules={[requireRule]}
          >
            <FileUpload readOnly={updateMode} bodyStyle={{ height: 448 }} />
          </Form.Item>
        )}
      </Flex>
    </BrandFormBlock>
  );
};

export default BrandForm;

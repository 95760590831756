export enum MarketingFileType {
  UPLOAD = 'UPLOAD',
  TEMPLATE = 'TEMPLATE',
}

export enum MarketingDisplayType {
  PUBLIC = 'PUBLIC',
  LIMIT = 'LIMIT',
}

export enum MarketingTemplateStatus {
  TMP = 'TMP',
  COM = 'COM',
  DO = 'DO',
}

export interface MarketingFile {
  materialMarketingDataId: number;
  formType: string;
  displayType: string;
  status: MarketingTemplateStatus;
  attachUrl: string;
  filename: string;
}

export interface Item {
  materialMarketingDataItemId?: number;
  dataTitle?: string;
  itemType?: string;
  attachUrl?: string;
  filename?: string;
  shortDescription?: string;
  description?: string;
  displayType?: MarketingDisplayType;
  templateType?: string;
  uploadFile?: File;
}

export interface Page {
  displayOrder: number;
  templateType: string;
  items: Item[];
}

export interface MarketingTemplateGet {
  files: MarketingFile[];
  publicPages: Page[];
  limitedPages: Page[];
}
export interface UploadFileBasic {
  materialMarketingDataId: number;
  name: string;
  url: string;
}

export interface UploadType {
  materialMarketingDataId: number;
  formType: string;
  displayType: MarketingDisplayType;
  status: string;
  attachUrl: string;
  filename: string;
}

export interface MarketingUploadGet {
  uploadTypes: UploadType[];
}

export interface MarketingFormDataBase {
  pages: Page[];
}

export interface MarketingTemplateFormData extends MarketingFormDataBase {
  displayType: MarketingDisplayType;
  status: MarketingTemplateStatus;
}

export type MarketingTemplateType = 'A' | 'B' | 'C';

export type MarketingUploadType = File | UploadFileBasic | number | null;

import AllergensList from 'components/material/rawMaterial/AllergensList';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const notices = [
  '알러지 유발 성분이 없는 경우, 알러지 프리를 체크해 주세요.',
  '알러지 유발 성분이 있는 경우, 알러지 유발 성분 있음을 선택하여 해당하는 알러지 성분의 실제 함유량을 입력해 주세요.',
  <MistakeNoticeItem />,
];

const RawMaterialAllergensListPage = () => {
  return (
    <RawMaterialPageTemplate documentName="Allergens List" notices={notices} back>
      <AllergensList />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialAllergensListPage;

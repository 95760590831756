import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Form, Input, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Typography } from 'components/system';
import NoticeCard from 'components/system/data/NoticeCard';
import FooterBox from 'components/FooterBox';
import ContractAccount from 'components/contract/ContractAccount';
import ContractDocuments from 'components/contract/ContractDocuments';
import { RootState } from 'modules';
import { ICIDEstimateParam } from 'types/material/icid/icid';
import { useICIDContract } from 'service/material/icid/icid';
import CheckBoxCard from 'components/system/data/CheckBoxCard';
import { CompanyType } from 'types/company';

const Container = styled.div``;

const ICIDContract = ({ icidApplicationId }: { icidApplicationId: number }) => {
  const { addICIDContract, isLoading } = useICIDContract(icidApplicationId);
  const { estimate, company } = useSelector(
    ({ estimate, company }: RootState) => ({
      company: company.company as { addressKo: string } | null,
      estimate: estimate.estimate as ICIDEstimateParam | null,
    }),
    shallowEqual,
  );
  const [address, setAddress] = useState(company?.addressKo);
  const [isChecked, setIsChecked] = useState(false);

  const handleContract = () => {
    Modal.confirm({
      styles: {
        body: {
          padding: 24,
          textAlign: 'center',
        },
      },
      width: 400,
      icon: null,
      content: (
        <Typography.Text type="BODY_2" gutter={{ bottom: 12 }}>
          '계약 체결' 버튼을 클릭하시면,
          <br />
          해당 원료는 입금 및 계약서 수령 대기 단계로 넘어가며,
          <br />
          '등재 중 원료 관리' 메뉴에서 추가 정보를 입력하실 수 있습니다.
          <br />
          <br />
          해당 원료의 ICID 등재 계약을 체결하시겠습니까?
        </Typography.Text>
      ),
      okText: '계약 체결',
      onOk: () => {
        addICIDContract({
          icidApplicationEstimateId: estimate?.materialIcidApplicationEstimateId,
          postAddress: address,
          isAgreeContract: isChecked,
        });
      },
    });
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Container>
      <ContractDocuments />
      <ContractAccount />
      <NoticeCard
        title="계약서 받으실 주소"
        titleStyle={{ marginBottom: 16 }}
        style={{ marginTop: 24 }}
        bodyStyle={{ paddingLeft: 24 }}
      >
        <ul>
          <li>
            <Typography.Text type="BODY_2">
              송부해 주실 계약서 2부 중 1부를 certicos에서 확인하고 날인한 뒤, 귀사로 다시 송부해
              드릴 예정이오니 계약서 받으실 주소를 입력해 주세요.
            </Typography.Text>
          </li>
          <li>
            <Typography.Text type="BODY_2">
              가입 시 입력한 사업장 소재지 주소가 기본으로 입력되어 있으니, 다른 곳으로 받으실 경우
              하단의 주소를 수정한 후 ‘계약 체결’ 버튼을 눌러주세요.
            </Typography.Text>
          </li>
          <Form.Item
            colon={false}
            label={<Typography.Text type="TITLE_2">주소</Typography.Text>}
            style={{
              marginTop: 16,
              marginBottom: 8,
              position: 'relative',
              left: -12,
            }}
          >
            <Input value={address} onChange={(e) => setAddress(e.target.value)} />
          </Form.Item>
        </ul>
      </NoticeCard>
      <CheckBoxCard companyType={CompanyType.MATERIAL} onChange={onChange} />
      <FooterBox>
        <Button
          key="contract"
          type="primary"
          size="large"
          loading={isLoading}
          onClick={handleContract}
          disabled={!isChecked}
        >
          계약 체결
        </Button>
      </FooterBox>
    </Container>
  );
};

export default ICIDContract;

import qs from 'qs';

import client from 'lib/api/client';
import { BuyerUpdate, BuyerAdd } from 'types/buyer';
import { APIPageableResponse, APIResponse } from 'types/common';
import { Buyer, ProductBuyer } from 'types/brand/buyer';

export const getBuyers = (companyId: number) => client.get(`/buyers/${companyId}`);
export const getBuyersByCountryCode = (params: { companyId: number; countryCode: string }) =>
  client.get<APIResponse<Buyer[]>>(`/buyers/code/${params.companyId}/${params.countryCode}`);
export const addBuyer = (buyer: BuyerAdd) => client.post('/buyer', buyer);
export const updateBuyer = (buyer: BuyerUpdate) => client.patch('/buyer', buyer);
export const deleteBuyers = (buyerIds: number[]) =>
  client.delete(`/buyers?${qs.stringify({ buyerIds }, { indices: false })}`);
export const getProductBuyers = async (
  params: { productId: number; countryId: number } | { companyId: number; buyerId: number },
) => {
  if ('productId' in params) {
    return client.get<APIResponse<ProductBuyer[]>>(
      `/product-buyers/${params.productId}/${params.countryId}`,
    );
  } else {
    const res = await client.post<APIPageableResponse<ProductBuyer[]>>(
      `/product-buyer/1/1000`,
      params,
    );
    return { ...res, data: { ...res.data, result: res.data.result.content } };
  }
};
export const addProductBuyers = (productBuyers: {
  productId: number;
  buyerIds: number[];
  deleteProductBuyerIds: number[];
}) => client.post(`/product-buyers`, productBuyers);

import styled, { css } from 'styled-components';

import { deviceSize } from 'lib/styles';
import COLOR from './colors';

type TypoStyle = {
  color?: keyof typeof COLOR;
  inline?: boolean;
  bold?: boolean;
};

export const H1 = styled.span<TypoStyle>`
  display: block;
  font-size: 50px;
  font-weight: 700;
  line-height: 66px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  @media ${deviceSize.sm} {
    font-size: 28px;
    line-height: 38px;
  }

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const LogoText = styled.span`
  font-size: 56px;
  line-height: 66px;
  font-weight: 700;

  @media ${deviceSize.sm} {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const H2 = styled.span<TypoStyle>`
  display: block;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const H3 = styled.span<TypoStyle>`
  display: block;
  font-size: 26px;
  font-weight: 400;
  line-height: 38px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  @media ${deviceSize.sm} {
    font-size: 18px;
    line-height: 22px;
  }

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const H4 = styled.span<TypoStyle>`
  display: block;
  font-size: 22px;
  color: ${(props) => props.color && COLOR[props.color]};
  line-height: 34px;
  margin-bottom: 0;
  font-weight: 500;

  @media ${deviceSize.sm} {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    ${({ bold }) => bold && `font-weight: bold;`}
  }

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const H5 = styled.span<TypoStyle>`
  display: block;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  @media ${deviceSize.sm} {
    font-size: 16px;
    line-height: 26px;
  }

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const Body1 = styled.span<TypoStyle>`
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const Body2 = styled.span<TypoStyle>`
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  @media ${deviceSize.sm} {
    font-size: 14px;
    line-height: 22px;
  }

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const Body3 = styled.span<TypoStyle>`
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const Body4 = styled.span<TypoStyle>`
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const Body5 = styled.span<TypoStyle>`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${(props) => props.color && COLOR[props.color]};
  margin-bottom: 0;

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ bold }) => bold && `font-weight: bold;`}
`;

export const Button = styled.button<{
  invert?: boolean;
}>`
  width: 234px;
  text-align: center;
  padding: 18px 0px;
  border-radius: 30px;
  background-color: ${COLOR.C_MAIN};
  border: none;
  color: ${COLOR.WHITE};
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    background-color: ${({ invert }) => (invert ? '#E8E0E0' : '#a90f15')};
    transition: all 150ms ease-in-out;
  }

  ${({ invert }) =>
    invert &&
    css`
      background-color: ${COLOR.WHITE};
      color: ${COLOR.C_MAIN};
      border: 1px solid ${COLOR.C_MAIN};
    `}

  @media ${deviceSize.sm} {
    width: 184px;
    font-size: 14px;
    height: 46px;
    padding: 0;
  }
`;

import { Empty } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Typography } from 'components/system';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { ProductDocument } from 'types/brand/retailer/retailerApply';

const Container = styled.div`
  .highlight {
    background: ${palette.LIGHT_BLUE20};
  }
`;

const PrivateDocumentTable = ({
  privateDocuments,
  productId,
  highlightIds = [],
  hasSelectedId,
}: {
  privateDocuments: ProductDocument[];
  productId: number;
  highlightIds?: number[];
  hasSelectedId: boolean;
}) => {
  const navigate = useNavigate();
  const columns: ColumnsType<ProductDocument> = [
    {
      title: '구분',
      align: 'center',
      width: 70,
      render: (_, __, index) => <Typography.Text type="BODY_2">{index + 1}</Typography.Text>,
    },
    {
      title: '입력정보',
      align: 'left',
      dataIndex: 'documentName',
      render: (documentName, { documentCode }) => (
        <Typography.Text
          type="BODY_2"
          color="GRAY90"
          style={{ cursor: 'pointer', width: 344 }}
          medium
          onClick={() =>
            navigate(
              `${
                path.retailer.retailerApply.product[
                  documentCode as keyof typeof path.retailer.retailerApply.product
                ]
              }/${productId}`,
            )
          }
        >
          {documentName}
        </Typography.Text>
      ),
    },
    {
      title: '처리현황',
      align: 'center',
      width: 120,
      dataIndex: 'status',
      render: (_, document) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(
              `${
                path.retailer.retailerApply.product[
                  document.documentCode as keyof typeof path.retailer.retailerApply.product
                ]
              }/${productId}`,
            )
          }
        >
          {document.retailerApplyDocStatus.status === 'FIN' ? (
            <Typography.Text type="BODY_2">입력 완료</Typography.Text>
          ) : (
            <Tags.Mark type="arrow" color="blue" style={{ cursor: 'pointer' }}>
              {document.retailerApplyDocStatus.status === 'INP' ? '필수 입력' : '선택 입력'}
            </Tags.Mark>
          )}
        </div>
      ),
    },
    {
      title: '최근 입력자명',
      align: 'center',
      width: 200,
      render: (_, document) => (
        <Typography.Text type="BODY_2">
          {document.retailerApplyDocStatus.name
            ? `${document.retailerApplyDocStatus.name}${
                document.retailerApplyDocStatus.companyName
                  ? ` (${document.retailerApplyDocStatus.companyName})`
                  : ''
              }`
            : '-'}
        </Typography.Text>
      ),
    },
    {
      title: '입력일시',
      align: 'center',
      width: 160,
      render: (_, document) => (
        <Typography.Text type="BODY_2">
          {document.retailerApplyDocStatus.registerDt || '-'}
        </Typography.Text>
      ),
    },
  ];
  return (
    <Container>
      <Table
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                hasSelectedId
                  ? '해당 유통사는 회사 공통 서류만으로 입점이 가능합니다.'
                  : '선택하신 유통사가 없습니다.'
              }
            />
          ),
        }}
        rowClassName={({ retailerApplyDocSeqId }) =>
          highlightIds.includes(retailerApplyDocSeqId) ? 'highlight' : ''
        }
        columns={columns}
        dataSource={privateDocuments}
        pagination={false}
        rowKey="retailerApplyDocSeqId"
      />
    </Container>
  );
};

export default PrivateDocumentTable;

import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import NMPAContract from 'components/material/nmpa/NMPAContract';
import PageTemplate from 'templates/PageTemplate';
import EstimatePayment from 'components/estimate/EstimatePayment';
import { NMPAContractStatus } from 'types/material/nmpa/nmpa';

const NMPAContractPage = () => {
  const location = useLocation();
  const params = useParams<{ materialNmpaEstimateId: string }>();

  const materialNmpaEstimateId = Number(params.materialNmpaEstimateId);
  const { status } = (location.state as { status: NMPAContractStatus }) || {
    status: NMPAContractStatus.ISS_FIN,
  };

  const [contractStep, setContractStep] = useState<'contract' | 'payment'>(
    status === NMPAContractStatus.CON_FIN ? 'payment' : 'contract',
  );

  return (
    <PageTemplate
      back={false}
      title={contractStep === 'contract' ? '계약 체결' : undefined}
      description={
        contractStep === 'contract' ? '아래 내용을 확인 후 계약을 체결해 주세요.' : undefined
      }
    >
      {contractStep === 'contract' && (
        <NMPAContract
          materialNmpaEstimateId={materialNmpaEstimateId}
          onChangeContractStep={setContractStep}
        />
      )}
      {contractStep === 'payment' && <EstimatePayment isNMPAEstimate={true} />}
    </PageTemplate>
  );
};

export default NMPAContractPage;

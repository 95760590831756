import { Col, Divider, Layout } from 'antd';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { deviceSize, size } from 'lib/styles';
import Typography from 'components/system/general/Typography';
import Logo from 'components/system/general/icon/Logo';
import palette from 'lib/styles/palette';
import path from 'lib/path';
import { Flex } from 'components/ui';

const noFooterPathnames = ['/artwork-screening'];

const VerticalDivider = ({ color = 'GRAY90' }: { color?: keyof typeof palette }) => {
  return (
    <Divider
      type="vertical"
      style={{
        borderLeft: `1px solid ${palette[color]}`,
        height: 13,
        margin: 0,
      }}
    />
  );
};

const Container = styled(Layout.Footer)`
  position: relative;
  border-top: 1px solid #deded3;
  background-color: #fff;
  padding: 36px 32px 42px;
  overflow: hidden;

  @media ${deviceSize.sm} {
    margin: 0;
    padding: 36px 24px;
  }
`;

const FooterBlock = styled(Flex)`
  gap: 98px;
  justify-content: space-between;
  align-items: center;
  max-width: ${size.layout.footerMax};
  margin: 0 auto;

  @media ${deviceSize.sm} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const LogoWrapper = styled.div`
  flex: 0 0 166px;
  height: 32px;

  @media ${deviceSize.sm} {
    flex: 0 0 32px;
  }
`;

const CollapsibleCol = styled(Col)`
  @media ${deviceSize.sm} {
    display: none;
  }
`;

const MarginCol = styled(Col)`
  @media ${deviceSize.sm} {
    margin-top: 4px;
  }
`;

const Footer = () => {
  const pathname = useLocation().pathname;
  const isNoDisplay = noFooterPathnames.some((name) => pathname.startsWith(name));

  if (isNoDisplay) return null;

  return (
    <Container>
      <FooterBlock>
        <LogoWrapper>
          <Logo isGrayscale />
        </LogoWrapper>
        <div>
          <Flex columnGap={16} rowGap={8} align="center" wrap="true">
            <a href="https://www.cdri.pro" target="_blank" rel="noreferrer">
              <Typography.Text type="BODY_2" color="GRAY90">
                회사소개
              </Typography.Text>
            </a>
            <VerticalDivider />
            <Link to={path.terms.service}>
              <Typography.Text bold={pathname === path.terms.service} type="BODY_2" color="GRAY90">
                이용약관
              </Typography.Text>
            </Link>
            <VerticalDivider />
            <Link to={path.terms.privacyProcess}>
              <Typography.Text
                bold={pathname === path.terms.privacyProcess}
                type="BODY_2"
                color="GRAY90"
              >
                개인정보 처리방침
              </Typography.Text>
            </Link>
            <VerticalDivider />
            <Link to={path.adInquiry}>
              <Typography.Text bold={pathname === path.adInquiry} type="BODY_2" color="GRAY90">
                광고 및 제휴 문의
              </Typography.Text>
            </Link>
            <VerticalDivider />
            <Link to={path.userInquiry}>
              <Typography.Text bold={pathname === path.userInquiry} type="BODY_2" color="GRAY90">
                이용 문의
              </Typography.Text>
            </Link>
            <VerticalDivider />
            <Link to={path.service}>
              <Typography.Text bold={pathname === path.service} type="BODY_2" color="GRAY90">
                고객센터
              </Typography.Text>
            </Link>
          </Flex>
          <Flex gap={6} style={{ marginTop: 16 }} align="center" wrap="true">
            <Typography.Text type="SMALL" color="SLATE_GRAY70">
              주식회사 씨디알아이
            </Typography.Text>
            <VerticalDivider color="SLATE_GRAY70" />
            <Typography.Text type="SMALL" color="SLATE_GRAY70">
              대표: 김준일
            </Typography.Text>
            <VerticalDivider color="SLATE_GRAY70" />
            <Typography.Text type="SMALL" color="SLATE_GRAY70">
              사업자 등록번호: 673-86-01908
            </Typography.Text>
            <CollapsibleCol>
              <VerticalDivider color="SLATE_GRAY70" />
            </CollapsibleCol>
            <MarginCol xs={{ flex: '0 0 100%' }} sm={{ flex: '0 0 auto' }}>
              <Flex gap={6} align="center">
                <Typography.Text type="SMALL" color="SLATE_GRAY70">
                  통신판매업신고번호: 제2020-충북청주-2866호
                </Typography.Text>
                <VerticalDivider color="SLATE_GRAY70" />
                <Typography.Text type="SMALL" color="SLATE_GRAY70">
                  대표 번호: 02-6225-5253
                </Typography.Text>
              </Flex>
            </MarginCol>
          </Flex>
          <Flex gap={6} style={{ marginTop: 4 }} align="center" wrap="true">
            <Typography.Text type="SMALL" color="SLATE_GRAY70">
              본사: 충북 청주시 흥덕구 오송읍 오송생명 1로 194-25, SB플라자 3층 A-13호 (28160)
            </Typography.Text>
            <CollapsibleCol>
              <VerticalDivider color="SLATE_GRAY70" />
            </CollapsibleCol>
            <MarginCol xs={{ flex: '0 0 100%' }} sm={{ flex: '0 0 auto' }}>
              <Typography.Text type="SMALL" color="SLATE_GRAY70">
                지사: 서울 강남구 논현로 417 화원빌딩 가든층 A (06246)
              </Typography.Text>
            </MarginCol>
          </Flex>
          <Typography.Text type="SMALL" color="SLATE_GRAY70" gutter={{ top: 4 }}>
            Copyright © CDRI CO., LTD. All Rights Reserved.
          </Typography.Text>
        </div>
      </FooterBlock>
    </Container>
  );
};

export default Footer;

import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useModal } from 'hook/useModal';

const RouteLeaveGuard = ({
  isChanged = false,
  closeText = '취소',
  okText = '이동하기',
  content = '변경 사항이 저장되지 않았습니다.\n페이지를 이동하시겠습니까?',
}: {
  isChanged: boolean;
  closeText?: string;
  okText?: string;
  content?: string;
}) => {
  let blocker = useBlocker(() => {
    return isChanged;
  });
  const { openAlertModal } = useModal();

  useEffect(() => {
    if (isChanged) {
      const preventLeave = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        const a = document.activeElement as HTMLAnchorElement | null;
        if ((a?.href || '').endsWith('.xlsx')) {
          a?.blur();
          return;
        }
        e.returnValue = '';
        return false;
      };
      window.addEventListener('beforeunload', preventLeave);
      return () => {
        window.removeEventListener('beforeunload', preventLeave);
      };
    } else {
      blocker.proceed?.();
    }
  }, [isChanged]);

  useEffect(() => {
    if (isChanged && blocker.state === 'blocked') {
      openAlertModal({
        content,
        okText,
        closeText,
        onClose: () => {
          blocker.reset?.();
        },
        onOk: () => {
          blocker.proceed?.();
        },
      });
    }
  }, [blocker, isChanged]);

  return null;
};

export default RouteLeaveGuard;

import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import {
  DocumentCode,
  ProductDocument,
  ProductDocumentStatusCode,
} from 'types/brand/retailer/retailerApply';

const Container = styled.div<{ highlight: boolean }>`
  ${({ highlight }) =>
    highlight &&
    css`
      .ant-table-wrapper table tr {
        background: ${palette.LIGHT_BLUE20};
      }
    `}
`;

const SharedDocumentTable = ({
  sharedDocuments,
  productId,
  isNeedOBD,
  highlight = false,
}: {
  sharedDocuments: ProductDocument[];
  productId: number;
  isNeedOBD: boolean;
  highlight?: boolean;
}) => {
  const navigate = useNavigate();
  const getStatusTitle = (isOBD: boolean) => {
    if (isOBD) {
      return isNeedOBD ? '필수 입력' : '선택 입력';
    } else {
      return '필수 입력';
    }
  };

  const columns: ColumnsType<ProductDocument> = [
    {
      title: '구분',
      align: 'center',
      width: 70,
      render: (_, __, index) => <Typography.Text type="BODY_2">{index + 1}</Typography.Text>,
    },
    {
      title: '입력정보',
      align: 'left',
      dataIndex: 'documentName',
      width: '60%',
      render: (documentName, { documentCode }) => (
        <Typography.Text
          type="BODY_2"
          color="GRAY90"
          style={{ cursor: 'pointer' }}
          medium
          onClick={() =>
            navigate(
              `${
                path.retailer.retailerApply[
                  documentCode as keyof typeof path.retailer.retailerApply
                ]
              }/${productId}`,
            )
          }
        >
          {documentName}
        </Typography.Text>
      ),
    },
    {
      title: '처리현황',
      align: 'center',
      dataIndex: 'status',
      render: (_, document) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                `${
                  path.retailer.retailerApply[
                    document.documentCode as keyof typeof path.retailer.retailerApply
                  ]
                }/${productId}`,
              )
            }
          >
            {document.retailerApplyDocStatus.status === ProductDocumentStatusCode.FIN ? (
              <Typography.Text type="BODY_2">입력 완료</Typography.Text>
            ) : (
              <Tags.Mark
                color="blue"
                type="arrow"
                onClick={() =>
                  navigate(
                    `${
                      path.retailer.retailerApply[
                        document.documentCode as keyof typeof path.retailer.retailerApply
                      ]
                    }/${productId}`,
                  )
                }
              >
                {getStatusTitle(document.documentCode === DocumentCode.OBD)}
              </Tags.Mark>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <Container highlight={highlight}>
      <Table
        columns={columns}
        dataSource={sharedDocuments}
        pagination={false}
        rowKey="retailerApplyDocSeqId"
      />
    </Container>
  );
};

export default SharedDocumentTable;

import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';

const Navigator = ({
  activeStep,
}: {
  activeStep: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const steps = [
    {
      id: 1,
      title: '입력 방식 선택',
    },
    {
      id: 2,
      title: '국가, 지역, 스토어 선택',
    },
    {
      id: 3,
      title: '결과 확인',
    },
  ];

  return (
    <NavigatorContainer>
      {steps.map(({ id, title }) => {
        const isActive = activeStep === id;
        const isCheckedStep = activeStep > id;
        return (
          <StepDiv key={id} align="center" gap={8} active={isActive}>
            {!isActive && isCheckedStep && <Icon name="checkCircleHover" size={16} />}
            {!isCheckedStep && <NumIcon active={isActive}>{id}</NumIcon>}
            <StepTitle active={isActive}>{title}</StepTitle>
          </StepDiv>
        );
      })}
    </NavigatorContainer>
  );
};

const NavigatorContainer = styled.div`
  width: 216px;
  border-right: 1px solid ${palette.GRAY30};
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  padding: 136px 16px;
`;

const StepTitle = styled(Typography.Text)<{ active: boolean }>`
  font-size: 14px;
  font-weight: ${({ active }) => (active ? `500` : `400`)};
  text-align: center;
  color: ${({ active }) => (active ? `${palette.SLATE_GRAY70}` : `${palette.SLATE_GRAY50}`)};
`;

const NumIcon = styled.div<{ active: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) =>
    active ? `${palette.PRIMARY50}` : `${palette.SLATE_GRAY50}`};
  border-radius: 100px;
  font-size: 12px;
  color: white;
`;

const StepDiv = styled(Flex)<{ active: boolean }>`
  padding: 18px 16px;
  border-radius: 4px;
  ${({ active }) => css`
    background-color: ${active ? `${palette.SLATE_GRAY10}` : `transparent`};
  `};
`;

export default Navigator;

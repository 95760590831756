import { Button, Modal, Spin, Typography, message } from 'antd';
import { useEffect, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';

import { ReactComponent as TooltipArrow } from 'asset/svg/tooltip_arrow.svg';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { Flex } from 'components/ui';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import { useModal } from 'hook/useModal';
import { getResponseHeaderValueByKey } from 'lib/file';
import palette from 'lib/styles/palette';
import { useProviderForms } from 'service/brand/certificate/certificate';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { useCountryNameKo } from 'service/country';
import { useProductDocStatus } from 'service/product';
import { useGuideDetail } from 'service/service';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { DocStatus, DocumentCode } from 'types/product';

const ProductCFSPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const countryName = useCountryNameKo(countryId!);

  const { openAlertModal } = useModal();
  const { providerForms, cfsFileData, isCFSFileDataLoading } = useProviderForms({
    productId,
    countryId: countryId,
  });

  const { productDocStatus: docStatus } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.CFS,
  });
  const isChina = countryName === '중국';
  const { guideDetail } = useGuideDetail(isChina ? 2 : 1);

  useEffect(() => {
    if (docStatus?.status === DocStatus.INP) {
      openAlertModal({
        content: '동시 진행 중인 제품이 있는 경우\nCDRI 담당자에게 문의해 주시기 바랍니다.',
        noCancelButton: true,
      });
    }
  }, []);

  const openGuideModal = () => {
    if (guideDetail) {
      Modal.info({
        width: 1000,
        icon: null,
        closable: true,
        title: guideDetail?.title,
        content: (
          <ModalContent
            style={{ height: 500, overflow: 'scroll' }}
            dangerouslySetInnerHTML={{ __html: guideDetail?.content }}
          ></ModalContent>
        ),
      });
    }
  };

  const notices = [
    <Typography.Text style={{ padding: 0 }}>
      아래 내용을 참고해 대한화장품협회 사이트에서{' '}
      <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={openGuideModal}>
        CFS(자유판매증명서)를 발급
      </span>
      하여 업로드해 주세요. <br />
      재발급 비용은 certicos에서 책임드릴 수 없는 점 주의해 주세요.
    </Typography.Text>,
    <MistakeNoticeItem />,
  ];

  const getFormName = (name: string) => {
    switch (name) {
      case '0':
        return '제조사명';
      case '1':
        return '제조사 주소';
      case '2':
        return '브랜드사 주소';
      case '3':
        return '브랜드사명';
      case '4':
        return '중문 제품명';
      case '5':
        return '영문 제품명';
    }
  };

  const providerFormMap = useMemo(() => {
    const newMap: Record<string, string> = {};
    if (providerForms) {
      Object.entries(providerForms).forEach(([key, value]) => {
        switch (key) {
          case 'manufacturerName':
            return (newMap[0] = value);
          case 'manufacturerAddress':
            return (newMap[1] = value);
          case 'brandAddress':
            return (newMap[2] = value);
          case 'brandName':
            return (newMap[3] = value);
          case 'productNameCn':
            return (newMap[4] = value);
          case 'productNameEn':
            return (newMap[5] = value);
        }
      });
    }
    return newMap;
  }, [providerForms]);

  const handleOpenCFS = () => {
    cfsFileData(
      {
        productId,
        countryId,
      },
      {
        onSuccess: (res) => {
          let file = new Blob([res.data], {
            type: 'application/pdf',
          });

          const filenameFromHeader = getResponseHeaderValueByKey({
            target: res.headers['content-disposition'],
            key: 'filename',
          });

          const url = URL.createObjectURL(file);

          window.open(url, filenameFromHeader);
        },
      },
    );
  };

  return (
    <ProductPageTemplate
      subtitle="CFS"
      notices={notices}
      exampleImg={!isChina ? 'CFS.png' : ''}
      exampleImgWidth={320}
    >
      {isChina && (
        <CFSGuideContainer dir="column">
          <Flex dir="column" gap={4}>
            <Flex align="center" gap={8}>
              <div
                style={{
                  backgroundColor: palette.PRIMARY50,
                  width: 4,
                  height: 4,
                }}
              />
              <Typography.Text
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: palette.SLATE_GRAY70,
                }}
                className="description"
              >
                대한화장품협회에서 CFS(자유판매증명서) 발급 시 아래 내용으로 발급해 주세요.
              </Typography.Text>
            </Flex>
            <Typography.Text
              style={{
                color: palette.SLATE_GRAY60,
                fontSize: 12,
                marginLeft: 14,
              }}
            >
              클릭하여 복사할 수 있습니다.
            </Typography.Text>
          </Flex>
          <Spin
            spinning={Object.entries(providerFormMap).length === 0}
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 872,
              alignItems: 'center',
            }}
          >
            <ContentContainer dir="column" gap={4}>
              {Object.entries(providerFormMap).map(([key, value], index) => {
                return (
                  <CopyToClipboard
                    text={value}
                    key={index}
                    onCopy={() => message.success('Ctrl+V 하여 붙여넣기 할 수 있습니다.')}
                  >
                    <StyledFlex key={index}>
                      {getFormName(key)}
                      <StyledText>
                        {value}
                        <CopyTooltip justify="center" align="cener">
                          클릭하여 복사
                          <TooltipArrow style={{ position: 'absolute', top: '100%' }} />
                        </CopyTooltip>
                      </StyledText>
                    </StyledFlex>
                  </CopyToClipboard>
                );
              })}
            </ContentContainer>
          </Spin>
          <ButtonContainer align="center" gap={8} justify="center">
            <StyledButton onClick={openGuideModal}>발급 가이드</StyledButton>
            <StyledButton loading={isCFSFileDataLoading} onClick={handleOpenCFS}>
              발급될 CFS 미리보기
            </StyledButton>
          </ButtonContainer>
        </CFSGuideContainer>
      )}
      <div
        style={{
          fontSize: 24,
          fontWeight: 400,
          color: palette.GRAY90,
          marginBottom: 24,
        }}
      >
        발급한 CFS(자유판매증명서) 업로드
      </div>
      <ProductSingleFileContainer documentCode={DocumentCode.CFS} accept=".pdf, image/*" />
    </ProductPageTemplate>
  );
};

const CFSGuideContainer = styled(Flex)`
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 24px;
  position: relative;
  margin-bottom: 36px;
  min-height: 378px;
`;

const ButtonContainer = styled(Flex)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 176px;
`;

const ContentContainer = styled(Flex)`
  padding: 16px 0;
  min-height: 232px;
`;

const StyledFlex = styled(Flex)`
  gap: 16px;
  color: ${palette.SLATE_GRAY70};
  font-weight: 500;
  align-items: center;
`;

const StyledText = styled(Typography.Text)`
  display: flex;
  color: ${palette.PRIMARY50};
  font-size: 14px;
  position: relative;
  transition: all 100ms ease;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${palette.PRIMARY30};
    border-radius: 30px;
  }

  &:active {
    background-color: ${palette.PRIMARY40};
  }
`;

const CopyTooltip = styled(Flex)`
  background-color: ${palette.SLATE_GRAY70};
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  color: ${palette.ETC_WHITE};
  padding: 5.5px 8.25px;
  display: none;
  position: absolute;
  width: 74.5px;
  top: -32px;
  transition: background-color 300ms ease;
  opacity: 0.9;
  left: 50%;
  transform: translateX(-50%);

  &:target {
    background-color: ${palette.PRIMARY40};
  }

  ${StyledText}:hover & {
    display: flex;
  }
`;

const ModalContent = styled.div`
  img {
    width: 100%;
  }
`;

export default ProductCFSPage;

import { Company } from 'types/company';

export interface BrandCompany extends Company {
  brandCompanyId: number;
}

export enum CompanyDocsUserType {
  COMPANY = 'COMPANY',
  PRODUCT = 'PRODUCT',
  LOT_NO = 'LOT_NO',
}

export interface CompanyDocsAttach {
  qcqaDocumentRecordAttachId: number;
  attachId: number;
  filename: string;
  uploadFileUrl: string;
  registerDt: string;
}

export interface CompanyDocsRecord {
  qcqaDocumentRecordId?: number;
  name: string;
  recordDate: string;
  attaches: CompanyDocsAttach[];
}

export interface CompanyDocsListParams {
  qcqaUserDocumentId?: number;
  name: string;
  isDisplay: boolean;
  displayOrder: number;
  records?: {
    qcqaDocumentRecordId?: number;
    name: string;
    recordDate: string;
    attaches: number[];
  }[];
}
export interface CompanyDocsList {
  qcqaUserDocumentId: number;
  relationType: 'BASIC' | 'CUSTOM';
  name: string;
  code?: string;
  useType: CompanyDocsUserType;
  isDisplay: true;
  isForCertification: boolean;
  displayOrder: number;
  registerDt: string;
  records?: CompanyDocsRecord[];
  description?: string;
}

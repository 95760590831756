import { Dispatch, SetStateAction } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { requireRule } from 'lib/validate';
import { VCRPAccountParam } from 'types/brand/vcrp';
import { Typography } from 'components/system';
import { useAddVCRPAccount, useUpdateVCRPAccount } from 'service/brand/product/vcrp';

const VCRPAccountAddModalContainer = styled.div`
  .ant-modal-body {
    padding: 24px;
  }
`;

const VCRPAccountAddModal = ({
  isModalVisible,
  setIsModalVisible,
  vcrpAccount,
}: {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  vcrpAccount: VCRPAccountParam | null;
}) => {
  const { addVCRPAccount } = useAddVCRPAccount();
  const { updateVCRPAccount } = useUpdateVCRPAccount();

  const handleSubmit = (formValues: Omit<VCRPAccountParam, 'vcrpAccountId'>) => {
    if (vcrpAccount === null) {
      addVCRPAccount(formValues, { onSuccess: () => setIsModalVisible(false) });
    } else if (
      formValues.vcrpAccount !== vcrpAccount.vcrpAccount ||
      formValues.vcrpPassword !== vcrpAccount.vcrpPassword
    ) {
      updateVCRPAccount(
        {
          vcrpAccountId: vcrpAccount.vcrpAccountId,
          vcrpAccount: formValues.vcrpAccount,
          vcrpPassword: formValues.vcrpPassword,
        },
        { onSuccess: () => setIsModalVisible(false) },
      );
    } else {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }
  };

  return (
    <VCRPAccountAddModalContainer>
      <Modal
        open={isModalVisible}
        destroyOnClose
        footer={null}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        centered
        width={528}
      >
        <Typography.Text type="TITLE_1" style={{ marginBottom: 4 }}>
          계정 정보 {vcrpAccount === null ? '입력' : '변경'}
        </Typography.Text>
        <Typography.Text inline type="BODY_2">
          {vcrpAccount === null ? (
            <>
              <Typography.Text inline type="BODY_2" style={{ fontWeight: 500 }}>
                VCRP
              </Typography.Text>{' '}
              등록을 신청하신 모든 제품에 입력하신 계정정보가 반영됩니다.
            </>
          ) : (
            <>
              변경시,{' '}
              <Typography.Text inline type="BODY_2" style={{ fontWeight: 500 }}>
                VCRP
              </Typography.Text>{' '}
              등록을 신청하신 모든 제품에 변경된 계정정보가 반영됩니다.
            </>
          )}
        </Typography.Text>
        <Form
          style={{ marginTop: 32 }}
          layout="vertical"
          scrollToFirstError={scrollToFirstErrorOption}
          onFinish={handleSubmit}
        >
          <Form.Item label="사용자명 (ID)" name="vcrpAccount" rules={[requireRule]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="비밀번호"
            name="vcrpPassword"
            rules={[
              requireRule,
              {
                pattern: /^.{3,35}$/i,
                message: '3~35자',
              },
            ]}
          >
            <Input maxLength={35} />
          </Form.Item>
          <FooterBox style={{ paddingTop: 32 }}>
            <Button type="primary" htmlType="submit">
              {vcrpAccount === null ? '입력' : '변경'}
            </Button>
          </FooterBox>
        </Form>
      </Modal>
    </VCRPAccountAddModalContainer>
  );
};

export default VCRPAccountAddModal;

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import SockJS from 'sockjs-client';
import StompJS from 'stompjs';
import parse from 'html-react-parser';
import { notification } from 'antd';

import { toggleDrawer } from 'modules/navigator';
import path from 'lib/path';
import { getReactEnvVar } from 'lib/common';
import { addUserNotice, getUserNotices, setUserNoticeDrawerVisible } from 'modules/service';
import TopSubMenu from 'components/menu/TopSubMenu';

const TopSubMenuContainer = ({ inlineMode = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, newUserNoticeCount } = useSelector(
    ({ auth, service }: any) => ({
      user: auth.user,
      newUserNoticeCount: service.userNotices.reduce(
        (acc: number, curr: any) => (curr.isConfirm ? acc : acc + 1),
        0,
      ),
    }),
    shallowEqual,
  );
  const isLoggedIn = user !== null;
  const logout = () => {
    navigate(path.logout, { replace: true });
  };
  const showUserNoticeDrawer = () => {
    dispatch(setUserNoticeDrawerVisible(true));
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserNotices(user.userId));
      const showUserNotice = ({ title, content, noticePath }: any) => {
        notification.open({
          key: 'userNoticeNotification',
          icon: null,
          message: title,
          description: parse(content),
          duration: 0,
          style: { cursor: 'pointer' },
          onClick: () => {
            if (noticePath === window.location.pathname) {
              window.location.reload();
            } else if (noticePath === null) {
              notification.destroy('userNoticeNotification');
            } else {
              navigate(noticePath);
            }
            notification.destroy('userNoticeNotification');
          },
        });
      };
      const initSocket = () => {
        const socket = new SockJS(`${getReactEnvVar('SERVER_URL')}/api/v1/socket/connection`);
        const client = StompJS.over(socket);
        if (process.env.NODE_ENV !== 'development') {
          client.debug = () => undefined;
        }
        client.connect({}, () => {
          client.subscribe(`/api/v1/socket/subscribe/${user.userId}`, ({ body }) => {
            const userNotice = JSON.parse(body);
            userNotice.isConfirm = true;
            dispatch(addUserNotice(userNotice));
            showUserNotice(userNotice);
          });
        });
        return client;
      };
      const client = initSocket();
      return () => {
        client.disconnect(() => {
          console.log('disconnected');
        });
        notification.destroy('userNoticeNotification');
      };
    }
  }, [isLoggedIn]);

  return (
    <TopSubMenu
      inlineMode={inlineMode}
      newUserNoticeCount={newUserNoticeCount}
      isLoggedIn={isLoggedIn}
      logout={logout}
      toggleDrawer={() => dispatch(toggleDrawer())}
      showUserNoticeDrawer={showUserNoticeDrawer}
    />
  );
};

export default TopSubMenuContainer;

import { checkMobile } from 'lib/common';
import { createGlobalStyle, css } from 'styled-components';
import palette from './palette';

const isMobile = checkMobile();

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    min-height: 100%;
    overscroll-behavior: none;
    touch-action: pan-x pan-y;
    color: ${palette.GRAY90};
    -webkit-text-size-adjust : none;
    -ms-text-size-adjust : none;
    -moz-text-size-adjust : none;
    -o-text-size-adjust : none;
    line-height: 1.5715;
  }

  #root {
    min-height: 100%;
  }

  a {
    text-decoration: none;
  }

  pre {
    margin-bottom: 0 !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  input::-webkit-calendar-picker-indicator {
    opacity: 100;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  ul, h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0;
  }

  table {
    width: 100%;
    border-spacing: 0;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  pre {
    margin: 0;
  }

  ${
    isMobile &&
    css`
      .ant-modal {
        top: 20px;
      }
    `
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }

  .ant-select .ant-select-arrow {
    width: 20px;
    height: 20px;
    background-position: center;
    // https://yoksel.github.io/url-encoder/
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.41073 6.91083C4.73617 6.58539 5.26381 6.58539 5.58925 6.91083L9.99999 11.3216L14.4107 6.91083C14.7362 6.58539 15.2638 6.58539 15.5892 6.91083C15.9147 7.23626 15.9147 7.7639 15.5892 8.08934L10.5892 13.0893C10.2638 13.4148 9.73617 13.4148 9.41073 13.0893L4.41073 8.08934C4.0853 7.7639 4.0853 7.23626 4.41073 6.91083Z' fill='%23A9A6A6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    right: 16px !important;
    margin-top: 0;
    transform: translateY(-50%);

    svg {
      display: none;
    }
  }

  .ant-select-open.ant-select-show-search {
    .ant-select-arrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.86339 3.86345C5.26991 2.45692 7.17756 1.66675 9.16669 1.66675C11.1558 1.66675 13.0635 2.45692 14.47 3.86345C15.8765 5.26997 16.6667 7.17762 16.6667 9.16675C16.6667 10.8761 16.0832 12.5253 15.0264 13.848L18.0893 16.9108C18.4147 17.2363 18.4147 17.7639 18.0893 18.0893C17.7638 18.4148 17.2362 18.4148 16.9108 18.0893L13.8479 15.0265C12.5252 16.0832 10.876 16.6667 9.16669 16.6667C7.17756 16.6667 5.26991 15.8766 3.86339 14.47C2.45686 13.0635 1.66669 11.1559 1.66669 9.16675C1.66669 7.17762 2.45686 5.26997 3.86339 3.86345ZM9.16669 3.33341C7.61959 3.33341 6.13586 3.948 5.0419 5.04196C3.94794 6.13592 3.33335 7.61965 3.33335 9.16675C3.33335 10.7138 3.94794 12.1976 5.0419 13.2915C6.13586 14.3855 7.61959 15.0001 9.16669 15.0001C10.7138 15.0001 12.1975 14.3855 13.2915 13.2915C14.3854 12.1976 15 10.7138 15 9.16675C15 7.61965 14.3854 6.13592 13.2915 5.04196C12.1975 3.948 10.7138 3.33341 9.16669 3.33341Z' fill='%23A9A6A6'/%3E%3C/svg%3E");
  }
  }

  .ant-select-arrow.ant-select-arrow-loading {
    background: none;
    svg {
        display: block;
    }
  }
`;

export default GlobalStyle;

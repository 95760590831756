import { DocumentStatus, ChatMessage } from 'types/common';

export enum MaterialNmpaBasicInfoStatus {
  INPUT = 'INPUT',
  READY = 'READY',
  ONGOING = 'ONGOING',
  FINISH = 'FINISH',
}

export enum NMPAMaterialProgressStatus {
  INPUTTING = 'INPUTTING', // 입력 중인 원료
  INPUT_FINISH = 'INPUT_FINISH', // 입력 완료 원료
}

export enum NMPAContractStatus {
  ALL = '',
  ISS_FIN = 'ISS_FIN', // 계약 체결 전 or 견적 발행
  CON_FIN = 'CON_FIN', // 계약 체결 완료
}

export enum NMPADepositStatus {
  READY = 'READY',
  PARTIAL = 'PARTIAL',
  FINISH = 'FINISH',
}

export enum NMPAApplicationStatus {
  INPUT = 'INPUT',
  ONGOING = 'ONGOING',
  APPLYING = 'APPLYING',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL',
}

export enum BasicInfoStatus {
  INPUT = 'INPUT',
  READY = 'READY',
  ONGOING = 'ONGOING',
  FINISH = 'FINISH',
}

export enum NMPADetailSolubility {
  RECEPTIVITY = 'RECEPTIVITY', // 수용성
  FAT_SOLUBLE = 'FAT_SOLUBLE', // 지용성
  INSOLUBLE = 'INSOLUBLE', // 불용성
  ETC = 'ETC', // 기타
}

export enum NMPATempStoreType {
  BASIC_ATTRIBUTE = 'BASIC_ATTRIBUTE',
  DETAIL = 'DETAIL',
  DANGER_INFO = 'DANGER_INFO',
}

export enum NMPAEtcDataDangerType {
  METAL = 'METAL',
  MICROBE = 'MICROBE',
  PESTICIDE_RESIDUE = 'PESTICIDE_RESIDUE',
  ETC = 'ETC',
}
export enum NMPADocumentCode {
  BASIC = 'BASIC01', // 기본 정보
  BAT = 'BAT01', // 기본 특성
  DETAIL = 'DETAIL01', // 상세 정보
  DIF = 'DIF01', // 위험 정보 및 통제 지표
  EDT = 'EDT01', // 국제 공인 기관 평가결론
  ESD = 'ESD01', // 기타 제출 자료
}

export enum NMPABATAttribute {
  NMPA_MAIN_INGREDIENT_BASIC_FEATURE = 'NMPA_MAIN_INGREDIENT_BASIC_FEATURE',
  NMPA_RAW_MATERIAL_SOURCE = 'NMPA_RAW_MATERIAL_SOURCE',
  NMPA_RAW_MATERIAL_PRODUCTION_METHOD = 'NMPA_RAW_MATERIAL_PRODUCTION_METHOD',
  NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE = 'NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE',
  NMPA_RAW_MATERIAL_MIXING_REASON = 'NMPA_RAW_MATERIAL_MIXING_REASON',
}

export enum NMAPCompanyType {
  PROXY = 'PROXY',
  PLATFORM = 'PLATFORM',
  INFO = 'INFO',
}

export interface NMPARequestListPageState {
  page: number;
  contractStatus: NMPAContractStatus;
}

export interface NMPAMaterialListPageState {
  page: number;
  progressStatus: NMPAMaterialProgressStatus;
  searchValue: string;
}

export interface NMPARegisteredPageState {
  page: number;
  searchValue: string;
}

export interface NMPAChecklistItem {
  countryChecklistId: number;
  countryId: number;
  country: null;
  questionMsg: string;
  questionSubMsg: string;
  answerType: string;
  yesNextId: number | null;
  noNextId: number | null;
  yesType: null;
  noType: null;
  yesMsg: null;
  noMsg: null;
  yesGoodsAdditionalId: null;
  noGoodsAdditionalId: null;
  targetCosmeticCategoryId: null;
}

export interface NMPAEstimate {
  rawMaterialCount: number;
  checklists: {
    countryChecklistId: number;
    isYes: boolean;
  }[];
}

export interface NMPARequestListItem {
  materialNmpaEstimateId: number;
  status: Omit<NMPAContractStatus, 'ALL'>;
  depositStatus: NMPADepositStatus;
  estimateCode: string;
  rawMaterialCount: number;
  estimateFileUrl: string;
  registerDt: string;
  receivedContract: boolean;
  isExpire: boolean;
}

export interface NMPAOngoingPageState {
  page: number;
  tradeName: string;
}

export interface NMPABasicInfo {
  materialNmpaBasicInfoId: number;
  tradeName: string;
  versionNumber: string;
  materialNmpaBasicInfoIncis: MaterialNmpaBasicInfoInci[];
  status: MaterialNmpaBasicInfoStatus;
}

export interface MaterialNmpaBasicInfoInci {
  materialNmpaBasicInfoId: number;
  materialNmpaBasicInfoInciId: number;
  chinaIngredient: ChinaIngredient;
  chinaIngredientId: number;
  content: number;
  rangeMin: number;
  rangeMax: number;
  description: string;
}

export interface NMPABasicInfosParams {
  tradeName: string;
  versionNumber: string;
  companyId: number;
  materialNmpaBasicInfoIncis: MaterialNmpaBasicInfoInciParams[];
}

export interface NMPABasicInfosNewParams {
  chinaIngredientId: number;
  versionNumber: string;
}

export interface MaterialNmpaBasicInfoInciParams {
  materialNmpaBasicInfoId?: number;
  materialNmpaBasicInfoInciId?: number;
  chinaIngredientId: number;
  content?: string;
  rangeMin?: string;
  rangeMax?: string;
  description?: string;
}

export interface NMPAOngoingDocsResponse {
  basicInfoStatus: string;
  materialNmpaBasicInfoId: number;
  ongoingDocs: NMPAOngoingDocs[];
}

export interface NMPAOngoingDocs {
  materialNmpaDocStatusId: number;
  documentCode: string;
  documentName: string;
  status: DocumentStatus;
  isOptional: boolean;
  isUserUpload: boolean;
  modifierId: number | null;
  modifierName: string | null;
  modifierCompanyName: string | null;
  registerDt: string | null;
}

export interface NMPAMaterialListItem {
  materialNmpaBasicInfoId: number;
  tradeName: string;
  status: MaterialNmpaBasicInfoStatus;
  readyDt?: string;
  updateDt?: string;
}

export interface NMPAOngoingMaterial {
  materialNmpaBasicInfoId: number;
  status: DocumentStatus;
  applicationCode: string;
  applicationDt?: string;
  tradeName: string;
}

export interface ChinaIngredient {
  chinaIngredientId: number;
  nameEn: string;
  nameCn: string;
}

export enum DocumentCode {
  BASIC = 'basic',
  CI = 'ci',
  PN = 'pn',
  CAT = 'cat',
  COUNTRY = 'country',
  PHY = 'phy',
  CHE = 'che',
  BAT = 'bat', // 기본 특성
  DETAIL = 'detail', // 상세 정보
  DIF = 'dif', // 위험 정보 및 통제 지표
  EDT = 'edt', // 국제 공인 기관 평가결론
  ESD = 'esd', // 기타 제출 자료
}

export interface NMPACompanyInfo {
  companyId: number | null;
  platformUsername: string | null;
  platformPassword: string | null;
  companyName: string | null;
  ceoName: string | null;
  zipcode: string | null;
  managerName: string | null;
  companyInformationFilename: string | null;
  companyInformationFileUrl: string | null;
  certificateOfBusinessFileUrl: string | null;
  rawMaterialCode: string | null;
}

export interface NMPARegisteredListItem {
  materialNmpaBasicInfoId: number;
  applicationCode: string;
  tradeName: string;
  declarationCode: string;
  declarationFileUrl: string;
  declarationFinishDt: string;
}

export interface NMPAEvaluationData {
  materialNmpaBasicInfoInci: MaterialNmpaBasicInfoInci;
  evaluationAgency: string;
  figure: string;
  unit: string;
  limitElement: string;
  addDataFilename: string;
  addDataFileUrl: string;
}

export interface UpdateNMPAEvaluationDataParams {
  evaluationDatas: {
    materialNmpaBasicInfoInciIds: number;
    evaluationAgency: string;
    figure: string;
    unit: string;
    limitElement?: string;
    addDataFile: File;
  }[];
}

// 기본 특성
export interface NMPABATCategory {
  materialCategoryId: number;
  type: string;
  code: string;
  nameKo: string;
  nameEn: string;
  description: string;
}

export interface NMPABATForm {
  [key: number]: {
    key?: number;
    materialNmpaBasicInfoInciIds?: number[];
    chinaIngredientId?: number;
    origin?: string;
    originTargetName?: string;
    indicator?: string;
    indicatorDirectInput?: string;
    originDirectInput?: string;
    figure?: string;
    unit?: string;
    molecularFormula?: string;
    casNo?: string;
    testWay?: string;
    scientificName?: string;
    organismName?: string;
    heredity?: string;
    geneticModification?: string;
    carrier?: string;
    source?: string;
    isAllMaterial?: boolean;
  }[];
  NMPA_MAIN_INGREDIENT_BASIC_FEATURE: number;
  NMPA_RAW_MATERIAL_SOURCE: number[];
  NMPA_RAW_MATERIAL_PRODUCTION_METHOD: number[];
  NMPA_OTHER_RAW_MATERIAL_ATTRIBUTE: number[];
  NMPA_RAW_MATERIAL_MIXING_REASON: number[];
}

export interface NMPABATParam {
  materialNmpaBasicAttributeId: number;
  materialNmpaBasicInfoId: number;
  materialCategoryId: number;
  materialNmpaBasicAttributeAdditionals: NMPABATAdditional[];
}

export interface NMPABATAdditional {
  materialNmpaBasicAttributeAdditionalId: number;
  materialNmpaBasicAttributeId: number;
  materialNmpaBasicInfoIncis: NMPABasicInfoInci[];
  origin: string;
  originTargetName: string;
  indicator: string;
  isDirectInputIndicator: boolean;
  isDirectInputOrigin: boolean;
  figure: string;
  unit: string;
  molecularFormula: string;
  casNo: string;
  testWay: string;
  scientificName: string;
  organismName: string;
  heredity: string;
  geneticModification: string;
  carrier: string;
  source: string;
  isAllMaterial: boolean;
}

export interface NMPABasicInfoInci {
  materialNmpaBasicInfoId: number;
  materialNmpaBasicInfoInciId: number;
  chinaIngredient: ChinaIngredient;
  content: number;
  rangeMin: number;
  rangeMax: number;
  description: string;
}

export interface ChinaIngredient {
  chinaIngredientId: number;
  nameEn: string;
}

export interface NMPABATRegisterParams {
  materialCategoryId?: number;
  materialNmpaBasicAttributeAdditionals?: NMPABATRegisterParamsAdditional[];
}

export interface NMPABATRegisterParamsAdditional {
  materialNmpaBasicAttributeId?: number;
  materialNmpaBasicInfoInciIds?: number[];
  chinaIngredientId?: number;
  origin?: string;
  originTargetName?: string;
  indicator?: string;
  isDirectInputIndicator?: boolean;
  isDirectInputOrigin?: boolean;
  figure?: string;
  unit?: string;
  molecularFormula?: string;
  casNo?: string;
  testWay?: string;
  scientificName?: string;
  organismName?: string;
  heredity?: string;
  geneticModification?: string;
  carrier?: string;
  source?: string;
  isAllMaterial: boolean;
}

export interface NMPAMaterialUsePurpose {
  materialCategoryId: number;
  type: string;
  code: string;
  nameKo: string;
  nameEn: string;
  nameCn: string;
  description: string | null;
}

export interface NMPADetailItem {
  usePurposes?: {
    materialCategory?: NMPAMaterialUsePurpose | null;
    materialCategoryId?: number;
    usePurpose?: string;
  }[];
  leaveOnMin: string;
  leaveOnMax: string;
  isNotLeaveOn: boolean;
  rinseOffMin: string;
  rinseOffMax: string;
  isNotRinseOff: boolean;
  prescriptionContraindications?: string | null;
  usageRestrictions?: string | null;
  warningText?: string | null;
  materialColor: string;
  materialSmell: string;
  materialAppearance: string;
  solubility: NMPADetailSolubility;
  solubilityReason?: string | null;
  logPow?: string | null;
  etcAttribute?: string;
  manufactureProcessExplanation: string;
  methodOfAnalysis?: string | null;
  otherRequirements?: string | null;
}

export interface UpdateNMPAEtcDataParams {
  etcDatas: {
    dataName: string;
    addDataFile: File;
  }[];
}

export interface EtcData {
  dataName: string;
  dataFilename: string;
  dataFileUrl: string;
}

export interface DangerInfo {
  isDangerMetal: boolean;
  isDangerMicrobe: boolean;
  isDangerPesticideResidue: boolean;
  isDangerEtc: boolean;
  materialNmpaControlIndicatorses: MaterialNmpaControlIndicatorse[];
}

export interface MaterialNmpaControlIndicatorse {
  dangerType: string;
  qualityOrHazardousSubstances: string;
  casNo: string;
  figure: string;
  unit: string;
  note: string;
}

export type UpdateNMPADangerInfosParams = DangerInfo;
export interface NMPABasicInfoStatusResponse {
  status: MaterialNmpaBasicInfoStatus; // BasicInfo Status
  isNmpaDocModStatus: boolean; // 보완 요청 여부
  correctRequestMessage: string; // 보완 메세지
}

export interface NMPADetailUseGuide {
  nameEn: string;
  nameCn: string;
  leaveOn: string;
  rinseOff: string;
  leaveOnValue: string;
  rinseOffValue: string;
}

export interface NMPAChatMessage extends ChatMessage {
  materialNmpaDocThreadId: number;
  materialNmpaDocThreadMessageId: number;
  threadAttaches: NMPAChatMessageThreadAttaches[];
}

export interface NMPAChatMessageThreadAttaches {
  materialNmpaDocThreadMessageAttachId: number;
  materialNmpaDocThreadMessageId: number | null;
  filename: string;
  fileUrl: string;
  fileSize: number;
  threadAttachRegisterDt: string;
}

export interface NMPACorrectRequestMsg {
  nmpaBasicInfoId: number;
  documentCode: NMPADocumentCode;
  materialNmpaDocThreadId?: number;
  nmpaDocThreadMessage: {
    message?: string;
    nmpaDocThreadMessageAttaches?: {
      attachFile: File | string | null;
    }[];
  };
}

import styled, { css } from 'styled-components';

import palette from 'lib/styles/palette';
import { TypographyBlockProps, TypographyProps } from '.';

const CategoryBlock = styled.div<TypographyBlockProps>`
  font-weight: 400;
  padding: 2px 8px;
  margin: 0;
  border-radius: 12px;
  border: solid 1px ${palette.PRIMARY50};
  color: ${palette.SLATE_GRAY70};
  background-color: ${palette.PRIMARY10};
  line-height: 20px;

  ${({ type }) =>
    type === 'primary' &&
    css`
      font-size: 16px;
    `}
  ${({ type }) =>
    type === 'secondary' &&
    css`
      font-size: 14px;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ medium }) =>
    medium &&
    css`
      font-weight: 500;
    `}
    
  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}
    
  ${({ color }) =>
    color &&
    css`
      color: ${palette[color]};
    `}

    span.primary {
    color: ${palette.PRIMARY50};
  }
`;

const Category = ({
  children,
  type = 'secondary',
  color,
  bold,
  medium = true,
  inline,
  className,
  style,
  onClick,
}: TypographyProps) => {
  return (
    <CategoryBlock
      type={type}
      color={color}
      bold={bold}
      medium={medium}
      inline={inline}
      className={className}
      style={style}
      onClick={onClick}
    >
      {children}
    </CategoryBlock>
  );
};

export default Category;

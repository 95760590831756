import 태국 from 'asset/svg/flag//thailand.svg';
import 미국 from 'asset/svg/flag/america.svg';
import 아르헨티나 from 'asset/svg/flag/argentina.svg';
import 호주 from 'asset/svg/flag/australia.svg';
import 바레인 from 'asset/svg/flag/bahrain.svg';
import 브라질 from 'asset/svg/flag/brazil.svg';
import 브루나이 from 'asset/svg/flag/brunei.svg';
import 캄보디아 from 'asset/svg/flag/cambodia.svg';
import 캐나다 from 'asset/svg/flag/canada.svg';
import 칠레 from 'asset/svg/flag/chile.svg';
import 중국 from 'asset/svg/flag/china.svg';
import 콜롬비아 from 'asset/svg/flag/colombia.svg';
import 코스타리카 from 'asset/svg/flag/costa_rica.svg';
import ETC from 'asset/svg/flag/etc.svg';
import 유럽연합 from 'asset/svg/flag/eu.svg';
import 온두라스 from 'asset/svg/flag/honduras.svg';
import 인도 from 'asset/svg/flag/india.svg';
import 인도네시아 from 'asset/svg/flag/indonesia.svg';
import 일본 from 'asset/svg/flag/japan.svg';
import 카자흐스탄 from 'asset/svg/flag/kazakhstan.svg';
import 쿠웨이트 from 'asset/svg/flag/kuwait.svg';
import 라오스 from 'asset/svg/flag/laos.svg';
import 말레이시아 from 'asset/svg/flag/malaysia.svg';
import 몽골 from 'asset/svg/flag/mongolia.svg';
import 모로코 from 'asset/svg/flag/morocco.svg';
import 미얀마 from 'asset/svg/flag/myanmar.svg';
import 뉴질랜드 from 'asset/svg/flag/new_zealand.svg';
import 파나마 from 'asset/svg/flag/panama.svg';
import 페루 from 'asset/svg/flag/peru.svg';
import 필리핀 from 'asset/svg/flag/philippines.svg';
import 카타르 from 'asset/svg/flag/qatar.svg';
import 러시아 from 'asset/svg/flag/russia.svg';
import 사우디아라비아 from 'asset/svg/flag/saudi_arabia.svg';
import 싱가포르 from 'asset/svg/flag/singapore.svg';
import 남아공 from 'asset/svg/flag/south_africa.svg';
import 대한민국 from 'asset/svg/flag/south_korea.svg';
import 대만 from 'asset/svg/flag/taiwan.svg';
import 터키 from 'asset/svg/flag/turkey.svg';
import UAE from 'asset/svg/flag/uae.svg';
import 영국 from 'asset/svg/flag/uk.svg';
import 베트남 from 'asset/svg/flag/vietnam.svg';
import 유럽연합1 from 'asset/svg/flag/유럽연합1.svg';
import Cm29 from 'asset/svg/retailer/cm29.svg';
import Coopang from 'asset/svg/retailer/coopang.svg';
import Gsshop from 'asset/svg/retailer/gsshop.svg';
import Hmall from 'asset/svg/retailer/hmall.svg';
import Kurly from 'asset/svg/retailer/kurly.svg';
import Lala from 'asset/svg/retailer/lala.svg';
import Lohbs from 'asset/svg/retailer/lohbs.svg';
import Lotte from 'asset/svg/retailer/lotte.svg';
import Nh from 'asset/svg/retailer/nh.svg';
import Olive from 'asset/svg/retailer/olive.svg';
import Shinsegae from 'asset/svg/retailer/shinsegae.svg';
import { RegisterSearchType } from 'types/material/register';
// TODO: 추후 이미지 수정 필요
import 아세안 from 'asset/svg/flag/아세안.png';

export const messages = {
  REQUIRED_FIELD: '필수 항목',
  REQUIRED_FILE: '파일을 업로드해 주세요.',
  REQUIRED_FILES: '하나 이상의 파일을 업로드해 주세요.',
  REQUIRE_ONE_OR_MORE_FILES: '최소 한 개 이상의 파일이 필요합니다.',
  SHOULD_CHECK_FILE: '파일에 오류가 있습니다. 파일을 확인하시고 다시 시도해주세요.',
  SHOULD_DUP_CHECK: '중복확인 버튼을 눌러주세요.',
  DUPLICATE_FILE_NAME: '같은 이름의 파일은 업로드 할 수 없습니다.',
  INVALID_DATE_FORMAT: '올바르지 않은 날짜 형식',
  INVALID_EMAIL_FORMAT: '올바르지 않은 이메일 형식',
  INVALID_BIZ_NUMBER_FORMAT: '올바르지 않은 사업자등록번호 형식',
  SHOULD_EXCEPT_KOREAN_OR_ALPHABET: '한글 또는 알파벳 입력 불가',
  SHOULD_EXCEPT_KOREAN_OR_NUMBER: '한글 또는 숫자 입력 불가',
  SHOULD_EXCEPT_KOREAN: '한글 입력 불가',
  SHOULD_EXCEPT_ALPHABET: '알파벳 입력 불가',
  SHOULD_NUMBER: '숫자만 입력 가능',
  SHOULD_ALPHABET_OR_SPACE: '알파벳만 입력 가능',
  SHOULD_ALPHABET_OR_NUMBER: '알파벳 또는 숫자만 입력 가능',
  PHONE_FORMAT: '숫자만 입력 가능, 8 ~ 12 글자',
  MOBILE_PHONE_FORMAT: '숫자만 입력 가능, 10 ~ 11 글자',
  CHINA_PHONE_FORMAT: '숫자만 입력 가능, 10 ~ 20 글자',
  NO_PERMISSION: '접근 권한이 없습니다.',
  NO_NEED_TO_UPDATE: '수정할 내역이 없습니다.',
  SHOULD_NOT_DUPLICATE: '중복 불가',
  SHOULD_NUMBER_OR_KOREAN: '숫자 또는 한글 입력 가능',
  SHOULD_KOREAN_OR_ALPHABET: '한글 또는 영문 입력 가능',
  INVALID_APPROACH: '비정상적인 접근입니다. 새로고침 후 다시 시도해주세요.',
  INPUT_INFO: '정보를 입력해주세요.',
  NUBER_ONE_TO_NINETYNINE: '1~99까지의 숫자',
};

export const productSpecificationsOption = {
  productTypeOptions: [
    { label: 'Leave-On', value: 'Leave-On' },
    { label: 'Rinse-Off', value: 'Rinse-Off' },
  ],
  skinTypeOptions: [
    { label: 'Dry Skin Type (건성)', value: 'Dry Skin Type' },
    { label: 'Neutral Skin Type (중성)', value: 'Neutral Skin Type' },
    { label: 'Oily Skin Type (지성)', value: 'Oily Skin Type' },
    { label: 'Combination Skin Type (복합성)', value: 'Combination Skin Type' },
    { label: 'Sensitive Skin Type (민감성)', value: 'Sensitive Skin Type' },
    { label: 'All Skin Type (모든 피부 타입)', value: 'All Skin Type ' },
  ],
  productPeriodOptions: [
    { label: 'month(s)', value: 'month(s)' },
    { label: 'year(s)', value: 'year(s)' },
  ],
  productPaoPeriodOptions: [
    { label: 'month(s)', value: 'month(s)' },
    { label: 'year(s)', value: 'year(s)' },
    { label: 'use immediately', value: 'use immediately' },
  ],
  productTargetAgeOptions: [
    { label: 'Over 3', value: 'over3' },
    { label: 'Over 13', value: 'over13' },
    { label: 'Over 18', value: 'over18' },
  ],
  packagingUnitOptions: [
    { label: 'CT', value: 'CT' },
    { label: 'PKG', value: 'PKG' },
    { label: '직접입력', value: 'others' },
  ],
  cartonUnitOptions: [
    { label: 'ea', value: 'ea' },
    { label: 'pcs', value: 'pcs' },
    { label: '직접입력', value: 'others' },
  ],
  cartonWeightUnitOptions: [
    { label: 'kg', value: 'kg' },
    { label: '직접입력', value: 'others' },
  ],
  cartonSizeUnitOptions: [
    { label: 'mm', value: 'mm' },
    { label: 'cm', value: 'cm' },
    { label: 'inch', value: 'inch' },
    { label: '직접입력', value: 'others' },
  ],
  appliedPerUseOptions: [
    { label: 'mg', value: 'mg' },
    { label: 'g', value: 'g' },
    { label: 'mL', value: 'mL' },
    { label: 'L', value: 'L' },
    { label: 'EA', value: 'EA' },
  ],
  frequencyOfUseOptions: [
    { label: 'day (1일)', value: 'day' },
    { label: 'week (7일)', value: 'week' },
    { label: 'month (30일)', value: 'month' },
    { label: 'year (365일)', value: 'year' },
  ],
};

export const scrollToFirstErrorOption = {
  behavior: (actions: any) => {
    const { el, top }: { el: Element; top: number } = { ...actions?.[0] };
    if (el && el.scrollTop > top) {
      el.scrollTop = top - 120;
    }
  },
};

export const contractDocumentKeyNameMap = {
  BANK_BOOK: '통장 사본',
  CBPR: '사업자등록증 사본',
  ToS: '이용 약관',
  NDA: '비밀유지 계약서',
  STANDARD_CONTRACT: '인증 컨설팅 계약서',
};

export const defaultMaterialEfficacies = [
  '가려움 방지',
  '각질 케어',
  '모공 케어',
  '모발 보호',
  '보습',
  '안티에이징',
  '자극 완화',
  '자외선 차단',
  '체취 완화',
  '탈모 완화',
  '트러블 완화',
  '피부 보호',
  '피부 진정',
  '피부 탄력',
  '피지 조절',
  '항균',
  '항산화',
  '항알러지',
  '염증 완화',
  '화이트닝',
  '피부 영양',
];

export const rawMaterialSpecialBrandOptions = [
  {
    label: 'Amazon',
    value: 'SB01',
  },
  {
    label: 'Walmart',
    value: 'SB02',
  },
  {
    label: 'Sam’s Club',
    value: 'SB03',
  },
  {
    label: 'LVMH',
    value: 'SB04',
  },
  {
    label: 'L’oreal',
    value: 'SB05',
  },
  {
    label: 'Chanel',
    value: 'SB06',
  },
  {
    label: 'L’Occitane',
    value: 'SB07',
  },
  {
    label: 'Hermès',
    value: 'SB08',
  },
  {
    label: 'Lush',
    value: 'SB09',
  },
  {
    label: 'Gucci',
    value: 'SB10',
  },
  {
    label: 'Prada',
    value: 'SB11',
  },
  {
    label: 'Dolce & Gabbana',
    value: 'SB12',
  },
  {
    label: 'Estée Lauder',
    value: 'SB13',
  },
  {
    label: 'Johnson & Johnson',
    value: 'SB14',
  },
  {
    label: 'P&G',
    value: 'SB15',
  },
  {
    label: 'Aēsop',
    value: 'SB16',
  },
];

interface CountryCodeMap {
  [key: string]: string;
}

export const countryCodeMap: CountryCodeMap = {
  EU: 'eu',
  유럽연합: 'eu',
  미국: 'us',
  중국: 'cn',
  러시아: 'ru',
  일본: 'jp',
  몽골: 'mn',
  베트남: 'vn',
  태국: 'th',
  말레이시아: 'my',
  싱가포르: 'sg',
  인도네시아: 'id',
  필리핀: 'ph',
  사우디아라비아: 'sa',
  UAE: 'ae',
  카타르: 'qa',
  쿠웨이트: 'kw',
  바레인: 'bh',
  터키: 'tr',
  미얀마: 'mm',
  인도: 'in',
  남아공: 'za',
  모로코: 'ma',
  호주: 'au',
  뉴질랜드: 'nz',
  캐나다: 'ca',
  칠레: 'cl',
  아르헨티나: 'ar',
  브라질: 'br',
  페루: 'pe',
  콜롬비아: 'co',
  캄보디아: 'kh',
  영국: 'gb',
  온두라스: 'hn',
  코스타리카: 'cr',
  파나마: 'pa',
  대한민국: 'kr',
  대만: 'tw',
  브루나이: 'bn',
  라오스: 'la',
  카자흐스탄: 'kz',
  걸프연합: 'gu',
  남아프리카공화국: 'za',
  남아프리카: 'za',
  아세안: 'aa',
  '아세안(개별국)': 'aa',
  타이: 'th',
  한국: 'kr',
};

export const materialDocumentCodeWithPathMap = {
  MDI01: 'basic', // 원료 기본 정보
  DETAIL01: 'detail', // 원료 상세 정보
  MCAS01: 'certification', // 인증 및 특이사항
  MCI01: 'composition-information', // Composition Information
  COA01: 'coa', // Certificate Of Analysis
  MSDS01: 'msds', // Material Safety Data Sheet
  ALLE01: 'allergens-list', // Allergens List
  IFRA01: 'ifra-certificate', // IFRA
  MMD01: 'marketing', // 마케팅 자료
  CLOD01: 'etc-data', // 기타 자료 (클라우드)
};

export const coaPhaseExampleMap = {
  ALL: '(모든 성상의 공통 검사 항목을 입력)',
  SOLID: '(왁스, 조각 등)',
  LIQUID: '(겔, 오일, 밤 등)',
  POWDER: '(분말, 가루 등)',
  CUSTOMIZED: '검사 항목 직접 입력',
};

export const COMPOSITION_INFORMATION_COLUMNS = [
  'NO',
  '국문성분명',
  'INCI Name',
  'WT %',
  'CAS NO.',
  'EC No.',
  'Composition Range',
  'Function',
  'Function 2',
  'Function 3',
  '구체적인 추출부위',
  '비고',
];

export const registerManageSearchTypeOptions = [
  { label: '전체', value: RegisterSearchType.ALL },
  { label: '원료명', value: RegisterSearchType.MATERIAL_NAME_EN },
  {
    label: '원료 생산 회사명',
    value: RegisterSearchType.ORIGIN_MANUFACTURE_NAME,
  },
];

export const totalNumOfServiceCountries = 35;

export const retailerImages = [
  Olive,
  Lala,
  Lohbs,
  Coopang,
  Coopang,
  Nh,
  Kurly,
  Cm29,
  Shinsegae,
  Gsshop,
  Lotte,
  Gsshop,
  Hmall,
];

// TODO: S3에서 가져오는걸로 변경하기
export const countryImages: { [key: string]: string } = {
  뉴질랜드,
  러시아,
  말레이시아,
  모로코,
  몽골,
  미국,
  미얀마,
  바레인,
  베트남,
  브라질,
  사우디아라비아,
  남아공,
  싱가포르,
  아르헨티나,
  영국,
  유럽: 유럽연합,
  EU: 유럽연합,
  유럽연합,
  유럽연합1,
  인도,
  인도네시아,
  일본,
  중국,
  칠레,
  카타르,
  캄보디아,
  캐나다,
  코스타리카,
  콜롬비아,
  쿠웨이트,
  태국,
  터키,
  파나마,
  페루,
  필리핀,
  호주,
  온두라스,
  UAE,
  아세안,
  브루나이,
  카자흐스탄,
  라오스,
  대한민국,
  대만,
  ETC,
};

export const s3AssetDomain = 'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image';

export const qcqaDocumentCodes = {
  FORMULA_BREAKDOWN_CODE: 'CODE_044', // 전성분표 코드
  BUSINESS_REGISTERS_CODE: 'CODE_026', // 제조사 사업자등록증 코드
  COSMETIC_REGISTERS_CODE: 'CODE_027', // 화장품 제조업 등록필증
};

import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import * as authApi from 'lib/api/auth';
import { UserType } from 'types/auth';
import { useBasicMutation } from './core';

const authApiUrls = {
  changePassword: '/users/password',
  validateAdminToken: '/v1/admin/auth/validate-access-token',
};

export const useIsLoggedIn = () => useSelector(({ auth }: any) => auth.user !== null);

export const useUser = () => useSelector(({ auth }: any) => auth.user);

export const useUserType = () =>
  useSelector(({ auth }: any) => {
    if (!auth.user || auth.user.userType === UserType.BRAND) {
      return UserType.BRAND;
    } else if (auth.user.userType === UserType.MANUFACTURE) {
      return UserType.MANUFACTURE;
    }
    return UserType.MATERIAL;
  });

export const useAuthority = () =>
  useSelector(({ auth }: any) => {
    return auth.user.authority;
  });

export const useRegisterUser = () => {
  const { mutate: registerUser, isLoading: registerLoading } = useMutation((manager: any) =>
    authApi.registerUser(manager),
  );
  return useMemo(() => ({ registerUser, registerLoading }), [registerUser, registerLoading]);
};

export const useChangePassword = () => {
  const { mutate: changePassword, isLoading: changePasswordLoading } = useBasicMutation<{
    userId: number;
    prevPassword: string;
    password: string;
    userType: UserType;
  }>({
    apiUrl: authApiUrls.changePassword,
    method: 'PATCH',
  });

  return {
    changePassword,
    changePasswordLoading,
  };
};

export const useValidateAdminToken = () => {
  // 토큰 검증(AAUTO17)
  const { mutate: validateAdminToken, isLoading: validateAdminTokenLoading } = useBasicMutation({
    apiUrl: authApiUrls.validateAdminToken,
    method: 'GET',
  });

  return {
    validateAdminToken,
    validateAdminTokenLoading,
  };
};

import { memo } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { FormInstance } from 'antd/es/form/Form';
import { Button, Col, Form, Input, Radio, Row, Select, Modal, Spin } from 'antd';

import palette from 'lib/styles/palette';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import { FirstAidForm, HazardsForm, Pictogram } from 'types/material/msds';
import { useAllPictograms, useClassifications, useGetStatementMap } from 'service/material/msds';
import { MinusIcon } from 'components/system/general/icon';
import FormLabel from 'components/system/form/FormLabel';
import { Tip, Typography } from 'components/system';
import AlphabetLabel from './AlphabetLabel';
import MSDSPictogram from './MSDSPictogram';
import MSDSPictogramSelectModalContent from './MSDSPictogramSelectModalContent';
import Icon from 'components/ui/Icon/Icon';

const MSDSHazardsBlock = styled.div`
  width: 538px;
  padding-left: 18px;
  margin: 0 auto 38px;
`;

const CircleText = styled.span`
  display: inline-block;
  background-color: ${palette.PRIMARY50};
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  letter-spacing: -0.8px;
`;

const PictogramAddButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
  width: 40px;
  height: 40px;
  margin: 0 8px;
  border: 1px dashed ${palette.PRIMARY50};
  transform: rotate(45deg);
  cursor: pointer;
`;

const MSDSHazards = ({ form }: { form: FormInstance }) => {
  const { ghsClassifications, getLoading: getClassificationsLoading } = useClassifications();
  const { getStatementMap, getLoading: getStatementMapLoading } = useGetStatementMap();
  const allPictograms = useAllPictograms();
  const depth0GHSOptions = ghsClassifications.map(({ materialCategoryId, nameKo }) => ({
    label: nameKo,
    value: materialCategoryId,
  }));

  const refreshModalProps = {
    icon: null,
    content: (
      <>
        <Typography.Text type="BODY_2">
          유해성·위험성 분류를 변경 및 추가, 삭제할 경우 아래 항목에서 직접 수정하신 내용이
          초기화됩니다. 진행하시겠습니까?
        </Typography.Text>
        <Typography.Text type="BODY_2" style={{ paddingLeft: 16 }} gutter={{ top: 16, left: 16 }}>
          <CircleText>2</CircleText> 유해성·위험성 -
          <Typography.Text
            type="BODY_2"
            color="PRIMARY50"
            inline
            gutter={{ left: 4, right: 4 }}
            medium
          >
            B.
          </Typography.Text>
          <Typography.Text type="BODY_2" medium inline>
            GHS-Labelling
          </Typography.Text>
        </Typography.Text>
        <Typography.Text type="BODY_2" style={{ paddingLeft: 16 }} gutter={{ top: 8, left: 16 }}>
          <CircleText>4</CircleText> 응급조치 요령 -
          <Typography.Text
            type="BODY_2"
            color="PRIMARY50"
            inline
            gutter={{ left: 4, right: 4 }}
            medium
          >
            A ~ F
          </Typography.Text>
        </Typography.Text>
      </>
    ),
    okText: '계속 진행',
    okButtonProps: {
      style: {
        width: 62,
        height: 36,
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: 12,
      },
    },
    cancelButtonProps: {
      style: {
        width: 62,
        height: 36,
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: 12,
      },
    },
  };

  const handleRefreshClassifications = () => {
    const materialCategoryIds = form
      .getFieldValue(['hazards', 'ghsClassifications'])
      .map(({ depth2GHSId }: any) => depth2GHSId)
      .filter((id: number) => id);
    getStatementMap(materialCategoryIds, {
      onSuccess: (res) => {
        const statementMap = res.data.result;
        const draftHazards = form.getFieldValue(['hazards']);
        const draftFirstAid = form.getFieldValue(['firstAid']);
        form.setFieldsValue({
          hazards: produce(draftHazards, (proxy: HazardsForm) => {
            proxy.pictograms = statementMap.pictograms.reduce((acc: Pictogram[], curr) => {
              if (
                acc.every(
                  ({ materialMsdsStatementId }) =>
                    materialMsdsStatementId !== curr.materialMsdsStatementId,
                )
              ) {
                acc.push(curr);
              }
              return acc;
            }, []);
            proxy.signalWord = statementMap.signalWords.reduce<'DANGER' | 'WARNING' | 'NONE'>(
              (acc, curr) => {
                if (acc === 'DANGER' || curr.nameEn === 'Danger') {
                  return 'DANGER';
                }
                if (acc === 'WARNING' || curr.nameEn === 'Warning') {
                  return 'WARNING';
                }
                return 'NONE';
              },
              'NONE',
            );
            proxy.hazardStatement =
              statementMap.hazardStatements.reduce((acc, curr) => {
                acc += `${!acc ? '' : '\n'}- ${curr.nameCode}, ${curr.nameEn}`;
                return acc;
              }, '') || 'None';
            proxy.preventionStatement =
              statementMap.preventionStatements.reduce((acc, curr) => {
                acc += `${!acc ? '' : '\n'}- ${curr.nameCode}, ${curr.nameEn}`;
                return acc;
              }, '') || 'None';
            proxy.responseStatement =
              statementMap.responseStatements.reduce((acc, curr) => {
                acc += `${!acc ? '' : '\n'}- ${curr.nameCode}, ${curr.nameEn}`;
                return acc;
              }, '') || 'None';
            proxy.storageStatement =
              statementMap.storageStatements.reduce((acc, curr) => {
                acc += `${!acc ? '' : '\n'}- ${curr.nameCode}, ${curr.nameEn}`;
                return acc;
              }, '') || 'None';
            proxy.disposalStatement =
              statementMap.disposalStatements.reduce((acc, curr) => {
                acc += `${!acc ? '' : '\n'}- ${curr.nameCode}, ${curr.nameEn}`;
                return acc;
              }, '') || 'None';
          }),
          firstAid: produce(draftFirstAid, (proxy: FirstAidForm) => {
            const eyeStatements = statementMap.eyeAids.map(({ nameEn, nameKo }) => ({
              statementEn: nameEn,
              statementKo: nameKo,
            }));
            proxy.eyeStatements = eyeStatements;
            proxy.eyeStatementOptions = eyeStatements;

            const skinStatements = statementMap.skinAids.map(({ nameEn, nameKo }) => ({
              statementEn: nameEn,
              statementKo: nameKo,
            }));
            proxy.skinStatements = skinStatements;
            proxy.skinStatementOptions = skinStatements;

            const inhalationStatements = statementMap.inhalationAids.map(({ nameEn, nameKo }) => ({
              statementEn: nameEn,
              statementKo: nameKo,
            }));
            proxy.inhalationStatements = inhalationStatements;
            proxy.inhalationStatementOptions = inhalationStatements;

            const ingestionStatements = statementMap.ingestionAids.map(({ nameEn, nameKo }) => ({
              statementEn: nameEn,
              statementKo: nameKo,
            }));
            proxy.ingestionStatements = ingestionStatements;
            proxy.ingestionStatementOptions = ingestionStatements;

            const physicianStatements = statementMap.physicianAids.map(({ nameEn, nameKo }) => ({
              statementEn: nameEn,
              statementKo: nameKo,
            }));
            proxy.physicianStatements = physicianStatements;
            proxy.physicianStatementOptions = physicianStatements;
          }),
        });
      },
    });
  };

  const normalizeStatement = (value: string, prevValue: string) => {
    if (value.length < prevValue.length) return value;
    return value
      .split('\n')
      .map((value) => (value.startsWith('- ') ? value : `- ${value}`))
      .join('\n');
  };

  return (
    <MSDSHazardsBlock>
      <Spin spinning={getClassificationsLoading || getStatementMapLoading}>
        <Form.Item
          label={<AlphabetLabel alphabet="A">GHS MarketingTemplateFormData</AlphabetLabel>}
          shouldUpdate={(prev, next) =>
            prev.hazards.ghsClassifications !== next.hazards.ghsClassifications
          }
        >
          {() => (
            <Form.List name={['hazards', 'ghsClassifications']} initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    const depth0GHSId = form.getFieldValue([
                      'hazards',
                      'ghsClassifications',
                      field.name,
                      'depth0GHSId',
                    ]);
                    const depth1GHSClassifications =
                      ghsClassifications.find(
                        ({ materialCategoryId }) => materialCategoryId === depth0GHSId,
                      )?.subClassifications || [];
                    const depth1GHSOptions =
                      depth1GHSClassifications.map(({ materialCategoryId, nameKo }) => ({
                        label: nameKo,
                        value: materialCategoryId,
                      })) || [];
                    const depth1GHSId = form.getFieldValue([
                      'hazards',
                      'ghsClassifications',
                      field.name,
                      'depth1GHSId',
                    ]);
                    const depth2GHSId = form.getFieldValue([
                      'hazards',
                      'ghsClassifications',
                      field.name,
                      'depth2GHSId',
                    ]);
                    const depth2GHSIds = form
                      .getFieldValue(['hazards', 'ghsClassifications'])
                      .map(({ depth2GHSId }: { depth2GHSId: number }) => depth2GHSId);

                    const depth2GHSOptions =
                      depth1GHSClassifications
                        .find(({ materialCategoryId }) => depth1GHSId === materialCategoryId)
                        ?.subClassifications.filter(
                          ({ materialCategoryId }) =>
                            !depth2GHSIds.includes(materialCategoryId) ||
                            materialCategoryId === depth2GHSId,
                        )
                        .map(({ materialCategoryId, nameKo }) => ({
                          label: nameKo,
                          value: materialCategoryId,
                        })) || [];
                    return (
                      <Row key={field.key} gutter={8} style={{ position: 'relative' }}>
                        <Col span={7}>
                          <Form.Item
                            name={[field.name, 'depth0GHSId']}
                            rules={[requireRule]}
                            normalize={(value, prevValue) => {
                              if (
                                form.getFieldValue([
                                  'hazards',
                                  'ghsClassifications',
                                  field.name,
                                  'depth2GHSId',
                                ])
                              ) {
                                Modal.confirm({
                                  ...refreshModalProps,
                                  onOk: () => {
                                    form.setFieldsValue({
                                      hazards: produce(
                                        form.getFieldValue(['hazards']),
                                        (proxy: any) => {
                                          proxy.ghsClassifications[field.name].depth0GHSId = value;
                                          proxy.ghsClassifications[field.name].depth1GHSId =
                                            undefined;
                                          proxy.ghsClassifications[field.name].depth2GHSId =
                                            undefined;
                                        },
                                      ),
                                    });
                                    handleRefreshClassifications();
                                  },
                                });
                                return prevValue;
                              } else if (
                                form.getFieldValue([
                                  'hazards',
                                  'ghsClassifications',
                                  field.name,
                                  'depth1GHSId',
                                ])
                              ) {
                                form.setFieldsValue({
                                  hazards: produce(
                                    form.getFieldValue(['hazards']),
                                    (proxy: any) => {
                                      proxy.ghsClassifications[field.name].depth1GHSId = undefined;
                                    },
                                  ),
                                });
                              }
                              return value;
                            }}
                          >
                            <Select options={depth0GHSOptions} placeholder="유해성 선택" />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name={[field.name, 'depth1GHSId']}
                            rules={[requireRule]}
                            normalize={(value, prevValue) => {
                              if (
                                form.getFieldValue([
                                  'hazards',
                                  'ghsClassifications',
                                  field.name,
                                  'depth2GHSId',
                                ])
                              ) {
                                Modal.confirm({
                                  ...refreshModalProps,
                                  onOk: () => {
                                    form.setFieldsValue({
                                      hazards: produce(
                                        form.getFieldValue(['hazards']),
                                        (proxy: any) => {
                                          proxy.ghsClassifications[field.name].depth1GHSId = value;
                                          proxy.ghsClassifications[field.name].depth2GHSId =
                                            undefined;
                                        },
                                      ),
                                    });
                                    handleRefreshClassifications();
                                  },
                                });
                                return prevValue;
                              }
                              return value;
                            }}
                          >
                            <Select
                              options={depth1GHSOptions}
                              placeholder="분류 선택"
                              dropdownClassName="ant-select-dropdown-174"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            name={[field.name, 'depth2GHSId']}
                            rules={[requireRule]}
                            normalize={(value, prevValue) => {
                              Modal.confirm({
                                ...refreshModalProps,
                                onOk: () => {
                                  form.setFieldsValue({
                                    hazards: produce(
                                      form.getFieldValue(['hazards']),
                                      (proxy: any) => {
                                        proxy.ghsClassifications[field.name].depth2GHSId = value;
                                      },
                                    ),
                                  });
                                  handleRefreshClassifications();
                                },
                                onCancel: () => {
                                  form.setFieldsValue({
                                    hazards: produce(
                                      form.getFieldValue(['hazards']),
                                      (proxy: any) => {
                                        proxy.ghsClassifications[field.name].depth2GHSId =
                                          prevValue;
                                      },
                                    ),
                                  });
                                },
                              });
                              return value;
                            }}
                          >
                            <Select
                              options={depth2GHSOptions}
                              placeholder="구분 선택"
                              dropdownStyle={{ width: 500 }}
                              dropdownClassName="ant-select-dropdown-174"
                            />
                          </Form.Item>
                        </Col>
                        {fields.length > 1 && (
                          <MinusIcon
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: 10,
                              right: -26,
                            }}
                            onClick={() => {
                              if (
                                form.getFieldValue([
                                  'hazards',
                                  'ghsClassifications',
                                  field.name,
                                  'depth2GHSId',
                                ])
                              ) {
                                Modal.confirm({
                                  ...refreshModalProps,
                                  onOk: () => {
                                    remove(field.name);
                                    handleRefreshClassifications();
                                  },
                                });
                              } else {
                                remove(field.name);
                              }
                            }}
                          />
                        )}
                      </Row>
                    );
                  })}
                  <Button
                    type="dashed"
                    block
                    icon={
                      <Icon
                        name="plus"
                        size={14}
                        color={fields.length >= 10 ? 'SLATE_GRAY60' : 'PRIMARY50'}
                      />
                    }
                    disabled={fields.length >= 10}
                    onClick={() => add({})}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    유해성·위험성 분류 추가
                  </Button>
                </>
              )}
            </Form.List>
          )}
        </Form.Item>
        <AlphabetLabel alphabet="B" style={{ marginBottom: 12 }}>
          GHS-Labelling
        </AlphabetLabel>
        <Form.Item label="Pictogram" style={{ marginBottom: 0 }} required>
          <Form.List name={['hazards', 'pictograms']} initialValue={[]}>
            {(fields, { add, remove }) => {
              const pictograms = form.getFieldValue(['hazards', 'pictograms']) as Pictogram[];
              const pictogramIds = pictograms.map(
                ({ materialMsdsStatementId }) => materialMsdsStatementId,
              );
              const addablePictograms = allPictograms.filter(
                ({ materialMsdsStatementId }) => !pictogramIds.includes(materialMsdsStatementId),
              );
              return (
                <Row gutter={[8, 8]} style={{ marginBottom: 16 }}>
                  {fields.map(({ key, name }) => (
                    <Col key={key}>
                      <Form.Item
                        name={[name, 'attachUrl']}
                        valuePropName="src"
                        style={{ marginBottom: 0 }}
                      >
                        <MSDSPictogram removable onClick={() => remove(name)} />
                      </Form.Item>
                    </Col>
                  ))}
                  {addablePictograms.length > 0 && (
                    <Col style={{ height: 56 }}>
                      <PictogramAddButton
                        onClick={() => {
                          const modal = Modal.info({
                            icon: null,
                            closable: true,
                            width: 520,
                            bodyStyle: {
                              padding: 24,
                            },
                            footer: null,
                            content: (
                              <MSDSPictogramSelectModalContent
                                pictograms={addablePictograms}
                                onSelectPictogram={(pictogram) => {
                                  add(pictogram);
                                  modal.destroy();
                                }}
                              />
                            ),
                            okButtonProps: { hidden: true },
                          });
                        }}
                      >
                        <Icon
                          name="plus"
                          size={14}
                          color="PRIMARY50"
                          style={{ transform: 'rotate(45deg)' }}
                        />
                      </PictogramAddButton>
                    </Col>
                  )}
                </Row>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item label="Signal Word" name={['hazards', 'signalWord']} rules={[requireRule]}>
          <Radio.Group>
            <Radio value="DANGER" style={{ marginRight: 16 }}>
              Danger
            </Radio>
            <Radio value="WARNING" style={{ marginRight: 16 }}>
              Warning
            </Radio>
            <Radio value="NONE" style={{ marginRight: 16 }}>
              None
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={
            <Row align="middle" gutter={4}>
              <Col>Hazard statement(s)</Col>
              <Col>
                <Typography.Text type="BODY_2" gutter={{ left: 4 }}>
                  입력 예시
                </Typography.Text>
              </Col>
              <Col>
                <Tip trigger="click" bodyStyle={{ width: 270 }}>
                  <Typography.Text type="SMALL" color="PRIMARY50">
                    - H200, Unstable explosive.
                  </Typography.Text>
                  <Typography.Text type="SMALL" color="PRIMARY50">
                    - H204, Fire or projection hazard
                  </Typography.Text>
                </Tip>
              </Col>
            </Row>
          }
          name={['hazards', 'hazardStatement']}
          initialValue=""
          rules={[requireRule, exceptKoreanRule]}
          normalize={normalizeStatement}
        >
          <Input.TextArea
            rows={6}
            maxLength={1500}
            autoSize={{ minRows: 6, maxRows: 6 }}
            showCount
          />
        </Form.Item>
        <FormLabel>Precautionary statement(s)</FormLabel>
        <Form.Item
          label={
            <Row align="middle" gutter={4}>
              <Col>
                <Typography.Text type="TITLE_2" color="PRIMARY50">
                  Prevention
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text type="BODY_2" gutter={{ left: 4 }}>
                  입력 예시
                </Typography.Text>
              </Col>
              <Col>
                <Tip trigger="click" bodyStyle={{ width: 300 }}>
                  <Typography.Text type="SMALL" color="PRIMARY50">
                    - P201, Obtain special instructions before use.
                  </Typography.Text>
                  <Typography.Text type="SMALL" color="PRIMARY50">
                    - P102, Keep out of reach of children.
                  </Typography.Text>
                </Tip>
              </Col>
            </Row>
          }
          name={['hazards', 'preventionStatement']}
          initialValue=""
          rules={[requireRule]}
          required={false}
          normalize={normalizeStatement}
        >
          <Input.TextArea
            rows={6}
            maxLength={1500}
            autoSize={{ minRows: 6, maxRows: 6 }}
            showCount
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Text type="TITLE_2" color="PRIMARY50">
              Response
            </Typography.Text>
          }
          name={['hazards', 'responseStatement']}
          initialValue=""
          rules={[requireRule, exceptKoreanRule]}
          required={false}
          normalize={normalizeStatement}
        >
          <Input.TextArea
            rows={6}
            maxLength={1500}
            autoSize={{ minRows: 6, maxRows: 6 }}
            showCount
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Text type="TITLE_2" color="PRIMARY50">
              Storage
            </Typography.Text>
          }
          name={['hazards', 'storageStatement']}
          initialValue=""
          rules={[requireRule, exceptKoreanRule]}
          required={false}
          normalize={normalizeStatement}
        >
          <Input.TextArea
            rows={6}
            maxLength={1500}
            autoSize={{ minRows: 6, maxRows: 6 }}
            showCount
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Text type="TITLE_2" color="PRIMARY50">
              Disposal
            </Typography.Text>
          }
          name={['hazards', 'disposalStatement']}
          initialValue=""
          rules={[requireRule, exceptKoreanRule]}
          required={false}
          normalize={normalizeStatement}
        >
          <Input.TextArea
            rows={6}
            maxLength={1500}
            autoSize={{ minRows: 6, maxRows: 6 }}
            showCount
          />
        </Form.Item>
        <Form.Item
          label={
            <AlphabetLabel alphabet="C">
              Other hazards which do not result in classification
            </AlphabetLabel>
          }
          rules={[requireRule, exceptKoreanRule]}
          name={['hazards', 'otherStatement']}
          required={false}
          initialValue="None Known"
        >
          <Input placeholder="유해성·위험성 분류기준에 포함되지 않는 기타 유해성·위험성" />
        </Form.Item>
      </Spin>
    </MSDSHazardsBlock>
  );
};

export default memo(MSDSHazards);

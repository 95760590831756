import { useParams } from 'react-router-dom';

import DocumentsUpload from 'components/brand/retailer/retailerApply/DocumentsUpload';
import ProductPageTemplate from 'components/brand/retailer/retailerApply/product/ProductPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import { DocumentCode } from 'types/brand/retailer/retailerApply';

const ProductCPRSPage = () => {
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  return (
    <ProductPageTemplate
      productId={productId}
      subtitle="협력업체 결제계좌 신고 확인서"
      notices={['금융기관에서 발급 받으실 수 있습니다.', <MistakeNoticeItem />]}
    >
      <DocumentsUpload documentCode={DocumentCode.CPRS} accept="application/pdf" />
    </ProductPageTemplate>
  );
};

export default ProductCPRSPage;

import { Button, Select, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SearchInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { useModal } from 'hook/useModal';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { QCQAProductListPageSearchParams } from 'pages/brand/qcqa/QCQAProductListPage';
import { useUser } from 'service/auth';
import {
  useDownloadQCQALotDocs,
  useDownloadQCQAProductDocs,
  useQCQALatesLotDocs,
  useQCQAProductList,
} from 'service/brand/qcqa/qcqa';
import {
  QCQALotDocsListItem,
  QCQALotDocumentUseType,
  QCQALotItem,
  QCQAProductListItem,
} from 'types/brand/qcqa';
import { CompanyDocsRecord } from 'types/brand/company';
import { downloadFilesAsZip } from 'lib/file';
import { useServerDateTimeNow } from 'service/common';

const QCQAProductList = ({
  queryParams,
  onChangeSearchParams,
}: {
  queryParams: QCQAProductListPageSearchParams;
  onChangeSearchParams: (state: Partial<QCQAProductListPageSearchParams>) => void;
}) => {
  const navigate = useNavigate();
  const user = useUser();
  const { page, productName, brandName } = queryParams;
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);
  const [searchType, setSearchType] = useState<'productName' | 'brandName'>('productName');
  const [isCanDeleteUser, setIsCanDeleteUser] = useState<boolean>(false);
  const [loadingMap, setLoadingMap] = useState<Record<number, boolean>>({});
  const { openAlertModal, openConfirmModal } = useModal();
  const {
    qcqaProductList,
    totalElements,
    isQCQAProductListLoading,
    deleteQCQAProduct,
    isDeleteQCQAProductLoading,
  } = useQCQAProductList({
    page,
    ...(searchType === 'productName' && { productName }),
    ...(searchType === 'brandName' && { brandName }),
  });
  const { qcqaProductDocs } = useDownloadQCQAProductDocs();
  const { qcqaLotDocsList } = useDownloadQCQALotDocs();
  const { qcqaLatestLotDocs } = useQCQALatesLotDocs();
  const { now } = useServerDateTimeNow();

  const isSuperUser = user.authority === 'SUPER_USER';

  const handleSearch = (searchValue: string) => {
    onChangeSearchParams({
      page: 1,
      productName: searchValue,
      brandName: searchValue,
    });
  };

  const handleChangePage = (page: number) => {
    onChangeSearchParams({
      page,
    });
  };

  const deleteProducts = () => {
    openAlertModal({
      content: (
        <Typography.Text type="BODY_2" style={{ whiteSpace: 'pre-wrap' }}>
          {`해당 제품의 등록된 정보가 삭제됩니다.
정말로 삭제하시겠습니까?`}
        </Typography.Text>
      ),
      onOk: () => {
        deleteQCQAProduct(selectedProductIds, {
          onSuccess: () => {
            message.success('삭제되었습니다.');
            handleChangePage(1);
          },
        });
      },
      okText: '삭제',
      okLoading: isDeleteQCQAProductLoading,
    });
  };

  const today = now ? now.slice(0, 10) : new Date().toISOString().split('T')[0];

  const handleDownloadDocs = async (qcqaProductId: number, zipName: string) => {
    let docsProductList: {
      productId: number;
      name: string;
      records: CompanyDocsRecord[];
    }[] = [];

    let docsLotList: QCQALotDocsListItem[] = [];

    let lotInfo: QCQALotItem;

    const createDocsZipData = (type?: string) => {
      const productZipData: Parameters<typeof downloadFilesAsZip>[0] = [];
      const productWithlotZipData: Parameters<typeof downloadFilesAsZip>[0] = [];

      const parentZipName = `[${today}] ${zipName}`;

      for (const docs of docsProductList) {
        for (const record of docs.records) {
          if (record.attaches && record.attaches?.length > 0) {
            productZipData.push({
              name: `${parentZipName}/${
                docsLotList.length > 0 && type !== 'product' ? `1. 제품서류` : docs.name
              }`,
              url: '',
            });
            for (const attach of record.attaches) {
              if (attach.filename)
                productZipData.push({
                  name: `${parentZipName}/${
                    docsLotList.length > 0 && type !== 'product'
                      ? `1. 제품서류/${docs.name}/${record.name}-${attach.filename}`
                      : `${docs.name}/${record.name}-${attach.filename}`
                  }`,
                  url: attach.uploadFileUrl,
                });
            }
          }
        }
      }

      if (docsLotList.length > 0 && type !== 'product') {
        for (const docs of docsLotList) {
          if (docs.attaches && docs.attaches.length > 0) {
            productWithlotZipData.push({
              name: `[${today}] ${zipName}/2. 제조번호 서류 - ${lotInfo.lotNo}`,
              url: '',
            });
            for (const attach of docs.attaches) {
              if (attach.filename) {
                productWithlotZipData.push({
                  name: `${parentZipName}/2. 제조번호 서류 - ${lotInfo.lotNo}/[${docs.name}] ${attach.filename}`,
                  url: attach.uploadFileUrl,
                });
              }
            }
          }
        }
      }

      return docsLotList.length > 0 && type === 'product'
        ? productZipData
        : [...productWithlotZipData, ...productZipData];
    };
    const download = async (type?: string) => {
      const zipData = createDocsZipData(type);

      if (zipData.length === 0) {
        message.warning('등록된 서류가 없습니다.');
        setLoadingMap((prev) => {
          prev[qcqaProductId] = false;
          return { ...prev };
        });
        return;
      }
      setLoadingMap((prev) => {
        prev[qcqaProductId] = true;
        return { ...prev };
      });

      if (!zipData) return;

      await downloadFilesAsZip(zipData, `[${today}] ${zipName}.zip`);

      setLoadingMap((prev) => {
        prev[qcqaProductId] = false;
        return { ...prev };
      });
    };

    const getLotDocsList = () => {
      qcqaLatestLotDocs(
        { qcqaProductId },
        {
          onSuccess: async (res) => {
            const data = res.data.result;

            const qcqaProductLotnoId = data?.qcqaProductLotnoId;

            if (qcqaProductLotnoId) {
              lotInfo = data;
              qcqaLotDocsList(
                { qcqaProductId, qcqaProductLotnoId },
                {
                  onSuccess: (res) => {
                    const docs = res.data.result;
                    if (docs.length > 0) {
                      docsLotList = docs
                        .filter(({ useType }) => useType === QCQALotDocumentUseType.LOT_NO)
                        .filter(({ attaches }) => attaches && attaches?.length > 0);

                      if (docsLotList.length > 0) {
                        openConfirmModal({
                          title: '',
                          width: 400,
                          content: (
                            <Flex style={{ textAlign: 'center' }} justify="center">
                              제품의 최신 제조번호 서류를 포함하여
                              <br />
                              다운로드하시겠습니까?
                            </Flex>
                          ),
                          bodyStyle: { paddingTop: 0 },
                          closeText: '제품 서류만 다운',
                          onCustomClose: () => {
                            download('product');
                          },
                          okText: '제조번호 서류 포함 다운',
                          onOk: () => {
                            download();
                          },
                        });
                      } else {
                        download();
                      }
                    }
                  },
                },
              );
            } else {
              download();
            }
          },
        },
      );
    };

    qcqaProductDocs(
      {
        qcqaProductId,
      },
      {
        onSuccess: (res) => {
          const data = res.data.result;
          const records = data.filter(
            (item) =>
              item.records &&
              item?.records?.length > 0 &&
              item.records?.filter(({ attaches }) => attaches?.length > 0),
          );

          if (records.length > 0) {
            docsProductList = records.map((item) => {
              const records = (item.records || []).reduce((a, b) =>
                a.recordDate > b.recordDate ? a : b,
              );
              return {
                productId: item.qcqaUserDocumentId,
                name: item.name,
                records: [records],
              };
            });
            getLotDocsList();
          } else {
            message.warning('등록된 제품 서류가 없습니다.');
          }
        },
      },
    );
  };

  const columns = useMemo<ColumnsType<QCQAProductListItem>>(
    () => [
      {
        title: 'No.',
        align: 'center',
        width: 64,
        render: (_, __, index) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {totalElements - index - (page - 1) * 10}
          </div>
        ),
      },
      {
        title: '제품명',
        width: '27.8%',
        dataIndex: 'productNameKo',
        render: (_, { productNameKo, productNameEn }) => (
          <Typography.Text type="BODY_2" color="GRAY90" medium>
            {productNameKo && productNameEn
              ? `${productNameKo} / ${productNameEn}`
              : productNameKo || productNameEn}
          </Typography.Text>
        ),
      },
      {
        title: '브랜드명',
        width: '21.7%',
        dataIndex: 'brandName',
        render: (_, { brandName }) => (
          <Typography.Text type="BODY_2" color="GRAY90" medium>
            {brandName || '-'}
          </Typography.Text>
        ),
      },
      {
        title: '등록자',
        width: '13%',
        render: (_, { registerUsername }) => (
          <Typography.Text style={{ fontSize: 14, color: palette.GRAY70, maxWidth: 88 }}>
            {registerUsername}
          </Typography.Text>
        ),
      },
      {
        title: '등록일',
        width: '13%',
        render: (_, { registerDate }) => registerDate?.slice(0, 10),
      },
      {
        width: '13%',
        align: 'center',
        onCell: () => ({
          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
          },
          style: {
            cursor: 'default',
          },
        }),
        render: (_, { qcqaProductId, productNameEn, productNameKo }) => (
          <Flex dir="column" gap={4} style={{ width: '100%' }}>
            <Button
              onClick={() => {
                handleDownloadDocs(qcqaProductId, `${productNameKo || productNameEn}`);
              }}
              style={{
                width: 92,
                height: 24,
                background: palette.GRAY20,
                borderRadius: 4,
                color: palette.GRAY50,
                border: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
                padding: '4px 8px',
                pointerEvents: loadingMap[qcqaProductId] ? 'none' : 'auto',
                cursor: loadingMap[qcqaProductId] ? 'not-allowed' : 'pointer',
                margin: '0 auto',
              }}
            >
              <Typography.Text
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: palette.GRAY50,
                  width: 66,
                }}
              >
                제품서류 다운
              </Typography.Text>
              <Icon
                name="download"
                color="GRAY50"
                loading={loadingMap[qcqaProductId]}
                size={loadingMap[qcqaProductId] ? 10 : 12}
              />
            </Button>
            <Tags.Mark
              type="arrow"
              color="blue"
              onClick={() =>
                navigate(`${path.qcqa.management.root}/${qcqaProductId}/lot`, {
                  state: {
                    rootPage: page,
                    productName,
                    brandName,
                  },
                })
              }
            >
              제조번호 관리
            </Tags.Mark>
          </Flex>
        ),
      },
    ],
    [qcqaProductList, loadingMap],
  );

  return (
    <Container>
      <Flex justify="space-between" align="center">
        <Flex gap={8}>
          <Select
            value={searchType}
            options={[
              {
                label: '제품명',
                value: 'productName',
              },
              {
                label: '브랜드명',
                value: 'brandName',
              },
            ]}
            style={{ width: 120 }}
            onSelect={(val) => setSearchType(val)}
          />
          <SearchInput
            defaultValue={productName}
            onSearch={handleSearch}
            style={{ width: 240 }}
            placeholder="검색"
          />
        </Flex>
        <Flex gap={8}>
          <Button
            type="primary"
            onClick={() => navigate(path.qcqa.outputs.root)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography.Text type="BODY_2" inline>
              생산실적 현황
            </Typography.Text>
          </Button>
          <Button
            icon={<Icon name="plus" size={18} color="PRIMARY50" />}
            onClick={() => navigate(path.qcqa.registration.product)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography.Text type="BODY_2" inline>
              제품 등록
            </Typography.Text>
          </Button>
          <Button
            className="download-btn"
            loading={isDeleteQCQAProductLoading}
            icon={
              <Icon
                name="delete"
                size={18}
                color={selectedProductIds.length === 0 ? 'GRAY60' : 'PRIMARY50'}
              />
            }
            disabled={selectedProductIds.length === 0}
            onClick={() => {
              if (!isCanDeleteUser) {
                openAlertModal({
                  content: '본인이 등록한 제품만 삭제할 수 있습니다.\n다시 선택해 주세요.',
                  noCancelButton: true,
                });
                return;
              }
              if (selectedProductIds.length > 0) {
                deleteProducts();
              }
            }}
          >
            삭제
          </Button>
        </Flex>
      </Flex>
      <Table
        columns={columns}
        rowKey="qcqaProductId"
        dataSource={qcqaProductList}
        loading={isQCQAProductListLoading}
        pagination={{
          current: page,
          onChange: handleChangePage,
          total: totalElements,
          pageSize: 10,
        }}
        onRow={(record) => ({
          onClick: (e) => {
            if (!(e.target as HTMLElement).querySelector('.ant-checkbox')) {
              navigate(`${path.qcqa.management.root}/${record.qcqaProductId}`);
            }
          },
          style: {
            cursor: 'pointer',
          },
        })}
        style={{ marginTop: 16 }}
        rowSelection={{
          selectedRowKeys: selectedProductIds,
          onChange: (key, selectedArr) => {
            if (isSuperUser) {
              setIsCanDeleteUser(true);
            } else {
              if (selectedArr.find(({ registerUserId }) => user.userId !== registerUserId)) {
                setIsCanDeleteUser(false);
              } else {
                setIsCanDeleteUser(true);
              }
            }

            setSelectedProductIds(key as number[]);
          },
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      padding: 10px 16px;
      height: 40px;
      line-height: 0;

      .ant-checkbox {
        top: 0;
      }

      &:first-of-type {
        padding: 10px 12px;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 8px 16px;
      max-width: 360px !important;

      .ant-checkbox-wrapper {
        display: flex;
      }
      .ant-checkbox {
        top: 0;
      }
      &:first-of-type {
        padding: 18px 12px 17px;
      }
    }
  }

  .ant-table-cell.ant-table-selection-column {
    cursor: default;
  }

  .download-btn {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .download-btn:disabled {
    background-color: ${palette.ETC_WHITE};
    border-color: ${palette.GRAY40};
    color: ${palette.GRAY50};
  }

  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin: 0 !important;
  }
`;

export default QCQAProductList;

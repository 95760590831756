import { Button, Col, Modal, Row, Statistic, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import StatisticCard from 'components/StatisticCard';
import { Typography } from 'components/system';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import path from 'lib/path';
import {
  useConfirmProductSamples,
  useProductSample,
  useProductSampleDocuments,
} from 'service/manufacturer/productSample';
import {
  ProductSampleDocument,
  ProductSampleDocumentCode,
  ProductSampleDocumentStatus,
  ProductSampleStatus,
} from 'types/manufacturer/productSample';
import { useNavigate } from 'react-router-dom';

const ProductSampleDetailBlock = styled.div``;

const ProductSampleDetail = ({ productSampleId }: { productSampleId: number }) => {
  const navigate = useNavigate();
  const { productSample, getLoading: getProductSampleLoading } = useProductSample(productSampleId);
  const { productSampleDocuments, getLoading } = useProductSampleDocuments(productSampleId);
  const { confirmProductSamples, isLoading: confirmLoading } = useConfirmProductSamples();
  const handleClickDocument = useCallback(
    (document: ProductSampleDocument) => {
      const {
        documentSeq: { documentCode },
        status,
      } = document;
      const getUpdateModeString = (status: ProductSampleDocumentStatus) => {
        return status === ProductSampleDocumentStatus.FIN ? '?updateMode=true' : '';
      };
      switch (documentCode) {
        case ProductSampleDocumentCode.BASIC: {
          navigate(`${path.manufacturer.productSample.basic}/${productSampleId}`);
          break;
        }
        case ProductSampleDocumentCode.RECIPE: {
          navigate(
            `${path.manufacturer.productSample.recipe}/${productSampleId}${getUpdateModeString(
              status,
            )}`,
          );
          break;
        }
        case ProductSampleDocumentCode.RDI: {
          navigate(
            `${
              path.manufacturer.productSample.recipeDetail
            }/${productSampleId}${getUpdateModeString(status)}`,
          );
          break;
        }
        case ProductSampleDocumentCode.PC: {
          const recipeDetailDocument = productSampleDocuments.find(
            ({ documentSeq: { documentCode } }) => documentCode === ProductSampleDocumentCode.RDI,
          )!;
          if (recipeDetailDocument.status === ProductSampleDocumentStatus.INP) {
            return message.warning(
              '공정도에 기입될 Phase 정보가 없습니다. 처방 상세 입력 후에 시도해 주세요.',
            );
          }
          navigate(
            `${path.manufacturer.productSample.processMap}/${productSampleId}${getUpdateModeString(
              status,
            )}`,
          );
          break;
        }
      }
    },
    [productSampleId, productSampleDocuments],
  );
  const handleClickConfirm = () => {
    if (productSample) {
      const { productSampleName, labNo } = productSample;
      Modal.confirm({
        icon: null,
        content: (
          <Typography.Text type="BODY_2" align="center">
            {productSampleName} ({labNo})
            <br />
            샘플을 확정하시겠습니까? 확정된 샘플은
            <br />
            ‘확정 완료 샘플 관리’ 메뉴에서 확인하실 수 있습니다.
          </Typography.Text>
        ),
        okText: '샘플 확정',
        onOk: () => {
          confirmProductSamples([productSampleId], {
            onSuccess: () => {
              navigate(path.manufacturer.productSample.list);
            },
          });
        },
      });
    }
  };
  const checkProductSampleIsConfirmable = () => {
    return (
      productSample &&
      productSample.status === ProductSampleStatus.RDY &&
      productSampleDocuments.every(({ status }) => status !== ProductSampleDocumentStatus.INP)
    );
  };
  const columns: ColumnsType<ProductSampleDocument> = useMemo(
    () => [
      {
        title: '구분',
        align: 'center',
        render: (_, __, index) => index + 1,
        width: '12%',
      },
      {
        title: '입력 정보',
        width: '50%',
        render: (_, document) => {
          const {
            documentSeq: { documentName },
            isOptional,
          } = document;
          return (
            <Typography.Text
              type="BODY_2"
              medium
              color="GRAY90"
              style={{ cursor: 'pointer' }}
              onClick={() => handleClickDocument(document)}
            >
              {documentName}
              {isOptional && (
                <Typography.Text type="SMALL" color="GRAY50" inline gutter={{ left: 8 }}>
                  (선택 입력)
                </Typography.Text>
              )}
            </Typography.Text>
          );
        },
      },
      {
        title: '처리 현황',
        align: 'center',
        render: (_, document) => {
          const { status } = document;
          switch (status) {
            case ProductSampleDocumentStatus.INP: {
              return (
                <Tags.Mark type="arrow" color="blue" onClick={() => handleClickDocument(document)}>
                  필수 입력
                </Tags.Mark>
              );
            }
            case ProductSampleDocumentStatus.OPT: {
              return (
                <Tags.Mark type="arrow" color="blue" onClick={() => handleClickDocument(document)}>
                  선택 입력
                </Tags.Mark>
              );
            }
            case ProductSampleDocumentStatus.FIN: {
              return (
                <Typography.Text type="BODY_2" color="GRAY70">
                  입력 완료
                </Typography.Text>
              );
            }
          }
        },
      },
      {
        title: '입력 일시',
        align: 'center',
        dataIndex: 'registerDt',
      },
    ],
    [handleClickDocument],
  );
  return (
    <ProductSampleDetailBlock>
      <StatisticCard style={{ marginBottom: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Statistic
              title="샘플명 가칭"
              value={productSample?.productSampleName || '-'}
              groupSeparator=""
            />
          </Col>
          <Col span={12}>
            <Statistic title="랩넘버" value={productSample?.labNo || '-'} groupSeparator="" />
          </Col>
        </Row>
      </StatisticCard>
      <Table
        columns={columns}
        dataSource={productSampleDocuments}
        rowKey="productSampleDocId"
        loading={getLoading}
        pagination={false}
      />
      {checkProductSampleIsConfirmable() && (
        <FooterBox>
          <Button
            loading={confirmLoading || getProductSampleLoading}
            type="primary"
            onClick={handleClickConfirm}
          >
            샘플 확정
          </Button>
        </FooterBox>
      )}
    </ProductSampleDetailBlock>
  );
};

export default ProductSampleDetail;

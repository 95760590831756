import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Col, Empty, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import { downloadFile, printFile } from 'lib/file';
import FooterBox from 'components/FooterBox';
import FileViewer from 'components/file/FileViewer';
import { useICIDEstimate } from 'service/material/icid/icid';
import * as estimateActions from 'modules/estimate';
import { RootState } from 'modules';
import { ICIDEstimateParam } from 'types/material/icid/icid';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    svg,
    path {
      fill: ${palette.PRIMARY50};
      transition: all 200ms ease;
    }
  }

  svg,
  path {
    transition: all 200ms ease;
  }
`;

const ICIDEstimate = ({ icidApplicationId }: { icidApplicationId: number }) => {
  const dispatch = useDispatch();
  const { addICIDEstimate, isLoading } = useICIDEstimate();
  const estimate = useSelector(
    ({ estimate }: RootState) => estimate.estimate as ICIDEstimateParam | null,
  );

  useEffect(() => {
    addICIDEstimate(icidApplicationId);
  }, []);

  const handleDownload = () => {
    downloadFile(estimate?.estimateFileUrl);
  };

  const handlePrint = () => {
    if (estimate) {
      printFile(estimate.estimateFileUrl);
    }
  };

  const handleMoveToNext = () => {
    dispatch(estimateActions.setContractStep('contract'));
  };

  if (isLoading) {
    return <Spin style={{ width: '100%', height: '400px' }} />;
  }

  if (!estimate?.estimateFileUrl) {
    return (
      <Container>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="발행한 견적내역이 없거나 이미 계약이 체결되었습니다."
        />
        <Row gutter={16}>
          <Col>
            <Link
              to={path.material.icid.requestList}
              style={{ textDecoration: 'underline', color: palette.PRIMARY50 }}
            >
              새로운 견적 받기
            </Link>
          </Col>
          <Col>
            <Link
              to={`${path.my}/estimate`}
              style={{
                textDecoration: 'underline',
                marginTop: 16,
                color: palette.PRIMARY50,
              }}
            >
              계약 보관함으로 이동
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row
        gutter={8}
        justify="end"
        style={{
          width: '100%',
          marginBottom: 8,
        }}
      >
        <Col>
          <StyledButton
            icon={<Icon name="download" size={14} />}
            style={{
              width: 144,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleDownload}
          >
            다운로드
          </StyledButton>
        </Col>
        <Col>
          <StyledButton
            icon={<Icon name="print" size={14} />}
            style={{
              width: 144,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handlePrint}
          >
            인쇄하기
          </StyledButton>
        </Col>
      </Row>
      <FileViewer title={' '} file={estimate?.estimateFileUrl} style={{ maxWidth: 1040 }} />
      <FooterBox>
        <Button type="primary" onClick={handleMoveToNext}>
          이 견적으로 계속 진행
        </Button>
      </FooterBox>
    </Container>
  );
};

export default ICIDEstimate;

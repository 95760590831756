import { CompanyGet } from './company';

export enum UserType {
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
  MATERIAL = 'MATERIAL',
}
export interface User {
  userId: number;
  companyId: number;
  company: CompanyGet;
  username: string;
  name: string;
  deptName: string;
  positionName: string;
  manufacturerId: number;
  manufacturerBizNumber?: string;
  authority: string;
  email: string;
  mobile: string;
  status: string;
  registerDt: Date;
  lastLoginDt: Date;
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;
  isSmsAgreed?: boolean;
  isEmailAgreed?: boolean;
  isResetPassword: boolean;
  token: string;
  refreshToken: string;
  redirectUrl: string;
  userType: UserType;
}
export interface UserRegister {
  isSuperUser: boolean;
  companyId: number;
  username: string;
  password: string;
  name: string;
  deptName: string;
  positionName: string;
  manufacturerId: number;
  manufacturerBizNumber?: string;
  email: string;
  mobile: string;
  isResetPassword: boolean;
  userType: string;
  directTelNumber: string;
}
export interface UserUpdate {
  userId: number;
  name: string;
  deptName: string;
  positionName: string;
  manufacturerId: number;
  manufacturerBizNumber: string;
  authority: string;
  email: string;
  mobile: string;
  status: string;
  isSmsAgreed: boolean;
  isEmailAgreed: boolean;
  userType: string;
  directTelNumber?: string;
  isPermitFormulaBreakdown: boolean;
}

export interface Manager {
  authority: string;
  company: CompanyGet;
  companyId: number;
  deptName: string;
  directTelNumber: string | null;
  email: string;
  isEmailAgreed: boolean | null;
  isEmailVerified: boolean | null;
  isPhoneVerified: boolean | null;
  isResetPassword: boolean;
  isSmsAgreed: boolean;
  lastLoginDt: string | null;
  manufacturerBizNumber: string | null;
  manufacturerId: number | null;
  mobile: string;
  name: string;
  positionName: string;
  redirectUrl: string | null;
  refreshToken: string | null;
  registerDt: string;
  status: string;
  token: string | null;
  userId: number;
  userType: UserType;
  username: string;
}

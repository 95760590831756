import { message } from 'antd';
import axios, { AxiosInstance } from 'axios';
import QueryString from 'qs';

import { store } from 'index';
import { reissueToken } from 'lib/api/auth';
import { getReactEnvVar } from 'lib/common';
import { createFormData } from 'lib/file';
import path from 'lib/path';
import Storage from 'lib/storage';
import { logout } from 'modules/auth.js';

/**
 * 에러 코드
 * NO_ERROR = "0";
 * NO_ERROR_CUSTOM = "1";
 * REGISTER_ALREADY_BIZ_NUMBER = "-1";
 * REGISTER_ALREADY_USERNAME = "-2";
 * REGISTER_ALREADY_SUPERUSER = "-3";
 * LOGIN_UNAUTHORIZED = "-5";
 * INVALID_REFRESHTOKEN_ERROR = "-6";
 * JWT_EXPIRE_ERROR = "-7";
 * VALIDATE_INVALID_EMAIL = "-10";
 * AUTHENTICATION_FAILED = "-13";
 * NOT_REGISTERED_USERNAME = "-14";
 * FILE_UPLOAD_ERROR = "-15";
 * INVALID_PRODUCT = "-20";
 * EMPTY_CART = "-25";
 * INVALID_PRODUCT_COMPONENT = "-26";
 * NOT_REGISTERED_DEVICE = "-30";
 * NO_SUCH_COMPANY_ERROR = "-31";
 * NO_SUCH_PRODUCT_ERROR = "-32";
 * REGISTER_ALREADY_COUNTRY_ERROR = "-33";
 * REGISTER_ALREADY_CATEGORY_ERROR = "-34";
 * REGISTER_ALREADY_CHEMICAL_PROPERTY_ERROR = "-35";
 * REGISTER_ALREADY_CHALLENGE_TEST_ERROR = "-36";
 * REGISTER_ALREADY_MICROBIOLOGICAL_PROPERTY_ERROR = "-37";
 * REGISTER_ALREADY_PHYSICAL_PROPERTY_ERROR = "-38";
 * REGISTER_ALREADY_STABILITY_TEST_ERROR = "-39";
 * BAD_REQUEST = "-90";
 * SERVER_ERROR = "-99";
 */

const baseURL = `${getReactEnvVar('SERVER_URL')}/api/v1`;
/**
 * HINT: api 주소에 /api가 붙지 않는 경우 exceptURLs에 추가
 */
const exceptURLs = ['/files/cfs/products', '/files/pcpa/products'];

const client: AxiosInstance = axios.create({
  baseURL,
  paramsSerializer: (params) =>
    QueryString.stringify(params, {
      encode: true,
    }),
});

client.interceptors.request.use((config) => {
  if (/^\/v\d+/.test(config.url || '')) {
    config.baseURL = `${getReactEnvVar('SERVER_URL')}/api`;
  }
  if (exceptURLs.some((url) => config.url?.startsWith(url))) {
    config.baseURL = `${getReactEnvVar('SERVER_URL')}`;
  }
  if (
    config.data &&
    Object.values(config.data).find((value) => {
      return value instanceof File || (value instanceof Array && value[0] instanceof File);
    })
  ) {
    config.data = createFormData(config.data);
  }
  return config;
});

client.interceptors.response.use(
  (response) => {
    const { url, method } = response.config;

    // 특정 url요청은 정상 응답 처리
    if (
      url?.startsWith('/auth/brand/validate') ||
      url?.startsWith('/files/cfs/products') ||
      url?.startsWith('/files/pcpa/products') ||
      url?.startsWith(`${getReactEnvVar('SERVER_URL')}/files`) ||
      url?.startsWith(`/v2/qcqa/products/outputs/excel`)
    ) {
      return response;
    }

    const [error] = response.data.errors;
    const { code, message: errorMessage } = error;

    if (code === '0') {
      return response;
    }

    if (
      (url?.startsWith('/v2/auth/company/biz-number/duplicate') && code === '-1') ||
      code === '-17' ||
      url?.startsWith('/nota/') ||
      (url?.startsWith('/v2/auth/username/duplicate') && code === '-2')
    ) {
      throw error;
    }

    // 세션 만료
    if (code === '-6' || code === '-7') {
      // 세션 만료
      window.location.href = `${path.logout}?reason=expire`;
      throw error;
    }

    // 비밀번호 찾기
    if (code === '-14' && url?.startsWith('/auth/password/find')) {
      message.warning(errorMessage);
      throw error;
    }

    // 비밀번호 변경 실패
    if (code === '-13' && url === '/v2/auth/password' && method === 'patch') {
      // 비밀번호 변경
      message.warning('이전 비밀번호가 일치하지 않습니다.');
      throw error;
    }

    // 토큰 에러
    if (
      (url?.startsWith('/auth/login/auto') && code === '-99') ||
      code === '-5' ||
      code === '-14' ||
      code === '-16'
    ) {
      window.location.href = `${path.logout}?reason=tokenError`;
      throw error;
    }

    // HINT : 비밀번호 변경시 에러 처리
    if (code === '-13' && url?.startsWith('/users/password')) {
      throw error;
    }
    // HINT : 비밀번호 매칭 실패
    if (code === '-13') {
      clearToken();
      throw error;
    }

    // 파일 용량 에러
    if (code === '-134') {
      message.warning(errorMessage);
      throw error;
    }

    //HINT: Document api 호출시 파일 오류일 경우 에러 무시
    if (url?.includes('completion-document-files') && code.includes('-')) {
      return response;
    }

    // 공통 에러 처리
    console.error('[RESPONSE ERROR]', response.data.errors[0]);
    message.error(response.data.errors[0].message);
    throw error;
  },
  async (error) => {
    console.error('[RESPONSE ERROR]', error);

    const originalRequest = error.config;

    // 세션 만료시 다시 로그인
    if (error.response && !originalRequest._retry && error.response.status === 401) {
      originalRequest._retry = true;
      const storedRefreshToken = Storage.getItem('auth.refreshToken');
      if (!storedRefreshToken) {
        window.location.href = `${path.logout}?reason=expire`;
        return;
      }
      clearToken(true);
      const {
        data: { result },
      } = await reissueToken(storedRefreshToken);
      const { token, refreshToken } = result;
      setToken(token, refreshToken);

      originalRequest.headers.Authorization = `Bearer ${token}`;
      return client(originalRequest);
    }
    if (error.message) {
      if (error.message === 'Network Error') {
        message.error('Network Error : 잠시 후 다시 시도해 주세요.');
      } else {
        message.error(error.message);
      }
    }
    return Promise.reject(error);
  },
);

export default client;

export function setToken(token: string, refreshToken: string) {
  client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  Storage.setItem('auth.refreshToken', refreshToken);
}

export function clearToken(noNeedDispatch?: boolean) {
  delete client.defaults.headers.common['Authorization'];
  Storage.removeItem('auth.refreshToken');
  if (!noNeedDispatch) {
    store.dispatch(logout(true));
  }
}

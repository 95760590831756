// Product Basic
import { CountryProhibitInfo } from './formula';
import { DocumentCode as ICIDDocumentCode } from 'types/material/common';

export enum DocStatus {
  INP = 'INP', // 필수 입력
  OPT = 'OPT', // 선택 입력
  MOD = 'MOD', // 보완 필요
  ONG = 'ONG', // 검토 중
  FIN = 'FIN', // 검토 완료
  CHK = 'CHK', // 확인 완료
}

export enum DocumentCode {
  BASIC = 'basic',
  CI = 'ci',
  PN = 'pn',
  CAT = 'cat',
  COUNTRY = 'country',
  PHY = 'phy',
  HMMS = 'hmms', // Heavy Metals & Microbiological Standard
  CHE = 'che',
  MIC = 'mic',
  FORMBR = 'formbr',
  FORMBRC = 'formbrc',
  ART = 'art',
  CGMP = 'cgmp',
  STAB = 'stab',
  CHAL = 'chal',
  PACK = 'pack',
  MANUC = 'manuc', //Manufacturing Process (China)
  IFRA = 'ifra',
  ALLE = 'alle',
  RMCOA = 'rmcoa',
  RMMSDS = 'rmmsds',
  AI = 'ai',
  BI = 'bi',
  THIRD = 'third',
  CFS = 'cfs',
  PCC = 'pcc',
  IRC = 'irc',
  LOGOAI = 'logoai',
  COBRM = 'cobrm',
  ROPQM = 'ropqm',
  SLD = 'sld',
  PS = 'ps',
  CV = 'cv',
  KW = 'kw',
  SWI = 'swi',
  SPI = 'spi',
  UPSI = 'upsi',
  FCCAD = 'fccad',
  VCRPAR = 'vcrpar',
  VCRPAC = 'vcrpac',
  PIF = 'pif', // 제품 정보
  MD = 'md', // Manufacturer's Declarations
  SMP = 'smp', // Statement Of Microbiological Purity
  POM = 'pom', // Period On Markets
  PI = 'pi', // Proudct Images
  PEI = 'pei', // 제품 효능 정보
  PMA = 'pma', // 원료 자료
  PQS = 'pqs', // 검역 샘플 정보
  PEEC = 'peec', // 효능 평가 자료
  PCPA = 'pcpa', // 위탁가공협의서 (생산위임계약서)
  CLOD = 'clod', // 기타 자료 (클라우드)
  EDC = 'edc', // 기타 자료 (인증)
  ANE = 'ane', //Annex 14
  RP = 'rp', // RP
  MF = 'mf', // 제조사
  FDAAR = 'fdaar', //FDA
}

// HINT: api 요청시 사용하는 DocumentCode
export enum DocumentIndexCode {
  BASIC = 'BASIC01',
  PN = 'PN01',
  CI = 'CI01',
  BI = 'BI01',
  COUNTRY = '  COUNTRY01',
  CAT = 'CAT01',
  PHY = 'PHY01',
  PI = 'PI01',
  CHE = 'CHE01',
  MIC = 'MIC01',
  FORM = 'FORM01',
  FORMC = 'FORMC01',
  FORMBR = 'FORMBR01',
  FORMBRC = 'FORMBRC01',
  ART = 'ART01',
  COA = 'COA01',
  MSDS = 'MSDS01',
  SPEC = 'SPEC01',
  CGMP = 'CGMP01',
  STAB = 'STAB01',
  CHAL = 'CHAL01',
  PACK = 'PACK01',
  MANU = 'MANU01',
  MANUC = 'MANUC01',
  SAFE = 'SAFE01',
  ALLE = 'ALLE01',
  IFRA = 'IFRA01',
  NONANIM = 'NONANIM01',
  NONHEXA = 'NONHEXA01',
  NONMIC = 'NONMIC01',
  HMDC = 'HMDC01',
  UNDER = 'UNDER01',
  MBIO = 'MBIO01',
  TPCOA = 'TPCOA01',
  TPHM = 'TPHM01',
  RMCOA = 'RMCOA01',
  RMMSDS = 'RMMSDS01',
  THIRD = 'THIRD01',
  CFS = 'CFS01',
  LOGOAI = 'LOGOAI01',
  COBRM = 'COBRM01',
  FOAC = 'FOAC01',
  MD = 'MD01',
  ROPQM = 'ROPQM01',
  CFF = 'CFF01',
  SLD = 'SLD01',
  IRC = 'IRC01',
  PS = 'PS01',
  SPI = 'SPI01',
  CV = 'CV01',
  KW = 'KW01',
  SWI = 'SWI01',
  UPSI = 'UPSI01',
  FCCAD = 'FCCAD01',
  VCRPAR = 'VCRPAR01',
  VCRPAC = 'VCRPAC01',
  PRNIL = 'PRNIL01',
  SMP = 'SMP01',
  PQS = 'PQS01', // 검역 샘플 정보
  PIF = 'PIF01', // 제품 정보
  PEI = 'PEI01', // 제품 효능 정보
  PMA = 'PMA01', // 원료 자료
  PEEC = 'PEEC01', // 효능 평가 자료
  PCPA = 'PCPA01', // 위탁 가공 협의서 (생산위임계약서)
  EDC = 'EDC01', //기타 자료 (인증)
  ANE = 'ANE01', //Annex 14
  RP = 'RP01', // RP 정보
  FDA = 'FDAAR01', // RP 정보
  MF = 'MF01', // 제조사 정보
}

export type AllDocumentCodeType = DocumentCode | ICIDDocumentCode;

export interface ProductListPageState {
  page: number;
  searchType: ProductSearchType;
  searchKeyword: string;
}

export enum ProductSearchType {
  ALL = 'ALL',
  PRODUCT_NAME = 'PRODUCT_NAME',
  COUNTRY_NAME = 'COUNTRY_NAME',
  MANUFACTURER_NAME = 'MANUFACTURER_NAME',
}

export interface ProductBasic {
  productId: number;
  countryId: number;
  status: ProductStatus;
  manufacturerId: number;
  netWeight: number;
  netWeightUnit: string;
  productDetail: ProductDetail;
  productDetails: ProductDetail[];
}

export interface ProductDetail {
  productDetailId: number;
  productId: number;
  countryId: number | undefined;
  brandNameEn: string;
  countryBrandName?: string;
  productNameEn: string;
  productNameKo: string;
  netWeight: string;
  netWeightUnit: string;
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerTel: string;
  manufacturerZipCode: number;
  countryProductName: string;
  isSeriesProduct: boolean;
  country: {
    countryCode: string;
  } | null;
}

export interface ProductAddParams {
  companyId: number;
  brandNameEn: string;
  productNameKo: string;
  productNameEn: string;
  manufacturerId: number;
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerZipCode: number;
  manufacturerTel: string;
  manufacturerFax?: string;
  netWeight: number;
  netWeightUnit: string;
}

export interface ProductUpdateParams extends Partial<ProductAddParams> {
  productId: number;
  countryId?: number;
  countryBrandName?: string;
  countryProductName?: string;
  iso22716ExpireDate?: string;
  productExpireMonths?: number;
  status?: ProductStatus;
}
export interface Product extends ProductBasic {
  manufacturerStatus?: string;
  netWeight: number;
  netWeightUnit: string;
  registerDt: string;
  updateDt?: string;
  manufacturerItemNo?: number;
  manufacturerItemNameKo?: string;
  brandCompanyNameKo?: string;
  companyFactoryId?: number;
  authCode: any;
  authenticatedBrandCompanyId?: number;
  isUsePackingAttestation?: boolean;
  packingAttestationType?: string;
  productCountries: ProductCountry[];
  productLotNos: any;
  productLabNos: any;
  productCategory: ProductCategory;
}

export interface ProductItem {
  productId: number;
  status: ProductStatus;
  netWeight: number;
  netWightUnit: string;
  productDetail: {
    productDetailId: number;
    productNameEn: string;
    manufacturerNameKo: string;
  };
  productCategory: ProductCategory | null;
  productCountries: ProductCountry[] | null;
  registerDt: string;
  updateDt: string | null;
}
export interface ProductDocStatus {
  productDocStatusId: number;
  documentSeqId: number;
  documentCode: DocumentCode;
  uploadFileUrl: string;
  documentUrl: string;
  filename: string;
  status: DocStatus;
  correctRequestMessage: string | null;
}

export enum ThirdCategoryType {
  NORMAL = 'NORMAL',
  FUNCTIONAL = 'FUNCTIONAL',
}

export interface ProductCategory {
  productCategoryId: number;
  productId: number;
  cosmeticCategoryIdDepth1: number;
  cosmeticCategoryIdDepth2: number;
  isFunctional: boolean;
  functionalCategoryId: number | null;
}

export interface CosmeticCategoryBasic {
  cosmeticCategoryId: number;
  depth: number;
  name: string;
  europeCategory: string | null;
  example: string | null;
  indiaCategory1: string | null;
  indiaCategory2: string | null;
  indiaCategory3: string | null;
}

export interface CosmeticCategory extends CosmeticCategoryBasic {
  parentId: number | null;
  children: CosmeticCategoryChild[];
}

export interface CosmeticCategoryChild extends CosmeticCategoryBasic {
  parentId: number;
  children: CosmeticCategoryChild[];
  detailCategoriesOfCommon: FunctionalCategory[];
  detailCategoriesOfFunctional: FunctionalCategory[];
}

export interface FunctionalCategory {
  functionalCategoryId: number;
  name: string;
}

export interface ProductCategoryAddParams {
  productId: number;
  cosmeticCategoryIdDepth1: number;
  cosmeticCategoryIdDepth2: number;
  isFunctional: boolean;
  functionalCategoryId?: number | null;
}

export interface ProductCountry {
  productCountryId: number;
  productId: number;
  countryId: number;
  country: Country;
  productNameKo?: string;
  productNameEn?: string;
  registerDt: string;
  updateDt?: string;
}

export interface Country {
  countryId: number;
  countryCode: string;
  countryNameKo: string;
  countryNameEn: string;
  programCost: any;
  isHaveChecklist: boolean;
  functionalCertAuthority: any;
  nonFunctionalCertAuthority: any;
}

export enum ProductStatus {
  PRD_RDY = 'PRD-RDY', // 등록중
  PRD_REG = 'PRD-REG', // 제품등록완료
  PRD_DEL = 'PRD-DEL', // 제품정보삭제
}
// Challenge Test
export interface ProductChallengeTest {
  productChallengeTestId: number;
  productId: number;
  isReportExist: boolean;
  bacteriaTemperature?: number;
  yeastTemperature?: number;
  moldTemperature?: number;
  isBacteriaTemperatureNone?: boolean;
  isYeastTemperatureNone?: boolean;
  isMoldTemperatureNone?: boolean;
  isMergeBacteria?: boolean;
  isMergeYeast?: boolean;
  isMergeMold?: boolean;
  saureus?: number;
  saureusExponent?: number;
  isSaureusTntc?: boolean;
  ecoli?: number;
  ecoliExponent?: number;
  isEcoliTntc?: boolean;
  paeruginosa?: number;
  paeruginosaExponent?: number;
  isPaeruginosaTntc?: boolean;
  bacillus?: number;
  bacillusExponent?: number;
  isBacillusTntc?: boolean;
  enterobacter?: number;
  enterobacterExponent?: number;
  isEnterobacterTntc?: boolean;
  yeastName?: string;
  yeast?: number;
  yeastExponent?: number;
  isYeastTntc?: boolean;
  moldName?: string;
  mold?: number;
  moldExponent?: number;
  isMoldTntc?: boolean;
  mergeBacteria?: number;
  mergeBacteriaExponent?: number;
  isMergeBacteriaTntc?: boolean;
  mergeYeast?: number;
  mergeYeastExponent?: number;
  isMergeYeastTntc?: boolean;
  mergeMold?: number;
  mergeMoldExponent?: number;
  isMergeMoldTntc?: boolean;
  countryId?: number;
  isSaureus?: boolean;
  isEcoli?: boolean;
  isPaeruginosa?: boolean;
  isBacillus?: boolean;
  isEnterobacter?: boolean;
}
export interface ProductChallengeTestAddParam extends Partial<ProductChallengeTest> {
  productId: number;
}
export interface ProductChallengeTestUpdateParam extends Partial<ProductChallengeTest> {
  productChallengeTestId: number;
}

export interface ProductChallengeTestForm extends Partial<ProductChallengeTest> {}

// Product specification
export interface ProductSpecification {
  countryOrigin: string;
  bestBefore: string;
  productType: string;
  targetAge: string;
  skinType: string;
  applicationArea: string;
  useFrequency: string;
  productPeriod: number;
  productPeriodUnit: string;
  packaging: number;
  packagingUnit: string;
  carton: number;
  cartonUnit: string;
  cartonWeight: number;
  cartonWeightUnit: string;
  cartonSize_W: number;
  cartonSize_D: number;
  cartonSize_H: number;
  cartonSizeUnit: string;
  useManual: string;
  warning: string;
  productFeature: string;
  marketingClaims: string;
  miscellaneous: string;
  inciList: string;
  pao: number;
  paoUnit: string;
  tempSaved: boolean;
}

export interface ShelfLifeDeclaration {
  productExpireMonths: number;
}

export interface ProductDuplicatedCheckParams {
  productNameEn: string;
  netWeight: number;
  netWeightUnit: string;
}

// Formula
export interface ProductFormulaBreakdownBasic {
  no: number;
  noSync?: number;
  noRowSpan: number;
  inciName: string;
  formulaWt: string;
  formulaWtRowSpan: number;
  formulaRawMaterialPercent: string;
  formulaSubstancePercent: string;
  formulaFunction: string;
  formulaCasNo: string;
  bigo?: string | null;
  useDescription: string | null;
  limitedFormulaCountryInfos: CountryProhibitInfo[];
}

export interface ProductFormulaBreakdownDraftBasic {
  isWtSumError: boolean;
  isSubstanceSumError: boolean;
  uploadedExcelUrl: string | null;
}
export interface ProductFormulaBreakdownItem extends ProductFormulaBreakdownBasic {
  isJapanCitesProhibited: boolean | null;
  isJapanProhibited: boolean | null;
  remark: string | null;
}

export interface ProductFormulaBreakdownDraft extends ProductFormulaBreakdownDraftBasic {
  list: ProductFormulaBreakdownItem[];
}

// Formula (China)
export interface ProductFormulaBreakdownChinaItem extends ProductFormulaBreakdownBasic {
  productFormulaBreakdownChinaId: number;
  nmpaDeclarationCode: string | null;
  hasAnnex14: boolean | null;
  tradeName: string | null;
  supplier: string | null;
  chineseIngredientName: string;
  remark: string | null;
  formulaTypeList: FormulaTypeList[];
  productId?: number;
}

export interface ProductFormulaBreakdownChinaDraft extends ProductFormulaBreakdownDraftBasic {
  list: ProductFormulaBreakdownChinaItem[];
}

export interface FormulaTypeList {
  inciName: string;
  casNo: string;
  ecNo: string;
  elnecsNo: string;
  baseDate: string;
  fdaAnnouncedColorName: string | null;
}
export interface PeriodOnMarkets {
  productPeriodOnMarketId: number;
  productLauncherYear: number;
  productLauncherMonth: number;
  productSalePeriod: number;
  productSalePeriodUnit: 'MONTH' | 'YEAR';
  uploadFileUrl: string;
  uploadFileName: string;
}

export interface PeriodOnMarketsForm {
  year: number;
  month: number;
  salePeriod: number;
  salePeriodUnit: 'MONTH' | 'YEAR';
}

export interface ProductEtcDatas {
  totalFileSize: number;
  productEtcDatas: ProductEtcData[];
}

export interface ProductEtcData {
  productEtcDataId?: number;
  file?: File;
  filename: string;
  uploadFileUrl?: string;
  fileSize: number;
  registerDt: string;
}

export interface AddProductAnnex14Params {
  countryId?: number;
  params: {
    uploadFile: File;
    tradeName: string;
  }[];
}

export interface ProductAnnex14Item {
  productAnnex14Id: number;
  tradeName: string;
  annex14FileUrl: string;
  annex14Filename: string;
}

export interface UpdateProductAnnex14Params {
  countryId?: number;
  params: {
    uploadFile?: File;
    productAnnex14Id?: number;
    tradeName?: string;
    isDelete?: boolean;
  }[];
}

export interface ProductArtworkCountry {
  productCountryId: number;
  productId: number;
  countryId: number;
  country: Country;
  productNameKo: string;
  productNameEn: string;
  registerDt: string;
  updateDt: string | null;
}

export interface ProductCountryArtwork {
  countryId: number | null;
  documentUrl: string | null;
  filename: string;
  name: string;
  productArtworkFileId: string;
  productArtworkId: number;
  productId: number;
  targetCountryId: number;
  uploadFileUrl: string;
  url: string;
}

export interface AddProductArtworkParams {
  productId?: number;
  countryId?: number;
  isConfirmOnly?: boolean;
  deleteProductArtworkIds?: number[];
  productArtworkFiles?: {
    targetCountryId: number;
    files: File[];
  }[];
}

export interface ProductArtworks {
  isLegacyProductArtwork: boolean;
  productArtworks: {
    uploadFileUrl: string;
    documentUrl: string;
    filename: string;
    targetCountryId: number;
  }[];
}

export enum ReportCode {
  ANE = 'ANE', // 문서가 존재할 수 없음.
  CNE = 'CNE', // 조건에 따라 문서가 존재함.
  ED = 'ED', // 문서가 있음.
}

export enum CertificateInfoFormType {
  C = 'C', // CDRI Form
  M = 'M', // Manufacturer Form
  B = 'B', // Brand Form
  E = 'E', // ETC Form
}

export interface GeneratableDocumentReport {
  targetDocumentCode: string;
  reportCode: ReportCode;
  canGenerate: boolean;
  message: string | null;
}

export interface CertificateDocumentFileProps {
  documentName: string;
  documentUrl: string | null;
  documentCode: DocumentIndexCode;
  uploadFileUrl: string | null;
  formType: CertificateInfoFormType;
  originalUploadFileName: string | undefined;
  filenames?: string[] | null;
  documentUrls?: string[] | null;
  uploadFileUrls?: string[] | null;
  generatableDocumentReport: GeneratableDocumentReport;
}

export const docBlackList = [
  'Non-Animal Testing Declaration',
  'Free of alcohol and coenzyme Q10 statement',
];

export interface ProductCompletedDocument {
  productDocStatusId: number | null;
  documentName: string;
  documentCode: DocumentIndexCode;
  isRequireUserRegister: boolean | null;
  status: DocStatus;
  statusString: string;
  updateDate: string;
  uploadFileUrl: string | null;
  documentUrl: string | null;
  formType: CertificateInfoFormType;
  correctRequestMessage: string | null;
  modifierId: number | null;
  modifierName: string | null;
  modifierCompanyName: string | null;
  originalUploadFileName: string | null;
  isDisplayed: boolean;
  isPermit: boolean;
  generatableDocumentReport: GeneratableDocumentReport;
}

export interface ProductETCCertifications {
  productEtcCertificationId: number;
  filename: string;
  uploadFileUrl: string;
  registerDt: string;
}

export enum CompanySize {
  CORPORATE = 'CORPORATE', // 중소기업 X
  MEDIUM = 'MEDIUM', // 중소기업 O
}

export interface ManufacturerInfo {
  productId: number;
  productDetailId: number;
  manufacturerId: number;
  manufacturerNameKo: string;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerTel: string;
  manufacturerIsSmallCompany: CompanySize | null;
  manufacturerFeiNumber: string | null;
}
export interface FDAAccountItem {
  fdaAccountId: number;
  fdaAccount: string;
  fdaPassword: string;
}

export enum RPCompanyType {
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
  PACKER = 'PACKER',
}

export enum BusinessType {
  CORPORATE = 'CORPORATE',
  MEDIUM = 'MEDIUM',
}

export interface MyRPItem {
  responsiblePersonId: number;
  countryId: number;
  companyName: string;
  companyAddress: string;
  companyTel: string | null;
  companyEmail: string | null;
  companyHomepage: string | null;
  companyType: RPCompanyType;
  companySize: BusinessType | null;
  updateDt?: string;
  companyId?: number | null;
}

export interface UsingProductRPItem {
  responsiblePersonId: number;
  companyName: string;
  products: {
    productNameEn: string;
    productId: number;
    netWeight: string;
    netWeightUnit: string;
  }[];
}

export interface ProductRPItem {
  productResponsiblePersonId: number;
  responsiblePerson: MyRPItem;
}

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import QCQAProduct from 'components/brand/qcqa/QCQAProduct/QCQAProduct';
import QCQAPageTemplate from 'templates/QCQAPageTemplate';

const notices = [
  '화장품책임판매업자의 주소가 잘못된 경우, 마이페이지 > 회원정보 수정에서 확인하실 수 있습니다.',
  '제품 서류는 개정이력으로 관리되며 항목 수정은 마이페이지 > 공통 서류 관리에서 가능합니다.',
  '인증 활용 서류는 영문 자료와 함께 등록해 두시면 해외인증 서비스 진행 시 간편하게 사용할 수 있습니다.',
  <MistakeNoticeItem />,
];

const QCQAProductPage = () => {
  return (
    <QCQAPageTemplate title="품질 제품 등록" notices={notices}>
      <QCQAProduct />
    </QCQAPageTemplate>
  );
};

export default QCQAProductPage;

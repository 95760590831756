import { useLayoutEffect } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductPeriodOnMarkets from 'components/product/ProductPeriodOnMarkets';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '해당 제품이 시장 내에서 총 유통된 기간을 작성하는 성명서입니다.',
  '하단의 파일 다운로드 버튼 클릭 시, 입력하신 정보가 기입된 양식을 받아보실 수 있습니다.',
  '브랜드사 책임자의 이름, 직함을 기입 후 서명을 완료한 파일을 스캔하셔서 다시 업로드해 주세요.',
  '이미 해당 제품의 Period on Markets 서류를 다른 국가에서 업로드하신 경우는, 기 업로드하신 파일을 확인하실 수 있습니다.',
  <MistakeNoticeItem />,
];

const ProductPeriodOnMarketsPage = () => {
  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateStyle"]');
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <ProductPageTemplate subtitle="Period on Markets" notices={notices}>
      <ProductPeriodOnMarkets />
    </ProductPageTemplate>
  );
};

export default ProductPeriodOnMarketsPage;

/**
 * value의 타입이 T인지 검증하는 함수
 * @author Lico
 * @param value - 검증할 값
 * @param field - 타입 검증에 사용할 필드
 * @returns
 */
export const instanceOf = <T extends object>(value: unknown, field: string): value is T => {
  return field in (value as T);
};

export const checkMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const getReactEnvVar = (
  name: 'SERVER_URL' | 'SERVER' | 'INGREDIENT_SERVER_URL' | 'INGREDIENT_API_KEY',
) => {
  return process.env[`REACT_APP_${name}`];
};

export const searchParamToNum = (key: string, defaultValue: number) => {
  const searchParams = new URLSearchParams(window.document.location.search);
  const param = searchParams.get(key);
  const num = Number(param || NaN);
  if (Number.isNaN(num)) return defaultValue;
  return num;
};

export const objToSearchParams = (params: any) => {
  return new URLSearchParams(objValuesToStr(params)).toString();
};

export const objValuesToStr = (object: any): Record<string, string> => {
  const newObj: Record<string, string> = {};

  for (const k in object) {
    newObj[k] = object[k].toString();
  }

  return newObj;
};

/**
 * 두 object의 변경값 찾는 함수
 * 내부에 배열이 있는 경우 비교의 기준이 되는 key를 보내야 함.
 * @param obj1 - 비교값1 (old)
 * @param obj2 - 비교값2 (new)
 * @returns - 변경값
 */
export const getChangedValues = ({
  obj1,
  obj2,
  returnArrayKey = false,
  useKeyName = false,
}: {
  obj1: any;
  obj2: any;
  returnArrayKey?: boolean;
  useKeyName?: boolean;
}) => {
  let result: any;

  if (Array.isArray(obj2) && Array.isArray(obj1)) {
    result = Array(obj2.length);

    if (
      obj2.every((item) => typeof item !== 'object') &&
      obj1.every((item) => typeof item !== 'object')
    ) {
      obj2.forEach((item, index) => {
        if (!obj1.includes(item)) {
          result[index] = item;
        }
      });

      return result;
    }

    if (useKeyName) {
      obj2.forEach(({ key: key2 }, index) => {
        const sameKeyObj =
          key2 !== undefined &&
          obj1.find(({ key: key1 }, obj1Index) => obj1[obj1Index][key1] === obj2[index][key2]);

        if (sameKeyObj) {
          const changes = getChangedValues({
            obj1: sameKeyObj,
            obj2: obj2[index],
            returnArrayKey,
            useKeyName,
          });

          if (Object.keys(changes).length > 0) {
            result[index] = Array.isArray(changes)
              ? changes
              : { ...changes, [key2]: obj2[index][key2] };
          }
        } else {
          result[index] = obj2[index];
        }
      });
    } else {
      obj2.forEach(({ key: key2 }, index) => {
        const sameKeyObj = key2 !== undefined && obj1.find(({ key: key1 }) => key1 === key2);

        if (sameKeyObj) {
          const changes = getChangedValues({
            obj1: sameKeyObj,
            obj2: obj2[index],
            returnArrayKey,
            useKeyName,
          });

          if (Object.keys(changes).length > 0) {
            result[index] = changes;
          }
        } else {
          result[index] = obj2[index];
        }
      });
    }

    return result.filter((item: any) => !!item);
  }

  result = {};

  for (let prop in obj2) {
    if (
      typeof obj2[prop] === 'object' &&
      obj2[prop] !== null &&
      !(obj2[prop] instanceof File) &&
      typeof obj1[prop] === 'object' &&
      obj1[prop] !== null &&
      !(obj1[prop] instanceof File)
    ) {
      const changes = getChangedValues({
        obj1: obj1[prop],
        obj2: obj2[prop],
        returnArrayKey,
        useKeyName,
      });

      if (Object.keys(changes).length > 0) {
        result[prop] = changes;
      }
    } else if (obj1[prop] !== obj2[prop]) {
      result[prop] = obj2[prop];
    }
  }

  for (let prop in obj1) {
    if (!obj2.hasOwnProperty(prop)) {
      result[prop] = obj2[prop];
    }
  }

  return result;
};

export const getOS = () => {
  const userAgent = window.navigator.userAgent;

  if (/Mac/gi.test(userAgent)) {
    return 'MacOS';
  } else if (/Win/gi.test(userAgent)) {
    return 'Windows';
  } else if (/Linux/gi.test(userAgent)) {
    return 'Linux';
  } else {
    return 'Unknown';
  }
};

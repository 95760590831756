import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Spin,
  Typography as Typo,
  Upload,
  Modal,
} from 'antd';
import styled from 'styled-components';

import FileViewer from 'components/file/FileViewer';
import FooterBox from 'components/FooterBox';
import { Tip, Typography } from 'components/system';
import { chinaPhoneRule, emailRule, requireRule } from 'lib/validate';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';

const Container = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
`;

const StyledTypo = styled(Typography.Text)<{ readOnlyMode: boolean }>`
  height: 18px;
  font-size: 12px;
  margin-left: 25px;
  margin-top: -6px;
  color: ${(props) => props.color};
  opacity: ${(props) => props.readOnlyMode && 0.3};
`;

const ProductInternalResponsibleCompany = ({
  updateMode,
  form,
  onFormFinished,
  uploadFileUrl,
  fileName,
  onChangeFile,
  bizLicenseFile,
  isVisibleNMPA,
  isVisibleNMPAInput,
  setIsVisibleNMPA,
  setIsVisibleNMPAInput,
  readOnlyMode,
  getLoading,
  submitLoading,
}: any) => {
  return (
    <Spin spinning={getLoading}>
      <Container>
        <Form form={form} layout="vertical" onFinish={onFormFinished}>
          <Form.Item
            name="isOwnSubsidiary"
            label="경내책임회사 보유 여부"
            required
            rules={[
              {
                validator: (_, value) => {
                  if (value === undefined) {
                    message.warning('경내책임회사 보유 여부를 선택해주세요');
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <StyledRadioGroup>
              <Radio
                value={false}
                onChange={() => {
                  setIsVisibleNMPA(false);
                }}
                disabled={readOnlyMode}
              >
                타 경내책임회사와 위생 허가를 진행합니다.
              </Radio>
              <Radio value={true} onChange={() => setIsVisibleNMPA(true)} disabled={readOnlyMode}>
                자회사 또는 중국 법인으로 경내책임회사를 보유하고 있습니다.
              </Radio>
              <StyledTypo color="MESSAGE_ERROR" readOnlyMode={readOnlyMode}>
                *검측 여부에 따라 검측 아이디와 비밀번호가 요구될 수 있습니다.
              </StyledTypo>
            </StyledRadioGroup>
          </Form.Item>
          {isVisibleNMPA && (
            <Form.Item
              name="isCreateNewNMPAAccount"
              label="NMPA 신규 계정 생성 여부"
              required
              rules={[
                {
                  validator: (_, value) => {
                    if (value === undefined) {
                      message.warning('NMPA 신규 계정 생성 여부를 체크해주세요');
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <StyledRadioGroup>
                <Radio
                  value={true}
                  onChange={() => setIsVisibleNMPAInput(true)}
                  disabled={readOnlyMode}
                >
                  네, 법규 개정 후 신규 계정을 생성하였습니다.
                </Radio>
                <Radio
                  value={false}
                  onChange={() => {
                    setIsVisibleNMPAInput(false);
                    Modal.info({
                      icon: null,
                      content:
                        'NMPA 신규 계정이 없는 경우, 전화나 하단의 ‘이용 문의’로 담당자에게 해당 내용을 공유해 주시면 빠른 인증 진행에 도움이 됩니다.',
                      okText: '네, 확인했습니다.',
                    });
                  }}
                  disabled={readOnlyMode}
                >
                  아니오, 없거나 진행 중입니다.
                </Radio>
                <StyledTypo color="GRAY60" readOnlyMode={readOnlyMode}>
                  (2021년 5월 1일 이후에 위생 허가증 받은 적 없음)
                </StyledTypo>
              </StyledRadioGroup>
            </Form.Item>
          )}
          {isVisibleNMPA && isVisibleNMPAInput && (
            <Row gutter={8} align="bottom">
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={
                    <>
                      신규 NMPA 시스템 로그인 정보{' '}
                      <Tip style={{ marginLeft: 4 }}>
                        <Typography.Text
                          type="BODY_2"
                          style={{ fontSize: 12, width: 'max-content' }}
                        >
                          경내책임회사에서 NMPA 시스템 로그인시 사용하는 아이디와 비밀번호가
                          필요합니다.
                        </Typography.Text>
                      </Tip>
                    </>
                  }
                  name="newNMPAAccountId"
                  rules={[requireRule]}
                >
                  <Input placeholder="아이디" disabled={readOnlyMode} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="newNMPAAccountPassword" rules={[requireRule]}>
                  <Input placeholder="비밀번호" disabled={readOnlyMode} />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item label="경내책임회사 전화번호" name="companyTel" rules={[chinaPhoneRule]}>
            <Input disabled={readOnlyMode} />
          </Form.Item>
          <Form.Item label="경내책임회사 이메일" name="companyEmail" rules={[emailRule]}>
            <Input disabled={readOnlyMode} />
          </Form.Item>
          <Form.Item name="file" label="경내책임회사 사업자등록증 (PDF)">
            <Row gutter={8} align="middle">
              <Col>
                <Upload
                  accept=".pdf"
                  itemRender={() => null}
                  multiple
                  beforeUpload={(file) => {
                    onChangeFile(file);
                    return false;
                  }}
                >
                  <Button
                    id="InternalResponsibleCompanyPDF"
                    style={{ width: 120 }}
                    disabled={readOnlyMode}
                  >
                    파일 선택
                  </Button>
                </Upload>
              </Col>
              {uploadFileUrl && fileName && (
                <Col>
                  <Typo.Link href={uploadFileUrl}>{fileName}</Typo.Link>
                </Col>
              )}
            </Row>
          </Form.Item>
        </Form>
        {bizLicenseFile && (
          <FileViewer file={bizLicenseFile} style={{ maxWidth: 520, margin: '16px auto 0' }} />
        )}
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            <Button type="primary" onClick={form.submit} loading={submitLoading}>
              {!updateMode ? '등록' : '수정'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </Container>
    </Spin>
  );
};

export default ProductInternalResponsibleCompany;

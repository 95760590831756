import Typography from 'components/system/general/Typography';
import RegisterTermsContainer from 'containers/auth/RegisterTermsContainer';
import styled from 'styled-components';

const RegisterTermsPageBlock = styled.div``;

const RegisterTermsPage = () => {
  return (
    <RegisterTermsPageBlock>
      <Typography.Text light type="HEADLINE_1">
        약관 동의
      </Typography.Text>
      <Typography.Text type="BODY_2" gutter={{ top: 4 }} color="GRAY70">
        아래 약관에 동의해 주세요.
      </Typography.Text>
      <RegisterTermsContainer />
    </RegisterTermsPageBlock>
  );
};

export default RegisterTermsPage;

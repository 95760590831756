import palette from 'lib/styles/palette';
import styled from 'styled-components';

const FlickerAnimation = styled.div`
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  background-color: ${palette.ETC_HIGHLIGHTING};
  transform: translate(-50%, -50%);
  opacity: 0.44;
  border-radius: 50%;
  animation-name: flicker;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;

  @keyframes flicker {
    from {
      transform: translate(-50%, -50%) scale(1);
    }

    50% {
      transform: translate(-50%, -50%) scale(3.5);
    }

    to {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

export default FlickerAnimation;

import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin } from 'antd';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { managerPositionOptions } from 'lib/selectOption';
import { emailRule, requireRule } from 'lib/validate';
import { useVCRPAccountRequest } from 'service/brand/product/vcrp';
import { VCRPAccountRequest } from 'types/brand/vcrp';
import UpdateLog from './UpdateLog';
import { DocumentCode } from 'types/product';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import { useNavigate } from 'react-router-dom';

const ProductVCRPAccountRequestContainer = styled.div`
  width: 520px;
  margin: 0 auto;
`;

const ProductVCRPAccountRequest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const navigate = useNavigate();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { sendEventToGA } = useGA();
  const {
    form,
    vcrpAccountRequest,
    readOnlyMode,
    updateMode,
    addVCRPAccountRequest,
    updateVCRPAccountRequest,
    isLoading,
  } = useVCRPAccountRequest({
    productId,
    countryId,
  });

  const handleSubmit = (formValues: VCRPAccountRequest) => {
    if (!updateMode) {
      addVCRPAccountRequest(formValues, {
        onSuccess: () => {
          sendEventToGA({
            documentName: '신규 VCRP 계정 생성',
            actionType: EGAActionType.REGISTER,
          });
          Modal.info({
            width: 400,
            style: { textAlign: 'center' },
            icon: null,
            content: (
              <p>
                담당자 이메일에 기재하신 메일 주소로
                <br />
                VCRP ADMIN으로부터 VCRP 계정 ID와
                <br />
                비밀번호가 전달될 예정입니다.
                <br />
                <br />
                메일을 전달 받으신 후,
                <br />
                <b>보유 VCRP 계정 정보</b> 항목에 계정 정보를 입력하세요.
              </p>
            ),
            onOk: () => navigate(-1),
          });
        },
      });
    } else if (
      formValues.companyNameEn !== vcrpAccountRequest?.companyNameEn ||
      formValues.managerEmail !== vcrpAccountRequest?.managerEmail ||
      formValues.managerFirstName !== vcrpAccountRequest?.managerFirstName ||
      formValues.managerLastName !== vcrpAccountRequest?.managerLastName ||
      formValues.managerMobile !== vcrpAccountRequest?.managerMobile ||
      formValues.managerPositionName !== vcrpAccountRequest?.managerPositionName ||
      formValues.vcrpAccount !== vcrpAccountRequest?.vcrpAccount
    ) {
      updateVCRPAccountRequest(formValues, {
        onSuccess: () => {
          sendEventToGA({
            documentName: '신규 VCRP 계정 생성',
            actionType: EGAActionType.MODIFY,
          });
          message.success('보완 완료되었습니다.');
          navigate(-1);
        },
      });
    } else {
      message.warning(messages.NO_NEED_TO_UPDATE);
    }
  };

  useEffect(() => {
    if (updateMode) {
      form.setFieldsValue(vcrpAccountRequest);
    }
  }, [readOnlyMode, vcrpAccountRequest]);

  return (
    <ProductVCRPAccountRequestContainer>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.VCRPAC}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.VCRPAC}
            />
          </Col>
        )}
      </Row>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError={scrollToFirstErrorOption}
          onFinish={handleSubmit}
          style={{ marginTop: 16 }}
        >
          <Form.Item
            label="회사명 (영문)"
            name="companyNameEn"
            rules={[
              requireRule,
              {
                pattern: /^[0-9A-Z\s]{0,100}$/i,
                message: '영문, 숫자, 공백 포함 최대 100자, 특수문자 사용 불가',
              },
            ]}
          >
            <Input maxLength={100} disabled={readOnlyMode} />
          </Form.Item>
          <Row gutter={8}>
            <Col flex="1 1 0">
              <Form.Item
                label="담당자 이름 (영문)"
                name="managerFirstName"
                rules={[
                  requireRule,
                  {
                    pattern: /^[0-9A-Z]{0,30}$/i,
                    message: '영문, 숫자 포함 최대 30자',
                  },
                ]}
              >
                <Input maxLength={30} disabled={readOnlyMode} />
              </Form.Item>
            </Col>
            <Col flex="1 1 0">
              <Form.Item
                label="담당자 성 (영문)"
                name="managerLastName"
                rules={[
                  requireRule,
                  {
                    pattern: /^[0-9A-Z]{0,30}$/i,
                    message: '영문, 숫자 포함 최대 30자',
                  },
                ]}
              >
                <Input maxLength={30} disabled={readOnlyMode} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col flex="1 1 0">
              <Form.Item
                label="담당자 직급 (영문)"
                name="managerPositionName"
                rules={[requireRule]}
              >
                <Select placeholder="담당자 직급 선택" disabled={readOnlyMode}>
                  {managerPositionOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="1 1 0">
              <Form.Item
                label="담당자 전화번호"
                name="managerMobile"
                rules={[
                  requireRule,
                  {
                    pattern: /^\d{10,15}$/i,
                    message: '숫자만 입력 가능, 10~15자',
                  },
                ]}
              >
                <Input placeholder="숫자만 입력 가능" maxLength={15} disabled={readOnlyMode} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="사용자명 (ID)"
            name="vcrpAccount"
            rules={[
              requireRule,
              {
                pattern: /^[0-9A-Z@\-_.]{5,200}$/i,
                message: '영문, 숫자, @, -_. 입력 가능, 5~200자',
              },
            ]}
          >
            <Input placeholder="회사명이나 이메일 입력" maxLength={200} disabled={readOnlyMode} />
          </Form.Item>
          <Form.Item label="담당자 이메일" name="managerEmail" rules={[requireRule, emailRule]}>
            <Input disabled={readOnlyMode} />
          </Form.Item>
          <FooterBox>
            <ReadOnlyBackButton readOnly={readOnlyMode}>
              {updateMode && (
                <CorrectRequestWithoutChangeButton documentCode={DocumentCode.VCRPAC} />
              )}
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {!updateMode ? '등록' : '보완 완료'}
              </Button>
            </ReadOnlyBackButton>
          </FooterBox>
        </Form>
      </Spin>
    </ProductVCRPAccountRequestContainer>
  );
};

export default ProductVCRPAccountRequest;

import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';

import { useNMPABasicOld } from 'service/material/nmpa/nmpa';
import * as S from 'templates/PageTemplateStyle';
import PageTemplate, { PageTemplateProps } from 'templates/PageTemplate';
import { Typography } from 'components/system';

const NMPAPageTemplateBlock = styled.div<{ document: boolean }>`
  .ant-page-header-heading-sub-title {
    padding-top: ${(props) => (props.document ? 8 : 10)}px !important;
  }
`;

interface NMPAPageTemplateProp extends PageTemplateProps {
  documentName?: string;
}

const NMPAPageTemplate = ({ title, subtitle, documentName, ...props }: NMPAPageTemplateProp) => {
  const params = useParams<{ materialId: string }>();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const materialId =
    Number(params.materialId) || Number(queryString.get('materialId')) || undefined;
  const { nmpaBasicInfo } = useNMPABasicOld(materialId);

  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateBlock"]');
    if (!rootPageTemplete) return;
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <NMPAPageTemplateBlock document={!!subtitle}>
      <S.PageTemplateBlock>
        <PageTemplate
          {...props}
          title={title}
          subtitle={
            <S.NameContainer>
              {documentName && (
                <Typography.Text type="TITLE_2" color="PRIMARY50" gutter={{ right: 16 }}>
                  {documentName}
                </Typography.Text>
              )}
              {nmpaBasicInfo && (
                <div>
                  <Typography.Text type="BODY_2" inline gutter={{ right: 4 }} className="label">
                    원료명
                  </Typography.Text>
                  <Typography.Text
                    type="TITLE_2"
                    medium
                    className="product-name"
                    inline
                    style={{ maxWidth: 580 }}
                  >
                    {nmpaBasicInfo.tradeName || ''}
                  </Typography.Text>
                </div>
              )}
            </S.NameContainer>
          }
        />
      </S.PageTemplateBlock>
    </NMPAPageTemplateBlock>
  );
};

export default NMPAPageTemplate;

import BuyerList from 'components/buyer/BuyerList';
import * as buyerActions from 'modules/buyer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const BuyerListContainer = () => {
  const dispatch = useDispatch();
  const { companyId, buyers, getLoading } = useSelector(({ auth, buyer, loading }) => ({
    companyId: auth.user.companyId,
    buyers: buyer.buyers,
    getLoading: loading['buyer/GET_BUYERS'],
  }));

  useEffect(() => {
    dispatch(buyerActions.getBuyers(companyId));
  }, [dispatch]);

  return <BuyerList buyers={buyers} getLoading={getLoading} />;
};

export default BuyerListContainer;

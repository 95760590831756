import styled from 'styled-components';

import { Typography } from 'components/system';
import { Button, Col, Form, Input, Row } from 'antd';
import { ProductBasic } from 'types/product';
import { ColumnsType } from 'antd/lib/table';
import { alphabetOrSpaceRule, requireRule } from 'lib/validate';
import Table from 'components/ui/Table/Table';
import palette from 'lib/styles/palette';

const ProductCategoryModalContentBlock = styled.div`
  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-explain {
      text-align: left;
    }
  }
`;

const Example = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  background-color: ${palette.SLATE_GRAY10};
`;

const columns: ColumnsType<ProductBasic> = [
  {
    title: '영문 제품명 (용량)',
    width: '67%',
    align: 'center',
    dataIndex: ['productDetails', 0, 'productNameEn'],
    render: (productNameEn: string, product) => (
      <Typography.Text type="BODY_2" medium color="GRAY90">
        {`${productNameEn} ${
          product.netWeight ? `(${product.netWeight} ${product.netWeightUnit})` : '( - )'
        }`}
      </Typography.Text>
    ),
  },
  {
    title: '화장품 카테고리',
    align: 'center',
    render: (_, __, i) => (
      <Form.Item name={['productCategories', i]} rules={[requireRule, alphabetOrSpaceRule]}>
        <Input placeholder="화장품 카테고리 입력" maxLength={30} />
      </Form.Item>
    ),
  },
];

const ProductCategoryModalContent = ({
  products,
  onSubmit,
}: {
  products: ProductBasic[];
  onSubmit: (productCategories: string[]) => void;
}) => {
  return (
    <ProductCategoryModalContentBlock>
      <Form onFinish={({ productCategories }) => onSubmit(productCategories)}>
        <Typography.Text type="BODY_2" gutter={{ bottom: 8 }}>
          선택한 제품의 화장품 카테고리를 각각 입력해 주세요. 만약 같은 유형의 제품이라면 화장품
          카테고리에 동일한 값을 입력해 주세요.
        </Typography.Text>
        <Example>
          Ex ){' '}
          <Typography.Text type="BODY_2" inline color="PRIMARY50">
            마스크팩 5개 제품의 경우
          </Typography.Text>{' '}
          : 5개 모두 동일하게 ‘Sheet Mask’ 입력
          <br />
          Ex )
          <Typography.Text type="BODY_2" inline color="PRIMARY50">
            크림 1개 , 마스크팩 1개 제품의 경우
          </Typography.Text>{' '}
          : 크림은 ‘Cream’, 마스크팩은 ‘Sheet Mask’ 입력
        </Example>
        <Table
          columns={columns}
          dataSource={products}
          rowKey={({ productId }) => productId}
          pagination={false}
        />
        <Row justify="center" style={{ marginTop: 24 }}>
          <Col>
            <Button type="primary" htmlType="submit" style={{ width: 84 }}>
              입력 완료
            </Button>
          </Col>
        </Row>
      </Form>
    </ProductCategoryModalContentBlock>
  );
};

export default ProductCategoryModalContent;

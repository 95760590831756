import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { deviceSize } from 'lib/styles';
import COLOR from './colors';
import { Body2, H1, H2, H3 } from './introStyles';
import { s3AssetDomain } from 'lib/consts';
import useIntersectionObserver from '../../../hook/useIntersectionObserver';

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 150px 0;
  background: ${COLOR.BG1};
  position: relative;

  @media ${deviceSize.sm} {
    padding: 70px 24px;
    height: auto;
  }
`;

const TitleWrapper = styled.div<{
  isTitleIntersecting: boolean;
  isIntersecting: boolean;
  isMobile: boolean;
}>`
  position: absolute;
  margin-left: 18%;
  transition: all 1s ease;
  height: 500px;

  @media ${deviceSize.sm} {
    margin: 0;
  }
`;

const StickyWrapper = styled.div`
  @media ${deviceSize.sm} {
    position: static;
  }
`;

const Title = styled(H1)`
  color: ${COLOR.WHITE};
  margin-bottom: 30px;

  @media ${deviceSize.sm} {
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 20px 0;
  }
`;

const SubTitle = styled(H3)`
  color: ${COLOR.TYPO6};

  @media ${deviceSize.sm} {
    font-size: 18px;
    font-weight: 400;
    text-align: start;
  }
`;

const ImgContainer = styled.div`
  margin: 12% 14% 150px 30%;
  display: grid;
  grid-template-columns: 40% 40%;
  row-gap: 6%;
  column-gap: 6%;
  justify-content: center;

  @media ${deviceSize.sm} {
    margin: 100px auto;
    display: flex;
    flex-direction: column;
  }
`;

const ImgWrapper = styled.div<{
  dir: string;
  isMobile: boolean;
}>`
  /* height: 68%; */
  position: relative;
  margin-top: 10%;
  transition: all 1s ease-in;
  transform: translateY(0);

  @media ${deviceSize.sm} {
    position: static;
    height: auto;
    ${({ dir }) =>
      dir === 'right' &&
      css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 10%;
      `};
  }

  ${({ dir }) =>
    dir === 'right' &&
    css`
      margin-top: -30%;
    `};
`;

const ImgTitle = styled(H2)`
  color: ${COLOR.WHITE};
  margin-top: 40px;

  @media ${deviceSize.sm} {
    font-size: 20px;
    font-weight: 400;
    width: 80%;
    margin-top: 30px;
  }
`;

const ImgDesc = styled(Body2)`
  color: ${COLOR.TYPO6};
  margin-top: 20px;

  @media ${deviceSize.sm} {
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
    width: 80%;
  }
`;

const imgArr: Array<{
  title: string;
  desc: string;
  src: string;
  dir: string;
}> = [
  {
    title: '절대적으로 빠른 시간',
    desc: '프로그램이 성분, 문안 등을 스크리닝하고 자동으로 서류를 관리하여 빠른 속도로 인증이 가능합니다. ',
    src: `${s3AssetDomain}/main_sectionH_1.png`,
    dir: 'left',
  },
  {
    title: '절대적인 효용성',
    desc: `허가할 때 입력한 정보를 활용하여 유럽, 미국, 인도네시아
      등 35개국 인증이 가능합니다. 또한, certicos에 임상을
      의뢰하여 진행할 시 해당 임상 자료가 35개국에서 통용될 수 있게 설계하여 브랜드사의 비용과 시간을 줄여줍니다. `,
    src: `${s3AssetDomain}/main_sectionH_3.png`,
    dir: 'right',
  },
  {
    title: '절대적인 편의성',
    desc: `제조사와 인증업체와의 커뮤니케이션을 대신하기 때문에 
    브랜드사는 본업에 더 집중할 수 있습니다. 
    실무자가 챙겨야 하는 공증, 검역샘플 전달 등의 업무들도 
    certicos가 컨설팅합니다.`,
    src: `${s3AssetDomain}/main_sectionH_2.png`,
    dir: 'left',
  },
  {
    title: '절대적 업무량 감소',
    desc: `기존의 경내책임회사에서 신규 경내책임회사가 추가되는 것이기 때문에 업무량 증가로 보일수 있으나, 1번의 입력으로 35개국을 관리할 수 있기에 결국 인허가 영역에서 내부 인력의 업무량이 감소합니다. `,
    src: `${s3AssetDomain}/main_sectionH_4.png`,
    dir: 'right',
  },
];

const SectionH = () => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const [isAbove, setIsAbove] = useState(true);
  const sectionRef = useRef<HTMLDivElement>(null);
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0.6,
  });

  const [isTitleIntersecting, setIsTitleInterSecting] = useState(false);
  const titleRef = useIntersectionObserver({
    onIntersect: setIsTitleInterSecting,
    threshold: 1,
  });

  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  useEffect(() => {
    const handleScroll = () => {
      const sectionElmt = sectionRef.current;
      if (!sectionElmt) return;

      const { y } = sectionElmt.getBoundingClientRect();

      setIsAbove(y >= 50);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Container ref={sectionRef}>
        <TitleWrapper
          style={isAbove || isMobile ? { opacity: 1 } : {}}
          ref={titleContainerRef}
          isIntersecting={isIntersecting}
          isTitleIntersecting={isTitleIntersecting}
          isMobile={isMobile}
        >
          <StickyWrapper>
            <Title ref={titleRef}>고객들이 선택한 이유</Title>
            <SubTitle>절대적 우위성과 장기적 관점에서의 이득</SubTitle>
          </StickyWrapper>
        </TitleWrapper>
        <ImgContainer ref={target}>
          {imgArr.map(({ title, desc, src, dir }) => (
            <ImgWrapper dir={dir} key={src} isMobile={isMobile}>
              <img src={src} style={{ width: isMobile ? '80%' : '100%' }} alt={title} />
              <ImgTitle>{title}</ImgTitle>
              <ImgDesc>{desc}</ImgDesc>
            </ImgWrapper>
          ))}
        </ImgContainer>
      </Container>
    </>
  );
};

export default SectionH;

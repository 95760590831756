import { Button, Checkbox, Spin, message } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import styled, { css } from 'styled-components';

import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import {
  CompanyDocsDataParmas,
  useCompanyDocsList,
  useGetProductsWithDocs,
} from 'service/brand/company';

import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import { CompanyDocsList, CompanyDocsListParams, CompanyDocsUserType } from 'types/brand/company';
import { messages } from 'lib/consts';

const Container = styled.div`
  width: 100%;
  position: relative;

  .placeholder {
    position: absolute;
    background-color: transparent;
    border-bottom: solid 4px ${palette.SLATE_GRAY40};
    transform: translateY(14px);
  }
`;

const HeaderContainer = styled(Flex)``;

const DocsContainer = styled(Flex)`
  padding-top: 24px;
  width: 100%;
`;

const DocsColumnContainer = styled(Flex)`
  margin-bottom: 16px;
  width: 100%;
  position: relative;
`;

const RowContainer = styled(Flex)<{
  visible?: boolean;
  isDraggableIconHover?: boolean;
  isDragging: boolean;
}>`
  background-color: ${palette.ETC_WHITE};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  width: 1040px;
  border: 1px solid transparent;
  transition: border 200ms ease;
  padding: 14px 32px;
  box-shadow: ${({ isDragging }) =>
    !isDragging ? '0px 1px 5px rgba(0, 0, 0, 0.1)' : '0px 2px 22px rgba(162, 162, 162, 0.23)'};
  transition: background-color 300ms ease;
  transition: box-shadow 300ms ease;
  transition: display 300ms ease;
  position: relative;
  border: 2px dashed transparent;

  .draggable_icon {
    display: none;
  }

  &:hover {
    .draggable_icon {
      cursor: pointer;
      position: absolute;
      left: 12px;
      display: flex;
      align-items: center;
    }
  }

  ${({ isDraggableIconHover }) =>
    isDraggableIconHover &&
    css`
      transition: display 200ms ease;
      .tooltip {
        display: block;
      }
    `};
`;

const DocColumnContainer = styled(Flex)``;

const DocsColumnHeader = styled(Flex)`
  width: 976px;
`;

const DocTitle = styled(Typography.Text)<{ visible: boolean }>`
  font-weight: 700;
  font-size: 16px;
  color: ${({ visible }) => (visible ? palette.SLATE_GRAY70 : palette.SLATE_GRAY40)};
`;

const ButtonContainer = styled(Flex)`
  margin-top: 20px;
`;

const CertificatonTip = styled(Flex)`
  width: 64px;
  height: 20px;
  background-color: ${palette.YELLOW_20};
  border-radius: 3px;
  color: ${palette.SLATE_GRAY70};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const Tooltip = styled(Flex)`
  display: none;
  font-size: 14px;
  position: absolute;
  left: -168px;
  height: 40px;
  background-color: ${palette.SLATE_GRAY70};
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 400;
  .tooltip_text1 {
    color: white;
  }

  .tooltip_text2 {
    color: ${palette.MESSAGE_WARNING};
    font-weight: 500;
  }
`;

const ProductListContainer = styled.div`
  background-color: ${palette.SLATE_GRAY10};
  padding: 16px 40px;
  text-align: start;
`;

interface DocsColumnProps {
  column?: {
    description?: string;
    id: string;
    relationType: string;
    name: string;
    visible: boolean;
    editable: boolean;
    open?: boolean;
    attachIds: string[];
    historyIds: string[];
    isForCertification?: boolean;
  };
  files?: {
    attachId: number;
    filename: string;
    uploadFileUrl: string;
    registerDt: string;
  }[];
  index: number;
  docsList: CompanyDocsDataParmas | undefined;

  docItemNameValue: {
    [key: string]: string;
  };
  setDocsList: React.Dispatch<React.SetStateAction<CompanyDocsDataParmas | undefined>>;
  setDocItemNameValue: React.Dispatch<React.SetStateAction<{ [columnId: string]: string }>>;
}

const DocColumn = ({
  index,
  column,
  docsList,
  docItemNameValue,
  setDocsList,
  setDocItemNameValue,
}: DocsColumnProps) => {
  const [isDraggableIconHover, setIsDraggableIconHover] = useState<boolean>();
  const { openDocsItemModal, openAlertModal } = useModal();
  const { getProductsWithDocs } = useGetProductsWithDocs();

  useEffect(() => {
    if (!column || !docsList) return;
    const columns = Object.values(docsList.columns);
    const newDocItemNameValue = { ...docItemNameValue };

    for (const item of columns) {
      newDocItemNameValue[item.id] = item.name;
    }

    setDocItemNameValue(newDocItemNameValue);
  }, [docsList]);

  const handleVisible = (type: 'off' | 'on') => {
    if (!docsList || !column) return;
    setDocsList({
      ...docsList,
      columns: {
        ...docsList.columns,
        [column.id]: {
          ...docsList.columns[column.id],
          visible: type === 'off' ? false : true,
        },
      },
    });
  };

  const handleDeleteItem = () => {
    if (!column) return;

    if (!isNaN(Number(column.id))) {
      getProductsWithDocs(
        { qcqaUserDocumentId: Number(column.id) },
        {
          onSuccess: ({ data: { result } }) => {
            if (result.length > 0) {
              openAlertModal({
                width: 400,
                content: (
                  <>
                    <Typography.Text
                      type="BODY_2"
                      style={{ textAlign: 'center', marginBottom: 16 }}
                    >
                      아래 제조번호에 사용 중인
                      <br />
                      항목을 삭제하실 수 없습니다.
                    </Typography.Text>
                    <ProductListContainer>
                      {result.map((product) => (
                        <Typography.Text
                          style={{
                            color: palette.SLATE_GRAY70,
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                          key={product.qcqaProductId}
                        >
                          {`${product.productNameKo || product.productNameEn} : ${product.lotNos
                            .map(({ lotNo }) => lotNo)
                            .join(', ')}`}
                        </Typography.Text>
                      ))}
                    </ProductListContainer>
                  </>
                ),
                noCancelButton: true,
              });
            }
          },
        },
      );
    }

    openAlertModal({
      content: '항목을 삭제 하시겠습니까?',
      onOk: () => {
        setDocsList((list) => {
          if (!list) return;

          const newColumnOrder = [...list.columnOrder];
          const idx = newColumnOrder.indexOf(column.id);

          newColumnOrder.splice(idx, 1);
          const docs = { ...list };
          delete docs.columns[column.id];

          return {
            ...docs,
            columnOrder: newColumnOrder,
          };
        });
        message.success('삭제되었습니다.');
      },
    });
  };

  const handleHideItem = () => {
    if (!column) return;

    getProductsWithDocs(
      { qcqaUserDocumentId: Number(column.id) },
      {
        onSuccess: ({ data: { result } }) => {
          if (result.length > 0) {
            openAlertModal({
              width: 400,
              content: (
                <>
                  <Typography.Text type="BODY_2" style={{ textAlign: 'center', marginBottom: 16 }}>
                    아래 제품에 사용 중인
                    <br />
                    항목이므로 숨길 수 없습니다.
                  </Typography.Text>
                  <ProductListContainer>
                    {result.map((product) => (
                      <Typography.Text
                        style={{
                          color: palette.SLATE_GRAY70,
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                        key={product.qcqaProductId}
                      >
                        {`${product.productNameKo || product.productNameEn} : ${product.lotNos
                          .map(({ lotNo }) => lotNo)
                          .join(', ')}`}
                      </Typography.Text>
                    ))}
                  </ProductListContainer>
                </>
              ),
              noCancelButton: true,
            });
            handleVisible('on');
          } else {
            handleVisible('off');
          }
        },
      },
    );
  };

  if (column) {
    return (
      <Draggable draggableId={column?.id} index={index}>
        {(provided, snapshot) => {
          return (
            <>
              <RowContainer
                dir="row"
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                gap={16}
                isDragging={snapshot.isDragging}
                align="center"
                visible={column?.visible}
                isDraggableIconHover={isDraggableIconHover}
              >
                {isDraggableIconHover && (
                  <Tooltip className="tooltip">
                    <span className="tooltip_text1">드래그하여</span>
                    <span className="tooltip_text2"> 순서변경</span>
                  </Tooltip>
                )}
                <Flex
                  {...provided.dragHandleProps}
                  className="draggable_icon"
                  onMouseEnter={() => setIsDraggableIconHover(true)}
                  onMouseLeave={() => setIsDraggableIconHover(false)}
                  style={{
                    height: '100%',
                    width: 25,
                    left: '0px !important',
                  }}
                >
                  <Icon name="move" size={18} style={{ position: 'absolute', left: -5 }} />
                </Flex>
                <DocColumnContainer dir="column">
                  <DocsColumnHeader justify="space-between" align="center">
                    <Flex gap={8} align="center">
                      <DocTitle visible={column?.visible}>{column?.name}</DocTitle>
                      {column.description && <Tip trigger="click">{column.description}</Tip>}
                      {column.isForCertification && (
                        <CertificatonTip align="center" justify="center" textAlign="center">
                          인증 활용
                        </CertificatonTip>
                      )}
                      {column?.editable && (
                        <Icon
                          name="write"
                          size={18}
                          color="SLATE_GRAY70"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            openDocsItemModal({
                              columnId: column.id,
                              updateMode: true,
                              docItemName: docItemNameValue,
                              onSubmit: (itemName) => {
                                if (!docsList) return;

                                setDocsList({
                                  ...docsList,
                                  columns: {
                                    ...docsList.columns,
                                    [column.id]: {
                                      ...docsList.columns[column.id],
                                      name: itemName,
                                    },
                                  },
                                });
                                setDocItemNameValue({
                                  ...docItemNameValue,
                                  [column.id]: itemName,
                                });
                                message.success('수정되었습니다.');
                              },
                            });
                          }}
                        />
                      )}
                    </Flex>
                    <Flex gap={16}>
                      {!column?.editable && column?.visible && (
                        <>
                          <Flex
                            gap={4}
                            role="button"
                            align="center"
                            style={{ cursor: 'pointer' }}
                            onClick={handleHideItem}
                          >
                            <Icon name="hide" size={16} color="SLATE_GRAY70" />
                            <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                              숨기기
                            </Typography.Text>
                          </Flex>
                        </>
                      )}
                      {column.editable && (
                        <Flex
                          gap={4}
                          role="button"
                          align="center"
                          style={{ cursor: 'pointer' }}
                          onClick={handleDeleteItem}
                        >
                          <Icon name="delete" size={16} color="SLATE_GRAY70" />
                          <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                            삭제
                          </Typography.Text>
                        </Flex>
                      )}
                      {!column?.visible && (
                        <Flex
                          gap={4}
                          role="button"
                          align="center"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleVisible('on')}
                        >
                          <Icon name="show" size={16} color="SLATE_GRAY70" />
                          <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                            보기
                          </Typography.Text>
                        </Flex>
                      )}
                    </Flex>
                  </DocsColumnHeader>
                </DocColumnContainer>
              </RowContainer>
            </>
          );
        }}
      </Draggable>
    );
  } else {
    return <></>;
  }
};

const LotDocsList = ({
  tabKey,
  onChangeIsBlockTab,
  companyDocsList,
  isCompanyDocsListLoading,
}: {
  tabKey: string;
  onChangeIsBlockTab: React.Dispatch<React.SetStateAction<boolean>>;
  companyDocsList: CompanyDocsList[] | undefined;
  isCompanyDocsListLoading: boolean;
}) => {
  const { addCompanyDocsList, iaAddCompanyDocsListLoading } = useCompanyDocsList({
    useType: CompanyDocsUserType.LOT_NO,
  });
  const [showHideItems, setShowHideItems] = useState<boolean>(true);
  const [docsList, setDocsList] = useState<CompanyDocsDataParmas | undefined>();
  const [docsCheckList, setDocsCheckList] = useState<CompanyDocsDataParmas | undefined>();
  const { openDocsItemModal } = useModal();

  const [docItemNameValue, setDocItemNameValue] = useState<{
    [columnId: string]: string;
  }>({});
  const [placeholderProps, setPlaceholderProps] = useState<{
    clientY: number;
    clientX: number;
    clientHeight: number;
    clientWidth: number;
  }>();

  useEffect(() => {
    if (!companyDocsList) return;
    docsListToData();
  }, [companyDocsList]);

  const docsListToData = useCallback(() => {
    if (!companyDocsList) return;

    const newFiles = { ...docsList?.files };
    const newHistories = { ...docsList?.histories };
    const newColumns = { ...docsList?.columns };
    const newColumnOrder: string[] = [];
    companyDocsList.forEach((doc) => {
      if (!doc) return;
      newColumns[doc.qcqaUserDocumentId] = {
        id: String(doc.qcqaUserDocumentId),
        relationType: doc.relationType,
        name: doc.name,
        visible: doc.isDisplay,
        description: doc.description,
        editable: doc.relationType === 'BASIC' ? false : true,
        open: false,
        isForCertification: doc.isForCertification,
        attachIds:
          doc?.records && doc.records.length > 0
            ? doc.records
                ?.map((history) => history.attaches?.map((attach) => String(attach.attachId)))
                .flat(1)
            : [],
        historyIds:
          doc.records && doc.records.length > 0
            ? doc.records?.map((record) => String(record.qcqaDocumentRecordId))
            : [],
      };
      doc.records?.forEach((history) => {
        if (!history.qcqaDocumentRecordId) return;

        newHistories[history.qcqaDocumentRecordId] = {
          qcqaDocumentRecordId: history.qcqaDocumentRecordId,
          name: history.name,
          recordDate: history.recordDate,
          attachIds: history.attaches?.map((attach) => String(attach.attachId)),
          qcqaDocumentRecordAttachIds: history.attaches?.map((attach) =>
            String(attach.qcqaDocumentRecordAttachId),
          ),
        };

        history?.attaches?.forEach((attach) => {
          newFiles[attach.attachId] = {
            attachId: attach.attachId,
            filename: attach.filename,
            uploadFileUrl: attach.uploadFileUrl,
            registerDt: attach.registerDt,
            qcqaDocumentRecordAttachId: attach.qcqaDocumentRecordAttachId,
          };
        });
      });
      newColumnOrder.push(String(doc.qcqaUserDocumentId));
    });

    const newDocList = {
      ...docsList,
      columns: newColumns,
      columnOrder: newColumnOrder,
      histories: newHistories,
      files: newFiles,
    };
    setDocsList(newDocList);
    setDocsCheckList(structuredClone(newDocList));
  }, [companyDocsList]);

  const addDocsItem = () => {
    if (!docsList) return;

    openDocsItemModal({
      onSubmit: (itemName) => {
        const newDocIdName = `doc-${itemName}_${String(docsList.columnOrder.length) + 1}`;

        const newColumns = {
          ...docsList?.columns,
          [newDocIdName]: {
            id: newDocIdName,
            relationType: 'CUSTOM',
            name: itemName,
            visible: true,
            editable: true,
            open: false,
            attachIds: [],
            historyIds: [],
          },
        };

        setDocItemNameValue({
          ...docItemNameValue,
          [newDocIdName]: itemName,
        });

        setDocsList({
          ...docsList,
          columns: newColumns,
          columnOrder: [...docsList.columnOrder, newDocIdName],
        });
        message.success('추가되었습니다.');
      },
    });
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    if (!docsList) return;

    if (destination.droppableId === source.droppableId && source.index === destination.index)
      return;

    const newDocsColumnOrder = Array.from(docsList.columnOrder);
    const [targetItem] = newDocsColumnOrder.splice(source.index, 1);

    newDocsColumnOrder.splice(destination.index, 0, targetItem);

    setDocsList({
      ...docsList,
      columnOrder: newDocsColumnOrder,
    });

    return;
  };

  const queryAttr = 'data-rfd-drag-handle-draggable-id';

  const onDragStart = (event: any) => {
    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) return;

    // const { clientHeight, clientWidth } = draggedDOM;
    const clientHeight = 52; //HINT: 전체 높이에서 border를 뺀 값
    const clientWidth = 1040;

    const sourceIndex = event.source.index;

    const borderHeight = 4;

    let clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);

        const marginBottom = parseFloat(style.marginBottom);
        return total + 52 + marginBottom + borderHeight;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const onDragUpdate = (event: any) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    // const { clientHeight, clientWidth } = draggedDOM;

    const clientHeight = 52; //HINT: 전체 높이에서 border를 뺀 값
    const clientWidth = 1040;

    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];

    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const borderHeight = 4;

    let clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + 52 + marginBottom + borderHeight;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const handleSubmit = () => {
    if (checkIsEqaul()) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }
    const docsListData: CompanyDocsListParams[] = [];

    if (!docsList) return;

    Object.entries(docsList.columnOrder).forEach(([key, order]) => {
      const column = docsList.columns[order];

      if (!column) return;

      const newRecords: Record<
        string,
        {
          qcqaDocumentRecordId?: number;
          name: string;
          recordDate: string;
          attaches: number[];
        }
      > = {};

      //attachIds가 없을 경우 record 이력 리셋
      if (column.attachIds.length > 0 && column.historyIds.length > 0) {
        column.historyIds.forEach((id) => {
          const history = docsList.histories[id];

          newRecords[history.name] = {
            name: history.name,
            recordDate: history.recordDate,
            attaches: history.attachIds.map((id) => Number(id)),
          };
        });
      } else {
        for (const record in newRecords) {
          delete newRecords[record];
        }
      }

      docsListData.push({
        name: column.name,
        isDisplay: column.visible,
        displayOrder: Number(key) + 1,
        records: Object.values(newRecords),
        ...(!isNaN(Number(column.id)) && {
          qcqaUserDocumentId: Number(column.id),
        }),
      });
    });

    addCompanyDocsList(docsListData, {
      onSuccess: () => {
        message.success('저장되었습니다.');
        onChangeIsBlockTab(false);
      },
    });
  };

  const checkIsEqaul = () => {
    const docsListCopy = structuredClone(docsList);
    const docsCheckListCopy = structuredClone(docsCheckList);

    if (docsListCopy && docsCheckListCopy) {
      for (const key of Object.keys(docsListCopy.columns)) {
        delete docsListCopy.columns[key].open;
      }

      for (const key of Object.keys(docsCheckListCopy.columns)) {
        delete docsCheckListCopy.columns[key].open;
      }

      return isEqual(docsListCopy, docsCheckListCopy);
    }

    return true;
  };

  useEffect(() => {
    onChangeIsBlockTab(!checkIsEqaul());
  }, [checkIsEqaul()]);

  return (
    <Container>
      <HeaderContainer justify="space-between">
        <Typography.Text style={{ fontSize: 24 }}>제조번호 서류</Typography.Text>
        <Flex columnGap={8} align="center">
          <Checkbox checked={showHideItems} onChange={(e) => setShowHideItems(e.target.checked)}>
            <Typography.Text type="SMALL" color="SLATE_GRAY70" bold inline>
              숨긴 항목도 보기
            </Typography.Text>
          </Checkbox>
        </Flex>
      </HeaderContainer>
      <Spin
        size="small"
        spinning={tabKey !== CompanyDocsUserType.LOT_NO || isCompanyDocsListLoading}
      >
        <DocsContainer>
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
          >
            <Droppable droppableId="all-product-docs" type="column">
              {(provided, snapshot) => (
                <DocsColumnContainer
                  dir="column"
                  align="center"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {docsList?.columnOrder.map((columnId: string, index: number) => {
                    const column = docsList.columns[columnId];
                    const filteredColumn =
                      docsList.columns[columnId]?.visible === true
                        ? docsList.columns[columnId]
                        : undefined;
                    return (
                      <DocColumn
                        column={!showHideItems ? filteredColumn : column}
                        key={column?.id}
                        index={index}
                        setDocsList={setDocsList}
                        docsList={docsList}
                        docItemNameValue={docItemNameValue}
                        setDocItemNameValue={setDocItemNameValue}
                      />
                    );
                  })}
                  {provided.placeholder}
                  {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                    <div
                      className="placeholder"
                      style={{
                        top: placeholderProps?.clientY,
                        left: placeholderProps?.clientX,
                        height: placeholderProps?.clientHeight,
                        width: placeholderProps?.clientWidth,
                      }}
                    />
                  )}
                </DocsColumnContainer>
              )}
            </Droppable>
          </DragDropContext>
        </DocsContainer>
        <ButtonContainer align="center" gap={58} dir="column">
          <Button
            type="dashed"
            block
            icon={<Icon name="plus" size={14} color="PRIMARY50" />}
            style={{
              width: 520,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={addDocsItem}
          >
            항목 추가
          </Button>
          <Button
            type="primary"
            block
            style={{ width: 200, height: 56, fontSize: 18 }}
            onClick={handleSubmit}
            loading={iaAddCompanyDocsListLoading}
          >
            저장
          </Button>
        </ButtonContainer>
      </Spin>
    </Container>
  );
};

export default LotDocsList;

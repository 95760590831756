import CRCSB from 'components/brand/retailer/retailerApply/CRCSB';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import PageTemplate from 'templates/PageTemplate';

const CRCSBPage = () => {
  return (
    <PageTemplate
      title="국내 입점 서류 관리"
      subtitle="화장품책임판매업 등록필증"
      exampleImg="CRCSB.png"
      notices={[
        '화장품책임판매업 등록필증의 앞, 뒷면을 각각 스캔하여 PDF 파일을 업로드해 주세요.',
        '뒷면의 변경 및 처분 사항의 경우에는 추가된 내용이 있을 경우, 가장 최근 변경사항까지 포함된 파일로 업로드해 주세요.',
        '해당 서류는 모든 유통사에 기본적으로 포함되는 서류로, 한 번만 업로드하시면 모든 유통사별 서류 다운로드 시 함께 받아보실 수 있습니다. ',
        <MistakeNoticeItem />,
      ]}
    >
      <CRCSB />
    </PageTemplate>
  );
};

export default CRCSBPage;

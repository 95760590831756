import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useMediaQuery } from 'react-responsive';

import COLOR from './colors';
import { deviceSize } from 'lib/styles';
import { Body3, H1, H3, H4 } from './introStyles';
import Item1 from 'asset/svg/intro/sectionE_1.svg';
import Item2 from 'asset/svg/intro/sectionE_2.svg';
import Item3 from 'asset/svg/intro/sectionE_3.svg';
import useIntersectionObserver from 'hook/useIntersectionObserver';

const Item = styled.div<{ isMobile?: boolean }>`
  width: 20%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      opacity: 0;
    `}

  @media ${deviceSize.sm} {
    width: auto;
    margin-top: 50px;
    justify-content: space-center;
  }

  @keyframes up {
    from {
      opacity: 0;
      transform: translate3d(0, 50%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0);
    }
  }

  &:nth-child(1) {
    animation-delay: 0.2s;
  }
  &:nth-child(2) {
    animation-delay: 0.4s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`;

const Container = styled.div<{
  isIntersecting: boolean;
  isMobile: boolean;
  isAnimated: boolean;
}>`
  width: 100%;
  height: 828px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 0;
  background: ${COLOR.BG2};
  overflow: hidden;

  @media ${deviceSize.sm} {
    padding: 70px 0px;
    height: 656px;
  }

  ${({ isIntersecting, isAnimated, isMobile }) =>
    (isIntersecting || isAnimated) &&
    !isMobile &&
    css`
      ${Item} {
        animation-name: up;
        animation-duration: 1s;
        animation-direction: alternate;
        animation-fill-mode: forwards;
      }
    `}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(H1)`
  margin-bottom: 30px;
  @media ${deviceSize.sm} {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const SubTitle = styled(H3)`
  @media ${deviceSize.sm} {
    font-size: 18px;
    font-weight: 400;
  }
`;

const ItemContainer = styled.div`
  justify-content: space-around;
  margin-top: 80px;
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ItemTitle = styled(H4)`
  margin-bottom: 20px;

  @media ${deviceSize.sm} {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }
`;

const ImgWrapper = styled.div`
  width: 174px;
  height: 174px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${COLOR.WHITE};
  margin-bottom: 40px;

  @media ${deviceSize.sm} {
    width: 180px;
    height: 200px;
    margin-bottom: 30px;
  }
`;

const Desc = styled(Body3)`
  text-align: center;

  @media ${deviceSize.sm} {
    font-size: 14px;
    font-weight: 400;
    width: 220px;
  }
`;

const SlideContainer = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;

  @media ${deviceSize.sm} {
    .splide__pagination {
      display: block;
      text-align: center;

      > li {
        &:not(:first-child) {
          margin-left: 16px;
        }

        button {
          width: 10px;
          height: 10px;
          background-color: ${COLOR.TYPO6};
          opacity: 1;

          &.is-active {
            transform: none;
            background-color: ${COLOR.C_MAIN};
          }
        }
      }
    }
  }
`;

const SectionE = () => {
  const [isIntersecting, setIsInterSecting] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  const target = useIntersectionObserver({
    onIntersect: setIsInterSecting,
    threshold: 0.5,
  });

  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  useEffect(() => {
    if (isIntersecting) {
      setIsAnimated(true);
    }
  }, [isIntersecting]);

  const itemArr: Array<{ title: string; desc: string; svg: any }> = [
    {
      title: '품질 서류 관리도',
      desc: 'MSDS, COA, Technical sheet 등 모든 품질 서류 관리가 가능합니다.',
      svg: Item1,
    },
    {
      title: '처방 관리도',
      desc: `전성분 스크리닝은 물론,
      처방에 관한 모든 것이 가능합니다.`,
      svg: Item2,
    },
    {
      title: '이력 관리도',
      desc: `제품 생성, 수정, 단종 등
      제품 히스토리를 관리 할 수 있습니다.`,
      svg: Item3,
    },
  ];

  return (
    <Container
      ref={target}
      isIntersecting={isIntersecting}
      isMobile={isMobile}
      isAnimated={isAnimated}
    >
      <TextContainer>
        <Title>가벼운 마음으로 {isMobile && <br />}시작해보세요.</Title>
        <SubTitle style={{ color: COLOR.C_MAIN }}>인허가 받을 때 빼고 모두 무료</SubTitle>
      </TextContainer>
      {isMobile ? (
        <SlideContainer>
          <Splide options={{ rewind: true }}>
            {itemArr.map((item) => (
              <SplideSlide key={item.title}>
                <Item isMobile={isMobile}>
                  <ImgWrapper>
                    <img src={item.svg} alt="splide_image" />
                  </ImgWrapper>
                  <ItemTitle>{item.title}</ItemTitle>
                  <Desc>{item.desc}</Desc>
                </Item>
              </SplideSlide>
            ))}
          </Splide>
        </SlideContainer>
      ) : (
        <ItemContainer>
          <ItemWrapper>
            {itemArr.map((item) => (
              <Item key={item.title} isMobile={isMobile}>
                <ImgWrapper>
                  <img src={item.svg} alt="splide_image" />
                </ImgWrapper>
                <H4 style={{ marginBottom: '20px', fontWeight: 'bold' }}>{item.title}</H4>
                <Body3 style={{ textAlign: 'center' }}>{item.desc}</Body3>
              </Item>
            ))}
          </ItemWrapper>
        </ItemContainer>
      )}
    </Container>
  );
};

export default SectionE;

import { useParams, useRoutes } from 'react-router-dom';

import Error404Page from 'pages/error/Error404Page';
import ProductReadyListPage from 'pages/manufacturer/product/ProductReadyListPage';
import ProductListPage from 'pages/manufacturer/product/ProductListPage';
import ProductBasicPage from 'pages/manufacturer/product/ProductBasicPage';
import ProductDetailPage from 'pages/manufacturer/product/ProductDetailPage';
import ProductSampleBasicPage from 'pages/manufacturer/productSample/ProductSampleBasicPage';
import ProductSampleRecipePage from 'pages/manufacturer/productSample/ProductSampleRecipePage';
import ProductSampleRecipeDetailPage from 'pages/manufacturer/productSample/ProductSampleRecipeDetailPage';
import ProductSampleProcessMapPage from 'pages/manufacturer/productSample/ProductSampleProcessMapPage';
import ProductSampleReadyListPage from 'pages/manufacturer/productSample/ProductSampleReadyListPage';
import ProductSampleListPage from 'pages/manufacturer/productSample/ProductSampleListPage';
import ProductSampleDetailPage from 'pages/manufacturer/productSample/ProductSampleDetailPage';
import { useSetCurrentProduct } from 'service/brand/product/product';

const ManufacturersRouter = () => {
  const params = useParams<{ productId?: string }>();
  const productId = Number(params.productId) || undefined;
  useSetCurrentProduct({ productId, manuMode: true });

  const element = useRoutes([
    {
      path: 'product',
      children: [
        {
          path: 'ready-list',
          element: <ProductReadyListPage />,
        },
        {
          path: 'list',
          element: <ProductListPage />,
        },
        {
          path: ':productId',
          element: <ProductDetailPage />,
        },
        {
          path: ':productId/basic',
          element: <ProductBasicPage />,
        },
        {
          path: 'basic',
          element: <ProductBasicPage />,
        },
      ],
    },
    {
      path: 'product-sample',
      children: [
        {
          path: 'ready-list',
          element: <ProductSampleReadyListPage />,
        },
        {
          path: 'list',
          element: <ProductSampleListPage />,
        },
        {
          path: 'detail/:productSampleId',
          element: <ProductSampleDetailPage />,
        },
        {
          path: 'basic',
          element: <ProductSampleBasicPage />,
        },
        {
          path: 'basic/:productSampleId',
          element: <ProductSampleBasicPage />,
        },
        {
          path: 'recipe/:productSampleId',
          element: <ProductSampleRecipePage />,
        },
        {
          path: 'recipe-detail/:productSampleId',
          element: <ProductSampleRecipeDetailPage />,
        },
        {
          path: 'process-map/:productSampleId',
          element: <ProductSampleProcessMapPage />,
        },
      ],
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);
  return element;
};

export default ManufacturersRouter;

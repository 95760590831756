import { Checkbox, Select, Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { SearchInput, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { RootState } from 'modules';
import { addIngredientToBox, deleteIngredientToBox } from 'modules/material/ingredient';
import { IngredientDictionaryPageSearchParams } from 'pages/material/ingredient/IngredientDictionaryPage';
import { useIngredients } from 'service/material/ingredient';
import { IngredientListItem, IngredientSearchType } from 'types/material/ingredient';

const IngredientSearch = ({
  queryParams,
  onChangeSearchParams,
}: {
  queryParams: IngredientDictionaryPageSearchParams;
  onChangeSearchParams: (state: Partial<IngredientDictionaryPageSearchParams>) => void;
}) => {
  const navigate = useNavigate();
  const { page, firstSearchType, secondSearchType, firstKeyword, secondKeyword, isSearchAgain } =
    queryParams;
  const { ingredientListItems, total, getIngredientListItemsLoading } = useIngredients({
    page,
    firstSearchType,
    secondSearchType,
    firstKeyword,
    secondKeyword,
  });

  const [searchTypeBuffer, setSearchTypeBuffer] = useState<IngredientSearchType>(
    secondSearchType || firstSearchType || IngredientSearchType.NAME,
  );

  const dispatch = useDispatch();

  const { ingredientIds } = useSelector(
    ({ ingredient }: RootState) => ({
      ingredientIds: ingredient.ingredientIds,
    }),
    shallowEqual,
  );

  const searchOptions = [
    {
      label: '성분명',
      value: IngredientSearchType.NAME,
    },
    {
      label: 'INCI Name',
      value: IngredientSearchType.INCI_NAME,
    },
    {
      label: 'CAS No.',
      value: IngredientSearchType.CAS_NO,
    },
    {
      label: 'EC No.',
      value: IngredientSearchType.EC_NO,
    },
  ];

  const renderHighlightedText = ({
    text,
    medium = false,
    color = 'GRAY90',
    highlightColor = 'PRIMARY50',
    keyword1,
    keyword2,
  }: {
    text: string;
    medium?: boolean;
    color?: keyof typeof palette;
    highlightColor?: 'PRIMARY50';
    keyword1?: string;
    keyword2?: string;
  }) => {
    const firstKeyword = keyword1 || keyword2;
    const secondKeyword = keyword1 && keyword2 ? keyword2 : '';

    if (!firstKeyword)
      return (
        <div
          style={{
            maxWidth: 312,
          }}
        >
          <Typography.Text color={color} medium={medium} type="BODY_2" inline>
            {text}
          </Typography.Text>
        </div>
      );

    let regex: RegExp;

    if (!secondKeyword) {
      regex = new RegExp(`(${firstKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
    } else {
      /**
       * 두 단어가 서로 이어지는지 확인하는 함수
       * ex) 키워드가 '뿌리', '리줄' 일때 두 키워드뿐 아니라 '뿌리줄'도 하이라이팅이 되어야 한다.
       */
      const mergeWords = (word1: string, word2: string) => {
        const checkMerge = (first: string, second: string) => {
          for (let i = 0; i < first.length; i++) {
            const subStr = first.substring(i);
            if (second.startsWith(subStr)) {
              return first.substring(0, i) + second;
            }
          }
          return null;
        };

        let result = checkMerge(word1, word2);
        if (result) return result;

        result = checkMerge(word2, word1);
        if (result) return result;

        return null;
      };

      const mergedWord = mergeWords(firstKeyword, secondKeyword);

      if (mergedWord) {
        regex = new RegExp(
          `(${mergedWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}|${firstKeyword.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&',
          )}|${secondKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`,
          'gi',
        );
      } else {
        regex = new RegExp(
          `(${firstKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}|${secondKeyword.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&',
          )})`,
          'gi',
        );
      }
    }

    return (
      <div style={{ maxWidth: 312 }}>
        {text.split(regex).map((item, index) => (
          <Typography.Text
            key={index}
            medium={medium}
            type="BODY_2"
            color={regex.test(item) ? highlightColor : color}
            inline
          >
            {item}
          </Typography.Text>
        ))}
      </div>
    );
  };

  const handleSearch = (searchValue: string) => {
    if (isSearchAgain) {
      onChangeSearchParams({
        page: 1,
        secondKeyword: searchValue,
        secondSearchType: searchTypeBuffer,
      });
    } else {
      onChangeSearchParams({
        page: 1,
        firstKeyword: searchValue,
        firstSearchType: searchTypeBuffer,
        secondKeyword: '',
        secondSearchType: IngredientSearchType.NAME,
      });
    }
  };

  const handleChangePage = (page: number) => {
    onChangeSearchParams({
      page,
    });
  };

  const handleChangeIsSearchAgain = (isSearchAgain: boolean) => {
    onChangeSearchParams({
      isSearchAgain,
    });
  };

  const handleMoveToDetail = ({ ingredientId }: IngredientListItem) => ({
    onClick: () => {
      navigate(`${path.material.ingredient.detail}/${ingredientId}`);
    },
    style: {
      cursor: 'pointer',
    },
  });

  const columns: ColumnsType<IngredientListItem> = [
    {
      width: 64,
      align: 'center',
      render: (_, { ingredientId }) => {
        const isSelected = ingredientIds.includes(ingredientId);

        return (
          <Icon
            name={isSelected ? 'checkCircleActived' : 'checkCircleInactived'}
            onClick={() => {
              if (isSelected) {
                dispatch(
                  deleteIngredientToBox(ingredientIds.findIndex((id) => id === ingredientId)),
                );
              } else if (ingredientIds.length === 3) {
                message.warning('최대 3개까지 담을 수 있습니다.');
              } else {
                dispatch(addIngredientToBox(ingredientId));
              }
            }}
            size={20}
            style={{ margin: '0 auto' }}
          />
        );
      },
    },
    {
      title: '성분명',
      width: 344,
      onCell: handleMoveToDetail,
      render: (_, { name }) =>
        name
          ? renderHighlightedText({
              text: name.name,
              medium: true,
              ...(firstSearchType === IngredientSearchType.NAME && {
                keyword1: firstKeyword,
              }),
              ...(secondSearchType === IngredientSearchType.NAME && {
                keyword2: secondKeyword,
              }),
            })
          : '-',
    },
    {
      title: 'INCI Name',
      width: 344,
      onCell: handleMoveToDetail,
      render: (_, { inciNames }) =>
        inciNames && inciNames.length > 0
          ? renderHighlightedText({
              text: inciNames[0].name,
              color: 'GRAY70',
              ...(firstSearchType === IngredientSearchType.INCI_NAME && {
                keyword1: firstKeyword,
              }),
              ...(secondSearchType === IngredientSearchType.INCI_NAME && {
                keyword2: secondKeyword,
              }),
            })
          : '-',
    },
    {
      title: 'CAS No.',
      width: 144,
      align: 'center',
      onCell: handleMoveToDetail,
      render: (_, { casNos }) => {
        const allCasNos = casNos?.map(({ casNo }) => casNo).join(', ');
        return (
          <>
            {allCasNos
              ? renderHighlightedText({
                  text: allCasNos,
                  color: 'GRAY70',
                  ...(firstSearchType === IngredientSearchType.CAS_NO && {
                    keyword1: firstKeyword,
                  }),
                  ...(secondSearchType === IngredientSearchType.CAS_NO && {
                    keyword2: secondKeyword,
                  }),
                })
              : '-'}
          </>
        );
      },
    },
    {
      title: 'EC No.',
      width: 144,
      align: 'center',
      onCell: handleMoveToDetail,
      render: (_, { ecNos }) => {
        const allEcNos = ecNos?.map(({ ecNo }) => ecNo).join(', ');

        return allEcNos && allEcNos.length > 0
          ? renderHighlightedText({
              text: allEcNos,
              color: 'GRAY70',
              ...(firstSearchType === IngredientSearchType.EC_NO && {
                keyword1: firstKeyword,
              }),
              ...(secondSearchType === IngredientSearchType.EC_NO && {
                keyword2: secondKeyword,
              }),
            })
          : '-';
      },
    },
  ];

  return (
    <Container>
      <Typography.Text light type="HEADLINE_1" align="center" gutter={{ top: 4, bottom: 40 }}>
        원하는 성분을 찾아보고
        <br />
        성분을 선택하여 비교해 보세요.
      </Typography.Text>
      <SearchWrapper>
        <Flex gap={24} align="center">
          <Flex gap={8} align="center">
            <Select
              defaultValue={IngredientSearchType.NAME}
              value={searchTypeBuffer}
              options={searchOptions}
              style={{ width: 160 }}
              onChange={setSearchTypeBuffer}
            />
            <SearchInput
              defaultValue={secondKeyword || firstKeyword}
              placeholder="검색어 입력"
              onSearch={handleSearch}
              style={{ width: 640 }}
            />
          </Flex>
          <Checkbox
            disabled={!firstKeyword}
            checked={isSearchAgain}
            onChange={(e) => handleChangeIsSearchAgain(e.target.checked)}
          >
            결과 내 재검색
          </Checkbox>
        </Flex>
      </SearchWrapper>
      {firstKeyword && (
        <SearchResult>
          <Typography.Text medium gutter={{ bottom: 16 }}>
            ‘
            <Typography.Text medium color="PRIMARY50" inline>
              {firstKeyword}
            </Typography.Text>
            ’
            {secondKeyword && (
              <Typography.Text medium inline>
                , ‘
                <Typography.Text medium color="PRIMARY50" inline>
                  {secondKeyword}
                </Typography.Text>
                ’
              </Typography.Text>
            )}{' '}
            검색 결과입니다.
          </Typography.Text>
          <Table
            loading={getIngredientListItemsLoading}
            columns={columns}
            dataSource={ingredientListItems}
            pagination={{
              current: page,
              onChange: handleChangePage,
              total,
              pageSize: 10,
            }}
            rowKey={({ ingredientId }) => ingredientId}
          />
        </SearchResult>
      )}
    </Container>
  );
};

const Container = styled.div`
  .ant-checkbox + span {
    font-size: 16px;
    padding-left: 8px;
    padding-right: 0;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 10px 16px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 18px 16px;
  }
`;

const SearchWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${palette.GRAY10};
  padding: 40px 0px;
  gap: 24px;
`;

const SearchResult = styled.div`
  margin-top: 32px;
`;

export default IngredientSearch;

import ManufacturerManage from 'components/manufacturer/ManufacturerManage';
import { useSearchParams } from 'hook/useSearchParams';
import path from 'lib/path';

const ManufacturerManagePage = () => {
  const { searchParams } = useSearchParams({
    path: `${path.my}/manufacturers`,
  });

  const { manufacturerId }: { manufacturerId?: number } = {
    manufacturerId: Number(searchParams.get('manufacturerId')) || undefined,
  };

  return <ManufacturerManage manufacturerId={manufacturerId} />;
};

export default ManufacturerManagePage;

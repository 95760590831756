import { useParams } from 'react-router-dom';

import NMPADocumentList from 'components/material/nmpa/NMPADocumentList';
import NMPAPageTemplate from 'templates/material/NMPAPageTemplate';

const NMPADocumentListPage = () => {
  const params = useParams<{ materialId: string }>();
  const materialId = Number(params.materialId);

  return (
    <NMPAPageTemplate
      title="원료 신고 정보"
      description="취득을 진행하고자 하는 원료의 정보를 입력해 주세요."
    >
      <NMPADocumentList materialId={materialId} />
    </NMPAPageTemplate>
  );
};

export default NMPADocumentListPage;

import { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import StompJS from 'stompjs';

import { getReactEnvVar } from 'lib/common';

const useScreeningSocket = ({ fileId }: { fileId?: string }) => {
  const [screeningStatus, setScreeningStatus] = useState<string>();
  useEffect(() => {
    if (!fileId) return;
    const initSocket = () => {
      const socket = new SockJS(`${getReactEnvVar('SERVER_URL')}/api/v1/socket/connection`);
      const client = StompJS.over(socket);
      if (process.env.NODE_ENV !== 'development') {
        client.debug = () => undefined;
      }

      client.connect({}, () => {
        client.subscribe(`/api/v1/socket/subscribe/${fileId}/complete`, ({ body }) => {
          const { status } = JSON.parse(body);
          client.disconnect(() => undefined);

          return setScreeningStatus(status);
        });
      });
      return client;
    };
    initSocket();
    return () => {
      setScreeningStatus(undefined);
    };
  }, [fileId]);

  return screeningStatus;
};

export default useScreeningSocket;

import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { debounce, isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PlusOutlined } from '@ant-design/icons';
import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { MinusIcon, Tip, Typography } from 'components/system';
import { CorrectButton, Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { useModal } from 'hook/useModal';
import { getChangedValues } from 'lib/common';
import { messages } from 'lib/consts';
import { downloadFile } from 'lib/file';
import { filterOptionForStringLabel, focusToInvalidatedField, isEmptyString } from 'lib/form';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { getNumberAndDotRule, getTextRule, requireRule } from 'lib/validate';
import {
  useNMPABasicInfoStatus,
  useNMPABasicOld,
  useNMPAChinaIngredients,
  useNMPACorrectWithNoUpdate,
  useNMPAOverwriteHistory,
  useNMPATradeNameDuplicateCheck,
} from 'service/material/nmpa/nmpa';
import {
  DocumentCode,
  MaterialNmpaBasicInfoStatus,
  NMPADocumentCode,
} from 'types/material/nmpa/nmpa';

const Container = styled.div`
  .text-center {
    .ant-form-item-explain {
      text-align: left;
    }
  }
  .oneline-error-message {
    &.ant-form-item-explain {
      padding-bottom: 12px;
    }
    .ant-form-item-explain {
      position: absolute;
      top: 100%;
      white-space: nowrap;
    }
  }

  .range-input-left {
    width: 92px;
    max-width: 92px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0px !important;
    margin-right: 10px;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      border-right-width: 1px !important;
    }
  }

  .range-input-right {
    width: 92px;
    max-width: 92px;
    transform: translateX(-14px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0px !important;
    margin-left: 10px;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      border-left-width: 1px !important;
    }
  }

  .range-input-between-char {
    background-color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    height: 44px;
    z-index: 1;
    top: 10px;
    right: 110px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }

  .ant-form-item-has-error {
    .range-input-right {
      border-left-width: 1px !important;
    }

    .range-input-left {
      border-right-width: 1px !important;
    }
  }
`;

const INCIContainer = styled.div`
  margin-top: 8px;
`;

const MaterialInfoContainer = styled.div`
  padding: 0px 200px;
`;

const TableContainer = styled.div`
  position: relative;

  .ant-table {
    border-top: 2px solid ${palette.PRIMARY50};
    border-radius: 0;
    overflow: visible;

    th.ant-table-cell {
      background-color: #f9f9f9 !important;
    }

    td.ant-table-cell {
      vertical-align: super;
    }

    .ant-table-cell {
      border-color: #d8d8d8;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input,
    input.ant-input,
    .ant-input-affix-wrapper,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-number-input,
    .ant-picker {
    }
  }

  .ant-checkbox + span {
    padding-left: 4px;
  }
`;

const ListCardBox = styled.ul`
  padding: 12px 16px;
  padding-left: 28px;
  border-radius: 8px;
  background-color: ${palette.SLATE_GRAY10};

  li {
    position: relative;
    list-style: none;
    letter-spacing: -0.5px;

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: -10px;
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-right: 4px;
      background-color: ${palette.SLATE_GRAY70};
      border-radius: 100%;
    }
  }
  li + li {
    margin-top: 4px;
  }
`;

const DescriptionGuideModalContainer = styled(Flex)`
  max-width: 300px;
  min-width: 300px;
  position: absolute;
  border-radius: 8px;
  background-color: ${palette.SLATE_GRAY70};
  top: 0px;
  left: calc(100% + 8px);

  > div {
    width: 100%;
    padding: 12px 16px;
    position: relative;

    > ul {
      list-style-type: none;
      padding-left: 24px;
      margin-bottom: 0px;

      > li {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          display: inline-block;
          left: -16px;
          top: 6px;
          width: 4px;
          height: 4px;
          border-radius: 100%;
          background-color: ${palette.ETC_WHITE};
        }
      }
    }
  }
`;

export interface NMPABasicForm {
  tradeName: string;
  versionNumber: string;
  materialNmpaBasicInfoIncis: {
    materialNmpaBasicInfoId?: number;
    materialNmpaBasicInfoInciId?: number;
    chinaIngredientId: number;
    content: string;
    description: string;
    rangeMin: string;
    rangeMax: string;
  }[];
}

const NMPABasic = ({ materialId }: { materialId?: number }) => {
  const [form] = Form.useForm<NMPABasicForm>();
  const [searchInciValue, setSearchInciValue] = useState('');
  // HINT: 최초 불러온 데이터를 저장하고 있고, 수정할 내역을 검사할 때 사용됩니다.
  const [initFormData, setInitFormData] = useState<NMPABasicForm>();
  const [descGuideVisible, setDescGuideVisible] = useState(true);
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { openNMPACorrectChatModal, openAlertModal } = useModal();
  const { nmpaChinaIngredients, isLoading: isChinaIngredientsLoading } = useNMPAChinaIngredients();
  const {
    nmpaBasicInfo,
    createNMPABasicInfo,
    updateNMPABasicInfo,
    isCreateNMPABasicInfoLoading,
    isUpdateNMPABasicInfoLoading,
  } = useNMPABasicOld(materialId);
  const { nmpaOverwriteHistory, nmpaOverwriteHistoryLoading } = useNMPAOverwriteHistory({
    nmpaBasicInfoId: materialId,
    documentCode: NMPADocumentCode.BASIC,
  });

  const materialNmpaBasicInfoId = nmpaBasicInfo?.materialNmpaBasicInfoId;

  const { nmpaCorrectRequestWithNoUpdate, nmpaCorrectRequestWithNoUpdateLoading } =
    useNMPACorrectWithNoUpdate({
      materialNmpaBasicInfoId,
      documentCode: NMPADocumentCode.BASIC,
    });
  const { duplicateCheck } = useNMPATradeNameDuplicateCheck();
  const { basicInfoStatus, isLoading: isBasicInfoStatusLoading } = useNMPABasicInfoStatus({
    materialId,
    documentCode: DocumentCode.BASIC,
    enabled: typeof materialId !== 'undefined',
  });
  const [isTradeNameDuplicated, setIsTradeNameDuplicated] = useState(false);
  const user = useSelector(({ auth }: any) => auth.user);

  const navigate = useNavigate();
  const readOnly =
    (nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.ONGOING &&
      !basicInfoStatus?.isNmpaDocModStatus) ||
    nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.FINISH;
  const updateMode = materialId !== undefined;
  const submitLoading =
    nmpaOverwriteHistoryLoading ||
    isCreateNMPABasicInfoLoading ||
    isUpdateNMPABasicInfoLoading ||
    isBasicInfoStatusLoading;
  const ctaName = useMemo(() => {
    if (!updateMode) return '등록';
    if (basicInfoStatus?.isNmpaDocModStatus) return '보완 완료';
    return '수정';
  }, [updateMode, basicInfoStatus]);
  const options = useMemo(
    () =>
      nmpaChinaIngredients?.map((nmpaChinaIngredient) => ({
        label: nmpaChinaIngredient.nameEn,
        value: nmpaChinaIngredient.chinaIngredientId,
      })),
    [nmpaChinaIngredients],
  );

  const generateOptions = (id: number) => {
    if (!nmpaChinaIngredients) {
      return [];
    }

    const materialNmpaBasicInfoIncis = form.getFieldValue('materialNmpaBasicInfoIncis');
    const selectedInciIds = materialNmpaBasicInfoIncis.map((inci: any) => inci.chinaIngredientId);

    return (
      nmpaChinaIngredients
        .filter(
          (ingredient) =>
            ingredient.chinaIngredientId === id ||
            !selectedInciIds.includes(ingredient.chinaIngredientId),
        )
        .map((nmpaChinaIngredient) => ({
          label: nmpaChinaIngredient.nameEn,
          value: nmpaChinaIngredient.chinaIngredientId,
        }))
        // HINT: nameEn이 비어있는 항목을 제외하고 정확히 일치하는 값을 리스트 맨 위로 올림
        .sort((a) =>
          !!a.label && a.label.toLowerCase() === searchInciValue.toLowerCase() ? -1 : 0,
        )
    );
  };

  const generateContentAndRangeValidation = (name: number, messagePos?: 'left' | 'right') => ({
    validator: () => {
      const { content, rangeMin, rangeMax } = form.getFieldValue([
        'materialNmpaBasicInfoIncis',
        name,
      ]);

      const { pattern } = getNumberAndDotRule({
        start: 0,
        end: 50,
        message: '올바르지 않은 형식',
      });

      if (!content && (isEmptyString(rangeMin) || isEmptyString(rangeMax))) {
        if (messagePos === 'left') {
          return Promise.reject('함량/범위 중 1개 항목 입력 필요');
        } else {
          return Promise.reject();
        }
      } else if ((rangeMin && isEmptyString(rangeMax)) || (isEmptyString(rangeMin) && rangeMax)) {
        if (messagePos === 'right') {
          return Promise.reject('수치를 확인해 주세요');
        } else {
          return Promise.reject();
        }
      } else if (Number(rangeMin) > Number(rangeMax)) {
        if (messagePos === 'right') {
          return Promise.reject('최소값은 최대값 보다 클 수 없음');
        } else {
          return Promise.reject();
        }
      } else if (
        // 공백에 대한 강제 벨리데이션
        !pattern.test(content || '0')
      ) {
        if (messagePos === 'left') {
          return Promise.reject('숫자 및 온점만 입력, 최대 50자');
        } else {
          return Promise.reject();
        }
      } else if (
        // 공백에 대한 강제 벨리데이션
        !pattern.test(rangeMin || '0') ||
        !pattern.test(rangeMax || '0')
      ) {
        if (messagePos === 'right') {
          return Promise.reject('숫자 및 온점만 입력, 최대 50자');
        } else {
          return Promise.reject();
        }
      } else {
        return Promise.resolve();
      }
    },
  });

  const validateContentAndRangeValidation = (name: number) => () => {
    const fieldsToValidate = [
      ['materialNmpaBasicInfoIncis', name, 'content'],
      ['materialNmpaBasicInfoIncis', name, 'rangeMin'],
      ['materialNmpaBasicInfoIncis', name, 'rangeMax'],
    ];
    form.validateFields(fieldsToValidate);
  };

  const handleTradeNameDuplicateCheck = debounce(() => {
    const tradeName: string = form.getFieldValue('tradeName');

    if (!tradeName.trim()) return;

    duplicateCheck(
      { tradeName },
      {
        onSuccess: (res) => {
          setIsTradeNameDuplicated(res.data.result);
        },
      },
    );
  }, 250);

  const handleNoUpdateSubmit = () => {
    if (materialNmpaBasicInfoId) {
      openAlertModal({
        content: `
'보완할 내용 없음' 버튼을 클릭하시면, 관리자의 보완 요청이 있기 전까지는 수정이 불가합니다.

보완할 내용 없이 처리 하시겠습니까?
        `,
        onOk: () => {
          nmpaCorrectRequestWithNoUpdate(
            {},
            {
              onSuccess: () => {
                message.success('보완 내용 없이 완료 처리되었습니다.');
                navigate(-1);
              },
            },
          );
        },
      });
    }
  };

  const handleSubmit = () => {
    const formData = form.getFieldsValue();

    if (
      Number(
        formData.materialNmpaBasicInfoIncis
          .reduce((prev, next) => prev + Number(next.content), 0)
          .toFixed(10),
      ) !== 100
    ) {
      message.warning('함량의 합이 100이 아닌 경우 입력할 수 없습니다.');
      return;
    }

    if (initFormData !== undefined && isEqual(formData, initFormData)) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    if (initFormData) {
      const newInitFormData = {
        ...initFormData,
        materialNmpaBasicInfoIncis: initFormData.materialNmpaBasicInfoIncis.map((item) => ({
          ...item,
          key: item.chinaIngredientId,
        })),
      };

      const newFormData = {
        ...formData,
        materialNmpaBasicInfoIncis: formData.materialNmpaBasicInfoIncis.map((item) => ({
          ...item,
          key: item.chinaIngredientId,
        })),
      };

      nmpaOverwriteHistory(getChangedValues({ obj1: newInitFormData, obj2: newFormData }));
    }

    if (updateMode) {
      if (!materialId) {
        return message.warning(messages.INVALID_APPROACH);
      }
      updateNMPABasicInfo(
        {
          materialId,
          ...formData,
          companyId: user.companyId,
        },
        {
          onSuccess: () => {
            message.success('수정되었습니다.');
            navigate(-1);
          },
          onError: () => {
            if (nmpaBasicInfo) {
              const newNmpaBasicInfo = {
                ...nmpaBasicInfo,
                tradeName: nmpaBasicInfo.tradeName,
                versionNumber: nmpaBasicInfo.versionNumber || '',
                materialNmpaBasicInfoIncis: nmpaBasicInfo.materialNmpaBasicInfoIncis.map(
                  (item) => ({
                    materialNmpaBasicInfoId: item.materialNmpaBasicInfoId,
                    materialNmpaBasicInfoInciId: item.materialNmpaBasicInfoInciId,
                    chinaIngredientId: item.chinaIngredient.chinaIngredientId,
                    content: item.content.toString(),
                    description: item.description,
                    rangeMax: item.rangeMax.toString(),
                    rangeMin: item.rangeMin.toString(),
                  }),
                ),
              };

              form.setFieldsValue(newNmpaBasicInfo);
            }
          },
        },
      );
    } else {
      createNMPABasicInfo(
        {
          ...formData,
          companyId: user.companyId,
        },
        {
          onSuccess: (res) => {
            message.success('등록되었습니다.');
            navigate(`${path.material.nmpa.material.root}/${res.data.result}`, {
              replace: true,
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    if (!nmpaBasicInfo) return;

    const newNmpaBasicInfo = {
      ...nmpaBasicInfo,
      tradeName: nmpaBasicInfo.tradeName,
      versionNumber: nmpaBasicInfo.versionNumber || '',
      materialNmpaBasicInfoIncis: nmpaBasicInfo.materialNmpaBasicInfoIncis.map((item) => ({
        materialNmpaBasicInfoId: item.materialNmpaBasicInfoId,
        materialNmpaBasicInfoInciId: item.materialNmpaBasicInfoInciId,
        chinaIngredientId: item.chinaIngredient.chinaIngredientId,
        content: item.content?.toString() || '',
        description: item.description,
        rangeMax: item.rangeMax?.toString() || '',
        rangeMin: item.rangeMin?.toString() || '',
      })),
    };

    form.setFieldsValue(newNmpaBasicInfo);
    setTimeout(() => setInitFormData(form.getFieldsValue()), 0);
  }, [nmpaBasicInfo]);

  const columns: ColumnsType<{
    name: number;
    remove: (index: number) => void;
  }> = [
    {
      title: <Typography.Text gutter={{ left: 16 }}>No.</Typography.Text>,
      render: (_, { remove }, index) => (
        <>
          {!readOnly && index > 0 && (
            <MinusIcon
              style={{ position: 'absolute', left: -40, top: 20 }}
              onClick={() => remove(index)}
            />
          )}
          <Typography.Text gutter={{ left: 16 }} style={{ color: palette.SLATE_GRAY70 }}>
            {index + 1}
          </Typography.Text>
        </>
      ),
    },
    {
      title: (
        <Typography.Text gutter={{ left: 16 }} asterisk>
          INCI Name
        </Typography.Text>
      ),
      width: 320,
      render: (_, { name }, index) => (
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.materialNmpaBasicInfoIncis !== next.materialNmpaBasicInfoIncis
          }
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item name={[name, 'chinaIngredientId']} rules={[requireRule]}>
                <Select
                  showSearch
                  disabled={readOnly}
                  placeholder="입력 또는 선택"
                  style={{ width: 310 }}
                  options={generateOptions(
                    getFieldValue(['materialNmpaBasicInfoIncis', name, 'chinaIngredientId']),
                  )}
                  filterOption={filterOptionForStringLabel}
                  onSearch={(value) => setSearchInciValue(value)}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      ),
    },
    {
      width: 314,
      title: <Typography.Text asterisk>함량 / 범위 (1개 항목 선택)</Typography.Text>,
      align: 'center',
      render: (_, { name }) => (
        <Flex align="flex-start" columnGap={4}>
          <Form.Item
            className="oneline-error-message"
            name={[name, 'content']}
            rules={[generateContentAndRangeValidation(name, 'left')]}
          >
            <Input
              onChange={validateContentAndRangeValidation(name)}
              placeholder="함량"
              disabled={readOnly}
              style={{ width: 90, maxWidth: 90, marginRight: '8px' }}
            />
          </Form.Item>
          <Typography.Text
            type="BODY_2"
            color="GRAY90"
            style={{ transform: 'translate(-8px, 12px)' }}
          >
            %
          </Typography.Text>
          <Form.Item
            className="oneline-error-message"
            name={[name, 'rangeMin']}
            rules={[generateContentAndRangeValidation(name, 'right')]}
          >
            <Input
              className="range-input-left"
              disabled={readOnly}
              placeholder="최소"
              onChange={validateContentAndRangeValidation(name)}
            />
          </Form.Item>
          <Typography.Text color="GRAY90" className="range-input-between-char">
            ~
          </Typography.Text>
          <Form.Item name={[name, 'rangeMax']} rules={[generateContentAndRangeValidation(name)]}>
            <Input
              className="range-input-right"
              disabled={readOnly}
              placeholder="최대"
              onChange={validateContentAndRangeValidation(name)}
            />
          </Form.Item>
          <Typography.Text
            type="BODY_2"
            color="GRAY90"
            style={{
              position: 'absolute',
              right: -8,
              transform: 'translate(-12px, 12px)',
            }}
          >
            %
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: (
        <Flex columnGap={4} justify="center" align="center">
          <Typography.Text>비고</Typography.Text>
          <Tip
            isBodyVisible={false}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setDescGuideVisible((prevVisible) => !prevVisible);
            }}
          />
        </Flex>
      ),
      align: 'center',
      render: (_, { name }) => (
        <Form.Item
          className="text-center"
          name={[name, 'description']}
          rules={[
            getTextRule({
              start: 0,
              end: 50,
              message: '최대 50자',
            }),
          ]}
        >
          <Input disabled={readOnly} />
        </Form.Item>
      ),
    },
  ];

  const loading = !options?.length || (materialId && !nmpaBasicInfo) || isChinaIngredientsLoading;

  return (
    <Container>
      {loading && <FullLoading />}
      {basicInfoStatus?.isNmpaDocModStatus && (
        <Flex justify="end" style={{ marginBottom: 16 }}>
          <CorrectButton
            onClick={() => {
              openNMPACorrectChatModal({
                title: nmpaBasicInfo?.tradeName,
                nmpaBasicInfoId: materialNmpaBasicInfoId,
                documentCode: NMPADocumentCode.BASIC,
                isFirstOpenChatModal,
              });
              setIsFirstOpenChatModal(true);
            }}
            disableAnim={false}
          />
        </Flex>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
      >
        <MaterialInfoContainer>
          <Form.Item
            label={
              <Row gutter={8} align="middle">
                <Col>
                  <Typography.Text type="TITLE_2">원료명(상품명)</Typography.Text>
                </Col>
              </Row>
            }
            name="tradeName"
            required
            rules={[
              requireRule,
              getTextRule({
                end: 200,
                message: '최대 200자',
              }),
            ]}
            style={{
              marginBottom: 8,
            }}
          >
            <Input
              disabled={readOnly}
              placeholder="실제 등록될 원료명"
              onKeyDown={() => setIsTradeNameDuplicated(false)}
              onKeyUp={handleTradeNameDuplicateCheck}
            />
          </Form.Item>
          {isTradeNameDuplicated && (
            <Typography.Text
              type="CAPTION"
              color="PRIMARY50"
              style={{ transform: 'translateY(-6px)' }}
            >
              중복된 원료명 : 중복된 원료명이긴 하나, 진행 가능합니다.
            </Typography.Text>
          )}
          <Form.Item
            label="버전 번호"
            name="versionNumber"
            rules={[
              getTextRule({
                end: 50,
                message: '최대 50자',
              }),
            ]}
            style={{ marginTop: 16 }}
          >
            <Input placeholder="원료 버전 번호가 있는 경우 입력해 주세요." disabled={readOnly} />
          </Form.Item>
        </MaterialInfoContainer>
        <INCIContainer>
          <Typography.Text type="TITLE_2" asterisk asteriskSize={16} gutter={{ bottom: 8 }}>
            원료 성분 조성
          </Typography.Text>
          <ListCard
            items={[
              '원료를 구성하는 각 성분의 조성 정보를 입력해 주세요.',
              <>
                INCI Name 은 중국기사용화장품원료 목록에 있는 성분만 입력 가능합니다.
                <Button
                  type="default"
                  style={{
                    padding: '0px 8px',
                    marginLeft: 8,
                    height: 24,
                    fontSize: 12,
                  }}
                  onClick={() => {
                    downloadFile(
                      'https://30cos.s3.ap-northeast-2.amazonaws.com/material/nmpa/%E1%84%80%E1%85%B5%E1%84%89%E1%85%A1%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%92%E1%85%AA%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%91%E1%85%AE%E1%86%B7%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%85%E1%85%AD%E1%84%86%E1%85%A9%E1%86%A8%E1%84%85%E1%85%A9%E1%86%A8.xlsx',
                      '중국기사용화장품원료목록.xlsx',
                    );
                  }}
                >
                  중국기사용화장품원료목록 보기
                </Button>
              </>,
              <Typography.Text type="BODY_2" color="SLATE_GRAY70" medium underline>
                합성 공정으로 인한 혼합물인 경우 범위를, 그 외 혼합물인 경우 함량을 반드시 입력해
                주세요.
              </Typography.Text>,
            ]}
          />
          <Form.List name="materialNmpaBasicInfoIncis" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                <TableContainer>
                  <DescriptionGuideModal
                    visible={descGuideVisible}
                    onClose={() => setDescGuideVisible(false)}
                  />
                  <Table
                    columns={columns}
                    pagination={false}
                    dataSource={fields.map(({ name }) => ({ name, remove }))}
                    rowKey="name"
                  />
                </TableContainer>
                {!readOnly && (
                  <Button
                    type="dashed"
                    icon={<PlusOutlined style={{ color: palette.PRIMARY50 }} />}
                    block
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '24px auto 0',
                      maxWidth: 520,
                    }}
                    onClick={() => {
                      if (fields.length >= 50) {
                        message.warning('최대 50개까지 입력 가능합니다.');
                        return;
                      }
                      add({});
                    }}
                  >
                    성분 추가
                  </Button>
                )}
              </>
            )}
          </Form.List>
        </INCIContainer>
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnly}>
            <Flex>
              {basicInfoStatus?.isNmpaDocModStatus && (
                <Button
                  loading={nmpaCorrectRequestWithNoUpdateLoading}
                  onClick={handleNoUpdateSubmit}
                >
                  보완할 내용 없음
                </Button>
              )}
              <Button type="primary" loading={submitLoading} onClick={form.submit}>
                {ctaName}
              </Button>
            </Flex>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </Container>
  );
};

const ListCard = ({ items }: { items: string[] | React.ReactNode[] }) => (
  <ListCardBox>
    {items?.map((item: string | React.ReactNode, itemIdx: number) => (
      <li key={itemIdx}>
        <Typography.Text type="BODY_2" color="SLATE_GRAY70">
          {item}
        </Typography.Text>
      </li>
    ))}
  </ListCardBox>
);

const DescriptionGuideModal = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose?: VoidFunction;
}) =>
  visible ? (
    <DescriptionGuideModalContainer dir="column" rowGap={4}>
      <div>
        <Icon
          name="smallClose"
          size={14}
          color="GRAY90"
          circleColor="ETC_WHITE"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 16,
            right: 16,
            cursor: 'pointer',
          }}
        />
        <Typography.Text type="BODY_2" color="ETC_WHITE" medium>
          🖋 비고 작성 가이드{' '}
        </Typography.Text>
        <ul>
          <li>
            <Typography.Text type="SMALL" color="ETC_WHITE">
              나노물질인 경우 → Nano
            </Typography.Text>
          </li>
          <li>
            <Typography.Text type="SMALL" color="ETC_WHITE">
              석유 및 콜타르 유해 탄화수소 사용 시 CAS No. 작성 → 12345-67-8
            </Typography.Text>
          </li>
          <li>
            <Typography.Text type="SMALL" color="ETC_WHITE">
              착색제는 호수 유형 명시 → CI12345
            </Typography.Text>
          </li>
        </ul>
      </div>
    </DescriptionGuideModalContainer>
  ) : null;

export default NMPABasic;

import { useLocation, useNavigate } from 'react-router-dom';

import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import ProductList from 'components/brand/retailer/retailerApply/product/ProductList';
import { ProductListPageState } from 'types/brand/retailer/retailerApply';

const ProductListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const productListPageState = location.state || {
    page: 1,
    searchValue: '',
  };

  const handleChangePageState = (state: ProductListPageState) => {
    navigate(path.retailer.retailerApply.product.list, {
      state,
      replace: true,
    });
  };

  return (
    <PageTemplate
      back={false}
      title="국내 입점 서류 관리"
      description="제품을 선택하여 유통사에 제출할 서류들을 한 번에 받아보세요."
    >
      <ProductList
        initialPageState={productListPageState}
        onChangePageState={handleChangePageState}
      />
    </PageTemplate>
  );
};

export default ProductListPage;

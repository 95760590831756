import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { fabric } from 'fabric';

import palette from 'lib/styles/palette';

const Container = styled.div<{
  canvasWidth?: number;
  canvasHeight?: number;
}>`
  width: 920px;
  height: 650px;
  border: 1px solid ${palette.GRAY30};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ canvasWidth, canvasHeight }) =>
    canvasWidth !== undefined &&
    canvasHeight !== undefined &&
    `
  canvas, .canvas-container {
    width: ${canvasWidth}px !important;
    height: ${canvasHeight}px !important;
  }
`}
`;

type ObObjectClick = ({
  leftTopX,
  leftTopY,
  rightBottomX,
  rightBottomY,
}: {
  leftTopX: number;
  leftTopY: number;
  rightBottomX: number;
  rightBottomY: number;
}) => void;

const ScreeningResultViewer = React.forwardRef<
  HTMLCanvasElement,
  {
    data?: {
      bgImgSrc?: string;
      fabricData?: string;
    };
    onObjectClick?: ObObjectClick;
  }
>(({ data, onObjectClick }, ref) => {
  const [canvasSize, setCanvasSize] = useState<{
    width: number;
    height: number;
  }>();
  const variablesRef = useRef<{
    bgImgSrc?: string;
  }>();
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const objectClickFuncRef = useRef<ObObjectClick>();

  useEffect(() => {
    objectClickFuncRef.current = onObjectClick;
  }, [onObjectClick]);

  useEffect(() => {
    if (!canvas) return;
  }, [canvas]);

  useEffect(() => {
    if (!canvas || !data) return;
    else if (variablesRef.current?.bgImgSrc === data.bgImgSrc) return;

    const attachEventsToGroups = (objects: fabric.Object[]) => {
      for (const object of objects) {
        const objectAny: any = object;
        object.on('mouseup', () => {
          if (!objectClickFuncRef.current) return;

          objectClickFuncRef.current({
            leftTopX: objectAny.leftTopPointX,
            leftTopY: objectAny.leftTopPointY,
            rightBottomX: objectAny.rightBottomPointX,
            rightBottomY: objectAny.rightBottomPointY,
          });
        });
      }
    };

    const sendToBackObjects = (objects: fabric.Object[]) => {
      for (const object of objects) {
        canvas.sendToBack(object);
      }
    };

    variablesRef.current = { bgImgSrc: data.bgImgSrc };

    const image = new Image();
    image.src = data.bgImgSrc || '';
    image.addEventListener('load', () => {
      const canvasWidth = 918;
      const canvasHeight = 648;
      const fabricImage = new fabric.Image(image);
      const parsedFabricData =
        typeof data.fabricData === 'string' ? JSON.parse(data.fabricData || '{}') : data.fabricData;

      const calcTargetPosSAndSize = () => {
        const min = {
          x: 0,
          y: 0,
        };

        const max = {
          x: image.width,
          y: image.height,
        };

        for (const object of parsedFabricData.objects || []) {
          const rightBottomX = object.left + object.width;
          const rightBottomY = object.top + object.height;

          if (object.left < min.x) {
            min.x = object.left;
          }

          if (object.top < min.y) {
            min.y = object.top;
          }

          if (rightBottomX > max.x) {
            max.x = rightBottomX;
          }

          if (rightBottomY > max.y) {
            max.y = rightBottomY;
          }
        }

        return {
          min,
          max,
          width: Math.floor(Math.abs(min.x) + Math.abs(max.x)),
          height: Math.floor(Math.abs(min.y) + Math.abs(max.y)),
        };
      };

      const { width, height, min } = calcTargetPosSAndSize();

      const canvasSize = {
        width,
        height,
      };

      if (width < canvasWidth && height < canvasHeight) {
        canvasSize.width = width;
        canvasSize.height = height;
      } else {
        let scale = canvasWidth / width;

        if (height * scale > canvasHeight) {
          scale = canvasHeight / height;
        }

        canvasSize.width = width * scale;
        canvasSize.height = height * scale;

        canvas.setViewportTransform([1, 0, 0, 1, -min.x, -min.y]);
      }

      canvas.setDimensions({
        width,
        height,
      });
      setCanvasSize(canvasSize);

      canvas.loadFromJSON(parsedFabricData, () => {
        canvas.setBackgroundImage(fabricImage, () => {
          attachEventsToGroups(canvas.getObjects('group'));
          sendToBackObjects(canvas.getObjects('rect'));
          sendToBackObjects(canvas.getObjects('ellipse'));

          canvas.renderAll();
        });
      });
    });
  }, [canvas, data]);

  useEffect(() => {
    const canvasEl = document.querySelector<HTMLCanvasElement>('#screening_result');
    if (!canvasEl) return;

    const fabricCanvas = new fabric.Canvas(canvasEl, {
      selection: false,
      hoverCursor: 'pointer',
    });

    setCanvas(fabricCanvas);

    return () => {
      fabricCanvas.dispose();
    };
  }, []);

  return (
    <Container canvasWidth={canvasSize?.width} canvasHeight={canvasSize?.height}>
      <canvas id="screening_result" ref={ref} />
    </Container>
  );
});

export default ScreeningResultViewer;

import styled from 'styled-components';
import { Form, FormInstance, Input, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';

import { generateFormRules } from 'lib/validate';
import { Flex } from 'components/ui';
import { Tip, Typography } from 'components/system';
import { QCQAProduct } from 'types/brand/qcqa';

const Container = styled.div`
  margin: 40px auto 0;
  max-width: 520px;
`;

export interface QCQAProductForm {
  brandName: string;
  productNameKo: string;
  productNameEn: string;
}

const QCQAProductBasic = ({
  form,
  qcProduct,
  isUpdateMode = false,
}: {
  form: FormInstance<QCQAProductForm>;
  qcProduct?: QCQAProduct | undefined;
  isUpdateMode?: boolean;
}) => {
  const { company } = useSelector(
    ({ auth, company }: any) => ({
      company: company.company,
      user: auth.user,
    }),
    shallowEqual,
  );

  const validateInputs = () => {
    form.validateFields(['productNameKo', 'productNameEn']);
  };

  return (
    <Container>
      <Spin spinning={isUpdateMode && !qcProduct}>
        <Form.Item
          label="브랜드명"
          name="brandName"
          rules={generateFormRules({
            required: true,
            maxLength: 100,
          })}
        >
          <Input />
        </Form.Item>
        <Flex align="flex-end" gap={8}>
          <Form.Item
            label={
              <Flex align="center">
                <Typography.Text type="TITLE_2">제품명 (국문/영문)</Typography.Text>
                <ProductNameTip />
              </Flex>
            }
            required
            name="productNameKo"
            rules={[
              ...generateFormRules({
                maxLength: 100,
              }),
              {
                validator: (_, value) => {
                  if (!value && !form.getFieldValue('productNameEn')) {
                    return Promise.reject(new Error('최소 1개 필수 입력'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            style={{ flex: 1 }}
          >
            <Input disabled={isUpdateMode} placeholder="국문 제품명" onChange={validateInputs} />
          </Form.Item>
          <Form.Item
            name="productNameEn"
            rules={[
              ...generateFormRules({
                maxLength: 100,
                exceptKorean: true,
              }),
              {
                validator: (_, value) => {
                  if (!value && !form.getFieldValue('productNameKo')) {
                    return Promise.reject(new Error('최소 1개 필수 입력'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            style={{ flex: 1 }}
          >
            <Input disabled={isUpdateMode} placeholder="영문 제품명" onChange={validateInputs} />
          </Form.Item>
        </Flex>
        <Flex gap={8}>
          <Form.Item label="화장품책임판매업자 (국문)" required style={{ flex: 1 }}>
            <Input disabled value={company.companyNameKo} />
          </Form.Item>
          <Form.Item label="화장품책임판매업자 (영문)" required style={{ flex: 1 }}>
            <Input disabled value={company.companyNameEn} />
          </Form.Item>
        </Flex>
        <Form.Item label="화장품책임판매업자 주소 (국문)" required>
          <Input disabled value={company.addressKo} />
        </Form.Item>
        <Form.Item label="화장품책임판매업자 주소 (영문)" required>
          <Input disabled value={company.addressEn} />
        </Form.Item>
      </Spin>
    </Container>
  );
};

const ProductNameTip = () => (
  <Tip style={{ marginLeft: 4 }} trigger="click">
    <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
      제품명에 브랜드명이 포함되길 원하는 경우
    </Typography.Text>
    <Typography.Text type="BODY_2" style={{ fontSize: 10 }}>
      예시 ① 브랜드명 <span className="primary">certicos</span> 제품명{' '}
      <span className="primary">soothing cream</span> 인 경우 →{' '}
      <span className="primary">'soothing cream'</span> 으로 인증
    </Typography.Text>
    <Typography.Text type="BODY_2" style={{ fontSize: 10 }}>
      예시 ② 브랜드명 <span className="primary">certicos</span> 제품명{' '}
      <span className="primary">certicos soothing cream</span> 인 경우 →{' '}
      <span className="primary">'certicos soothing cream'</span> 으로 인증
    </Typography.Text>
  </Tip>
);

export default QCQAProductBasic;

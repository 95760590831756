import styled from 'styled-components';
import { Radio, RadioChangeEvent } from 'antd';

import palette from 'lib/styles/palette';
import Flex from '../Flex/Flex';
import { Typography } from 'components/system';

const Container = styled(Flex)`
  padding: 8px 0px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 4px;

  .ant-col:first-child {
    margin-right: 16px;
  }
`;

const AllergenBackgroundRadio = ({
  value,
  onChange,
  options,
  disabled = false,
  bodyStyle,
  gap,
}: {
  value?: boolean;
  onChange?: (e: RadioChangeEvent) => void;
  options: { title: string; value: boolean }[];
  disabled?: boolean;
  bodyStyle?: React.CSSProperties;
  gap?: number;
}) => {
  return (
    <Container justify="center" style={bodyStyle} dir="column">
      <Radio.Group value={value} onChange={onChange} disabled={disabled}>
        <Flex gap={gap} dir="column">
          <Radio value={options[0].value}>{options[0].title}</Radio>
          <Typography.Text type="SMALL" gutter={{ left: 24, top: 4, bottom: 16 }}>
            *
            <Typography.Text type="SMALL" color="PRIMARY50" inline>
              알레르기 유발성분 함량이 0%
            </Typography.Text>
            로 기입된 알러젠 리스트가 자동으로 발행
          </Typography.Text>
          <Radio value={options[1].value}>{options[1].title}</Radio>
        </Flex>
      </Radio.Group>
    </Container>
  );
};

export default AllergenBackgroundRadio;

import { useLocation, useParams } from 'react-router-dom';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import QCQALot from 'components/brand/qcqa/QCQALot';
import QCQAPageTemplate from 'templates/QCQAPageTemplate';

const notices = [
  '화장품 제조업자 명칭은 화장품 제조업자 사업자등록증 또는 필증과 동일하게 입력해 주세요.',
  '인증 활용 서류는 영문 자료와 함께 등록해 두시면 해외인증 서비스 진행 시 간편하게 사용할 수 있습니다.',
  <MistakeNoticeItem />,
];

const QCQALotPage = () => {
  const location = useLocation();
  const pageState = location.state as { qcqaProductId: number } | undefined;
  const params = useParams<{
    qcqaProductLotnoId?: string;
    qcqaProductId?: string;
  }>();
  const updateMode = !!params.qcqaProductLotnoId;

  return (
    <QCQAPageTemplate title={updateMode ? '제조번호 관리' : '제조번호 등록'} notices={notices}>
      <QCQALot pageState={pageState} params={params} updateMode={updateMode} />
    </QCQAPageTemplate>
  );
};

export default QCQALotPage;

import { message } from 'antd';
import ingredientClient from 'lib/api/ingredientClient';
import { useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { apiUrls, inciApiUrls, useBasicMutation, useBasicQuery } from 'service/core';
import { APIPageableResponse } from 'types/common';
import {
  CountryLawRegulation,
  GroupingLawRegulation,
  IngredientLawRegulation,
} from 'types/material/ingredient';

export enum FormulaScreeningItemStatus {
  NORMAL = 'NORMAL', // 정상
  DELETE = 'DELETE', // 삭제
}
export enum RegulationType {
  LIMIT = 'LIMIT', // 제한
  PROHIBIT = 'PROHIBIT', // 금지
}

export enum LanguageCode {
  KO = 'KO', // 국문
  EN = 'EN', // 영문
  CN = 'CN', // 중문
  JP = 'JP', // 일문
}

export interface FormulaScreeningItem {
  list: FormulaScreeningItemList[];
  detectionProhibitIngredientsOfDistributor: DetectionProhibitIngredientsOfDistributorItem[];
  isRowError?: boolean;
}

export interface FormulaScreeningItemList {
  countryLawRegulations: CountryLawRegulation[] | null;
  distributorLawRegulations: DistributorLawRegulation[] | null;
  specialRegionLawRegulations: SpecialRegionLawRegulation[] | null;
  ewg: EWGLevel | null;
  ingredient: {
    no?: number;
    noRowSpan?: number;
    name: string;
    casNo: string | null;
    wt: string | null;
    wtRowSpan?: number;
    errorCode?: 'EMPTY_INGREDIENT_NAME' | 'NO_FOUND_INGREDIENT' | null;
    bigo?: string | null;
  };
}

export interface FormulaScreeningCountryProhibitInfo {
  ingredientRegulationCountryId: number;
  countryCode: string;
  groupingRegulations: GroupingRegulation[] | null;
  ingredientRegulation: {
    regulationId: number;
    type: RegulationType;
    provisoryClause: string | null;
    notifiedIngredientName: string | null;
    categoryOfMaxContent: string | null;
    maxContent: number | null;
    regulationsOfLanguage: {
      languageCode: LanguageCode | null;
      limitedMatter: string | null;
    }[];
  };
}

export interface GroupingRegulation {
  regulationId: number;
  type: RegulationType;
  provisoryClause: string | null;
  notifiedIngredientName: string | null;
  categoryOfMaxContent: string | null;
  maxContent: number | null;
  regulationsOfLanguage: {
    languageCode: LanguageCode | null;
    limitedMatter: string | null;
  }[];
}

export interface DistributorLawRegulation {
  ingredientId: number;
  distributorDto: Distributor;
  ingredientLawRegulations: IngredientLawRegulation[];
  groupingLawRegulations: GroupingLawRegulation[];
}

export interface Distributor {
  distributorId: number;
  distributorName: string;
  logoUrl: string;
  logoFilename: string;
  url: string;
}

export interface SpecialRegionLawRegulation {
  ingredientId: number;
  specialRegion: {
    logoUrl: string;
    specialRegionId: number;
    specialRegionName: string;
    url: string | null;
  };
  ingredientLawRegulations: IngredientLawRegulation[];
  groupingLawRegulations: GroupingLawRegulation[];
}

export interface EWGLevel {
  ingredientEwgId: number;
  scoreMin: number;
  scoreMax: number;
}

export interface DetectionProhibitIngredientsOfDistributorItem {
  distributor: {
    distributorId: number;
    distributorName: string | null;
    status: FormulaScreeningItemStatus;
    logoUrl: string | null;
  };
  ingredientDetectionRegulation: IngredientDetectionRegulationItem[];
}

export interface IngredientDetectionRegulationItem {
  distributorId: number;
  ingredientDetectionRegulationId: number;
  chemicalGroupName: string | null;
  type: RegulationType;
  maxContent: string | null;
  categoryOfMaxContent: string | null;
  regulationLanguages: {
    limitedMatter: string | null;
    languageCode: LanguageCode | null;
  }[];
}

export interface FormulaScreeningItemDistributor {
  distributorId: number;
  distributorName: string;
  logoUrl: string;
  url: string;
}

export interface IngredientItem {
  name: string;
  wt?: string;
  casNo?: string;
}

export interface FormulaScreeningJson {
  distributorLawIds?: number[];
  countryLaws?: CountryLaw[];
  specialRegionLawIds?: number[];
  ingredients: IngredientItem[];
}

export interface CountryLaw {
  countryId: number;
  lawId: number;
}

export interface RegulationCountry {
  countryId: number;
  code: string;
  nameKo: string;
  nameEn: string;
  logoUrl: string;
}

export interface RegulationDistributor {
  distributorId: number;
  distributorName: string;
  logoUrl: string;
  url: string;
  logoFilename: string;
}
export interface RegulationSpecialRegion {
  specialRegionId: number;
  specialRegionName: string;
  logoUrl: string;
  url: string;
  countryId: number;
}

export interface Law {
  lawId: number;
  name?: string;
  version?: string;
  establishmentDate?: string;
  announcementDate?: string;
  implementationDate?: string;
  latestRevisionDate?: string;
  latestRevisionImplementationDate?: string;
  repealDate?: string;
  referenceInfos?: {
    referenceInfoId: number;
    name: string;
    url: string;
  }[];
}

export interface Regulation {
  countryLaws: {
    confederationLawId: number;
    country: RegulationCountry;
    countryLawId: number;
    law: Law;
  }[];
  distributorLaws: {
    distributor: RegulationDistributor;
    distributorLawId: number;
    law: Law;
  }[];
  specialRegionLaws: {
    specialRegion: RegulationSpecialRegion;
    specialRegionLawId: number;
    law: Law;
  }[];
}

const calculateFormulaScreeningRowSpan = (
  formulaScreeningResult: FormulaScreeningItemList[],
  isJson = false,
) => {
  const targetKeys: ['no', 'wt'] = ['no', 'wt'];
  let rowSpanAcc = 1;

  targetKeys.forEach((targetKey) => {
    for (let i = formulaScreeningResult.length - 1; i >= 0; i--) {
      if (!formulaScreeningResult[i].ingredient[targetKey] && !isJson) {
        rowSpanAcc++;
      } else {
        switch (targetKey) {
          case 'no': {
            formulaScreeningResult[i].ingredient.noRowSpan = rowSpanAcc;
            break;
          }
          case 'wt': {
            formulaScreeningResult[i].ingredient.wtRowSpan = rowSpanAcc;
            break;
          }
        }
        rowSpanAcc = 1;
      }
    }
  });
};

export const useFormulaScreeningIngredient = ({ name }: { name: string }) => {
  // 성분명_검색sdc03
  const {
    data,
    isLoading: getIngredientsForScreeningLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [inciApiUrls.formulaScreeningSearchInciNames, name],
    (params) =>
      ingredientClient.post<APIPageableResponse<{ name: string; ingredientId: number }[]>>(
        `${inciApiUrls.formulaScreeningSearchInciNames}?page=${params.pageParam || 1}&size=30`,
        {
          name,
        },
      ),
    {
      enabled: !!name,
      staleTime: Number.MAX_VALUE,
      getNextPageParam: (lastPage, pages) => {
        return lastPage.data.result.totalElements >= pages.length * 10 + 1
          ? pages.length + 1
          : undefined;
      },
    },
  );

  const ingredientsForScreening = useMemo(
    () =>
      data?.pages
        ?.map((page) => page)
        ?.reduce(
          (prevRawMaterials, response) => prevRawMaterials.concat(response.data.result.content),
          [] as { name: string; ingredientId: number }[],
        ) || [],
    [data],
  );

  return {
    ingredientsForScreening,
    getIngredientsForScreeningLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  };
};

export const useFormulaScreening = ({ enabled }: { enabled: boolean }) => {
  const [isUploaded, setIsUploaded] = useState(false);

  const [formulaScreeningResult, setFormulaScreeningResult] = useState<FormulaScreeningItem | null>(
    null,
  );

  const { data: formulaScreeningDistributors = [], isLoading: getDistributorsLoading } =
    useBasicQuery<FormulaScreeningItemDistributor[]>({
      isInciApi: true,
      apiUrl: apiUrls.formulaScreeningDistributors,
      option: {
        staleTime: Number.MAX_VALUE,
        enabled,
      },
    });

  const { data: formulaScreeningSpecialRegions = [], isLoading: getSpecialRegionsLoading } =
    useBasicQuery<
      {
        specialRegionId: number;
        specialRegionName: string;
        logoUrl: string;
        url: string;
      }[]
    >({
      isInciApi: true,
      apiUrl: apiUrls.formulaScreeningSpecialRegions,
      option: {
        staleTime: Number.MAX_VALUE,
        enabled,
      },
    });

  //엑셀로_성분_스크리닝_로그인_필요_fsc004
  const { mutate: uploadFormulaScreening, isLoading: uploadLoading } = useBasicMutation<
    FormData,
    FormulaScreeningItem
  >({
    isInciApi: true,
    apiUrl: inciApiUrls.formulaScreening,
    option: {
      onSuccess: (res) => {
        const { list } = res.data.result;
        calculateFormulaScreeningRowSpan(list);

        // HINT: findLastIndex 기능 빌드에러로 인한 직접 구현
        let validIndex = -1;

        for (let i = list.length - 1; i >= 0; i--) {
          if (list[i].ingredient.errorCode !== 'EMPTY_INGREDIENT_NAME') {
            validIndex = i;
            break;
          }
        }

        if (validIndex !== -1) {
          res.data.result.list = list.slice(0, validIndex + 1);
        }

        setFormulaScreeningResult(res.data.result);
        if (list?.length !== 0) {
          message.success('정상적으로 업로드되었습니다.');
          setIsUploaded(true);
        } else {
          message.warning('업로드한 파일에 오류가 있습니다.');
        }
      },
      onError: () => {
        setFormulaScreeningResult(null);
      },
    },
  });

  // json으로_성분_스크리닝_로그인_필요_fsc005
  const { mutate: uploadFormulaScreeningJson, isLoading: uploadFormulaScreeningJsonLoading } =
    useBasicMutation<FormulaScreeningJson, FormulaScreeningItem>({
      apiUrl: inciApiUrls.formulaScreening,
      isInciApi: true,
      option: {
        onSuccess: (res) => {
          const { list } = res.data.result;

          calculateFormulaScreeningRowSpan(list, true);
          setFormulaScreeningResult(res.data.result);
          if (list?.length !== 0) {
            setIsUploaded(true);
          } else {
            message.warning('업로드한 파일에 오류가 있습니다.');
          }
        },
        onError: (err) => {
          setFormulaScreeningResult(null);
        },
      },
    });

  return {
    setFormulaScreeningResult,
    formulaScreeningDistributors,
    formulaScreeningSpecialRegions,
    isLoading: getDistributorsLoading || getSpecialRegionsLoading,
    uploadFormulaScreening,
    uploadLoading,
    formulaScreeningResult,
    isUploaded,
    setIsUploaded,
    uploadFormulaScreeningJson,
    uploadFormulaScreeningJsonLoading,
  };
};

//규제명 전체 조회(SRC000)
export const useAllRegulations = ({ isAdmin, enabled }: { isAdmin: boolean; enabled: boolean }) => {
  const { data: allRegulations, isLoading: allRegulationsLoading } = useBasicQuery<Regulation>({
    isInciApi: true,
    apiUrl: isAdmin ? inciApiUrls.adminRegulationScreening : inciApiUrls.regulationScreening,
    option: {
      staleTime: Number.MAX_VALUE,
      enabled,
    },
  });

  return {
    allRegulations,
    allRegulationsLoading,
  };
};

import { Button, Form, Input, Modal, Upload, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RcFile } from 'antd/lib/upload';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import FullLoading from 'components/FullLoading';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { MinusIcon, Typography } from 'components/system';
import { CorrectButton, Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { useModal } from 'hook/useModal';
import { getChangedValues } from 'lib/common';
import { messages } from 'lib/consts';
import { urlToFile } from 'lib/file';
import { focusToInvalidatedField } from 'lib/form';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { getTextRule, requireRule } from 'lib/validate';
import {
  useNMPABasicInfoStatus,
  useNMPABasicOld,
  useNMPACorrectWithNoUpdate,
  useNMPAEtcData,
  useNMPAOverwriteHistory,
} from 'service/material/nmpa/nmpa';
import {
  DocumentCode,
  MaterialNmpaBasicInfoStatus,
  NMPADocumentCode,
} from 'types/material/nmpa/nmpa';

const Container = styled.div``;

const TableContainer = styled.div`
  position: relative;

  .ant-table {
    border-top: 2px solid ${palette.PRIMARY50};
    border-radius: 0;
    overflow: visible;

    th.ant-table-cell {
      background-color: #f9f9f9 !important;
    }

    td.ant-table-cell {
      vertical-align: super;
      padding-right: 16px;
    }

    .ant-table-cell {
      border-color: #d8d8d8;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input,
    input.ant-input,
    .ant-input-affix-wrapper,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-number-input,
    .ant-picker {
    }
  }

  .ant-checkbox + span {
    padding-left: 4px;
  }
`;

interface NMPAESDForm {
  etcDatas: {
    key: number;
    dataName: string;
    dataFile: any;
    dataFileUrl?: string;
  }[];
}

const NMPAESD = ({ materialId }: { materialId: number }) => {
  // HINT: 최초 불러온 데이터를 저장하고 있고, 수정할 내역을 검사할 때 사용됩니다.
  const [initFormData, setInitFormData] = useState<NMPAESDForm>();
  const [form] = Form.useForm<NMPAESDForm>();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);

  const { openNMPACorrectChatModal, openAlertModal } = useModal();
  const { nmpaBasicInfo } = useNMPABasicOld(materialId);
  const navigate = useNavigate();
  const materialNmpaBasicInfoId = nmpaBasicInfo?.materialNmpaBasicInfoId;

  const { nmpaCorrectRequestWithNoUpdate, nmpaCorrectRequestWithNoUpdateLoading } =
    useNMPACorrectWithNoUpdate({
      materialNmpaBasicInfoId,
      documentCode: NMPADocumentCode.ESD,
    });
  const { etcData, isReadNMPAEtcDataLoading, updateNMPAEtcData, isUpdateNMPAEtcDataLoading } =
    useNMPAEtcData(materialId);
  const { basicInfoStatus, isLoading: isBasicInfoStatusLoading } = useNMPABasicInfoStatus({
    materialId,
    documentCode: DocumentCode.ESD,
    enabled: typeof materialId !== 'undefined',
  });
  const { nmpaOverwriteHistory, nmpaOverwriteHistoryLoading } = useNMPAOverwriteHistory({
    nmpaBasicInfoId: materialId,
    documentCode: NMPADocumentCode.ESD,
  });
  const readOnly =
    (nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.ONGOING &&
      !basicInfoStatus?.isNmpaDocModStatus) ||
    nmpaBasicInfo?.status === MaterialNmpaBasicInfoStatus.FINISH;
  const updateMode = etcData.length > 0;
  const ctaName = useMemo(() => {
    if (basicInfoStatus?.isNmpaDocModStatus) return '보완 완료';
    if (!updateMode) return '등록';
    return '수정';
  }, [updateMode, basicInfoStatus]);
  const loading = isBasicInfoStatusLoading || isReadNMPAEtcDataLoading;

  const handleFileUpload = (name: number) => (file: RcFile) => {
    const newEvaluationDatas = { ...form.getFieldValue('etcDatas') };

    // HINT: 형식을 바꾸기가 어려워 antd file upload 형식으로 맞춤.
    newEvaluationDatas[name]['dataFile'] = {
      file,
    };

    form.setFieldsValue(newEvaluationDatas);

    return false;
  };

  const handleNoUpdateSubmit = () => {
    if (materialNmpaBasicInfoId) {
      openAlertModal({
        content: `
'보완할 내용 없음' 버튼을 클릭하시면, 관리자의 보완 요청이 있기 전까지는 수정이 불가합니다.

보완할 내용 없이 처리 하시겠습니까?
`,
        onOk: () => {
          nmpaCorrectRequestWithNoUpdate(
            {},
            {
              onSuccess: () => {
                message.success('보완 내용 없이 완료 처리되었습니다.');
                navigate(-1);
              },
            },
          );
        },
      });
    }
  };

  const handleSubmit = () => {
    const formValues = form.getFieldsValue();
    const { etcDatas } = formValues;
    const newEtcDatas: any[] = [];

    const isNoData = etcDatas[0].dataFile === undefined && etcDatas[0].dataName === undefined;

    if (initFormData !== undefined && isEqual(formValues, initFormData as any)) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    if (initFormData) {
      nmpaOverwriteHistory(getChangedValues({ obj1: initFormData, obj2: formValues }));
    }

    // HINT: antd file 형식을 전송 형식에 맞게 수정. { file, fileList } => file
    const formatParams = () => {
      for (const key of Object.keys(etcDatas)) {
        newEtcDatas.push({
          ...etcDatas[Number(key)],
          dataFile: etcDatas[Number(key)].dataFile?.file,
        });
      }
    };

    formatParams();

    updateNMPAEtcData(
      {
        materialId,
        etcDatas: isNoData ? [] : newEtcDatas,
      },
      {
        onSuccess: () => {
          message.success(`${ctaName}되었습니다.`);
          navigate(`${path.material.nmpa.material.root}/${materialId}`, {
            replace: true,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (!etcData?.length) return;

    const apiData2FormData = async () => {
      const etcDatas: any[] = [];

      let key = 0;
      for (const data of etcData) {
        const file = await urlToFile(data.dataFileUrl, data.dataFilename);
        // ant file 형식 지키기 위함
        (file as any).originFileObj = file;

        etcDatas.push({
          key,
          dataName: data.dataName,
          dataFile: {
            file,
          },
          dataFileUrl: data.dataFileUrl,
        });

        key++;
      }

      form.setFieldsValue({ etcDatas });
      setTimeout(() => setInitFormData(form.getFieldsValue()), 0);
    };

    apiData2FormData();
  }, [etcData]);

  const columns: ColumnsType<{
    name: number;
    remove: (index: number) => void;
  }> = [
    {
      title: (
        <Typography.Text gutter={{ left: 16 }} asterisk>
          자료 명칭
        </Typography.Text>
      ),
      width: '50%',
      render: (_, { name, remove }, index) => (
        <>
          {!readOnly && index > 0 && (
            <MinusIcon
              style={{ position: 'absolute', left: -40, top: 20 }}
              onClick={() => remove(index)}
            />
          )}
          <Form.Item
            name={[name, 'dataName']}
            rules={[
              !updateMode ? requireRule : { required: false },
              getTextRule({
                end: 100,
                message: '최대 100자',
              }),
            ]}
          >
            <Input disabled={readOnly} placeholder="자료 명칭 및 간략한 설명" />
          </Form.Item>
        </>
      ),
    },
    {
      title: (
        <Typography.Text align="left" asterisk>
          PDF 파일 (25MB이하)
        </Typography.Text>
      ),
      width: '50%',
      align: 'center',
      render: (_, { name }) => (
        <Flex align="center" columnGap={16}>
          <Form.Item
            valuePropName="dataFile"
            name={[name, 'dataFile']}
            rules={[!updateMode ? requireRule : { required: false }]}
          >
            <Upload
              accept="application/pdf"
              itemRender={() => null}
              beforeUpload={handleFileUpload(name)}
            >
              <Button size="small" disabled={readOnly}>
                파일 선택
              </Button>
            </Upload>
          </Form.Item>
          <Typography.Text type="BODY_2" color="GRAY90">
            {form.getFieldValue(['etcDatas', name, 'dataFile'])?.file?.name}
          </Typography.Text>
        </Flex>
      ),
    },
  ];

  const handleReset = () => {
    Modal.confirm({
      content: (
        <Typography.Text type="BODY_2" align="center">
          입력된 정보를 모두 삭제하고 <br />
          초기화하시겠습니까?
        </Typography.Text>
      ),
      icon: null,
      onOk: () => {
        form.resetFields();
      },
      closable: true,
    });
  };

  return (
    <Container>
      {loading && <FullLoading />}
      {basicInfoStatus?.isNmpaDocModStatus && (
        <Flex justify="end" style={{ marginBottom: 16 }}>
          <CorrectButton
            onClick={() => {
              openNMPACorrectChatModal({
                title: nmpaBasicInfo?.tradeName,
                nmpaBasicInfoId: materialNmpaBasicInfoId,
                documentCode: NMPADocumentCode.ESD,
                isFirstOpenChatModal,
              });
              setIsFirstOpenChatModal(true);
            }}
            disableAnim={false}
          />
        </Flex>
      )}
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
      >
        <TableContainer>
          <Form.List name="etcDatas" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={fields.map(({ name }) => ({ name, remove }))}
                  rowKey="name"
                />
                {!readOnly && (
                  <Button
                    type="dashed"
                    icon={<Icon name="plus" size={14} color="PRIMARY50" />}
                    block
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 24,
                      maxWidth: 520,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    onClick={() => {
                      if (fields.length >= 10) {
                        message.warning('최대 10개까지 업로드 가능합니다.');
                        return;
                      }
                      add({});
                    }}
                  >
                    기타 제출 자료 추가
                  </Button>
                )}
              </>
            )}
          </Form.List>
        </TableContainer>
        <FooterBox>
          {!readOnly && updateMode && <Button onClick={handleReset}>입력 초기화</Button>}
          <ReadOnlyBackButton readOnly={readOnly}>
            <Flex>
              {basicInfoStatus?.isNmpaDocModStatus && (
                <Button
                  loading={nmpaCorrectRequestWithNoUpdateLoading}
                  onClick={handleNoUpdateSubmit}
                >
                  보완할 내용 없음
                </Button>
              )}
              <Button
                type="primary"
                loading={isUpdateNMPAEtcDataLoading || nmpaOverwriteHistoryLoading}
                onClick={form.submit}
              >
                {ctaName}
              </Button>
            </Flex>
          </ReadOnlyBackButton>
        </FooterBox>
      </Form>
    </Container>
  );
};

export default NMPAESD;

import { ComponentProps, useCallback } from 'react';
import SelectRange from './SelectRange';

const SelectYear = ({
  start = 2010,
  end = 2030,
  placeholder = '년도',
  ...props
}: ComponentProps<typeof SelectRange>) => {
  const customLabel = useCallback((value: number) => value + '년', []);
  return (
    <SelectRange
      width={120}
      start={start}
      end={end}
      placeholder={placeholder}
      customLabel={customLabel}
      {...props}
    />
  );
};

export default SelectYear;

import { Col, Row, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CountryProhibitInfosModal from 'components/modal/formula/CountryProhibitInfosModal';
import { Typography } from 'components/system';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import { countryCodeMap } from 'lib/consts';
import palette from 'lib/styles/palette';
import { useState } from 'react';
import { RegulationType } from 'service/brand/formulaScreening/formulaScreening';
import { useCountryProhibitsUpdatedDate, useEwgUpdatedDate } from 'service/material/ingredient';
import styled from 'styled-components';
import { CountryProhibitInfo } from 'types/formula';
import { CompositionInformation } from 'types/material/rawMaterialDetail';

const TableWrap = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #f9f9f9 !important;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: ${palette.SLATE_GRAY70};
  }

  .ant-space-item {
    margin-right: 0 !important;
  }
`;

const ProhibitLabelWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
const CompositionInformations = ({
  compositionInformations,
}: {
  compositionInformations: CompositionInformation[];
}) => {
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();
  const ewgUpdatedDate = useEwgUpdatedDate();
  const [selectedCountryName, setSelectedCountryName] = useState<string>('');
  const [selectedCountryInfos, setSelectedCountryInfos] = useState<CountryProhibitInfo[]>([]);
  const handleCloseCountryProhibitModal = () => {
    setSelectedCountryInfos([]);
    setSelectedCountryName('');
  };
  const handleClickProhibit = (countryProhibits: CountryProhibitInfo[], countryNameKo?: string) => {
    setSelectedCountryInfos(countryProhibits);
    if (countryNameKo) {
      setSelectedCountryName(countryNameKo);
    }
  };
  const columns: ColumnsType<CompositionInformation> = [
    {
      title: '국문 성분명',
      dataIndex: 'inciNameKo',
    },
    {
      title: 'INCI Name',
      dataIndex: 'inciNameEn',
    },
    {
      title: 'CAS No.',
      align: 'center',
      dataIndex: 'casNo',
    },
    {
      title: 'EC No.',
      align: 'center',
      dataIndex: 'euCasNo',
    },
    {
      title: 'EWG Skin deep Score',
      align: 'center',
      render: (_, { ewgScore }) => <img src={ewgScore} alt="" style={{ width: 20 }} />,
    },
    {
      title: '국가별 성분 규제',
      dataIndex: 'countryNameKo',
      align: 'center',
      width: 160,
      render: (_, { countryProhibits }, index) => {
        const prohibitTypes = Array.from(
          countryProhibits.reduce<Set<string>>((acc, { type }) => {
            acc.add(type.toUpperCase() === RegulationType.LIMIT ? '제한' : '금지');
            return acc;
          }, new Set()),
        );

        return (
          prohibitTypes.length > 0 && (
            <Space size="middle" style={{ width: '100%', justifyContent: 'center' }}>
              {prohibitTypes.length > 0 && (
                <ProhibitLabelWrap>
                  {prohibitTypes.map((prohibitType) => (
                    <Tags.Chip
                      key={prohibitType}
                      type="line"
                      color={prohibitType === '제한' ? 'MESSAGE_CHECK' : 'MESSAGE_ERROR'}
                      style={{
                        display: 'inline-flex',
                      }}
                      onClick={() => handleClickProhibit(countryProhibits)}
                    >
                      {prohibitType}
                    </Tags.Chip>
                  ))}
                </ProhibitLabelWrap>
              )}
              <Row gutter={[2, 2]}>
                {countryProhibits.map(({ countryNameKo }) => (
                  <Col key={`${index}_${countryNameKo}`}>
                    <img
                      src={`https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/flag/${countryCodeMap[countryNameKo]}.png`}
                      alt=""
                      style={{
                        width: 20,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClickProhibit(countryProhibits, countryNameKo)}
                    />
                  </Col>
                ))}
              </Row>
            </Space>
          )
        );
      },
    },
  ];

  return (
    <>
      <Row align="middle" gutter={16} style={{ marginTop: 36, marginBottom: 8 }}>
        <Col>
          <Typography.Text type="TITLE_1">Composition Information</Typography.Text>
        </Col>
        <Col flex="auto">
          <Typography.Text color="SLATE_GRAY70" medium>
            배합 성분 정보
          </Typography.Text>
        </Col>
        <Col style={{ paddingTop: 14 }}>
          <Tags.Mark color="lightGray" style={{ display: 'inline-block' }}>
            DB 업데이트
          </Tags.Mark>
          <Typography.Text type="SMALL" medium color="SLATE_GRAY70" inline gutter={{ left: 8 }}>
            [EWG Skin deep]
          </Typography.Text>
          <Typography.Text type="SMALL" medium color="SLATE_GRAY70" inline gutter={{ left: 4 }}>
            {ewgUpdatedDate}
          </Typography.Text>
          <Typography.Text type="SMALL" medium color="SLATE_GRAY70" inline gutter={{ left: 8 }}>
            [국가별 성분 규제]
          </Typography.Text>
          <Typography.Text type="SMALL" medium color="SLATE_GRAY70" inline gutter={{ left: 4 }}>
            {prohibitDataUpdatedDate}
          </Typography.Text>
        </Col>
      </Row>
      <TableWrap>
        <Table
          style={{
            borderTop: `2px solid ${palette.PRIMARY50}`,
          }}
          columns={columns}
          dataSource={compositionInformations}
          rowKey="materialCompositionInformationId"
          pagination={false}
        />
      </TableWrap>
      <CountryProhibitInfosModal
        defaultCountryName={selectedCountryName}
        countryInfos={selectedCountryInfos}
        onClose={handleCloseCountryProhibitModal}
      />
    </>
  );
};

export default CompositionInformations;

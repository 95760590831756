import styled from 'styled-components';
import { Descriptions as Des, DescriptionsProps } from 'antd';

import palette from 'lib/styles/palette';

interface Props extends DescriptionsProps {
  children: React.ReactNode;
}

const StyledDescription = styled(Des)`
  margin-top: 16px;

  .ant-descriptions-item-label {
    padding: 10px 16px;
  }
  .ant-descriptions-item-content {
    padding: 10px 16px;
  }
  .ant-descriptions-view {
    border: 3px solid;
    border-color: ${palette.PRIMARY50} transparent transparent transparent;
  }
`;

const Description = ({ children }: Props) => {
  return <StyledDescription>{children}</StyledDescription>;
};

export default Description;

import { useRoutes } from 'react-router-dom';

import Error404Page from 'pages/error/Error404Page';
import AllTermsPage from 'pages/terms/AllTermsPage';

const TermsRouter = () => {
  const element = useRoutes([
    {
      path: '/',
      element: <AllTermsPage />,
    },
    {
      path: '/service',
      element: <AllTermsPage />,
    },
    {
      path: '/privacy-process',
      element: <AllTermsPage />,
    },
    {
      path: '/privacy-service',
      element: <AllTermsPage />,
    },
    {
      path: '/privacy-third-party',
      element: <AllTermsPage />,
    },
    {
      path: '/ci-bi-service',
      element: <AllTermsPage />,
    },
    {
      path: '*',
      element: <Error404Page />,
    },
  ]);
  return element;
};

export default TermsRouter;

import styled from 'styled-components';
import { Form, Row, Col, Checkbox, Input, Select, Button, Spin, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UpdateLog from 'components/product/UpdateLog';
import { Tip, Typography } from 'components/system';
import { exceptKoreanRule, generateFormRules, requireRule } from 'lib/validate';
import FooterBox from 'components/FooterBox';
import { useProductBasic } from 'service/manufacturer/productBasic';
import { netWeightUnitOptions } from 'lib/selectOption';
import { normalizeBlank, parseWeightUnit } from 'lib/form';
import { scrollToFirstErrorOption } from 'lib/consts';
import { useGetProductSample, useUsableProductSamples } from 'service/manufacturer/productSample';
import { CountryTarget } from 'types/manufacturer/productSample';
import { ProductBasicForm } from 'types/manufacturer/product';
import { useGetProduct } from 'service/manufacturer/product';
import { DocumentCode } from 'types/product';
import BackgroundRadio from 'components/ui/BackgroundRadio/BackgroundRadio';
import palette from 'lib/styles/palette';
import { Flex } from 'components/ui';

const ProductBasicBlock = styled.div`
  max-width: 688px;
  margin: 0 auto;

  .ant-form-item-label {
    pointer-events: none;
  }

  .ant-radio-wrapper {
    margin-inline-end: 0;
  }
`;

const ProductBasic = ({ productId }: { productId?: number }) => {
  const navigate = useNavigate();
  const updateMode = typeof productId !== 'undefined';
  const { form, product, getProductLoading, submitProduct, factories, fetchLoading } =
    useProductBasic(productId);
  const checkNetWeightIsDirectedInput = () =>
    !netWeightUnitOptions.find(({ value }) => value === product?.netWeightUnit);
  const [netWeightDirect, setNetWeightDirect] = useState<boolean>(checkNetWeightIsDirectedInput());

  const { productSamples, isLoading: getUsableProductSamplesLoading } = useUsableProductSamples();
  const { getProductSample, isLoading: getProductSampleLoading } = useGetProductSample();
  const { getProduct, isLoading: getProductForDuplicateLoading } = useGetProduct();
  const handleSubmitProduct = async (productForm: ProductBasicForm) => {
    const {
      data: { result: duplicatedProduct },
    } = await getProduct({
      labNo: productForm.labNo,
      netWeight: productForm.netWeight,
      netWeightUnit:
        productForm.netWeightUnit !== 'direct'
          ? productForm.netWeightUnit
          : productForm.netWeightUnit,
    });
    if (duplicatedProduct && (!product || product.productId !== duplicatedProduct.productId)) {
      if (!updateMode && duplicatedProduct.manufacturerStatus === 'PRD-RDY') {
        Modal.confirm({
          icon: null,
          width: 400,
          closable: true,
          content: (
            <Typography.Text type="BODY_2" align="center">
              이미 등록 중인 동일한 샘플 랩넘버와 용량이 있습니다.
              <br />
              해당 제품을 이어서 등록하시겠습니까?
            </Typography.Text>
          ),
          okText: '등록',
          cancelText: '취소',
          onOk: () => {
            navigate(`/manufacturer/product/${duplicatedProduct.productId}`, {
              replace: true,
            });
          },
        });
      } else {
        Modal.info({
          icon: null,
          width: 400,
          closable: true,
          content: (
            <Typography.Text type="BODY_2" align="center">
              이미 사용된 샘플 랩넘버와 용량이 동일하여
              <br />
              {!updateMode ? '등록' : '수정'}할 수 없으니 확인 후 다시 시도해 주세요.
            </Typography.Text>
          ),
          okText: '확인',
          cancelText: '취소',
        });
      }
      return;
    }
    if (updateMode && productForm.isUsedProductSample !== product!.isUsedProductSample) {
      Modal.confirm({
        icon: null,
        width: 400,
        content: (
          <Typography.Text type="BODY_2">
            {productForm.isUsedProductSample ? (
              <>
                샘플 연결하시면 아래 입력 항목이 해당하는 샘플의 내용
                <br />
                으로 입력됩니다. ‘확정 완료 샘플 연결’으로 수정하시겠습니까?
              </>
            ) : (
              <>
                샘플 연결을 해제하시면 아래에 자동 입력된 항목의
                <br /> 내용이 삭제됩니다. ‘샘플 없이 등록’ 으로 수정하시겠습니까?
              </>
            )}
            <br />
            <br />
            <Typography.Text medium>
              Product Formula
              <br />
              Product Formula Breakdown
              {productForm.isForChina && (
                <>
                  <br />
                  Manufacturing Process (China)
                </>
              )}
            </Typography.Text>
          </Typography.Text>
        ),
        okText: '수정',
        cancelText: '취소',
        onOk: () => {
          submitProduct(productForm);
        },
      });
      return;
    }
    if (
      updateMode &&
      product!.isUsedProductSample &&
      productForm.productSampleId !== product!.productSampleId
    ) {
      Modal.confirm({
        icon: null,
        width: 400,
        content: (
          <Typography.Text type="BODY_2">
            선택한 샘플을 수정하시면 아래에 자동 입력된 항목의 내용이 변경됩니다. 샘플을
            수정하시겠습니까?
            <br />
            <br />
            <Typography.Text medium>
              Product Formula
              <br />
              Product Formula Breakdown
              {productForm.isForChina && (
                <>
                  <br />
                  Manufacturing Process (China)
                </>
              )}
            </Typography.Text>
          </Typography.Text>
        ),
        okText: '수정',
        cancelText: '취소',
        onOk: () => {
          submitProduct(productForm);
        },
      });
      return;
    }
    submitProduct(productForm);
  };
  return (
    <>
      {updateMode && <UpdateLog productId={productId!} documentCode={DocumentCode.BASIC} />}
      <ProductBasicBlock>
        <Spin spinning={getProductSampleLoading || getProductLoading}>
          <Form
            form={form}
            colon={false}
            layout="vertical"
            onFinish={handleSubmitProduct}
            scrollToFirstError={scrollToFirstErrorOption}
          >
            <Form.Item
              label="제품 등록 방법"
              required
              name="isUsedProductSample"
              rules={[requireRule]}
            >
              <BackgroundRadio
                gap={80}
                itemWidth={160}
                options={[
                  { value: false, title: '샘플 없이 등록' },
                  { value: true, title: '확정 완료 샘플 연결' },
                ]}
              />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.isUsedProductSample !== next.isUsedProductSample}
            >
              {({ getFieldValue, setFieldsValue }) =>
                getFieldValue('isUsedProductSample') && (
                  <Form.Item
                    label="샘플 선택하기"
                    name="productSampleId"
                    rules={[requireRule]}
                    preserve={false}
                  >
                    <Select
                      placeholder="샘플명, 랩넘버 검색 혹은 선택"
                      loading={getUsableProductSamplesLoading}
                      options={productSamples.map(
                        ({ productSampleId, productSampleName, labNo }) => ({
                          label: `${productSampleName} - ${labNo}`,
                          value: productSampleId,
                        }),
                      )}
                      showSearch
                      filterOption={(searchKeyword, option) =>
                        (option?.label as string)
                          .toLowerCase()
                          .includes(searchKeyword.toLowerCase())
                      }
                      onSelect={(productSampleId: number) => {
                        getProductSample(productSampleId, {
                          onSuccess: (res) => {
                            const { labNo, isSelfDevelop, brandCompany, countryTargets } =
                              res.data.result;
                            const brandCompanyNameKo = isSelfDevelop
                              ? '자체 개발용'
                              : brandCompany!.companyNameKo;
                            setFieldsValue({
                              brandCompanyNameKo,
                              labNo,
                              isForEurope: countryTargets.includes(CountryTarget.EUROPE),
                              isForChina: countryTargets.includes(CountryTarget.CHINA),
                              isForUs: countryTargets.includes(CountryTarget.USA),
                            });
                          },
                        });
                      }}
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
            <Typography.Text type="TITLE_2" style={{ marginBottom: 8 }}>
              국가
            </Typography.Text>
            <Form.Item noStyle>
              <Row>
                <Col flex="auto">
                  <Checkbox defaultChecked disabled>
                    <Typography.Text type="BODY_2" inline style={{ color: palette.GRAY90 }}>
                      글로벌 (유럽연합, 중국, 미국 외)
                    </Typography.Text>
                  </Checkbox>
                </Col>
                <Col>
                  <Form.Item
                    noStyle
                    name="isForEurope"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      disabled={(product && product.manufacturerStatus === 'PRD-REG') || false}
                    >
                      유럽연합
                    </Checkbox>
                  </Form.Item>
                  <Form.Item noStyle name="isForChina" valuePropName="checked" initialValue={false}>
                    <Checkbox
                      disabled={(product && product.manufacturerStatus === 'PRD-REG') || false}
                    >
                      중국
                    </Checkbox>
                  </Form.Item>
                  <Form.Item noStyle name="isForUs" valuePropName="checked" initialValue={false}>
                    <Checkbox
                      disabled={(product && product.manufacturerStatus === 'PRD-REG') || false}
                    >
                      미국
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              required
              label="브랜드사명"
              name="brandCompanyNameKo"
              rules={generateFormRules({
                required: true,
                maxLength: 100,
              })}
              style={{ marginTop: 16 }}
            >
              <Input placeholder="브랜드사명 입력" />
            </Form.Item>
            <Flex gap={16}>
              <Form.Item
                style={{ width: '100%' }}
                label={
                  <Row align="middle" gutter={4}>
                    <Col>자체 품목명 (국문)</Col>
                    <Col>
                      <Tip trigger="click">
                        <Typography.Text type="SMALL">
                          정식 명칭이 없는 경우, 제조사 내<br />
                          자체(임시) 품목명을 입력해 주세요.
                        </Typography.Text>
                      </Tip>
                    </Col>
                  </Row>
                }
                name="manufacturerItemNameKo"
                normalize={normalizeBlank}
                rules={[
                  requireRule,
                  {
                    type: 'string',
                    max: 100,
                    message: '최대 100자 입력',
                  },
                ]}
              >
                <Input
                  onBlur={(e) => {
                    const value = e.target.value;

                    if (value.endsWith(' ')) {
                      form.setFieldsValue({
                        manufacturerItemNameKo: value.trimEnd(),
                      });
                    }
                  }}
                  placeholder="영문 브랜드명 입력"
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                label="품번 (자체관리용)"
                name="manufacturerItemNo"
              >
                <Input placeholder="품번 입력" />
              </Form.Item>
            </Flex>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.isUsedProductSample !== next.isUsedProductSample}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="샘플 랩넘버"
                  name="labNo"
                  rules={[requireRule]}
                  style={{ width: '100%' }}
                >
                  <Input
                    placeholder="샘플 랩넘버 입력"
                    maxLength={100}
                    disabled={getFieldValue('isUsedProductSample')}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Flex gap={16}>
              <Form.Item
                label={
                  <Row justify="space-between" style={{ width: 326 }} align="middle">
                    <Typography.Text style={{ fontSize: 18, pointerEvents: 'none' }}>
                      용량
                    </Typography.Text>
                    <Checkbox
                      style={{ pointerEvents: 'auto' }}
                      defaultChecked={netWeightDirect}
                      onChange={(e) => {
                        setNetWeightDirect(e.target.checked);
                        form.setFieldsValue({
                          netWeightUnit: e.target.checked ? 'others' : undefined,
                        });
                      }}
                    >
                      <Typography.Text style={{ fontSize: 14 }} inline>
                        단위 직접 입력
                      </Typography.Text>
                    </Checkbox>
                  </Row>
                }
                required
                shouldUpdate={(prev, next) => prev.netWeightUnit !== next.netWeightUnit}
                style={{ marginBottom: 0, width: '100%' }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  return (
                    <Row gutter={8}>
                      <Col>
                        <Form.Item
                          name="netWeight"
                          rules={generateFormRules({
                            onlyNumber: true,
                            required: true,
                          })}
                          required
                        >
                          <Input style={{ width: '164px' }} placeholder="숫자 입력" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name="netWeightUnit" rules={[requireRule]}>
                          {netWeightDirect ? (
                            <Form.Item name="netWeightUnitDirect" rules={[requireRule]} noStyle>
                              <Input
                                autoFocus
                                placeholder="단위 직접 입력"
                                style={{ width: 164 }}
                                onBlur={(e) => {
                                  const netWeightUnit = parseWeightUnit(e.target.value);
                                  if (netWeightUnit) {
                                    setFieldsValue({
                                      netWeightUnit,
                                      netWeightUnitDirect: null,
                                    });
                                  }
                                }}
                              />
                            </Form.Item>
                          ) : (
                            <Select
                              style={{ width: '164px' }}
                              placeholder="단위 선택"
                              onSelect={() => setFieldsValue({ netWeightUnitDirect: null })}
                            >
                              {netWeightUnitOptions.map((option) => (
                                <Select.Option key={option.value} value={option.value}>
                                  {option.text}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }}
              </Form.Item>
              <Form.Item
                required
                rules={generateFormRules({
                  required: true,
                  minLength: 5,
                  maxLength: 10,
                  onlyNumber: true,
                })}
                label="화장품제조업자 우편번호"
                style={{ width: '100%' }}
                name="manufacturerZipCode"
              >
                <Input placeholder="우편번호 입력" />
              </Form.Item>
            </Flex>
            <Form.Item
              label="제조공장 주소 (국문)"
              name="companyFactoryId"
              required
              rules={[requireRule]}
            >
              <Select
                placeholder="국문 주소 선택"
                onChange={(companyFactoryId) => {
                  form.setFieldsValue({
                    addressEn: factories.find(
                      (factory) => factory.companyFactoryId === companyFactoryId,
                    )!.addressEn,
                  });
                }}
              >
                {factories.map(({ companyFactoryId, addressKo }: any) => (
                  <Select.Option key={companyFactoryId} value={companyFactoryId}>
                    {addressKo}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="제조공장 주소 (영문)"
              name="addressEn"
              required
              rules={[requireRule, exceptKoreanRule]}
            >
              <Input placeholder="영문 주소 입력" disabled />
            </Form.Item>
            <FooterBox>
              <Button
                type="primary"
                htmlType="submit"
                loading={fetchLoading || getProductForDuplicateLoading}
              >
                {!updateMode ? '등록' : '수정'}
              </Button>
            </FooterBox>
          </Form>
        </Spin>
      </ProductBasicBlock>
    </>
  );
};

export default ProductBasic;

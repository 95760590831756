import ProductSamplePageTemplate from 'templates/manufacturer/ProductSamplePageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSampleRecipeDetail from 'components/manufacturer/productSample/ProductSampleRecipeDetail';

const notices = [
  '‘처방’ 페이지에서 등록하신 원료를 기준으로, 완제품으로 진행되어 변경되지 않는 확정 처방에 대한 상세 정보를 업로드해 주세요.',
  '업로드해 주신 상세 정보를 기준으로  certicos 양식의 단일 성분표를 생성해 드립니다.',
  '처방 상세 등록 후 수정하시게 되는 경우, 공정도 페이지의 입력이 초기화되며 새로 입력이 필요합니다.',
  <MistakeNoticeItem />,
];

const ProductSampleRecipeDetailPage = () => {
  return (
    <ProductSamplePageTemplate
      notices={notices}
      subtitle="처방 상세"
      exampleImg="Recipe Details.png"
    >
      <ProductSampleRecipeDetail />
    </ProductSamplePageTemplate>
  );
};

export default ProductSampleRecipeDetailPage;

import { useLayoutEffect } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductStatementMicrobiologicalPurity from 'components/product/ProductStatementMicrobiologicalPurity';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '해당 서류는 제조사에서 제품을 제조하는 데 사용한 모든 원료와 혼합물에 금지 성분, 오염물이 포함되지 않았으며 미생물 기준을 준수한다는 성명서 양식입니다.',
  '하단의 파일 다운로드 버튼 클릭 시, 입력하신 제조사의 정보가 기입된 양식을 받아보실 수 있습니다.',
  '제조사 책임자의 이름, 직함을 기입 후 서명을 완료한 파일을 스캔하셔서 다시 업로드해 주세요.',
  '이미 해당 제품의 Statement of Microbiological Purity 서류를 다른 국가에서 업로드하신 경우는, 기 업로드하신 파일을 확인하실 수 있습니다.',
  <MistakeNoticeItem />,
];

const ProductStatementMicrobiologicalPurityPage = () => {
  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateStyle"]');
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <ProductPageTemplate subtitle="Statement of Microbiological Purity" notices={notices}>
      <ProductStatementMicrobiologicalPurity />
    </ProductPageTemplate>
  );
};

export default ProductStatementMicrobiologicalPurityPage;

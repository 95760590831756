import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductBasicContainer from 'containers/product/ProductBasicContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  '화장품 제조업자, 화장품 책임판매업자 명칭은 사업자등록증 또는 화장품 제조업자, 화장품 책임판매업자 필증과 동일하게 입력해 주세요.',
  '제품명(영문)은 인증 시 사용될 제품명으로 대소문자, 띄어쓰기에 주의하여 입력해 주세요.',
  <>
    인증서에 등록될 제품명에 브랜드명이 포함되길 원하시는 경우, 브랜드명이 포함된 제품명을 입력해
    주세요.
  </>,
  '화장품책임판매업자의 주소가 잘못된 경우, 마이페이지 > 회원정보 수정에서 확인하실 수 있습니다.',
  <MistakeNoticeItem />,
];
const ProductBasicPage = () => {
  const { productId } = useParams<{ productId: string }>();

  useEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateStyle"]');
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <ProductPageTemplate
      subtitle="기본정보"
      notices={notices}
      hasSkip={false}
      back={productId !== undefined}
    >
      <ProductBasicContainer />
    </ProductPageTemplate>
  );
};

export default ProductBasicPage;

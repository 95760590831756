import { useMemo } from 'react';
import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Typography } from 'components/system';
import { useEstimateHistories } from 'service/estimate';
import { useModal } from 'hook/useModal';
import { BrandEstimateHistory } from 'types/brand/estimate';
import { EstimateHistoryDomainType } from 'types/estimate';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';

export interface EstimateHistoriesModalProps {
  estimateId: number;
  type: EstimateHistoryDomainType;
  onClose?: () => void;
}

const EstimateHistoriesModal = ({ estimateId, type, onClose }: EstimateHistoriesModalProps) => {
  const { isFetching, estimateHistories } = useEstimateHistories({
    estimateId,
    type,
  });

  const { openDocumentPreviewModal } = useModal();
  const columns: ColumnsType<BrandEstimateHistory> = useMemo(
    () => [
      { title: 'No.', dataIndex: 'no', align: 'center' },
      {
        title: '견적서 번호',
        align: 'center',
        dataIndex: 'estimateCode',
      },
      {
        title: '견적 금액',
        align: 'center',
        render: (_, { estimatePrice }) => `${Math.floor(estimatePrice * 1.1).toLocaleString()}원`,
      },
      {
        title: '견적서',
        align: 'center',
        render: (_, { fileUrl }) => (
          <Icon
            name="page"
            style={{ margin: '0 auto' }}
            onClick={() => openDocumentPreviewModal({ documentUrls: fileUrl })}
          />
        ),
      },
    ],
    [],
  );

  return (
    <Modal visible={estimateId !== null} width={768} footer={null} onCancel={onClose}>
      <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
        견적서 수정 이력
      </Typography.Text>
      <Table
        loading={isFetching}
        columns={columns}
        dataSource={estimateHistories}
        rowKey={({ estimateHistoryId }) => estimateHistoryId}
        pagination={false}
      />
    </Modal>
  );
};

export default EstimateHistoriesModal;

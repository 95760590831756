import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import {
  ADD_INGREDIENT_TO_BOX,
  DELETE_INGREDIENT_TO_BOX,
  INITIALIZE_INGREDIENT_TO_BOX,
} from './actions';

import { IngredientAction, IngredientState } from './types';

const initialState: IngredientState = {
  ingredientIds: [],
};

export default createReducer<IngredientState, IngredientAction>(initialState, {
  [ADD_INGREDIENT_TO_BOX]: (state, { payload: ingredientId }) =>
    produce(state, (draft) => {
      draft.ingredientIds.push(ingredientId);
    }),
  [DELETE_INGREDIENT_TO_BOX]: (state, { payload: index }) =>
    produce(state, (draft) => {
      draft.ingredientIds.splice(index, 1);
    }),
  [INITIALIZE_INGREDIENT_TO_BOX]: (state) =>
    produce(state, (draft) => {
      draft.ingredientIds = [];
    }),
});

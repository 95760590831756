import { Badge, Divider, Menu, MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import path from 'lib/path';
import palette from 'lib/styles/palette';
import Icon from 'components/ui/Icon/Icon';
import { deviceSize } from 'lib/styles';

const TopSubMenuBlock = styled.div<{ is_inline_mode: 'true' | 'false' }>`
  flex: 0 0 178px;

  .ant-badge {
    vertical-align: middle;
  }

  .ant-menu {
    gap: 24px;
    justify-content: flex-end;

    .ant-menu-item {
      padding-inline: 0;

      &::after {
        inset-inline: 0;
      }
    }

    &::before,
    &::after {
      display: none;
    }
  }

  @media ${deviceSize['sm']} {
    display: none;
  }
`;

type MenuItem = Required<MenuProps>['items'][number];

const TopSubMenu = ({
  inlineMode,
  newUserNoticeCount,
  isLoggedIn,
  logout,
  toggleDrawer,
  showUserNoticeDrawer,
}: {
  inlineMode: boolean;
  newUserNoticeCount: number;
  isLoggedIn: boolean;
  logout: VoidFunction;
  toggleDrawer: VoidFunction;
  showUserNoticeDrawer: VoidFunction;
}) => {
  const { pathname } = useLocation();

  const menuItems: MenuItem[] = !isLoggedIn
    ? [
        {
          label: <Link to={path.login}>로그인</Link>,
          key: path.login,
          style: { color: palette.GRAY70 },
        },
      ]
    : [
        ...(!inlineMode
          ? [
              {
                label: (
                  <Badge
                    color={palette.PRIMARY50}
                    dot={newUserNoticeCount > 0}
                    style={{
                      top: -2,
                      right: -2,
                      width: 5,
                      height: 5,
                      minWidth: 5,
                    }}
                  >
                    <Icon
                      name="bell"
                      size={18}
                      style={{
                        position: 'relative',
                        top: -2,
                        cursor: 'pointer',
                      }}
                    />
                  </Badge>
                ),
                key: 'userNotice',
                onClick: showUserNoticeDrawer,
              } as MenuItem,
            ]
          : []),
        {
          key: path.my,
          label: <Link to={path.my}>마이페이지</Link>,
          style: { color: palette.GRAY70 },
        },
        {
          key: path.logout,
          label: '로그아웃',
          onClick: logout,
          style: { color: palette.GRAY70 },
        },
      ];

  return (
    <TopSubMenuBlock is_inline_mode={inlineMode ? 'true' : 'false'}>
      {inlineMode && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
      <Menu
        mode={inlineMode ? 'inline' : 'horizontal'}
        selectedKeys={[pathname]}
        onClick={() => {
          inlineMode && toggleDrawer();
        }}
        items={menuItems}
      />
    </TopSubMenuBlock>
  );
};

export default TopSubMenu;

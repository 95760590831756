import { useEffect, useState } from 'react';
import { Button, Col, message, Row, Spin } from 'antd';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import FileUploadContainer from 'containers/file/FileUploadContainer';
import { useFDACertificatedColor } from 'service/brand/product/vcrp';
import UpdateLog from './UpdateLog';
import { FDACertificatedColorAttachUpload } from 'types/brand/vcrp';
import { createFormData } from 'lib/file';
import { messages } from 'lib/consts';
import { DocumentCode } from 'types/product';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import { useNavigate } from 'react-router-dom';

const ProductFDACertificatedColorContainer = styled.div`
  width: 816px;
  margin: 8px auto 0;
`;

const ProductFDACertificatedColor = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const navigate = useNavigate();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const { sendEventToGA } = useGA();
  const { fdaCertificatedColor, addFDACertificatedColor, readOnlyMode, updateMode, isLoading } =
    useFDACertificatedColor({ productId, countryId });

  const [files, setFiles] = useState<(File | FDACertificatedColorAttachUpload)[]>([]);
  const [deletePFCCADIds, setDeletePFCCADIds] = useState<number[]>([]);

  const handleSubmit = () => {
    if (!files.length) {
      message.warning(messages.REQUIRED_FILES);
      return;
    }

    const newFiles = files.filter((file) => !('url' in file));

    if (!newFiles.length && !deletePFCCADIds.length) {
      message.warning(messages.NO_NEED_TO_UPDATE);
      return;
    }

    const formData = createFormData({
      files: newFiles,
      ...(updateMode && { deletePFCCADIds }),
    });

    if (!updateMode) {
      addFDACertificatedColor(formData, {
        onSuccess: () => {
          sendEventToGA({
            documentName: 'FDA 색소 인증 서류',
            actionType: EGAActionType.REGISTER,
          });
          message.success('등록되었습니다.');
          navigate(-1);
        },
      });
    } else {
      addFDACertificatedColor(formData, {
        onSuccess: () => {
          sendEventToGA({
            documentName: 'FDA 색소 인증 서류',
            actionType: EGAActionType.MODIFY,
          });
          message.success('보완 완료되었습니다.');
          navigate(-1);
        },
      });
    }
  };

  const handleUpload = (file: File) => {
    if (files.find((item) => item.name === file.name)) {
      message.warning(messages.DUPLICATE_FILE_NAME);
      return false;
    }

    setFiles((draft) => draft.concat(file));
    return true;
  };

  const handleDelete = (file: File | FDACertificatedColorAttachUpload) => {
    if ('productFdaCertificatedColorAdditivesDocumentId' in file) {
      setDeletePFCCADIds([...deletePFCCADIds, file.productFdaCertificatedColorAdditivesDocumentId]);
    }
    setFiles(files.filter((item) => item !== file));
    return false;
  };

  useEffect(() => {
    if (fdaCertificatedColor.length) {
      setFiles(
        fdaCertificatedColor.map((attach) => ({
          name: attach.filename,
          url: attach.attachUrl,
          productFdaCertificatedColorAdditivesDocumentId:
            attach.productFdaCertificatedColorAdditivesDocumentId,
        })),
      );
    }
  }, [fdaCertificatedColor]);

  if (isLoading) {
    return <Spin style={{ width: '100%' }}></Spin>;
  }

  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.FCCAD}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.FCCAD}
            />
          </Col>
        )}
      </Row>
      <ProductFDACertificatedColorContainer>
        <FileUploadContainer
          accept=".pdf,.zip"
          max={10}
          files={files}
          readOnly={readOnlyMode}
          onUpload={handleUpload}
          onDelete={handleDelete}
        />
        <FooterBox>
          <ReadOnlyBackButton readOnly={readOnlyMode}>
            {updateMode && <CorrectRequestWithoutChangeButton documentCode={DocumentCode.FCCAD} />}
            <Button type="primary" loading={isLoading} onClick={handleSubmit}>
              {!updateMode ? '등록' : '보완 완료'}
            </Button>
          </ReadOnlyBackButton>
        </FooterBox>
      </ProductFDACertificatedColorContainer>
    </>
  );
};

export default ProductFDACertificatedColor;

import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import client from 'lib/api/client';
import { APIPageableResponse, APIResponse } from 'types/common';
import { ICIDOngoingDocs, MaterialIcidApplicationOngoing } from 'types/material/icid/icid';
import { PCPCQuestionData } from 'types/material/icid/pcpc';
import { createFormData } from 'lib/file';

const useOngoingKey = 'material/icid-applications/ongoing';

export const useOngoing = ({ page, tradeName }: { page: number; tradeName?: string }) => {
  const { data = null } = useQuery(
    [useOngoingKey, page, tradeName],
    () =>
      client.get<APIPageableResponse<MaterialIcidApplicationOngoing[]>>(
        '/v1/material/icid-applications/ongoing',
        {
          params: {
            page,
            tradeName,
            size: 10,
          },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const { content: icidApplicationOngoings = [], totalElements } = data || {};

  return useMemo(
    () => ({ icidApplicationOngoings, totalElements }),
    [icidApplicationOngoings, totalElements],
  );
};

export const useOngoingDocs = (icidApplicationId?: number) => {
  const { data: icidApplicationOngoings = [], isLoading } = useQuery(
    ['/material/icid-applications/ongoing-docs', icidApplicationId],
    () =>
      client.get<APIResponse<ICIDOngoingDocs[]>>(
        `/v1/material/icid-applications/${icidApplicationId}/ongoing-docs`,
      ),
    {
      enabled: typeof icidApplicationId !== 'undefined',
      select: (res) => res.data.result,
    },
  );

  return { icidApplicationOngoings, isLoading };
};

export const useCompleteOngoingDocs = () => {
  const { mutate: completeOngoingDocs, isLoading: isCompleteLoading } = useMutation(
    (icidApplicationId: number) =>
      client.post(`/v1/material/icid-applications/${icidApplicationId}/ongoing-docs/complete`),
  );

  return { completeOngoingDocs, isCompleteLoading };
};

export const usePCPCQuestions = (icidApplicationId: number) => {
  const { data: pcpcQuestionData } = useQuery(
    ['material/icid-applications/pcpc-questions', icidApplicationId],
    () =>
      client.get<APIResponse<PCPCQuestionData>>(
        `/v1/material/icid-applications/${icidApplicationId}/pcpc-questions`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return pcpcQuestionData;
};

export const useAnswerPCPCQuestion = () => {
  const queryClient = useQueryClient();
  const { mutate: answerPCPCQuestion } = useMutation(
    ({
      icidApplicationId,
      parentMaterialIcidApplicationPcpcQuestionId,
      content,
      attachFiles,
    }: {
      icidApplicationId: number;
      parentMaterialIcidApplicationPcpcQuestionId: number;
      content: string;
      attachFiles: File[];
    }) =>
      client.post(
        `/v1/material/icid-applications/${icidApplicationId}/answer-pcpc-questions`,
        createFormData({
          content,
          attachFiles,
          parentMaterialIcidApplicationPcpcQuestionId,
        }),
      ),
    {
      onSuccess: () => queryClient.refetchQueries(useOngoingKey, { active: true }),
    },
  );

  return {
    answerPCPCQuestion,
  };
};

import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';

import { messages } from 'lib/consts';
import { exceptKoreanRule, generateFormRules, phoneRule, requireRule } from 'lib/validate';
import { toggleCompanyInfoModalVisible } from 'modules/certificate';
import { updateCompanyInfo } from 'modules/product';
import FooterBox from 'components/FooterBox';
import UpdateLog from 'components/product/UpdateLog';
import { DocumentCode } from 'types/product';
import { useCurrentProduct } from 'service/brand/product/product';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

const CompanyInfoModal = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const dispatch = useDispatch();
  const { productDetail } = useCurrentProduct();
  const visible = useSelector(({ certificate }: any) => certificate.companyInfoModalVisible);
  const { estimateTarget, readOnlyMode } = useSelector(
    ({ certificate }: any) => ({
      estimateTarget: certificate.certificate.estimateTarget,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const { productId, countryId } = estimateTarget;
  const submitLoading = useSelector(({ loading }: any) => loading['product/UPDATE_COMPANY_INFO']);
  const [form] = Form.useForm();
  const onClose = () => {
    dispatch(toggleCompanyInfoModalVisible());
  };
  const onSubmit = ({
    manufacturerNameKo,
    manufacturerNameEn,
    manufacturerAddressEn,
    manufacturerTel,
    manufacturerZipCode,
  }: any) => {
    const updateValues = {
      ...(manufacturerNameKo !== productDetail.manufacturerNameKo && {
        manufacturerNameKo,
      }),
      ...(manufacturerNameEn !== productDetail.manufacturerNameEn && {
        manufacturerNameEn,
      }),
      ...(manufacturerAddressEn !== productDetail.manufacturerAddressEn && {
        manufacturerAddressEn,
      }),
      ...(manufacturerTel !== productDetail.manufacturerTel && {
        manufacturerTel,
      }),
      ...(manufacturerZipCode !== productDetail.manufacturerZipCode && {
        manufacturerZipCode,
      }),
    };
    if (Object.keys(updateValues).length === 0) {
      return message.warning(messages.NO_NEED_TO_UPDATE);
    }
    dispatch(updateCompanyInfo({ productId, countryId, ...updateValues }));
  };
  useEffect(() => {
    if (productDetail) {
      form.setFieldsValue(productDetail);
    }
  }, [productDetail]);
  return (
    <Modal
      open={visible}
      title="회사 정보"
      footer={null}
      width={700}
      destroyOnClose
      maskClosable={false}
      onCancel={onClose}
    >
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            productId={productId}
            countryId={countryId}
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.CI}
          />
        </Col>
        <Col>
          <UpdateLog
            productId={productId}
            countryId={countryId}
            documentCode={DocumentCode.BASIC}
            validCodes={['BASIC01-MNK', 'BASIC01-MNE', 'BASIC01-MAE', 'BASIC01-MT', 'BASIC01-MF']}
          />
        </Col>
      </Row>

      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="화장품제조업자 (국문)"
          name="manufacturerNameKo"
          required
          rules={[requireRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="화장품제조업자 (영문)"
          name="manufacturerNameEn"
          required
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="화장품제조업자 주소 (영문)"
          name="manufacturerAddressEn"
          required
          rules={[requireRule, exceptKoreanRule]}
        >
          <Input disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="화장품제조업자 전화번호"
          name="manufacturerTel"
          required
          rules={[requireRule, phoneRule]}
        >
          <Input placeholder={messages.PHONE_FORMAT} disabled={readOnlyMode} />
        </Form.Item>
        <Form.Item
          label="화장품제조업자 우편번호"
          name="manufacturerZipCode"
          required
          rules={generateFormRules({
            required: true,
            minLength: 5,
            maxLength: 10,
            onlyNumber: true,
          })}
        >
          <Input placeholder={'숫자만 입력 가능, 5 ~ 10글자'} disabled={readOnlyMode} />
        </Form.Item>
        {!readOnlyMode && (
          <FooterBox style={{ marginTop: 20 }}>
            <CorrectRequestWithoutChangeButton
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.CI}
              onSubmit={() => onClose()}
            />
            <Button type="primary" loading={submitLoading} htmlType="submit">
              보완 완료
            </Button>
          </FooterBox>
        )}
      </Form>
    </Modal>
  );
};

export default CompanyInfoModal;

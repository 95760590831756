import { Col, Descriptions, Modal, Row, Space } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { CloseIcon } from 'components/system/general/icon';
import Icon from 'components/ui/Icon/Icon';
import Tags from 'components/ui/Tags';
import palette from 'lib/styles/palette';
import { useState } from 'react';
import { SpecialBrandDocResponseType } from 'types/material/rawMaterial';
import { RawMaterialDetail } from 'types/material/rawMaterialDetail';

const DescriptionWrapper = styled.div`
  .ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view {
    border-left: none;
    border-right: none;
    border-top: 2px solid ${palette.PRIMARY50};
    border-radius: 0;

    .ant-descriptions-row > .ant-descriptions-item-label {
      width: 200px;
      padding: 10px 16px;
      color: ${palette.SLATE_GRAY70};
      font-weight: 500;
      font-size: 16px;
      vertical-align: top;
    }
    .ant-descriptions-item-content {
      width: 260px;
      max-width: 260px;
      padding: 10px 16px;
      color: ${palette.SLATE_GRAY70};
      font-weight: 500;
      vertical-align: top;
    }
  }
`;

const InnerDescriptions = styled(Descriptions)`
  .ant-descriptions-view {
    border: none !important;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: transparent;
    padding: 32px 40px 0px 40px;
    display: none;
  }
`;

const CompanyInfo = styled.div`
  padding: 3px 8px;
  border-radius: 4px;
  border: solid 1px ${palette.GRAY40};
  background-color: #fff;
  font-size: 12px;
  line-height: normal;
  color: ${palette.GRAY70};

  cursor: pointer;
`;

const Description = ({
  rawMaterialDetail,
  remarkCategoryNames,
}: {
  rawMaterialDetail: RawMaterialDetail;
  remarkCategoryNames: string;
}) => {
  const [companyInfoModalVisible, setCompanyInfoModalVisible] = useState(false);
  const {
    materialDetail,
    canUseForFragrance,
    companyInformation: company,
    materialBasicInformation,
    isAllergenFree,
    marketingFiles,
  } = rawMaterialDetail;
  const {
    materialNameEn,
    materialNameKo,
    originManufacturerName,
    efficacies,
    formulaPurposes,
    marketingKeywords,
    isDiscontinued,
  } = materialBasicInformation;

  return (
    <>
      <Row align="middle" gutter={16} style={{ marginBottom: 12 }}>
        <Col>
          <Typography.Text type="TITLE_1">{materialNameEn}</Typography.Text>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={{ marginTop: 3 }} color="SLATE_GRAY70" medium>
              {materialNameKo}
            </Typography.Text>
            {isDiscontinued && <Tags.Mark color="lightGray">단종 원료</Tags.Mark>}
          </Space>
        </Col>
      </Row>
      <DescriptionWrapper style={{ marginBottom: 22 }}>
        <Descriptions
          bordered
          column={2}
          items={[
            {
              label: '원료사',
              span: 1,
              children: (
                <Row gutter={8} align="middle">
                  <Col>{company.companyNameKo}</Col>
                  <Col>
                    <CompanyInfo onClick={() => setCompanyInfoModalVisible(true)}>
                      회사 정보
                    </CompanyInfo>
                  </Col>
                </Row>
              ),
            },
            {
              label: '원료 생산 회사',
              span: 1,
              children: originManufacturerName,
            },
            {
              label: '효능',
              span: 1,
              children: (
                <Row gutter={[4, 4]}>
                  {efficacies.map(({ efficacyCategoryId, efficacyName }) => (
                    <Col key={efficacyCategoryId}>
                      <Typography.Category>{efficacyName}</Typography.Category>
                    </Col>
                  ))}
                </Row>
              ),
            },
            {
              label: '배합 목적',
              span: 1,
              children: (
                <Row gutter={[4, 4]}>
                  {formulaPurposes.map(({ formulaPurposeCategoryId, formulaPurposeName }) => (
                    <Col key={formulaPurposeCategoryId}>
                      <Typography.Category>{formulaPurposeName}</Typography.Category>
                    </Col>
                  ))}
                </Row>
              ),
            },
            {
              label: '마케팅 키워드',
              span: 2,
              children: (
                <Row gutter={[4, 4]}>
                  {marketingKeywords.map(({ marketingKeywordId, keyword }) => (
                    <Col key={marketingKeywordId}>
                      <Typography.Category>{keyword}</Typography.Category>
                    </Col>
                  ))}
                </Row>
              ),
            },
            ...(materialDetail
              ? [
                  {
                    label: '향료 사용 가능 여부',
                    span: 1,
                    children: canUseForFragrance ? '향료로 사용 가능' : '향료로 사용 불가',
                  },
                  {
                    label: '알러지 유발 성분 유무',
                    span: 1,
                    children:
                      isAllergenFree === null
                        ? '-'
                        : isAllergenFree
                        ? '알러지 프리'
                        : '알러지 유발 성분 있음',
                  },
                  {
                    label: 'HS Code',
                    span: 1,
                    children: materialDetail?.hsCode || '-',
                  },
                  {
                    label: '특허 번호',
                    span: 1,
                    children:
                      materialDetail?.materialPatents
                        ?.map(({ patentNumber }) => patentNumber)
                        .join(', ') || '-',
                  },
                  {
                    label: '마케팅 자료',
                    span: 1,
                    children:
                      marketingFiles &&
                      marketingFiles.map(({ materialMarketingDataId, displayType, attachUrl }) => (
                        <a href={attachUrl} target="_self" key={materialMarketingDataId}>
                          <Icon
                            name="page"
                            color={displayType === 'PUBLIC' ? 'GRAY90' : 'GRAY70'}
                          />
                        </a>
                      )),
                  },
                  {
                    label: '원료 특이사항',
                    span: 1,
                    children: remarkCategoryNames,
                  },
                  {
                    label: '납품 가능한 특수 브랜드',
                    span: 2,
                    contentStyle: materialDetail?.isExperienceSpecialBrandDelivery
                      ? { padding: 0 }
                      : undefined,
                    children: materialDetail?.isExperienceSpecialBrandDelivery ? (
                      <InnerDescriptions bordered column={1} labelStyle={{ display: 'none' }}>
                        {materialDetail?.specialBrands?.map(
                          ({ specialBrandId, brandName, docResponseType }) => (
                            <Descriptions.Item key={specialBrandId}>
                              {brandName} / 서류 대응 여부 :{' '}
                              {docResponseType === SpecialBrandDocResponseType.POSSIBLE
                                ? '가능'
                                : docResponseType === SpecialBrandDocResponseType.IMPOSSIBLE
                                ? '불가능'
                                : '모름'}
                            </Descriptions.Item>
                          ),
                        )}
                      </InnerDescriptions>
                    ) : (
                      '없음'
                    ),
                  },
                ]
              : []),
          ]}
        />
      </DescriptionWrapper>
      <StyledModal
        width={800}
        open={companyInfoModalVisible}
        title="회사 정보"
        footer={null}
        closable={false}
        onCancel={() => setCompanyInfoModalVisible(false)}
      >
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Typography.Text type="TITLE_1">회사 정보</Typography.Text>
          <CloseIcon onClick={() => setCompanyInfoModalVisible(false)} />
        </Row>
        <DescriptionWrapper>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="회사명">{company.companyNameKo}</Descriptions.Item>
            <Descriptions.Item label="대표자 성명">{company.ceoNameKo}</Descriptions.Item>
            <Descriptions.Item label="회사 전화번호">{company.tel}</Descriptions.Item>
            <Descriptions.Item label="회사 이메일">{company.email ?? '-'}</Descriptions.Item>
            <Descriptions.Item label="팩스번호 (FAX)">{company.fax ?? '-'}</Descriptions.Item>
            <Descriptions.Item label="주요 품목">{company.mainItem}</Descriptions.Item>
            <Descriptions.Item label="회사 소개">{company.introduction}</Descriptions.Item>
          </Descriptions>
        </DescriptionWrapper>
      </StyledModal>
    </>
  );
};

export default Description;

import { Modal } from 'antd';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { CountryProhibitInfo } from 'types/formula';
import { RegulationType } from 'service/brand/formulaScreening/formulaScreening';

const ModalHeader = styled(Flex)`
  padding: 16px 24px;
  border-bottom: 1px solid ${palette.GRAY20};
  height: 58px;
`;

const ModalBody = styled.div`
  padding: 24px 40px 32px;
  height: 502px;
`;

const Tab = styled.div<{ isActive: boolean; label: string }>`
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${palette.SLATE_GRAY20};
          background-color: ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
          font-weight: 500;
          cursor: default;
        `
      : css`
          border: 1px solid ${palette.GRAY40};
          background-color: ${palette.ETC_WHITE};
          color: ${palette.SLATE_GRAY60};
          cursor: pointer;
        `};

  &::after {
    content: '${({ label }) => label}';
    font-weight: bold;
    display: block;
    height: 0;
    visibility: hidden;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  border: 1px solid ${palette.PRIMARY50};
  font-size: 16px;
  line-height: 24px;
  overflow-y: scroll;
`;

const ContentTitle = styled(Flex)`
  flex: 0 0 200px;
  background-color: ${palette.PRIMARY10};
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${palette.GRAY30};
`;

const Description = styled.div`
  padding: 12px;
  background-color: white;
`;

const CountryProhibitInfosModal = ({
  defaultCountryName,
  countryInfos,
  onClose,
}: {
  defaultCountryName?: string;
  countryInfos: CountryProhibitInfo[];
  onClose: () => void;
}) => {
  const tabRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [selectedCountryName, setSelectedCountryName] = useState(defaultCountryName);
  const { type, notifiedIngredientName, limitedMatter, fdaAnnouncedColorName } = useMemo(
    () => ({
      ...countryInfos?.find(({ countryNameKo }) => countryNameKo === selectedCountryName),
    }),
    [selectedCountryName, countryInfos],
  );
  useEffect(() => {
    if (countryInfos.length > 0) {
      setSelectedCountryName(defaultCountryName || countryInfos[0].countryNameKo);
    }
  }, [countryInfos, defaultCountryName]);

  useLayoutEffect(() => {
    if (tabRef.current && contentRef.current) {
      const tabHeight = tabRef.current.clientHeight;

      // ModalBody 높이 - ModalBody 패딩 - gap - tab 높이
      contentRef.current.style.height = `${502 - 56 - 24 - tabHeight}px`;
    }
  }, [selectedCountryName]);

  return (
    <Modal
      open={countryInfos.length > 0}
      width={896}
      onCancel={onClose}
      footer={null}
      centered
      styles={{ body: { padding: 0, height: 560 } }}
      closable={false}
    >
      <ModalHeader justify="space-between" align="center">
        <Typography.Text type="TITLE_2" semiBold>
          성분 규제사항
        </Typography.Text>
        <Icon name="close" size={24} onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <Flex gap={8} wrap="true" ref={tabRef}>
          {countryInfos.map(({ countryNameKo }) => (
            <Tab
              label={countryNameKo}
              key={countryNameKo}
              isActive={countryNameKo === selectedCountryName}
              onClick={() =>
                countryNameKo !== selectedCountryName && setSelectedCountryName(countryNameKo)
              }
            >
              {countryNameKo}
            </Tab>
          ))}
        </Flex>
        <Content ref={contentRef}>
          <Flex align="stretch" style={{ borderBottom: `1px solid ${palette.GRAY30}` }}>
            <ContentTitle>구분</ContentTitle>
            <Description>
              {type?.toUpperCase() === RegulationType.LIMIT ? '제한' : '금지'}
            </Description>
          </Flex>
          <Flex align="stretch" style={{ borderBottom: `1px solid ${palette.GRAY30}` }}>
            <ContentTitle>고시원료명</ContentTitle>
            <Description>{notifiedIngredientName || '-'}</Description>
          </Flex>
          {fdaAnnouncedColorName && (
            <Flex align="stretch" style={{ borderBottom: `1px solid ${palette.GRAY30}` }}>
              <ContentTitle>FDA 허용 색소명</ContentTitle>
              <Description>{fdaAnnouncedColorName}</Description>
            </Flex>
          )}
          <Flex align="stretch" style={{ flex: 1 }}>
            <ContentTitle>제한사항</ContentTitle>
            <Description
              style={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {limitedMatter || '-'}
            </Description>
          </Flex>
        </Content>
      </ModalBody>
    </Modal>
  );
};

export default CountryProhibitInfosModal;

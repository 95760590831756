import { useRoutes } from 'react-router-dom';

import Error404Page from 'pages/error/Error404Page';
import IngredientDetailPage from 'pages/material/ingredient/IngredientDetailPage';
import IngredientDictionaryPage from 'pages/material/ingredient/IngredientDictionaryPage';

const IngredientRouter = () => {
  const element = useRoutes([
    { path: 'dictionary', element: <IngredientDictionaryPage /> },
    { path: 'detail/:ingredientId', element: <IngredientDetailPage /> },
    { path: '*', element: <Error404Page /> },
  ]);
  return element;
};

export default IngredientRouter;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Tooltip from 'components/ui/Tooltip';
import { useModal } from 'hook/useModal';
import { deviceSize } from 'lib/styles';
import palette from 'lib/styles/palette';

const FixedContainer = styled.div<{
  isLoggedIn?: boolean;
}>`
  position: fixed;
  z-index: 10;
  bottom: ${({ isLoggedIn }) => (isLoggedIn ? 92 : 20)}px;
  right: 20px;

  @media ${deviceSize.sm} {
    bottom: ${({ isLoggedIn }) => (isLoggedIn ? 68 : 18)}px;
    right: 14px;
  }
`;

const Container = styled.div`
  width: fit-content;
`;

const IconWrapper = styled(Flex)`
  width: 60px;
  height: 60px;
  background-color: ${palette.ETC_WHITE};
  border-radius: 26px;
  cursor: pointer;
  box-shadow: rgb(255 255 255 / 12%) 0px 0px 2px 0px inset, rgb(0 0 0 / 5%) 0px 0px 2px 1px,
    rgb(0 0 0 / 22%) 0px 4px 20px;

  @media ${deviceSize.sm} {
    width: 42px;
    height: 42px;
    border-radius: 18px;
  }
`;

IconWrapper.defaultProps = {
  align: 'center',
  justify: 'center',
};

export const RightBottomIconBarUI = ({ onEstimate }: { onEstimate?: VoidFunction }) => {
  const [estimateTooltipVisible, setEstimateTooltipVisible] = useState(true);
  const isMobile = useMediaQuery({
    query: deviceSize.sm,
  });

  const handleEstimateTooltipClose = () => setEstimateTooltipVisible(false);

  const width = isMobile ? 21 : 31;
  const height = isMobile ? 23 : 34;

  return (
    <Container>
      <IconWrapper onClick={onEstimate}>
        <Icon name="docGlass" width={width} height={height} />
        <Tooltip
          direction="left"
          visible={estimateTooltipVisible}
          onClose={handleEstimateTooltipClose}
          title="견적이 궁금하시다면?"
        />
      </IconWrapper>
    </Container>
  );
};

const RightBottomIconBar = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const param = queryParams.get('contact_modal');

  const { openEstimateContactModal } = useModal();
  const user = useSelector(({ auth }: any) => auth.user);
  const isLoggedIn = user !== null;

  const handleEstimateContactOpen = () => {
    openEstimateContactModal();
  };

  useEffect(() => {
    if (param === 'open') {
      openEstimateContactModal();
    }
  }, [param]);

  return (
    <FixedContainer isLoggedIn={isLoggedIn}>
      <RightBottomIconBarUI onEstimate={handleEstimateContactOpen} />
    </FixedContainer>
  );
};

export default RightBottomIconBar;

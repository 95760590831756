import { Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { CountryLawRegulation } from 'types/material/ingredient';
import Tags from 'components/ui/Tags';

const ModalHeader = styled(Flex)`
  padding: 16px 24px;
  border-bottom: 1px solid ${palette.GRAY20};
  height: 58px;
`;

const ModalBody = styled.div`
  padding: 24px 40px 32px;
  height: 502px;
  overflow-y: scroll;
`;

const Tab = styled.div<{ isActive: boolean; label: string }>`
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${palette.SLATE_GRAY20};
          background-color: ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
          font-weight: 500;
          cursor: default;
        `
      : css`
          border: 1px solid ${palette.GRAY40};
          background-color: ${palette.ETC_WHITE};
          color: ${palette.SLATE_GRAY60};
          cursor: pointer;
        `};

  &::after {
    content: '${({ label }) => label}';
    font-weight: bold;
    display: block;
    height: 0;
    visibility: hidden;
  }
`;

const StyledTable = styled.div`
  margin-top: 24px;
  border-top: 2px solid ${palette.PRIMARY50};
`;

const HeaderItem = styled.div`
  padding: 12px 16px;
  background-color: ${palette.GRAY10};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  font-weight: 500;

  &:last-child {
    border-right: none;
  }
`;

const TableBody = styled.div``;

const BodyItem = styled(Flex)`
  align-items: center;
  padding: 12px 16px;
  background-color: ${palette.ETC_WHITE};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  white-space: pre-wrap;

  &:last-child {
    border-right: none;
  }
`;

const FormulaScreeningCountryProhibitInfosModal = ({
  defaultCountryCode,
  countryInfos,
  onClose,
  maskStyle,
}: {
  defaultCountryCode?: string;
  countryInfos: CountryLawRegulation[];
  onClose: () => void;
  maskStyle?: React.CSSProperties;
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    defaultCountryCode || countryInfos[0].country.code,
  );

  const selectedCountryInfos = useMemo(
    () => countryInfos?.filter(({ country: { code } }) => code === selectedCountryCode),
    [selectedCountryCode, countryInfos],
  );

  return (
    <Modal
      open={countryInfos.length > 0}
      width={896}
      onCancel={onClose}
      footer={null}
      centered
      styles={{ body: { padding: 0, height: 560 }, mask: maskStyle }}
      closable={false}
    >
      <ModalHeader justify="space-between" align="center">
        <Typography.Text type="TITLE_2" semiBold>
          성분 규제사항
        </Typography.Text>
        <Icon name="close" size={24} onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <Flex gap={8} wrap="true">
          {countryInfos
            .reduce((acc, cur) => {
              if (!acc.find((item) => item.code === cur.country.code)) {
                acc.push({
                  nameKo: cur.country.nameKo,
                  code: cur.country.code,
                });
              }
              return acc;
            }, [] as { nameKo: string; code: string }[])
            .map(({ code, nameKo }) => (
              <Tab
                label={nameKo}
                key={code}
                isActive={code === selectedCountryCode}
                onClick={() => code !== selectedCountryCode && setSelectedCountryCode(code)}
              >
                {nameKo}
              </Tab>
            ))}
        </Flex>
        {selectedCountryInfos.length > 0 && (
          <StyledTable>
            <Flex>
              <HeaderItem style={{ flex: '0 0 160px' }}>규제명</HeaderItem>
              <HeaderItem style={{ flex: '0 0 280px' }}>고시원료명</HeaderItem>
              <HeaderItem style={{ flex: '0 0 376px' }}>제한사항</HeaderItem>
            </Flex>
            <TableBody>
              {selectedCountryInfos
                ?.reduce((acc, cur) => {
                  if (!acc.find((item) => item.country.code === cur.country.code)) {
                    acc.push(cur);
                  }
                  return acc;
                }, [] as CountryLawRegulation[])
                .map(({ ingredientLawRegulations, groupingLawRegulations, ingredientId }) => {
                  return (
                    <React.Fragment key={ingredientId}>
                      {ingredientLawRegulations &&
                        ingredientLawRegulations.length > 0 &&
                        ingredientLawRegulations.map((ingredientLawRegulation) => {
                          const isIngredientLimit =
                            ingredientLawRegulation?.regulation?.type === 'LIMIT';

                          return (
                            <Flex
                              align="stretch"
                              key={`${ingredientLawRegulation.ingredientLawRegulationId}-${ingredientLawRegulation.lawIngredientWhitelistId}`}
                            >
                              <BodyItem style={{ flex: '0 0 160px' }}>
                                {ingredientLawRegulation.law.name}
                              </BodyItem>
                              <BodyItem
                                style={{
                                  flex: '0 0 280px',
                                  fontWeight: 500,
                                }}
                              >
                                {ingredientLawRegulation.regulation?.notifiedIngredientName || '-'}
                              </BodyItem>
                              <BodyItem style={{ flex: '0 0 376px' }}>
                                <div>
                                  <Tags.Mark
                                    color={isIngredientLimit ? 'green' : 'red'}
                                    style={{ margin: '0 0 8px 0' }}
                                  >
                                    {isIngredientLimit ? '제한' : '금지'}
                                  </Tags.Mark>
                                  {ingredientLawRegulation.regulation?.regulationsOfLanguage.find(
                                    (item) => item.languageCode === 'KO',
                                  )?.limitedMatter || ''}
                                </div>
                              </BodyItem>
                            </Flex>
                          );
                        })}
                      {groupingLawRegulations &&
                        groupingLawRegulations.map((groupingLawRegulation) => {
                          const isGroupLimit = groupingLawRegulation.regulation?.type === 'LIMIT';

                          return (
                            <Flex
                              align="stretch"
                              key={groupingLawRegulation.groupingLawRegulationId}
                            >
                              <BodyItem style={{ flex: '0 0 160px' }}>
                                {groupingLawRegulation.law.name}
                              </BodyItem>
                              <BodyItem
                                style={{
                                  flex: '0 0 280px',
                                  fontWeight: 500,
                                }}
                              >
                                {groupingLawRegulation.regulation?.notifiedIngredientName || '-'}
                              </BodyItem>
                              <BodyItem style={{ flex: '0 0 376px' }}>
                                <div>
                                  <Tags.Mark
                                    color={isGroupLimit ? 'green' : 'red'}
                                    style={{ margin: '0 0 8px 0' }}
                                  >
                                    {isGroupLimit ? '제한' : '금지'}
                                  </Tags.Mark>
                                  {groupingLawRegulation.regulation?.regulationsOfLanguage.find(
                                    (item) => item.languageCode === 'KO',
                                  )?.limitedMatter || ''}
                                </div>
                              </BodyItem>
                            </Flex>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </StyledTable>
        )}
      </ModalBody>
    </Modal>
  );
};

export default FormulaScreeningCountryProhibitInfosModal;

import { Typography } from 'antd';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductSingleFileContainer from 'containers/product/ProductSingleFileContainer';
import ProductPageTemplate from 'templates/ProductPageTemplate';

const notices = [
  <Typography.Text type="danger" style={{ padding: 0 }}>
    전성분표에 기재된 모든 성분이 공정도상에 기재되었는지 확인해 주세요.
  </Typography.Text>,
  '업로드해 주신 파일은 중국 위생허가 용도로만 사용됨을 알려드립니다.',
  <MistakeNoticeItem />,
];

const ProductManufacturingProcessChinaPage = () => {
  return (
    <ProductPageTemplate
      subtitle="Manufacturing Process (China)"
      notices={notices}
      exampleImg="Manufacturing Process_China.jpg"
    >
      <ProductSingleFileContainer documentCode="manuc" />
    </ProductPageTemplate>
  );
};

export default ProductManufacturingProcessChinaPage;

import IFRACertificate from 'components/material/rawMaterial/IFRACertificate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';

const notices = [
  '원료에 해당하는 IFRA Category 값에 퍼센트를 입력해 주세요. (IFRA 50th Amendment 기준으로 작성해 주세요)',
  '소수점 이하 최대 열 번째 자리까지만 작성 가능합니다.',
  '해당하지 않는 카테고리는 비워주세요. (자동으로 제외됩니다)',
  <MistakeNoticeItem />,
];

const RawMaterialIFRACertificatePage = () => {
  return (
    <RawMaterialPageTemplate documentName="IFRA Conformity Certificate" notices={notices} back>
      <IFRACertificate />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialIFRACertificatePage;

import { Button, Modal } from 'antd';
import { Typography } from 'components/system';
import { ReactNode } from 'react';
import styled from 'styled-components';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';

const StyledModal = styled(Modal)<{ titleBorder: boolean }>`
  border-radius: 4px;

  .ant-modal-header {
    height: min-content !important;
    padding: 16px 24px !important;
    border: ${({ titleBorder }) => (titleBorder ? 'auto' : 'none')};
  }
`;

export interface ConfirmModalProps {
  title: string | ReactNode;
  content: string | ReactNode;
  width?: number;
  bodyStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  onClose?: () => void;
  onCustomClose?: () => void;
  preventOkClose?: boolean;
  onOk?: () => void;
  closeText?: string;
  okText?: string;
  okLoading?: boolean;
  noCancelButton?: boolean;
  footer?: ReactNode;
}

const ConfirmModal = ({
  title,
  content,
  width = 560,
  bodyStyle = {
    padding: '24px 40px 32px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  footerStyle,
  onClose,
  onCustomClose,
  preventOkClose = false,
  onOk,
  closeText = '취소',
  okText = '확인',
  okLoading = false,
  noCancelButton = false,
  footer,
}: ConfirmModalProps) => {
  return (
    <StyledModal
      open={true}
      title={
        <Flex justify="space-between" align="center">
          {typeof title === 'string' ? (
            <Typography.Text type="TITLE_2" medium>
              {title}
            </Typography.Text>
          ) : (
            title
          )}
          <Icon name="close" size={24} onClick={onClose} />
        </Flex>
      }
      closable={false}
      styles={{
        body: bodyStyle,
      }}
      centered
      width={width}
      footer={null}
      titleBorder={title !== ''}
    >
      {typeof content === 'string' ? (
        <Typography.Text type="BODY_2">{content}</Typography.Text>
      ) : (
        content
      )}
      {footer !== undefined ? (
        footer
      ) : (
        <Flex justify="center" align="center" gap={8} gutter={{ top: 24 }} style={footerStyle}>
          {!noCancelButton && (
            <Button
              key="back"
              onClick={() => {
                onCustomClose?.();
                onClose?.();
              }}
            >
              {closeText}
            </Button>
          )}
          <Button
            key="submit"
            type="primary"
            loading={okLoading}
            onClick={() => {
              onOk?.();
              if (!preventOkClose) {
                onClose?.();
              }
            }}
            style={noCancelButton ? { minWidth: 73 } : {}}
          >
            {okText}
          </Button>
        </Flex>
      )}
    </StyledModal>
  );
};

export default ConfirmModal;

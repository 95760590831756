import { Checkbox, Form, Select } from 'antd';
import React, { useRef } from 'react';

import { Typography } from 'components/system';
import SelectOptionInput from 'components/ui/Select/SelectOptionInput';
import { hardnessUnitOptions } from 'lib/selectOption';
import { generateFormRules } from 'lib/validate';
import * as S from './Styled';
import InputNumber from 'components/ui/InputNumber';

const HardnessForm = ({
  isOnlyCN,
  readOnlyMode,
  onChangeHardnessRadioGroup,
}: {
  isOnlyCN: boolean;
  readOnlyMode: boolean;
  onChangeHardnessRadioGroup: (notApplicableHardness: boolean) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <S.StyledLabelSpaceBetween>
        <S.StyledLabel isOnlyCN={isOnlyCN}>Hardness</S.StyledLabel>
        <Form.Item
          name="notApplicableHardness"
          initialValue={false}
          valuePropName="checked"
          noStyle
        >
          <Checkbox
            disabled={readOnlyMode}
            onChange={(e) => onChangeHardnessRadioGroup(e.target.checked)}
          >
            <S.CheckBoxLabel>해당 없음</S.CheckBoxLabel>
          </Checkbox>
        </Form.Item>
      </S.StyledLabelSpaceBetween>
      <S.StyledRow style={{ gap: 16 }}>
        <S.StyledColumn style={{ marginRight: '30px' }}>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.notApplicableHardness !== next.notApplicableHardness ||
              prev.hardnessLimitMax !== next.hardnessLimitMax ||
              prev.hardnessLimitMin !== next.hardnessLimitMin
            }
          >
            {({ getFieldValue }) => {
              const notApplicableHardness = getFieldValue('notApplicableHardness');
              const hardnessLimitMax = getFieldValue('hardnessLimitMax');
              const hardnessLimitMin = getFieldValue('hardnessLimitMin');
              return (
                <S.StyledRow>
                  <S.StyledColumn>
                    <Typography.Text gutter={{ right: 4, bottom: 8 }}>정상 범위</Typography.Text>
                    <S.StyledRow>
                      {!notApplicableHardness ? (
                        <>
                          <S.AntdExplainWrapper>
                            <Form.Item
                              dependencies={['hardnessLimitMax']}
                              name="hardnessLimitMin"
                              rules={[
                                ...generateFormRules({
                                  required: !(isOnlyCN || notApplicableHardness),
                                }),
                                {
                                  validator: (_, hardnessLimitMinValue) => {
                                    if (hardnessLimitMinValue > hardnessLimitMax) {
                                      return Promise.reject('좌측 값은 우측 값보다 클 수 없음');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <InputNumber
                                maxLength={8}
                                precision={4}
                                min={0}
                                max={999.9999}
                                disabled={readOnlyMode}
                                step={0.1}
                                style={{ width: 160 }}
                                inputwidth={82}
                              />
                            </Form.Item>
                          </S.AntdExplainWrapper>
                          <Typography.Text
                            style={{
                              display: 'inline-block',
                              width: '16px',
                              textAlign: 'center',
                            }}
                          >
                            ~
                          </Typography.Text>
                          <Form.Item
                            name="hardnessLimitMax"
                            rules={generateFormRules({
                              required: !(isOnlyCN || notApplicableHardness),
                            })}
                          >
                            <InputNumber
                              maxLength={8}
                              step={0.1}
                              min={0}
                              max={999.9999}
                              precision={4}
                              disabled={readOnlyMode}
                              style={{ width: 160 }}
                              inputwidth={82}
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <Form.Item>
                            <InputNumber disabled style={{ width: 160 }} />
                          </Form.Item>
                          <Typography.Text
                            style={{
                              display: 'inline-block',
                              width: '16px',
                              textAlign: 'center',
                            }}
                          >
                            ~
                          </Typography.Text>
                          <Form.Item>
                            <InputNumber disabled style={{ width: 160 }} />
                          </Form.Item>
                        </>
                      )}
                    </S.StyledRow>
                  </S.StyledColumn>
                  <S.StyledColumn style={{ marginLeft: '16px' }}>
                    <Typography.Text gutter={{ bottom: 8 }}>수치</Typography.Text>
                    <Form.Item
                      shouldUpdate={(prev, next) => prev.hardnessUnit !== next.hardnessUnit}
                      style={{ marginBottom: 0 }}
                    >
                      {({ getFieldValue, setFieldsValue }) => {
                        return (
                          <S.StyledRow style={{ gap: 6 }}>
                            {!notApplicableHardness ? (
                              <Form.Item
                                name="hardness"
                                required={!(isOnlyCN || notApplicableHardness)}
                                dependencies={['hardnessLimitMax', 'hardnessLimitMin']}
                                rules={[
                                  ...generateFormRules({
                                    required: !(isOnlyCN || notApplicableHardness),
                                  }),
                                  !(isOnlyCN || notApplicableHardness)
                                    ? {
                                        validator: (_, hardness) => {
                                          if (
                                            hardness < hardnessLimitMin ||
                                            hardness > hardnessLimitMax
                                          ) {
                                            return Promise.reject('정상 범위를 벗어난 수치');
                                          }
                                          return Promise.resolve();
                                        },
                                      }
                                    : {
                                        validator: (_, hardness) => {
                                          if (
                                            hardness !== '' &&
                                            hardness !== null &&
                                            (hardness < hardnessLimitMin ||
                                              hardness > hardnessLimitMax) &&
                                            hardnessLimitMax !== null &&
                                            hardnessLimitMin !== null
                                          ) {
                                            return Promise.reject('정상 범위를 벗어난 수치');
                                          }
                                          return Promise.resolve();
                                        },
                                      },
                                ]}
                                // 빈 값을 입력하면 value가 0으로 떨어져 ref를 통해 빈 값을 구분함
                                normalize={(value) =>
                                  ref.current?.value === '' && value === 0 ? null : value
                                }
                              >
                                <InputNumber
                                  ref={ref}
                                  maxLength={8}
                                  disabled={readOnlyMode}
                                  step={0.1}
                                  // 빈 값은 onChange로 전달 받지 않아 onInput 이용
                                  onInput={(value) => {
                                    if (value === '') {
                                      setFieldsValue({
                                        hardness: '',
                                      });
                                    }
                                  }}
                                  max={999.9999}
                                  precision={4}
                                  style={{ width: 160 }}
                                  inputwidth={82}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item noStyle>
                                <InputNumber disabled style={{ width: 160 }} />
                              </Form.Item>
                            )}

                            {!notApplicableHardness ? (
                              <SelectOptionInput
                                parentFormName="hardnessUnit"
                                rules={generateFormRules({
                                  required: !(isOnlyCN || notApplicableHardness),
                                })}
                                selectPlaceholder="단위선택"
                                onSelect={() =>
                                  setFieldsValue({
                                    hardnessUnitDirect: null,
                                  })
                                }
                                selectDisabled={readOnlyMode}
                                isOthers={
                                  getFieldValue('hardnessUnit') === 'others' &&
                                  !notApplicableHardness
                                }
                                inputFormName={
                                  getFieldValue('hardnessUnit') === 'others'
                                    ? 'hardnessUnitDirect'
                                    : 'hardnessUnit'
                                }
                                inputDisabled={readOnlyMode}
                                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                  setFieldsValue({
                                    hardnessUnitDirect: e.target.value,
                                  })
                                }
                                unitDirectValue={getFieldValue('hardnessUnitDirect')}
                              >
                                {hardnessUnitOptions.map((option) => (
                                  <Select.Option key={option.value} value={option.value}>
                                    {option.text}
                                  </Select.Option>
                                ))}
                                <Select.Option key="others" value="others">
                                  직접입력
                                </Select.Option>
                              </SelectOptionInput>
                            ) : (
                              <Form.Item>
                                <Select
                                  disabled
                                  style={{
                                    width: 160,
                                    position: 'relative',
                                    left: '14%',
                                    margin: -10,
                                  }}
                                />
                              </Form.Item>
                            )}
                          </S.StyledRow>
                        );
                      }}
                    </Form.Item>
                  </S.StyledColumn>
                </S.StyledRow>
              );
            }}
          </Form.Item>
        </S.StyledColumn>
      </S.StyledRow>
    </>
  );
};
export default HardnessForm;

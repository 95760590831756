import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Step1 from 'components/brand/formulaScreening/Step1';
import Step2 from 'components/brand/formulaScreening/Step2';
import Step3 from 'components/brand/formulaScreening/Step3';
import { Flex } from 'components/ui';
import history from 'lib/history';
import path from 'lib/path';
import {
  CountryLaw,
  useAllRegulations,
  useFormulaScreening,
} from 'service/brand/formulaScreening/formulaScreening';
import { UserType } from 'types/auth';
import Navigator from 'components/brand/formulaScreening/Navigator';
import { useValidateAdminToken } from 'service/auth';
import client from 'lib/api/client';

export interface ScreeningFormData {
  ingredients: {
    name: string;
    wt: string;
    casNo: string;
  }[];
}

const FormulaScreeningPage = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const storageToken = sessionStorage.getItem('auth.token');

  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const { auth } = useSelector(({ auth }: any) => ({ auth }));
  const [screeningForm] = useForm<ScreeningFormData>();
  const [selectedCountryLaws, setSelectedCountryLaws] = useState<CountryLaw[]>([]);
  const [selectedDistributorIds, setSelectedDistributorIds] = useState<string[]>([]);
  const [selectedSpecialRegionIds, setSelectedSpecialRegionIds] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [method, setMethod] = useState(1);

  const {
    formulaScreeningResult,
    uploadFormulaScreeningJsonLoading,
    uploadFormulaScreeningJson,
    uploadFormulaScreening,
    uploadLoading,
  } = useFormulaScreening({
    enabled: step === 3,
  });
  const { allRegulations, allRegulationsLoading } = useAllRegulations({
    isAdmin,
    enabled: step === 2,
  });
  const { validateAdminToken } = useValidateAdminToken();

  useEffect(() => {
    if (
      !isAdmin &&
      auth.user?.userType !== UserType.MANUFACTURE &&
      auth.user?.userType !== UserType.BRAND
    ) {
      message.error('올바르지 않은 접근입니다.');
      navigate(path.main, { replace: true });
    }
  }, []);

  const [, setStep2DisabledMode] = useState(true);

  useEffect(() => {
    if (isAdmin && token) {
      client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      validateAdminToken(
        {},
        {
          onSuccess: () => {
            history.replace(`${path.formulaScreening}?source=admin`);
            sessionStorage.setItem('auth.token', token);
          },
        },
      );
    } else if (isAdmin && !storageToken) {
      message.error('올바르지 않은 접근입니다.');
      history.replace(path.main);
    }
  }, []);

  return (
    <Flex dir="column" style={{ maxWidth: 1040, marginTop: -58 }}>
      <Navigator activeStep={step} setActiveStep={setStep} />
      {step === 1 ? (
        <Step1
          form={screeningForm}
          file={file}
          method={method}
          setMethod={setMethod}
          setStep={setStep}
          setFile={setFile}
          setStep2DisabledMode={setStep2DisabledMode}
          formulaScreeningResult={formulaScreeningResult}
        />
      ) : step === 2 ? (
        <Step2
          file={file}
          method={method}
          form={screeningForm}
          setStep={setStep}
          selectedCountryLaws={selectedCountryLaws}
          selectedSpecialRegionIds={selectedSpecialRegionIds}
          selectedDistributorIds={selectedDistributorIds}
          setSelectedCountryLaws={setSelectedCountryLaws}
          setSelectedDistributorIds={setSelectedDistributorIds}
          setSelectedSpecialRegionIds={setSelectedSpecialRegionIds}
          uploadFormulaScreeningJson={uploadFormulaScreeningJson}
          uploadFormulaScreeningJsonLoading={uploadFormulaScreeningJsonLoading}
          uploadFormulaScreening={uploadFormulaScreening}
          uploadLoading={uploadLoading}
          allRegulations={allRegulations}
          allRegulationsLoading={allRegulationsLoading}
        />
      ) : (
        <Step3
          setStep={setStep}
          selectedCountryLaws={selectedCountryLaws}
          selectedSpecialRegionIds={selectedSpecialRegionIds}
          selectedDistributorIds={selectedDistributorIds}
          formulaScreeningResult={formulaScreeningResult}
          uploadFormulaScreeningJsonLoading={uploadFormulaScreeningJsonLoading}
          method={method}
        />
      )}
    </Flex>
  );
};

export default FormulaScreeningPage;

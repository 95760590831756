export enum RawMaterialSearchType {
  ALL = 'ALL',
  NAME = 'M_NAME',
  COMPANY = 'M_C_NAME',
  INCI = 'INCI',
  MARKETING = 'MARKETING',
}

export interface Efficacy {
  efficacyCategoryId: number;
  efficacyName: string;
  materialCount: number;
  isDefault: boolean;
}

export interface FormulaPurpose {
  formulaPurposeCategoryId: number;
  formulaPurposeName: string;
  materialCount: number;
}

export interface RetentionCharacteristics {
  materialCategoryId: number;
  type: string;
  code: string;
  nameKo: string;
  nameEn: string;
  nameCn: string;
  description: string;
}

export interface RawMaterialCategories {
  allEfficacies: Efficacy[];
  allFormulaPurposes: FormulaPurpose[];
  allRetentionCharacteristics: RetentionCharacteristics[];
}

export interface RawMaterial {
  materialId: number;
  materialNameEn: string;
  companyNameKo: string;
  efficacies?: string[];
  formulaPurposes?: string[];
  marketingKeywords?: string[];
  isRegisterPatent: boolean;
  countOfIngredients: number;
}

export interface AdRawMaterial extends RawMaterial {
  companyPurchaseProductId: number;
}

export interface RawMaterialSearchParams {
  inciName?: string;
  efficacyIds: number[];
  purposeIds: number[];
  retentionCharacteristicIds: number[];
  searchKeyword: string;
  searchType: RawMaterialSearchType;
}

import qs from 'qs';
import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { EtcDataGet, EtcDatasAdd } from 'types/material/etcData';

export const getEtcDatas = (materialId: number) =>
  client.get<APIResponse<EtcDataGet[]>>(`/material/etc-datas/${materialId}`);

export const getEtcDataUsages = (companyId: number) =>
  client.get<APIResponse<number>>(`/material/etc-datas/usages?companyId=${companyId}`);

export const addEtcDatas = (etcDatas: EtcDatasAdd) => client.post('/material/etc-datas', etcDatas);

export const deleteEtcDatas = (materialEtcDataIds: number[]) =>
  client.delete(`/material/etc-datas?${qs.stringify({ materialEtcDataIds }, { indices: false })}`);

import styled from 'styled-components';
import { Button, Spin } from 'antd';
import { Link } from 'react-router-dom';

import { Typography } from 'components/system';
import path from 'lib/path';
import { s3AssetDomain } from 'lib/consts';
import palette from 'lib/styles/palette';
import { usePCPCCouncilOpenDates } from 'service/material/icid/icid';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

const Container = styled.div`
  width: 736px !important;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  width: 200px;
  height: 56px;
  font-size: 16px;
`;

const StyledImg = styled.img`
  width: 170px;
  height: 240px;
`;

const ICIDInfo = styled.div`
  border-radius: 4px;
  padding: 27px 32px;
  background: ${palette.PRIMARY10};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;

const ICIDDescription = styled.ul`
  border-radius: 4px;
  padding: 14px 40px;
  margin: 8px 0 0;
  background: ${palette.SLATE_GRAY10};
  text-align: left;
  color: ${palette.SLATE_GRAY70};
`;

const PCPCCouncilOpenDatesDiv = styled.div`
  text-align: left;
`;

const PCPCCouncilOpenDateDiv = styled.div<{ isLast: boolean }>`
  display: inline-block;
  margin-left: 8px;
  width: ${(props) => !props.isLast && '81px;'};
`;

const ICIDRequest = () => {
  const { pcpcCouncilOpendates, isLoading, currentYear } = usePCPCCouncilOpenDates();

  const formattedPCPCCouncilOpendates = pcpcCouncilOpendates?.map((date, index) => (
    <PCPCCouncilOpenDateDiv
      key={index}
      isLast={(index + 1) % 5 === 0 || index + 1 === pcpcCouncilOpendates.length}
    >
      <Typography.Text type="TITLE_1" medium inline color="PRIMARY50">
        ･
      </Typography.Text>
      <Typography.Text type="TITLE_1" inline medium>
        {date}
      </Typography.Text>
    </PCPCCouncilOpenDateDiv>
  ));

  return (
    <Container>
      <Spin spinning={isLoading}>
        <StyledImg src={`${s3AssetDomain}/icid_request.png`} alt="icid_request" />
        <Typography.Text light type="HEADLINE_1" gutter={{ top: 50 }}>
          개발하신 새로운 원료의
          <br />
          ICID 등재가 필요하신가요?
        </Typography.Text>
        <Typography.Text type="BODY_2" gutter={{ top: 16 }} color="GRAY70">
          certicos 플랫폼을 이용하여 몇 가지 입력으로 ICID에 원료를 등재해 보세요.
        </Typography.Text>
        <ICIDInfo>
          <Flex align="center">
            <Icon name="calendar" color="PRIMARY50" />
            <Typography.Text type="TITLE_2" gutter={{ left: 8 }} inline color="SLATE_GRAY70">
              {currentYear} PCPC 위원회 일정
            </Typography.Text>
          </Flex>
          <PCPCCouncilOpenDatesDiv>
            {formattedPCPCCouncilOpendates && formattedPCPCCouncilOpendates.length > 5 ? (
              <>
                <div>{formattedPCPCCouncilOpendates.slice(0, 5)}</div>
                <div>{formattedPCPCCouncilOpendates.slice(5)}</div>
              </>
            ) : (
              formattedPCPCCouncilOpendates
            )}
          </PCPCCouncilOpenDatesDiv>
        </ICIDInfo>
        <ICIDDescription>
          <li>모든 자료가 최소 2개월 전에 준비되어야 해당 위원회에서 검토를 진행합니다.</li>
        </ICIDDescription>
        <ICIDInfo>
          <Flex align="center">
            <Icon name="paid" color="PRIMARY50" />
            <Typography.Text type="TITLE_2" gutter={{ left: 8 }} inline color="SLATE_GRAY70">
              가격
            </Typography.Text>
          </Flex>
          <Flex align="center">
            <Typography.Text medium color="SLATE_GRAY70">
              SKU당
            </Typography.Text>
            <Typography.Text type="TITLE_1" medium gutter={{ left: 8 }}>
              190만원
            </Typography.Text>
            <Typography.Text type="BODY_2" gutter={{ left: 8 }} color="SLATE_GRAY70">
              (부가세 별도)
            </Typography.Text>
          </Flex>
        </ICIDInfo>
        <ICIDDescription>
          <li>ICID에 새 INCI 명을 등재하기 위해 PCPC 위원회 승인이 필요합니다.</li>
          <li>정보 등록 시점과 실제 원료 등재 완료 시점까지 기간 차이가 있을 수 있습니다.</li>
          <li>2024년부터 PCPC 지불 비용이 $400에서 $1,000로 인상되었습니다.</li>
          <li>
            해당 비용에는 CDRI 컨설팅 비용이 포함되어 있으니, CDRI를 통해 컨설팅까지 꼭 받아보시기를
            바랍니다.
          </li>
        </ICIDDescription>
        <StyledButton type="primary">
          <Link to={path.material.icid.basic}>등재 의뢰하기</Link>
        </StyledButton>
      </Spin>
    </Container>
  );
};

export default ICIDRequest;

import { Button, Col, message, Modal, Row, Statistic } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import FooterBox from 'components/FooterBox';
import StatisticCard from 'components/StatisticCard';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import Tags from 'components/ui/Tags';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { useICIDApplication } from 'service/material/icid/icid';
import { useCompleteOngoingDocs, useOngoingDocs } from 'service/material/icid/ongoing';
import { DocumentStatus } from 'types/common';
import { DocumentCode, ICIDApplicationStatus, ICIDOngoingDocs } from 'types/material/icid/icid';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 18px 16px;
  }

  .ant-btn[disabled] {
    background: ${palette.SLATE_GRAY30};
    border-color: #fff;
    color: ${palette.SLATE_GRAY60};
    opacity: 1;
  }
`;

const ICIDDocumentList = ({ icidApplicationId }: { icidApplicationId: number }) => {
  const { icidApplication, getICIDApplicationLoading } = useICIDApplication(icidApplicationId);
  const { icidApplicationOngoings, isLoading } = useOngoingDocs(icidApplicationId);
  const disabledInputBubtton = useMemo(
    () =>
      !icidApplicationOngoings.every(
        ({ status }) => status === DocumentStatus.ONG || status === DocumentStatus.FIN,
      ),
    [icidApplicationOngoings],
  );

  const navigate = useNavigate();
  const columns: ColumnsType<ICIDOngoingDocs> = [
    {
      title: '구분',
      dataIndex: 'no',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: '입력정보',
      render: (_, { documentName, documentCode }) => (
        <Typography.Text
          type="BODY_2"
          onClick={() =>
            navigate(
              `${path.material.icid.root}/${icidApplicationId}/${
                DocumentCode[documentCode.replace('01', '') as keyof typeof DocumentCode]
              }`,
            )
          }
          style={{
            fontWeight: 500,
            cursor: 'pointer',
            color: palette.GRAY90,
          }}
        >
          {documentName}
        </Typography.Text>
      ),
    },
    {
      title: '처리현황',
      align: 'center',
      render: (_, { status, documentCode }) => {
        if (icidApplication?.status === 'INPUT') {
          if (status === DocumentStatus.INP || status === DocumentStatus.MOD || status === null) {
            return (
              <Tags.Mark
                type="arrow"
                color="blue"
                onClick={() =>
                  navigate(
                    `${path.material.icid.root}/${icidApplicationId}/${
                      DocumentCode[documentCode.replace('01', '') as keyof typeof DocumentCode]
                    }`,
                  )
                }
              >
                필수 입력
              </Tags.Mark>
            );
          } else {
            return (
              <Typography.Text
                type="BODY_2"
                onClick={() =>
                  navigate(
                    `${path.material.icid.root}/${icidApplicationId}/${
                      DocumentCode[documentCode.replace('01', '') as keyof typeof DocumentCode]
                    }`,
                  )
                }
              >
                입력 완료
              </Typography.Text>
            );
          }
        }

        if (
          icidApplication?.status === ICIDApplicationStatus.ONGOING ||
          icidApplication?.status === ICIDApplicationStatus.APPLYING
        ) {
          if (status === DocumentStatus.INP || status === DocumentStatus.MOD || status === null) {
            return (
              <Tags.Mark
                type="arrow"
                color="blue"
                onClick={() =>
                  navigate(
                    `${path.material.icid.root}/${icidApplicationId}/${
                      DocumentCode[documentCode.replace('01', '') as keyof typeof DocumentCode]
                    }`,
                  )
                }
              >
                {status === DocumentStatus.MOD ? '보완 필요' : '필수 입력'}
              </Tags.Mark>
            );
          } else {
            return <Tags.Mark color="lightGray">검토중</Tags.Mark>;
          }
        }

        if (icidApplication?.status === 'CANCEL') {
          return <Typography.Text type="BODY_2">진행 취소</Typography.Text>;
        }
      },
    },
    {
      title: '최근 입력자 명',
      align: 'center',
      render: (_, { modifierName, modifierCompanyName }) =>
        modifierName ? `${modifierName} (${modifierCompanyName})` : '-',
    },
    {
      title: '입력 일시',
      align: 'center',
      render: (_, { modifierName, modifiedDt }) =>
        modifierName && modifiedDt ? modifiedDt.replace('T', ' ') : '-',
    },
  ];

  const { completeOngoingDocs, isCompleteLoading } = useCompleteOngoingDocs();
  const handleFinish = useCallback(() => {
    Modal.confirm({
      icon: null,
      closable: true,
      width: 400,
      bodyStyle: {
        paddingTop: 8,
      },
      closeIcon: <Icon name="close" size={20} style={{ margin: '0 auto', height: 56 }} />,
      content: (
        <Typography.Text type="BODY_2" align="center">
          ‘입력 완료' 버튼을 클릭하시면,
          <br />
          해당 원료에 입력해 주신 정보가 관리자에게 전달되며,
          <br />
          관리자의 보완 요청이 있기 전까지는 수정이 불가합니다.
          <br />
          <br />
          입력 완료 처리 하시겠습니까?
        </Typography.Text>
      ),

      okText: '완료 처리',
      onOk: () => {
        completeOngoingDocs(icidApplicationId, {
          onSuccess: () => {
            message.success('입력 완료되었습니다.');
            navigate(path.material.icid.ongoing);
          },
        });
      },
    });
  }, []);

  return (
    <Container>
      <StatisticCard>
        <Row>
          <Col span={9}>
            <Statistic title="원료명 (영문)" value={icidApplication?.tradeName} />
          </Col>
          <Col span={15}>
            <Statistic title="카테고리" value={icidApplication?.category.categoryName} />
          </Col>
        </Row>
      </StatisticCard>
      <Table
        size="small"
        columns={columns}
        rowKey="documentName"
        dataSource={icidApplicationOngoings}
        pagination={false}
        loading={isLoading || getICIDApplicationLoading}
        style={{ marginTop: 24 }}
      />
      {icidApplication?.status === 'INPUT' && (
        <FooterBox>
          <Button
            type="primary"
            onClick={handleFinish}
            loading={isCompleteLoading}
            disabled={disabledInputBubtton}
          >
            입력 완료
          </Button>
        </FooterBox>
      )}
    </Container>
  );
};

export default ICIDDocumentList;

import { useParams } from 'react-router-dom';
import { useLayoutEffect } from 'react';

import RawMaterialPageTemplate from 'templates/material/RawMaterialPageTemplate';
import MistakeNoticeItem from 'components/MistakeNoticeItem';
import Marketing from 'components/material/rawMaterial/marketing/Marketing';

const notices = [
  '업로드형은 기존 보유하신 마케팅 자료를 첨부하여 업로드할 수 있으며, 템플릿형은 제공된 템플릿으로 마케팅 자료를 제작해 업로드할 수 있습니다.',
  '공개형은 모두에게 공개되는 자료이며, 제한형의 경우 열람 승인한 제조사에게만 공개되는 자료입니다.',
  '파일이 여러 개일 경우, 하나의 PDF로 병합하거나 ZIP 파일로 압축하여 업로드해 주세요.',
  <MistakeNoticeItem />,
];

const RawMaterialMarketingPage = () => {
  const params = useParams<{
    materialId: string;
  }>();
  const materialId = Number(params.materialId) || undefined;

  useLayoutEffect(() => {
    const rootPageTemplete = document.querySelector('[class*="PageTemplateBlock"]');
    if (!rootPageTemplete) return;
    (rootPageTemplete as HTMLElement).style.maxWidth = '1040px';
  }, []);

  return (
    <RawMaterialPageTemplate documentName="마케팅 자료" notices={notices} back>
      <Marketing materialId={materialId} />
    </RawMaterialPageTemplate>
  );
};

export default RawMaterialMarketingPage;

import { Button, Modal } from 'antd';
import styled from 'styled-components';

import FileViewer from 'components/file/FileViewer';
import { downloadFile, printFile } from 'lib/file';
import { Flex } from 'components/ui';
import { Typography } from 'components/system';
import Icon from 'components/ui/Icon/Icon';

const StyledModal = styled(Modal)<{ type: DocumentPreviewModalProps['type'] }>`
  ${({ type }) =>
    type === 'secondary' &&
    `
      .ant-modal-close-x {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 13px;
        right: 29px;
      }
    `}
`;

const FileViewerListBlock = styled.div<{
  type: DocumentPreviewModalProps['type'];
}>`
  padding-top: 28px;

  ${({ type }) =>
    type === 'secondary' &&
    `
      max-height: 798px;
      padding-top: 16px;
    `}
`;

const FileViewWrap = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export interface DocumentPreviewModalProps {
  documentUrls: string[] | string | null;
  documentName?: string;
  footerVisible?: boolean;
  onClose?: () => void;
  type?: 'primary' | 'secondary';
  title?: string;
}

const DocumentPreviewModal = ({
  documentUrls,
  documentName,
  footerVisible = true,
  onClose,
  type = 'primary',
  title,
}: DocumentPreviewModalProps) => {
  return (
    <StyledModal
      type={type}
      open={documentUrls instanceof Array ? documentUrls.length > 0 : !!documentUrls}
      destroyOnClose
      closable
      onCancel={onClose}
      footer={null}
      width={type === 'primary' ? 992 : 562}
      closeIcon={
        type === 'secondary' && (
          <Icon size={34} name="close" style={{ margin: '0 auto', height: 56 }} />
        )
      }
    >
      {title && <Typography.Text type="TITLE_1">{title}</Typography.Text>}
      {documentUrls && (
        <FileViewerListBlock type={type}>
          {(documentUrls instanceof Array ? documentUrls : [documentUrls]).map((documentUrl) => (
            <FileViewWrap key={documentUrl}>
              <FileViewer key={documentUrl} file={documentUrl} />
              {footerVisible && (
                <Flex gap={8} justify="end" style={{ marginTop: 8 }}>
                  <Button
                    size={type === 'secondary' ? 'small' : 'middle'}
                    type="primary"
                    icon={<Icon name="download" size={14} color="ETC_WHITE" />}
                    onClick={() => downloadFile(documentUrl, documentName)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(type === 'secondary'
                        ? { padding: '5px 16px', lineHeight: 'initial' }
                        : {}),
                    }}
                  >
                    다운로드
                  </Button>
                  <Button
                    size={type === 'secondary' ? 'small' : 'middle'}
                    type="primary"
                    icon={<Icon name="print" size={14} color="ETC_WHITE" />}
                    onClick={() => printFile(documentUrl)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(type === 'secondary'
                        ? { padding: '5px 16px', lineHeight: 'initial' }
                        : {}),
                    }}
                  >
                    인쇄하기
                  </Button>
                </Flex>
              )}
            </FileViewWrap>
          ))}
        </FileViewerListBlock>
      )}
    </StyledModal>
  );
};

export default DocumentPreviewModal;

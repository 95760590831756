import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { s3AssetDomain } from 'lib/consts';
import { downloadFile } from 'lib/file';
import { deviceSize } from 'lib/styles';
import { Flex } from 'components/ui';
import { Button, H1, LogoText } from './introStyles';

const Container = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url(${`${s3AssetDomain}/intro_main_section_l.jpeg`});
  padding: 170px 0px;
  background-size: 200%;
  background-position: 10% 80%;
  background-repeat: no-repeat;

  @media ${deviceSize.lg} {
    background-size: 100%;
  }

  @media ${deviceSize.sm} {
    background-size: 200%;
    padding: 90px 0px;
  }
`;

const Body = styled.div`
  width: fit-content;
  margin: auto;
  white-space: pre-wrap;
`;

const ButtonWrapper = styled(Flex)`
  margin-top: 60px;
  column-gap: 20px;
  row-gap: 10px;

  @media ${deviceSize.sm} {
    flex-direction: column;
    align-items: center;
  }
`;

const SectionF = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Body>
        <H1 color="WHITE" style={{ textAlign: 'center' }}>
          인증, <LogoText>certicos</LogoText>
          {`에 맡기고\n사업에만 집중하세요.`}
        </H1>
        <ButtonWrapper>
          <Button onClick={() => navigate('/login')}>지금 시작하기</Button>
          <Button
            invert
            onClick={() =>
              downloadFile(
                'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/document/Certicos+%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%8C%E1%85%A1%E1%84%85%E1%85%AD(%E1%84%87%E1%85%B3%E1%84%85%E1%85%A2%E1%86%AB%E1%84%83%E1%85%B3%E1%84%89%E1%85%A1%2C%E1%84%8C%E1%85%A6%E1%84%8C%E1%85%A9%E1%84%89%E1%85%A1%2C%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%85%E1%85%AD%E1%84%89%E1%85%A1).zip',
                'CDRI_서비스_소개서.zip',
              )
            }
          >
            서비스 소개서 다운로드
          </Button>
        </ButtonWrapper>
      </Body>
    </Container>
  );
};

export default SectionF;

import MistakeNoticeItem from 'components/MistakeNoticeItem';
import ProductVCRPAccount from 'components/product/ProductVCRPAccount';
import ProductPageTemplate from 'templates/ProductPageTemplate';
import { Typography } from 'components/system';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';

const notices = [
  <>
    <Typography.Text type="BODY_2" medium inline>
      VCRP
    </Typography.Text>{' '}
    계정의{' '}
    <Typography.Text type="BODY_2" medium inline>
      사용자명 (ID), 비밀번호
    </Typography.Text>
    는 오직 귀사의 제품 인증을 위해서만 사용됩니다. 기타 다른 목적으로 사용되지 않습니다.
  </>,
  <>
    <Typography.Text type="BODY_2" medium inline>
      certicos
    </Typography.Text>
    의 담당자는 귀사의 계정에 하위{' '}
    <Typography.Text type="BODY_2" medium inline>
      FILER
    </Typography.Text>
    로 제품 등록을 진행합니다.
  </>,
  '회사 당 하나의 계정만 등록 가능합니다.',
  <>
    <Typography.Text type="BODY_2" medium inline>
      VCRP
    </Typography.Text>{' '}
    등록 완료 후, 계정의 비밀번호 변경은{' '}
    <Typography.Text type="BODY_2" medium inline>
      vcrp@fda.gov
    </Typography.Text>
    로 요청 바랍니다.
  </>,
  <>
    <Typography.Text type="BODY_2" medium inline>
      certicos
    </Typography.Text>
    를 통해 신규 계정 신청을 진행하시는 경우, 신규{' '}
    <Typography.Text type="BODY_2" medium inline>
      VCRP
    </Typography.Text>{' '}
    계정 생성 항목을 먼저 입력하신 후,{' '}
    <Typography.Text type="BODY_2" medium inline>
      VCRP ADMIN
    </Typography.Text>
    으로부터 받은 메일 상의 계정정보를 입력해주세요.
  </>,
  <MistakeNoticeItem />,
];

const ProductVCRPAccountPage = () => {
  const { productId } = useCurrentProduct();
  const countryId = useCountryId()!;

  return (
    <ProductPageTemplate subtitle="보유 VCRP 계정 정보" notices={notices}>
      <ProductVCRPAccount productId={productId} countryId={countryId} />
    </ProductPageTemplate>
  );
};

export default ProductVCRPAccountPage;
